import * as React from 'react';

import 'chartjs-plugin-datalabels';

import IBaseGraphProps from "./interfaces/IBaseGraphProps";
import { ChartDataSets, ChartOptions } from 'chart.js';

interface IBaseGraphState {
    datasets?: readonly ChartDataSets[];
    graphTitle?: string;
}

export default abstract class BaseGraph extends React.Component<IBaseGraphProps, IBaseGraphState> {
    protected chartInstance: Chart;

    public constructor(props: IBaseGraphProps) {
        super(props);
        this.onLegendItemClick = this.onLegendItemClick.bind(this);
        this.onElementsClickCallback = this.onElementsClickCallback.bind(this);
        this.state = {};
        this.onElementHover = this.onElementHover.bind(this);
        this.getChartOptions = this.getChartOptions.bind(this);
    }

    public componentDidUpdate(prevProps: IBaseGraphProps): void {
        if (prevProps.data.datasets !== this.props.data.datasets && this.props.data.datasets) {
            // force re-render for tooltip information
            this.setState({
                datasets: this.props.data.datasets
            });
        }
    }

    protected onElementHover(event: MouseEvent, chartElement: any[]): void {
        if (event.target === null) {
            return;
        }

        const eventTarget: any = event.target;
        eventTarget.style.cursor = this.props.elementsClickable ? chartElement.length > 0 ? 'pointer' : 'default' : 'default';
    }

    protected async onElementsClickCallback(input: any[]): Promise<void> {
        if (input.length === 0 || this.props.onElementClick === undefined) {
            return;
        }

        await this.props.onElementClick(input[0]._index);
    }

    protected onLegendItemClick(): void {
        this.chartInstance.update();
    }

    protected getChartOptions(): ChartOptions {
        const options = this.props.options;
        if (!this.props.options.plugins?.datalabels) {
            options.plugins = {
                datalabels: {
                    display: false
                }
            };
        }
        return options;
    }
}