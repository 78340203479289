import * as React from "react";
import { PulseLoader } from 'react-spinners';
import ISpaceRestrictionsInfoProps from "./interfaces/ISpaceRestrictionsInfoProps";

import UnavailableWorkPlaceImage from '@/images/workplace_unavailable.svg';

import Colors from "@/styles/colors";
import './spaceRestrictionsInfo.scss';
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

export default class SpaceRestrictionsInfo extends React.Component<ISpaceRestrictionsInfoProps> {
    public constructor(props: ISpaceRestrictionsInfoProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div id="space-restrictions-info">
                {this.props.loading && <div id="restrictions-pulse-loader">
                    <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.props.loading} />
                </div>}
                {!this.props.loading && <div className="align-self-center">
                    <div className="space-restrictions-container">
                        <div className="space-restrictions-row first-line">
                            <div id="available-circle" />
                            <span className="space-restrictions-count">{this.props.unrestrictedCount}</span>
                        </div>
                        <div className="space-restrictions-row second-line"> {LanguageProvider.getTranslation(translations.pages.settings.workspaces.reservable)} </div>
                    </div>
                    <div className="space-restrictions-container">
                        <div className="space-restrictions-row first-line">
                            <img src={UnavailableWorkPlaceImage} />
                            <span className="space-restrictions-count">{this.props.restrictedCount}</span>
                        </div>
                        <div className="space-restrictions-row second-line"> {LanguageProvider.getTranslation(translations.pages.settings.workspaces.unreservable)} </div>
                    </div>
                </div>}
            </div>
        );
    }
}