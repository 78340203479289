import React from 'react';

import LanguageProvider from '../../../../providers/languageProvider';
import TempReservationMockData, { ReservedTimeData } from '../tempMockData';

import './reservedTimeGrid.scss';

interface IReservedTimeGridProps {
    startHour: number;
    endHour: number;
};

const ReservedTimeGrid: React.FC<IReservedTimeGridProps> = (props) => {

    const [reservedTimeData] = React.useState<ReservedTimeData>(TempReservationMockData.generateReservedTimeData());

    function generateTimeColumns(start: number, end: number): string[] {
        const timeColumns: string[] = [];
        for (let i = start; i < end; i++) {
            timeColumns.push(`${i}-${i + 1}`);
        }
        return timeColumns;
    }

    function calculateRowAverage(values: number[]): number {
        const sum = values.reduce((acc, value) => acc + value, 0);
        return Math.round(sum / values.length);
    }

    function calculateColumnAverages(start: number, end: number): number[] {
        const columnSums = Array(end - start).fill(0);
        const rowCount = Object.keys(reservedTimeData).length;

        Object.values(reservedTimeData).forEach(values => {
            values.slice(start, end).forEach((value, index) => {
                columnSums[index] += value;
            });
        });

        return columnSums.map(sum => Math.round(sum / rowCount));
    }

    function getBackgroundColor(value: number): string {
        const maxColor = { r: 67, g: 189, b: 207 };
        const intensity = Math.floor((value / 50) * 100);

        const r = Math.floor(255 - (intensity / 100) * (255 - maxColor.r));
        const g = Math.floor(255 - (intensity / 100) * (255 - maxColor.g));
        const b = Math.floor(255 - (intensity / 100) * (255 - maxColor.b));

        return `rgb(${r}, ${g}, ${b})`;
    }

    const columnAverages = calculateColumnAverages(props.startHour, props.endHour);
    const timeColumns = generateTimeColumns(props.startHour, props.endHour);

    return (
        <div className="reserved-time-grid-container">
            <div className="header">
                <h3>{LanguageProvider.getTranslation('pages.realestateutilization.charts.reservedtimegrid.title')}</h3>
            </div>

            <table>
                <thead>
                    <tr>
                        <th className="standard-font">{LanguageProvider.getTranslation('pages.realestateutilization.charts.reservedtimegrid.columnrowlabel')}</th>
                        {timeColumns.map((time) => (
                            <th className="standard-font" key={time}>{time}</th>
                        ))}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(reservedTimeData).map(([day, values]) => {
                        const slicedValues = values.slice(props.startHour, props.endHour);
                        const rowAverage = calculateRowAverage(slicedValues);
                        return (
                            <tr key={day}>
                                <td>{day}</td>
                                {slicedValues.map((value, index) => {
                                    const backgroundColor = getBackgroundColor(value);

                                    return (<td key={index} style={{ backgroundColor }}>{value}%</td>);
                                })}
                                <td style={{ textAlign: 'center' }}>{rowAverage}%</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td></td>
                        {columnAverages.map((average, index) => (
                            <td key={index}>{average}%</td>
                        ))}
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ReservedTimeGrid;
