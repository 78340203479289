import * as React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from "react-i18next";

import IPageHeaderItem from '../../interfaces/IPageHeaderItem';
import LanguageProvider from '../../providers/languageProvider';

interface IItem extends WithTranslation {
    onClick: (tab: IPageHeaderItem) => void;
    data: IPageHeaderItem;
    isActive: boolean;
}

// tslint:disable-next-line:max-classes-per-file
class Item extends React.Component<IItem, {}>{
    public constructor(props: any) {
        super(props);
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    private getTestAtribute(): string {
        const slashRegex = /\//g;
        const transformedUrl = this.props.data.relativeUrl.replace(slashRegex, "-");
        return `tab${transformedUrl}-nav`;
    }

    public render(): JSX.Element {
        return (
            <li className="nav-item">
                <Link
                    title={LanguageProvider.getTranslation(this.props.data.translationLabel)}
                    className={`nav-link mr-5 pageheader-item ${this.props.isActive && "active"}`}
                    to={this.props.data.relativeUrl}
                    id={this.props.data.id}
                    onClick={this.onClickHandler}
                    data-test={this.getTestAtribute()}>
                    {LanguageProvider.getTranslation(this.props.data.translationLabel)}
                </Link>
            </li>
        );
    }

    private onClickHandler(): void {
        this.props.onClick(this.props.data);
    }
}

export default withTranslation()(Item);
