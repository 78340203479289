import DashboardService from "./dashboardService";
import { AccountInfo } from "@azure/msal-common";
import AuthService from "services/authService";
import { PublicClientApplication } from "@azure/msal-browser";
import { ApplicationConfig } from "../config";

export default class AccessService {
	private dashboardService: DashboardService;
	private authService: AuthService;
	private readonly publicClientApplication: PublicClientApplication;

	public constructor() {
		this.publicClientApplication = new PublicClientApplication({
			auth: {
				clientId: ApplicationConfig.ad.clientId,
				authority: `https://login.microsoftonline.com/${ApplicationConfig.ad.clientId}`,
				redirectUri: ApplicationConfig.ad.redirectUri,
			},
			cache: {
				cacheLocation: ApplicationConfig.ad.cacheLocation,
			},
		});
		this.dashboardService = new DashboardService();
		this.authService = new AuthService();
	}

	public signIn(onSuccess: () => void, onFailure: () => void, onError: (error: Error) => void): void {
		this.authService
			.login()
			.then(() => this.ensureAccess())
			.then((allowed) => (allowed ? onSuccess() : onFailure()))
			.catch((error) => onError(error));
	}

	public getUser(): AccountInfo | undefined {
		return this.publicClientApplication.getActiveAccount() ?? undefined;
	}

	public signOut(): void {
		this.publicClientApplication.logoutRedirect();
		sessionStorage.clear();
		window.location.href =
			"https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=" +
			encodeURIComponent(process.env.REACT_APP_LogoutReturnUrl || window.location.protocol + "//" + window.location.host);
	}

	public getName(): string {
		const user = this.getUser();
		return user ? user.name ?? "Unknown" : "Unknown";
	}
	public getUserName(): string {
		const user = this.getUser();
		return user ? user.username : "Unknown";
	}

	private async ensureAccess(): Promise<boolean> {
		return this.dashboardService.ensureAccess();
	}
}
