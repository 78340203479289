import * as React from 'react';
import { NotificationManager } from 'react-notifications';

import "./homePage.scss";

import IHomePageProps from './interfaces/IHomePageProps';
import IHomePageState from "./interfaces/IHomePageState";
import EnergyPortfolio from './components/energyManagementOverview';
import EnergyTopConsumersOverview from './components/energyTopConsumersOverview';
import CurrentComfortOverview from './components/currentComfortOverview';
import ComfortIcon from './../../images/temperature-blue.svg';
import ComfortIconSelected from './../../images/temperature-white.svg';
import OccupationIcon from './../../images/occupation-blue.svg';
import OccupationIconSelected from './../../images/occupation-white.svg';
import Blue_Arrow from "./../../images/Blue_Arrow_Right.svg";

import CurrentOccupancyOverview from './components/currentOccupancyOverview';
import CoreSpaceService from '../../services/coreSpaceService';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import VenueProvider from '../../providers/venueProvider';
import LanguageProvider from '../../providers/languageProvider';
import { withTelemetry } from '../../services/telemetryService';
import SubscriptionValidationService from '../../services/subscriptionValidationService';
import FullPageLoader from '../../components/loaders/fullPageLoader';
import { withRouter } from 'react-router-dom';
import links from '../../utils/links';

class HomePage extends React.Component<IHomePageProps, IHomePageState> {
    private subscriptionValidationService: SubscriptionValidationService;

    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: IHomePageProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();

        const state: IHomePageState = {
            loading: true,
            hasOccupationSubscription: false,
            hasEnergyManagementSubscription: false,
            hasIndoorClimateSubscription: false,
            cleaningNotifications: [],
            comfortValueOverviewSelected: false
        };

        this.state = state;
        this.initializeActiveVenue = this.initializeActiveVenue.bind(this);
        this.changeComponentToComfort = this.changeComponentToComfort.bind(this);
        this.changeComponentToOccupation = this.changeComponentToOccupation.bind(this);
        this.redirectToEnergyPage = this.redirectToEnergyPage.bind(this);
        this.redirectToIndoorClimagePage = this.redirectToIndoorClimagePage.bind(this);
        this.redirectToRealEstateUtilizationPage = this.redirectToRealEstateUtilizationPage.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeActiveVenue);
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeActiveVenue);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

        let activeVenue = VenueProvider.getActiveVenue();

        // Ensure a venue is selected (since this component mounts before app.tsx does).
        if (!activeVenue) {
            const venues = await this.coreSpaceService.getVenues();

            if (!venues.length) {
                NotificationManager.error(LanguageProvider.getTranslation("global-error-messages.account-configuration-incorrect"));
            } else {
                activeVenue = venues[0];
                VenueProvider.saveActiveVenue(activeVenue);
            }
        }
        if (activeVenue) {
            this.initializeActiveVenue();
        }
    }

    private initializeActiveVenue(): void {
        const venue = VenueProvider.getActiveVenue();
        if (!venue) {
            return;
        }

        if (!this.subscriptionValidationService.userHasAnyService()) {
            NotificationManager.error(LanguageProvider.getTranslation("pages.home.nosubscriptions"));
        }

        // Note that energy is showing data based on the current selected venue,
        // while occupancy/comfort is checking all accessible customers

        const hasEnergyManagement = this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEEnergized"]);
        const hasRealEstateUtilization = this.subscriptionValidationService.userHasAnyApplicableService(["BEEquipped"]);
        const hasIndoorClimate = this.subscriptionValidationService.userHasAnyApplicableService(["BEVital"]);

        if (!hasRealEstateUtilization && hasIndoorClimate) {
            this.changeComponentToComfort();
        }

        this.setState({
            loading: false,
            hasEnergyManagementSubscription: hasEnergyManagement,
            hasOccupationSubscription: hasRealEstateUtilization,
            hasIndoorClimateSubscription: hasIndoorClimate,
        });
    }

    private changeComponentToComfort(): void {
        this.setState({
            comfortValueOverviewSelected: true,
        });
    }

    private changeComponentToOccupation(): void {
        this.setState({
            comfortValueOverviewSelected: false,
        });
    }

    private redirectToEnergyPage(): void {
        this.props.history.push(links.energymanagement.portfolio);
    }

    private redirectToRealEstateUtilizationPage(): void {
        this.props.history.push(links.realestateutilization.motion);
    }

    private redirectToIndoorClimagePage(): void {
        this.props.history.push(links.indoorclimate.general);
    }

    public render(): JSX.Element {
        return (<>
            {this.state.loading && <FullPageLoader loading={this.state.loading} />}
            {!this.state.loading &&
                <div className="main-content">
                    {(this.state.hasOccupationSubscription || this.state.hasIndoorClimateSubscription) &&
                        <>
                            <div className="main-content-header">
                                <div className="row header-margin-bottom-h1">
                                    <div className="col-sm-12 d-flex">
                                        <h1>{this.state.comfortValueOverviewSelected ?
                                            LanguageProvider.getTranslation("pages.home.comfort.comfortheader")
                                            : LanguageProvider.getTranslation("pages.home.occupancy.occupancyheader")}</h1>
                                        <div className="homepage-switch-button-comfort-occupation-container ml-auto">
                                            {this.state.hasOccupationSubscription &&
                                                <div onClick={this.changeComponentToOccupation}
                                                    title={LanguageProvider.getTranslation("pages.home.occupancy.occupancyheader")}
                                                    className={`button-occupation ${!this.state.comfortValueOverviewSelected ? 'selected' : ''}`}>
                                                    <img src={!this.state.comfortValueOverviewSelected ? OccupationIconSelected : OccupationIcon} />
                                                </div>}
                                            {this.state.hasIndoorClimateSubscription &&
                                                <div onClick={this.changeComponentToComfort}
                                                    title={LanguageProvider.getTranslation("pages.home.comfort.comfortheader")}
                                                    className={`button-comfort ${this.state.comfortValueOverviewSelected ? 'selected' : ''}`}>
                                                    <img src={this.state.comfortValueOverviewSelected ? ComfortIconSelected : ComfortIcon} />
                                                </div>}
                                        </div>
                                        <div className="d-flex align-items-center clickable ml-2 mb-2 pb-1">
                                            <div className="mr-2" onClick={this.state.comfortValueOverviewSelected ? this.redirectToIndoorClimagePage : this.redirectToRealEstateUtilizationPage}>
                                            {LanguageProvider.getTranslation("pages.home.all")}
                                            </div>
                                            <img src={Blue_Arrow} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content-body">
                                {this.state.comfortValueOverviewSelected ?
                                    <CurrentComfortOverview itemsToLoad={4} />
                                    : <CurrentOccupancyOverview />}
                            </div>
                        </>
                    }
                    {
                        ((this.state.hasOccupationSubscription || this.state.hasIndoorClimateSubscription)
                            && this.state.hasEnergyManagementSubscription) &&
                        <div className="content-divider" />
                    }
                    {
                        this.state.hasEnergyManagementSubscription &&
                        <>
                            <div className={`main-content-header ${(this.state.hasOccupationSubscription || this.state.hasIndoorClimateSubscription) && 'no-border-bottom'}`}>
                                <div className="row header-margin-bottom-h1">
                                    <div className="col-sm d-flex">
                                        <h1>{LanguageProvider.getTranslation("energy.managementoverview.title")}</h1>
                                        <div className="ml-auto clickable d-flex align-items-center" onClick={this.redirectToEnergyPage}>
                                            <span className="mr-2 more-button">{LanguageProvider.getTranslation("pages.home.all")}</span>
                                            <img src={Blue_Arrow} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-content-body">
                                <EnergyPortfolio />
                                <div className="row mt-5">
                                    <div className="col-sm">
                                        <EnergyTopConsumersOverview />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>}
        </>


        );
    }
}

export default withRouter((withTelemetry(HomePage, "HomePage")));