import React from 'react';

interface CheckboxProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, label }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                style={{
                    width: 18,
                    height: 18,
                    marginRight: 8,
                    cursor: 'pointer',
                }}
            />
            {label && <label>{label}</label>}
        </div>
    );
};

export default Checkbox;
