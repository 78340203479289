import * as React from 'react';

import ContractTileCollection from '../components/contractTileCollection';

import IEnergyOverviewPageProps from './interfaces/IEnergyOverviewPageProps';
import IEnergyOverviewPageState from './interfaces/IEnergyOverviewPageState';

import VenueSavingsTileCollection from '../components/venueSavingsTileCollection';
import EnergyAssetOverview from '../components/energyAssetOverview';
import EnergyService from '@/services/energyService';
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import VenueProvider from '@/providers/venueProvider';
import EnergyContract from '@/models/energy/energyContract';
import { withTelemetry } from '@/services/telemetryService';
import EnergyManagementPage from '../energyManagementPage';
import FullPageLoader from '@/components/loaders/fullPageLoader';
import LanguageProvider from '@/providers/languageProvider';

class EnergyOverviewPage extends React.Component<IEnergyOverviewPageProps, IEnergyOverviewPageState>{
    private readonly energyService: EnergyService;

    public constructor(props: IEnergyOverviewPageProps) {
        super(props);

        this.setPageDataAsync = this.setPageDataAsync.bind(this);

        const state: IEnergyOverviewPageState = {
            loading: true,
        };

        this.state = state;

        this.energyService = new EnergyService();

        AppEventHub.on(AppEvents.BuildingSelected, this.setPageDataAsync);
    }

    public async componentDidMount(): Promise<void> {
        await this.setPageDataAsync();
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.setPageDataAsync);
    }

    private async setPageDataAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();

        const data = await this.energyService.GetVenueDetailsAsync();
        const contracts = data.subscriptions.map(s => EnergyContract.fromSubscription(s));

        this.setState({
            venue: venue,
            loading: false,
            venueDetails: data,
            contracts: contracts
        });
    }

    public render(): JSX.Element {
        return (
            <EnergyManagementPage>
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}
                {!this.state.loading &&
                    <div id="venue-energy-overview" className="main-content">
                        <div className="main-content-header">
                            <div className="row header-margin-bottom-h1">
                                <div className="col-sm-12">
                                    <h1>{this.state.venue && this.state.venue.name}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="main-content-body">
                            <div className="row">
                                <div className="col-sm-12">
                                    {this.state.venueDetails &&
                                        <VenueSavingsTileCollection
                                            data={this.state.venueDetails.savings}
                                            contracts={this.state.venueDetails.subscriptions} />
                                    }
                                </div>
                            </div>
                            <div className="content-divider" />
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2>{LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.title")}</h2>
                                    {this.state.contracts && <ContractTileCollection contracts={this.state.contracts} />                                    }
                                </div>
                            </div>
                            <div className="content-divider" />
                            <div className="row">
                                <div className="col-sm-12">
                                    {this.state.contracts &&
                                        <EnergyAssetOverview />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
            </EnergyManagementPage>
        );
    }
}

export default withTelemetry(EnergyOverviewPage, "EnergyOverviewPage");