import moment from 'moment';

import IEnergyPortfolioLine from '../../interfaces/energy/IEnergyPortfolioLine';
import { ISubscriptionDetails } from '../../interfaces/ISubscriptionDetails';

export default class EnergyContractProgress {
  public readonly unixTimeLeft: number;
  public readonly yearsLeft: number;
  public readonly monthsLeft: number;
  public readonly daysLeft: number;
  public readonly yearsDuration: number;
  public readonly monthsDuration: number;
  public readonly startDate: Date;
  public readonly endDate: Date;
  public readonly savingsGoalPercentage?: number;
  public readonly currentSavingsPercentage?: number;
  public readonly electricityContractSavingsPercentage?: number;
  public readonly gasContractSavingsPercentage?: number;
  public readonly heatContractSavingsPercentage?: number;
  public readonly subscriptionDetails: ISubscriptionDetails;

  public constructor(energyPortfolioLine: IEnergyPortfolioLine) {
    const today = moment(new Date());
    this.startDate = new Date(energyPortfolioLine.energySubscriptionDetails.startDate);
    this.endDate = new Date(energyPortfolioLine.energySubscriptionDetails.endDate);

    this.savingsGoalPercentage = energyPortfolioLine.savingsGoalPercentage;
    this.currentSavingsPercentage = energyPortfolioLine.currentSavingsPercentage;
    this.electricityContractSavingsPercentage = energyPortfolioLine.electricityContractSavingsPercentage;
    this.gasContractSavingsPercentage = energyPortfolioLine.gasContractSavingsPercentage;
    this.heatContractSavingsPercentage = energyPortfolioLine.heatContractSavingsPercentage;
    this.subscriptionDetails = energyPortfolioLine.energySubscriptionDetails;

    const endDateMoment = moment(this.endDate);
    const startDateMoment = moment(this.startDate);

    this.unixTimeLeft = endDateMoment.diff(today).valueOf();
    this.yearsLeft = endDateMoment.diff(today, 'years');
    this.monthsLeft = endDateMoment.diff(today, 'months') - this.yearsLeft * 12;
    this.daysLeft = endDateMoment.diff(today, 'days');
    this.yearsDuration = endDateMoment.diff(startDateMoment, 'years');
    this.monthsDuration = endDateMoment.diff(startDateMoment, 'months') - this.yearsDuration * 12;
  }
}