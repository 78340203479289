import React, { useCallback, useEffect, useState } from "react";

import { AutodeskMap, IAutodeskFloorChangeEvent, ModelUtils, IMapToolbar, Popup } from "@beyondeyes/shared";
import VenueProvider from "providers/venueProvider";
import AppEventHub, { AppEvents } from "utils/appEventHub";
import { IAutodeskMapWrapperProps } from "autodeskMap/interfaces/IAutodeskMapWrapperProps";
import { IAutodeskMapWrapperState } from "autodeskMap/interfaces/IAutodeskMapWrapperState";
import CoreSpace from "models/coreSpace";

const mapBackgroundColor: { red: number; green: number; blue: number; gradientRed?: number; gradientGreen?: number; gradientBlue?: number } = {
	red: 247,
	green: 244,
	blue: 240,
};

const AutodeskMapWrapper: React.FC<IAutodeskMapWrapperProps> = (props) => {
	const [status, setStatus] = useState<IAutodeskMapWrapperState>({});
	const { onFloorChangedAsync, onPlaceClickAsync } = props;
	const [floorChangeEvent, setFloorChangeEvent] = useState<IAutodeskFloorChangeEvent>();
	const [showMap, setShowMap] = useState<boolean>();

	const { onMapLoadFailure } = props;

	const onFloorChangedWrapperAsync = useCallback(
		async (event: IAutodeskFloorChangeEvent): Promise<void> => {
			setFloorChangeEvent(event);
			const floorSpaces = await props.mapService.getFloorSpacesAsync(event.floor);
			const floorSpacesDictionary = Object.assign({}, ...floorSpaces.map((space) => ({ [space.id]: space })));
			const availableSpacesOnFloor = await ModelUtils.getAllSpacesFromViewModelAsync(event.model, floorSpacesDictionary);
			const renderedSpaces = availableSpacesOnFloor.filter(s => s.beSpace && !(s.beSpace as CoreSpace).isPOI);
			const pointsOfInterest = availableSpacesOnFloor.filter(s => s.beSpace && (s.beSpace as CoreSpace).isPOI);

			onFloorChangedAsync({
				renderedSpaces: renderedSpaces,
				pointsOfInterest: pointsOfInterest,
				floor: event.floor,
			});
		},
		[props.mapService, onFloorChangedAsync]
	);

	useEffect(() => {
		const setVenueFloorsAsync = async (venueId: string): Promise<void> => {
			const allFloors = await props.mapService.getMapFloorsAsync();
			const newAccessToken = await props.mapService.getAccessTokenAsync();

			if (!allFloors || allFloors.length === 0) {
				if (onMapLoadFailure) {
					onMapLoadFailure();
				}
				setShowMap(false);
				return;
			}

			setShowMap(true);

			setStatus({
				venueId: venueId,
				floors: allFloors,
				proxyEndpoint: props.mapService.proxyEndpoint,
				accessToken: newAccessToken,
			});
		};

		const onVenueChange = async (): Promise<void> => {
			const venueId = VenueProvider.getActiveVenue()?.id ?? "";

			await setVenueFloorsAsync(venueId);
		};

		AppEventHub.on(AppEvents.BuildingSelected, onVenueChange);
		onVenueChange();

		return (): void => {
			AppEventHub.off(AppEvents.BuildingSelected, onVenueChange);
		};
	}, [props.mapService, onMapLoadFailure]);

	const getStartFloorLevel = (): number | undefined => {
		if (props.startWithFirstFloor) {
			if (!status.floors || status.floors.length === 0) {
				return 0;
			}

			return status.floors.reduce((prev, curr) => (prev.level < curr.level ? prev : curr)).level;
		}
		return props.startFloorLevel;
	};

	return (
		<>
			{showMap && <AutodeskMap
				id="autodesk-map"
				proxyEndpoint={status.proxyEndpoint}
				accessToken={status.accessToken}
				venueId={status.venueId}
				floors={status.floors}
				backgroundColor={mapBackgroundColor}
				markers={props.markers}
				onFloorChangedAsync={onFloorChangedWrapperAsync}
				onBeforeFloorChangeAsync={props.onBeforeFloorChangeAsync}
				onClickAsync={props.onClickAsync}
				onPlaceClickAsync={onPlaceClickAsync}
				onRightClickAsync={props.onRightClickAsync}
				zoom={props.zoom}
				spaces={props.spaces}
				startFloorLevel={getStartFloorLevel() ?? 0}
				toolbar={props.toolbar ?? defaultToolbar}
				selectedSpaces={props.selectedSpaces}
				currentFloorLevel={props.currentFloorLevel}
				maxNumberOfFloorButtons={props.maxNumberOfFloorButtons ?? 7}
				cacheVenueFloors={true}
				hideOverlappingLabels={true}
			/>}
			{floorChangeEvent && props.popupContent && <Popup viewer={floorChangeEvent.viewer} showPopup={props.showPopup ?? false} content={props.popupContent}></Popup>}
		</>
	);
};

export default AutodeskMapWrapper;

export const defaultToolbar: IMapToolbar = {
	showCameraActionsButton: true,
	showFullScreenToggleButton: false,
	showHomeButton: false,
	showPanButton: false,
	showRotateButtons: true,
	showForceLabelsButton: true,
	showLabelsOnZoomButton: true
};
