import * as React from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import "./heatmapPage.scss";

import IInsightsOverviewProps from "../interfaces/IInsightsOverviewProps";
import DatePickerDeluxe from "../../energymanagement/components/datePicker/datePickerDeluxe";
import DateScroller from "@/components/datePicker/dateScroller";
import { IDatePickerTimeUnit } from "../../../interfaces/IDatePickerTimeUnit";
import HeatmapLegend from "../components/heatmapLegend";
import AbnormalValuesOverview from "../components/abnormalValuesOverview";
import AbnormalSpaceValue from "../components/models/abnormalSpaceValue";
import ThresholdSettingsModal from "../components/thresholdSettingsModal";
import { ThresholdType } from "../components/enums/thresholdType";
import { ThresholdSubtype } from "../components/enums/thresholdSubtype";
import { IAutodeskMapService, ISelectorEvent, IFloorChangeEvent, ISpaceOnMap, IVector } from "@beyondeyes/shared";

import DataInsightsService from "@/services/dataInsightsService";
import UserContextService from "@/services/userContextService";
import VenueProvider from "@/providers/venueProvider";
import LanguageProvider from "@/providers/languageProvider";
import MultiAssetMotionValue from "@/models/multiAssetMotionValue";
import SelectBoxUtils from "@/utils/selectBoxUtils";
import { withTelemetry } from "@/services/telemetryService";

import AutodeskMap from "autodeskMap/components/autodeskMapWrapper";
import CoreSpaceService from "@/services/coreSpaceService";
import CoreSpace from "@/models/coreSpace";
import RealEstateUtilizationPage from "../realEstateUtilizationPage";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";
import translations from "@/translations/mapper";
import DashboardMapService from "components/dashboardMap/interfaces/DashboardMapService";
import { DashboardMapOptions } from "components/dashboardMap/interfaces/options";
import IHeatmapPageState from "../interfaces/IHeatmapPageState";
import HeatmapStyler from "@/utils/stylers/heatmapStyler";
import AbnormalitiesChecker from "@/utils/stylers/abnormalitiesChecker";
import { SpaceUtils } from "utils/spaceUtils";
import { HeatmapPopup } from "pages/map/components/mapPopup";

class HeatmapPage extends React.Component<IInsightsOverviewProps, IHeatmapPageState> {
	private readonly heatmapStyler: HeatmapStyler;
	private readonly abnormalitiesChecker: AbnormalitiesChecker;

	private readonly dataInsightsService: DataInsightsService;
	private readonly userContextService: UserContextService = new UserContextService();
	private readonly coreSpaceService: CoreSpaceService;
	private readonly mapService: IAutodeskMapService;
	private spacesOnMap: ISpaceOnMap[] = [];

	public constructor(props: IInsightsOverviewProps) {
		super(props);

		this.heatmapStyler = new HeatmapStyler();
		this.abnormalitiesChecker = new AbnormalitiesChecker();
		this.mapService = new DashboardMapService(new DashboardMapOptions());

		this.dataInsightsService = new DataInsightsService();
		this.coreSpaceService = new CoreSpaceService();

		const endDate = new Date();
		const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
		endDate.setHours(23, 59, 59, 999);

		const state: IHeatmapPageState = {
			loading: true,
			activeTab: "map",
			endDate: endDate,
			startDate: startDate,
			selectedTimeUnit: "month",
			exceptionsEnabled: false,
			exceptionLowerbound: 3,
			exceptionUpperbound: 50,
			thresholdSubtype: ThresholdSubtype.occupancy,
			motionData: [],
			showPopup: false,
		};

		this.state = state;

		this.onDateChange = this.onDateChange.bind(this);
		this.setAllData = this.setAllData.bind(this);
		this.setExceptions = this.setExceptions.bind(this);
		this.updateThresholds = this.updateThresholds.bind(this);
		this.getExceptionBoundsAsync = this.getExceptionBoundsAsync.bind(this);

		this.onFloorChangeAsync = this.onFloorChangeAsync.bind(this);
		this.onPlaceClickAsync = this.onPlaceClickAsync.bind(this);
		this.updateAssetDataAsync = this.updateAssetDataAsync.bind(this);
		this.showPopup = this.showPopup.bind(this);
		this.onBeforeFloorChangeAsync = this.onBeforeFloorChangeAsync.bind(this);
	}

	public async componentDidMount(): Promise<void> {
		let activeVenue = VenueProvider.getActiveVenue();

		// Ensure a venue is selected (since this component mounts before app.tsx does).
		if (!activeVenue) {
			const venues = await this.coreSpaceService.getVenues();
			activeVenue = venues[0];
			VenueProvider.saveActiveVenue(activeVenue);
		}

		await this.getExceptionBoundsAsync();

		this.setState({
			loading: false,
		});
	}

	private async getExceptionBoundsAsync(): Promise<void> {
		const userContext = await this.userContextService.getUserContextAsync();
		const thresholds = userContext.getGraphThresholdsBySubtype(ThresholdSubtype[ThresholdSubtype.occupancy]);

		if (thresholds) {
			const lowerBound = thresholds.min ? thresholds.min : 3;
			const upperBound = thresholds.max ? thresholds.max : 50;

			this.heatmapStyler.SetExceptionBounds(lowerBound, upperBound);
			this.abnormalitiesChecker.SetExceptionBounds(lowerBound, upperBound);
			this.setState({
				exceptionLowerbound: lowerBound,
				exceptionUpperbound: upperBound,
			});
		}
	}

	private async onFloorChangeAsync(eventContext: IFloorChangeEvent): Promise<void> {
		this.spacesOnMap = eventContext.renderedSpaces;
		this.updateAssetDataAsync();
	}

	private async onBeforeFloorChangeAsync(): Promise<void> {
		this.setState({
			showPopup: false
		});
	}

	private async onPlaceClickAsync(eventContext: ISelectorEvent): Promise<void> {
		const space = eventContext.space?.beSpace;

		if (!space) {
			this.setState({ showPopup: false });
			return;
		}

		const mapPopup = new HeatmapPopup(
			space as CoreSpace,
			this.state.motionData,
			() => this.setState({ showPopup: false })
		);

		this.showPopup(eventContext.space!.position, mapPopup.getHtmlToRender());
	}

	private showPopup(position: IVector, content: JSX.Element): void {
		this.setState({
			popupContent: {
				content: content,
				position: { ...position },
			},
			showPopup: true,
		});
	}

	private setAllData(): void {
		if (!this.state.exceptionsEnabled) {
			return;
		}

		this.heatmapStyler.SetOnlyShowExceptions(false);

		this.setState({
			exceptionsEnabled: false,
			spaces: this.getSpacesWithStyle,
		});
	}

	private setExceptions(): void {
		if (this.state.exceptionsEnabled) {
			return;
		}

		this.heatmapStyler.SetOnlyShowExceptions(true);

		this.setState({
			exceptionsEnabled: true,
			spaces: this.getSpacesWithStyle,
		});
	}

	private updateThresholds(subtype: string, thresholdMin?: number, thresholdMax?: number): void {
		const newThresholdMin = thresholdMin !== undefined ? thresholdMin : this.state.exceptionLowerbound;
		const newThresholdMax = thresholdMax !== undefined ? thresholdMax : this.state.exceptionUpperbound;
		this.setState({
			exceptionLowerbound: newThresholdMin,
			exceptionUpperbound: newThresholdMax,
		});

		this.heatmapStyler.SetExceptionBounds(newThresholdMin, newThresholdMax);
		this.abnormalitiesChecker.SetExceptionBounds(newThresholdMin, newThresholdMax);
		this.setState({
			spaces: this.getSpacesWithStyle,
		});
	}

	private onDateChange(timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date): void {
		this.setState(
			{
				selectedTimeUnit: timeUnit,
				startDate: startDate,
				endDate: endDate,
			},
			this.updateAssetDataAsync
		);
	}

	private async updateAssetDataAsync(): Promise<void> {
		this.setState({
			loading: true,
		});

		const spaceIds = this.spacesOnMap.map((space) => space.beSpace!.id);

		let allAssetData: MultiAssetMotionValue[][] = [];
		switch (this.state.selectedTimeUnit) {
			case "month":
				allAssetData = await this.dataInsightsService.getMonthlyMultiAssetMotionData(spaceIds, this.state.startDate, this.state.endDate);
				break;
			case "week":
				allAssetData = await this.dataInsightsService.getWeeklyMultiAssetMotionData(spaceIds, this.state.startDate, this.state.endDate);
				break;
			case "day":
				allAssetData = await this.dataInsightsService.getDailyMultiAssetMotionData(spaceIds, this.state.startDate, this.state.endDate);
				break;
			default:
				break;
		}

		this.heatmapStyler.SetAllAssetData(allAssetData);
		this.abnormalitiesChecker.SetAllAssetData(allAssetData);
		this.setState({
			motionData: allAssetData,
			loading: false,
			spaces: this.getSpacesWithStyle,
		});
	}

	private get getSpacesWithStyle(): ISpaceOnMap[] {
		return SpaceUtils.getSpacesWithStyle(this.spacesOnMap, this.heatmapStyler);
	}

	private get abnormalitiesToShow(): AbnormalSpaceValue[] {
		return this.abnormalitiesChecker.checkForAbnormalities(this.spacesOnMap ?? []);
	}

	public render(): JSX.Element {
		const venue = VenueProvider.getActiveVenue();
		const venueName = venue?.name ?? "";

		return (
			<RealEstateUtilizationPage>
				<div id="map-insights-heatmap" className="main-content map">
					<div className="main-content-header">
						<div className="row">
							<div className="col-sm">
								<h1>{venueName}</h1>
							</div>
						</div>
						<div className="row input-selection">
							<div className="col-sm-12 d-flex">
								<div className="input-field-l mr-2">
									<Select
										options={[
											{ value: "Bezetting", label: LanguageProvider.getTranslation(translations.pages.heatmap.occupancy) },
										]}
										isClearable={false}
										isMulti={false}
										value={{ value: "Bezetting", label: LanguageProvider.getTranslation(translations.pages.heatmap.occupancy) }}
										styles={SelectBoxUtils.getDefaultSelectStyles(40)}
									/>
								</div>
								<div className="d-flex col-sm-4 pl-0">
									<div className="d-inline-flex">
										<DatePickerDeluxe
											defaultDateUnit={"month"}
											onDateChange={this.onDateChange}
											newStartDate={this.state.startDate}
											newEndDate={this.state.endDate}
											compactView={true}
											newTimeUnitType={this.state.selectedTimeUnit}
											disableYearPicker={true}
										/>
									</div>
									<div className="d-inline-flex pl-4 date-scroller-wrapper">
										<DateScroller
											selectedTimeUnit={this.state.selectedTimeUnit}
											startDate={this.state.startDate}
											onDateChange={this.onDateChange}
										/>
									</div>
								</div>
								<button
									className={"btn ml-auto mr-0 btn-data border-right-0 " + (this.state.exceptionsEnabled ? "" : "selected")}
									onClick={this.setAllData}
								>
									{LanguageProvider.getTranslation(`pages.heatmap.all_data`)}
								</button>
								<button className={"btn btn-data " + (this.state.exceptionsEnabled ? "selected" : "")} onClick={this.setExceptions}>
									{LanguageProvider.getTranslation(`pages.heatmap.abnormal_values`)}
								</button>
							</div>
						</div>
					</div>

					<div className="main-content-body">
						<div className="map" id="heatmap-map-container">
							<AutodeskMap
								onFloorChangedAsync={this.onFloorChangeAsync}
								onPlaceClickAsync={this.onPlaceClickAsync}
								mapService={this.mapService}
								spaces={this.state.spaces}
								startWithFirstFloor={true}
								showPopup={this.state.showPopup}
								popupContent={this.state.popupContent}
								onBeforeFloorChangeAsync={this.onBeforeFloorChangeAsync}
								maxNumberOfFloorButtons={6}
							/>

							<HeatmapLegend thresholdLowerbound={this.state.exceptionLowerbound} thresholdUpperbound={this.state.exceptionUpperbound} />
						</div>

						{this.state.loading && (
							<div id="map-loader">
								<div className="w-100 h-100">
									<div className="w-100 h-100 map-margin-correction d-flex justify-content-center">
										<CenteredPageLoader loading={this.state.loading} />
									</div>
								</div>
							</div>
						)}

						<div className="row mt-3">
							<div className="col-sm">
								<AbnormalValuesOverview abnormalities={this.abnormalitiesToShow} loading={this.state.loading} />
							</div>
						</div>

						<ThresholdSettingsModal
							thresholdSubtype={ThresholdSubtype[this.state.thresholdSubtype]}
							updateCallback={this.updateThresholds}
							thresholdType={ThresholdType[ThresholdType.heatmap]}
						/>
					</div>
				</div>
			</RealEstateUtilizationPage>
		);
	}
}

export default withTranslation()(withTelemetry(HeatmapPage, "HeatmapPage"));
