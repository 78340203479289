import VenueContextService from "./venueContextService";
import IDayTimeNotificationSettings from "../interfaces/IDayTimeNotificationSettings";
import IVenueSetting from "../interfaces/IVenueSetting";

export default class DayTimeNotificationSettingsService {
    private readonly venueContextService: VenueContextService;
    private static readonly prefix: string = "datathreshold_sendnotification_";
    private static readonly daysSetting: string = "days";
    private static readonly startTimeSetting: string = "starttime";
    private static readonly endTimeSetting: string = "endtime";

    private static readonly defaultWeekdays: string[] = ["7"];
    private static readonly defaultStartTime: string = "0:00";
    private static readonly defaultEndTime: string = "23:59";

    public constructor() {
        this.venueContextService = new VenueContextService();
    }

    public async getDayTimeNotificationSettingsAsync(skipCacheCheck?: boolean): Promise<IDayTimeNotificationSettings> {
        const allSettings = await this.venueContextService.getVenueContext(skipCacheCheck);
        const dayTimeNotificationSettings = allSettings.filter(s => s.settingIdentifier.startsWith(DayTimeNotificationSettingsService.prefix));

        const settings: IDayTimeNotificationSettings = {
            weekdays: DayTimeNotificationSettingsService.defaultWeekdays,
            starttime: DayTimeNotificationSettingsService.defaultStartTime,
            endtime: DayTimeNotificationSettingsService.defaultEndTime
        };

        dayTimeNotificationSettings.forEach(setting => {
            if (setting.settingIdentifier.endsWith(DayTimeNotificationSettingsService.startTimeSetting)) {
                settings.starttime = setting.value;
            }

            if (setting.settingIdentifier.endsWith(DayTimeNotificationSettingsService.endTimeSetting)) {
                settings.endtime = setting.value;
            }

            if (setting.settingIdentifier.endsWith(DayTimeNotificationSettingsService.daysSetting)) {
                settings.weekdays = setting.value.split(',');
            }
        });

        return settings;
    }

    public async upsertDayTimeNotificationSettings(startTime: string, endTime: string, weekDays: string[]): Promise<Response> {
        const settings: IVenueSetting[] = [];

        const startSetting = {
            value: startTime === undefined ? DayTimeNotificationSettingsService.defaultStartTime : startTime,
            settingIdentifier: DayTimeNotificationSettingsService.prefix + DayTimeNotificationSettingsService.startTimeSetting
        };

        settings.push(startSetting);

        const endSetting = {
            value: endTime === undefined ? DayTimeNotificationSettingsService.defaultEndTime : endTime,
            settingIdentifier: DayTimeNotificationSettingsService.prefix + DayTimeNotificationSettingsService.endTimeSetting
        };

        settings.push(endSetting);

        const weekdaysSetting = {
            value: weekDays === undefined ? DayTimeNotificationSettingsService.defaultWeekdays.join(',') : weekDays.join(','),
            settingIdentifier: DayTimeNotificationSettingsService.prefix + DayTimeNotificationSettingsService.daysSetting
        };

        settings.push(weekdaysSetting);

        return await this.venueContextService.batchUpsertVenueSettings(settings);
    }
}