import IBaseGraphProps from "./interfaces/IBaseGraphProps";
import { withTranslation } from 'react-i18next';
import { Line } from "react-chartjs-2";
import GraphLegend from './graphLegend';
import BaseGraph from './baseGraph';
import CenteredPageLoader from "./../loaders/centeredPageLoader";

class LineGraph extends BaseGraph {

    public constructor(props: IBaseGraphProps) {
        super(props);
        this.chartCallback = this.chartCallback.bind(this);
    }

    private chartCallback(ref: Line): void {
        if (!ref) {
            return;
        }

        this.chartInstance = ref.chartInstance;
        this.setState({
            graphTitle: this.props.title,
            datasets: ref.chartInstance.data.datasets
        });
    }

    public render(): JSX.Element {
        return (
            <div className="line-graph-container">
                {this.props.loading && <div id="graph-loader">
                    <div className="w-100 h-75 position-absolute">
                        <div className="w-100 h-100 map-margin-correction d-flex justify-content-center">
                            <CenteredPageLoader loading={this.props.loading ?? false} />
                        </div>
                    </div>
                </div>}
                <Line
                    data={this.props.data}
                    width={this.props.width}
                    height={this.props.height}
                    ref={this.chartCallback}
                    options={this.getChartOptions()} />
                <div className="row">
                    <div className="col-sm-12 d-flex pr-4">
                        <div className="mr-auto">
                            {this.props.showLegend &&
                                <GraphLegend
                                    graphTitle={this.state.graphTitle}
                                    datasets={this.props.legendFilter ? this.state.datasets?.filter(this.props.legendFilter) : this.state.datasets}
                                    onLegendItemClick={this.onLegendItemClick} />}
                        </div>
                        {this.props.renderExportButtons && this.props.renderExportButtons()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(LineGraph);