import AppEventHub, { AppEvents } from "../utils/appEventHub";

import { ApplicationConfig } from "../config";
import CoreSpace from "../models/coreSpace";
import ICoreSpace from "../interfaces/ICoreSpace";
import ExternalConnectionType from "@/enums/externalConnectionType";

export default class VenueProvider {
    private static venueStorageKey: string = `bs.${ApplicationConfig.environment}.active.corevenue`;

    public static saveActiveVenue(venue: CoreSpace): void {
        if (!venue) {
            return;
        }

        localStorage.setItem(VenueProvider.venueStorageKey, JSON.stringify(venue));
        AppEventHub.emit(AppEvents.BuildingSelected);
    }

    public static getActiveVenue(): CoreSpace | undefined {
        const json = localStorage.getItem(VenueProvider.venueStorageKey);
        if (!json || json.length <= 0) {
            return undefined;
        }

        const venue = JSON.parse(json) as ICoreSpace;
        return new CoreSpace(venue);
    }

    public static clear(): void {
        localStorage.removeItem(VenueProvider.venueStorageKey);
    }

    public static externalConnectionTypeIsOfKind(type: ExternalConnectionType): boolean {
        const typeAsStringValue = ExternalConnectionType[type].toString();
        const found = this.getActiveVenue()?.externalSystemsConnectionType?.split(";").includes(typeAsStringValue) ?? false;
        return found;
    }
}