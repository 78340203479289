export const AIEvents = {
    general: {
        question: {
            open: "OpenedQuestionModal",
            sent: "SentQuestion"
        }
    },
    motionInsights: {
        startDateChange: "StartDateChanged",
        endDateChange: "EndDateChanged",
        setFloor: "FloorSelectionChanged",
        setAsset: "AssetSelectionChanged",
        map: {
            open: "OpenedMap",
            close: "ClosedMap",
            click: "ClickedAssetOnMap",
        }
    }
};