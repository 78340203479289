import React from "react";
import { ChartOptions } from "chart.js";
import INoShowAveragesHoursProps from "./interfaces/INoShowAveragesHoursProps";

import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";
import IBeSenseChartData from "@/components/graphs/interfaces/IBeSenseChartData";
import moment from "moment";
import IHistoricReservationsGroup from "../interfaces/IHistoricReservationsGroup";

import BarGraph from "@/components/graphs/barGraph";

import VenueProvider from "@/providers/venueProvider";
import { Context } from "chartjs-plugin-datalabels";
import Colors from "@/styles/colors";

export default class NoShowAveragesHours extends React.Component<INoShowAveragesHoursProps> {

    public constructor(props: INoShowAveragesHoursProps) {
        super(props);
    }

    private getNoShowHoursData(openingHour: number, closingHour: number): IBeSenseChartData {

        const hourNumbers = Array.from({ length: (closingHour - openingHour + 1) }, (v, k) => k + openingHour);
        const hourLabels = hourNumbers.map(n => n.toString());

        let hourData: number[] = [];
        if (this.props.historicReservations.length === 0) {
            hourData = hourLabels.map(h => 0);
        }

        else {
            const noShows = this.props.historicReservations.filter(r => r.isNoShow);
            const groupedReservations = noShows.reduce((workspaceReservations, reservation) => {
                const startMoment = (moment(reservation.reservationStart));
                const key = startMoment.hour().toString();
                let workspace = workspaceReservations.find(r => r.key === key);
                if (workspace === undefined) {
                    workspace = {
                        key: key,
                        historicReservations: [reservation]
                    };
                    workspaceReservations.push(workspace);
                }
                else {
                    workspace.historicReservations.push(reservation);
                }

                return workspaceReservations;
            }, [] as IHistoricReservationsGroup[]);

            const clusters = groupedReservations.map(g => {
                const AveragePerHour = Math.round((g.historicReservations.length / this.props.totalOpenDays) * 100) / 100;
                return {
                    hour: g.key,
                    averagePerHour: AveragePerHour
                };
            });

            hourData = hourLabels.map(h => {
                let hourAverage = 0;
                const data = clusters.find(c => c.hour === h);
                if (data) {
                    hourAverage = data.averagePerHour;
                }
                return hourAverage;
            });
        }

        const noShowHoursData: IBeSenseChartData = {
            labels: hourLabels,
            datasets: [
                {
                    label: "No-shows",
                    backgroundColor: Colors.shakespeare_blue,
                    data: hourData
                }
            ]
        };

        return noShowHoursData;
    }

    private getNoShowTimesOptions(totalOpenHours: number, displayTooltip: boolean): ChartOptions {
        const noShowTimesOptions: ChartOptions = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        maxTicksLimit: Math.round(totalOpenHours / 2)
                    },
                    gridLines: {
                        drawBorder: false,
                        display: false
                    },
                    stacked: false
                }],
                yAxes: [{
                    id: 'bar',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        drawBorder: false,
                        zeroLineColor: Colors.alto_grey,
                        color: Colors.white
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true
                    },
                    stacked: false
                }]
            },
            maintainAspectRatio: true,
            legend: {
                display: false
            },
            plugins: {
                datalabels: {
                    display: (context: Context): boolean => {
                        return context.dataset.data !== undefined;
                    },
                    anchor: 'end',
                    color: Colors.blue_grey,
                    align: 'top',
                    offset: 1,
                    font: {
                        size: 8
                    },
                    formatter: (value: any, context: Context): any => {
                        if (this.props.historicReservations.length === 0) {
                            return 0;
                        }
                        else {
                            return value;
                        }

                    }
                }
            },
            layout: {
                padding: {
                    top: 20
                }
            },
            title: {
                display: false,
                text: "no-show-hours"
            },
            tooltips: {
                enabled: displayTooltip
            }
        };

        return noShowTimesOptions;
    }

    public render(): JSX.Element {
        const currentVenue = VenueProvider.getActiveVenue();
        let openingHour = 0;
        let closingHour = 0;
        if (currentVenue) {
            openingHour = currentVenue.openingHour;
            closingHour = currentVenue.closingHour;
        }

        const displayTooltip = this.props.historicReservations && this.props.historicReservations.length > 0;

        return (
            <div className="no-show-averages-component" id="no-show-top-times">
                <div className="no-show-graph">
                    <BarGraph data={this.getNoShowHoursData(openingHour, closingHour)} options={this.getNoShowTimesOptions(closingHour - openingHour, displayTooltip)} />
                </div>
                <div className="no-show-averages-title">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.noshowtimes)}</div>
                <div className="no-show-averages-subtitle">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.averagenoshows)}</div>
            </div>
        );
    }

}