import moment from 'moment';
import { ApplicationConfig } from "../config";
import BaseService from "../services/baseService";
import AuthService from "../services/authService";
import VenueProvider from "../providers/venueProvider";
import IEnergyManagementOverview from "../interfaces/energy/IEnergyManagementOverview";
import IEnergyThisYearVenueSavings from "../interfaces/energy/IEnergyThisYearVenueSavings";
import IEnergyPortfolioLine from "../interfaces/energy/IEnergyPortfolioLine";
import IQuarterlyEnergyValue from "../interfaces/energy/IQuarterlyEnergyValue";
import IDailyCompensatedEnergieMissieValue from "../interfaces/energy/IDailyCompensatedEnergieMissieValue";
import IDailyEnergyValue from "../interfaces/energy/IDailyEnergyValue";
import IMonthlyEnergyValue from "../interfaces/energy/IMonthlyEnergyValue";
import IQuarterlyEnergieMissieValue from "../interfaces/energy/IQuarterlyEnergieMissieValue";
import IMonthlyCompensatedEnergieMissieValue from "../interfaces/energy/IMonthlyCompensatedEnergieMissieValue";
import IHourlyEnergieMissieValue from "../interfaces/energy/IHourlyEnergieMissieValue";
import IEnergyVenueDetails from '../interfaces/energy/IEnergyVenueDetails';
import IDailyEnergySavings from '../interfaces/energy/IDailyEnergySavings';
import IMonthlyEnergySavings from '../interfaces/energy/IMonthlyEnergySavings';
import IRecentWeeksEnergieMissieValue from '../interfaces/energy/IRecentWeeksEnergieMissieValue';
import IRecentWeeksEnergyValue from '../interfaces/energy/IRecentWeeksEnergyValue';
import IDailyNormalisedEnergieMissieValue from "../interfaces/energy/IDailyNormalisedEnergieMissieValue";
import IMonthlyNormalisedEnergieMissieValue from "../interfaces/energy/IMonthlyNormalisedEnergieMissieValue";
import IHolidayQuarterlyEnergy from '../interfaces/energy/IHolidayQuarterlyEnergy';
import IHolidayHourlyEnergy from '../interfaces/energy/IHolidayHourlyEnergy';
import IHourlyTemperatureData from "../interfaces/energy/IHourlyTemperatureData";
import IDailyTemperatureData from '../interfaces/energy/IDailyTemperatureData';
import ICoreDevice from 'interfaces/ICoreDevice';

export default class EnergyService extends BaseService {
    public constructor() {
        super(ApplicationConfig.endpoints.energyApi, new AuthService());
    }

    public async GetElectricMainMetersAsync(): Promise<ICoreDevice[]> {
        const query = `mainmeters/venues/${this.getVenueId()}/electric`;
        return this.get<ICoreDevice[]>(query);
    }

    public async GetGasMainMetersAsync(): Promise<ICoreDevice[]> {
        const query = `mainmeters/venues/${this.getVenueId()}/gas`;
        return this.get<ICoreDevice[]>(query);
    }

    public async GetManagementOverviewAsync(): Promise<IEnergyManagementOverview> {
        const query = `savings/customer/${this.getCustomerId()}/managementoverview`;
        return this.get<IEnergyManagementOverview>(query);
    }

    public async GetThisYearsTopVenueSavingsAsync(): Promise<IEnergyThisYearVenueSavings[]> {
        const query = `savings/customer/${this.getCustomerId()}/venues?top=5`;
        return this.get<IEnergyThisYearVenueSavings[]>(query);
    }

    public async GetPortfolioAsync(): Promise<IEnergyPortfolioLine[]> {
        const query = `savings/portfolio`;
        return this.get<IEnergyPortfolioLine[]>(query);
    }

    public async GetVenueDetailsAsync(): Promise<IEnergyVenueDetails> {
        const query = `savings/customer/${this.getCustomerId()}/venues/${this.getVenueId()}`;
        return this.get<IEnergyVenueDetails>(query);
    }

    public async GetHourlyTemperatureDataAsync(startDay: Date, endDay: Date, skipCacheCheck?: boolean): Promise<IHourlyTemperatureData[]> {
        const query = `temperature/venues/${this.getVenueId()}/hourly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IHourlyTemperatureData[]>(query, skipCacheCheck);
    }

    public async GetDailyTemperatureDataAsync(startDay: Date, endDay: Date, skipCacheCheck?: boolean): Promise<IDailyTemperatureData[]> {
        const query = `temperature/venues/${this.getVenueId()}/daily/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IDailyTemperatureData[]>(query, skipCacheCheck);
    }

    public async GetQuarterlyAssetEnergeyManagementDataAsync(startDay: Date, endDay: Date, assetIdentifiers: string[]): Promise<IQuarterlyEnergyValue[][]> {
        const body = {
            identifiers: assetIdentifiers
        };
        const query = `beenergydata/customer/${this.getCustomerId()}/assets/quarterly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.postWithResponse<IQuarterlyEnergyValue[][]>(query, body);
    }

    public async GetDailyAssetEnergyManagementDataAsync(startDay: Date, endDay: Date, assetIdentifiers: string[]): Promise<IDailyEnergyValue[][]> {
        const body = {
            identifiers: assetIdentifiers
        };
        const query = `beenergydata/customer/${this.getCustomerId()}/assets/daily/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.postWithResponse<IDailyEnergyValue[][]>(query, body);
    }

    public async GetMonthlyAssetEnergyManagementDataAsync(startDay: Date, endDay: Date, assetIdentifiers: string[]): Promise<IMonthlyEnergyValue[][]> {
        const body = {
            identifiers: assetIdentifiers
        };
        const query = `beenergydata/customer/${this.getCustomerId()}/assets/monthly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.postWithResponse<IMonthlyEnergyValue[][]>(query, body);
    }

    public async GetQuarterlyElectricVenueUsageDataAsync(startDay: Date, endDay: Date, meterId?: string): Promise<IQuarterlyEnergieMissieValue[]> {
        let query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/electric/quarterly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;

        if (meterId) {
            query = `${query}?meterId=${meterId}`;
        }

        return this.get<IQuarterlyEnergieMissieValue[]>(query);
    }

    public async GetHourlyElectricVenueUsageDataAsync(startDay: Date, endDay: Date, skipCacheCheck?: boolean): Promise<IHourlyEnergieMissieValue[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/electric/hourly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IHourlyEnergieMissieValue[]>(query, skipCacheCheck);
    }

    public async GetDailyElectricVenueUsageDataAsync(startDay: Date, endDay: Date, meterId?: string): Promise<IDailyCompensatedEnergieMissieValue[]> {
        let query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/electric/daily/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;

        if (meterId) {
            query = `${query}?meterId=${meterId}`;
        }

        return this.get<IDailyCompensatedEnergieMissieValue[]>(query);
    }

    public async GetMonthlyElectricVenueUsageDataAsync(startDay: Date, endDay: Date, meterId?: string): Promise<IMonthlyCompensatedEnergieMissieValue[]> {
        let query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/electric/monthly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;

        if (meterId) {
            query = `${query}?meterId=${meterId}`;
        }

        return this.get<IMonthlyCompensatedEnergieMissieValue[]>(query);
    }

    public async GetHourlyGasVenueUsageDataAsync(startDay: Date, endDay: Date, meterId?: string, skipCacheCheck?: boolean): Promise<IHourlyEnergieMissieValue[]> {
        let query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/gas/hourly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;

        if (meterId) {
            query = `${query}?meterId=${meterId}`;
        }

        return this.get<IHourlyEnergieMissieValue[]>(query, skipCacheCheck);
    }

    public async GetDailyGasVenueUsageDataAsync(startDay: Date, endDay: Date, meterId?: string): Promise<IDailyNormalisedEnergieMissieValue[]> {
        let query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/gas/daily/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;

        if (meterId) {
            query = `${query}?meterId=${meterId}`;
        }

        return this.get<IDailyNormalisedEnergieMissieValue[]>(query);
    }

    public async GetMonthlyGasVenueUsageDataAsync(startDay: Date, endDay: Date, meterId?: string): Promise<IMonthlyNormalisedEnergieMissieValue[]> {
        let query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/gas/monthly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;

        if (meterId) {
            query = `${query}?meterId=${meterId}`;
        }

        return this.get<IMonthlyNormalisedEnergieMissieValue[]>(query);
    }

    public async GetRecentGasUsageAsync(): Promise<IRecentWeeksEnergieMissieValue> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/gas/recentweeks/4`;
        return this.get<IRecentWeeksEnergieMissieValue>(query);
    }

    public async GetRecentElectricUsageAsync(): Promise<IRecentWeeksEnergieMissieValue> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/electric/recentweeks/4`;
        return this.get<IRecentWeeksEnergieMissieValue>(query);
    }

    public async GetRecentEnergyManagementUsageAsync(assetIdentifiers: string[]): Promise<IRecentWeeksEnergyValue[]> {
        const body = {
            identifiers: assetIdentifiers
        };
        const query = `beenergydata/customer/${this.getCustomerId()}/assets/recentweeks/4`;
        return this.postWithResponse<IRecentWeeksEnergyValue[]>(query, body);
    }

    public async GetDailyEnergySavings(customerId: string, venueId: string, startDay: Date, endDay: Date): Promise<IDailyEnergySavings[]> {
        const query = `energieMissieSavings/customer/${customerId}/venue/${venueId}/daily/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IDailyEnergySavings[]>(query);
    }

    public async GetMonthlyEnergySavings(customerId: string, venueId: string, startDay: Date, endDay: Date): Promise<IMonthlyEnergySavings[]> {
        const query = `energieMissieSavings/customer/${customerId}/venue/${venueId}/monthly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IMonthlyEnergySavings[]>(query);
    }

    public async GetHourlyHeatVenueUsageDataAsync(startDay: Date, endDay: Date, skipCacheCheck?: boolean): Promise<IHourlyEnergieMissieValue[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/heat/hourly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IHourlyEnergieMissieValue[]>(query, skipCacheCheck);
    }

    public async GetDailyHeatVenueUsageDataAsync(startDay: Date, endDay: Date): Promise<IDailyNormalisedEnergieMissieValue[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/heat/daily/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IDailyNormalisedEnergieMissieValue[]>(query);
    }

    public async GetMonthlyHeatVenueUsageDataAsync(startDay: Date, endDay: Date): Promise<IMonthlyNormalisedEnergieMissieValue[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/heat/monthly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IMonthlyNormalisedEnergieMissieValue[]>(query);
    }

    public async GetRecentHeatUsageAsync(): Promise<IRecentWeeksEnergieMissieValue> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/heat/recentweeks/4`;
        return this.get<IRecentWeeksEnergieMissieValue>(query);
    }

    public async GetHourlyColdVenueUsageDataAsync(startDay: Date, endDay: Date, skipCacheCheck?: boolean): Promise<IHourlyEnergieMissieValue[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/cold/hourly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IHourlyEnergieMissieValue[]>(query, skipCacheCheck);
    }

    public async GetDailyColdVenueUsageDataAsync(startDay: Date, endDay: Date): Promise<IDailyCompensatedEnergieMissieValue[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/cold/daily/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IDailyCompensatedEnergieMissieValue[]>(query);
    }

    public async GetMonthlyColdVenueUsageDataAsync(startDay: Date, endDay: Date): Promise<IMonthlyCompensatedEnergieMissieValue[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/cold/monthly/${this.formatDate(startDay)}/${this.formatDate(endDay)}`;
        return this.get<IMonthlyCompensatedEnergieMissieValue[]>(query);
    }

    public async GetRecentColdUsageAsync(): Promise<IRecentWeeksEnergieMissieValue> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/cold/recentweeks/4`;
        return this.get<IRecentWeeksEnergieMissieValue>(query);
    }

    public async GetElectricUsageHolidaysAsync(year: number): Promise<IHolidayQuarterlyEnergy[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/electric/holidays/${year}`;
        return this.get<IHolidayQuarterlyEnergy[]>(query);
    }

    public async GetGasUsageHolidaysAsync(year: number): Promise<IHolidayHourlyEnergy[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/gas/holidays/${year}`;
        return this.get<IHolidayHourlyEnergy[]>(query);
    }

    public async GetHeatUsageHolidaysAsync(year: number): Promise<IHolidayHourlyEnergy[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/heat/holidays/${year}`;
        return this.get<IHolidayHourlyEnergy[]>(query);
    }

    public async GetColdUsageHolidaysAsync(year: number): Promise<IHolidayHourlyEnergy[]> {
        const query = `energiemissiedata/customer/${this.getCustomerId()}/venue/${this.getVenueId()}/cold/holidays/${year}`;
        return this.get<IHolidayHourlyEnergy[]>(query);
    }

    private getCustomerId(): string {
        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        return venue.customerId;
    }

    private getVenueId(): string {
        const venue = VenueProvider.getActiveVenue();
        if (!venue) {
            throw new Error("No venue was selected.");
        }
        return venue.id;
    }

    private formatDate(date: Date): string {
        return moment(date).format("YYYY-MM-DD");
    }
}