import ClientSideCache from "../models/clientSideCache";
import AuthService from "./authService";

type CRUDOperation = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export default abstract class BaseService {
    public constructor(
        private resource: string,
        private authService?: AuthService
    ) { }

    public async get<T>(
        query: string,
        skipCacheCheck: boolean = false
    ): Promise<T> {
        const url = `${this.resource}/${query}`;

        if (!skipCacheCheck) {
            const cachedResponse = ClientSideCache.TryGetResponseFromCache(url);
            if (cachedResponse !== undefined) {
                return cachedResponse;
            }
        }

        return this.execute(query, "GET").then(r =>
            r.json().then(result => {
                if (!skipCacheCheck) {
                    ClientSideCache.AddReponseToCache(url, result);
                }

                return result;
            }).catch(_ => {
                throw new Error(r.status + " " + r.statusText);
            })
        );
    }

    public async post(query: string, data: any): Promise<Response> {
        return this.execute(query, "POST", data);
    }

    public async postWithResponse<T>(query: string, data: any): Promise<T> {
        return this.execute(query, "POST", data).then(r => r.json());
    }

    public async put(query: string, data: any): Promise<Response> {
        return this.execute(query, "PUT", data);
    }

    public async patch(query: string, data: any): Promise<Response> {
        return this.execute(query, "PATCH", data);
    }

    public async delete(query: string): Promise<Response> {
        return this.execute(query, "DELETE");
    }

    private async execute(
        query: string,
        method: CRUDOperation,
        data?: any
    ): Promise<Response> {
        const url = `${this.resource}/${query}`;
        let request: RequestInit = {
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            method
        };

        if (data) {
            request.body = JSON.stringify(data);
        }
        if (this.authService) {
            request = await this.authService.authenticateRequest(request);
        }
        return await fetch(url, request).then(response => {
            if (!response.ok) {
                console.error(response.statusText);
            }

            return response;
        });
    }
}
