import CoreSpaceValues from "../../../enums/coreSpaceValues";
import CoreSpace from "@/models/coreSpace";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import CloseButton from "@/images/Close2.svg";

import "./mapPopup.scss";
import MultiAssetMotionValue from "models/multiAssetMotionValue";
import CoreSpaceTypes from "@/enums/coreSpaceTypes";

interface IMapPopupOptions {
    showCleaningTime: boolean;
    showComfortValues: boolean;
    showCleaningStatus: boolean;
    showOccupancyTile: boolean;
}

export class HeatmapPopup {
    public constructor(
        private space: CoreSpace,
        private allAssetData: MultiAssetMotionValue[][],
        private onClose: () => void) {
    }

    public getHtmlToRender(): JSX.Element {

        let average = 0;

        const assetData = this.allAssetData.find(ar => ar.find(v => v.partitionKey === this.space.id) !== undefined);
        if (assetData === undefined) {
            console.warn(`Failed to find asset data for space ${this.space.name}`);
        }
        else {
            average = assetData.reduce((p, c) => p + c.percentage, 0) / assetData.length;
        }

        const html: JSX.Element = (
            <div className="map-popup">

                <div className="map-popup-header d-flex justify-content-between">
                    <div>{this.space.name}</div>
                    <img src={CloseButton} alt="Close" onClick={this.onClose} />
                </div>

                <div className="map-popup-divider"></div>

                <div className="map-popup-body">
                    <div>{LanguageProvider.getTranslation(translations.pages.heatmap.occupation_percentage)}</div>
                    <div className="mt-1">
                        <span className="popup-text-emphasis">{Math.round(average)}</span> %
                    </div>
                </div>
            </div>
        );

        return html;
    }
}

export class MapPopup {
    public constructor(
        private space: CoreSpace,
        private popupOptions: IMapPopupOptions,
        private onClose: () => void) {
    }

    public getHtmlToRender(): JSX.Element {

        const html: JSX.Element = (
            <div className="map-popup">

                <div className="map-popup-header d-flex justify-content-between">
                    <div>{this.space.name}</div>
                    <img src={CloseButton} alt="Close" onClick={this.onClose} />
                </div>

                <div className="map-popup-divider"></div>

                <div className="map-popup-body">
                    {this.possiblyRenderNoDataMessage()}
                    {this.getOccupancyRow()}
                    {this.popupOptions.showCleaningStatus && this.getCleaningStatusRow()}
                    {this.popupOptions.showComfortValues && this.getComfortRow()}
                    {this.popupOptions.showCleaningTime && this.getCleaningRow()}
                </div>
            </div>
        );

        return html;
    }

    private possiblyRenderNoDataMessage(): JSX.Element {
        const values = [
            CoreSpaceValues.OccupancyStatus,
            CoreSpaceValues.Temperature,
            CoreSpaceValues.Humidity,
            CoreSpaceValues.CarbonDioxide
        ];

        if (!this.space.hasMotionDevices && !values.some(value => this.space.hasValue(value))) {
            return (<>
                {LanguageProvider.getTranslation(translations.mappopup.nodata)}
            </>);
        }
        else {
            return (<></>);
        }
    }

    private getOccupancyRow(): JSX.Element {

        if (!this.space.hasMotionDevices) {
            return (<></>);
        }

        let countTileClass = '';
        let message = '';

        const status = this.space.getActualValue(CoreSpaceValues.OccupancyStatus);

        if (!status) {
            countTileClass = 'green-tile';
            message = this.space.type === CoreSpaceTypes.Room ? translations.mappopup.roomisfree : translations.mappopup.workspaceisfree;
        }
        else if (this.space.isCoolingDown) {
            countTileClass = 'orange-tile';
            message = this.space.type === CoreSpaceTypes.Room ? translations.mappopup.roomispossiblyfree : translations.mappopup.workspaceispossiblyfree;
        }
        else if (status.toLowerCase() === 'unoccupied') {
            countTileClass = 'green-tile';
            message = this.space.type === CoreSpaceTypes.Room ? translations.mappopup.roomisfree : translations.mappopup.workspaceisfree;
        }
        else {
            countTileClass = 'red-tile';
            message = this.space.type === CoreSpaceTypes.Room ? translations.mappopup.roomisoccupied : translations.mappopup.workspaceisoccupied;
        }

        return (
            <div className="d-flex mt-3">
                {this.popupOptions.showOccupancyTile && <div className={`count-tile ${countTileClass} mr-1`}></div>}
                <div>{LanguageProvider.getTranslation(message)}</div>
            </div>
        )
    }

    private getCleaningStatusRow(): JSX.Element {
        const timeUsed = this.space.correctedUsedTimeInMinutes;
        const isPolluted = timeUsed > 0;

        return (<div className="mt-3">
            <div>{LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.status)}</div>
            <div className="popup-text-emphasis">
                {isPolluted ?
                    LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.used)
                    : LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.clean)}
            </div>
        </div>)
    }

    private getComfortRow(): JSX.Element {
        const comfortValues = [
            CoreSpaceValues.Temperature,
            CoreSpaceValues.Humidity,
            CoreSpaceValues.CarbonDioxide
        ];


        if (!comfortValues.some(value => this.space.hasValue(value))) {
            return (<></>);
        }

        const temperature = this.space.getActualValue(CoreSpaceValues.Temperature);
        const humidity = this.space.getActualValue(CoreSpaceValues.Humidity);
        const co2 = this.space.getActualValue(CoreSpaceValues.CarbonDioxide);

        return (
            <div className="d-flex mt-3 justify-content-between">
                {temperature && <div>
                    <div>{LanguageProvider.getTranslation(translations.mappopup.sensor.temperature.title)}</div>
                    <div className="mt-1">
                        <span className="popup-text-emphasis">{temperature}</span> {LanguageProvider.getTranslation(translations.mappopup.sensor.temperature.symbol)}
                    </div>
                </div>}

                {humidity && <div>
                    <div>{LanguageProvider.getTranslation(translations.mappopup.sensor.humidity.title)}</div>
                    <div className="mt-1">
                        <span className="popup-text-emphasis">{humidity}</span> {LanguageProvider.getTranslation(translations.mappopup.sensor.humidity.symbol)}
                    </div>
                </div>}

                {co2 && <div>
                    <div>{LanguageProvider.getTranslation(translations.mappopup.sensor.carbondioxide.title)}</div>
                    <div className="mt-1">
                        <span className="popup-text-emphasis">{co2}</span> {LanguageProvider.getTranslation(translations.mappopup.sensor.carbondioxide.symbol)}
                    </div>
                </div>}
            </div>
        )
    }

    private getCleaningRow(): JSX.Element {
        if (!this.space.hasMotionDevices) {
            return (<></>);
        }

        const lastCleanedTime = this.space.lastUsedTimeResetTime;
        const timeUsed = this.space.correctedUsedTimeInMinutes;
        const isPolluted = timeUsed > 0;

        if (!lastCleanedTime && !isPolluted) {
            return (<></>);
        }

        const minutesInDay = 60 * 24;
        const days = Math.floor(timeUsed / minutesInDay);
        const hours = Math.floor((timeUsed % minutesInDay) / 60);
        const minutes = timeUsed % 60;

        return (<div className="mt-3">
            <div>{LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.timeoccupied)}</div>
            <div className="d-flex mt-1">
                {days > 0 && <div className="mr-1">
                    <span className="popup-text-emphasis">
                        {days}&nbsp;
                    </span>
                    {days === 1 ?
                        LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.day)
                        : LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.days)}
                </div>}

                {hours > 0 && <div className="mr-1">
                    <span className="popup-text-emphasis">
                        {hours}&nbsp;
                    </span>
                    {hours === 1 ?
                        LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.hour)
                        : LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.hours)}
                </div>}

                <div>
                    <span className="popup-text-emphasis">
                        {minutes}&nbsp;
                    </span>
                    {minutes === 1 ?
                        LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.minute)
                        : LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.values.minutes)}
                </div>
            </div>

            {
                lastCleanedTime && <div className="mt-3">
                    <div>{LanguageProvider.getTranslation(translations.pages.pollutionmap.popup.lastcleaned)}</div>
                    <div className="popup-text-emphasis mt-1">{`${lastCleanedTime.toLocaleDateString()}`} {`${lastCleanedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</div>
                </div>
            }
        </div >);
    }
}