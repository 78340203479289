import { Component } from "react";
import FullPageLoader from "../../components/loaders/fullPageLoader";
import Teaser from '../../components/teaser/teaser';
import LanguageProvider from "../../providers/languageProvider";
import VenueProvider from "../../providers/venueProvider";
import SubscriptionValidationService from "../../services/subscriptionValidationService";
import TeaserImage from '../../images/bg_energie_management.jpg';
import AppEventHub, { AppEvents } from "../../utils/appEventHub";
import translations from "../../translations/mapper";

import State from './interfaces/iEnergyManagementPageState';
import Props from "./interfaces/iEnergyManagementPageProps";
import links from "../../utils/links";

export default class EnergyManagementPage extends Component<Props, State> {

    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: Props) {
        super(props);

        const state = {
            loading: true,
            displayContent: false
        };

        this.state = state;

        this.initializeAsync = this.initializeAsync.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeAsync);
        await this.initializeAsync();
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeAsync);
    }

    private async initializeAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();
        const hasSubscription = venue ?
            this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEEnergized"])
            : false;

        this.setState({
            displayContent: hasSubscription,
            loading: false
        });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}

                {!this.state.loading && <>
                    {!this.state.displayContent && <Teaser
                        imagePath={TeaserImage}
                        title={LanguageProvider.getTranslation(translations.pages.energy.teaser.title)}
                        subtitle={LanguageProvider.getTranslation(translations.pages.energy.teaser.subtitle)}
                        paragraph1={LanguageProvider.getTranslation(translations.pages.energy.teaser.paragraph1)}
                        paragraph2={LanguageProvider.getTranslation(translations.pages.energy.teaser.paragraph2)}
                        learnmoretext={LanguageProvider.getTranslation(translations.pages.energy.teaser.learnmore)}
                        buttonLink={links.external.beyondeyes.energymanagement}>
                    </Teaser>}
                    {this.state.displayContent && <>
                        {this.props.children}
                    </>}
                </>}
            </>
        );
    }
}