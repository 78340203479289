import AuthService from "@/services/authService";
import { IAutodeskMapService, IFloor } from "@beyondeyes/shared";
import VenueProvider from "providers/venueProvider";
import CoreSpaceService from "services/coreSpaceService";
import { DashboardMapOptions } from "./options";
import CoreSpace from "models/coreSpace";
import { ApplicationConfig } from "@/config";

export default class DashboardMapService implements IAutodeskMapService {
	public proxyEndpoint: string;
	private readonly options: DashboardMapOptions;
	private readonly coreSpaceService: CoreSpaceService;
	private readonly authenticator: AuthService;

	public constructor(options: DashboardMapOptions) {
		this.options = options;
		this.proxyEndpoint = options.proxyEndpoint;
		this.coreSpaceService = new CoreSpaceService();
		this.authenticator = new AuthService();
	}

	public async getAccessTokenAsync(): Promise<string> {
		const accessToken = await this.authenticator.getTokenAsync([ApplicationConfig.ad.scopes.dashboardApi]);
		return `Bearer ${accessToken}`;
	}

	public async getMapFloorsAsync(): Promise<IFloor[]> {
		const floorOptions = this.options.floorOptions;
		const activeVenue = VenueProvider.getActiveVenue();
		const floors = await this.coreSpaceService.getFloorsForVenue(activeVenue!.id, floorOptions.includes, floorOptions.skipCacheCheck);

		// TODO:remove filter when all floors will have a valid URN
		return floors
			.filter((floor) => floor.urn !== undefined)
			.map((validFloor) => {
				const floor = {
					id: validFloor.id,
					venueId: validFloor.venueId,
					urn: validFloor.urn,
					level: parseInt(validFloor.floorLevel),
				};
				return floor;
			});
	}

	public getFloorSpacesAsync(floor: IFloor): Promise<CoreSpace[]> {
		const spaceOptions = this.options.spaceOptions;
		return this.coreSpaceService.getSpacesForFloor(
			floor.venueId,
			floor.id,
			spaceOptions.types,
			spaceOptions.includes,
			undefined,
			spaceOptions.skipCacheCheck
		);
	}
}
