export class CsvExport {

    private static readonly NewlineCharacter: string = '\r\n';

    private readonly _fileName: string;

    private readonly _columns: CsvColumn[];

    private readonly _separator: string;

    public constructor(fileName: string, separator: string = ',') {
        this._fileName = fileName.endsWith('.csv') ? fileName : fileName + '.csv';
        this._separator = separator;
        this._columns = [];
    }

    public AddColumn(...columns: CsvColumn[]) : CsvExport {
        columns.forEach(c => this._columns.push(c));
        return this;
    }

    public ExecuteDownload(): void {
        const csvContent = this.constructCsvContent();
        const blob = new Blob([csvContent], {type: 'text/csv'});
        const blobUrl = URL.createObjectURL(blob);

        const element = document.createElement("a");
        element.href = blobUrl;
        element.download = this._fileName;;
        document.body.appendChild(element);
        element.click();
        element.remove();
    }

    public static stringifyWithAtLeastOneDecimal(data: number|null): string {
        if (data === null){
            return ''
        }

        if (Math.round(data) === data){
            return data.toFixed(1);
        }
        else {
            return data.toString();
        }
    }

    private constructCsvContent(): string {
        const rows: string[][] = [];
        const header = this._columns.map(c => c.header);
        rows.push(header);

        const maxColumnLength = Math.max(...this._columns.map(a => a.data.length));

        for (let i = 0; i < maxColumnLength; i++) {
            const row = this._columns.map(a => a.data[i] ?? '');
            rows.push(row);
        }

        return rows.map(r => r.join(this._separator)).join(CsvExport.NewlineCharacter);
    }

}

export interface CsvColumn {
    header: string;
    data: string[];
}