import * as React from 'react';
import moment, { Moment } from 'moment';

import IDayDatePickerDeluxeState from './interfaces/IDayDatePickerDeluxeState';
import WeekDayUtils from './utils/weekDayUtils';
import IDayDatePickerDeluxeProps from './interfaces/IDayDatePickerDeluxeProps';
import LanguageProvider from '@/providers/languageProvider';

import ArrowLeft from '@/images/Arrow Left.svg';
import ArrowRight from '@/images/Arrow Right.svg';

class DayDatePickerDeluxe extends React.Component<IDayDatePickerDeluxeProps, IDayDatePickerDeluxeState> {
    private currentTime: Moment = moment(new Date());
    private currentYear: number = this.currentTime.year();
    private currentMonth: number = this.currentTime.month();
    private currentWeek: number = WeekDayUtils.getFirstDayOfWeek(this.currentTime).week();
    private lastWeek: number = WeekDayUtils.getFirstDayOfWeek(this.currentTime.add(-7, 'day')).week();
    private currentDay: number = this.currentTime.date();

    private daysOfWeek: string[] = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    public constructor(props: IDayDatePickerDeluxeProps) {
        super(props);

        const state: IDayDatePickerDeluxeState = {
            displayedYear: this.currentYear,
            displayedMonth: this.currentMonth,
            selectedYear: this.currentYear,
            selectedMonth: this.currentMonth,
            selectedWeek: this.props.openOnLastWeek ? this.lastWeek : this.currentWeek,
            selectedDay: this.currentDay
        };
        this.state = state;

        this.renderRows = this.renderRows.bind(this);

        this.setSelectedDay = this.setSelectedDay.bind(this);
        this.setSelectedWeek = this.setSelectedWeek.bind(this);
        this.setSelectedWeekByDay = this.setSelectedWeekByDay.bind(this);

        this.increaseMonth = this.increaseMonth.bind(this);
        this.decreaseMonth = this.decreaseMonth.bind(this);

        this.renderMonthSelection = this.renderMonthSelection.bind(this);
        this.renderHeader = this.renderHeader.bind(this);

        this.createDatePickerTile = this.createDatePickerTile.bind(this);
        this.createWeekPickerTile = this.createWeekPickerTile.bind(this);
    }

    public componentWillReceiveProps(nextProps: IDayDatePickerDeluxeProps): void {
        if (nextProps.isActive !== this.props.isActive) {
            if (nextProps.isActive) {
                if (this.isWeekSelectionEnabled) {
                    this.setSelectedWeek(this.state.selectedWeek);
                }
                else {
                    if(nextProps.newStartDate === undefined){
                        this.setSelectedDay(this.state.selectedDay);
                    }
                    else{
                        this.setState({
                            displayedYear: nextProps.newStartDate.getFullYear(),
                            displayedMonth: nextProps.newStartDate.getMonth(),
                            selectedYear: nextProps.newStartDate.getFullYear(),
                            selectedMonth: nextProps.newStartDate.getMonth(),
                            selectedDay: nextProps.newStartDate.getDate()
                        }, () => this.setSelectedDay(this.state.selectedDay));
                    }
                }
            }
        }

        if (nextProps.newStartDate !== undefined && nextProps.newStartDate !== this.props.newStartDate && nextProps.isActive) {
            if (this.isWeekSelectionEnabled) {
                this.setState({
                    displayedYear: nextProps.newStartDate.getFullYear(),
                    displayedMonth: nextProps.newStartDate.getMonth(),
                    selectedYear: nextProps.newStartDate.getFullYear(),
                    selectedMonth: nextProps.newStartDate.getMonth(),
                    selectedWeek: WeekDayUtils.getFirstDayOfWeek(moment(nextProps.newStartDate)).week()
                });
            }
            else {
                this.setState({
                    displayedYear: nextProps.newStartDate.getFullYear(),
                    displayedMonth: nextProps.newStartDate.getMonth(),
                    selectedYear: nextProps.newStartDate.getFullYear(),
                    selectedMonth: nextProps.newStartDate.getMonth(),
                    selectedDay: nextProps.newStartDate.getDate()
                });
            }
        }
    }

    private get isWeekSelectionEnabled(): boolean {
        return this.props.selectionType === 'week';
    }

    private setSelectedDay(day: number): void {
        this.setState({
            selectedDay: day,
            selectedMonth: this.state.displayedMonth,
            selectedYear: this.state.displayedYear
        });

        this.props.onDateChange('day', moment(`${day}-${this.state.displayedMonth + 1}-${this.state.displayedYear}`, "DD-MM-YYYY").toDate(), moment(`${day}-${this.state.displayedMonth + 1}-${this.state.displayedYear}`, "DD-MM-YYYY").toDate());
    }

    private setSelectedWeekByDay(day: number): void {
        const date = moment(`${day}-${this.state.displayedMonth + 1}-${this.state.displayedYear}`, "DD-MM-YYYY");
        const week = WeekDayUtils.getFirstDayOfWeek(date).week();

        this.setSelectedWeek(week);
    }

    private setSelectedWeek(week: number): void {
        this.setState({
            selectedWeek: week,
            selectedMonth: this.state.displayedMonth,
            selectedYear: this.state.displayedYear,
        });

        // Handle the case of the last couple days of the year falling in a week of the next year.
        const startOfWeek = this.state.displayedMonth > 1 && week === 1
            ? WeekDayUtils.getFirstDayOfWeekByWeek(this.state.displayedYear + 1, week)
            : WeekDayUtils.getFirstDayOfWeekByWeek(this.state.displayedYear, week);

        const endOfWeek = moment(startOfWeek).add('6', 'days');

        this.props.onDateChange('week', startOfWeek.toDate(), endOfWeek.toDate());
    }

    private increaseMonth(): void {
        const newYear = this.state.displayedMonth + 1 >= 12;

        this.setState({
            displayedMonth: newYear ? 0 : this.state.displayedMonth + 1,
            displayedYear: newYear ? this.state.displayedYear + 1 : this.state.displayedYear
        });
    }

    private decreaseMonth(): void {
        const newYear = this.state.displayedMonth - 1 < 0;

        this.setState({
            displayedMonth: newYear ? 11 : this.state.displayedMonth - 1,
            displayedYear: newYear ? this.state.displayedYear - 1 : this.state.displayedYear
        });
    }

    private createDatePickerTile(key: number, defaultDisabled: boolean, dayOfMonth: number, weekNumber?: number): JSX.Element {
        if (this.isWeekSelectionEnabled) {
            return (
                <DayDatePickerTile
                    key={key}
                    day={dayOfMonth}
                    disabled={defaultDisabled}
                    displayedMonth={this.state.displayedMonth}
                    displayedYear={this.state.displayedYear}
                    onDaySelect={this.setSelectedWeekByDay}
                    weekSelected={this.state.selectedYear === this.state.displayedYear
                        && this.state.selectedMonth === this.state.displayedMonth
                        && this.state.selectedWeek === weekNumber} />
            );
        }
        else {
            return (
                <DayDatePickerTile
                    key={key}
                    day={dayOfMonth}
                    disabled={defaultDisabled}
                    displayedMonth={this.state.displayedMonth}
                    displayedYear={this.state.displayedYear}
                    onDaySelect={this.setSelectedDay}
                    selected={this.state.selectedYear === this.state.displayedYear
                        && this.state.selectedMonth === this.state.displayedMonth
                        && this.state.selectedDay === dayOfMonth} />
            );
        }
    }

    private createWeekPickerTile(key: number, weekNumber: number): JSX.Element {
        return (
            <WeekDatePickerTile
                key={key}
                week={weekNumber}
                displayedMonth={this.state.displayedMonth}
                displayedYear={this.state.displayedYear}
                onWeekSelect={this.setSelectedWeek}
                selected={this.state.selectedYear === this.state.displayedYear
                    && this.state.selectedMonth === this.state.displayedMonth
                    && this.state.selectedWeek === weekNumber} />
        );
    }

    private renderRows(): JSX.Element[] {
        // Find the first day of the month
        const firstMoment = moment(`1-${this.state.displayedMonth + 1}-${this.state.displayedYear}`, "DD-MM-YYYY");

        const rows: JSX.Element[] = [];
        let currentMoment = moment(firstMoment);
        let colPosition = 0;
        let row: JSX.Element[] = [];
        let rowKey = 0;
        let tileKey = 0;

        // Determine how far back we should go
        let missing = currentMoment.day();
        let currentWeek = WeekDayUtils.getWeekNumber(currentMoment);

        while (missing !== 0) {
            currentMoment.subtract(1, 'day');
            row.push(this.createDatePickerTile(tileKey++, true, currentMoment.date(), currentWeek));
            colPosition++;
            missing--;
        }

        if (this.isWeekSelectionEnabled) {
            row.push(this.createWeekPickerTile(tileKey++, currentWeek));
            colPosition++;
        }

        currentMoment = moment(firstMoment);
        row.reverse();

        const maxColumns = this.isWeekSelectionEnabled ? 8 : 7;
        while (currentMoment.month() === firstMoment.month()) {

            row.push(this.createDatePickerTile(tileKey++, false, currentMoment.date(), currentWeek));
            colPosition++;
            currentMoment.add(1, 'day');

            if (colPosition === maxColumns) {
                rows.push(<tr key={rowKey++}>{row}</tr>);
                row = [];
                currentWeek = currentMoment.week();

                colPosition = 0;
                if (this.isWeekSelectionEnabled) {
                    row.push(this.createWeekPickerTile(tileKey++, currentWeek));
                    colPosition++;
                }
            }
        }

        // Finish the last column
        while (colPosition !== maxColumns) {
            row.push(this.createDatePickerTile(tileKey++, true, currentMoment.date(), currentWeek));
            colPosition++;
            currentMoment.add(1, 'day');
        }

        // Don't forget to push the last row
        if (rows.length > 0) {
            rows.push(<tr key={rowKey++}>{row}</tr>);
        }

        return rows;
    }

    private renderMonthSelection(): string {
        const labels = LanguageProvider.getTranslations(["january", "february", "march", "april", "may", "june", "july", "august",
            "september", "october", "november", "december"].map(d => `dates.${d}`));

        return `${labels[this.state.displayedMonth]} ${this.state.displayedYear}`;
    }

    private renderHeader(): JSX.Element {

        let labels = LanguageProvider.getTranslations((this.daysOfWeek)
            .map(d => `dates.shorthand.${d}`));

        if (this.isWeekSelectionEnabled) {
            const weekLabel = LanguageProvider.getTranslation("dates.week");
            labels = [weekLabel].concat(labels);
        }

        const result: JSX.Element[] = labels.map(label => (<th className="text-center" key={label}>{label}</th>));
        return (<thead><tr>{result}</tr></thead>);
    }

    public render(): JSX.Element {
        return (<div className={(this.props.isActive ? "" : "d-none")}>
            <div className="sliding-selection pb-5">
                <div className="w-25 d-inline-block align-bottom">
                    <div onClick={this.decreaseMonth} className="sliding-selection-container text-center clickable float-right">
                        <img src={ArrowLeft} />
                    </div>
                </div>
                <div className="d-inline-block w-50 text-center" >
                    {this.renderMonthSelection()}
                </div>
                <div className="w-25 d-inline-block">
                    <div onClick={this.increaseMonth} className="sliding-selection-container text-center clickable">
                        <img src={ArrowRight} />
                    </div>
                </div>
            </div>
            <table className="unit-calendar w-100">
                {this.renderHeader()}
                <tbody className="table-border">{this.renderRows()}</tbody>
            </table>
        </div>);
    }
}

export default DayDatePickerDeluxe;

class WeekDatePickerTile extends React.Component<IWeekDatePickerTileProps> {
    private currentTime: Moment = moment(new Date());
    private currentYear: number = this.currentTime.year();
    private currentMonth: number = this.currentTime.month();

    public constructor(props: IWeekDatePickerTileProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    private onClick(): void {
        if (this.isDisabled) {
            return;
        }

        this.props.onWeekSelect(this.props.week);
    }

    private get isDisabled(): boolean {
        return (this.props.week > WeekDayUtils.getWeekNumber(this.currentTime) && this.props.displayedMonth === this.currentMonth && this.props.displayedYear === this.currentYear)
            || (this.props.displayedMonth > this.currentMonth && this.props.displayedYear === this.currentYear)
            || this.props.displayedYear > this.currentYear;
    }

    public render(): JSX.Element {
        return (
            <td
                key={this.props.week}
                onClick={this.onClick}
                className={"text-center border-right "
                    + (this.isDisabled ? "disabled " : "clickable ")
                    + (this.props.selected && !this.isDisabled ? "selected" : "")}>
                {this.props.week}
            </td>
        );
    }
}

interface IWeekDatePickerTileProps {
    displayedMonth: number;
    displayedYear: number;
    week: number;
    selected: boolean;
    onWeekSelect: (week: number) => void;
}

class DayDatePickerTile extends React.Component<IDayDatePickerTileProps> {
    private currentTime: Moment = moment(new Date());
    private currentYear: number = this.currentTime.year();
    private currentMonth: number = this.currentTime.month();
    private currentDay: number = this.currentTime.date();

    public constructor(props: IDayDatePickerTileProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    private onClick(): void {
        if (this.isDisabled) {
            return;
        }

        this.props.onDaySelect(this.props.day);
    }

    private get isDisabled(): boolean {
        return (this.props.day > this.currentDay && this.props.displayedMonth === this.currentMonth && this.props.displayedYear === this.currentYear)
            || (this.props.displayedMonth > this.currentMonth && this.props.displayedYear === this.currentYear)
            || this.props.displayedYear > this.currentYear
            || this.props.disabled;
    }

    public render(): JSX.Element {
        return (
            <td
                key={this.props.day}
                onClick={this.onClick}
                className={"text-center "
                    + (this.isDisabled ? this.props.weekSelected ? "week-disabled " : "disabled " : "clickable ")
                    + (this.props.day === this.currentDay && this.props.displayedMonth === this.currentMonth && this.props.displayedYear === this.currentYear && !this.isDisabled ? "current " : "")
                    + (this.props.selected && !this.isDisabled ? "selected " : "")
                    + (this.props.weekSelected ? "week-selected " : "")}>
                {this.props.day}
            </td>
        );
    }
}

interface IDayDatePickerTileProps {
    disabled: boolean;
    displayedMonth: number;
    displayedYear: number;
    day: number;
    selected?: boolean;
    weekSelected?: boolean;
    onDaySelect: (day: number) => void;
}