import CoreEntity from "./CoreEntity";
import ISpaceTag from "./../interfaces/ISpaceTag";
import ISpaceProperty from "./../interfaces/ISpaceProperty";
import ISpaceValue from "./../interfaces/ISpaceValue";
import CoreSpaceValues from "../enums/coreSpaceValues";
import ICoreSpace from "../interfaces/ICoreSpace";
import CoreSpaceDatatypes from "../enums/coreSpaceDatatypes";
import CoreSpaceTypes from "../enums/coreSpaceTypes";

export default class CoreSpace extends CoreEntity implements ICoreSpace {
    public id: string;
    public venueId: string;
    public parentId: string;
    public name: string;
    public type: CoreSpaceTypes;
    public dataTypes?: string[];
    public properties: ISpaceProperty[];
    public values: ISpaceValue[];
    public tags?: ISpaceTag[];

    public constructor(space: ICoreSpace) {
        super(space);
        this.id = space.id;
        this.venueId = space.venueId;
        this.parentId = space.parentId;
        this.name = space.name;
        this.type = CoreSpaceTypes[space.type];
        this.dataTypes = space.dataTypes;
        this.properties = space.properties ?? [];
        this.values = space.values ?? [];
        this.tags = space.tags;
    }

    public get customerId(): string {
        return this.getCustomPropertyValue("CustomerId");
    }

    public get mapwizeId(): string {
        return this.getCustomPropertyValue("MapwizeId");
    }

    public get urn(): string {
        return this.getCustomPropertyValue("CurrentDerivativeModelId");
    }

    public get openingHour(): number {
        const openingHour = this.getCustomPropertyValue("OpeningHour");
        return openingHour ? Number(openingHour) : 0;
    }

    public get closingHour(): number {
        const closingHour = this.getCustomPropertyValue("ClosingHour");
        return closingHour ? (Number(closingHour) === 0 ? 24 : Number(closingHour)) : 24;
    }

    public get openDays(): string {
        return this.getCustomPropertyValue("OpenDays");
    }

    public get floorLevel(): string {
        return this.getCustomPropertyValue("FloorLevel");
    }

    public get isReservable(): string {
        return this.getCustomPropertyValue("IsReservable");
    }

    public get buildUpTimeMinutes(): number {
        const buildUpTimeInMinutes = this.getCustomPropertyValue("BuildUpTimeMinutes");
        return buildUpTimeInMinutes ? Number(buildUpTimeInMinutes) : 0;
    }

    public get windDowTimeMinutes(): number {
        const windDowTimeMinutes = this.getCustomPropertyValue("WindDownTimeMinutes");
        return windDowTimeMinutes ? Number(windDowTimeMinutes) : 0;
    }

    public get capacity(): number {
        const capacity = this.getCustomPropertyValue("Capacity");
        return capacity ? Number(capacity) : 0;
    }

    public get accessibleStartTime(): string {
        return this.getCustomPropertyValue("AccessibleStartTime");
    }

    public get accessibleEndTime(): string {
        return this.getCustomPropertyValue("AccessibleEndTime");
    }

    public get accessibleDays(): string {
        return this.getCustomPropertyValue("AccessibleDays");
    }

    public get facilities(): string {
        return this.getCustomPropertyValue("Facilities") ?? "";
    }

    public get restricted(): string {
        return this.getCustomPropertyValue("Restricted");
    }

    public get isRestricted(): boolean {
        return this.restricted === "true";
    }

    public get isPOI(): boolean {
        return this.getCustomPropertyValue("IsPOI")?.toLowerCase() === 'true' ?? false;
    }

    public get isAutomaticCancellationForRoomsEnabled(): boolean {
        const booleanString = this.getCustomPropertyValue("IsAutomaticCancellationForRoomsEnabled");
        return booleanString?.toLowerCase() === 'true';
    }

    public get automaticCancellationAfter(): number {
        const numberString = this.getCustomPropertyValue("AutomaticCancellationAfterInMinutes");
        return Number.parseInt(numberString, 10);
    }

    public get externalSystemsConnectionType(): string {
        return this.getCustomPropertyValue("ExternalSystemsConnectionType");
    }

    public get realEstateUtilizationExternalApplicationUrl(): string {
        return this.getCustomPropertyValue("BEEquippedExternalApplicationUrl");
    }

    public get hasMotionDevices(): boolean {
        return this.hasDeviceType(CoreSpaceDatatypes.Motion);
    }

    public get hasComfortDevices(): boolean {
        return this.hasDeviceType(CoreSpaceDatatypes.Comfort);
    }

    public get hasToiletDevices(): boolean {
        return this.hasDeviceType(CoreSpaceDatatypes.Toilet);
    }

    public get energyManagementCorrectionType(): string {
        return this.getCustomPropertyValue("BeEnergyCorrectionType");
    }

    public hasValue(type: CoreSpaceValues): boolean {
        return this.getValue(type) ? true : false;
    }

    public get isOccupied(): boolean {
        const type = CoreSpaceValues.OccupancyStatus;
        return this.hasValue(type) && this.getValue(type).value.toLowerCase() === "occupied";
    }

    public get isCoolingDown(): boolean {
        if (!this.hasValue(CoreSpaceValues.ActualFree)) {
            return false;
        }

        const value = this.getValue(CoreSpaceValues.ActualFree);
        const actualFreeLocal = new Date(value.value);
        return actualFreeLocal > new Date();
    }

    public get usedTimeValue(): number | undefined {
        if (!this.hasValue(CoreSpaceValues.UsedTime)) {
            return undefined;
        }

        const value = this.getValue(CoreSpaceValues.UsedTime);
        return parseInt(value.value, 10);
    }

    public get lastUsedTimeResetTime(): Date | undefined {
        const value = this.getActualValue(CoreSpaceValues.LastUsedTimeReset);
        return value ? new Date(value) : undefined;
    }

    public getActualValue(type: CoreSpaceValues): string | undefined {
        // Separate case for peopleCount, since this is a aggregated value in case of BeCount V2.
        if (type === CoreSpaceValues.PeopleCount) {
            return this.peopleCount;
        }

        if (!this.hasValue(type)) {
            return undefined;
        }

        return this.getValue(type).value;
    }

    public get correctedUsedTimeInMinutes(): number {
        let usedTime = this.usedTimeValue ?? 0;

        if (this.isOccupied) {
            const now = new Date();
            const timeSinceOccupied = this.getActualValueSetTime(CoreSpaceValues.OccupancyStatus);

            if (timeSinceOccupied) {
                usedTime += Math.abs((now.getTime() - timeSinceOccupied.getTime()) / (1000 * 60));
            }
        }

        return Math.round(usedTime);
    }

    public get peopleCount(): string | undefined {
        if (this.hasValue(CoreSpaceValues.PeopleCount)) {
            return this.getValue(CoreSpaceValues.PeopleCount).value;
        }
        else if (this.hasValue(CoreSpaceValues.NrOfPeopleEnteredTotal) && this.hasValue(CoreSpaceValues.NrOfPeopleLeftTotal)) {
            const lastUpdate = this.getValue(CoreSpaceValues.LastBeCountV2Update);
            if (!lastUpdate || (new Date(lastUpdate.value).getDate() !== (new Date()).getUTCDate())) {
                return "0";
            }

            const entered = this.getValue(CoreSpaceValues.NrOfPeopleEnteredTotal).value;
            const left = this.getValue(CoreSpaceValues.NrOfPeopleLeftTotal).value;

            const diff = parseInt(entered, 10) - parseInt(left, 10);
            return diff.toString();
        }
        return undefined;
    }

    private hasDeviceType(dataType: CoreSpaceDatatypes): boolean {
        return !!this.dataTypes && this.dataTypes.includes(dataType);
    }

    private getActualValueSetTime(type: CoreSpaceValues): Date | undefined {
        if (!this.hasValue(type)) {
            return undefined;
        }

        return new Date(this.getValue(type).timestamp);
    }
}