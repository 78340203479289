import { ISpaceOnMap, ISpaceStyle, IStyle, IStyler } from "@beyondeyes/shared";
import ICoreSpace from "interfaces/ICoreSpace";
import Colors from "@/styles/colors";
import BaseStyler from "./baseStyler";
class TagStyler extends BaseStyler implements IStyler {
    private activeTagOption: string;

	public getStyle(spaceOnMap: ISpaceOnMap): IStyle | undefined {
		const space = spaceOnMap.beSpace as ICoreSpace;

		if (space == null || space.type !== "Workspace" || space.tags === undefined) {
			return undefined;
		}

        const style: ISpaceStyle = {
            fillOpacity: 0.95,
        };

        if (space.tags.length === 0) {
            style.fillColor = Colors.shakespeare_blue;
        }
        else {
            if (space.tags.some(t => t.optionIdentifier === this.activeTagOption)) {
                style.fillColor = Colors.signal_green;
            }
            else {
                style.fillColor = Colors.amaranth_red;
            }
        }

		return {
            placeStyle: style,
            placeLabel: { text: space.name, size: this.defaultLabelSize }
		};
	}

    public setActiveTagOption(optionIdentifier: string): void {
        this.activeTagOption = optionIdentifier;
    }
}

export default TagStyler;