import mime from 'mime-types';

export default class DocumentUtils {
    public static base64ToBlob(base64Data: string, type?: string): Blob {
        const split = base64Data.split('base64,');
        const bytecharacters = atob(split[split.length-1]);

        const byteNumbers = new Array(bytecharacters.length);
        for (let i = 0; i < bytecharacters.length; i++) {
            byteNumbers[i] = bytecharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        let blob: Blob;
        if (!type) {
            blob = new Blob([byteArray]);
        }
        else {
            blob = new Blob([byteArray], { type: type });
        }
        return blob;
    }

    // Opens a file to a new tab. Falls back to download when that fails:
    public static openDocument(content: string, fileName: string): void {
        const type = mime.lookup(fileName);
        if (!type) {
            return;
        }

        const file = DocumentUtils.base64ToBlob(content, type);
        const fileUrl = URL.createObjectURL(file);
        const tab = window.open('about:blank');

        // Magic for correctly opening file in another tab.
        // First check if creating tab succeeded, if not create a download (for example when firefox blocks the popup)
        if (tab != null) {
            // If creation succeeded link to the file blob.
            tab.location.href = fileUrl;

            // Check on the status of the tab after a small delay, since Ublock (when active) kills the tab after its content is set.
            // If so, create a download after all, so the user will still get the file.
            setTimeout(() => {
                if (tab != null && tab.closed) {
                    const element = document.createElement("a");
                    element.href = fileUrl;
                    element.download = fileName;
                    document.body.appendChild(element);
                    element.click();
                }
            }, 500);
        }
        else {
            const element = document.createElement("a");
            element.href = fileUrl;
            element.download = fileName;
            document.body.appendChild(element);
            element.click();
        };
    }

    public static downloadDocument(content: string, fileName: string): void {
        const type = mime.lookup(fileName);
        if (!type) {
            return;
        }

        const file = DocumentUtils.base64ToBlob(content, type);
        const fileUrl = URL.createObjectURL(file);

        const element = document.createElement("a");
        element.href = fileUrl;
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
    }
}