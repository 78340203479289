import * as React from 'react';
import { withTranslation } from 'react-i18next';

import IEnergyGraphHeaderState from './interfaces/IEnergyGraphHeaderState';
import IEnergyGraphHeaderProps from './interfaces/IEnergyGraphHeaderProps';

import './energyGraphHeader.scss';

import BesenseTooltip from '@/components/besenseTooltip/besenseTooltip';
import DateScroller from '@/components/datePicker/dateScroller';

class EnergyGraphHeader extends React.Component<IEnergyGraphHeaderProps, IEnergyGraphHeaderState> {
    public constructor(props: IEnergyGraphHeaderProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div className="d-flex w-100 energy-graph-header">
                <div className="d-flex-inline mr-3">
                    <h2 className="d-inline-block">
                        {this.props.title}
                    </h2>
                    {this.props.tooltipContent &&
                        <BesenseTooltip
                            content={this.props.tooltipContent} />
                    }
                </div>
                <div className="mr-auto d-flex">
                    {
                        this.props.extraRenderAfterTitle && this.props.extraRenderAfterTitle()
                    }
                </div>
                <DateScroller
                    selectedTimeUnit={this.props.selectedTimeUnit}
                    startDate={this.props.startDate}
                    onDateChange={this.props.onDateChange}
                />
            </div>
        );
    }
}

export default withTranslation()(EnergyGraphHeader);