import * as React from 'react';
import { withTranslation } from "react-i18next";
import moment from 'moment';
import 'moment/locale/nl';

import './energySavingsPage.scss';

import GasSelectedIcon from '@/images/Gas_selected.svg';
import GasIcon from '@/images/Gas_unselected.svg';
import EnergySelectedIcon from '@/images/Energy_selected.svg';
import EnergyIcon from '@/images/Energy_unselected.svg';
import LineSelectedIcon from '@/images/Line_selected.svg';
import LineIcon from '@/images/Line_unselected.svg';
import BarSelectedIcon from '@/images/Bar_selected.svg';
import BarIcon from '@/images/Bar_unselected.svg';
import SavingsIcon from '@/images/piggy_unselected.svg';
import SavingsSelectedIcon from '@/images/piggy_selected.svg';
import ContractIcon from '@/images/contract_unselected.svg';
import ContractSelectedIcon from '@/images/contract_selected.svg';
import CO2IconUnselected from '@/images/co2_unselected.svg';
import CO2IconSelected from '@/images/co2_selected.svg';
import HeatSelectedIcon from '@/images/Heat_selected.svg';
import HeatIcon from '@/images/Heat_unselected.svg';

import EnergyGraph from '../components/energyGraph';
import DatePickerDeluxe from '../components/datePicker/datePickerDeluxe';
import { IDatePickerTimeUnit } from '../../../interfaces/IDatePickerTimeUnit';
import EnergyGraphHeader from '../components/energyGraphHeader';

import IEnergySavingsPageProps from './interfaces/IEnergySavingsPageProps';
import IEnergySavingsPageState from './interfaces/IEnergySavingsPageState';
import EnergyUnitType from '../components/enums/energyUnitType';
import EnergyService from '@/services/energyService';
import VenueProvider from '@/providers/venueProvider';
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import LanguageProvider from '@/providers/languageProvider';
import IMonthlyEnergySavings from '../../../interfaces/energy/IMonthlyEnergySavings';
import IDailyEnergySavings from '../../../interfaces/energy/IDailyEnergySavings';
import { withTelemetry } from '@/services/telemetryService';
import SubscriptionValidationService from '@/services/subscriptionValidationService';
import EnergyManagementPage from '../energyManagementPage';
import translations from 'translations/mapper';
import Colors from '@/styles/colors';

class EnergySavingsPage extends React.Component<IEnergySavingsPageProps, IEnergySavingsPageState>{
    private readonly graphHtmlReference: React.RefObject<HTMLDivElement>;

    private readonly energyService: EnergyService;
    private subscriptionValidationService: SubscriptionValidationService;

    private timeUnitWeek: IDatePickerTimeUnit = 'week';
    private timeUnitMonth: IDatePickerTimeUnit = 'month';

    public constructor(props: IEnergySavingsPageProps) {
        super(props);

        this.graphHtmlReference = React.createRef();

        const activeVenue = VenueProvider.getActiveVenue();
        const state: IEnergySavingsPageState = {
            loading: true,
            venue: activeVenue,
            selectedTimeUnit: 'year',
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate(),
            selectedUnitType: EnergyUnitType.percentage,
            selectedGraphType: "bar",
            energyGraphData: [],
            projectedEnergyGraphData: [],
            hasElectricitySubscription: false,
            hasGasSubscription: false,
            hasHeatSubscription: false
        };

        this.state = state;

        this.energyService = new EnergyService();

        this.setHeatUnitTypeSelection = this.setHeatUnitTypeSelection.bind(this);
        this.setGasUnitTypeSelection = this.setGasUnitTypeSelection.bind(this);
        this.setEnergyUnitTypeSelection = this.setEnergyUnitTypeSelection.bind(this);
        this.setCo2TypeSelection = this.setCo2TypeSelection.bind(this);
        this.setSavingsTypeSelection = this.setSavingsTypeSelection.bind(this);
        this.setPercentageTypeSelection = this.setPercentageTypeSelection.bind(this);
        this.setBarGraphSelection = this.setBarGraphSelection.bind(this);
        this.setLineGraphSelection = this.setLineGraphSelection.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.initializeEnergyDataAsync = this.initializeEnergyDataAsync.bind(this);
        this.initializeVenueAsync = this.initializeVenueAsync.bind(this);
        this.isEnergyTypeSelected = this.isEnergyTypeSelected.bind(this);
        this.renderGraphHeaderTitle = this.renderGraphHeaderTitle.bind(this);
        this.onGraphElementClickAsync = this.onGraphElementClickAsync.bind(this);
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
        this.renderDatePicker = this.renderDatePicker.bind(this);
        this.getExportFileName = this.getExportFileName.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeVenueAsync();
    }

    public async componentWillUnmount(): Promise<void> {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenueAsync);
    }

    private isEnergyTypeSelected(type: EnergyUnitType): boolean {
        return this.state.selectedUnitType === type;
    }

    private get gasSelected(): boolean {
        return this.state.selectedUnitType === EnergyUnitType.m3;
    }

    private get electricitySelected(): boolean {
        return this.state.selectedUnitType === EnergyUnitType.kWh;
    }

    private get heatSelected(): boolean {
        return this.state.selectedUnitType === EnergyUnitType.GJ;
    }

    private get moneySelected(): boolean {
        return this.state.selectedUnitType === EnergyUnitType.euro;
    }

    private get percentageSelected(): boolean {
        return this.state.selectedUnitType === EnergyUnitType.percentage;
    }

    private get co2Selected(): boolean {
        return this.state.selectedUnitType === EnergyUnitType.co2;
    }

    private get barGraphSelected(): boolean {
        return this.state.selectedGraphType === "bar";
    }

    private get lineGraphSelected(): boolean {
        return this.state.selectedGraphType === "line";
    }

    private async initializeVenueAsync(): Promise<void> {
        this.setState({ loading: true });
        const activeVenue = VenueProvider.getActiveVenue();

        const hasGasSubscription = activeVenue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Gas"]) : false;
        const hasElectricitySubscription = activeVenue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Electricity"]) : false;
        const hasHeatSubscription = activeVenue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Heat"]) : false;

        this.setState({
            selectedUnitType: EnergyUnitType.percentage,
            venue: activeVenue,
            hasElectricitySubscription: hasElectricitySubscription,
            hasGasSubscription: hasGasSubscription,
            hasHeatSubscription: hasHeatSubscription,
            energyGraphData: [],
        }, async () => {
            if (!activeVenue) {
                return;
            }

            await this.initializeEnergyDataAsync();
        });
    }

    private async setGasUnitTypeSelection(): Promise<void> {
        if (this.gasSelected) {
            return;
        }

        this.setState({
            selectedUnitType: EnergyUnitType.m3,
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setEnergyUnitTypeSelection(): Promise<void> {
        if (this.electricitySelected) {
            return;
        }

        this.setState({
            selectedUnitType: EnergyUnitType.kWh
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setHeatUnitTypeSelection(): Promise<void> {
        if (this.heatSelected) {
            return;
        }

        this.setState({
            selectedUnitType: EnergyUnitType.GJ,
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setSavingsTypeSelection(): Promise<void> {
        if (this.moneySelected) {
            return;
        }

        this.setState({
            selectedUnitType: EnergyUnitType.euro
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setPercentageTypeSelection(): Promise<void> {
        if (this.percentageSelected) {
            return;
        }

        this.setState({
            selectedUnitType: EnergyUnitType.percentage
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setCo2TypeSelection(): Promise<void> {
        if (this.co2Selected) {
            return;
        }

        this.setState({
            selectedUnitType: EnergyUnitType.co2
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setBarGraphSelection(): Promise<void> {
        if (this.barGraphSelected) {
            return;
        }

        this.setState({
            selectedGraphType: "bar"
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setLineGraphSelection(): Promise<void> {
        if (this.lineGraphSelected) {
            return;
        }

        this.setState({
            selectedGraphType: "line"
        }, async () => await this.initializeEnergyDataAsync());
    }

    private onDateChange(timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date): void {
        this.setState({
            selectedTimeUnit: timeUnit,
            startDate: startDate,
            endDate: endDate,
            energyGraphData: [],
            projectedEnergyGraphData: [],
        }, async () => await this.initializeEnergyDataAsync());
    }

    private onTimeUnitClickAsync(timeUnit: IDatePickerTimeUnit): void {

        if (timeUnit === "week") {
            const newDate = moment(this.state.startDate).startOf('week');
            const newEndDate = moment(this.state.startDate).endOf('week');
            this.onDateChange('week', newDate.toDate(), newEndDate.toDate());
        }
        else if (timeUnit === "month") {
            const newDate = moment(this.state.startDate).startOf('month');
            const newEndDate = moment(this.state.startDate).endOf('month');
            this.onDateChange('month', newDate.toDate(), newEndDate.toDate());
        }
        else if (timeUnit === "year") {
            const newDate = moment(this.state.startDate).startOf('year');
            const newEndDate = moment(this.state.startDate).endOf('year');
            this.onDateChange('year', newDate.toDate(), newEndDate.toDate());
        }
    }

    private renderGraphHeaderTitle(): string {
        switch (this.state.selectedUnitType) {
            case EnergyUnitType.kWh:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.savings.electricitysavingstitle);
            case EnergyUnitType.m3:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.savings.gassavingstitle);
            case EnergyUnitType.GJ:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.savings.heatsavingstitle);
            case EnergyUnitType.euro:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.savings.savingstitle);
            case EnergyUnitType.percentage:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.savings.percentagesavingstitle);
            case EnergyUnitType.co2:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.savings.co2savingstitle);
            default:
                return '';
        }
    }

    private renderTooltipContent(): string | undefined {
        switch (this.state.selectedUnitType) {
            case EnergyUnitType.kWh:
                return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-savings-content']['savings-kwh']);
            case EnergyUnitType.m3:
                return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-savings-content']['savings-m3']);
            case EnergyUnitType.GJ:
                return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-savings-content']['savings-heat']);
            case EnergyUnitType.euro:
                return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-savings-content']['savings-cost']);
            case EnergyUnitType.percentage:
                return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-savings-content']['savings-percentage']);
            case EnergyUnitType.co2:
                return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-savings-content']['savings-co2']);
            default:
                return undefined;
        }
    }

    private async initializeEnergyDataAsync(): Promise<void> {
        this.setState({ loading: true });
        if (!this.state.venue) {
            return;
        }

        const startDate = this.state.startDate;
        const endDate = this.state.endDate;
        const selectedTimeUnit = this.state.selectedTimeUnit;

        const mapper = (savings: IMonthlyEnergySavings | IDailyEnergySavings): number | null => {
            if (this.electricitySelected) {
                return savings.energySavingsInkWh;
            }
            else if (this.gasSelected) {
                return savings.gasSavingsInM3;
            }
            else if (this.heatSelected) {
                return savings.heatSavingsInGj;
            }
            else if (this.co2Selected) {
                return savings.carbonDioxideSavingsInKg;
            }
            else if (this.moneySelected) {
                return savings.costSavingsInCurrency;
            }
            else if (this.percentageSelected) {
                return savings.fractionalSavings * 100;
            }
            else {
                return null;
            }
        };

        let data: (number | null)[];

        if (selectedTimeUnit === this.timeUnitWeek || selectedTimeUnit === this.timeUnitMonth) {
            data = (await this.energyService.GetDailyEnergySavings(this.state.venue.customerId, this.state.venue.id, startDate, endDate))
                .sort((a, b) => a.localDay - b.localDay)
                .map(d => mapper(d));
        }
        else {
            data = (await this.energyService.GetMonthlyEnergySavings(this.state.venue.customerId, this.state.venue.id, startDate, endDate))
                .sort((a, b) => a.localMonth - b.localMonth)
                .map(d => mapper(d));
        }

        let label = "";
        if (this.electricitySelected || this.gasSelected || this.heatSelected) {
            label = EnergyUnitType[this.state.selectedUnitType];
        }
        else if (this.co2Selected) {
            label = "Kg";
        }
        else if (this.percentageSelected) {
            label = "%";
        }
        else if (this.moneySelected) {
            label = "\u20ac";
        }

        this.setState({
            projectedEnergyGraphData: [{
                label: label,
                color: Colors.royal_blue,
                data: data,
            }],
            loading: false
        });
    }

    private async onGraphElementClickAsync(index: number): Promise<void> {
        const timeUnit = this.state.selectedTimeUnit;

        if (timeUnit === "year") {
            const startOfYear = this.state.startDate;
            const newDate = moment(startOfYear).add(index, "month");
            this.onDateChange('month', newDate.toDate(), newDate.add(1, "month").add(-1, "day").toDate());
        }
    }

    private renderDatePicker(): JSX.Element {
        return (
            <>
                <div className="ml-3 d-flex png-hidden">
                    <div
                        className={`datetoggle-btn ${(this.state.selectedTimeUnit === 'week') ? 'active' : ''}`}
                        onClick={(): void => this.onTimeUnitClickAsync('week')}>
                        {LanguageProvider.getTranslation(translations.dates.week)}
                    </div>
                    <div
                        className={`datetoggle-btn ${(this.state.selectedTimeUnit === 'month') ? 'active' : ''}`}
                        onClick={(): void => this.onTimeUnitClickAsync('month')}>
                        {LanguageProvider.getTranslation(translations.dates.month)}
                    </div>
                    <div
                        className={`datetoggle-btn ${(this.state.selectedTimeUnit === 'year') ? 'active' : ''}`}
                        onClick={(): void => this.onTimeUnitClickAsync('year')}>
                        {LanguageProvider.getTranslation(translations.dates.year)}
                    </div>
                </div>
                <DatePickerDeluxe
                    disableDayPicker={true}
                    defaultDateUnit={'year'}
                    onDateChange={this.onDateChange}
                    newStartDate={this.state.startDate}
                    newEndDate={this.state.endDate}
                    compactView={true}
                    newTimeUnitType={this.state.selectedTimeUnit}
                    minimalisticCompactButton={true}
                />
            </>
        );
    }

    private getExportFileName(): string {
        return `${this.state.venue && this.state.venue.name}-${this.renderGraphHeaderTitle()}`.replaceAll(' ', '_');
    }

    public render(): JSX.Element {
        return (
            <EnergyManagementPage>
                <div id="venue-energy-savings" className="main-content" ref={this.graphHtmlReference}>
                    <div className="main-content-header">
                        <div className="row header-margin-bottom-h1">
                            <div className="col-sm-8">
                                <h1>{this.state.venue && this.state.venue.name}</h1>
                            </div>
                            <div className="col-sm-4 d-flex png-hidden">
                                <div className="selection-container mr-2 ml-auto">
                                    <div onClick={this.setBarGraphSelection} className={"selection-item " + (this.barGraphSelected ? "selected" : "")}>
                                        <img src={this.barGraphSelected ? BarSelectedIcon : BarIcon} />
                                    </div>
                                    <div onClick={this.setLineGraphSelection} className={"selection-item " + (this.lineGraphSelected ? "selected" : "")}>
                                        <img src={this.lineGraphSelected ? LineSelectedIcon : LineIcon} />
                                    </div>
                                </div>
                                <div className="selection-container">
                                    <div onClick={this.setPercentageTypeSelection} className={"selection-item " + (this.percentageSelected ? "selected" : "")}>
                                        <img src={this.percentageSelected ? ContractSelectedIcon : ContractIcon} />
                                    </div>
                                    <div onClick={this.setSavingsTypeSelection} className={"selection-item " + (this.moneySelected ? "selected" : "")}>
                                        <img src={this.moneySelected ? SavingsSelectedIcon : SavingsIcon} />
                                    </div>
                                    <div onClick={this.setCo2TypeSelection} className={"selection-item " + (this.co2Selected ? "selected" : "")}>
                                        <img src={this.co2Selected ? CO2IconSelected : CO2IconUnselected} />
                                    </div>
                                    {this.state.hasElectricitySubscription && <div onClick={this.setEnergyUnitTypeSelection} className={"selection-item " + (this.electricitySelected ? "selected" : "")}>
                                        <img src={this.electricitySelected ? EnergySelectedIcon : EnergyIcon} />
                                    </div>}
                                    {this.state.hasGasSubscription && <div onClick={this.setGasUnitTypeSelection} className={"selection-item " + (this.gasSelected ? "selected" : "")}>
                                        <img src={this.gasSelected ? GasSelectedIcon : GasIcon} />
                                    </div>}
                                    {this.state.hasHeatSubscription && <div onClick={this.setHeatUnitTypeSelection} className={"selection-item " + (this.heatSelected ? "selected" : "")}>
                                        <img src={this.heatSelected ? HeatSelectedIcon : HeatIcon} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <EnergyGraphHeader
                                    selectedTimeUnit={this.state.selectedTimeUnit}
                                    startDate={this.state.startDate}
                                    onDateChange={this.onDateChange}
                                    tooltipContent={this.renderTooltipContent()}
                                    title={this.renderGraphHeaderTitle()}
                                    extraRenderAfterTitle={this.renderDatePicker}
                                />
                            </div>
                        </div>
                        <div className="row pt-5">
                            <div className="col-sm-12">
                                <EnergyGraph
                                    width={20}
                                    height={6}
                                    timeRepresentation={this.state.selectedTimeUnit}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    unitType={this.state.selectedUnitType}
                                    graphType={this.state.selectedGraphType}
                                    data={this.state.projectedEnergyGraphData}
                                    onElementClick={this.onGraphElementClickAsync}
                                    isBarGraphElementClickable={this.state.selectedTimeUnit === 'year'}
                                    loading={this.state.loading}
                                    exportOptions={{
                                        fileName: this.getExportFileName()
                                    }}
                                    graphHtmlReference={this.graphHtmlReference}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </EnergyManagementPage>
        );
    }
}

export default withTranslation()(withTelemetry(EnergySavingsPage, "EnergySavingsPage"));