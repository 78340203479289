enum Unit {
    kWh,
    m3,
    GJ,
    celsius,
    percentage,
    ppm,
    euro,
    kg,
    ton,
    none
}

export default Unit;