import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import Fullscreen from 'highcharts/modules/full-screen';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import chartOptions from './reservedTimeChartOptions';

Fullscreen(Highcharts);
HighchartsMore(Highcharts);
SolidGauge(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

interface IReservedTimeChartProps {
    id: string;
    percentage: number;
    title: string;
}

const ReservedTimeChart: React.FC<IReservedTimeChartProps> = (props) => {
    return (
        <div id={props.id} >
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(props.title, props.percentage)}
            />
        </div>
    );
};

export default ReservedTimeChart;