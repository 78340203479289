import * as React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { PulseLoader } from "react-spinners";

import IEnergyAssetOverviewProps from "./interfaces/IEnergyAssetOverviewProps";
import IEnergyAssetOverviewState from "./interfaces/IEnergyAssetOverviewState";

import BesenseTooltip from "@/components/besenseTooltip/besenseTooltip";
import EnergyAssetTree from "@/models/energyAssetTree";
import EnergyAssetExpand from "./energyAssetExpand";
import EnergyNavigationStore from "../utils/energyNavigationStore";
import CoreSpaceService from "@/services/coreSpaceService";
import EnergyService from "@/services/energyService";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import LanguageProvider from "@/providers/languageProvider";
import Dictionary from "@/utils/dictionary";
import { EnergyAssetType } from "@/models/energyAssetType";
import VenueProvider from "@/providers/venueProvider";
import EnergyUnitType from "./enums/energyUnitType";
import CoreSpaceTypes from "@/enums/coreSpaceTypes";
import CoreSpaceIncludes from "@/enums/coreSpaceIncludes";
import CoreSpace from "@/models/coreSpace";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import links from "@/utils/links";

import Colors from "@/styles/colors";

class EnergyAssetOverview extends React.Component<IEnergyAssetOverviewProps, IEnergyAssetOverviewState> {
    private coreSpaceService: CoreSpaceService;

    private energyService: EnergyService;
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: IEnergyAssetOverviewProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        this.energyService = new EnergyService();

        this.navigateToDetails = this.navigateToDetails.bind(this);
        this.navigateToGasDetails = this.navigateToGasDetails.bind(this);
        this.navigateToEnergyDetails = this.navigateToEnergyDetails.bind(this);
        this.navigateToHeatDetails = this.navigateToHeatDetails.bind(this);

        this.state = {
            electricDataTree: undefined,
            gasDataTree: undefined,
            heatDataTree: undefined,
            coldLoading: true,
            heatLoading: true,
            gasLoading: true,
            electricLoading: true,
            showComponent: false,
            showElectricSection: false,
            showGasSection: false,
            showHeatSection: false
        };

        this.initializeAsync = this.initializeAsync.bind(this);
        this.initializeEnergyTreeAsync = this.initializeEnergyTreeAsync.bind(this);
        this.initializeGasTreeAsync = this.initializeGasTreeAsync.bind(this);
        this.initializeHeatTreeAsync = this.initializeHeatTreeAsync.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeAsync();
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeAsync);
    }

    private async initializeAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();

        const gasSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["Gas"]) : false;
        const electricitySubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["Electricity"]) : false;
        const heatSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["Heat"]) : false;

        this.setState({
            showComponent: gasSubscription || electricitySubscription,
            showGasSection: gasSubscription,
            showElectricSection: electricitySubscription,
            showHeatSection: heatSubscription
        });

        if (gasSubscription || electricitySubscription || heatSubscription) {
            await Promise.all([this.initializeGasTreeAsync(), this.initializeEnergyTreeAsync(), this.initializeHeatTreeAsync()]);
        }
    }

    private async initializeGasTreeAsync(): Promise<void> {
        this.setState({ gasLoading: true });
        const venue = VenueProvider.getActiveVenue();
        if (venue === undefined) {
            console.error(`Did not find a selected venue, this should not happen!`);
            return;
        }
        const gasData = await this.energyService.GetRecentGasUsageAsync();
        const gasTree = new EnergyAssetTree(LanguageProvider.getTranslation("pages.energy.tabs.overview.mainmeters"), venue.id, "venue", gasData.value, 100);
        this.setState({
            gasDataTree: gasTree,
            gasLoading: false
        });
    }

    private async initializeHeatTreeAsync(): Promise<void> {
        this.setState({ gasLoading: true });
        const venue = VenueProvider.getActiveVenue();
        if (venue === undefined) {
            console.error(`Did not find a selected venue, this should not happen!`);
            return;
        }
        const heatData = await this.energyService.GetRecentHeatUsageAsync();
        const heatTree = new EnergyAssetTree(LanguageProvider.getTranslation("pages.energy.tabs.overview.mainmeters"), venue.id, "venue", heatData.value, 100);
        this.setState({
            heatDataTree: heatTree,
            heatLoading: false
        });
    }

    private async initializeEnergyTreeAsync(): Promise<void> {
        this.setState({ electricLoading: true });
        const venue = VenueProvider.getActiveVenue();
        if (venue === undefined) {
            console.error(`Did not find a selected venue, this should not happen!`);
            return;
        }

        const rootElectricData = await this.energyService.GetRecentElectricUsageAsync();
        let installationsAndGroups = await this.coreSpaceService.getSpacesForVenue(
            venue.id,
            [CoreSpaceTypes.Installation, CoreSpaceTypes.InstallationGroup],
            [CoreSpaceIncludes.DataTypes, CoreSpaceIncludes.Properties]);

        // Filter installations down to only those that are marked as producing:
        installationsAndGroups = installationsAndGroups.filter(s => s.type === "InstallationGroup"
            || s.energyManagementCorrectionType === undefined
            || s.energyManagementCorrectionType === "Usage");

        const rootOfTree = new EnergyAssetTree(
            LanguageProvider.getTranslation("pages.energy.tabs.overview.mainmeters"),
            venue.id,
            "venue",
            rootElectricData.value,
            100);

        await this.constructElectricTreeAsync(rootOfTree, installationsAndGroups);

        const assetData = await this.energyService.GetRecentEnergyManagementUsageAsync(installationsAndGroups.filter(s => s.type === "Installation").map(s => s.id));
        const assetDataLookup = new Dictionary<number>();
        assetData.forEach(d => {
            assetDataLookup.add(d.assetId, d.value);
        });

        await Promise.all(rootOfTree.children.map(c => this.calculateUsagesForTree(c, assetDataLookup, rootOfTree.usage)));

        this.sortEnergyAssetsAlphabetically(rootOfTree);

        this.setState({ electricDataTree: rootOfTree, electricLoading: false });
    }

    private sortEnergyAssetsAlphabetically(assetTree: EnergyAssetTree): void {
        assetTree.children.sort((firstAsset, secondAsset) => {

            const firstLabel = firstAsset.name.toUpperCase();
            const secondLabel = secondAsset.name.toUpperCase();

            return firstLabel > secondLabel ? 1 : -1;
        });
        for (const child of assetTree.children) {
            this.sortEnergyAssetsAlphabetically(child);
        }
    }

    private async constructElectricTreeAsync(rootOfTree: EnergyAssetTree, installationsAndGroups: CoreSpace[]): Promise<void> {
        const installationsAndGroupsLookup = new Dictionary<CoreSpace>();
        installationsAndGroups.forEach(sp => {
            installationsAndGroupsLookup.add(sp.id, sp);
        });

        const otherNodes = installationsAndGroups.map(s => new EnergyAssetTree(s.name, s.id, s.type === "Installation" ? "installation" : "group"));

        // Start of lookup of parent-asset id to correct parent in the tree.
        const parentLookup = new Dictionary<EnergyAssetTree>();

        parentLookup.add(rootOfTree.assetId, rootOfTree);
        otherNodes.forEach(node => {
            parentLookup.add(node.assetId, node);
        });
        await Promise.all(otherNodes.map(o => this.updateTreeForNodeAsync(o, installationsAndGroupsLookup, parentLookup)));
    }

    private calculateUsagesForTree(tree: EnergyAssetTree, assetDataLookup: Dictionary<any>, totalUsage: number): void {
        if (assetDataLookup.containsKey(tree.assetId) && tree.children.length > 0) {
            console.warn(`Asset '${tree.assetId}' has both data itself and has children, this should probably not happen!`);
        }

        let value = assetDataLookup.containsKey(tree.assetId) ? assetDataLookup.item(tree.assetId) : 0;

        for (const child of tree.children) {
            this.calculateUsagesForTree(child, assetDataLookup, totalUsage);
            value += child.usage;
        }

        tree.usage = value;
        tree.percentage = totalUsage > 0 ? (value * 100) / totalUsage : 100;
    }

    private async updateTreeForNodeAsync(node: EnergyAssetTree, installationsAndGroupsLookup: Dictionary<CoreSpace>, parentLookup: Dictionary<EnergyAssetTree>): Promise<void> {
        if (!installationsAndGroupsLookup.containsKey(node.assetId)) {
            throw new Error("Did not find space corresponding to assettree node, this should not be possible!");
        }
        const space = installationsAndGroupsLookup.item(node.assetId);

        if (!parentLookup.containsKey(space.parentId)) {
            // If we don't know what the correct parent node is yet, then there must be a route of non installation/group nodes in between the current node
            // and the venue (or another potential parent). Do a traverse up, to find this path.
            let nextAncestor: CoreSpace;

            // Recursively get the parent space to get all ancestors towards the venue.
            const ancestorLookup = new Dictionary<CoreSpace>();
            let currentSpace = space;
            do {
                nextAncestor = await this.coreSpaceService.getSpaceById(currentSpace.venueId, currentSpace.parentId, [CoreSpaceIncludes.Properties]);
                ancestorLookup.add(nextAncestor.id, nextAncestor);
                currentSpace = nextAncestor;

            } while (nextAncestor.id !== nextAncestor.venueId && nextAncestor.id !== nextAncestor.parentId);

            this.updateParentLookupForSpace(parentLookup, ancestorLookup, space);
        }

        const parent = parentLookup.item(space.parentId);
        parent.children.push(node);
    }

    private updateParentLookupForSpace(parentLookup: Dictionary<EnergyAssetTree>, ancestorsLookup: Dictionary<CoreSpace>, space: CoreSpace): void {
        if (!ancestorsLookup.containsKey(space.parentId)) {
            throw new Error("Failed to find parent space amongst ancestors, this should not happen!");
        }

        const parentSpace = ancestorsLookup.item(space.parentId);

        if (!parentLookup.containsKey(parentSpace.parentId)) {
            // Fix the lookup for the parent first:
            this.updateParentLookupForSpace(parentLookup, ancestorsLookup, parentSpace);
        }

        // The parent of our parent is now a valid lookup, so chain that lookup to the current space's parent:
        parentLookup.add(space.parentId, parentLookup.item(parentSpace.parentId));
    }

    private navigateToEnergyDetails(assetId: string, type: EnergyAssetType, name: string): void {
        this.navigateToDetails(assetId, type, name, 'kWh');
    }

    private navigateToGasDetails(assetId: string, type: EnergyAssetType, name: string): void {
        this.navigateToDetails(assetId, type, name, 'm3');
    }

    private navigateToHeatDetails(assetId: string, type: EnergyAssetType, name: string): void {
        this.navigateToDetails(assetId, type, name, 'GJ');
    }

    private navigateToDetails(assetId: string, type: EnergyAssetType, name: string, unitType: 'm3' | 'kWh' | 'GJ',): void {
        EnergyNavigationStore.setNavigationDetails({
            id: assetId,
            type: type,
            unitType: unitType,
            name: name
        });

        this.props.history.push(links.energymanagement.reports.baseline);
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.showComponent &&
                    <div id="asset-overview">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="header">
                                    <h2>{LanguageProvider.getTranslation("pages.energy.tabs.overview.meteroverview")}</h2>
                                </div>
                            </div>
                        </div>

                        {this.state.showGasSection && <>
                            <div className="row pb-3 pt-3">
                                <div className="col-sm-4">
                                    <h3 className="d-inline">{LanguageProvider.getTranslation("pages.energy.tabs.overview.gasmeters")}</h3>
                                </div>
                                <div className="col-sm-4">
                                    <h3 className="d-inline">{LanguageProvider.getTranslation("pages.energy.tabs.overview.usage")}</h3>
                                    <BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.asset-tree.usage-m3")} />
                                </div>
                                <div className="col-sm-4">
                                    <h3 className="d-inline">% {LanguageProvider.getTranslation("pages.energy.tabs.overview.usage")}</h3>
                                    <BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.asset-tree.usage-m3-percentage")} />
                                </div>
                            </div>
                            <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.state.gasLoading} />
                            {!this.state.gasLoading
                                && this.state.gasDataTree !== undefined
                                && <EnergyAssetExpand
                                    navigateToDetails={this.navigateToGasDetails}
                                    unitType={EnergyUnitType.m3}
                                    dataTree={this.state.gasDataTree}
                                    depth={0} />}
                        </>}

                        {this.state.showHeatSection && <>
                            <div className="row pb-3 pt-3">
                                <div className="col-sm-4">
                                    <h3 className="d-inline">{LanguageProvider.getTranslation("pages.energy.tabs.overview.heatmeters")}</h3>
                                </div>
                                <div className="col-sm-4">
                                    <h3 className="d-inline">{LanguageProvider.getTranslation("pages.energy.tabs.overview.usage")}</h3>
                                    <BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.asset-tree.usage-gj")} />
                                </div>
                                <div className="col-sm-4">
                                    <h3 className="d-inline">% {LanguageProvider.getTranslation("pages.energy.tabs.overview.usage")}</h3>
                                    <BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.asset-tree.usage-gj-percentage")} />
                                </div>
                            </div>
                            <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.state.heatLoading} />
                            {!this.state.heatLoading
                                && this.state.heatDataTree !== undefined
                                && <EnergyAssetExpand
                                    navigateToDetails={this.navigateToHeatDetails}
                                    unitType={EnergyUnitType.GJ}
                                    dataTree={this.state.heatDataTree}
                                    depth={0} />}
                        </>}

                        {this.state.showElectricSection && <>
                            <div className="row pb-3 pt-3">
                                <div className="col-sm-4">
                                    <h3 className="d-inline">{LanguageProvider.getTranslation("pages.energy.tabs.overview.electricmeters")}</h3>
                                </div>
                                <div className="col-sm-4">
                                    <h3 className="d-inline">{LanguageProvider.getTranslation("pages.energy.tabs.overview.usage")}</h3>
                                    <BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.asset-tree.usage-kwh")} />
                                </div>
                                <div className="col-sm-4">
                                    <h3 className="d-inline">% {LanguageProvider.getTranslation("pages.energy.tabs.overview.usage")}</h3>
                                    <BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.asset-tree.usage-kwh-percentage")} />
                                </div>
                            </div>

                            <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.state.electricLoading} />
                            {!this.state.electricLoading
                                && this.state.electricDataTree !== undefined
                                && <EnergyAssetExpand
                                    navigateToDetails={this.navigateToEnergyDetails}
                                    dataTree={this.state.electricDataTree}
                                    unitType={EnergyUnitType.kWh}
                                    depth={0} />}
                        </>}
                    </div>}</>
        );
    }
}

export default withRouter(withTranslation()(EnergyAssetOverview));