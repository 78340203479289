import * as React from 'react';

import ITimeUnitSelectButtonProps from './interfaces/ITimeUnitSelectButtonProps';

import "./datePickerDeluxe.scss";

class TimeUnitSelectButton extends React.Component<ITimeUnitSelectButtonProps> {
    public constructor(props: ITimeUnitSelectButtonProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    private onClick(): void {
        this.props.onSelect(this.props.timeUnit);
    }

    public render(): JSX.Element {
        return (
            <div className="date-selection-container">
                <div
                    onClick={this.onClick}
                    className={"date-selection-item text-center clickable " + (this.props.selected ? "selected " : "") + (this.props.lastElement ? "date-border-right" : "")}>
                    {this.props.displayText}
                </div>
            </div>
        );
    }
}

export default TimeUnitSelectButton;