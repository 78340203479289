import * as React from "react";
import moment from "moment";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Select, { OnChangeValue } from "react-select";

import "./motionInsightsPage.scss";

import IInsightsOverviewProps from "../interfaces/IInsightsOverviewProps";
import InsightOccupationBarGraph from "../components/insightOccupationBarGraph";
import TopDataTable from "../components/topDataTable";
import InsightHourlyOccupationBarGraph from "../components/insightHourlyOccupationBarGraph";

import IFloorSelectValue from "../../../interfaces/IFloorSelectValue";
import IMotionInsightsPageState from "../interfaces/IMotionInsightsPageState";
import CoreSpaceService from "@/services/coreSpaceService";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import VenueProvider from "@/providers/venueProvider";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import Dictionary from "@/utils/dictionary";
import LanguageProvider from "@/providers/languageProvider";
import SelectBoxUtils from "@/utils/selectBoxUtils";
import { nl } from "date-fns/esm/locale";
import TelemetryService, { withTelemetry } from "@/services/telemetryService";
import { AIEvents } from "@/utils/aiEvents";
import CoreSpaceIncludes from "../../../enums/coreSpaceIncludes";
import CoreSpaceTypes from "../../../enums/coreSpaceTypes";
import FloorSelect from "@/components/select/floorSelect";

import RealEstateUtilizationPage from "../realEstateUtilizationPage";
import translations from "@/translations/mapper";
import InsightsMap from "../components/insightsMap";

class MotionInsightsPage extends React.Component<IInsightsOverviewProps, IMotionInsightsPageState> {
	private coreSpaceService: CoreSpaceService;

	public constructor(props: IInsightsOverviewProps) {
		super(props);

		this.coreSpaceService = new CoreSpaceService();

		const startDate = new Date();
		startDate.setHours(0, 0, 0, 0);
		const dateDiff = startDate.getDay() === 1 ? 7 : (startDate.getDay() + 6) % 7;
		startDate.setDate(startDate.getDate() - dateDiff);
		const endDate = new Date();
		endDate.setHours(23, 59, 59, 999);

		const state: IMotionInsightsPageState = {
			loading: true,
			venueId: "",
			selectedRenderIds: [],
			selectedRenderType: "",
			endDate: endDate,
			startDate: startDate,
			assetSelectionOptions: [],
			selectedAssets: [],
			selectedFloor: null,
			renderMap: false,
			renderMapInitial: false,
			floors: [],
		};

		this.state = state;

		this.initializeActiveVenueAsync = this.initializeActiveVenueAsync.bind(this);
		this.onFloorChange = this.onFloorChange.bind(this);
		this.onAssetAdd = this.onAssetAdd.bind(this);
		this.onAssetRemove = this.onAssetRemove.bind(this);

		this.setActiveFloorAsync = this.setActiveFloorAsync.bind(this);
		this.setActiveAsset = this.setActiveAsset.bind(this);
		this.setActiveVenueAsync = this.setActiveVenueAsync.bind(this);
		this.clearFloorSelection = this.clearFloorSelection.bind(this);
		this.clearAssetSelection = this.clearAssetSelection.bind(this);
		this.handleStartDateChange = this.handleStartDateChange.bind(this);
		this.handleEndDateChange = this.handleEndDateChange.bind(this);
		this.toggleMap = this.toggleMap.bind(this);

		AppEventHub.on(AppEvents.BuildingSelected, this.initializeActiveVenueAsync);
	}

	public async componentDidMount(): Promise<void> {
		registerLocale("nl", nl);
		setDefaultLocale("nl");

		this.initializeActiveVenueAsync();
	}

	public async initializeActiveVenueAsync(): Promise<void> {
		const venue = VenueProvider.getActiveVenue();

		if (venue) {
			await this.setActiveVenueAsync();
		}

		this.setState({
			loading: false,
		});
	}

	public componentWillUnmount(): void {
		// Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
		AppEventHub.off(AppEvents.BuildingSelected, this.initializeActiveVenueAsync);
	}

	private async setActiveVenueAsync(): Promise<void> {
		const activeVenue = VenueProvider.getActiveVenue();
		this.clearFloorSelection();

		if (!activeVenue) {
			return;
		}

		const floors = await this.coreSpaceService.getFloorsForVenue(activeVenue.id, [CoreSpaceIncludes.Properties]);

		this.setState({
			venueId: activeVenue.id,
			selectedRenderIds: [activeVenue.id],
			selectedRenderType: "venue",
			floors: floors,
		});
	}

	private onFloorChange(floorLevel: number): void {
		const floor = this.state.floors.find((f) => parseInt(f.floorLevel, 10) === floorLevel);

		if ((floor && !this.state.selectedFloor) || (floor && this.state.selectedFloor && this.state.selectedFloor.floorLevel !== floorLevel)) {
			this.setActiveFloorAsync({ value: floor.id, label: floor.name, floorLevel: floorLevel });
		}
	}

	private onAssetAdd(selectedValue: IReactSelectValue): void {
		if (!selectedValue) {
			return;
		}

		const selectedAssets = this.state.selectedAssets.filter((asset) => asset.value !== selectedValue.value);
		this.setActiveAsset(selectedAssets.concat(selectedValue));
	}

	private onAssetRemove(selectedValue: IReactSelectValue): void {
		if (!selectedValue) {
			return;
		}

		this.setActiveAsset(this.state.selectedAssets.filter((asset) => asset.value !== selectedValue.value));
	}

	private async setActiveFloorAsync(selectedItem: OnChangeValue<IFloorSelectValue, false>): Promise<void> {
		TelemetryService.AppInsights.trackEvent({ name: AIEvents.motionInsights.setFloor });
		const item = selectedItem as IFloorSelectValue | null;

		if (!item) {
			this.setState(
				{
					selectedFloor: item,
				},
				this.clearAssetSelection
			);
			return;
		}

		if (item.isDefault) {
			this.setState({
				selectedRenderIds: [this.state.venueId],
				selectedRenderType: "venue",
				selectedFloor: null,
				selectedAssets: [],
				assetSelectionOptions: [],

			});

			return;
		}

		const floorId = item.value;
		const assets = await this.coreSpaceService.getSpacesForFloor(
			this.state.venueId,
			floorId,
			[CoreSpaceTypes.Room, CoreSpaceTypes.Workspace],
			[CoreSpaceIncludes.Properties, CoreSpaceIncludes.DataTypes]
		);

		const comfortOnlyLookup = new Dictionary<string>();
		assets
			.filter((s) => s.dataTypes !== undefined && s.hasComfortDevices && s.dataTypes.length === 1)
			.forEach((a) => comfortOnlyLookup.add(a.id, a.id));

		const assetSelectionOptions = assets
			.filter((a) => !comfortOnlyLookup.containsKey(a.id))
			.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
			.map((a) => ({
				value: a.id,
				label: a.name,
			}));

		this.setState({
			selectedRenderIds: [item.value],
			selectedFloor: item,
			selectedRenderType: "floor",
			assetSelectionOptions: assetSelectionOptions,
			selectedAssets: [],
		});
	}

	private setActiveAsset(selectedItems: OnChangeValue<IReactSelectValue, true>): void {
		TelemetryService.AppInsights.trackEvent({ name: AIEvents.motionInsights.setAsset });

		const items = selectedItems as IReactSelectValue[];

		if (!items || items.length === 0) {
			this.setState({
				selectedRenderIds: this.state.selectedFloor ? [this.state.selectedFloor.value] : [],
				selectedRenderType: "floor",
				selectedAssets: [],
			});

			return;
		}

		this.setState({
			selectedAssets: items,
			selectedRenderType: "asset",
			selectedRenderIds: items.map((i) => i.value),
		});
	}

	private handleStartDateChange(state: Date): void {
		TelemetryService.AppInsights.trackEvent({ name: AIEvents.motionInsights.startDateChange });

		this.setState({
			startDate: state,
		});
	}

	private handleEndDateChange(state: Date): void {
		TelemetryService.AppInsights.trackEvent({ name: AIEvents.motionInsights.endDateChange });

		this.setState({
			endDate: state,
		});
	}

	private clearAssetSelection(): void {
		this.setState({
			selectedRenderIds: this.state.selectedFloor ? [this.state.selectedFloor.value] : [],
			selectedRenderType: "floor",
			assetSelectionOptions: [],
			selectedAssets: [],
		});
	}

	private clearFloorSelection(): void {
		this.clearAssetSelection();
		this.setState({
			selectedRenderIds: [this.state.venueId],
			selectedRenderType: "venue",
			assetSelectionOptions: [],
			selectedFloor: null,
			selectedAssets: [],
		});
	}

	private toggleMap(): void {
		const event = this.state.renderMap ? AIEvents.motionInsights.map.close : AIEvents.motionInsights.map.open;
		TelemetryService.AppInsights.trackEvent({ name: event });

		this.setState({
			renderMap: !this.state.renderMap,
			renderMapInitial: true,
		});
	}

	public render(): JSX.Element {
		const noOptionsMessage = (): string => LanguageProvider.getTranslation("buttons.dropdowns.nooptions");
		const venue = VenueProvider.getActiveVenue();
		const venueName = venue?.name ?? "";

		return (
			<RealEstateUtilizationPage>
				<div id="motion-insights-page" className="main-content">
					<div className="main-content-header">
						<div className="row">
							<div className="col-sm-12">
								<h1>{venueName}</h1>
							</div>
						</div>

						<div className="row input-selection">
							<div className="col-sm d-flex">
								<div className="input-field-m mr-2">
									<DatePicker
										id="start-date"
										selected={this.state.startDate}
										onChange={this.handleStartDateChange}
										maxDate={this.state.endDate}
										placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
										className="asset-datepicker clickable"
										dateFormat="dd-MM-yyyy"
										todayButton={LanguageProvider.getTranslation("datepicker.today")}
									/>
								</div>
								<div className="input-field-m mr-2">
									<DatePicker
										id="end-date"
										selected={this.state.endDate}
										onChange={this.handleEndDateChange}
										minDate={this.state.startDate}
										maxDate={moment().toDate()}
										placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
										className="asset-datepicker clickable"
										dateFormat="dd-MM-yyyy"
										todayButton={LanguageProvider.getTranslation("datepicker.today")}
									/>
								</div>

								<div className="input-field-l mr-2">
									<FloorSelect
										dataTest="floor-select"
										floors={this.state.floors}
										setActiveFloor={this.setActiveFloorAsync}
										isDisabled={this.state.renderMap}
										selectedFloor={this.state.selectedFloor}
									/>
								</div>
								<div className="input-field-xl mr-2">
									<Select
										value={this.state.selectedAssets}
										onChange={this.setActiveAsset}
										options={this.state.assetSelectionOptions}
										isMulti={true}
										placeholder={`${LanguageProvider.getTranslation("buttons.dropdowns.asset")}`}
										noOptionsMessage={noOptionsMessage}
										styles={SelectBoxUtils.getDefaultSelectStyles(40)}
									/>
								</div>
								<div className="ml-auto">
									<button id="show-map" className="btn btn-secondary" onClick={this.toggleMap}>
										{this.state.renderMap
											? LanguageProvider.getTranslation(translations.buttons.map.hide)
											: LanguageProvider.getTranslation(translations.buttons.map.show)}
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="main-content-body">
						{this.state.renderMapInitial && (
							<div className={`row ${this.state.renderMap ? "d-block" : "d-none"}`}>
								<InsightsMap
									selectedFloor={this.state.selectedFloor}
									assetOptions={this.state.assetSelectionOptions}
									selectedAssets={this.state.selectedAssets}
									onAssetAdd={this.onAssetAdd}
									onAssetRemove={this.onAssetRemove}
									onFloorChange={this.onFloorChange}
									venueId={this.state.venueId}
									isDisplayed={this.state.renderMap}
								/>
							</div>
						)}

						{!this.state.renderMap && (
							<>
								<TopDataTable startDate={this.state.startDate} endDate={this.state.endDate} />
								<div className="content-divider" />
								<div className="row">
									<div className="col-sm-6">
										<h2>
											{LanguageProvider.getTranslation("pages.motioninsights.reports.graph.occupancy.dailytitle")}
										</h2>
										<InsightOccupationBarGraph
											ids={this.state.selectedRenderIds}
											renderType={this.state.selectedRenderType}
											startDate={this.state.startDate}
											endDate={this.state.endDate}
											height={8}
											width={20}
										/>
									</div>
									<div className="col-sm-6">
										<h2>
											{LanguageProvider.getTranslation("pages.motioninsights.reports.graph.occupancy.hourlytitle")}
										</h2>
										<InsightHourlyOccupationBarGraph
											ids={this.state.selectedRenderIds}
											renderType={this.state.selectedRenderType}
											startDate={this.state.startDate}
											endDate={this.state.endDate}
											height={8}
											width={20}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</RealEstateUtilizationPage>
		);
	}
}

export default withTelemetry(MotionInsightsPage, "MotionInsightsPage");
