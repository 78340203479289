import React, { useState, useEffect, ReactElement } from 'react';
import Select, { components, MultiValue } from 'react-select';
import IReactSelectValue from '../../../../interfaces/IReactSelectValue';

import LanguageProvider from '../../../../providers/languageProvider';
import Checkbox from './checkbox';

import './multiSelectCheckboxes.scss';

interface MultiSelectProps {
    id?: string;
    options: IReactSelectValue[];
    initialSelectedOptions: IReactSelectValue[];
    placeholderLabel: string;
    onChange: (selected: IReactSelectValue[]) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = (props) => {
    const [selectedOptions, setSelectedOptions] = useState<IReactSelectValue[]>([]);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    useEffect(function () {
        const initialSelection = props.initialSelectedOptions;

        setSelectedOptions(initialSelection);
        setIsAllSelected(initialSelection.length === props.options.length);
    }, [props.initialSelectedOptions, props.options]);

    function handleSelectAll(): void {

        if (isAllSelected) {
            setSelectedOptions([]);
            props.onChange([]);
        } else {
            setSelectedOptions(props.options);
            props.onChange(props.options);
        }
        setIsAllSelected(!isAllSelected);
    }

    function handleChange(newValue: MultiValue<IReactSelectValue>): void {
        const selected = [...newValue] as IReactSelectValue[];

        if (selected.some((option) => option.value === 'all')) {
            handleSelectAll();
            return;
        }

        if (selected.length === 0) {
            setSelectedOptions([]);
            setIsAllSelected(false);
        } else {
            setSelectedOptions(selected);
            setIsAllSelected(selected.length === props.options.length);
        }

        props.onChange(selected);
    }

    function OptionComponent(props: any): ReactElement {
        return (
            <components.Option {...props} className='multiSelectOption'>
                <Checkbox
                    checked={props.isSelected}
                    onChange={(): any => null}
                    label={props.label}
                />
            </components.Option>
        );
    }

    function getPlaceholder(): string {
        if (selectedOptions.length === 0) {
            return LanguageProvider.getTranslation('select.select') + ' ' + props.placeholderLabel.toLowerCase();
        } else {
            return (isAllSelected ? LanguageProvider.getTranslation('select.allselected') + ' ' : '') +  `${selectedOptions.length} ` + props.placeholderLabel;
        }
    }

    const selectAllOption: IReactSelectValue = { label: LanguageProvider.getTranslation('select.all'), value: 'all' };

    // Add the 'Select All' option at the top of the options list
    const combinedOptions = [selectAllOption, ...props.options];

    return (
        <div className={'multi-select-checkboxes-wrapper'}>
            <Select
                id={props.id}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                //menuIsOpen={true}
                components={{
                    Option: (props: any): ReactElement => {
                        if (props.data.value === 'all') {
                            return (
                                <components.Option {...props} className='multiSelectOption'>
                                    <Checkbox
                                        checked={isAllSelected}
                                        onChange={(): any => null}
                                        label={LanguageProvider.getTranslation('select.all')}
                                    />
                                </components.Option>
                            );
                        }
                        return <OptionComponent {...props} />;
                    },
                }}
                options={combinedOptions}
                value={selectedOptions}
                onChange={handleChange}
                placeholder={getPlaceholder()}
                isClearable={false}
                noOptionsMessage={(): string => LanguageProvider.getTranslation('select.nooptions')}
                controlShouldRenderValue={false} // Hide selected values in the input itself
            />
        </div>
    );
}

export default MultiSelect;
