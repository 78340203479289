import { withTranslation } from "react-i18next";

import IComfortOverviewGraphProps from "../../realestateutilization/components/interfaces/IComfortOverviewGraphProps";
import BaseComfortOverviewGraph from "../../realestateutilization/components/baseComfortOverviewGraph";
import ILineGraphData from "../../../interfaces/ILineGraphData";
import ChartDataCreator from "@/utils/chartDataCreator";
import LanguageProvider from "@/providers/languageProvider";

class DailyComfortOverviewGraph extends BaseComfortOverviewGraph {
    public constructor(props: IComfortOverviewGraphProps) {
        super(props);
    }

    public getTestKey(): string {
        return "daily-comfort-overview-graph";
    }

    public async updateChartData(): Promise<void> {
        const assetIds = this.props.assetIds || [];
        const ids = assetIds.map(a => a.value);
        const comfortData = await this.dataInsightsService.getDailyAssetComfortData(ids, this.props.selectedComfortType.value, this.props.startDate, this.props.endDate);
        const chartData: ILineGraphData[] = ChartDataCreator.createDailyComfortOverview(comfortData, assetIds, this.props.thresholdHigh, this.props.thresholdLow);
        this.setState({
            chartData: chartData
        });
    }

    public getLabels(): string[] {
        return LanguageProvider.getTranslations(["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            .map(d => `dates.shorthand.${d}`));
    }
}

export default withTranslation()(DailyComfortOverviewGraph);