import { Component } from "react";
import { WithTranslation, withTranslation } from 'react-i18next';

import "./tile.scss";

import Unit from "./enums/unit";
import IconType from "./enums/iconType";
import BesenseTooltip from "@/components/besenseTooltip/besenseTooltip";
import IBaseState from "@/interfaces/IBaseState";

import HumidityIcon from '@/images/humidity.svg';
import TemperatureIcon from '@/images/temperature-thermometer.svg';
import CO2Icon from '@/images/co2.svg';
import EnergyIcon from '@/images/Energiebesparing stroom.svg';
import GasIcon from '@/images/Energiebesparing gas.svg';
import HeatIcon from '@/images/Energiebesparing warmte.svg';
import PdfIcon from "@/images/PDF_download_button.svg";
import CalenderCheckedIcon from "@/images/Start contract.svg";
import CalenderCrossedIcon from "@/images/Einde contract.svg";
import ContactIcon from "@/images/Contract_contact.svg";
import HandShakeIcon from "@/images/Afspraak contract.svg";
import ContractIcon from "@/images/Contract.svg";
import CalendarIcon from "@/images/Duur contract.svg";
import AssetSoonAvailableIcon from "@/images/asset-soon-available.svg";
import AssetOccupiedIcon from "@/images/asset-occupied.svg";
import AssetAvailableIcon from "@/images/asset-available.svg";
import BuildingSavingsIcon from "@/images/Energiebesparing totaal.svg";
import BuildingEuroIcon from "@/images/Building_euro.svg";
import PigEuroIcon from "@/images/Piggy_euros.svg";
import SolarPanelsIcon from "@/images/Zonnepanelen.svg";
import TreeIcon from "@/images/Bomen geplant.svg";
import TotalCostsSavingsIcon from "@/images/Kostenbesparing totaal.svg";
import CostSavingsGasIcon from "@/images/kostenbesparing gas.svg";
import CostSavingsElectricityIcon from "@/images/Kostenbesparing stroom.svg";
import CostSavingsHeatIcon from '@/images/Kostenbesparing warmte.svg';

interface ITileProps extends WithTranslation {
    unit: Unit;
    value: number | string;
    subtitle: JSX.Element | string;
    subtitle2?: string;
    subtitle3?: string;
    icon: IconType;
    totalNumberOfTileColumns: number | undefined;
    tooltip?: string;
}

class Tile extends Component<ITileProps, IBaseState> {
    public constructor(props: ITileProps) {
        super(props);

        const state: IBaseState = {
            loading: true
        };

        this.state = state;

        this.renderDisplayValue = this.renderDisplayValue.bind(this);
        this.renderTileIcon = this.renderTileIcon.bind(this);
        this.columnStyling = this.columnStyling.bind(this);
    }

    private renderDisplayValue(): JSX.Element {
        switch (this.props.unit) {
            case Unit.m3:
                return <h4>{this.props.value.toLocaleString()} m<sup>3</sup></h4>;
            case Unit.kWh:
                return <h4>{this.props.value.toLocaleString()} {Unit[this.props.unit]}</h4>;
            case Unit.GJ:
                return <h4>{this.props.value.toLocaleString()} GJ</h4>;
            case Unit.celsius:
                return <h4>{this.props.value.toLocaleString()}&deg;C</h4>;
            case Unit.percentage:
                return <h4>{this.props.value.toLocaleString()}&#37;</h4>;
            case Unit.ppm:
                return <h4>{this.props.value.toLocaleString()} ppm</h4>;
            case Unit.euro:
                return <h4>€ {this.props.value.toLocaleString()}</h4>;
            case Unit.kg:
                return <h4>{this.props.value.toLocaleString()} kg</h4>;
            case Unit.ton:
                return <h4>{this.props.value.toLocaleString()} ton</h4>;
            case Unit.none:
                return <h4>{this.props.value.toLocaleString()}</h4>;
            default:
                return <h4>{this.props.value.toLocaleString()}</h4>;
        }
    }

    private renderTileIcon(): string {
        switch (this.props.icon) {
            case IconType.humidity:
                return HumidityIcon;
            case IconType.co2:
                return CO2Icon;
            case IconType.temperature:
                return TemperatureIcon;
            case IconType.energy:
                return EnergyIcon;
            case IconType.gas:
                return GasIcon;
            case IconType.heat:
                return HeatIcon;
            case IconType.pdf:
                return PdfIcon;
            case IconType.calenderChecked:
                return CalenderCheckedIcon;
            case IconType.calenderCrossed:
                return CalenderCrossedIcon;
            case IconType.contact:
                return ContactIcon;
            case IconType.handShake:
                return HandShakeIcon;
            case IconType.contract:
                return ContractIcon;
            case IconType.calendar:
                return CalendarIcon;
            case IconType.assetAvailable:
                return AssetAvailableIcon;
            case IconType.assetSoonAvailable:
                return AssetSoonAvailableIcon;
            case IconType.assetOccupied:
                return AssetOccupiedIcon;
            case IconType.tree:
                return TreeIcon;
            case IconType.solarpanels:
                return SolarPanelsIcon;
            case IconType.buildingSavings:
                return BuildingSavingsIcon;
            case IconType.costSavingsTotal:
                return TotalCostsSavingsIcon;
            case IconType.costSavingsElectricity:
                return CostSavingsElectricityIcon;
            case IconType.costSavingsGas:
                return CostSavingsGasIcon;
            case IconType.costSavingsHeat:
                return CostSavingsHeatIcon;
            case IconType.pigEuro:
                return PigEuroIcon;
            case IconType.buildingEuro:
                return BuildingEuroIcon;
            default:
                return '';
        }
    }

    private columnStyling(): string {
        switch (this.props.totalNumberOfTileColumns) {
            case 1:
                return 'col-md-12';
            case 2:
                return 'col-md-4';
            case 3:
                return 'col-md-4';
            default:
                return '';
        }
    }

    public render(): JSX.Element {
        return (
            <div className={`tile-container ${this.columnStyling()}`}>
                <div className="row pl-0">
                    <div className="image-container col-md-1">
                        <img src={this.renderTileIcon()} />
                    </div>
                    <div className="information-container col-md-9">
                        {this.renderDisplayValue()}
                        <p className="subtitle">{this.props.subtitle}</p>
                        {this.props.tooltip && <BesenseTooltip content={this.props.tooltip} />}
                        {this.props.subtitle2 && <p className="mb-0">{this.props.subtitle2}</p>}
                        {this.props.subtitle3 && <p>{this.props.subtitle3}</p>}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Tile);