import { ISpaceOnMap, IStyler } from "@beyondeyes/shared";

export class SpaceUtils {
	public static getSpacesWithStyle(spaces?: ISpaceOnMap[], styler?: IStyler): ISpaceOnMap[] {
		if (!spaces || !styler) {
			return [];
		}
		return spaces.map((space) => {
			// spread operator is used to ensure the updated space
			//  does not refer to the space item from state from the calling component
			const copy = { ...space };
			copy.style = styler.getStyle(space);
			return copy;
		});
	}
}
