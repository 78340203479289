import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";

import { withTelemetry } from "@/services/telemetryService";
import INoShowOverviewTableProps from "./interfaces/INoShowOverviewTableProps";
import LanguageProvider from "@/providers/languageProvider";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import RoomNoShowData from "@/models/roomNoShowData";
import Dictionary from "@/utils/dictionary";
import TimeZoneUtils from "@/utils/timeZoneUtils";
import translations from "@/translations/mapper";
import PageUtils from "@/utils/pageUtils";

const NoShowOverviewTable: React.FC<INoShowOverviewTableProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [noShowOverviewData, setNoShowOverviewData] = useState<RoomNoShowData[]>([]);
    const [columns, setColumns] = useState<TableColumn<any>[]>(getColumns());
    const [pageSize, setPageSize] = useState<number>(10);

    useEffect(() => {
        const getData = async () : Promise<void> => {
            await getNoShowOverviewData();
            setPageSize(Math.min(10, noShowOverviewData.length));
        }

        getData();
        updateTable();
        setLoading(false);

        AppEventHub.on(AppEvents.LanguageChanged, updateTable);

        return (): void => { AppEventHub.off(AppEvents.LanguageChanged, updateTable); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    function getColumns(): TableColumn<RoomNoShowData>[] {
        const columns: TableColumn<RoomNoShowData>[] = [
            {
                id: 'room',
                name: LanguageProvider.getTranslation("pages.noshowinsights.overview.table.room"),
                selector: (row): string => row.roomName,
                sortable: true,
            },
            {
                id: 'lastnoshow',
                name: LanguageProvider.getTranslation("pages.noshowinsights.overview.table.lastnoshow"),
                selector: (row: RoomNoShowData): string => row.lastNoShow ? TimeZoneUtils.ConvertUtcDateToWestEurope(row.lastNoShow).format("DD-MM-YYYY HH:mm") : "",
                sortable: true,
            },
            {
                id: 'floor',
                name: LanguageProvider.getTranslation("pages.noshowinsights.overview.table.floor"),
                selector: (row): string => row.floorName,
                sortable: true,
            },
            {
                id: 'numberofnoshows',
                name: LanguageProvider.getTranslation("pages.noshowinsights.overview.table.numberofnoshows"),
                selector: (row): number => row.numberOfNoShows,
                sortable: true,
            }];
        return columns;
    }

    function updateTable(): void {
        const columns = getColumns();
        setColumns(columns);
    }

    async function getNoShowOverviewData(): Promise<void> {
        const historicReservations = props.data;

        const numberOfNoShows = new Dictionary<string>();
        const lastNoShows = new Dictionary<string>();
        const assetNames = new Dictionary<string>();
        const floorNames = new Dictionary<string>();
        const assetIds: string[] = [];

        historicReservations.forEach(r => {
            if (!r.isNoShow) {
                return;
            }
            const id = r.assetId;
            if (numberOfNoShows[id] > 0) {
                numberOfNoShows[id]++;
            } else {
                numberOfNoShows[id] = 1;
            }
            if (lastNoShows[id] < r.reservationStart || lastNoShows[id] === undefined) {
                lastNoShows[id] = r.reservationStart;
            }
            assetNames[id] = r.assetName;
            floorNames[id] = r.floorName;
            if (!assetIds.includes(id)) {
                assetIds.push(id);
            }
        });

        const roomNoShowData = assetIds.flatMap(id => new RoomNoShowData(assetNames[id], lastNoShows[id], floorNames[id], numberOfNoShows[id]));

        setNoShowOverviewData(roomNoShowData);
    }

    return (
        <div className="row" id="noshow-insights-table">
            <div className="col-sm-12">
                <h2>{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.table.title)}</h2>
                <DataTable
                    className="besense-grey-border-table"
                    progressPending={loading}
                    columns={columns}
                    data={noShowOverviewData}
                    noDataComponent={<span>{LanguageProvider.getTranslation(translations.tables.nodata)}</span>}
                    pagination={noShowOverviewData.length > 10}
                    paginationPerPage={pageSize}
                    paginationComponentOptions={PageUtils.getDefaultPaginationOptions()}
                />
            </div>
        </div>
    );

}

export default withTelemetry(NoShowOverviewTable, "NoShowOverviewTable");