import * as React from 'react';
import moment from 'moment';

import IUnitDatePickerDeluxeProps from './interfaces/IUnitDatePickerDeluxeProps';
import IMonthDatePickerDeluxeState from './interfaces/IMonthDatePickerDeluxeState';
import LanguageProvider from '@/providers/languageProvider';

import ArrowLeft from '@/images/Arrow Left.svg';
import ArrowRight from '@/images/Arrow Right.svg';

class MonthDatePickerDeluxe extends React.Component<IUnitDatePickerDeluxeProps, IMonthDatePickerDeluxeState> {
    private currentYear: number = moment(new Date()).year();
    private currentMonth: number = moment(new Date()).month();

    public constructor(props: IUnitDatePickerDeluxeProps) {
        super(props);

        const state: IMonthDatePickerDeluxeState = {
            displayedYear: this.currentYear,
            selectedYear: this.currentYear,
            selectedMonth: this.currentMonth
        };
        this.state = state;

        this.renderRow = this.renderRow.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.setSelectedMonth = this.setSelectedMonth.bind(this);
        this.increaseYear = this.increaseYear.bind(this);
        this.decreaseYear = this.decreaseYear.bind(this);
    }

    public componentWillReceiveProps(nextProps: IUnitDatePickerDeluxeProps): void {
        if (nextProps.isActive !== this.props.isActive) {
            if (nextProps.isActive) {
                if(nextProps.newStartDate === undefined){
                    this.setSelectedMonth(this.state.selectedMonth);
                }
                else{
                    this.setState({
                        displayedYear: nextProps.newStartDate.getFullYear(),
                        selectedYear: nextProps.newStartDate.getFullYear(),
                        selectedMonth: nextProps.newStartDate.getMonth()
                    }, () => this.setSelectedMonth(this.state.selectedMonth));
                }
            }
        }

        if (nextProps.newStartDate !== undefined && nextProps.newStartDate !== this.props.newStartDate && nextProps.isActive) {
            this.setState({
                displayedYear: nextProps.newStartDate.getFullYear(),
                selectedYear: nextProps.newStartDate.getFullYear(),
                selectedMonth: nextProps.newStartDate.getMonth()
            });
        }
    }

    private setSelectedMonth(month: number): void {
        this.setState({
            selectedMonth: month,
            selectedYear: this.state.displayedYear
        });

        const startOfMonth = moment(`1-${month + 1}-${this.state.displayedYear}`, "DD-MM-YYYY");
        this.props.onDateChange('month', startOfMonth.toDate(), startOfMonth.add(1, 'month').subtract('1', 'day').toDate());
    }

    private renderRow(from: number, until: number): JSX.Element {
        const cols: JSX.Element[] = [];

        const labels = LanguageProvider.getTranslations(["january", "february", "march", "april", "may", "june", "july", "august",
            "september", "october", "november", "december"].map(d => `dates.${d}`));

        for (let i = from; i <= until; i++) {
            const item = <MonthDatePickerTile
                key={i}
                month={i}
                label={labels[i]}
                displayedYear={this.state.displayedYear}
                selected={this.state.selectedMonth === i && this.state.selectedYear === this.state.displayedYear}
                onMonthSelect={this.setSelectedMonth} />;
            cols.push(item);
        }

        return (
            <tr key={from}>{cols}</tr>
        );
    }

    private renderRows(): JSX.Element[] {
        const rows: JSX.Element[] = [];
        for (let i = 0; i < 4; i++) {
            rows.push(this.renderRow(i * 3, 2 + (i * 3)));
        }
        return rows;
    }

    private increaseYear(): void {
        this.setState({
            displayedYear: this.state.displayedYear + 1
        });
    }

    private decreaseYear(): void {
        this.setState({
            displayedYear: this.state.displayedYear - 1
        });
    }

    public render(): JSX.Element {
        return (<div className={(this.props.isActive ? "" : "d-none")}>
            <div className="sliding-selection pb-5">
                <div className="w-25 d-inline-block align-bottom">
                    <div onClick={this.decreaseYear} className="sliding-selection-container text-center clickable float-right">
                        <img src={ArrowLeft} />
                    </div>
                </div>
                <div className="d-inline-block w-50 text-center" >
                    {this.state.displayedYear}
                </div>
                <div className="w-25 d-inline-block">
                    <div onClick={this.increaseYear} className="sliding-selection-container text-center clickable">
                        <img src={ArrowRight} />
                    </div>
                </div>
            </div>
            <table className="unit-calendar table-border w-100">
                <tbody>{this.renderRows()}</tbody>
            </table>
        </div>);
    }
}

export default MonthDatePickerDeluxe;

class MonthDatePickerTile extends React.Component<IMonthDatePickerTileProps> {
    private currentYear: number = moment(new Date()).year();
    private currentMonth: number = moment(new Date()).month();

    public constructor(props: IMonthDatePickerTileProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    private get isDisabled(): boolean {
        return (this.props.month > this.currentMonth && this.props.displayedYear === this.currentYear)
            || this.props.displayedYear > this.currentYear;
    }

    private onClick(): void {
        if (this.isDisabled) {
            return;
        }

        this.props.onMonthSelect(this.props.month);
    }

    public render(): JSX.Element {
        return (
            <td
                key={this.props.month}
                onClick={this.onClick}
                className={"text-center "
                    + (this.isDisabled ? "disabled " : "clickable ")
                    + (this.props.month === this.currentMonth && this.props.displayedYear === this.currentYear ? "current " : "")
                    + (this.props.selected ? "selected" : "")}>
                {this.props.label}
            </td>
        );
    }
}

interface IMonthDatePickerTileProps {
    displayedYear: number;
    month: number;
    label: string;
    selected: boolean;
    onMonthSelect: (month: number) => void;
}