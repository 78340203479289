import { useEffect, useState } from "react";
import { NotificationManager } from 'react-notifications';

import { ApplicationConfig } from '@/config';
import LanguageProvider from '@/providers/languageProvider';
import AdminMessagePriority from '@/enums/adminMessagePriority';
import IAdministratorMessage from '@/interfaces/IAdministratorMessage';
import DashboardService from '@/services/dashboardService';
import './adminMessageBar.scss';

const AdminMessageBar: React.FC = () => {

    const [message, setMessage] = useState<IAdministratorMessage>();

    const browserStorageItemName = `bs.${ApplicationConfig.environment}.acknowledged.adminmessage`;
    const browserStorageUpdateSuccessItemName = `bs.${ApplicationConfig.environment}.updatesuccess`;
    const dashboardService = new DashboardService();

    useEffect(() => {
        checkForAdminMessages();

        if (navigator?.serviceWorker != null) {
            checkForUpdate();

            setInterval(checkForUpdate, 5 * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function checkForUpdate(): void {

        const updateSuccesFull = window.sessionStorage.getItem(browserStorageUpdateSuccessItemName);

        if (updateSuccesFull) {
            setTimeout(showSucces, 1000);
            window.sessionStorage.removeItem(browserStorageUpdateSuccessItemName);
        }

        if (navigator.serviceWorker) {
            navigator.serviceWorker.ready?.then((status) => {
                if (status.waiting) {
                    setMessage({ body: LanguageProvider.getTranslation("adminmessagebar.new-app-version"), priority: AdminMessagePriority.Update });
                }
            });
        }
    }

    function doShowMessage(cachedMessage: IAdministratorMessage, apiMessage: IAdministratorMessage): boolean {

        const timeDiff = new Date().getTime() - new Date(cachedMessage.cachedDate!).getTime();
        const halfAnHour = 30 * 60 * 1000;

        if (timeDiff >= halfAnHour) {
            window.sessionStorage.removeItem(browserStorageItemName);
            return true;
        }

        apiMessage.cachedDate = cachedMessage.cachedDate;

        if (JSON.stringify(apiMessage) !== JSON.stringify(cachedMessage)) {
            window.sessionStorage.removeItem(browserStorageItemName);
            return true;
        }

        return false;
    }

    async function checkForAdminMessages(): Promise<void> {
        const apiMessage = await dashboardService.getAdminMessage();
        const cachedMessageItem = window.sessionStorage.getItem(browserStorageItemName);

        if (apiMessage) {
            if (cachedMessageItem) {
                const cachedMessage = JSON.parse(cachedMessageItem) as IAdministratorMessage;

                if (!doShowMessage(cachedMessage, apiMessage)) {
                    return;
                }
            }
            setMessage(apiMessage);
        }
    }

    function updateApplication(): void {

        if (navigator.serviceWorker) {
            setMessage(undefined);

            navigator.serviceWorker.ready?.then((status) => {
                if (status?.waiting != null) {
                    status?.waiting.postMessage({ type: "SKIP_WAITING" });
                    setTimeout(reload, 2500);
                }
            });
        }
    }

    function reload(): void {
        window.sessionStorage.setItem(browserStorageUpdateSuccessItemName, 'true');
        document.location.assign(document.location.origin + "/");
    }

    function showSucces(): void {
        NotificationManager.success(LanguageProvider.getTranslation("adminmessagebar.updatesucces"));
    }
    function hide(): void {
        message!.cachedDate = new Date().toString();
        window.sessionStorage.setItem(browserStorageItemName, JSON.stringify(message));
        NotificationManager.success(LanguageProvider.getTranslation("adminmessagebar.adminmessagehidden"));
        setMessage(undefined);
    }

    return (
        <>
            {message && <div className="admin-message-bar-container">
                <div className={`message ${AdminMessagePriority[message.priority].toLowerCase()}-prio`} onClick={(): void => { if (message.priority === AdminMessagePriority.Update) { updateApplication() } }}>
                    {(message.title && message.body) && <div><b>{message.title}</b>: {message.body} </div>}
                    {(message.title && !message.body) && <div>{message.title}</div>}
                    {(!message.title && message.body) && <div>{message.body}</div>}

                    {(message.priority === AdminMessagePriority.Low || message.priority === AdminMessagePriority.Medium) && <div><a href="#" className={`close ${AdminMessagePriority[message.priority].toLowerCase()}`} onClick={hide} /></div>}
                </div>
            </div>}
        </>);
}

export default AdminMessageBar;