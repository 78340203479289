export default class Serializable {

    public fromJSON(json: any): this {

        for (const propName in json) {
            if (json.hasOwnProperty(propName)) {
                this[propName] = json[propName];
            }
        }

        return this;
    }
}