import * as React from "react";
import { withTranslation } from "react-i18next";

import TrashcanService from "@/services/trashcanService";
import CoreSpaceDatatypes from "../../../../../enums/coreSpaceDatatypes";
import IPaperbinsSettingsTabProps from "./interfaces/IPaperbinsSettingsTabProps";
import IPaperbinsSettingsTabState from "./interfaces/IPaperbinsSettingsTabState";
import IThreshold from "../../../../../interfaces/IThreshold";
import SanitarySettingsTab from "./sanitarySettingsTab";

class PaperbinsSettingsTab extends React.Component<IPaperbinsSettingsTabProps, IPaperbinsSettingsTabState> {
    private readonly trashcanService: TrashcanService;

    public constructor(props: IPaperbinsSettingsTabProps) {
        super(props);

        this.trashcanService = new TrashcanService();
        this.getDefaultThreshold = this.getDefaultThreshold.bind(this);
        this.getAllSanitaryThresholds = this.getAllSanitaryThresholds.bind(this);
        this.upsertThresholds = this.upsertThresholds.bind(this);
    }

    private getDefaultThreshold(): IThreshold {
        return {
            assetId: "",
            notificationEnabled: false,
            threshold: 60
        };
    }

    private async getAllSanitaryThresholds(): Promise<IThreshold[]> {
        const trashcanThresholds = await this.trashcanService.getAllTrashcanThresholds(true);
        return trashcanThresholds.map(threshold => ({
            assetId: threshold.assetId,
            notificationEnabled: threshold.notificationEnabled,
            threshold: threshold.thresholdPercentage,
        }));
    }

    private async upsertThresholds(thresholds: IThreshold[]): Promise<Response> {
        const trashcanThresholds = thresholds.map(t => ({
            assetId: t.assetId,
            notificationEnabled: t.notificationEnabled,
            thresholdPercentage: t.threshold,
        }));
        return await this.trashcanService.batchUpsertVenueSettings(trashcanThresholds);
    }

    public render(): JSX.Element {
        return (
            <SanitarySettingsTab
                tabName="paperbinstab"
                defaultThreshold={this.getDefaultThreshold()}
                dataType={CoreSpaceDatatypes.Scale}
                getThresholds={this.getAllSanitaryThresholds}
                upsertThresholds={this.upsertThresholds}
                sliderDomain={[0, 100]}
                sliderStep={5}
            />
        );
    }
}

export default withTranslation()(PaperbinsSettingsTab);