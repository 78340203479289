export default class ColorUtils {
    public static numberToGradientColor(number: number, upperbound: number, isLegend: boolean = false): string {
        number = Math.max(0, Math.min(number, upperbound));
        return ColorUtils.percentageToGradientColor(((number / upperbound) * 100), isLegend);
    }

    public static percentageToGradientColor(perc: number, isLegend: boolean = false): string {
        let startColor = [0, 0, 255];
        let firstIntervalColor = [0, 255, 0];
        let secondIntervalColor = [255, 255, 0];
        let endColor = [255, 0, 0];

        // If legend, reduce saturation with 50% to match map opaqueness
        if (isLegend) {
            startColor = [127, 127, 255];
            firstIntervalColor = [127, 255, 127];
            secondIntervalColor = [255, 255, 127];
            endColor = [255, 127, 127];
        }

        const calculateGradient = (from: number, to: number, percentage: number): number => Math.round(from + (to - from) * (percentage / 100));

        let r = 0;
        let g = 0;
        let b = 0;

        if (perc <= 35) {
            perc = perc * 2.8;
            r = calculateGradient(startColor[0], firstIntervalColor[0], perc);
            g = calculateGradient(startColor[1], firstIntervalColor[1], perc);
            b = calculateGradient(startColor[2], firstIntervalColor[2], perc);
        }

        else if (perc > 35 && perc <= 55) {
            perc = (perc - 35) * 5;
            r = calculateGradient(firstIntervalColor[0], secondIntervalColor[0], perc);
            g = calculateGradient(firstIntervalColor[1], secondIntervalColor[1], perc);
            b = calculateGradient(firstIntervalColor[2], secondIntervalColor[2], perc);
        }

        else if (perc > 55) {
            perc = (perc - 55) * 2.22;
            r = calculateGradient(secondIntervalColor[0], endColor[0], perc);
            g = calculateGradient(secondIntervalColor[1], endColor[1], perc);
            b = calculateGradient(secondIntervalColor[2], endColor[2], perc);
        }

        const hex = r * 0x10000 + g * 0x100 + b * 0x1;
        return '#' + ('000000' + hex.toString(16)).slice(-6);
    }
}