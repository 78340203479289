import { Component } from "react";
import { PulseLoader } from 'react-spinners';

import Colors from "@/styles/colors";

interface ICenteredPageLoaderProps {
    loading: boolean;
    color?: string | undefined;
    size?: number;
}

export default class CenteredPageLoader extends Component<ICenteredPageLoaderProps> {

    public constructor(props: ICenteredPageLoaderProps) {
        super(props);
    }

    public render(): JSX.Element {

        const color = this.props.color ?? Colors.midnight_black;

        return (
            <div className="d-flex justify-content-center align-items-center">
                <PulseLoader color={color} size={this.props.size ?? 8} margin="15px" loading={this.props.loading} />
            </div>
        );
    }
}