import * as React from "react";
import { withTranslation } from "react-i18next";
import { withTelemetry } from "@/services/telemetryService";

import IPollutionMapPageProps from "./interfaces/IPollutionMapPageProps";
import IPollutionMapPageState from "./interfaces/IPollutionMapPageState";
import PollutionMapLegend from "./components/pollutionMapLegend";
import VenueProvider from "@/providers/venueProvider";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";
import PollutionStyler from "@/utils/stylers/pollutionStyler";
import { ISelectorEvent, IFloorChangeEvent, IAutodeskMapService, IVector } from "@beyondeyes/shared";
import CoreSpace from "models/coreSpace";
import { DashboardMapOptions } from "@/components/dashboardMap/interfaces/options";
import AutodeskMap from "autodeskMap/components/autodeskMapWrapper";
import DashboardMapService from "@/components/dashboardMap/interfaces/DashboardMapService";
import { SpaceUtils } from "@/utils/spaceUtils";
import { MapPopup } from "@/pages/map/components/mapPopup";

import "./pollutionMapPage.scss";

class PollutionMapPage extends React.Component<IPollutionMapPageProps, IPollutionMapPageState> {
	private readonly pollutionStyler: PollutionStyler;
	private readonly mapService: IAutodeskMapService;

	public constructor(props: IPollutionMapPageProps) {
		super(props);

		const state: IPollutionMapPageState = {
			loading: true,
			showPopup: false,
			markerSelected: false,
		};

		this.state = state;

		this.pollutionStyler = new PollutionStyler();
		this.mapService = new DashboardMapService(new DashboardMapOptions());

		this.onPlaceClickAsync = this.onPlaceClickAsync.bind(this);
		this.onFloorChangeAsync = this.onFloorChangeAsync.bind(this);
		this.onBeforeFloorChangeAsync = this.onBeforeFloorChangeAsync.bind(this);
		this.showPopup = this.showPopup.bind(this);
	}

	public async componentDidMount(): Promise<void> {
		this.setState({
			loading: false,
		});
	}

	private async onPlaceClickAsync(eventContext: ISelectorEvent): Promise<void> {
		const space = eventContext.space?.beSpace as CoreSpace;

		if (!space || !space.hasMotionDevices) {
			this.setState({ showPopup: false });
			return;
		}

		const pollutionPopup = new MapPopup(
			space as CoreSpace,
			{
				showCleaningTime: true,
				showCleaningStatus: true,
				showComfortValues: false,
				showOccupancyTile: false
			},
			() => this.setState({ showPopup: false }));

		this.showPopup(eventContext.space!.position, pollutionPopup.getHtmlToRender());
	}

	private showPopup(position: IVector, content: JSX.Element): void {
		this.setState({
			popupContent: {
				content: content,
				position: { ...position },
			},
			showPopup: true,
		});
	}

	private async onBeforeFloorChangeAsync(): Promise<void> {
		this.setState({
			showPopup: false
		});
	}

	private async onFloorChangeAsync(eventContext: IFloorChangeEvent): Promise<void> {
		this.setState({
			spaces: SpaceUtils.getSpacesWithStyle(eventContext.renderedSpaces, this.pollutionStyler)
		});
	}

	public render(): JSX.Element {
		const venue = VenueProvider.getActiveVenue();
		const venueName = venue?.name ?? "";

		return (
			<div className="main-content map" id="pollution-map-page">
				<div className="main-content-header">
					<div className="row header-margin-bottom-h1">
						<div className="col-sm">
							<h1>{venueName}</h1>
						</div>
					</div>
				</div>
				<div className="main-content-body">
					{this.state.loading && (
						<div id="map-loader">
							<div className="w-100 h-100">
								<div className="w-100 h-100 map-margin-correction d-flex justify-content-center">
									<CenteredPageLoader loading={this.state.loading} />
								</div>
							</div>
						</div>
					)}
					<div className="map" id="pollution-map-container">
						<AutodeskMap
							onFloorChangedAsync={this.onFloorChangeAsync}
							onPlaceClickAsync={this.onPlaceClickAsync}
							markers={this.state.markers}
							spaces={this.state.spaces}
							mapService={this.mapService}
							startWithFirstFloor={true}
							showPopup={this.state.showPopup}
							popupContent={this.state.popupContent}
							onBeforeFloorChangeAsync={this.onBeforeFloorChangeAsync}
						/>
					</div>
					<PollutionMapLegend lowerbound={this.pollutionStyler.lowerbound} upperbound={this.pollutionStyler.upperbound} />
				</div>
			</div>
		);
	}
}

export default withTranslation()(withTelemetry(PollutionMapPage, "PollutionMapPage"));
