import React, { useEffect } from 'react';

import ReservationInsightsFilter from '../interfaces/reservationInsightsFilter';
import ReportFilter from './reportfilter/reportFilter';
import LanguageProvider from '../../../providers/languageProvider';

import './reservationInsightsPage.scss';
import ReservedTimeChart from './charts/reservedTimeChart';
import ReservedTimeGrid from './charts/reservedTimeGrid';

const ReservationInsightsPage: React.FC<{}> = (props) => {

    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    function handleFilterChange(filter: ReservationInsightsFilter): void {
        // Update data
    }

    return (<>
        <div>
            <ReportFilter onChange={handleFilterChange} id="reportFilter" />
        </div>
        <div className="d-flex">
            <div className="widget" >
                <div className="gereserveerde-tijd">
                    <ReservedTimeChart
                        id="gereserveerdeTijd"
                        percentage={28}
                        title={LanguageProvider.getTranslation("pages.realestateutilization.charts.reservedtimechart.reservedtime")}
                    />
                </div>
                <div className="gereserveerde-plekken">
                    <div>
                        <h6>{LanguageProvider.getTranslation("pages.realestateutilization.charts.reservedtimechart.reservedplaces")}</h6>
                        <div>{LanguageProvider.getTranslation("pages.realestateutilization.charts.reservedtimechart.reservedplacesaverage")} 42 {LanguageProvider.getTranslation("pages.realestateutilization.charts.reservedtimechart.of")} 117</div>
                    </div>
                </div>
            </div>
            <div className="widget">
                <ReservedTimeGrid startHour={5} endHour={17}></ReservedTimeGrid>
            </div>
        </div>
    </>);
}

export default ReservationInsightsPage;