import './comfortInsightsPage.scss';

import { nl } from 'date-fns/esm/locale';
import moment from 'moment';
import * as React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import Select, { OnChangeValue } from 'react-select';

import CoreSpaceDatatypes from '../../enums/coreSpaceDatatypes';
import CoreSpaceIncludes from '../../enums/coreSpaceIncludes';
import IReactSelectValue from '../../interfaces/IReactSelectValue';
import ColorProvider from '../../providers/colorProvider';
import LanguageProvider from '../../providers/languageProvider';
import VenueProvider from '../../providers/venueProvider';
import CoreSpaceService from '../../services/coreSpaceService';
import DataThresholdsService from '../../services/dataThresholdsService';
import { withTelemetry } from '../../services/telemetryService';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import Dictionary from '../../utils/dictionary';
import SelectBoxUtils from '../../utils/selectBoxUtils';
import DailyComfortOverviewGraph from './components/dailyComfortOverviewGraph';
import HourlyComfortOverviewGraph from './components/hourlyComfortOverviewGraph';
import IComfortInsightsPageState from './interfaces/IComfortInsightsPageState';
import IFloorSelectValue from '../../interfaces/IFloorSelectValue';
import IGraphSelectValue from '../realestateutilization/interfaces/IGraphSelectValue';
import IInsightsOverviewProps from '../realestateutilization/interfaces/IInsightsOverviewProps';
import FloorSelect from "../../components/select/floorSelect";
import InsightsMap from 'pages/realestateutilization/components/insightsMap';

class ComfortInsightsPage extends React.Component<IInsightsOverviewProps, IComfortInsightsPageState> {
    private readonly coreSpaceService: CoreSpaceService;
    private readonly dataThresholdsService: DataThresholdsService;

    private comfortAssetsLookup: Dictionary<string>;

    public constructor(props: IInsightsOverviewProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        this.dataThresholdsService = new DataThresholdsService();

        this.comfortAssetsLookup = new Dictionary<string>();

        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        const dateDiff = startDate.getDay() === 1 ? 7 : (startDate.getDay() + 6) % 7;
        startDate.setDate(startDate.getDate() - dateDiff);
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        const comfortOptions = this.getComfortOptions();

        const state: IComfortInsightsPageState = {
            loading: true,
            venueId: "",
            selectedRenderIds: [],
            selectedRenderType: "",
            endDate: endDate,
            startDate: startDate,
            comfortSelectionOptions: [],
            selectedComfortType: comfortOptions[0],
            selectedFloor: null,
            selectedComfortAssets: [],
            comfortTypeOptions: comfortOptions,
            renderMap: false,
            renderMapInitial: false,
            floors: []
        };

        this.state = state;

        this.onFloorChange = this.onFloorChange.bind(this);
        this.onAssetAdd = this.onAssetAdd.bind(this);
        this.onAssetRemove = this.onAssetRemove.bind(this);

        this.setActiveFloorAsync = this.setActiveFloorAsync.bind(this);
        this.setActiveComfortAsset = this.setActiveComfortAsset.bind(this);
        this.setActiveVenueAsync = this.setActiveVenueAsync.bind(this);
        this.setComfortType = this.setComfortType.bind(this);
        this.setTresholdsAndComfortType = this.setTresholdsAndComfortType.bind(this);
        this.clearFloorSelection = this.clearFloorSelection.bind(this);
        this.clearAssetSelection = this.clearAssetSelection.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.toggleMap = this.toggleMap.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.setActiveVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        registerLocale('nl', nl);
        setDefaultLocale('nl');

        await this.setActiveVenueAsync();

        this.setState({
            loading: false
        });
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.setActiveVenueAsync);
    }

    private getComfortOptions(): IReactSelectValue[] {
        return [
            { label: LanguageProvider.getTranslation("comfort.temperature"), value: "temperature" },
            { label: LanguageProvider.getTranslation("comfort.carbondioxide"), value: "carbondioxide" },
            { label: LanguageProvider.getTranslation("comfort.humidity"), value: "humidity" },
        ];
    }

    private async setActiveVenueAsync(): Promise<void> {
        this.comfortAssetsLookup = new Dictionary<string>();

        const activeVenue = VenueProvider.getActiveVenue();
        this.clearFloorSelection();
        this.setState({ comfortSelectionOptions: [] });

        if (!activeVenue) {
            return;
        }

        const floors = await this.coreSpaceService.getFloorsForVenue(activeVenue.id, [CoreSpaceIncludes.Properties]);

        this.setState({
            venueId: activeVenue.id,
            selectedRenderIds: [activeVenue.id],
            selectedRenderType: "venue",
            floors: floors
        });
        await this.setTresholdsAndComfortType();
    }

    private onFloorChange(floorLevel: number): void {
        const floor = this.state.floors.find(f => parseInt(f.floorLevel, 10) === floorLevel);

        if ((floor && !this.state.selectedFloor)
            || (floor && this.state.selectedFloor && this.state.selectedFloor.floorLevel !== floorLevel)) {
            this.setActiveFloorAsync({ value: floor.id, label: floor.name, floorLevel: floorLevel });
        }
    }

    private onAssetAdd(selectedValue: IReactSelectValue): void {
        if (!selectedValue) {
            return;
        }

        const selectedAssets = this.state.selectedComfortAssets.filter((asset) => asset.value !== selectedValue.value);
        this.setActiveComfortAsset(selectedAssets.concat(selectedValue as IGraphSelectValue));
    }

    private onAssetRemove(selectedValue: IReactSelectValue): void {
        if (!selectedValue) {
            return;
        }

        this.setActiveComfortAsset(this.state.selectedComfortAssets?.filter(asset => asset.value !== selectedValue.value) ?? []);
    }

    private async setActiveFloorAsync(selectedItem: OnChangeValue<IFloorSelectValue, false>): Promise<void> {
        const item = selectedItem as IFloorSelectValue | null;
        if (!item) {
            this.setState({
                selectedFloor: item
            }, this.clearAssetSelection);
            return;
        }

        if (item.isDefault) {
            this.setState({
                selectedRenderIds: [this.state.venueId],
                selectedRenderType: "venue",
                selectedFloor: null,
                comfortSelectionOptions: [],
                selectedComfortAssets: []
            });

            return;
        }

        const floorId = item.value;
        const assets = await this.coreSpaceService.getSpacesForFloor(this.state.venueId, floorId, undefined, undefined, [CoreSpaceDatatypes.Comfort]);

        assets.forEach(a => this.comfortAssetsLookup.add(a.id, a.id));

        const comfortSelectionOptions = assets.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
            .map((a, i) => ({
                value: a.id,
                label: a.name,
                color: ColorProvider.getNextSemiRandomBackgroundColorForIndex(i),
                textColor: ColorProvider.getNextSemiRandomTextColorForIndex(i)
            }));

        this.setState({
            selectedRenderIds: [item.value],
            selectedFloor: item,
            selectedRenderType: "floor",
            comfortSelectionOptions: comfortSelectionOptions,
            selectedComfortAssets: []
        });
    }

    private setActiveComfortAsset(selectedItems: OnChangeValue<IGraphSelectValue, true>): void {
        const items = selectedItems as IGraphSelectValue[];

        this.setState({
            selectedComfortAssets: items,
        });
    }

    private async setTresholdsAndComfortType(selectedType?: IReactSelectValue): Promise<void> {
        const dataType = selectedType ? selectedType.value : this.state.selectedComfortType.value;
        const thresholds = await this.dataThresholdsService.getDataThresholdsForType(dataType);

        const stateUpdate = {
            graphTresholdMax: thresholds.upperThreshold,
            graphTresholdMin: thresholds.lowerThreshold
        };

        if (selectedType) {
            stateUpdate["selectedComfortType"] = selectedType;
        }

        this.setState(stateUpdate);
    }

    private setComfortType(selectedItem: OnChangeValue<IReactSelectValue, false>): void {
        if (selectedItem === null || selectedItem === undefined) {
            return;
        }

        const item = selectedItem as IReactSelectValue;

        // Async call not awaited here, since this method has to be sync. It is still executed.
        this.setTresholdsAndComfortType(item);
    }

    private handleStartDateChange(state: Date): void {
        this.setState({
            startDate: state
        });
    }

    private handleEndDateChange(state: Date): void {
        this.setState({
            endDate: state
        });
    }

    private clearAssetSelection(): void {
        this.setState({
            selectedRenderIds: this.state.selectedFloor ? [this.state.selectedFloor.value] : [],
            selectedRenderType: "floor",
            comfortSelectionOptions: [],
            selectedComfortAssets: []
        });
    }

    private clearFloorSelection(): void {
        this.clearAssetSelection();
        this.setState({
            selectedRenderIds: [this.state.venueId],
            selectedRenderType: "venue",
            selectedFloor: null,
            selectedComfortAssets: []
        });
    }

    private toggleMap(): void {
        this.setState({
            renderMap: !this.state.renderMap,
            renderMapInitial: true
        });
    }

    public render(): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation("buttons.dropdowns.nooptions");
        const venue = VenueProvider.getActiveVenue();
        const venueName = venue?.name ?? "";

        return (<>
            <div id="comfort-insights-page" className="main-content">
                <div className="main-content-header">
                    <div className="row">
                        <div className="col-sm">
                            <h1>{venueName}</h1>
                        </div>
                    </div>
                    <div className="row input-selection">
                        <div className="col-sm d-flex">
                            <div className="input-field-m mr-2">
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    maxDate={this.state.endDate}
                                    placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
                                    className="asset-datepicker clickable"
                                    dateFormat="dd-MM-yyyy"
                                    todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                />
                            </div>
                            <div className="input-field-m mr-2">
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    minDate={this.state.startDate}
                                    maxDate={moment().toDate()}
                                    placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
                                    className="asset-datepicker clickable"
                                    dateFormat="dd-MM-yyyy"
                                    todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                />
                            </div>
                            <div className="input-field-l mr-2" id="select-comfort-type">
                                <Select
                                    value={this.state.selectedComfortType}
                                    onChange={this.setComfortType}
                                    options={this.state.comfortTypeOptions}
                                    placeholder={`${LanguageProvider.getTranslation("buttons.dropdowns.comfortasset")}...`}
                                    noOptionsMessage={noOptionsMessage}
                                    styles={SelectBoxUtils.getDefaultSelectStyles(40)}
                                />
                            </div>
                            <div className="input-field-l mr-2" id="select-floor-type">
                                <FloorSelect
                                    floors={this.state.floors}
                                    setActiveFloor={this.setActiveFloorAsync}
                                    isDisabled={this.state.renderMap}
                                    selectedFloor={this.state.selectedFloor}
                                />
                            </div>
                            <div className="input-field-l mr-2" id="select-comfort-assets">
                                <Select
                                    value={this.state.selectedComfortAssets}
                                    onChange={this.setActiveComfortAsset}
                                    options={this.state.comfortSelectionOptions}
                                    isMulti={true}
                                    placeholder={`${LanguageProvider.getTranslation("buttons.dropdowns.asset")}`}
                                    noOptionsMessage={noOptionsMessage}
                                    styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)}
                                    classNamePrefix={"asset-multi-select"}
                                />
                            </div>
                            <div className="show-map-button ml-auto">
                                <button id="show-map" className="btn btn-secondary" onClick={this.toggleMap}>
                                    {this.state.renderMap ? LanguageProvider.getTranslation("buttons.map.hide") : LanguageProvider.getTranslation("buttons.map.show")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content-body">
                    {
                        this.state.renderMapInitial &&
                        <div className={`row ${(this.state.renderMap ? "d-block" : "d-none")}`}>
                            <InsightsMap
                                selectedFloor={this.state.selectedFloor}
                                assetOptions={this.state.comfortSelectionOptions}
                                selectedAssets={this.state.selectedComfortAssets}
                                onAssetAdd={this.onAssetAdd}
                                onAssetRemove={this.onAssetRemove}
                                onFloorChange={this.onFloorChange}
                                venueId={this.state.venueId}
                                isDisplayed={this.state.renderMap}
                            />
                        </div>
                    }
                    {
                        !this.state.renderMap &&
                        <div className="row">
                            <div className="col-sm-6">
                                <h2>{LanguageProvider.getTranslation("pages.comfortinsights.reports.graph.comfort.dailytitle")}</h2>
                                <div className="pt-3">
                                    <DailyComfortOverviewGraph
                                        assetIds={this.state.selectedComfortAssets}
                                        selectedComfortType={this.state.selectedComfortType}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        width={20}
                                        height={8}
                                        thresholdHigh={this.state.graphTresholdMax}
                                        thresholdLow={this.state.graphTresholdMin}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <h2>{LanguageProvider.getTranslation("pages.comfortinsights.reports.graph.comfort.hourlytitle")}</h2>
                                <div className="pt-3">
                                    <HourlyComfortOverviewGraph
                                        assetIds={this.state.selectedComfortAssets}
                                        selectedComfortType={this.state.selectedComfortType}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        width={20}
                                        height={8}
                                        thresholdHigh={this.state.graphTresholdMax}
                                        thresholdLow={this.state.graphTresholdMin}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
        );
    }
}

export default withTelemetry(ComfortInsightsPage, "ComfortInsightsPage");