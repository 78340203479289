import React, { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';

import LanguageProvider from '../../../../providers/languageProvider';
import ReservationInsightsFilter from '../../interfaces/reservationInsightsFilter';
import IReactSelectValue from '../../../../interfaces/IReactSelectValue';
import MultiSelectCheckBoxes from '../MultiSelectCheckboxes/multiSelectCheckboxes';
import HourFilter from '../hourFilter/hourFilter';
import TempReservationMockData from '../tempMockData';

import './reportFilter.scss';

interface ReportFilterProps {
    id?: string;
    onChange: (filter: ReservationInsightsFilter) => void;
}

const ReportFilter: React.FC<ReportFilterProps> = (props) => {

    const MockData = TempReservationMockData;

    const [filter, setFilter] = useState<ReservationInsightsFilter>(new ReservationInsightsFilter());
    const [minStartDate] = useState<Date | undefined>(new Date());
    const [maxStartDate] = useState<Date | undefined>(new Date());
    const [minEndDate] = useState<Date | undefined>(new Date());
    const [maxEndDate] = useState<Date | undefined>(() => { const date = new Date(); date.setDate(date.getDate() + 7); return date; });

    const [weekOptions] = useState<IReactSelectValue[]>(MockData.generateWeekOptions());
    const [dayOfWeekOptions] = useState<IReactSelectValue[]>(MockData.generateDayOptions());
    const [holidayOptions] = useState<IReactSelectValue[]>(MockData.generateHolidayOptions());
    const [venueOptions] = useState<IReactSelectValue[]>(MockData.generateVenueOptions());
    const [floorOptions] = useState<IReactSelectValue[]>(MockData.generateFloorOptions());
    const [placeOptions] = useState<IReactSelectValue[]>(MockData.generatePlaceOptions());
    const [placeTypeOptions] = useState<IReactSelectValue[]>(MockData.generatePlaceTypeOptions());

    useEffect(() => {
        const savedFilter = localStorage.getItem('reservationFilter');

        if (savedFilter) {
            const parsedFilter = JSON.parse(savedFilter);
            const filterWithDates = convertDates(parsedFilter) as ReservationInsightsFilter;

            setFilter(filterWithDates);
        } else {
            setFilter({ ...filter, startDate: minStartDate, endDate: maxStartDate });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        saveFilterToLocalStorage();
    }, [filter]);

    function handleStartDateChange(date: Date | null): void {
        setFilter({ ...filter, startDate: date || undefined });
    }

    function handleEndDateChange(date: Date | null): void {
        setFilter({ ...filter, endDate: date || undefined });
    }

    function handleWeeksChanged(weeks: MultiValue<IReactSelectValue>): void {
        setFilter({ ...filter, weeks: weeks.map(item => item) });
    }

    function handleDayOfWeekChanged(days: MultiValue<IReactSelectValue>): void {
        setFilter({ ...filter, weekdays: days.map(item => item) });
    }

    function handleHolidayChanged(holidays: MultiValue<IReactSelectValue>): void {
        setFilter({ ...filter, holidays: holidays.map(item => item) });
    }

    function handleHourChanged(hourFrom: number, hourTo: number): void {
        setFilter({ ...filter, fromTime: hourFrom, toTime: hourTo });
    }

    function handleVenueChanged(venues: MultiValue<IReactSelectValue>): void {
        setFilter({ ...filter, venues: venues.map(item => item) });
    }

    function handleFloorChanged(floors: MultiValue<IReactSelectValue>): void {
        setFilter({ ...filter, floors: floors.map(item => item) });
    }

    function handlePlaceChanged(places: MultiValue<IReactSelectValue>): void {
        setFilter({ ...filter, places: places.map(item => item) });
    }

    function handlePlaceTypesChanged(placeTypes: MultiValue<IReactSelectValue>): void {
        setFilter({ ...filter, typeOfPlaces: placeTypes.map(item => item) });
    }

    function saveFilterToLocalStorage(): void {
        localStorage.setItem('reservationFilter', JSON.stringify(filter));
    }

    function clearFilter(): void {
        const newFilter = new ReservationInsightsFilter();
        setFilter({ ...newFilter, startDate: minStartDate, endDate: maxEndDate });
    }

    function convertDates(obj: any): any {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (typeof value === 'string' && !isNaN(Date.parse(value))) {
                    obj[key] = parseISO(value);
                }
            }
        }
        return obj;
    }

    return (<div className="report-filter">
        <div className="filter-row">
            <div>
                <DatePicker
                    id="startDate"
                    selected={filter.startDate}
                    onChange={handleStartDateChange}
                    maxDate={filter.endDate}
                    minDate={minStartDate}
                    placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
                    className="datepicker clickable"
                    dateFormat="dd-MM-yyyy"
                    todayButton={LanguageProvider.getTranslation("datepicker.today")}
                />
            </div>
            <div>
                <DatePicker
                    id="endDate"
                    selected={filter.endDate}
                    onChange={handleEndDateChange}
                    maxDate={filter.endDate}
                    minDate={minEndDate}
                    placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
                    className="datepicker clickable"
                    dateFormat="dd-MM-yyyy"
                    todayButton={LanguageProvider.getTranslation("datepicker.today")}
                />
            </div>
            <div>
                <MultiSelectCheckBoxes
                    id="weekFilter"
                    options={weekOptions}
                    onChange={handleWeeksChanged}
                    initialSelectedOptions={filter.weeks || []}
                    placeholderLabel={LanguageProvider.getTranslation("pages.realestateutilization.filter.weeks")}
                />
            </div>
            <div>
                <MultiSelectCheckBoxes
                    id="dayOfWeekFilter"
                    options={dayOfWeekOptions}
                    onChange={handleDayOfWeekChanged}
                    initialSelectedOptions={filter.weekdays || []}
                    placeholderLabel={LanguageProvider.getTranslation("pages.realestateutilization.filter.days")}
                />
            </div>
            <div>
                <MultiSelectCheckBoxes
                    id="holidayFilter"
                    options={holidayOptions}
                    onChange={handleHolidayChanged}
                    initialSelectedOptions={filter.holidays || []}
                    placeholderLabel={LanguageProvider.getTranslation("pages.realestateutilization.filter.holidays")}
                />
            </div>
            <div>
                <HourFilter
                    id="hourFilter"
                    onChange={handleHourChanged}
                    initialHourFrom={filter.fromTime || 0}
                    initialHourTo={filter.toTime || 0}
                    minTime={0}
                    maxTime={24}
                />
            </div>
        </div>
        <div className="filter-row">
            <div>
                <MultiSelectCheckBoxes
                    id="venueFilter"
                    options={venueOptions}
                    onChange={handleVenueChanged}
                    initialSelectedOptions={filter.venues || []}
                    placeholderLabel={LanguageProvider.getTranslation("pages.realestateutilization.filter.venues")}
                />
            </div>
            <div>
                <MultiSelectCheckBoxes
                    id="floorFilter"
                    options={floorOptions}
                    onChange={handleFloorChanged}
                    initialSelectedOptions={filter.floors || []}
                    placeholderLabel={LanguageProvider.getTranslation("pages.realestateutilization.filter.floors")}
                />
            </div>
            <div>
                <MultiSelectCheckBoxes
                    id="placeFilter"
                    options={placeOptions}
                    onChange={handlePlaceChanged}
                    initialSelectedOptions={filter.places || []}
                    placeholderLabel={LanguageProvider.getTranslation("pages.realestateutilization.filter.places")}
                />
            </div>
            <div>
                <MultiSelectCheckBoxes
                    id="placeTypeFilter"
                    options={placeTypeOptions}
                    onChange={handlePlaceTypesChanged}
                    initialSelectedOptions={filter.typeOfPlaces || []}
                    placeholderLabel={LanguageProvider.getTranslation("pages.realestateutilization.filter.placeTypes")}
                />
            </div>
            <div>
                <button type="button" className="btn btn-secondary clear-filter" onClick={clearFilter}>Wissen</button>
            </div>
        </div>
    </div>);
}

export default ReportFilter;