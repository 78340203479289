import { ISpaceOnMap, ILabel, ISpaceStyle, IStyle, IStyler } from "@beyondeyes/shared";
import CoreSpace from "models/coreSpace";
import ColorUtils from "utils/colorUtils";
import Colors from "@/styles/colors";
import BaseStyler from "./baseStyler";

class PollutionStyler extends BaseStyler implements IStyler {
	public readonly lowerbound: number = 0;
	public readonly upperbound: number = 360;

	public constructor() {
		super();
		this.minutesToColor = this.minutesToColor.bind(this);
	}

	public getStyle(spaceOnMap: ISpaceOnMap): IStyle | undefined {
		const space = spaceOnMap.beSpace as CoreSpace;

		if (space == null) {
			return undefined;
		}

		if (!space.hasMotionDevices) {
			return this.getDefaultStyle(space);
		}

		const occupiedMinutes = space.correctedUsedTimeInMinutes;
		const color = this.minutesToColor(occupiedMinutes);

		const style: ISpaceStyle = {
			fillColor: color,
			fillOpacity: 0.95,
		};

		return {
			placeStyle: style,
			placeLabel: this.getLabel(space),
		};
	}

	public getDefaultStyle(space: CoreSpace): IStyle {
		return {
			placeStyle: {
				fillColor: Colors.delta_grey,
			}
		};
	}

	private getLabel(space: CoreSpace): ILabel {
		return {
			size: this.defaultLabelSize,
			text: space.name,
		};
	}

	private minutesToColor(min: number): string {
		return ColorUtils.numberToGradientColor(min, this.upperbound);
	}
}

export default PollutionStyler;
