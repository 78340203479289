import * as React from "react";

import IEnergyAssetExpandProps from "./interfaces/IEnergyAssetExpandProps";
import IEnergyAssetExpandState from "./interfaces/IEnergyAssetExpandState";
import EnergyContractArrow from "@/images/EnergyContractArrow.svg";

import "./energyAssetExpand.scss";

import ArrowRight from "@/images/Blue_Arrow_Right.svg";
import LanguageProvider from "@/providers/languageProvider";
import EnergyUnitType from "./enums/energyUnitType";

class EnergyAssetExpand extends React.Component<IEnergyAssetExpandProps, IEnergyAssetExpandState> {
    public constructor(props: IEnergyAssetExpandProps) {
        super(props);

        this.state = {
            expanded: false
        };

        this.toggleExpand = this.toggleExpand.bind(this);
        this.navigateToDetails = this.navigateToDetails.bind(this);
    }

    private toggleExpand(): void {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    private navigateToDetails(): void {
        this.props.navigateToDetails(this.props.dataTree.assetId, this.props.dataTree.nodeType, this.props.dataTree.name);
    }

    private get hasChildren(): boolean {
        return this.props.dataTree.children.length > 0 && this.props.depth < 5;
    }

    public render(): JSX.Element {
        return (
            <div className="energy-asset-expand">
                <div className={`row ${this.hasChildren ? "hover-cursor" : ""}`} onClick={this.toggleExpand}>
                    <div className={`col-sm-4 pr-0`}>
                        <div className={`expand-depth-${this.props.depth} expand-left expand-part`}>
                            {this.hasChildren && <img src={EnergyContractArrow} className={this.state.expanded ? "energy-arrow" : "expand-arrow energy-arrow"} />}
                            <span className={`pl-2 ${this.hasChildren ? "" : "no-arrow"}`}>
                                {this.props.dataTree.name}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-4 expand-center expand-part">
                        {Math.round(this.props.dataTree.usage)} {EnergyUnitType[this.props.unitType]}
                    </div>
                    <div className="col-sm-2 expand-center expand-part">
                        {Math.round(this.props.dataTree.percentage * 10) / 10}%
                    </div>
                    <div className="col-sm-2 expand-right expand-part clickable" onClick={this.navigateToDetails}>
                        {LanguageProvider.getTranslation("pages.energy.tabs.overview.details")}
                        <img className="ml-2" src={ArrowRight} />
                    </div>
                </div>
                <div className="pt-2">
                    {this.hasChildren && this.state.expanded && this.props.dataTree.children.map((ch, i) => {
                        return <EnergyAssetExpand
                            navigateToDetails={this.props.navigateToDetails}
                            dataTree={ch}
                            depth={this.props.depth + 1}
                            unitType={this.props.unitType}
                            key={i} />;
                    })}
                </div>
            </div >
        );
    }
}

export default EnergyAssetExpand;