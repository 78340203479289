import * as React from 'react';
import { withTranslation } from "react-i18next";
import Colors from '@/styles/colors';

import { ChartOptions, ChartData } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import ITemperatureScatterGraphProps from './interfaces/ITemperatureScatterGraphProps';
import ITemperatureScatterGraphState from './interfaces/ITemperatureScatterGraphState';
import Dictionary from '@/utils/dictionary';
import GraphLegend from 'components/graphs/graphLegend';
import CenteredPageLoader from 'components/loaders/centeredPageLoader';
import IHourlyEnergieMissieValue from 'interfaces/energy/IHourlyEnergieMissieValue';
import IBeSenseChartData from 'components/graphs/interfaces/IBeSenseChartData';
import DownloadIcon from "@/images/Download.svg";
import { CsvColumn, CsvExport } from '../models/csvExport';
import moment from 'moment';
import EnergyType from './enums/energyType';
import EnergyGraphPngButton from './energyGraphPngButton';
import EnergyUnitType from './enums/energyUnitType';

class TemperatureScatterGraph extends React.Component<ITemperatureScatterGraphProps, ITemperatureScatterGraphState> {
    protected chartInstance: Chart;

    public constructor(props: any) {
        super(props);

        this.getData = this.getData.bind(this);
        this.getChartOptions = this.getChartOptions.bind(this);
        this.chartCallback = this.chartCallback.bind(this);
        this.onLegendItemClick = this.onLegendItemClick.bind(this);
        this.formatCsvData = this.formatCsvData.bind(this);
        this.renderExportButtons = this.renderExportButtons.bind(this);
    }

    public componentDidUpdate(prevProps: ITemperatureScatterGraphProps): void {
        if (prevProps.measuredData !== this.props.measuredData && this.props.measuredData) {
            // force re-render for tooltip information
            this.setState({
                datasets: this.getData().datasets
            });
        }
    }

    private getData(): ChartData {
        const createKey = (year: number, month: number, day: number, hour: number): string => `${year}-${month}-${day}-${hour}`;

        const temperatureLookup = new Dictionary<number>();
        this.props.hourlyTemperatureData.forEach(
            t => temperatureLookup.add(createKey(t.localYear, t.localMonth, t.localDay, t.localHour), t.temperature)
        );

        const data: IBeSenseChartData = {
            datasets: this.props.measuredData.map(dataSet => {
                const presentData = dataSet.data.filter(d => d.actualUsage !== null);

                return {
                    label: dataSet.label,
                    labels: presentData.map(d => this.getLabelForDataElement(d, dataSet.label)),
                    data: presentData.map(d => (
                        { x: temperatureLookup.item(createKey(d.localYear, d.localMonth, d.localDay, d.localHour)), y: d.actualUsage ?? undefined }
                    )),
                    pointBorderColor: dataSet.color,
                    backgroundColor: dataSet.color,
                    renderLegendAsCircle: true
                };
            })
        };

        return data;
    }

    private getLabelForDataElement(dataElement: IHourlyEnergieMissieValue, dataSetLabel: string): string {
        return `${dataElement.localDay}/${dataElement.localMonth} ${dataElement.localHour}:00`;
    }

    private getLowerTemperatureBound(): number {
        const minAcrossData = Math.min(...this.props.hourlyTemperatureData.map(d => d.temperature));
        const lowestMultipleOfFive = ((Math.floor(minAcrossData / 5))) * 5;
        return Math.min(-10, lowestMultipleOfFive);
    }

    private getUpperTemperatureBound(): number {
        const maxAcrossData = Math.max(...this.props.hourlyTemperatureData.map(d => d.temperature));
        const highestMultipleOfFive = ((Math.ceil(maxAcrossData / 5))) * 5;
        return Math.max(35, highestMultipleOfFive);
    }

    private getChartOptions(): ChartOptions {
        const options: ChartOptions = {
            scales: {
                xAxes: [{
                    type: 'linear',
                    position: 'bottom',
                    ticks: {
                        min: this.getLowerTemperatureBound(),
                        max: this.getUpperTemperatureBound()
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "\u2103"
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'kWh'
                    },
                    id: 'bar',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        beginAtZero: true,
                        padding: 10
                    }
                }]
            },
            title: {
                display: false
            },
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: (item: any, data: any): string => {
                        let label = data.datasets[item.datasetIndex].labels[item.index];
                        const roundedUsage = Math.round(Number.parseFloat(item.yLabel) * 1000) / 1000;
                        label += ` (${item.xLabel}\u2103, ${roundedUsage} kWh)`;
                        return label;
                    }
                }
            },
            animation: {
                duration: 0
            }
        };

        return options;
    }

    private chartCallback(ref: Scatter): void {
        if (!ref) {
            return;
        }

        this.chartInstance = ref.chartInstance;
    }

    protected onLegendItemClick(): void {
        this.chartInstance.update();
    }

    private formatCsvData(): void {
        const csvExport = new CsvExport(this.props.exportOptions?.fileName ?? "export.csv");

        const createKey = (year: number, month: number, day: number, hour: number): string => `${year}-${month}-${day}-${hour}`;

        const temperatureLookup = new Dictionary<string>();
        this.props.hourlyTemperatureData.forEach(
            t => temperatureLookup.add(createKey(t.localYear, t.localMonth, t.localDay, t.localHour), CsvExport.stringifyWithAtLeastOneDecimal(t.temperature))
        );

        const electricityUsageLookup = new Dictionary<string>();
        const gasUsageLookup = new Dictionary<string>();
        const heatUsageLookup = new Dictionary<string>();
        const coldUsageLookup = new Dictionary<string>();

        let electricityEnabled = false;
        let gasEnabled = false;
        let heatEnabled = false;
        let coldEnabled = false;

        this.props.measuredData.forEach(ds => {
            if (ds.type === EnergyType.Electricity) {
                electricityEnabled = true;
                ds.data.forEach(d => electricityUsageLookup
                    .add(createKey(d.localYear, d.localMonth, d.localDay, d.localHour), d.actualUsage === null ? '' : CsvExport.stringifyWithAtLeastOneDecimal(d.actualUsage)));
            }
            else if (ds.type === EnergyType.Gas) {
                gasEnabled = true;
                ds.data.forEach(d => gasUsageLookup
                    .add(createKey(d.localYear, d.localMonth, d.localDay, d.localHour), d.actualUsage === null ? '' : CsvExport.stringifyWithAtLeastOneDecimal(d.actualUsage)));
            }
            else if (ds.type === EnergyType.Heat) {
                heatEnabled = true;
                ds.data.forEach(d => heatUsageLookup
                    .add(createKey(d.localYear, d.localMonth, d.localDay, d.localHour), d.actualUsage === null ? '' : CsvExport.stringifyWithAtLeastOneDecimal(d.actualUsage)));
            }
            else if (ds.type === EnergyType.Cold) {
                coldEnabled = true;
                ds.data.forEach(d => coldUsageLookup
                    .add(createKey(d.localYear, d.localMonth, d.localDay, d.localHour), d.actualUsage === null ? '' : CsvExport.stringifyWithAtLeastOneDecimal(d.actualUsage)));
            }
        });

        const current = moment(`1-1-${this.props.year}`, "DD-MM-YYYY");
        const timestampData: string[] = [];
        const temperatureData: string[] = [];
        const electricityData: string[] = [];
        const gasData: string[] = [];
        const heatData: string[] = [];
        const coldData: string[] = [];

        const now = moment();

        while (current.year() === this.props.year && current < now) {
            const key = createKey(current.year(), current.month() + 1, current.date(), current.hour());

            const timestamp = current.format("YYYY-MM-DD HH:mm");
            timestampData.push(timestamp);

            const temperature = temperatureLookup.item(key);
            temperatureData.push(temperature);

            const electricityUsage = electricityUsageLookup.item(key);
            electricityData.push(electricityUsage);

            const gasUsage = gasUsageLookup.item(key);
            gasData.push(gasUsage);

            const heatUsage = heatUsageLookup.item(key);
            heatData.push(heatUsage);

            const coldUsage = coldUsageLookup.item(key);
            coldData.push(coldUsage);

            current.add('1', 'hour');
        }

        const dateColumn: CsvColumn = {
            header: 'Timestamp',
            data: timestampData
        };

        const temperatureColumn = {
            header: 'Temperature',
            data: temperatureData
        };

        csvExport.AddColumn(dateColumn, temperatureColumn);
        const unitType = EnergyUnitType[EnergyUnitType.kWh];

        if (electricityEnabled) {
            const electricityColumn = {
                header: `ElectricityUsage (${unitType})`,
                data: electricityData
            };

            csvExport.AddColumn(electricityColumn);
        }

        if (gasEnabled) {
            const gasColumn = {
                header: `GasUsage (${unitType})`,
                data: gasData
            };

            csvExport.AddColumn(gasColumn);
        }

        if (heatEnabled) {
            const heatColumn = {
                header: `HeatUsage (${unitType})`,
                data: heatData
            };

            csvExport.AddColumn(heatColumn);
        }

        if (coldEnabled) {
            const coldColumn = {
                header: `ColdUsage (${unitType})`,
                data: coldData
            };

            csvExport.AddColumn(coldColumn);
        }

        csvExport.ExecuteDownload();
    }

    private renderExportButtons(): JSX.Element {
        return (
            <>
                <div className="row png-hidden mr-1">
                    <div className="col-sm-12">
                        <div className="d-flex w-100">
                            <button
                                onClick={this.formatCsvData}
                                className="ml-auto btn btn-secondary">
                                <span>CSV</span>
                                <img className="pl-1" src={DownloadIcon} />
                            </button>
                        </div>
                    </div>
                </div>
                <EnergyGraphPngButton divRef={this.props.graphHtmlReference} heightCompensation={25} fileName={this.props.exportOptions?.fileName} />
            </>);
    }

    public render(): JSX.Element {
        const data = this.getData();
        const options = this.getChartOptions();
        const chartDatasets = this.chartInstance?.data?.datasets;

        return (
            <>
                {this.props.loading && <div id="graph-loader">
                    <div className="w-100 h-75 position-absolute">
                        <div className="w-100 h-100 map-margin-correction d-flex justify-content-center">
                            <CenteredPageLoader loading={this.props.loading ?? false} size={12} />
                        </div>
                    </div>
                </div>}
                <Scatter
                    data={data}
                    options={options}
                    height={this.props.height}
                    width={this.props.width}
                    ref={this.chartCallback}
                />
                <div className="row">
                    <div className="col-sm-12 d-flex pr-4">
                        <div className="mr-auto">
                            {this.props.showLegend && <GraphLegend
                                datasets={chartDatasets}
                                onLegendItemClick={this.onLegendItemClick} />}
                        </div>
                        {this.renderExportButtons()}
                    </div>
                </div>
            </>
        );
    }

}

export default withTranslation()(TemperatureScatterGraph);