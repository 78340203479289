import IBaseGraphProps from "./interfaces/IBaseGraphProps";
import { withTranslation } from 'react-i18next';
import { HorizontalBar } from "react-chartjs-2";
import GraphLegend from './graphLegend';
import BaseGraph from './baseGraph';

class HorizontalBarGraph extends BaseGraph {
    public constructor(props: IBaseGraphProps) {
        super(props);
        this.chartCallback = this.chartCallback.bind(this);
    }

    private chartCallback(ref: HorizontalBar): void {
        if (!ref) {
            return;
        }

        this.chartInstance = ref.chartInstance;
        this.setState({
            graphTitle: this.props.title,
            datasets: ref.chartInstance.data.datasets
        });
    }

    public render(): JSX.Element {
        this.props.options.onHover = this.onElementHover;
        return (
            <div>
                <HorizontalBar data={this.props.data} width={this.props.width} height={this.props.height} options={this.getChartOptions()} ref={this.chartCallback} onElementsClick={this.onElementsClickCallback} />
                {this.props.showLegend && <GraphLegend graphTitle={this.state.graphTitle} datasets={this.props.legendFilter ? this.state.datasets?.filter(this.props.legendFilter) : this.state.datasets} onLegendItemClick={this.onLegendItemClick} />}
            </div>
        );
    }
}

export default withTranslation()(HorizontalBarGraph);