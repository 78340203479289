import * as React from 'react';

import BesenseTooltip from '../../components/besenseTooltip/besenseTooltip';
import './graphLegend.scss';
import IBeSenseChartDataSets from './interfaces/IBeSenseChartDataSets';
import Colors from '@/styles/colors';

interface IGraphLegendProps {
    datasets?: readonly IBeSenseChartDataSets[];
    graphTitle?: string;
    onLegendItemClick: () => void;
}

export default class GraphLegend extends React.Component<IGraphLegendProps> {

    public constructor(props: IGraphLegendProps) {
        super(props);
    }

    public render(): JSX.Element {
        if (!this.props.datasets) {
            return (<div />);
        }

        return (
            <div className="graph-legend">
                <div className="graph-title">
                    {this.props.graphTitle}
                </div>
                <div className="graph-series">
                    <ul>
                        {this.props.datasets.filter(set => !set.disableLegend).map(set =>
                            <LegendItem
                                key={set.label}
                                set={set}
                                onLegendItemClick={this.props.onLegendItemClick}
                            />)}
                    </ul>
                </div>
            </div>
        );
    }
}

// tslint:disable-next-line:max-classes-per-file
class LegendItem extends React.Component<any, { hidden: boolean }>{
    private originalSetMeta: any;
    private indexer: string;
    public constructor(props: any) {
        super(props);
        this.indexer = Object.keys(this.props.set._meta)[0];
        // For some reason only the initial set passed contains the _meta property.
        this.originalSetMeta = this.props.set._meta;

        this.state = {
            hidden: false
        };

        this.props.set._meta[this.indexer].hidden = false;
        this.onLegendClick = this.onLegendClick.bind(this);
    }

    public render(): JSX.Element {
        const colorIndicatorStyle: React.CSSProperties = {
            backgroundColor: this.props.set.backgroundColor,
            borderColor: this.props.set.pointBorderColor ?
                this.props.set.pointBorderColor :
                this.props.set.borderColor ? this.props.set.borderColor : Colors.black,
            borderWidth: this.props.set.borderWidth ? 1 : this.props.set.pointRadius ? 1 : "0",
            borderStyle: this.props.set.borderDash ? 'dashed' : 'solid',
        };

        if (this.props.set.renderLegendAsLine) {
            colorIndicatorStyle['height'] = '1px';

            if (this.props.set.borderDash === 'solid') {
                colorIndicatorStyle['backgroundColor'] = this.props.set.borderColor;
            }
        }

        if (this.props.set.renderLegendAsCircle) {
            colorIndicatorStyle['borderRadius'] = '50%';
        }

        const labelStyle: React.CSSProperties = {
            textDecoration: this.state.hidden ? 'line-through' : 'none'
        };

        if (this.props.set.greyedOut) {
            colorIndicatorStyle.backgroundColor = Colors.blue_grey;
            colorIndicatorStyle.borderColor = Colors.blue_grey;

            labelStyle.color = Colors.blue_grey;
        }

        return (
            <li key={this.props.set.label} onClick={this.onLegendClick} className={!!this.props.set.disableLegendClick ? '' : 'clickable'}>
                <span style={colorIndicatorStyle}> &nbsp; </span>
                <span style={labelStyle}>{this.props.set.label}</span>
                {this.props.set.tooltipContent && <BesenseTooltip content={this.props.set.tooltipContent} />}
            </li>);
    }

    private onLegendClick(e: any): void {
        if (this.props.set.disableLegendClick) {
            return;
        }

        const hidden = !this.originalSetMeta[this.indexer].hidden;
        this.setState({
            hidden: hidden
        });

        this.originalSetMeta[this.indexer].hidden = hidden;
        this.props.onLegendItemClick(e);
    }
}