import moment from 'moment';
import ISubscription from '../../interfaces/energy/ISubscription';
import { SubscriptionType } from '@/enums/subscriptionType';

export default class EnergyContract {
    public readonly unixTimeLeft: number;
    public readonly yearsLeft: number;
    public readonly monthsLeft: number;
    public readonly yearsDuration: number;
    public readonly monthsDuration: number;

    public static fromSubscription(subscription: ISubscription): EnergyContract {
        return new EnergyContract(
            subscription.venueId,
            subscription.startDate,
            subscription.endDate,
            subscription.contact.email,
            subscription.contact.name,
            subscription.contact.phoneNumber,
            subscription.beEnergizedDetails.totalSavings,
            subscription.beEnergizedDetails.electricitySavings,
            subscription.beEnergizedDetails.gasSavings,
            subscription.type
        );
    }

    public constructor(
        public venueId: string,
        public startDate: Date,
        public endDate: Date,
        public contactEmail?: string,
        public contactName?: string,
        public contactPhoneNumber?: string,
        public promisedSavings?: number,
        public electricitySavings?: number,
        public gasSavings?: number,
        public type?: SubscriptionType) {

        const today = moment(new Date());
        const endDateMoment = moment(endDate);
        const startDateMoment = moment(startDate);

        this.unixTimeLeft = endDateMoment.diff(today).valueOf();

        this.yearsLeft = endDateMoment.diff(today, 'years');
        this.monthsLeft = endDateMoment.diff(today, 'months', true) - this.yearsLeft * 12;
        this.yearsDuration = endDateMoment.diff(startDateMoment, 'years');
        this.monthsDuration = Math.round(endDateMoment.diff(startDateMoment, 'months', true)) - this.yearsDuration * 12;
    }
}