import * as React from 'react';
import { NotificationManager } from 'react-notifications';

import State from './interfaces/iCleaningPageState';

import { withTranslation } from 'react-i18next';
import { withTelemetry } from '@/services/telemetryService';

import LanguageProvider from '@/providers/languageProvider';
import VenueProvider from '@/providers/venueProvider';
import CoreSpaceService from '@/services/coreSpaceService';
import BeCleanTeaserImage from '@/images/bg_schoonmaak.jpg';
import Teaser from "@/components/teaser/teaser";
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import SubscriptionValidationService from '@/services/subscriptionValidationService';
import FullPageLoader from '@/components/loaders/fullPageLoader';
import links from '@/utils/links';
import PollutionMapPage from './tabs/pollutionMapPage';
import translations from '@/translations/mapper';

class CleaningPage extends React.Component<{}, State> {
    private subscriptionValidationService: SubscriptionValidationService;
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: {}) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();

        this.state = {
            loading: true,
            hasCleaningSubscription: false,
            hasApplicableRealEstateManagementSubscription: false
        };

        this.initializeActiveVenueAsync = this.initializeActiveVenueAsync.bind(this);
        AppEventHub.on(AppEvents.BuildingSelected, this.initializeActiveVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        let activeVenue = VenueProvider.getActiveVenue();

        // Ensure a venue is selected (since this component mounts before app.tsx does).
        if (!activeVenue) {
            const venues = await this.coreSpaceService.getVenues();

            if (!venues.length) {
                NotificationManager.error(LanguageProvider.getTranslation("global-error-messages.account-configuration-incorrect"));
            } else {
                activeVenue = venues[0];
                VenueProvider.saveActiveVenue(activeVenue);
            }
        }
        if (activeVenue) {
            await this.initializeActiveVenueAsync();
        }
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeActiveVenueAsync);
    }

    private async initializeActiveVenueAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();
        const hasBeCleanSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEClean"]) : false;
        const hasApplicableRealEstateUtilizationSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["Motion", "MotionSanitary"]) : false;

        this.setState({
            loading: false,
            hasCleaningSubscription: hasBeCleanSubscription,
            hasApplicableRealEstateManagementSubscription: hasApplicableRealEstateUtilizationSubscription
        });
    }

    private shouldShowMapPage(): boolean {
        return this.state.hasCleaningSubscription &&
            this.state.hasApplicableRealEstateManagementSubscription;
    }

    public render(): JSX.Element {

        return (
            <>
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}

                {!this.state.loading &&
                    <>
                        <div className={this.shouldShowMapPage() ? "" : "d-none"}><PollutionMapPage /></div>
                        {(!this.state.hasCleaningSubscription || !this.state.hasApplicableRealEstateManagementSubscription) &&
                            <Teaser
                                imagePath={BeCleanTeaserImage}
                                title={LanguageProvider.getTranslation(translations.pages.cleaning.title)}
                                subtitle={LanguageProvider.getTranslation(translations.pages.cleaning.teaser.subtitle)}
                                paragraph1={LanguageProvider.getTranslation(translations.pages.cleaning.teaser.paragraph1)}
                                paragraph2={LanguageProvider.getTranslation(translations.pages.cleaning.teaser.paragraph2)}
                                learnmoretext={LanguageProvider.getTranslation(translations.pages.cleaning.teaser.learnmore)}
                                buttonLink={links.external.beyondeyes.cleaning}>
                            </Teaser>}
                    </>}
            </>
        );
    }
}

export default withTranslation()(withTelemetry(CleaningPage, "BeCleanPage"));