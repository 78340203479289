import CoreSpaceService from "./../services/coreSpaceService";
import CoreSpaceTypes from "./../enums/coreSpaceTypes";
import CoreSpaceIncludes from "./../enums/coreSpaceIncludes";
import IRoomSettingsDto from "../interfaces/IRoomSettingsDto";
import DashboardService from "./dashboardService";
import IRoomSettingsForRender from "interfaces/IRoomSettingsForRender";
import VenueProvider from "providers/venueProvider";
import IRoomPhotoForCreationDto from "interfaces/IRoomPhotoForCreationDto";
import IRoomPhoto from "interfaces/IRoomPhoto";
import IAutomaticCancellationForRoomsSetting from "interfaces/IAutomaticCancellationForRoomsSetting";

const parseFacilityArray = (arrayString: string): string[] => {
    let facilityArray = arrayString?.split(';');
    facilityArray = facilityArray?.filter(word => word.length > 0).map(item => item.toLowerCase());
    return facilityArray;
}

const parseStartTime = (startTime: string | undefined): string => {
    const venue = VenueProvider.getActiveVenue()!;
    return startTime === undefined
        ? `${venue.openingHour}:00` ?? '0:00'
        : startTime;
}

const parseEndTime = (endTime: string | undefined): string => {
    const venue = VenueProvider.getActiveVenue()!;
    return endTime === undefined
        ? `${venue.closingHour}:00` ?? '24:00'
        : endTime;
}

const parseIsReservable = (isReservable?: string): boolean => {
    // If isReservable is undefined we should set it to false as a default value
    return isReservable === "true";
}

const parseAccessibleDays = (accessibleDays: string | undefined): string => {
    const venue = VenueProvider.getActiveVenue()!;
    return accessibleDays === undefined
        // The opendays property on a venue starts on Monday which is set in Dynamics.
        // For rooms the sequence needs to start on Sunday.
        ? convertMondayStartSequenceToSundayStart(venue.openDays)
        : accessibleDays;
}

const convertMondayStartSequenceToSundayStart = (bitSequence: string): string => {
    const bitArray = bitSequence.split('');
    const sundayBit = bitArray.pop();
    bitArray.unshift(sundayBit!);
    const sundayStartString = bitArray.join('');
    return sundayStartString;
};

export const getRoomsSettingsForVenueAsync = async(venueId: string, skipCache: boolean = false): Promise<IRoomSettingsForRender[]> => {
    const coreSpaceService = new CoreSpaceService();
    const [spaces, floors] = await Promise.all([
        coreSpaceService.getSpacesForVenue(
            venueId,
            [CoreSpaceTypes.Room],
            [CoreSpaceIncludes.Properties],
            undefined,
            skipCache),
        coreSpaceService.getFloorsForVenue(
            venueId,
            [CoreSpaceIncludes.Properties])
    ]);

    const settings: IRoomSettingsForRender[] = spaces.filter(s => !s.isPOI).map(s => ({
        accessibleStartTime: parseStartTime(s.accessibleStartTime),
        accessibleEndTime: parseEndTime(s.accessibleEndTime),
        capacity: s.capacity,
        buildUpTimeMinutes: s.buildUpTimeMinutes,
        windDownTimeMinutes: s.windDowTimeMinutes,
        facilities: parseFacilityArray(s.facilities),
        isReservable: parseIsReservable(s.isReservable),
        accessibleDays: parseAccessibleDays(s.accessibleDays),
        spaceId: s.id,
        floorLevel: floors.find(f => f.id === s.parentId)?.floorLevel ?? "",
        name: s.name
    }));

    return settings;
}

export const upsertRoomPhoto = async(roomPhoto: IRoomPhotoForCreationDto): Promise<any> => {
    const dashboardService = new DashboardService();
    return await dashboardService.upsertRoomPhoto(roomPhoto);
}

export const deleteRoomPhoto = async(spaceId: string): Promise<any> => {
    const dashboardService = new DashboardService();
    return await dashboardService.deleteRoomPhoto(spaceId);
}

export const getVenueRoomPhotos = async(): Promise<IRoomPhoto[]> => {
    const dashboardService = new DashboardService();
    return await dashboardService.getRoomPhotos();
}

export const upsertRoomsSettingsForVenueAsync = async(venueId: string, roomSettings: IRoomSettingsForRender[], skipCache: boolean = false): Promise<Response> => {
    const dashboardService = new DashboardService();
    const roomSettingsForUpsert: IRoomSettingsDto[] = roomSettings.map(s => ({
        accessibleStartTime: s.accessibleStartTime,
        accessibleEndTime: s.accessibleEndTime,
        capacity: s.capacity,
        buildUpTimeMinutes: s.buildUpTimeMinutes,
        windDownTimeMinutes: s.windDownTimeMinutes,
        facilities: s.facilities?.join(';') ?? "",
        isReservable: s.isReservable,
        accessibleDays: s.accessibleDays,
        spaceId: s.spaceId,
        name: s.name
    }));

    return await dashboardService.updateSpacesRoomSettings(venueId, roomSettingsForUpsert);
}

export const upsertAutomaticCancellationSettingAsync = async(venueId: string, automaticCancellationForRoomsUpdate: IAutomaticCancellationForRoomsSetting): Promise<Response> => {
    const dashboardService = new DashboardService();
    return await dashboardService.upsertRoomsAutomaticCancellationSetting(venueId, automaticCancellationForRoomsUpdate);
}
