import * as React from 'react';
import moment from 'moment';
import { WithTranslation, withTranslation } from 'react-i18next';

import WeekDayUtils from '@/pages/energymanagement/components/datePicker/utils/weekDayUtils';
import LanguageProvider from '@/providers/languageProvider';
import { IDatePickerTimeUnit } from '@/interfaces/IDatePickerTimeUnit';
import ArrowLeft from '@/images/Arrow Left.svg';
import ArrowRight from '@/images/Arrow Right.svg';


interface IDateScrollerProps extends WithTranslation {
    selectedTimeUnit: IDatePickerTimeUnit;
    startDate: Date;
    onDateChange: (timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date) => void;
}

class DateScroller extends React.Component<IDateScrollerProps, {}> {
    public constructor(props: IDateScrollerProps) {
        super(props);

        this.renderStartDate = this.renderStartDate.bind(this);
        this.goToPast = this.goToPast.bind(this);
        this.goToFuture = this.goToFuture.bind(this);
    }

    private goToPast(): void {
        this.changeTimeRange(-1);
    }

    private goToFuture(): void {
        this.changeTimeRange(1);
    }

    private changeTimeRange(amount: number): void {
        switch (this.props.selectedTimeUnit) {
            case "year":
                const newYear = this.props.startDate.getFullYear() + amount;
                this.props.onDateChange('year', moment(`1-1-${newYear}`, "DD-MM-YYYY").toDate(), moment(`31-12-${newYear}`, "DD-MM-YYYY").toDate());
                break;
            case "month":
                const startOfNewMonth = moment(this.props.startDate).add(amount, "month");
                this.props.onDateChange('month', startOfNewMonth.toDate(), startOfNewMonth.add(1, 'month').subtract('1', 'day').toDate());
                break;
            case "week":
                const startOfNewWeek = WeekDayUtils.getFirstDayOfWeek(moment(this.props.startDate)).add(amount, "week");
                const endOfWeek = moment(startOfNewWeek).add('6', 'days');
                this.props.onDateChange('week', startOfNewWeek.toDate(), endOfWeek.toDate());
                break;
            case "day":
                const newDay = moment(this.props.startDate).add(amount, "day");
                this.props.onDateChange('day', newDay.toDate(), newDay.toDate());
                break;
            default:
                break;
        }
    }

    private renderStartDate(): string {
        const monthTranslations = LanguageProvider.getTranslations(
            ["january", "february", "march", "april", "may", "june", "july", "august",
                "september", "october", "november", "december"].map(d => `dates.shorthand.${d}`));

        switch (this.props.selectedTimeUnit) {
            case "year":
                return `${this.props.startDate.getFullYear()}`;
            case "month":
                return `${monthTranslations[this.props.startDate.getMonth()]}  (${this.props.startDate.getFullYear()})`;
            case "week":
                return `Week ${(moment(this.props.startDate).week())}  (${monthTranslations[this.props.startDate.getMonth()]} ${this.props.startDate.getFullYear()})`;
            case "day":
                return `${this.props.startDate.getDate()} ${monthTranslations[this.props.startDate.getMonth()]} ${this.props.startDate.getFullYear()}`;
            default:
                return "Error";
        }
    }

    public render(): JSX.Element {
        return (
            <div className="d-flex-inline date-scroller">
                <img id="datescroller-nav-left" height="12px" src={ArrowLeft} onClick={this.goToPast} className="clickable mr-2 d-inline date-scroller-arrow png-hidden" />
                <div className="d-inline">
                    {this.renderStartDate()}
                </div>
                <img id="datescroller-nav-right" height="12px" src={ArrowRight} onClick={this.goToFuture} className="clickable ml-2 d-inline date-scroller-arrow png-hidden" />
            </div>
        );
    }
}

export default withTranslation()(DateScroller);