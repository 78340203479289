import React from "react";
import { PulseLoader } from "react-spinners";

import VenueProvider from "@/providers/venueProvider";
import CoreSpaceService from "@/services/coreSpaceService";
import LanguageProvider from "@/providers/languageProvider";
import Translations from "@/translations/mapper";
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import IBaseState from "@/interfaces/IBaseState";

import SwitchAppIcon from "@/images/BEappMenu_icon_white.svg";
import BeReservationsIcon from "@/images/BEReservations_calendar.svg";
import AppMenuLinkIcon from "@/images/appMenu_link.svg";
import "./switchAppMenu.scss";
import Colors from "@/styles/colors";

interface ISwitchAppMenuState extends IBaseState {
    realEstateUtilizationExternalApplicationUrl: string | undefined
}

class SwitchAppMenu extends React.Component<{}, ISwitchAppMenuState> {

    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: any) {
        super(props);

        this.state = { loading: true, realEstateUtilizationExternalApplicationUrl: "" };

        this.coreSpaceService = new CoreSpaceService();
        this.initializeSwitchAppMenu = this.initializeSwitchAppMenu.bind(this);
        this.constructRealEstateUtilizationExternalApplicationUrl = this.constructRealEstateUtilizationExternalApplicationUrl.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeSwitchAppMenu);
    }

    private initializeSwitchAppMenu(): void {
        const venue = VenueProvider.getActiveVenue();
        this.setState({
            realEstateUtilizationExternalApplicationUrl: venue?.realEstateUtilizationExternalApplicationUrl,
            loading: false
        });
    }

    public async componentDidMount(): Promise<void> {
        this.initializeSwitchAppMenu();
    }

    private constructRealEstateUtilizationExternalApplicationUrl(): string {
        return this.state.realEstateUtilizationExternalApplicationUrl?.toLowerCase().startsWith("http")
            ? this.state.realEstateUtilizationExternalApplicationUrl
            : "//" + this.state.realEstateUtilizationExternalApplicationUrl;
    }

    private renderDropdownItemEEA(): JSX.Element {
        if(this.state.realEstateUtilizationExternalApplicationUrl === '') return (<div/>);

        const url = this.constructRealEstateUtilizationExternalApplicationUrl();
        return (
            <div className="dropdown-item">
                <div className="appMenuIcon">
                    <img src={AppMenuLinkIcon} />
                </div>
                <div className="appMenuLink">
                    <a href={url} target="_blank" rel="noreferrer">
                        <div className={`clickable`}>
                            <h3 className="font-weight-bolder mb-1 mt-2" >{LanguageProvider.getTranslation(Translations.switchappmenu.beequippedbevitalreports)}</h3>
                        </div>
                    </a>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <div className="appMenu" id="appMenu-picker">
                <div className="clickable d-flex" data-toggle="dropdown" id="switch-app-button" aria-haspopup="true" aria-expanded="false">
                    <div className="mr-4">
                    <div className="default-text">{LanguageProvider.getTranslation(Translations.switchappmenu.switchapp)}</div>
                        <div className="font-weight-bold">{LanguageProvider.getTranslation(Translations.switchappmenu.bedashboard)}</div>
                    </div>
                    <div className="d-flex align-items-end">
                        <img src={SwitchAppIcon} id="switch-app-icon" />
                    </div>
                </div>
                <div className="dropdown-menu dropdown-menu-right appMenuDropdown" aria-labelledby="switch-app-button">
                    {
                        this.state.loading && <div className="d-flex justify-content-center p-3">
                            <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.state.loading} />
                        </div>
                    }
                    {<div className="dropdown-item">
                        <div className="appMenuIcon">
                            <img src={BeReservationsIcon} />
                        </div>
                        <div className="appMenuLink">
                            <a href={process.env.REACT_APP_reservationsUrl} target="_blank" rel="noreferrer">
                                <div className={`clickable`}>
                                    <h3 className="font-weight-bolder mb-1" >{LanguageProvider.getTranslation(Translations.switchappmenu.bereservationsapp)}</h3>
                                </div>
                            </a>
                        </div>
                    </div>}
                     {!this.state.loading
                     && this.state.realEstateUtilizationExternalApplicationUrl !== undefined
                     && this.renderDropdownItemEEA()}
                </div>
            </div>
        );
    }
}

export default SwitchAppMenu;