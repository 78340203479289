import { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import FullPageLoader from '@/components/loaders/fullPageLoader';
import EnergyService from '@/services/energyService';
import BesenseTooltip from '@/components/besenseTooltip/besenseTooltip';
import CoreSpaceIncludes from '../../../enums/coreSpaceIncludes';
import IEnergyPortfolioLine from '../../../interfaces/energy/IEnergyPortfolioLine';
import EnergyContractProgress from '@/models/energy/energyContractProgress';
import LanguageProvider from '@/providers/languageProvider';
import VenueProvider from '@/providers/venueProvider';
import CoreSpaceService from '@/services/coreSpaceService';
import SubscriptionValidationService from '@/services/subscriptionValidationService';
import translations from '@/translations/mapper';
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import Sorter from '@/utils/sorter';
import ContractProgressBar from '../components/contractProgressBar';
import { SelectedPortfolioTableType } from './interfaces/IEnergyPortfolioPageState';
import IEnergyPortfolioPageProps from './interfaces/IEnergyPortfolioPageProps';
import EnergyManagementPage from '../energyManagementPage';
import { withTelemetry } from '@/services/telemetryService';
import links from '@/utils/links';

import { ContractSelectedIcon, ContractIcon, EnergySelectedIcon, EnergyIcon, GasSelectedIcon, GasIcon, HeatSelectedIcon, HeatIcon, CurrencySelectedIcon, CurrencyIcon } from '@/images';
import './energyPortfolioPage.scss';
import PageUtils from '../../../utils/pageUtils';

type Primitive = string | number | boolean;


const EnergyPortFolioPageNew: React.FC<IEnergyPortfolioPageProps> = (props) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<IEnergyPortfolioLine[]>([]);
    const [selectedType, setSelectedType] = useState<SelectedPortfolioTableType>(SelectedPortfolioTableType.Contract);
    const [columns, setColumns] = useState<TableColumn<IEnergyPortfolioLine>[]>(getColumns());
    const [showGasSelection, setShowGasSelection] = useState<boolean>();
    const [showElectricitySelection, setShowElectricitySelection] = useState<boolean>();
    const [showHeatSelection, setShowHeatSelection] = useState<boolean>();

    const coreSpaceService = new CoreSpaceService();
    const energyService = new EnergyService();

    useEffect(() => {

        const getInstances = async (): Promise<void> => {
            const svs = await SubscriptionValidationService.GetInstanceAsync();
            const portfolio = await energyService.GetPortfolioAsync();

            const hasGasSubscription = svs?.userHasAnyApplicableSubscription(["Gas"]);
            const hasElectricitySubscription = svs?.userHasAnyApplicableSubscription(["Electricity"]);
            const hasHeatSubscription = svs?.userHasAnyApplicableSubscription(['Heat']);
            const sorted = Sorter.sortByProperty(portfolio, 'venue');

            setData(sorted);
            setShowElectricitySelection(hasElectricitySubscription);
            setShowGasSelection(hasGasSubscription);
            setShowHeatSelection(hasHeatSubscription);
            setLoading(false);
        }

        getInstances();

        AppEventHub.on(AppEvents.LanguageChanged, updateTable);

        return (): void => { AppEventHub.off(AppEvents.LanguageChanged, updateTable); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType]);

    function goToVenuePage(): void {
        props.history.push(links.energymanagement.overview);
    }

    const updateTable = (): void =>  {
        const columns = getColumns();
        setColumns(columns);
    };

    function renderContractTimeLeft(yearsLeft: number, monthsLeft: number, daysLeft: number): JSX.Element {
        let text = LanguageProvider.getTranslation("pages.energy.overview.contract.expired");

        if (yearsLeft > 0 || monthsLeft > 0 || daysLeft > 0) {
            const yearsText = yearsLeft > 0 ? `${yearsLeft} ${LanguageProvider.getTranslation("pages.energy.overview.contract.years")}` : '';
            const monthsText = monthsLeft > 0 ? `${monthsLeft} ${LanguageProvider.getTranslation("pages.energy.overview.contract.months")}.` : '';
            const daysText = monthsLeft === 0 && yearsLeft === 0 && daysLeft > 0 ? `${daysLeft} ${LanguageProvider.getTranslation("pages.energy.overview.contract.days")}.` : '';
            text = `${LanguageProvider.getTranslation("pages.energy.overview.contract.still")} ${yearsText} ${monthsText} ${daysText}`;
        }

        return (
            <div className="text-right">
                {text}
            </div>
        );
    }

    function renderContractProgress(contract: EnergyContractProgress): JSX.Element {
        return (
            <div className="mr-5 w-100">
                {renderContractTimeLeft(contract.yearsLeft, contract.monthsLeft, contract.daysLeft)}
                <ContractProgressBar progress={contract} />
            </div>
        );
    }
    function getColumns(): TableColumn<IEnergyPortfolioLine>[] {
        let currentSavingsAccessor: string;
        let goalSavingsAccessor: string;
        let unitFormatter: (value: any) => JSX.Element | string;
        let goalFormatter: (value: any) => string;
        let header: string;
        let tooltipYearSavings: string;
        let tooltipSavingsTarget: string;

        switch (selectedType) {
            case SelectedPortfolioTableType.Contract:
                currentSavingsAccessor = 'currentSavingsPercentage';
                goalSavingsAccessor = 'savingsGoalPercentage';
                unitFormatter = (value?: number): string => `${(value !== undefined && value !== null) ? Math.round(value * 10) / 10 : '-'}%`;
                goalFormatter = (value?: number): string => `${(value !== undefined && value !== null) ? Math.round(value * 10) / 10 : '-'}%`;
                header = LanguageProvider.getTranslation('energy.portfolio.columns.goalsavings');
                tooltipYearSavings = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].contract["year-savings"]);
                tooltipSavingsTarget = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].contract["savings-target"]);
                break;
            case SelectedPortfolioTableType.Currency:
                currentSavingsAccessor = 'thisYearsCostSavingsIncurrency';
                goalSavingsAccessor = 'totalCostSavingsInCurrency';
                unitFormatter = (value?: number): string => `€ ${(value !== undefined && value !== null) ? Math.round(value) : '-'}`;
                goalFormatter = (value?: number): string => `€ ${(value !== undefined && value !== null) ? Math.round(value) : '-'}`;
                header = LanguageProvider.getTranslation('energy.portfolio.columns.totalsavings');
                tooltipYearSavings = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].currency["year-savings"]);
                tooltipSavingsTarget = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].currency.totalsavings);
                break;
            case SelectedPortfolioTableType.Electricity:
                currentSavingsAccessor = 'electricityThisYearsContractSavingsInkWh';
                goalSavingsAccessor = 'electricityContractSavingsPercentage';
                unitFormatter = (value?: number): string => `${(value !== undefined && value !== null) ? Math.round(value) : "-"} kWh`;
                goalFormatter = (value?: number): string => `${(value !== undefined && value !== null) ? Math.round(value * 10) / 10 : '-'}%`;
                header = LanguageProvider.getTranslation('energy.portfolio.columns.totalsavings');
                tooltipYearSavings = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].electricity["year-savings"]);
                tooltipSavingsTarget = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].electricity.totalsavings);
                break;
            case SelectedPortfolioTableType.Gas:
                currentSavingsAccessor = 'gasThisYearsContractSavingsInM3';
                goalSavingsAccessor = 'gasContractSavingsPercentage';
                unitFormatter = (value?: number): JSX.Element => (<div>{(value !== undefined && value !== null) ? Math.round(value) : "-"} m<sup>3</sup></div>);
                goalFormatter = (value?: number): string => `${(value !== undefined && value !== null) ? Math.round(value * 10) / 10 : '-'}%`;
                header = LanguageProvider.getTranslation('energy.portfolio.columns.totalsavings');
                tooltipYearSavings = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].gas["year-savings"]);
                tooltipSavingsTarget = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].gas.totalsavings);
                break;
            case SelectedPortfolioTableType.Heat:
                currentSavingsAccessor = 'heatThisYearsContractSavingsInGJ';
                goalSavingsAccessor = 'heatContractSavingsPercentage';
                unitFormatter = (value?: number): JSX.Element => (<div>{(value !== undefined && value !== null) ? Math.round(value) : "-"} GJ</div>);
                goalFormatter = (value?: number): string => `${(value !== undefined && value !== null) ? Math.round(value * 10) / 10 : '-'}%`;
                header = LanguageProvider.getTranslation('energy.portfolio.columns.totalsavings');
                tooltipYearSavings = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].heat["year-savings"]);
                tooltipSavingsTarget = LanguageProvider.getTranslation(translations.tooltips.energy["energy-portfolio"].heat.totalsavings);
                break;
        }

        const sortContracts = (a: any, b: any): number => {
            const left = new EnergyContractProgress(a);
            const right = new EnergyContractProgress(b);
            const leftTime = left.unixTimeLeft;
            const rightTime = right.unixTimeLeft;

            if (leftTime === rightTime) {
                return 0;
            }

            return leftTime > rightTime ? 1 : -1;
        }

        const sortSavings = (savingsPercentageA?: any, savingsPercentageB?: any): number => {

            const isNull = (x?: number): boolean => x === null || x === undefined;

            const a = (savingsPercentageA as IEnergyPortfolioLine).currentSavingsPercentage;
            const b = (savingsPercentageB as IEnergyPortfolioLine).currentSavingsPercentage;

            return (a === b ? 0 : isNull(a) ? -1 : isNull(b) ? 1 : a! - b!);
        }

        const sortGoals = (savingsGoalPercentageA?: any, savingsGoalPercentageB?: any): number => {

            const isNull = (x?: number): boolean => x === null || x === undefined;

            const a = (savingsGoalPercentageA as IEnergyPortfolioLine).savingsGoalPercentage;
            const b = (savingsGoalPercentageB as IEnergyPortfolioLine).savingsGoalPercentage;

            return a === b ? 0 : isNull(a) ? -1 : isNull(b) ? 1 :a! - b!;
        }

        const caseInsensitiveSort = (rowA: any, rowB: any): number => {
            const a = rowA.toLowerCase();
            const b = rowB.toLowerCase();

            if (a > b) { return 1; }
            if (b > a) { return -1; }

            return 0;
        };

        const columns: TableColumn<IEnergyPortfolioLine>[] = [
            {
                id: 'venue',
                name: LanguageProvider.getTranslation('energy.portfolio.columns.venue'),
                selector: (row): Primitive => row.venue,
                sortField: 'venue',
                sortable: true,
                sortFunction: (a, b): number => { return caseInsensitiveSort(a.venue, b.venue); }
            },
            {
                id: 'subscription',
                name: LanguageProvider.getTranslation('energy.portfolio.columns.subscription'),
                cell: (row): JSX.Element => {
                    const contract = new EnergyContractProgress(row as IEnergyPortfolioLine);

                    return renderContractProgress(contract);
                },
                sortField: 'subscription',
                sortable: true,
                sortFunction: sortContracts,
            },
            {
                id: currentSavingsAccessor,
                name: <div>{LanguageProvider.getTranslation('energy.portfolio.columns.yearsavings')}<BesenseTooltip content={tooltipYearSavings} /></div>,
                selector: (row): IEnergyPortfolioLine => { return unitFormatter(row.currentSavingsPercentage) as unknown as IEnergyPortfolioLine; },
                sortFunction: sortSavings
            },
            {
                id: goalSavingsAccessor,
                name: <div>{header}<BesenseTooltip content={tooltipSavingsTarget} /></div>,
                selector: (row): any => goalFormatter(row.savingsGoalPercentage),
                sortFunction: sortGoals
            }

        ];

        return columns;
    }

    async function handleRowClick(row: IEnergyPortfolioLine): Promise<void> {
        if (row.venueId) {
            const space = await coreSpaceService.getVenueById(row.venueId, [CoreSpaceIncludes.Properties, CoreSpaceIncludes.DataTypes]);

            VenueProvider.saveActiveVenue(space);
            goToVenuePage();
        }
    }

    return (
        <EnergyManagementPage disableHeader={true}>
            {loading && <FullPageLoader loading={loading} />}
            {!loading &&
                <div id="energy-overview-page" className="main-content">
                    <div className="main-content-header">
                        <div className="row header-margin-bottom-h1">
                            <div className="col-sm-12 d-flex">
                                <h1>{LanguageProvider.getTranslation("energy.portfolio.title")}</h1>
                                <div className="selection-container ml-auto">
                                    <div onClick={(): void => setSelectedType(SelectedPortfolioTableType.Contract)} className={"selection-item " + (selectedType === SelectedPortfolioTableType.Contract ? "selected" : "")}>
                                        <img src={selectedType === SelectedPortfolioTableType.Contract ? ContractSelectedIcon : ContractIcon} />
                                    </div>
                                    <div onClick={(): void => setSelectedType(SelectedPortfolioTableType.Currency)} className={"selection-item " + (selectedType === SelectedPortfolioTableType.Currency ? "selected" : "")}>
                                        <img src={selectedType === SelectedPortfolioTableType.Currency ? CurrencySelectedIcon : CurrencyIcon} />
                                    </div>
                                    {showElectricitySelection && <div onClick={(): void => setSelectedType(SelectedPortfolioTableType.Electricity)} className={"selection-item " + (selectedType === SelectedPortfolioTableType.Electricity ? "selected" : "")}>
                                        <img src={selectedType === SelectedPortfolioTableType.Electricity ? EnergySelectedIcon : EnergyIcon} />
                                    </div>}
                                    {showGasSelection && <div onClick={(): void => setSelectedType(SelectedPortfolioTableType.Gas)} className={"selection-item " + (selectedType === SelectedPortfolioTableType.Gas ? "selected" : "")}>
                                        <img src={selectedType === SelectedPortfolioTableType.Gas ? GasSelectedIcon : GasIcon} />
                                    </div>}
                                    {showHeatSelection && <div onClick={(): void => setSelectedType(SelectedPortfolioTableType.Heat)} className={"selection-item " + (selectedType === SelectedPortfolioTableType.Heat ? "selected" : "")}>
                                        <img src={selectedType === SelectedPortfolioTableType.Heat ? HeatSelectedIcon : HeatIcon} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <DataTable
                                    className="customer-enery-table"
                                    progressPending={loading}
                                    columns={columns}
                                    data={data}
                                    pagination={true}
                                    paginationPerPage={10}
                                    onRowClicked={(row): void => { handleRowClick(row); }}
                                    paginationComponentOptions={PageUtils.getDefaultPaginationOptions()}
                                />
                            </div>
                        </div>
                    </div>
                </div>}
        </EnergyManagementPage>
    );

}

export default withTelemetry(EnergyPortFolioPageNew, "EnergyPortfolioPage");