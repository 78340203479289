import LanguageProvider from 'providers/languageProvider';
import DatePicker from "react-datepicker";
import SelectBoxUtils from "utils/selectBoxUtils";
import translations from "translations/mapper";
import IReactSelectValue from "interfaces/IReactSelectValue";
import Select, { ActionMeta } from 'react-select';
import React, { useEffect, useState } from 'react';

import './roomSettings.scss';
import IRoomSettingsProps from './interfaces/IRoomsSettingsProps';
import IRoomSettingsForRender from 'interfaces/IRoomSettingsForRender';
import RoomSettingsFacilities from 'enums/roomSettingsFacilities';
import { NotificationManager } from 'react-notifications';
import { ImageToMaximizedBase64Async } from 'utils/imageUtils';
import defaultImage from 'images/default_room_image.png';
import Cross_Cancel from 'images/Blue_Cross.svg';

const RoomSettings: React.FC<IRoomSettingsProps> = props => {
    const [hasValidCapacityValue, setCapacityValue] = useState(true);
    const [isCustomPhotoSelected, setIsCustomPhotoSelected] = useState(false);

    useEffect(() => {
        if (props.room.uploadedRoomPhoto?.fileLocation) {
            setIsCustomPhotoSelected(true);
        }
    }, [props.room]);

    const getDefaultSelectedTimeInMinutesValue = (): IReactSelectValue => {
        return { label: `0 ${LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.labels.minutes)}`, value: "0" };
    };

    const getMinutesLabels = (): IReactSelectValue[] => {
        return [
            { label: `0 ${LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.labels.minutes)}`, value: "0" },
            { label: `15 ${LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.labels.minutes)}`, value: "15" },
            { label: `30 ${LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.labels.minutes)}`, value: "30" },
            { label: `45 ${LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.labels.minutes)}`, value: "45" },
            { label: `60 ${LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.labels.minutes)}`, value: "60" },
        ];
    };

    const tryGetSelectedTimeInMinutesValue = (initialTimeInMinutes: number): IReactSelectValue => {
        return getMinutesLabels().find(l => l.value === initialTimeInMinutes.toString()) ??
                getDefaultSelectedTimeInMinutesValue();
    };

    const parseDateFromTimeString = (timeString: String | undefined): Date | undefined  => {
        if (timeString !== undefined && timeString.split(':').length === 2) {
            const timeStringArray = timeString.split(':');
            const hours = timeStringArray[0];
            const minutes = timeStringArray[1];
            const currentDate = new Date();

            currentDate.setHours(parseInt(hours));
            currentDate.setMinutes(parseInt(minutes));

            return currentDate;
        }

        return undefined;
    };

    const checkCapacityInput = (input: string): boolean => {
        const onlyNumberRegex = /^[0-9\b]+$/;
        return input !== undefined
            && input !== ""
            && onlyNumberRegex.test(input);
    };

    const handleCapacityChange = (event: any): void => {
        const isValidCapacity = checkCapacityInput(event.target.value);
        setCapacityValue(isValidCapacity);

        // Remove leading zeros
        const actualCapacity = isValidCapacity
            ? Number(event.target.value).toString()
            : event.target.value;

        const roomSettings: IRoomSettingsForRender = { ...props.room };
        roomSettings.capacity = actualCapacity;
        props.onRoomSettingChange(roomSettings);
    };

    const createAccessibleDaysBitString = (event: { target: { id: string; }; }): string => {
        const alteredDayIndex = parseInt(event.target.id);
        const currentAccessibleDaysArray = props.room.accessibleDays.split('');
        const newBitCheckState = currentAccessibleDaysArray[alteredDayIndex] === '0' ? '1' : '0';
        currentAccessibleDaysArray.splice(alteredDayIndex, 1, newBitCheckState);
        let stringResult = '';
        currentAccessibleDaysArray.map(bit => stringResult += bit);
        return stringResult;
    };

    const handleCheckedDays = (event: any): void => {
        const newAccessibleDaysString = createAccessibleDaysBitString(event);
        const newRoomSettings = { ...props.room, accessibleDays: newAccessibleDaysString};
        props.onRoomSettingChange(newRoomSettings);
    };

    const handleCheckedFacility = (event: any): void => {
        if (event.target.checked) {
            const currFacilities = [ ...props.room.facilities ];
            currFacilities.push(event.target.id);
            props.onRoomSettingChange( {...props.room, facilities: currFacilities });
        } else {
            const currFacilities = [ ...props.room.facilities ];
            const facilityIndex = currFacilities.indexOf(event.target.id);
            currFacilities.splice(facilityIndex, 1);
            props.onRoomSettingChange( { ...props.room, facilities: currFacilities });
        }
    };

    const handleIsReservableChange = (event: any): void => {
        const roomSettings: IRoomSettingsForRender = { ...props.room };
        roomSettings.isReservable = event.target.id === 'yes-radio-btn';
        props.onRoomSettingChange(roomSettings);
    };

    const handleRawChange = (event: { preventDefault: () => void; }): void => {
        event.preventDefault();
    };

    const startTimeBeginsBeforeEndTime = (startTime: string, endTime: string): boolean => {
        startTime = startTime.replace(":", "");
        endTime = endTime.replace(":", "");

        return (parseInt(startTime) < parseInt(endTime));
    };

    const startAndEndTimeAreTheSame = (startTime: string, endTime: string): boolean => {
        startTime = startTime!.replace(":", "").trim();
        endTime = endTime!.replace(":", "").trim();

        const result = (parseInt(startTime) === parseInt(endTime));
        return result;
    };

    const handleStartTimeChange = (startDate: Date): void => {
        if (startDate === null) {
            return;
        }

        const startTimeString = startDate.getHours() + ':' + (startDate.getMinutes()===0?'0':'') + startDate.getMinutes();
        const endTimeString = props.room.accessibleEndTime;
        if (!startTimeBeginsBeforeEndTime(startTimeString, endTimeString)
                || startAndEndTimeAreTheSame(startTimeString, endTimeString)) {
            return;
        }

        const room: IRoomSettingsForRender = { ...props.room };
        room.accessibleStartTime = startTimeString;
        props.onRoomSettingChange(room);
    };

    const handleEndTimeChange = (endDate: Date): void => {
        if (endDate === null) {
            return;
        }

        const startTimeString = props.room.accessibleStartTime!;
        const endTimeString = endDate.getHours() + ':' + (endDate.getMinutes()===0?'0':'') + endDate.getMinutes();
        if (!startTimeBeginsBeforeEndTime(startTimeString, endTimeString)
                || startAndEndTimeAreTheSame(startTimeString, endTimeString)) {
            return;
        }

        const room: IRoomSettingsForRender = { ...props.room };
        room.accessibleEndTime= endTimeString;
        props.onRoomSettingChange(room);
    };

    const handleBuildUpTimeChange = (item: any): void => {
        const roomSettings: IRoomSettingsForRender = { ...props.room };
        roomSettings.buildUpTimeMinutes = Number(item.value);
        props.onRoomSettingChange(roomSettings);
    };

    const handleWindDownTimeChange = (item: any): void => {
        const roomSettings: IRoomSettingsForRender = { ...props.room };
        roomSettings.windDownTimeMinutes = Number(item.value);
        props.onRoomSettingChange(roomSettings);
    };

    const handleToggleCustomPhoto = (event): void  => {
        if (event.target.id === "customphoto-radio-btn") {
            setIsCustomPhotoSelected(true);
            return;
        }
        const deleteConfirmationMessage = LanguageProvider.getTranslation(`pages.settings.roomreservations.photodeleteconfirmationbydefaultphotochange`);
        if (isPhotoDeletionConfirmed(deleteConfirmationMessage)) {
            handleRoomPhotoDelete();
            setIsCustomPhotoSelected(false);
        }
    };

    const isPhotoDeletionConfirmed = (confirmDeleteMessage: string): boolean => {
        return window.confirm(confirmDeleteMessage);
    };

    const processImageFile = (file): void => {
        const reader = new FileReader();

        reader.onload = (e): void => {
            const uploadedImage = new Image();
            uploadedImage.src = e.target!.result!.toString();
            uploadedImage.onload = (): void => {
                if (uploadedImage.width < uploadedImage.height) {
                    NotificationManager.error(LanguageProvider.getTranslation(`pages.settings.roomreservations.photowrongorientationerror`));
                    return;
                }

                ImageToMaximizedBase64Async(file)
                    .then((image) => {
                        const stripedBase64 = image.split(',')[1];
                        handleRoomPhotoUpload(stripedBase64);
                });
            };
        };

        reader.readAsDataURL(file);
    };

    const handleBrowserUpload = (e?: React.ChangeEvent<HTMLInputElement>): void => {
        if (!e) {
            return;
        }

        const uploadedFile = e.target.files;

        if (uploadedFile) {
                for (const file of uploadedFile) {
                    const maxUncompressedSize = 1024 * 1024 * 1; // = 1Mb

                if (file.size > maxUncompressedSize) {
                    NotificationManager.error(LanguageProvider.getTranslation(`pages.settings.roomreservations.phototoobigerror`));
                    // Clear HTML input, otherwise no new files can be entered once a incorrect file has been added.
                    e.target.value = "";
                    e.target.files = null;
                }
                else {
                    switch (file.type) {
                        case "image/jpeg":
                        case "image/png":
                            processImageFile(file);
                            break;
                        default:
                            NotificationManager.error(LanguageProvider.getTranslation(`pages.settings.roomreservations.phototoobigerror`));
                    }
                }
            }
        }
    };

    const handleDeleteIconClick =(): void => {
        const deleteConfirmationMessage = LanguageProvider.getTranslation(`pages.settings.roomreservations.photodeleteconfirmationbydeleteclick`);
        if (isPhotoDeletionConfirmed(deleteConfirmationMessage)) {
            handleRoomPhotoDelete();
            setIsCustomPhotoSelected(false);
        }
    };

    const handleRoomPhotoDelete = (): void => {
        props.onRoomPhotoDelete(props.room.spaceId);
    };

    const handleRoomPhotoUpload = async(image: string): Promise<void> => {
        await props.onRoomPhotoUpload({ spaceId: props.room.spaceId, base64Image: image});
    };

    const renderImageUpload = (): JSX.Element => {
        const imgSrc = isCustomPhotoSelected && props.room.uploadedRoomPhoto?.fileLocation ? props.room.uploadedRoomPhoto?.fileLocation : defaultImage;
        const isImageDeletable = isCustomPhotoSelected && props.room.uploadedRoomPhoto?.fileLocation;
        return(
            <div className="d-flex align-items-end image-upload-container">
                <div className="roomsetting-photo-container">
                    <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.photo`)}</h3>
                    {isImageDeletable && <img className="delete-icon" src={Cross_Cancel} onClick={handleDeleteIconClick} />}
                    <img className="roomsetting-photo" src={imgSrc} alt="Red dot" />
                </div>
                <div className="photo-options">
                    <div className="photo-checkbox-container d-flex flex-row clickable mr-5">
                        <span className={!isCustomPhotoSelected ? "checked-radio-btn custom-radio-button" : "unchecked-radio-btn custom-radio-button"} id="defaultphoto-radio-btn" onClick={handleToggleCustomPhoto} />
                        <label className="photo-toggle-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.defaultphoto`)}</label>
                    </div>
                    <div className="photo-checkbox-container d-flex flex-row clickable mr-5">
                        <span className={isCustomPhotoSelected ? "checked-radio-btn custom-radio-button" : "unchecked-radio-btn custom-radio-button"} id="customphoto-radio-btn" onClick={handleToggleCustomPhoto} />
                        <label className="photo-toggle-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.customphoto`)}</label>
                    </div>
                </div>
                {isCustomPhotoSelected && <div className="d-flex flex-column justify-content-center drag-and-drop">
                        <p className="text-instruction">{LanguageProvider.getTranslation(`pages.settings.roomreservations.browsephoto`)}</p>
                            <input className="custom-photo-input" name="custom-photo-input" id="image-input" type="file" accept=".png, .jpg, .jpeg" capture
                                onClick={(e): void => { e.currentTarget.files = null; e.currentTarget.value = ""; }}
                                onChange={handleBrowserUpload} />
                    </div>}
            </div>
        );
    };

    const renderCapacitySetting = (): JSX.Element => {
        return(
            <>
                <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.capacity`)}</h3>
                <input maxLength={3}
                    className={`form-control mb-4 capacity-input ${hasValidCapacityValue ? "" : "is-invalid"}`}
                    defaultValue={props.room.capacity}
                    onBlur={handleCapacityChange}
                    />
                {!hasValidCapacityValue && <p className="text-danger mt-1">
                    {LanguageProvider.getTranslation(translations.pages.settings.roomreservations.invalidcapacity)}
                </p>}
            </>
        );
    };

    const renderFacilities = (): JSX.Element => {
        const facilityOptions = Object.values(RoomSettingsFacilities).map(item => item.toLowerCase());
        return(
            <>
                <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.facilities`)}</h3>
                <div className="d-flex ml-1 mb-4">
                    {facilityOptions.map((facility, index) =>
                        <div className="roomsettings-checkbox-container align-middle mr-4" key={index}>
                                <input
                                    id={facility}
                                    type="checkbox"
                                    className={'clickable'}
                                    data-cy={`checkbox-${facility}`}
                                    readOnly={true}
                                    checked={props.room.facilities.includes(facility) ?? false}
                                    onChange={handleCheckedFacility}
                                />
                                <span
                                    id={facility}
                                    className={props.room.facilities.includes(facility) ? 'checkbox checked' : 'checkbox'}
                                    />
                                <label className="facility-checkbox" htmlFor="flexCheckChecked">
                                {facility}
                                </label>
                        </div>
                    )}
                </div>
            </>
        );
    };

    const renderReservableSetting = (): JSX.Element => {
        return(
            <>
                <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.reservationenabled`)}</h3>
                <div className="d-flex ml-1 mb-4">
                    <div className="checkbox-container clickable mr-5">
                        <span className={!props.room.isReservable ? "checked-radio-btn custom-radio-button" : "unchecked-radio-btn custom-radio-button"} id="no-radio-btn" onClick={handleIsReservableChange} />
                        <label className="form-check-label clickable ml-2">{LanguageProvider.getTranslation(`pages.settings.roomreservations.nooption`)}</label>
                    </div>
                    <div className="checkbox-container clickable mr-5">
                        <span className={props.room.isReservable ? "checked-radio-btn custom-radio-button" : "unchecked-radio-btn custom-radio-button"} id="yes-radio-btn" onClick={handleIsReservableChange} />
                        <label className="form-check-label clickable ml-2">{LanguageProvider.getTranslation(`pages.settings.roomreservations.yesoption`)}</label>
                    </div>
                </div>
            </>
        );
    };

    const renderDaysCheckboxes = (): JSX.Element => {
        const dayOptions = LanguageProvider.getTranslation(translations.pages.settings.roomreservations.days).split(' ');
        const classes = "days-checkbox";
        const checkboxCheckedClasses = 'checkbox checked clickable';
        const checkboxUnCheckedClasses = 'checkbox clickable';
        const openDaysStringBits = props.room.accessibleDays.split('');
        const openDaysBools = openDaysStringBits.map(str => !!parseInt(str));
        return(
            <>
                <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.availability`)}</h3>
                <div className="d-flex ml-1 mb-4">
                    {dayOptions.map((day, index) =>
                        <div key={index} className="roomsettings-checkbox-container mr-5"  >
                            <input id={index.toString()} className={openDaysBools[index] ? checkboxCheckedClasses : checkboxUnCheckedClasses} type="checkbox" onClick={handleCheckedDays} />
                            <span id={index.toString()} className={openDaysBools[index] ? 'checkbox checked' : 'checkbox'} onClick={handleCheckedDays} />
                            <label className={classes} htmlFor="flexCheckChecked">{day}</label>
                        </div>
                    )}
                </div>
            </>
        );
    };

    const renderTimePicker = (changeHandler, selectedTime: string): JSX.Element => {
        return(
            <DatePicker
                className="form-control mb-4"
                selected={parseDateFromTimeString(selectedTime)}
                onChange={changeHandler}
                onChangeRaw={handleRawChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                autoComplete="off"
                isClearable={false}
                showPopperArrow={false}
                popperClassName="calendar-popout"
                popperPlacement="bottom-start"
                fixedHeight
                disabled={false}
            />
        );
    };

    const renderMinutesDropdown = (changeHandler: (((value: IReactSelectValue | null, actionMeta: ActionMeta<IReactSelectValue>) => void) & ((value: IReactSelectValue | null, action: ActionMeta<IReactSelectValue>) => void)) | undefined, selectedValue: IReactSelectValue): JSX.Element => {
        return(
            <Select
                className="roomsetting-minutes-dropdown mb-5"
                value={selectedValue}
                onChange={changeHandler}
                options={getMinutesLabels()}
                styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)} />
        );
    };

    const renderTimeFrame = (): JSX.Element => {
        return(
            <>
                <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.timeframe`)}</h3>
                    <div className="timeframe d-flex flex-row">
                        <div className="time-picker mr-4">
                            {renderTimePicker(handleStartTimeChange, props.room.accessibleStartTime)}
                        </div>
                        <div className="time-picker">
                            {renderTimePicker(handleEndTimeChange, props.room.accessibleEndTime)}
                        </div>
                    </div>
            </>
        );
    };

    const renderBuildUpWinddownTime = (): JSX.Element => {
        return(
            <div className="d-flex flex-row">
                <div className="time-picker mr-4">
                <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.builduptime`)}</h3>
                    {renderMinutesDropdown(handleBuildUpTimeChange, tryGetSelectedTimeInMinutesValue(props.room.buildUpTimeMinutes))}
                </div>
                <div className="time-picker mr-4">
                    <h3 className="roomsetting-setting-label">{LanguageProvider.getTranslation(`pages.settings.roomreservations.winddowntime`)}</h3>
                    {renderMinutesDropdown(handleWindDownTimeChange, tryGetSelectedTimeInMinutesValue(props.room.windDownTimeMinutes))}
                </div>
            </div>
        );
    };

    return (
        <>
            <h2 className="expanded-title">{props.room.name}</h2>
            {renderImageUpload()}
            {renderCapacitySetting()}
            {renderFacilities()}
            {renderReservableSetting()}
            {props.room.isReservable && renderDaysCheckboxes() }
            {props.room.isReservable && renderTimeFrame() }
            {props.room.isReservable && renderBuildUpWinddownTime() }
        </>
    );

};

export default RoomSettings;


