import moment from "moment";
import "moment/locale/nl";
import { OnChangeValue } from "react-select";

import NotificationService from "@/services/notificationService";
import { withTelemetry } from "@/services/telemetryService";
import thresholdNotification from "@/models/thresholdNotification";
import ExpandedThresholdNotification from "../components/expandedThresholdNotification";
import BaseNotificationsComponent from "../abstractions/baseNotificationsComponent";
import ThresholdNotificationProvider from "@/providers/thresholdNotificationProvider";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";
import Teaser from "@/components/teaser/indoorClimateTeaser";
import VenueProvider from "@/providers/venueProvider";
import IComfortNotificationsPageExtraState from "./interfaces/IComfortNotificationsPageExtraState";
import DayTimeNotificationSettingsService from "@/services/dayTimeNotificationSettingsService";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";
import links from "@/utils/links";
import { ConditionalStyles } from "react-data-table-component";
import ISelectDropdownField from "@/components/filterMenu/interfaces/ISelectDropdownField";
import ISelectDropdownValue from "@/components/filterMenu/interfaces/ISelectDropdownValue";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";

import settingIcon from "@/images/settings-icon.svg";
import "./comfortNotificationsPage.scss";

class ComfortNotificationsPage extends BaseNotificationsComponent<thresholdNotification, IComfortNotificationsPageExtraState> {
    private readonly notificationService: NotificationService;
    private readonly dayTimeNotificationSettingsService: DayTimeNotificationSettingsService;

    private static readonly allDaysOfWeek: string = "7";
    private static readonly weekdays: string[] = ["1", "2", "3", "4", "5", "6", "0"];

    public constructor(props: any) {
        const extraState: IComfortNotificationsPageExtraState = {
            showOnlyDayTimeFilteredNotifications: true,
            selectedEndTimeSetting: "",
            selectedStartTimeSetting: "",
            selectedWeekdaySettings: [],
            loadingDayTimeSettings: true
        };

        super(props, extraState);

        this.notificationService = new NotificationService();
        this.dayTimeNotificationSettingsService = new DayTimeNotificationSettingsService();

        this.notificationFallsInDayTimeSettings = this.notificationFallsInDayTimeSettings.bind(this);
        this.goToSettingsPage = this.goToSettingsPage.bind(this);
        this.handleTimeFilterSelect = this.handleTimeFilterSelect.bind(this);
        this.loadTimeSettingsAsync = this.loadTimeSettingsAsync.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.loadTimeSettingsAsync);
    }

    public async componentDidMount(): Promise<void> {
        await this.loadTimeSettingsAsync();
        await super.componentDidMountBase();
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.BuildingSelected, this.loadTimeSettingsAsync);
        super.componentWillUnmountBase();
    }

    private async loadTimeSettingsAsync(): Promise<void> {
        const notificationSettings = await this.dayTimeNotificationSettingsService.getDayTimeNotificationSettingsAsync(true);
        const currentExtraState = this.state.extraState;

        if (!currentExtraState) {
            return;
        }

        currentExtraState.selectedWeekdaySettings = notificationSettings.weekdays.includes(ComfortNotificationsPage.allDaysOfWeek) ? ComfortNotificationsPage.weekdays : [...notificationSettings.weekdays];
        currentExtraState.selectedStartTimeSetting = notificationSettings.starttime;
        currentExtraState.selectedEndTimeSetting = notificationSettings.endtime;
        currentExtraState.loadingDayTimeSettings = false;

        this.setState({
            extraState: currentExtraState
        });
    }

    protected setHasSubscription(): void {
        const venue = VenueProvider.getActiveVenue();

        const hasSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEVital"]) : false;
        this.setState({
            hasSubscription: hasSubscription
        });
    }

    protected additionalFilterForNotifications(notifications: thresholdNotification[]): thresholdNotification[] {
        if (!this.state.extraState?.showOnlyDayTimeFilteredNotifications) {
            return notifications;
        }

        return notifications.filter(notification => this.notificationFallsInDayTimeSettings(notification));
    }

    private notificationFallsInDayTimeSettings(notification: thresholdNotification): boolean {
        const dayOfWeek = notification.LocalizedCreatedOn.day();

        if (!this.state.extraState?.selectedWeekdaySettings.includes(dayOfWeek.toString())) {
            return false;
        }

        const timeInDayAsString = notification.LocalizedCreatedOn.format("HH:mm");

        // Correct the formatting for start and end time so 5:05 is displayed as 05:05, so we can use string compare against formatted value
        // from notification.
        const correctedStartTimeSetting = this.state.extraState.selectedStartTimeSetting.length < 5 ? `0${this.state.extraState.selectedStartTimeSetting}`: this.state.extraState.selectedStartTimeSetting;
        const correctedEndTimeSetting = this.state.extraState.selectedEndTimeSetting.length < 5 ? `0${this.state.extraState.selectedEndTimeSetting}`: this.state.extraState.selectedEndTimeSetting;
        const fallsInTimeOfDay = timeInDayAsString >= correctedStartTimeSetting &&
            timeInDayAsString < correctedEndTimeSetting;

        return fallsInTimeOfDay;
    }

    protected async getNotificationsAsync(): Promise<void> {
        this.setState({
            loading: true
        });

        const endOfDayOfEndDate = moment(this.state.endDate).add(1, "days").toDate();
        const retrievedComfortNotifications = await this.notificationService.getThresholdNotificationsorCurrentVenueAsync(this.state.startDate, endOfDayOfEndDate);

        this.setState({
            notifications: retrievedComfortNotifications,
            loading: false,
        }, () => {
            this.setSelectFilterOptions();
            this.updateFilters();
        });
    }

    protected translateTypeOfElement(element: thresholdNotification): string {
        return ThresholdNotificationProvider.GetTranslatedCategoryForThresholdNotification(element);
    }

    protected getTitle(): string {
        return `${LanguageProvider.getTranslation(translations.navigation.indoorclimate)} - ${LanguageProvider.getTranslation(translations.pages.notifications.notifications)}`;
    }

    protected translateFilterCategory(filterCategory: string): string {
        return ThresholdNotificationProvider.TranslateDataType(filterCategory);
    }

    protected getTeaser(): JSX.Element {
        return (
            <Teaser />
        );
    }
    protected getConditionalRowStyles(): ConditionalStyles<any>[] {

        // TODO: make rows purple
        /*const originalNotification = rowInfo?.original as thresholdNotification;
        if (originalNotification && !this.notificationFallsInDayTimeSettings(originalNotification)) {
            baseProps.className = "rt-tr outside-time-notification-row";
        }
        else {
            baseProps.className = "rt-tr";
        }*/

        return [];
    }
    protected getExtraFilterDropdowns(): ISelectDropdownField[] {
        const options= ComfortNotificationsPage.getTimeFilterOptions();

        // Update the translation of the currently selected filter by hand if it was already set at some point:
        if(this.state.extraState?.selectedTimefilterOption){
            if(this.state.extraState.selectedTimefilterOption.value === "false"){
                this.state.extraState.selectedTimefilterOption.label = LanguageProvider.getTranslation(translations.pages.notifications.showallnotifications);
            }
            else{
                this.state.extraState.selectedTimefilterOption.label = LanguageProvider.getTranslation(translations.pages.notifications.shownotificationsindaytime);
            }
        }
        else if (this.state.extraState){
            // Otherwise set the default option in state to have a reference for future language changes.
            const extraState = this.state.extraState;
            extraState.selectedTimefilterOption = options[0];
            this.setState({
                extraState: extraState
            });
        }

        return [
            {
                dropdownName: "timeFilter",
                dropdownList: options,
                key: "3",
                onSelect: this.handleTimeFilterSelect,
                defaultValue: options[0],
                cannotBeCleared: true,
                keepOptionsOrder: true
            }];
    }

    private handleTimeFilterSelect(optionSelected: OnChangeValue<ISelectDropdownValue, false>): void{
        if(!optionSelected){
            return;
        }

        const extraState = this.state.extraState;

        if (!extraState) {
            return;
        }

        if (optionSelected.value === "true") {
            extraState.showOnlyDayTimeFilteredNotifications = true;
        }
        else {
            extraState.showOnlyDayTimeFilteredNotifications = false;
        }

        extraState.selectedTimefilterOption = optionSelected;
        this.setState({ extraState: extraState }, this.updateFilters);
    }

    private goToSettingsPage(): void {
        this.props.history.push(links.settings.indoorclimate.notifications);
    }

    private getSelectedDaysText(): string {
        if (!this.state.extraState?.selectedWeekdaySettings) {
            return LanguageProvider.getTranslation(translations.pages.notifications.nodaysselected);
        }

        if (this.state.extraState.selectedWeekdaySettings.length === 7) {
            return LanguageProvider.getTranslation(translations.pages.notifications.alldaysselected);
        }

        const dayNames: string[] = [];

        for (let i = 1; i < 7; i++) {
            if (this.state.extraState.selectedWeekdaySettings.includes(i.toString())) {
                dayNames.push(LanguageProvider.getTranslation(`pages.settings.workspaces.generaltab.weekdays.weekday${i}`));
            }
        }

        if (this.state.extraState.selectedWeekdaySettings.includes("0")) {
            dayNames.push(LanguageProvider.getTranslation(`pages.settings.workspaces.generaltab.weekdays.weekday0`));
        }

        return dayNames.reduce((result, day) => result.length > 0 ? `${result} | ${day}` : day, "");
    }

    private static getTimeFilterOptions(): ISelectDropdownValue[] {
        return [{
            value: "true",
            label: LanguageProvider.getTranslation(translations.pages.notifications.shownotificationsindaytime),
            dropdownType: "dayTimeFilter",
        }, {
            value: "false",
            label: LanguageProvider.getTranslation(translations.pages.notifications.showallnotifications),
            dropdownType: "dayTimeFilter",
        }];
    }

    protected renderAboveNotifications(): JSX.Element {
        return (
            <>
                <div className="comfort-notifications-description">
                    <div>
                        {LanguageProvider.getTranslation(translations.pages.notifications.daytimeexplanation)}
                    </div>
                    <div>
                        {LanguageProvider.getTranslation(translations.pages.notifications.daytimeexplanation2)}
                    </div>
                </div>
                {
                    !this.state.extraState?.loadingDayTimeSettings &&
                    <>
                        <h3 className="comfort-notifications-time-header">
                            {LanguageProvider.getTranslation(translations.pages.notifications.configureddaytime)}
                        </h3>
                        <div className="comfort-notifications-time-information">
                            <span className="comfort-notifications-selected-days">
                                {this.getSelectedDaysText()}
                            </span>
                            <span className="comfort-notifications-selected-time">
                                {`${LanguageProvider.getTranslation(translations.pages.notifications.time)}: ${this.state.extraState?.selectedStartTimeSetting} - ${this.state.extraState?.selectedEndTimeSetting}`}
                            </span>
                            <span className="comfort-notifications-settings" onClick={this.goToSettingsPage}>
                                <img src={settingIcon} />
                                {LanguageProvider.getTranslation(translations.pages.notifications.changedaytime)}
                            </span>
                        </div>
                    </>
                }
                {
                    this.state.extraState?.loadingDayTimeSettings &&
                    <CenteredPageLoader loading={this.state.extraState.loadingDayTimeSettings} />
                }
            </>);
    }

    protected renderExpandedNotification(row: any): JSX.Element {
        return (
            <div className="expanded-notification-row">
                <ExpandedThresholdNotification notification={row} />
            </div>);
    }
}

export default withTelemetry(ComfortNotificationsPage, "ComfortNotificationsPage");