import 'moment/locale/nl';
import './energyReportStyling.scss';

import moment from 'moment';
import * as React from 'react';
import { withTranslation } from 'react-i18next';

import PageHeader from "@/components/header/pageHeader";
import EnergyService from '@/services/energyService';
import EnergySelectedIcon from '@/images/Energy_selected.svg';
import EnergyIcon from '@/images/Energy_unselected.svg';
import GasSelectedIcon from '@/images/Gas_selected.svg';
import GasIcon from '@/images/Gas_unselected.svg';
import ColdSelectedIcon from '@/images/Cold_selected.svg';
import ColdIcon from '@/images/Cold_unselected.svg';
import HeatSelectedIcon from '@/images/Heat_selected.svg';
import HeatIcon from '@/images/Heat_unselected.svg';
import { IDatePickerTimeUnit } from '../../../../interfaces/IDatePickerTimeUnit';
import ColorProvider from '@/providers/colorProvider';
import LanguageProvider from '@/providers/languageProvider';
import VenueProvider from '@/providers/venueProvider';
import { withTelemetry } from '@/services/telemetryService';
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import DatePickerDeluxe from '../../components/datePicker/datePickerDeluxe';
import EnergyGraph from '../../components/energyGraph';
import EnergyGraphHeader from '../../components/energyGraphHeader';
import EnergyUnitType from '../../components/enums/energyUnitType';
import IEnergyWeekOverviewPageProps from './interfaces/IEnergyWeekOverviewPageProps';
import IEnergyWeekOverviewPageState from './interfaces/IEnergyWeekOverviewPageState';
import EnergyType from '../../components/enums/energyType';
import { IEnergyUnitType } from '../../components/interfaces/IEnergyUnitType';
import EnergyManagementPage from '../../energyManagementPage';
import SubscriptionValidationService from '@/services/subscriptionValidationService';
import IEnergyGraphData from '../interfaces/IEnergyGraphData';
import IQuarterlyEnergieMissieValue from '../../../../interfaces/energy/IQuarterlyEnergieMissieValue';
import IHourlyEnergieMissieValue from '../../../../interfaces/energy/IHourlyEnergieMissieValue';
import translations from '@/translations/mapper';
import WeekDayUtils from 'pages/energymanagement/components/datePicker/utils/weekDayUtils';

class EnergyWeekOverviewPage extends React.Component<IEnergyWeekOverviewPageProps, IEnergyWeekOverviewPageState>{
    private readonly graphHtmlReference: React.RefObject<HTMLDivElement>;
    private readonly energyService: EnergyService;
    private subscriptionValidationService: SubscriptionValidationService;

    private viewportHeightThresholdForIncreasedGraphHeight: number = 980;

    private disableRendering: boolean;

    public constructor(props: IEnergyWeekOverviewPageProps) {
        super(props);

        this.graphHtmlReference = React.createRef();

        const startOfLastWeek = WeekDayUtils.getFirstDayOfWeek(moment().add(-7, 'day')).toDate();
        const endOfLastWeek = WeekDayUtils.getFirstDayOfWeek(moment().add(-7, 'day')).add(6, 'day').toDate();

        const activeVenue = VenueProvider.getActiveVenue();
        const state: IEnergyWeekOverviewPageState = {
            loading: true,
            venue: activeVenue,
            selectedTimeUnit: 'week',
            startDate: startOfLastWeek,
            endDate: endOfLastWeek,
            selectedUnitType: "kWh",
            energyType: EnergyType.Electricity,
            selectedGraphType: "line",
            hasElectricitySubscription: false,
            hasGasSubscription: false,
            hasColdSubscription: false,
            hasHeatSubscription: false,
            hourlyDataPerWeekday: [],
            quarterlyDataPerWeekday: [],
            newStartDate: startOfLastWeek,
            newEndDate: endOfLastWeek
        };

        this.state = state;

        this.energyService = new EnergyService();

        this.setGasUnitTypeSelection = this.setGasUnitTypeSelection.bind(this);
        this.setEnergyUnitTypeSelection = this.setEnergyUnitTypeSelection.bind(this);
        this.setHeatUnitTypeSelection = this.setHeatUnitTypeSelection.bind(this);
        this.setColdUnitTypeSelection = this.setColdUnitTypeSelection.bind(this);
        this.onDateChange = this.onDateChange.bind(this);

        this.initializeEnergyDataAsync = this.initializeEnergyDataAsync.bind(this);
        this.initializeVenueAsync = this.initializeVenueAsync.bind(this);

        this.renderDatePicker = this.renderDatePicker.bind(this);
        this.getGraphHeaderTitle = this.getGraphHeaderTitle.bind(this);
        this.getExportFileName = this.getExportFileName.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.on(AppEvents.LanguageChanged, this.initializeEnergyDataAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeVenueAsync();
    }

    public shouldComponentUpdate(): boolean {
        return !this.disableRendering;
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.off(AppEvents.LanguageChanged, this.initializeEnergyDataAsync);
    }

    private get gasSelected(): boolean {
        return this.state.energyType === EnergyType.Gas;
    }

    private get energySelected(): boolean {
        return this.state.energyType === EnergyType.Electricity;
    }

    private get heatSelected(): boolean {
        return this.state.energyType === EnergyType.Heat;
    }

    private get coldSelected(): boolean {
        return this.state.energyType === EnergyType.Cold;
    }

    private setSubscriptionsForVenue(): void {
        const activeVenue = VenueProvider.getActiveVenue();
        if (activeVenue) {
            const hasElectricity = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Electricity"]);
            const hasGas = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Gas"]);
            const hasHeat = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Heat"]);
            const hasCold = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Cold"]);

            this.setState({
                hasElectricitySubscription: hasElectricity,
                hasGasSubscription: hasGas,
                hasHeatSubscription: hasHeat,
                hasColdSubscription: hasCold
            }, () => this.initializeDataTypes());
        }
    }

    private async initializeVenueAsync(): Promise<void> {
        this.setState({ loading: true });
        const activeVenue = VenueProvider.getActiveVenue();

        this.setSubscriptionsForVenue();
        this.setState({
            venue: activeVenue
        }, this.initializeEnergyDataAsync);
    }

    private initializeDataTypes(): void {
        const initialDataType = this.determineInitialDataType();
        const initialUnitType = this.determineInitialUnitType();

        this.setState({
            energyType: initialDataType,
            selectedUnitType: initialUnitType
        });
    }

    private determineInitialDataType(): EnergyType {
        if (this.state.hasElectricitySubscription) {
            return EnergyType.Electricity;
        }

        if (this.state.hasGasSubscription) {
            return EnergyType.Gas;
        }

        if (this.state.hasHeatSubscription) {
            return EnergyType.Heat;
        }

        return EnergyType.Cold;
    }

    private determineInitialUnitType(): IEnergyUnitType {
        if (this.state.hasElectricitySubscription) {
            return 'kWh';
        }

        if (this.state.hasGasSubscription) {
            return 'm3';
        }

        return 'GJ';
    }

    private async setGasUnitTypeSelection(): Promise<void> {
        if (this.gasSelected) {
            return;
        }

        this.setState({
            selectedUnitType: "m3",
            energyType: EnergyType.Gas
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setEnergyUnitTypeSelection(): Promise<void> {
        if (this.energySelected) {
            return;
        }

        this.setState({
            selectedUnitType: "kWh",
            energyType: EnergyType.Electricity
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setHeatUnitTypeSelection(): Promise<void> {
        if (this.heatSelected) {
            return;
        }

        this.setState({
            selectedUnitType: "GJ",
            energyType: EnergyType.Heat
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setColdUnitTypeSelection(): Promise<void> {
        if (this.coldSelected) {
            return;
        }

        this.setState({
            selectedUnitType: "GJ",
            energyType: EnergyType.Cold
        }, async () => await this.initializeEnergyDataAsync());
    }

    private onDateChange(timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date, triggerDataInitalization: boolean = true): void {
        this.setState({
            selectedTimeUnit: timeUnit,
            startDate: startDate,
            endDate: endDate,
            newStartDate: startDate,
            newEndDate: endDate,
            newTimeUnitType: timeUnit
        }, async () => { if (triggerDataInitalization) { await this.initializeEnergyDataAsync(); } });
    }

    private getShortHandDate(date: Date): string {
        const months = ["january", "february", "march", "april", "may", "june", "july", "august",
            "september", "october", "november", "december"];
        return `${date.getDate()} ${LanguageProvider.getTranslation(translations.dates.shorthand[months[date.getMonth()]])}`;
    }

    private async initializeEnergyDataAsync(): Promise<void> {
        this.setState({loading: true});
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;

        if (this.energySelected) {
            const energyData = await this.energyService.GetQuarterlyElectricVenueUsageDataAsync(startDate, endDate);
            const results = this.createDayDatasetsFromQuarterlyData(startDate, endDate, energyData);

            this.setState({
                quarterlyDataPerWeekday: results,
                loading: false
            });
        }
        else if (this.gasSelected) {
            const gasData = await this.energyService.GetHourlyGasVenueUsageDataAsync(startDate, endDate);
            const results = this.createDayDatasetsFromHourlyData(startDate, endDate, gasData);

            this.setState({
                hourlyDataPerWeekday: results,
                loading: false
            });
        }
        else if (this.heatSelected) {
            const heatData = await this.energyService.GetHourlyHeatVenueUsageDataAsync(startDate, endDate);
            const results = this.createDayDatasetsFromHourlyData(startDate, endDate, heatData);

            this.setState({
                hourlyDataPerWeekday: results,
                loading: false
            });
        }
        else if (this.coldSelected) {
            const coldData = await this.energyService.GetHourlyColdVenueUsageDataAsync(startDate, endDate);
            const results = this.createDayDatasetsFromHourlyData(startDate, endDate, coldData);

            this.setState({
                hourlyDataPerWeekday: results,
                loading: false
            });
        }
    }

    private createDayDatasetsFromQuarterlyData(startDate: Date, endDate: Date, data: IQuarterlyEnergieMissieValue[]): IEnergyGraphData[] {
        const results: IEnergyGraphData[] = [];
        const weekDays = LanguageProvider.getTranslations(["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            .map(d => `dates.${d}`));

        for (let date = moment(startDate); date <= moment(endDate); date = date.add(1, 'days')) {
            const year = date.get('year');
            // moment month is 0 indexed, but day is not...
            const month = date.get('month') + 1;
            const day = date.get('date');
            const dayOfWeek = date.get('day');

            const dataForDate = data.filter(d => d.localDay === day && d.localMonth === month && d.localYear === year);

            if (dataForDate.every(d => d.actualUsage === null)) {
                results.push({
                    label: weekDays[dayOfWeek] + " - " + LanguageProvider.getTranslation(translations.energy.general.nodataavailable),
                    color: ColorProvider.getColorForWeekday(dayOfWeek),
                    data: [],
                    noData: true
                });
                continue;
            }

            const orderedData = dataForDate.sort((a, b) => (a.localHour * 60 + a.localMinutes) - (b.localHour * 60 + a.localMinutes));

            const graphData = {
                label: weekDays[dayOfWeek],
                color: ColorProvider.getColorForWeekday(dayOfWeek),
                data: orderedData.map(i => i.actualUsage),
            };
            results.push(graphData);
        }

        return results;
    }

    private createDayDatasetsFromHourlyData(startDate: Date, endDate: Date, data: IHourlyEnergieMissieValue[]): IEnergyGraphData[] {
        const results: IEnergyGraphData[] = [];
        const weekDays = LanguageProvider.getTranslations(["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            .map(d => `dates.${d}`));

        for (let date = moment(startDate); date <= moment(endDate); date = date.add(1, 'days')) {
            const year = date.get('year');
            // moment month is 0 indexed, but day is not...
            const month = date.get('month') + 1;
            const day = date.get('date');
            const dayOfWeek = date.get('day');

            const dataForDate = data.filter(d => d.localDay === day && d.localMonth === month && d.localYear === year);

            if (dataForDate.every(d => d.actualUsage === null)) {
                results.push({
                    label: weekDays[dayOfWeek] + " - " + LanguageProvider.getTranslation(translations.energy.general.nodataavailable),
                    color: ColorProvider.getColorForWeekday(dayOfWeek),
                    data: [],
                    noData: true
                });
                continue;
            }

            const orderedData = dataForDate.sort((a, b) => a.localHour - b.localHour);

            const graphData = {
                label: weekDays[dayOfWeek],
                color: ColorProvider.getColorForWeekday(dayOfWeek),
                data: orderedData.map(i => i.actualUsage)
            };
            results.push(graphData);
        }

        return results;
    }

    private getTooltipContent(): string {
        if (this.gasSelected) {
            return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-gas']);
        }
        else if (this.energySelected) {
            return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-electricity']);
        }
        else if (this.heatSelected) {
            return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-heat']);
        }
        else if (this.coldSelected) {
            return LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-cold']);
        }
        else {
            return 'No tooltip found for current datatype!';
        }
    }

    private getGraphHeaderTitle(): string {
        let baseTitle = "";
        switch (this.state.energyType) {
            case EnergyType.Electricity:
                baseTitle = LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.energytitle);
                break;
            case EnergyType.Gas:
                baseTitle = LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.gastitle);
                break;
            case EnergyType.Heat:
                baseTitle = LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.heattitle);
                break;
            case EnergyType.Cold:
                baseTitle = LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.coldtitle);
                break;
        }

        const title = `${baseTitle} - ${LanguageProvider.getTranslation(translations.dates.shorthand.sunday)} ${this.getShortHandDate(this.state.startDate)}`
            + ` ${LanguageProvider.getTranslation(translations.dates.shorthand.upto)} ${LanguageProvider.getTranslation(translations.dates.shorthand.saturday)} ${this.getShortHandDate(this.state.endDate)}`;

        return title;
    }

    private renderDatePicker(): JSX.Element {
        return (
            <DatePickerDeluxe
                defaultDateUnit={'week'}
                onDateChange={this.onDateChange}
                newStartDate={this.state.newStartDate}
                newEndDate={this.state.newEndDate}
                compactView={true}
                newTimeUnitType={this.state.newTimeUnitType}
                disableDayPicker={true}
                disableMonthPicker={true}
                disableYearPicker={true}
                openByDefaultOnPreviousWeek={true}
                draggableOffset={{ x: 50, y: -220 }}
                minimalisticCompactButton={true}
            />
        );
    }

    private getExportFileName(): string {
        return `${this.state.venue && this.state.venue.name}-${this.getGraphHeaderTitle()}`.replaceAll(' ', '_');
    }

    public render(): JSX.Element {
        const tooltipText = this.getTooltipContent();
        return (
            <EnergyManagementPage>
                <PageHeader pageName="energymanagement-reports" />
                <div id="venue-energy-week-overview" className="main-content" ref={this.graphHtmlReference}>
                    <div className="main-content-header">
                        <div className="row input-selection">
                            <div className="col-sm-10">
                                <h1>
                                    {this.state.venue && this.state.venue.name}
                                </h1>
                            </div>
                            <div className="col-sm-2 d-flex png-hidden">
                                <div className="selection-container ml-auto">
                                    {this.state.hasElectricitySubscription && <div onClick={this.setEnergyUnitTypeSelection} className={"selection-item " + (this.energySelected ? "selected" : "")}>
                                        <img src={this.energySelected ? EnergySelectedIcon : EnergyIcon} />
                                    </div>}
                                    {this.state.hasGasSubscription && <div onClick={this.setGasUnitTypeSelection} className={"selection-item " + (this.gasSelected ? "selected" : "")}>
                                        <img src={this.gasSelected ? GasSelectedIcon : GasIcon} />
                                    </div>}
                                    {this.state.hasHeatSubscription && <div onClick={this.setHeatUnitTypeSelection} className={"selection-item " + (this.heatSelected ? "selected" : "")}>
                                        <img src={this.heatSelected ? HeatSelectedIcon : HeatIcon} />
                                    </div>}
                                    {this.state.hasColdSubscription && <div onClick={this.setColdUnitTypeSelection} className={"selection-item " + (this.coldSelected ? "selected" : "")}>
                                        <img src={this.coldSelected ? ColdSelectedIcon : ColdIcon} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <EnergyGraphHeader
                                    selectedTimeUnit={this.state.selectedTimeUnit}
                                    startDate={this.state.startDate}
                                    onDateChange={this.onDateChange}
                                    title={this.getGraphHeaderTitle()}
                                    extraRenderAfterTitle={this.renderDatePicker}
                                    tooltipContent={tooltipText}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <EnergyGraph
                                    width={20}
                                    height={window.innerHeight > this.viewportHeightThresholdForIncreasedGraphHeight ? 7 : 6}
                                    timeRepresentation={'day'}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    unitType={EnergyUnitType[this.state.selectedUnitType]}
                                    graphType={this.state.selectedGraphType}
                                    data={this.energySelected ? this.state.quarterlyDataPerWeekday : this.state.hourlyDataPerWeekday}
                                    isBarGraphElementClickable={this.state.selectedTimeUnit !== 'day'}
                                    showLegend={true}
                                    loading={this.state.loading}
                                    exportOptions={{
                                        fileName: this.getExportFileName(),
                                        enableWeekDailyTimestamp: true
                                    }}
                                    graphHtmlReference={this.graphHtmlReference}
                                />
                                <span className="pl-5 png-hidden">
                                    {LanguageProvider.getTranslation(translations.energy.general.legendexplanation)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </EnergyManagementPage>
        );
    }
}

export default withTranslation()(withTelemetry(EnergyWeekOverviewPage, "EnergyWeekOverviewPage"));