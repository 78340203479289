import * as React from "react";
import { GetTrackProps, SliderItem } from "react-compound-slider";

interface ITrackProps {
    source: SliderItem;
    target: SliderItem;
    getTrackProps: GetTrackProps;
    key: number | string;
}

export default class Track extends React.Component<ITrackProps> {

    public constructor(props: ITrackProps) {
        super(props);
    }
    public render(): JSX.Element {
        const firstRail = this.props.target.id ==="$$-0";
        return (
            <div
                className={`${firstRail ? "track-style first" : "track-style"}`}
                style={{
                    left: `${this.props.source.percent}%`,
                    width: `${this.props.target.percent - this.props.source.percent}%`,
                }}
                {...this.props.getTrackProps()} // this will set up events if you want it to be clickeable (optional)
            />
        );
    }
}