import React, { Component } from 'react';
import TelemetryService, { withTelemetry } from '../services/telemetryService';
import { withRouter, RouteComponentProps } from 'react-router-dom';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<ITelemetryProviderProps, ITelemetryProviderState> {
    public constructor(props: ITelemetryProviderProps) {
        super(props);

        const state: ITelemetryProviderState = {
            initialized: false
        };
        this.state = state;
    }

    public componentDidMount(): void {
        const history = this.props.history;
        const initialized = this.state.initialized;
        const AppInsightsInstrumentationKey = process.env.REACT_APP_endpoints_ApplicationInsights;
        if (!initialized && AppInsightsInstrumentationKey && history) {
            TelemetryService.initialize(AppInsightsInstrumentationKey, history);
            this.setState({ initialized: true });
        }
    }

    public render(): JSX.Element {
        const { children } = this.props;
        return (
            <>
                {children}
            </>
        );
    }
}

export default withTelemetry(withRouter(TelemetryProvider));

export interface ITelemetryProviderProps extends RouteComponentProps {
}

interface ITelemetryProviderState {
    initialized: boolean;
}