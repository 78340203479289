import IUserContext from "../interfaces/IUserContext";

export default class UserContext {
    private readonly userContext: IUserContext;

    public constructor(userContext: IUserContext) {
        this.userContext = userContext;
    }

    public getGraphThresholdsBySubtype(subtype: string): { min?: number, max?: number } {
        const thresholds = this.userContext.graphThresholds.filter(t => t.subtype.toLowerCase().trim() === subtype.toLowerCase().trim());
        const min = thresholds.find(t => t.isMinThreshold);
        const max = thresholds.find(t => !t.isMinThreshold);

        return {
            min: min ? min.value : undefined,
            max: max ? max.value : undefined
        };
    }

    public updateGraphThresholdsBySubType(subtype: string, min?: number, max?: number): void {
        const thresholds = this.userContext.graphThresholds.filter(t => t.subtype.toLowerCase().trim() === subtype.toLowerCase().trim());
        const minThreshold = thresholds.find(t => t.isMinThreshold);
        const maxThreshold = thresholds.find(t => !t.isMinThreshold);

        if (minThreshold !== undefined) {
            minThreshold.value = min;
        }

        if (maxThreshold !== undefined) {
            maxThreshold.value = max;
        }
    }

    public export(): IUserContext {
        return this.userContext;
    }
}