export default class RoomNoShowData {
    public roomName: string;
    public lastNoShow: Date;
    public floorName: string;
    public numberOfNoShows: number;

    public constructor(roomName:string, lastNoShow:Date, floorName: string, numberOfNoShows: number){
        this.roomName = roomName;
        this.lastNoShow = lastNoShow;
        this.floorName = floorName;
        this.numberOfNoShows = numberOfNoShows;
    }
}
