import { ISpaceOnMap, ILabel, ISpaceStyle, IStyle, IStyler } from "@beyondeyes/shared";
import Dictionary from "utils/dictionary";
import Colors from "@/styles/colors";
import BaseStyler from "./baseStyler";

class InsightsMapStyler extends BaseStyler implements IStyler {
	private readonly isSelectableLookup: Dictionary<boolean>;
	private readonly isSelectedLookup: Dictionary<boolean>;

	public constructor() {
		super();
		this.isSelectableLookup = new Dictionary<boolean>();
		this.isSelectedLookup = new Dictionary<boolean>();
	}

	public getDefaultStyle(): IStyle {
		return {
			placeStyle: {
				fillColor: Colors.white,
				fillOpacity: 0.0,
			},
		};
	}

	public getStyle(spaceOnMap: ISpaceOnMap): IStyle | undefined {
		const space = spaceOnMap.beSpace;

		if (space == null) {
			return undefined;
		}

		const isSelectable = this.isSpaceSelectable(space.id);
		if (!isSelectable) {
			return this.getDefaultStyle();
		}

		const placeStyle = this.isSpaceSelected(space.id) ? this.getSpaceIsSelectedStyle() : this.getSpaceIsUnselectedStyle();

		return {
			placeStyle: placeStyle,
			placeLabel: this.getLabel(spaceOnMap),
		};
	}

	public setSelectableSpaces(spaceIds: string[]): void {
		this.isSelectableLookup.clear();
		spaceIds.forEach((spaceId) => this.isSelectableLookup.add(spaceId, true));
	}

	public setSpaceAsSelected(spaceId: string, selected: boolean): void {
		this.isSelectedLookup.add(spaceId, selected);
	}

	public isSpaceSelected(spaceId: string): boolean {
		return this.isSelectedLookup.item(spaceId);
	}

	public isSpaceSelectable(spaceId: string): boolean {
		return this.isSelectableLookup.item(spaceId);
	}

	public clearSelectedSpaces(): void {
		this.isSelectedLookup.clear();
	}

	private getSpaceIsSelectedStyle(): ISpaceStyle {
		return {
			fillOpacity: 0.95,
			fillColor: Colors.turbo_yellow,
		};
	}

	private getSpaceIsUnselectedStyle(): ISpaceStyle {
		return {
			fillOpacity: 0.75,
			fillColor: Colors.shakespeare_blue,
		};
	}

	private getLabel(spaceOnMap: ISpaceOnMap): ILabel {
		return {
			size: this.defaultLabelSize,
			text: spaceOnMap.beSpace!.name,
		};
	}
}

export default InsightsMapStyler;
