import * as React from "react";
import IWorkSpaceReservationThresholdsProps from "./interfaces/IWorkSpaceReservationThresholdsProps";
import IWorkSpaceReservationThresholdsState from "./interfaces/IWorkSpaceReservationThresholdsState";

import LanguageProvider from "@/providers/languageProvider";
import { withTranslation } from "react-i18next";
import { translations } from "@/translations/mapper";
import Select, { OnChangeValue } from "react-select";
import IReactSelectValue from "@/interfaces/IReactSelectValue";
import SelectBoxUtils from "@/utils/selectBoxUtils";

import moment from "moment";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import IWorkSpaceReservationSettings from "@/interfaces/IWorkSpaceReservationSettings";


class WorkSpaceReservationThresholds extends React.Component<IWorkSpaceReservationThresholdsProps, IWorkSpaceReservationThresholdsState>{
    private readonly hour: number = 60 * 60 * 1000;
    private readonly endSlider: number = this.hour * 24;

    public constructor(props: any) {
        super(props);

        this.onDaysInAdvanceChange = this.onDaysInAdvanceChange.bind(this);
        this.onReleaseAfterChange = this.onReleaseAfterChange.bind(this);
        this.onIgnoreReleaseStartChange = this.onIgnoreReleaseStartChange.bind(this);
        this.onIgnoreReleaseEndChange = this.onIgnoreReleaseEndChange.bind(this);
        this.releaseIsDisabled = this.releaseIsDisabled.bind(this);
        this.displayHandleValue = this.displayHandleValue.bind(this);
        this.validateTimeInput = this.validateTimeInput.bind(this);

        this.updateColumnNames = this.updateColumnNames.bind(this);
        this.setInitialThesholdValues = this.setInitialThesholdValues.bind(this);
        this.getInAdvanceLabels = this.getInAdvanceLabels.bind(this);
        this.getReleaseAfterLabels = this.getReleaseAfterLabels.bind(this);

        this.getSelectedDaysInAdvanceLabel = this.getSelectedDaysInAdvanceLabel.bind(this);
        this.getSelectedreleaseAfterMinutesLabel = this.getSelectedreleaseAfterMinutesLabel.bind(this);

        const state: IWorkSpaceReservationThresholdsState = {
            loading: true,
            venueWorkSpaceSettings: this.props.initialWorkSpaceReservationSettings,
            selectedInAdvanceOption: this.getSelectedDaysInAdvanceLabel(),
            selectedReleaseAfterOption: this.getSelectedreleaseAfterMinutesLabel(),
            inAdvanceLabels: [],
            releaseAfterLabels: [],
            validTimeInput: true
        };

        this.state = state;

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumnNames);
    }

    public async componentDidMount(): Promise<void> {
        this.updateColumnNames();
    }

    public async componentDidUpdate(prevProps: IWorkSpaceReservationThresholdsProps, prevState: IWorkSpaceReservationThresholdsState): Promise<void> {
        if (prevProps.isVenueSwapLoading && !this.props.isVenueSwapLoading) {
            this.setInitialThesholdValues();
        }
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumnNames);
    }

    private getSelectedDaysInAdvanceLabel(): IReactSelectValue {
        const daysInAdvanceValue = this.props.initialWorkSpaceReservationSettings.daysInAdvance?.toString() ?? "30";
        return this.getInAdvanceLabels().find(i => i.value === daysInAdvanceValue)!;
    }

    private getSelectedreleaseAfterMinutesLabel(): IReactSelectValue {
        const releaseAfterMinutesValue = this.props.initialWorkSpaceReservationSettings.releaseAfterMinutes?.toString() ?? "-1";
        return this.getReleaseAfterLabels().find(i => i.value === releaseAfterMinutesValue)!;
    }

    private setInitialThesholdValues(): void {
        const selectedInAdvanceLabel = this.getSelectedDaysInAdvanceLabel();
        const selectedreleaseAfterMinutesLabel = this.getSelectedreleaseAfterMinutesLabel();

        const settings = this.state.venueWorkSpaceSettings;

        settings.ignoreReleaseStart = this.props.initialWorkSpaceReservationSettings.ignoreReleaseStart;
        settings.ignoreReleaseEnd = this.props.initialWorkSpaceReservationSettings.ignoreReleaseEnd;

        settings.releaseAfterMinutes = this.props.initialWorkSpaceReservationSettings.releaseAfterMinutes ?? -1;

        this.setState({
            selectedInAdvanceOption: selectedInAdvanceLabel,
            venueWorkSpaceSettings: settings,
            selectedReleaseAfterOption: selectedreleaseAfterMinutesLabel
        });
    }

    private onDaysInAdvanceChange(selectedItem: OnChangeValue<IReactSelectValue, false>): void {
        const item = selectedItem as IReactSelectValue;
        const settings = this.state.venueWorkSpaceSettings;
        settings.daysInAdvance = Number(item.value);
        this.setState(
            {
                selectedInAdvanceOption: item,
                venueWorkSpaceSettings: settings,
            }, () => this.props.onChange(settings)
        );
    }

    private onReleaseAfterChange(selectedItem: OnChangeValue<IReactSelectValue, false>): void {
        const item = selectedItem as IReactSelectValue;
        const settings = this.state.venueWorkSpaceSettings;

        if (item.value === "-1") {
            settings.ignoreReleaseStart = "";
            settings.ignoreReleaseEnd = "";
        }
        else if (settings.releaseAfterMinutes === -1) {
            settings.ignoreReleaseStart = "12:00";
            settings.ignoreReleaseEnd = "13:00";
        }

        settings.releaseAfterMinutes = Number(item.value);

        this.setState(
            {
                selectedReleaseAfterOption: item,
                venueWorkSpaceSettings: settings,
            }, () => this.props.onChange(settings)
        );
    }

    private displayHandleValue(value: number): string {
        if (value === this.endSlider) {
            value = value - 60 * 1000;
        }

        const date = new Date(value);
        return moment.utc(date).format('HH:mm');
    }

    private onIgnoreReleaseStartChange(event: any): void {
        const ignoreStart = event.target.value;

        const settings = this.state.venueWorkSpaceSettings;
        settings.ignoreReleaseStart = ignoreStart;

        const validTimeInput = this.validateTimeInput(settings);

        this.setState(
            {
                venueWorkSpaceSettings: settings,
                validTimeInput: validTimeInput,
            }, () => this.props.onChange(settings, validTimeInput));
    }

    private onIgnoreReleaseEndChange(event: any): void {
        const ignoreEnd = event.target.value;

        const settings = this.state.venueWorkSpaceSettings;
        settings.ignoreReleaseEnd = ignoreEnd;

        const validInput = this.validateTimeInput(settings);

        this.setState(
            {
                venueWorkSpaceSettings: settings,
                validTimeInput: validInput,
            }, () => this.props.onChange(settings, validInput));
    }

    private releaseIsDisabled(): boolean {
        return this.state.venueWorkSpaceSettings.releaseAfterMinutes === -1;
    }

    private validateTimeInput(settings: IWorkSpaceReservationSettings): boolean {
        if (settings.ignoreReleaseStart === "" && settings.ignoreReleaseEnd === "") {
            return true;
        }
        const regEx = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
        return regEx.test(settings.ignoreReleaseStart) && regEx.test(settings.ignoreReleaseEnd);
    }

    private updateColumnNames(): void {
        const inAdvanceLabels = this.getInAdvanceLabels();
        const releaseAfterLabels = this.getReleaseAfterLabels();

        const currentSelectedInadvance = this.state.selectedInAdvanceOption;
        const currentSelectedReleaseAfter = this.state.selectedReleaseAfterOption;

        const matchingInAdvanceLabel = inAdvanceLabels.find(l => l.value === currentSelectedInadvance.value);
        if (matchingInAdvanceLabel !== undefined) {
            currentSelectedInadvance.label = matchingInAdvanceLabel.label;
        }

        const matchingReleaseAfterLabel = releaseAfterLabels.find(l => l.value === currentSelectedReleaseAfter.value);
        if (matchingReleaseAfterLabel !== undefined) {
            currentSelectedReleaseAfter.label = matchingReleaseAfterLabel.label;
        }

        this.setState({
            inAdvanceLabels: inAdvanceLabels,
            releaseAfterLabels: releaseAfterLabels
        });
    }

    private getInAdvanceLabels(): IReactSelectValue[] {
        const root = translations.pages.settings.workspaces.generaltab.labels;
        return [
            { label: `1 ${LanguageProvider.getTranslation(root.day)}`, value: "1" },
            { label: `1 ${LanguageProvider.getTranslation(root.week)}`, value: "7" },
            { label: `2 ${LanguageProvider.getTranslation(root.weeks)}`, value: "14" },
            { label: `3 ${LanguageProvider.getTranslation(root.weeks)}`, value: "21" },
            { label: `1 ${LanguageProvider.getTranslation(root.month)}`, value: "30" },
            { label: `2 ${LanguageProvider.getTranslation(root.months)}`, value: "60" },
            { label: `3 ${LanguageProvider.getTranslation(root.months)}`, value: "90" },
            { label: `6 ${LanguageProvider.getTranslation(root.months)}`, value: "180" },
            { label: `12 ${LanguageProvider.getTranslation(root.months)}`, value: "365" }
        ];
    }

    private getReleaseAfterLabels(): IReactSelectValue[] {
        const root = translations.pages.settings.workspaces.generaltab.labels;
        return [
            { label: `${LanguageProvider.getTranslation(root.never)}`, value: "-1" },
            { label: `15 ${LanguageProvider.getTranslation(root.minutes)}`, value: "15" },
            { label: `30 ${LanguageProvider.getTranslation(root.minutes)}`, value: "30" },
            { label: `60 ${LanguageProvider.getTranslation(root.minutes)}`, value: "60" },
            { label: `120 ${LanguageProvider.getTranslation(root.minutes)}`, value: "120" },
            { label: `180 ${LanguageProvider.getTranslation(root.minutes)}`, value: "180" },
            { label: `240 ${LanguageProvider.getTranslation(root.minutes)}`, value: "240" }
        ];
    }

    public render(): JSX.Element {
        return (
            <>
                    <div className="row header-margin-bottom-paragraph">
                    <div className="col-sm">
                        <h1>{LanguageProvider.getTranslation(translations.pages.settings.realestateutilization.workspacereservations)}</h1>
                            <div className="row">
                                <div className="col-sm-9">
                                    {LanguageProvider.getTranslation(translations.pages.settings.workspaces.settingsdescription)}
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="main-content-body">
                    <div className="row">
                        <div className="col-sm">
                            <h2>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.thresholds)}</h2>
                        </div>
                    </div>
                    {this.props.hasReserveWorkspacesSubscription &&
                        <div className="pl-0 col-3">
                            <h3>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.inadvance)}</h3>
                            <Select
                                isDisabled={true}
                                value={this.state.selectedInAdvanceOption}
                                onChange={this.onDaysInAdvanceChange}
                                options={this.getInAdvanceLabels()}
                                styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)}
                            />
                        </div>}
                    {this.props.hasAutomaticWorkspaceCancellationSubscription && <>
                        <div className="pl-0 mt-3 col-3">
                            <h3>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.release)}</h3>
                            <Select
                                value={this.state.selectedReleaseAfterOption}
                                onChange={this.onReleaseAfterChange}
                                options={this.getReleaseAfterLabels()}
                                styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)}
                            />
                        </div>
                        <div className="pl-0 mt-3 col-8">
                            <h3>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.ignorerelease)}</h3>
                            <div className="row threshold-time-input d-flex align-items-center">
                                <div className="row mt-1 col-4">
                                    <div className="col-sm-6">
                                        <input
                                            className={`form-control ${this.state.validTimeInput ? '' : 'is-invalid'}`}
                                            required={false}
                                            placeholder={LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.from)}
                                            value={this.state.venueWorkSpaceSettings.ignoreReleaseStart}
                                            disabled={this.releaseIsDisabled()}
                                            onChange={this.onIgnoreReleaseStartChange} />
                                    </div>
                                    <div className="pl-0 pr-0 col-sm-6">
                                        <input
                                            className={`form-control ${this.state.validTimeInput ? '' : 'is-invalid'}`}
                                            placeholder={LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.until)}
                                            value={this.state.venueWorkSpaceSettings.ignoreReleaseEnd}
                                            disabled={this.releaseIsDisabled()}
                                            onChange={this.onIgnoreReleaseEndChange} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    {!this.state.validTimeInput && this.state.venueWorkSpaceSettings.releaseAfterMinutes !== -1 &&
                                        <p className="text-danger mb-0">{LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.invalidtime)}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </>);
    }
}

export default withTranslation()(WorkSpaceReservationThresholds);