import { ApplicationConfig } from "../config";
import { PublicClientApplication } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";

export default class AuthService {
	protected readonly publicClientApplication: PublicClientApplication;

	public constructor() {
		this.publicClientApplication = new PublicClientApplication({
			auth: {
				clientId: ApplicationConfig.ad.clientId,
				authority: `https://login.microsoftonline.com/organizations`,
				redirectUri: ApplicationConfig.ad.redirectUri,
			},
			cache: {
				cacheLocation: ApplicationConfig.ad.cacheLocation,
			},
		});
	}

	public async login(): Promise<void> {
		let account = await this.getUser();

		let isExpired = true;
		const overlapMilliseconds = 5 * 60 * 1000; // Redirect when token is less than 5 minutes valid

		if (account?.username === "cypress@besense.onmicrosoft.com") {
			account.idTokenClaims = { exp: Date.now() + (60 * 60 * 1000) };
		}

		if (account?.idTokenClaims?.exp) {
			const tokenExpirationEpochInMilliseconds = account?.idTokenClaims?.exp * 1000;
			const now = Date.now() - overlapMilliseconds;
			isExpired = tokenExpirationEpochInMilliseconds < now;
		}

		if (isExpired)
		{
			await this.publicClientApplication.loginRedirect({ scopes: [ApplicationConfig.ad.scopes.dashboardApi] });
			account = await this.getUser();
		}

		this.publicClientApplication.setActiveAccount(account);
	}

	private getUser(): Promise<AccountInfo | null> {
		return this.publicClientApplication.handleRedirectPromise().then((response) => {
			if (response != null) {
				return response.account;
			} else {
				return this.getLoggedInAccount();
			}
		});
	}

	public async authenticateRequest(request: RequestInit): Promise<RequestInit> {
		const accessToken = await this.getTokenAsync([ApplicationConfig.ad.scopes.dashboardApi]);
		request.headers = { ...request.headers, Authorization: `Bearer ${accessToken}` };
		return request;
	}

	public async getTokenAsync(scopes: string[]): Promise<string> {
		const account = this.publicClientApplication.getActiveAccount();

		if (account == null) {
			return "";
		}

		const silentRequest = {
			scopes: scopes,
			forceRefresh: false,
			account: account,
		};

		const result = await this.publicClientApplication.acquireTokenSilent(silentRequest);
		if (!result.accessToken) {
			throw Error();
		}

		this.publicClientApplication.setActiveAccount(account);

		return result.accessToken;
	}

	private getLoggedInAccount(): AccountInfo | null {
		const allAccounts = this.publicClientApplication.getAllAccounts();
		if (allAccounts.length === 1) {
			return allAccounts[0];
		} else if (allAccounts.length > 1) {
			allAccounts.forEach((e) => {
				const logoutRequest = {
					account: e,
				};

				this.publicClientApplication.logoutRedirect(logoutRequest);
			});
		}

		return null;
	}
}
