import * as React from 'react';
import { Component } from "react";
import IExpandedThresholdNotificationProps from "./IExpandedThresholdNotificationProps";
import ThresholdNotificationProvider from "@/providers/thresholdNotificationProvider";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import './expandedNotification.scss';

export default class ExpandedThresholdNotification extends Component<IExpandedThresholdNotificationProps>{
    public constructor(props: IExpandedThresholdNotificationProps) {
        super(props);
    }

    private roundMeasurement(measurement: number): number {
        return Math.round(measurement * 10) / 10;
    }

    public render(): JSX.Element {
        return (
            <div className="expanded-notification">
                <div className="expanded-header mb-3">
                    {ThresholdNotificationProvider.GetTranslatedCategoryForThresholdNotification(this.props.notification)}
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.createdby)}
                                    </b>
                                </div>
                                {this.props.notification.createdBy.toString()}
                            </div>
                            <div className="col-sm-4">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.venuename)}
                                    </b>
                                </div>
                                {this.props.notification.venueName}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-5">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.createdon)}
                                    </b>
                                </div>
                                {this.props.notification.LocalizedCreatedOn.format("D-M-YYYY HH:mm")}
                            </div>
                            <div className="col-sm-4">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.spacename)}
                                    </b>
                                </div>
                                {this.props.notification.spaceName}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-5">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.resolvedon)}
                                    </b>
                                </div>
                                {this.props.notification.LocalizedResolvedOn !== undefined && this.props.notification.LocalizedResolvedOn !== null ? this.props.notification.LocalizedResolvedOn.format("D-M-YYYY HH:mm") : "--"}
                            </div>
                            <div className="col-7">
                                <div className="mb-1 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.status)}
                                    </b>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="expand-measurement">
                                            {`${this.roundMeasurement(this.props.notification.thresholdValue)} ${ThresholdNotificationProvider.getMeasurementIcon(this.props.notification.category)}`}
                                        </div>
                                        <div className="mb-2 expand-sub-title">
                                            {LanguageProvider.getTranslation(translations.pages.notifications.thresholdexpand.thresholdvalue)}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="expand-measurement">
                                            {`${this.roundMeasurement(this.props.notification.measuredValue)} ${ThresholdNotificationProvider.getMeasurementIcon(this.props.notification.category)}`}
                                        </div>
                                        <div className="mb-2 expand-sub-title">
                                            {LanguageProvider.getTranslation(translations.pages.notifications.thresholdexpand.measuredvalue)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 ml-3">
                        <div className="mb-2 expand-title">
                            <b>
                                {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.description)}
                            </b>
                        </div>
                        {ThresholdNotificationProvider.GetTranslatedDescriptionForThresholdNotification(this.props.notification)}
                    </div>
                </div>
            </div>
        );
    }
}