import { ISpaceOnMap } from "@beyondeyes/shared";
import CoreSpace from "models/coreSpace";
import MultiAssetMotionValue from "models/multiAssetMotionValue";
import AbnormalSpaceValue from "pages/realestateutilization/components/models/abnormalSpaceValue";

class AbnormalitiesChecker {
	private allAssetData: MultiAssetMotionValue[][];
	private exceptionLowerBound: number;
	private exceptionUpperBound: number;

	public constructor() {
		this.allAssetData = [];
	}

	public checkForAbnormalities(spacesOnMap: ISpaceOnMap[]): AbnormalSpaceValue[] {
		const abnormalities: AbnormalSpaceValue[] = [];

		spacesOnMap.forEach((s) => {
			const abnormality = this.checkForSpaceAbnormalities(s);

			if (abnormality != null) {
				abnormalities.push(abnormality);
			}
		});

		return abnormalities;
	}

	private checkForSpaceAbnormalities(spaceOnMap: ISpaceOnMap): AbnormalSpaceValue | undefined {
		const space = spaceOnMap.beSpace as CoreSpace;

		if (space == null) {
			return;
		}

		if (space.hasMotionDevices) {
			return this.getAbnormalSpaceValue(spaceOnMap);
		}

		return;
	}

	public SetAllAssetData(allAssetData: MultiAssetMotionValue[][]): void {
		this.allAssetData = allAssetData;
	}

	public SetExceptionBounds(lowerBound: number, upperBound: number): void {
		this.exceptionLowerBound = lowerBound;
		this.exceptionUpperBound = upperBound;
	}

	private getAbnormalSpaceValue(spaceOnMap: ISpaceOnMap): AbnormalSpaceValue | undefined {
		const average = Math.round(this.calculateAverageOccupancyForSpace(spaceOnMap.beSpace! as CoreSpace));

		if (average < 0) {
			// Handle assets with no known data:
			return;
		}

		const isException = average < this.exceptionLowerBound || average > this.exceptionUpperBound;

		if (isException) {
			return new AbnormalSpaceValue(spaceOnMap.beSpace!.name, average);
		}

		return;
	}

	private calculateAverageOccupancyForSpace(space: CoreSpace): number {
		const assetData = this.allAssetData.find((ar) => ar.find((v) => v.partitionKey === space.id) !== undefined);

		if (assetData === undefined) {
			return -1;
		} else {
			return assetData.reduce((p, c) => p + c.percentage, 0) / assetData.length;
		}
	}
}

export default AbnormalitiesChecker;
