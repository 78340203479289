import { UnavailableWorkPlaceImage, ComfortSensorImage } from "@/images";
import { IMarker, ISpaceStyle, IStyle, IStyler, ISpaceOnMap } from "@beyondeyes/shared";
import CoreSpace from "models/coreSpace";
import Colors from "@/styles/colors";
import BaseStyler from "./baseStyler";

class OccupancyStyler extends BaseStyler implements IStyler {
	private imageSize: number = 30;

	public getStyle(spaceOnMap: ISpaceOnMap): IStyle | undefined {
		const space = spaceOnMap.beSpace as CoreSpace;

		if (space == null) {
			return undefined;
		}

		const placeMarker = space.isRestricted ? this.createRestrictedMarker() : this.getPotentialComfortMarker(space);
		const placeStyle = this.getPlaceStyle(space);

		return {
			placeStyle: placeStyle,
			placeMarker: placeMarker,
			placeLabel: { text: space.name, size: this.defaultLabelSize }
		};
	}

	private getPotentialComfortMarker(space: CoreSpace): IMarker | undefined {
		if (!space.hasComfortDevices) {
			return undefined;
		}
		return {
			image: ComfortSensorImage,
			imageSize: this.imageSize,
		};
	}

	private createRestrictedMarker(): IMarker {
		return {
			image: UnavailableWorkPlaceImage,
			imageSize: this.imageSize,
		};
	}

	private getPlaceStyle(space: CoreSpace): ISpaceStyle {
		return space.hasMotionDevices ? this.getOccupancyStyling(space) : this.getNoDevicesStyling();
	}

	private getOccupancyStyling(space: CoreSpace): ISpaceStyle {
		const occupancyColor = space.isCoolingDown ? Colors.signal_orange : space.isOccupied ? Colors.signal_red : Colors.signal_green;

		return {
			fillColor: occupancyColor,
			fillOpacity: 0.95,
		};
	}

	private getNoDevicesStyling(): ISpaceStyle {
		return {
			fillColor: Colors.shakespeare_blue,
			fillOpacity: 0.95,
		};
	}
}

export default OccupancyStyler;
