import moment from 'moment';
import 'moment/locale/nl';

import NotificationService from "@/services/notificationService";
import { withTelemetry } from "@/services/telemetryService";
import WorkspaceNotification from "@/models/workspaceNotification";
import BaseNotificationsComponent from "../abstractions/baseNotificationsComponent";
import WorkspaceNotificationProvider from "@/providers/workspaceNotificationProvider";
import ExpandedWorkspaceNotification from "../components/expandedWorkspaceNotification";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";
import Teaser from '@/components/teaser/realEstateUtilizationTeaser';
import VenueProvider from '@/providers/venueProvider';
import { ConditionalStyles } from "react-data-table-component";
import ISelectDropdownField from '@/components/filterMenu/interfaces/ISelectDropdownField';

class WorkspaceNotificationsPage extends BaseNotificationsComponent<WorkspaceNotification> {
    private readonly notificationService: NotificationService;

    public constructor(props: any) {
        super(props);

        this.notificationService = new NotificationService();
    }

    public async componentDidMount(): Promise<void> {
        await super.componentDidMountBase();
    }

    public componentWillUnmount(): void {
        super.componentWillUnmountBase();
    }

    protected async getNotificationsAsync(): Promise<void> {
        this.setState({
            loading: true
        });

        const endOfDayOfEndDate = moment(this.state.endDate).add(1, "days").toDate();
        const retrievedWorkspaceNotifications = await this.notificationService.getWorkspaceNotificationsForCurrentVenueAsync(this.state.startDate, endOfDayOfEndDate);

        this.setState({
            notifications: retrievedWorkspaceNotifications,
            loading: false
        }, () => {
            this.setSelectFilterOptions();
            this.updateFilters();
        });
    }

    protected translateTypeOfElement(element: WorkspaceNotification): string {
        return WorkspaceNotificationProvider.getTranslatedNotificationCategoryForWorkspaceNotification(element);
    }

    protected translateFilterCategory(filterCategory: string): string {
        return WorkspaceNotificationProvider.translateNotificationCategory(filterCategory);
    }

    protected getTitle(): string {
        return `${LanguageProvider.getTranslation(translations.navigation.realestateutilization)} - ${LanguageProvider.getTranslation(translations.pages.notifications.notifications)}`;
    }

    protected renderAboveNotifications(): JSX.Element {
        return (<></>);
    }

    protected getConditionalRowStyles(): ConditionalStyles<WorkspaceNotification>[] {
        return [];
    }

    protected getExtraFilterDropdowns(): ISelectDropdownField[] {
        return [];
    }

    protected renderExpandedNotification(row: any): JSX.Element {
        return (
            <div className="expanded-notification-row">
                <ExpandedWorkspaceNotification notification={row} />
            </div>);
    }

    protected setHasSubscription(): void {
        const venue = VenueProvider.getActiveVenue();

        const hasSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEEquipped"]) : false;
        this.setState({
            hasSubscription: hasSubscription
        });
    }

    protected getTeaser(): JSX.Element {
        return (
            <Teaser />
        );
    }
}

export default withTelemetry(WorkspaceNotificationsPage, "WorkspaceNotificationsPage");