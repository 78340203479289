import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';

import LineGraph from '@/components/graphs/lineGraph';
import LanguageProvider from '@/providers/languageProvider';
import DataInsightsService from '@/services/dataInsightsService';
import translations from '@/translations/mapper';
import IComfortOverviewGraphProps from './interfaces/IComfortOverviewGraphProps';
import IComfortOverviewGraphState from './interfaces/IComfortOverviewGraphState';
import Colors from '@/styles/colors';

export default abstract class BaseComfortOverviewGraph extends React.Component<IComfortOverviewGraphProps, IComfortOverviewGraphState> {

    protected dataInsightsService: DataInsightsService;
    public constructor(props: IComfortOverviewGraphProps) {
        super(props);
        this.dataInsightsService = new DataInsightsService();

        const state: IComfortOverviewGraphState = {
            chartData: [],
            loading: true
        };

        this.state = state;
    }

    public abstract getTestKey(): string;

    public async componentDidMount(): Promise<void> {

        await this.updateChartData();

        // DO NOT REMOVE!! This avoids a (suspected) bug within react chartjs.
        // Reproduction steps (when this code is removed):
        // 1. Go to insights comfort page of a building with comfort sensors.
        // 2. BEFORE SELECTING A FLOOR, select CO2 as type.
        // 3. Select a floor.
        // 4. Select any asset, this will cause a 'Cannot read property of '_meta' of undefined' exception in chartJs.
        //
        // This bug seems to be avoided if the original data set has been loaded at least twice, giving us the following (ugly) solution.
        await this.updateChartData();
        this.setState({
            loading: false
        });
    }

    public async componentDidUpdate(previousProps: IComfortOverviewGraphProps, previousState: IComfortOverviewGraphState): Promise<void> {
        if (previousProps.startDate === this.props.startDate
            && previousProps.endDate === this.props.endDate
            && previousProps.assetIds === this.props.assetIds
            && previousProps.selectedComfortType.value === this.props.selectedComfortType.value
            && previousProps.thresholdLow === this.props.thresholdLow
            && previousProps.thresholdHigh === this.props.thresholdHigh) {
            return;
        }

        if (this.props.assetIds.length === 0) {
            this.setState({
                chartData: []
            });

            return;
        }

        this.setState({
            loading: true,
            chartData: []
        });

        if (!(previousProps.startDate === this.props.startDate
            && previousProps.endDate === this.props.endDate
            && previousProps.assetIds === this.props.assetIds
            && previousProps.selectedComfortType.value === this.props.selectedComfortType.value)) {
            await this.updateChartData();
        }
        else if (previousProps.thresholdLow !== this.props.thresholdLow || previousProps.thresholdHigh !== this.props.thresholdHigh) {
            const chartData = this.state.chartData;

            const lowerSet = chartData.find(c => c.isLowerBound);
            const upperSet = chartData.find(c => c.isUpperBound);

            if (lowerSet && this.props.thresholdLow !== undefined) {
                lowerSet.data = lowerSet.data.map(n => (this.props.thresholdLow as number));
            }

            if (upperSet && this.props.thresholdHigh !== undefined) {
                upperSet.data = upperSet.data.map(n => (this.props.thresholdHigh as number));
            }

            this.setState({ chartData: chartData });
        }

        this.setState({
            loading: false
        });
    }

    public abstract updateChartData(): Promise<void>;

    public abstract getLabels(): string[];

    public render(): JSX.Element {
        const labels = this.getLabels();
        const minThresholdLabel = `${this.props.selectedComfortType.label} ${LanguageProvider.getTranslation(translations.pages.comfortinsights.reports.graph.comfort.thresholdmin)}`;
        const maxThresholdLabel = `${this.props.selectedComfortType.label} ${LanguageProvider.getTranslation(translations.pages.comfortinsights.reports.graph.comfort.thresholdmax)}`;

        const dataSets: Chart.ChartDataSets[] = this.state.chartData.filter((assetData) => assetData.data.length > 0).map((assetData, i) => {
            if (assetData.isLowerBound || assetData.isUpperBound) {
                return {
                    label: assetData.isLowerBound ? minThresholdLabel : maxThresholdLabel,
                    data: assetData.data,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: Colors.dark_grey,
                    yAxisID: 'line',
                    borderWidth: 1.5,
                    pointStyle: "circle",
                    borderDash: [8, 5],
                    pointBackgroundColor: Colors.dark_grey,
                    pointBorderColor: Colors.dark_grey,
                    pointRadius: 0
                } as Chart.ChartDataSets;
            }

            const color = assetData.color;
            return {
                label: this.props.selectedComfortType.label + " " + assetData.label,
                data: assetData.data,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: color,
                yAxisID: 'line',
                borderWidth: 1.5,
                pointStyle: "circle",
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointRadius: 4
            } as Chart.ChartDataSets;
        });

        const data: ChartData = {
            labels: labels,
            datasets: dataSets
        };

        const options: ChartOptions = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        beginAtZero: true
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }],
                yAxes: [{
                    id: 'line',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        padding: 10
                    }
                }]
            },
            maintainAspectRatio: true,
            legend: {
                display: false
            }
        };

        return (
            <LineGraph
                testKey={this.getTestKey()}
                data={data}
                showLegend={true}
                legendFilter={(dataset): boolean => dataset.label === minThresholdLabel || dataset.label === maxThresholdLabel}
                height={this.props.height}
                width={this.props.width}
                options={options}
                loading={this.state.loading} />
        );
    }
}