import WorkspaceNotification from "../models/workspaceNotification";
import LanguageProvider from "./languageProvider";
import {LanguageCode} from "./languageProvider";

export default class WorkspaceNotificationProvider{
    public static getTranslatedDescriptionForWorkspaceNotification(notification: WorkspaceNotification): string{
        const activeLanguage = LanguageProvider.activeLanguage;

        switch (notification.category){
            case WorkspaceNotification.RestrictedWorkspaceCategory:
                switch (activeLanguage) {
                    case LanguageCode.EN:
                        return `Take note: someone has taken a workspace that was marked as 'not available'. ` +
                        `The workspace is '${notification.spaceName}' on floor '${notification.floorName}' in venue '${notification.venueName}'. ` +
                        `This notification was created automatically by a sensor, after it detected motion. ` +
                        `After the person leaves the workspace, the status of this notification will be changed to solved. `;
                    case LanguageCode.NL:
                        return `Let op: er is bezetting gedetecteerd op een werkplek die is aangegeven als 'niet beschikbaar'. ` +
                        `Het gaat om werkplek '${notification.spaceName}' op verdieping '${notification.floorName}' in gebouw '${notification.venueName}'. ` +
                        `Deze melding is automatisch aangemaakt door een sensor, omdat deze beweging heeft geconstateerd. ` +
                        `Nadat de persoon de werkplek verlaten heeft, wordt de status automatisch veranderd naar opgelost. `;
                    default:
                        return `No description translations available yet for language ${activeLanguage}`;
                }
            default:
                return `No description translations available yet for category ${notification.category}`;
        }
    }

    public static getTranslatedNotificationCategoryForWorkspaceNotification(notification: WorkspaceNotification): string{
        return WorkspaceNotificationProvider.translateNotificationCategory(notification.category);
    }

    public static translateNotificationCategory(category: string): string{
        const activeLanguage = LanguageProvider.activeLanguage;

        switch(category){
            case WorkspaceNotification.RestrictedWorkspaceCategory:
                switch(activeLanguage){
                    case LanguageCode.EN:
                        return "Workspace Alert";
                    case LanguageCode.NL:
                        return "Werkplek melding";
                    default:
                        return `No category translations available yet for language ${activeLanguage}`;
                }
            default:
                return `No category translations available yet for category ${category}`;
        }
    }
}