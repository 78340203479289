import React from "react";
import { ChartOptions } from "chart.js";

import INoShowGraphProps from "./interfaces/INoShowGraphProps";

import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import HorizontalBarGraph from '@/components/graphs/horizontalBarGraph';
import IBeSenseChartData from "@/components/graphs/interfaces/IBeSenseChartData";

import IHistoricReservationsGroup from "../interfaces/IHistoricReservationsGroup";
import Colors from "@/styles/colors";

export default class NoShowAveragesTopFive extends React.Component<INoShowGraphProps> {

    public constructor(props: INoShowGraphProps) {
        super(props);
    }

    private getTopFiveData(): IBeSenseChartData {
        let workSpaceNames: string[] = [];
        let workSpaceNoShowsCount: number[] = [];

        if (this.props.historicReservations.length !== 0) {
            const noShows = this.props.historicReservations.filter(r => r.isNoShow);
            const groupedReservations = noShows.reduce((workspaceReservations, reservation) => {
                const key = reservation.assetName;
                let workspace = workspaceReservations.find(r => r.key === key);
                if (workspace === undefined) {
                    workspace = {
                        key: key,
                        historicReservations: [reservation]
                    };
                    workspaceReservations.push(workspace);
                }
                else {
                    workspace.historicReservations.push(reservation);
                }

                return workspaceReservations;
            }, [] as IHistoricReservationsGroup[]);

            const sortedGroup = groupedReservations.sort((a, b) => {
                return b.historicReservations.length - a.historicReservations.length;
            }).slice(0, 5);

            workSpaceNames = sortedGroup.map(g => g.key);
            workSpaceNoShowsCount = sortedGroup.map(g => g.historicReservations.length);
        }

        else {
            workSpaceNames = ["", "", "", "", ""];
            workSpaceNoShowsCount = [0, 0, 0, 0, 0];
        }

        const topFiveData: IBeSenseChartData = {
            labels: workSpaceNames,
            datasets: [
                {
                    label: "No-shows",
                    backgroundColor: [Colors.shakespeare_blue, Colors.shakespeare_blue, Colors.shakespeare_blue, Colors.shakespeare_blue, Colors.shakespeare_blue],
                    data: workSpaceNoShowsCount,
                    barThickness: 20,
                }
            ]
        };

        return topFiveData;
    }

    private getTopFiveOptions(): ChartOptions {
        const topFiveOptions: ChartOptions = {
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 8,
                        fontStyle: "300",
                        beginAtZero: true
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }],
                xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: false,
                        },
                        id: 'bar',
                        type: 'linear',
                        position: 'top',
                        gridLines: {
                            drawBorder: false,
                            zeroLineColor: Colors.alto_grey,
                            color: Colors.black
                        },
                        ticks: {
                            display: false,
                            beginAtZero: true
                        }
                    }
                ]
            },
            maintainAspectRatio: true,
            legend: {
                display: false
            },
            plugins: {
                datalabels: {
                    display: true,
                    anchor: 'end',
                    color: Colors.blue_grey,
                    align: 'right',
                    offset: 2,
                    font: {
                        size: 8
                    },
                    clamp: true
                }
            },
            layout: {
                padding: {
                    right: 30
                }
            },
            title: {
                display: false,
                text: "no-show-top-five"
            }
        };

        return topFiveOptions;
    }

    public render(): JSX.Element {

        return (
            <div className="no-show-averages-component" id="no-show-top-five">
                <div className="no-show-graph">
                    <HorizontalBarGraph testKey={"no-show-averages-top-five"} data={this.getTopFiveData()} options={this.getTopFiveOptions()} />
                </div>
                <div className="no-show-averages-title">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.outliers)}</div>
                <div className="no-show-averages-subtitle">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.mostnoshows)}</div>
            </div>
        );
    }

}