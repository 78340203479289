import React from "react";
import { PulseLoader } from "react-spinners";

import CoreSpace from "@/models/coreSpace";
import VenueProvider from "@/providers/venueProvider";
import CoreSpaceService from "@/services/coreSpaceService";
import Sorter from "@/utils/sorter";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import IVenuePickerState from "./interfaces/IVenuePickerState";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import SubscriptionIcon from "@/images/venue_vink.svg";
import VenueIcon from "@/images/venue_picker.svg";
import CrossCancelIcon from "@/images/Cross_Cancel.svg";
import "./venuePicker.scss";
import Colors from "@/styles/colors";

class VenuePicker extends React.Component<{}, IVenuePickerState> {

    private readonly coreSpaceService: CoreSpaceService;

    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: any) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();

        const venue = VenueProvider.getActiveVenue();
        const state: IVenuePickerState = {
            activeVenue: venue,
            venues: [],
            loading: true,
            venueFilter: ""
        };

        this.state = state;

        this.renderDropdownItem = this.renderDropdownItem.bind(this);
        this.setActiveVenue = this.setActiveVenue.bind(this);
        this.setActiveVenueByExternalEvent = this.setActiveVenueByExternalEvent.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.setActiveVenueByExternalEvent);
    }

    public async componentDidMount(): Promise<void> {
        const [venues, subscriptionValidationService] = await Promise.all([this.coreSpaceService.getVenues(), SubscriptionValidationService.GetInstanceAsync()]);

        const sortedVenues = Sorter.sortByProperty(venues, "name");

        this.subscriptionValidationService = subscriptionValidationService;

        this.setState({
            venues: sortedVenues,
            loading: false,
            venueFilter: ""
        });
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.setActiveVenueByExternalEvent);
    }

    private setActiveVenueByExternalEvent(): void {
        const venue = VenueProvider.getActiveVenue();

        if (venue) {
            this.setActiveVenue(venue);
        }
    }

    private renderDropdownItem(venue: CoreSpace, renderSeparator: boolean): JSX.Element {
        const isActive = this.state.activeVenue?.id === venue.id;

        const hasIndoorClimate = this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEVital"]);
        const hasEnergyManagement = this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEEnergized"]);
        const hasRealEstateUtilization = this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEEquipped"]);
        const hasCleaning = this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEClean"]);

        return (
            <div title={venue.name}
                key={venue.id}
                onClick={(): void => this.setActiveVenue(venue)}
                className={`clickable dropdown-item ${isActive ? 'active-venue' : ''}`}>
                <h3 className="font-weight-bolder mb-1 mt-2">{venue.name}</h3>
                <div className="dropdown-subscription-container mb-2 d-flex">
                    {hasCleaning && <div className="subscription-element">
                        <img src={SubscriptionIcon} />
                        <span className="pl-img">{LanguageProvider.getTranslation("pages.cleaning.titleshort")}</span>
                    </div>}
                    {hasEnergyManagement && <div className="subscription-element">
                        <img src={SubscriptionIcon} />
                        <span className="pl-img">{LanguageProvider.getTranslation("pages.energy.titleshort")}</span>
                    </div>}
                    {hasRealEstateUtilization && <div className="subscription-element">
                        <img src={SubscriptionIcon} />
                        <span className="pl-img">{LanguageProvider.getTranslation("pages.realestateutilization.titleshort")}</span>
                    </div>}
                    {hasIndoorClimate && <div className="subscription-element">
                        <img src={SubscriptionIcon} />
                        <span className="pl-img">{LanguageProvider.getTranslation("pages.indoorclimate.titleshort")}</span>
                    </div>}
                </div>
                {!renderSeparator && <div className="dropdown-item-separator"></div>}
            </div>);
    }

    private setActiveVenue(venue: CoreSpace): void {
        if (venue.id === this.state.activeVenue?.id) {
            return;
        }

        // Make the saving a callback,
        // because venue provider events will also trigger events in this component (through setActiveVenueByExternalEvent)
        this.setState({
            activeVenue: venue
        }, () => VenueProvider.saveActiveVenue(venue));
    }

    private clearVenueFilter(): void {
        this.setState({ venueFilter: "" });
    }

    private renderNoResult(): JSX.Element {
        return <div className={`dropdown-item`}><h3 className="font-weight-bolder mb-1 mt-2">{LanguageProvider.getTranslation(translations.buttons.venue.noresults)}</h3></div>;
    }

    private renderFilter(): JSX.Element {
        return (<div title="search" key="0" className="dropdown-item nohover" onClick={(e): void => e.stopPropagation()}>
            <div className="venuefilter" onClick={(): void => this.clearVenueFilter()}>
                <input id="vanuefilterinput" className="font-weight-bolder" type="text" autoComplete="off"
                    onKeyDown={(e): void => { if (e.keyCode === 46) { this.clearVenueFilter(); } }}
                    onChange={(e): void => { this.setState({ venueFilter: e.currentTarget?.value }) }}
                    value={this.state.venueFilter}
                    placeholder={LanguageProvider.getTranslation(translations.buttons.venue.filter)}></input>
                <img className="clearfilter" src={CrossCancelIcon} onClick={(): void => setVenueFilterFocus()} alt={LanguageProvider.getTranslation(translations.buttons.venue.clear)}></img>
            </div>
            <div className="dropdown-item-separator"></div>
        </div>
        )
    }

    public render(): JSX.Element {

        const hasFilter = !this.state.loading && this.state.venues.length > 5;
        const filteredItems = this.state.venues.filter(item => { return item.name.toLowerCase().indexOf(this.state.venueFilter.toLowerCase()) > -1; });

        return (
            <div className="dropdown venue-picker" id="venue-picker">
                <div className="clickable d-flex" data-toggle="dropdown" onClick={(): void => setVenueFilterFocus()} id="venue-menu-button" aria-haspopup="true" aria-expanded="false">
                    <div className="mr-4">
                        <div className="default-text">{LanguageProvider.getTranslation(translations.buttons.venue.currentvenue)}</div>
                        <div className="font-weight-bold">{this.state.activeVenue?.name}</div>
                    </div>
                    <div className="d-flex align-items-end">
                        <img src={VenueIcon} id="venue-picker-icon" />
                    </div>
                </div>

                <div className={'dropdown-menu dropdown-menu-right p-0' + (hasFilter ? " hasfilter" : "")} aria-labelledby="venue-menu-button">
                    {
                        this.state.loading && <div className="d-flex justify-content-center p-3">
                            <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.state.loading} />
                        </div>
                    }
                    {hasFilter && this.renderFilter()}
                    {filteredItems.length > 0 && filteredItems.map((v, i) => this.renderDropdownItem(v, i === this.state.venues.length - 1))}
                    {filteredItems.length === 0 && this.renderNoResult()}
                </div>
            </div>
        );
    }
}

function setVenueFilterFocus(): void {
    setTimeout(function () { document.getElementById("vanuefilterinput")?.focus(); }, 0);
}

export default VenuePicker;