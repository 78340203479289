import * as React from "react";

import { NotificationManager } from "react-notifications";

import LanguageProvider from "@/providers/languageProvider";
import { withTranslation } from "react-i18next";
import { translations } from "@/translations/mapper";
import IWorkSpaceReservationSettingsProps from "./interfaces/IWorkSpaceReservationSettingsProps";
import IWorkSpaceReservationSettingsState from "./interfaces/IWorkSpaceReservationSettingsState";

import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import IWorkSpaceReservationSettings from "../../../../../interfaces/IWorkSpaceReservationSettings";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import FullPageLoader from "@/components/loaders/fullPageLoader";
import GeneralTeaser from "@/components/teaser/generalTeaser";
import links from "@/utils/links";
import VenueProvider from "@/providers/venueProvider";
import WorkSpaceReservationThresholds from "./components/workSpaceReservationThresholds";
import WorkspaceSettingsService from "services/workspaceSettingsService";
import WorkspaceAutoCancellationSettings from "interfaces/workspaceAutoCancellationSettings";
import RealEstateUtilizationSettingsPage from "../../realEstateUtilizationSettingsPage";

class WorkSpaceReservationSettings extends React.Component<IWorkSpaceReservationSettingsProps, IWorkSpaceReservationSettingsState> {
    private readonly workspaceSettingsService: WorkspaceSettingsService;
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: any) {
        super(props);

        const state: IWorkSpaceReservationSettingsState = {
            loading: true,
            venueWorkSpaceSettings: {
                daysInAdvance: 0,
                releaseAfterMinutes: 0,
                ignoreReleaseStart: "",
                ignoreReleaseEnd: "",
            },
            initialVenueWorkSpaceSettings: {
                daysInAdvance: 0,
                releaseAfterMinutes: 0,
                ignoreReleaseStart: "",
                ignoreReleaseEnd: "",
            },
            initialDaysInAdvanceValue: 30,
            hasReserveWorkspacesSubscription: false,
            hasAutomaticWorkspaceCancellationSubscription: false,
            hasNoShowSubscription: false,
            enableSaveButton: false,
            validTimeInput: true,
            isVenueSwapLoading: false,
        };

        this.state = state;

        this.initializeVenueAsync = this.initializeVenueAsync.bind(this);
        this.workspaceSettingsService = new WorkspaceSettingsService();
        this.finalizeSubmitAsync = this.finalizeSubmitAsync.bind(this);
        this.onWorkSpaceSettingsChange = this.onWorkSpaceSettingsChange.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.setState({
            loading: true,
        });

        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeVenueAsync();

        this.setState({
            loading: false,
        });
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenueAsync);
    }

    private async initializeVenueAsync(): Promise<void> {
        this.setState({
            isVenueSwapLoading: true,
        });

        const venue = VenueProvider.getActiveVenue();

        const settings = await this.workspaceSettingsService.getWorkSpaceReservationSettings(true);

        const hasReserveWorkspacesSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue!, ["BEFMISWorkspaces"]) : false;
        const hasNoShowSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["NoShowWorkspaces"]) : false;
        const hasAutomaticWorkspaceCancellationSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["AutomaticWorkspaceCancellation"]) : false;

        this.setState({
            initialVenueWorkSpaceSettings: settings,
            venueWorkSpaceSettings: settings,
            isVenueSwapLoading: false,
            hasReserveWorkspacesSubscription: hasReserveWorkspacesSubscription,
            hasNoShowSubscription: hasNoShowSubscription,
            hasAutomaticWorkspaceCancellationSubscription: hasAutomaticWorkspaceCancellationSubscription,
            enableSaveButton: false,
        });
    }

    private async finalizeSubmitAsync(): Promise<void> {
        if (this.state.loading) {
            return;
        }

        const workspaceAutoCancelSettings: WorkspaceAutoCancellationSettings = {
            ignoreReleaseWorkspaceTimeEnd: this.state.venueWorkSpaceSettings.ignoreReleaseEnd,
            ignoreReleaseWorkspaceTimeStart: this.state.venueWorkSpaceSettings.ignoreReleaseStart,
            isAutomaticCancellationForWorkspacesEnabled: this.state.venueWorkSpaceSettings.releaseAfterMinutes === -1 ? false : true,
            releaseWorkspaceTimeAfterInMinutes: this.state.venueWorkSpaceSettings.releaseAfterMinutes!,
        };

        const [upsertAutoCancelSettingsResult, upsertWorkspaceResult] = await Promise.all([
            this.workspaceSettingsService.upsertWorkspaceAutoCancelSettings(workspaceAutoCancelSettings),
            this.workspaceSettingsService.upsertWorkSpaceReservationSettings(this.state.venueWorkSpaceSettings),
        ]);

        const accepted =
            upsertAutoCancelSettingsResult.status === 200 && upsertWorkspaceResult.status === 204;

        if (accepted) {
            NotificationManager.success(LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.saved));
        } else {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.erroronsave));
        }

        this.setState({
            initialDaysInAdvanceValue: this.state.venueWorkSpaceSettings.daysInAdvance ?? 30,
            enableSaveButton: !accepted,
        });
    }

    private onWorkSpaceSettingsChange(settings: IWorkSpaceReservationSettings, validTimeInput?: boolean): void {
        this.setState({ venueWorkSpaceSettings: settings, enableSaveButton: true });
        if (validTimeInput) {
            this.setState({ validTimeInput: validTimeInput });
        }
    }

    public render(): JSX.Element {

        const showTresholds = this.state.hasReserveWorkspacesSubscription || this.state.hasAutomaticWorkspaceCancellationSubscription;

        return (
            <RealEstateUtilizationSettingsPage
                onSave={this.finalizeSubmitAsync}
                displaySaveButton={this.state.hasReserveWorkspacesSubscription}
                enableSaveButton={this.state.enableSaveButton}
            >
                {(this.state.loading || this.state.isVenueSwapLoading) && <FullPageLoader loading={this.state.loading} viewHeightPxReduction={240} />}
                {!this.state.loading && (
                    <>
                        <div className={`${this.state.isVenueSwapLoading ? "invisible" : ""}`}>
                            {!showTresholds && <GeneralTeaser buttonLink={links.external.beyondeyes.realestateutilization} />}
                            {showTresholds && <div className="main-content map" id="workspace-reservation-settings">
                                <WorkSpaceReservationThresholds
                                    onChange={this.onWorkSpaceSettingsChange}
                                    isVenueSwapLoading={this.state.isVenueSwapLoading}
                                    initialWorkSpaceReservationSettings={this.state.initialVenueWorkSpaceSettings}
                                    hasNoShowSubscription={this.state.hasNoShowSubscription}
                                    hasAutomaticWorkspaceCancellationSubscription={this.state.hasAutomaticWorkspaceCancellationSubscription}
                                    hasReserveWorkspacesSubscription={this.state.hasReserveWorkspacesSubscription}
                                />
                            </div>}
                        </div>
                    </>
                )}
            </RealEstateUtilizationSettingsPage>
        );
    }
}

export default withTranslation()(WorkSpaceReservationSettings);
