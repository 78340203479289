import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { withTelemetry } from '@/services/telemetryService';

import IEnergyTemperaturePageProps from "../interfaces/IEnergyTemperaturePageProps";
import IEnergyTemperaturePageState from "../interfaces/IEnergyTemperaturePageState";
import EnergyManagementPage from "../../energyManagementPage";
import PageHeader from "@/components/header/pageHeader";
import VenueProvider from "@/providers/venueProvider";

import EnergyIcon from '@/images/Energy_unselected.svg';
import GasIcon from '@/images/Gas_unselected.svg';
import ColdIcon from '@/images/Cold_unselected.svg';
import HeatIcon from '@/images/Heat_unselected.svg';
import Selectbox_on from "@/images/Selectbox_on.svg";
import Selectbox_off from "@/images/Selectbox_off.svg";

import "./energyTemperaturePage.scss";
import './energyReportStyling.scss';

import EnergyType from "../../components/enums/energyType";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import EnergyGraphHeader from '../../components/energyGraphHeader';
import { IDatePickerTimeUnit } from "@/interfaces/IDatePickerTimeUnit";
import TemperatureScatterGraph from '../../components/temperatureScatterGraph';
import EnergyService from "@/services/energyService";
import IHourlyEnergieMissieValue from "@/interfaces/energy/IHourlyEnergieMissieValue";
import ITemperatureGraphData from "../interfaces/ITemperatureGraphData";
import ColorProvider from "@/providers/colorProvider";
import translations from '@/translations/mapper';
import LanguageProvider from '@/providers/languageProvider';
import DatePickerDeluxe from '../../components/datePicker/datePickerDeluxe';
import Dictionary from "utils/dictionary";
import IHourlyTemperatureData from "interfaces/energy/IHourlyTemperatureData";

class EnergyTemperaturePage extends React.Component<IEnergyTemperaturePageProps, IEnergyTemperaturePageState>{
    private readonly graphHtmlReference: React.RefObject<HTMLDivElement>;
    private heatColdGJTokWhFactor: number = 277.778;

    // Is given by M3->MJ factor times MJ->kWh factor, same M3->MJ factor as in aggregation:
    private gasM3TokWhFactor: number = 31.486 * 0.277778;

    private viewportHeightThresholdForIncreasedGraphHeight: number = 965;

    private subscriptionValidationService: SubscriptionValidationService;

    private energyService: EnergyService;

    // Dictionary key = `{venueId}|{year}`
    private hourlyGasData: Dictionary<IHourlyEnergieMissieValue[]> = new Dictionary<IHourlyEnergieMissieValue[]>();
    private gasDataPromises: Dictionary<Promise<void>> = new Dictionary<Promise<void>>();
    private hourlyEnergyData: Dictionary<IHourlyEnergieMissieValue[]> = new Dictionary<IHourlyEnergieMissieValue[]>();
    private energyDataPromises: Dictionary<Promise<void>> = new Dictionary<Promise<void>>();
    private hourlyHeatData: Dictionary<IHourlyEnergieMissieValue[]> = new Dictionary<IHourlyEnergieMissieValue[]>();
    private heatDataPromises: Dictionary<Promise<void>> = new Dictionary<Promise<void>>();
    private hourlyColdData: Dictionary<IHourlyEnergieMissieValue[]> = new Dictionary<IHourlyEnergieMissieValue[]>();
    private coldDataPromises: Dictionary<Promise<void>> = new Dictionary<Promise<void>>();

    private temperatureDataPromises: Dictionary<Promise<void>> = new Dictionary<Promise<void>>();

    public constructor(props: IEnergyTemperaturePageProps) {
        super(props);

        this.graphHtmlReference = React.createRef();

        const activeVenue = VenueProvider.getActiveVenue();

        const state: IEnergyTemperaturePageState = {
            loading: true,
            venue: activeVenue,
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate(),
            energyTypes: [],
            hasElectricitySubscription: false,
            hasGasSubscription: false,
            hasColdSubscription: false,
            hasHeatSubscription: false,
            hourlyTemperatureData: new Dictionary<IHourlyTemperatureData[]>(),
            measuredData: [],
            openingTimeOnly: true
        };

        this.state = state;

        this.energyService = new EnergyService();

        this.toggleGasUnitTypeSelection = this.toggleGasUnitTypeSelection.bind(this);
        this.toggleEnergyUnitTypeSelection = this.toggleEnergyUnitTypeSelection.bind(this);
        this.toggleHeatUnitTypeSelection = this.toggleHeatUnitTypeSelection.bind(this);
        this.toggleColdUnitTypeSelection = this.toggleColdUnitTypeSelection.bind(this);
        this.initializeDataTypesAndData = this.initializeDataTypesAndData.bind(this);
        this.handleOpeningHoursToggle = this.handleOpeningHoursToggle.bind(this);
        this.dataPointFallsInOpeningTime = this.dataPointFallsInOpeningTime.bind(this);
        this.getGraphHeaderTitle = this.getGraphHeaderTitle.bind(this);
        this.renderDatePicker = this.renderDatePicker.bind(this);
        this.updateDatasetsFromRetrievedData = this.updateDatasetsFromRetrievedData.bind(this);
        this.getExportFileName = this.getExportFileName.bind(this);

        this.initializeVenueAsync = this.initializeVenueAsync.bind(this);
        this.onDateChange = this.onDateChange.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.on(AppEvents.LanguageChanged, this.updateDatasetsFromRetrievedData);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

        await this.initializeVenueAsync();
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.off(AppEvents.LanguageChanged, this.updateDatasetsFromRetrievedData);
    }

    private async initializeVenueAsync(): Promise<void> {
        const activeVenue = VenueProvider.getActiveVenue();

        this.setState({
            loading: true,
            venue: activeVenue
        }, this.setSubscriptionsForVenueAndInitializeData);
    }

    private setSubscriptionsForVenueAndInitializeData(): void {
        const activeVenue = VenueProvider.getActiveVenue();
        if (activeVenue) {
            const hasElectricity = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Electricity"]);
            const hasGas = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Gas"]);
            const hasHeat = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Heat"]);
            const hasCold = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Cold"]);

            this.setState({
                hasElectricitySubscription: hasElectricity,
                hasGasSubscription: hasGas,
                hasHeatSubscription: hasHeat,
                hasColdSubscription: hasCold
            }, this.initializeDataTypesAndData);
        }
    }

    private initializeDataTypesAndData(): void {
        const initialDataType = this.determineInitialDataTypes();

        this.setState({
            energyTypes: initialDataType
        }, this.initializeCompleteDataAsync);
    }

    private async initializeCompleteDataAsync(): Promise<void> {
        await Promise.all([this.initializeTemperatureDataAsync(), this.initializeAllEnergyDataAsync()]);
    }

    private async initializeTemperatureDataAsync(): Promise<void> {
        const key = this.getCurrentDictionaryKey();
        if (!this.temperatureDataPromises.containsKey(key)) {
            this.temperatureDataPromises.add(key, this.resolveTemperatureRetrievalAsync(key));
        }

        await this.temperatureDataPromises.item(key);
    }

    private async resolveTemperatureRetrievalAsync(key: string): Promise<void> {
        const temperatureData = await this.energyService.GetHourlyTemperatureDataAsync(this.state.startDate, this.state.endDate, true);

        const dictionary = this.state.hourlyTemperatureData;
        dictionary.add(key, temperatureData);
        this.setState({
            hourlyTemperatureData: dictionary
        });
    }

    private async initializeAllEnergyDataAsync(): Promise<void> {
        // Updates all (selected) datatypes to state.
        // Any not currently selected datatypes are also initialized, but non-blocking (not awaited).
        // This way they can preload in case the user selects them later on.
        // This happens after retrieval of selected datatypes (since those have priority)
        const dataRetrievalPromises: Promise<void>[] = [];

        if (this.gasSelected) {
            dataRetrievalPromises.push(this.initializeGasRetrievalAsync());
        }

        if (this.electricitySelected) {
            dataRetrievalPromises.push(this.initializeElectricityRetrievalAsync());
        }

        if (this.heatSelected) {
            dataRetrievalPromises.push(this.initializeHeatRetrievalAsync());
        }

        if (this.coldSelected) {
            dataRetrievalPromises.push(this.initializeColdRetrievalAsync());
        }

        await Promise.all(dataRetrievalPromises);
        this.updateDatasetsFromRetrievedData();
        this.preloadAllDatatypes();
    }

    private preloadAllDatatypes(): void {
        // Preloads all datatypes (including selected types, but this is no issue since the results are cached in private field anyway).
        // Should never be called blocking (without awaiting) since we want preloading to happen in background, so is typed as void.
        if (this.state.hasGasSubscription) {
            this.initializeGasRetrievalAsync();
        }

        if (this.state.hasElectricitySubscription) {
            this.initializeElectricityRetrievalAsync();
        }

        if (this.state.hasHeatSubscription) {
            this.initializeHeatRetrievalAsync();
        }

        if (this.state.hasColdSubscription) {
            this.initializeColdRetrievalAsync();
        }

    }

    private async initializeGasRetrievalAsync(): Promise<void> {
        const key = this.getCurrentDictionaryKey();
        if (!this.gasDataPromises.containsKey(key)) {
            this.gasDataPromises.add(key, this.resolveGasRetrievalAsync(key));
        }

        await this.gasDataPromises.item(key);
    }

    private async resolveGasRetrievalAsync(key: string): Promise<void> {
        const rawGasData = await this.energyService.GetHourlyGasVenueUsageDataAsync(this.state.startDate, this.state.endDate, undefined, true);
        this.hourlyGasData.add(key, rawGasData.map(d => {
            d.actualUsage = d.actualUsage !== null ? d.actualUsage * this.gasM3TokWhFactor : null;
            return d;
        }));
    }

    private async initializeElectricityRetrievalAsync(): Promise<void> {
        const key = this.getCurrentDictionaryKey();
        if (!this.energyDataPromises.containsKey(key)) {
            this.energyDataPromises.add(key, this.resolveElectricityRetrievalAsync(key));
        }

        await this.energyDataPromises.item(key);
    }

    private async resolveElectricityRetrievalAsync(key: string): Promise<void> {
        const rawEnergyData = await this.energyService.GetHourlyElectricVenueUsageDataAsync(this.state.startDate, this.state.endDate, true);
        this.hourlyEnergyData.add(key, rawEnergyData);
    }

    private async initializeHeatRetrievalAsync(): Promise<void> {
        const key = this.getCurrentDictionaryKey();
        if (!this.heatDataPromises.containsKey(key)) {
            this.heatDataPromises.add(key, this.resolveHeatRetrievalAsync(key));
        }

        await this.heatDataPromises.item(key);
    }

    private async resolveHeatRetrievalAsync(key: string): Promise<void> {
        const rawHeatData = await this.energyService.GetHourlyHeatVenueUsageDataAsync(this.state.startDate, this.state.endDate, true);

        this.hourlyHeatData.add(key, rawHeatData.map(d => {
            d.actualUsage = d.actualUsage !== null ? d.actualUsage * this.heatColdGJTokWhFactor : null;
            return d;
        }));
    }

    private async initializeColdRetrievalAsync(): Promise<void> {
        const key = this.getCurrentDictionaryKey();
        if (!this.coldDataPromises.containsKey(key)) {
            this.coldDataPromises.add(key, this.resolveColdRetrievalAsync(key));
        }

        await this.coldDataPromises.item(key);
    }

    private async resolveColdRetrievalAsync(key: string): Promise<void> {
        const rawColdData = await this.energyService.GetHourlyColdVenueUsageDataAsync(this.state.startDate, this.state.endDate, true);

        this.hourlyColdData.add(key, rawColdData.map(d => {
            d.actualUsage = d.actualUsage !== null ? d.actualUsage * this.heatColdGJTokWhFactor : null;
            return d;
        }));
    }

    private updateDatasetsFromRetrievedData(): void {
        // Updates state using present hourly data, does not actually retrieve data!
        this.setState({
            measuredData: []
        });
        const dataSets: ITemperatureGraphData[] = [];
        const key = this.getCurrentDictionaryKey();

        if (this.electricitySelected) {
            dataSets.push({
                data: (this.hourlyEnergyData.item(key) ?? []).filter(this.dataPointFallsInOpeningTime),
                label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.electricity),
                color: ColorProvider.getTemperatureGraphColors(EnergyType.Electricity, false),
                type: EnergyType.Electricity
            });

            if (!this.state.openingTimeOnly) {
                dataSets.push({
                    data: (this.hourlyEnergyData.item(key) ?? []).filter(d => !this.dataPointFallsInOpeningTime(d)),
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.electricityoutsideopeninghours),
                    color: ColorProvider.getTemperatureGraphColors(EnergyType.Electricity, true),
                    type: EnergyType.Electricity
                });
            }
        }

        if (this.gasSelected) {
            dataSets.push({
                data: (this.hourlyGasData.item(key) ?? []).filter(this.dataPointFallsInOpeningTime),
                label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.gas),
                color: ColorProvider.getTemperatureGraphColors(EnergyType.Gas, false),
                type: EnergyType.Gas
            });

            if (!this.state.openingTimeOnly) {
                dataSets.push({
                    data: (this.hourlyGasData.item(key) ?? []).filter(d => !this.dataPointFallsInOpeningTime(d)),
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.gasoutsideopeninghours),
                    color: ColorProvider.getTemperatureGraphColors(EnergyType.Gas, true),
                    type: EnergyType.Gas
                });
            }
        }

        if (this.heatSelected) {
            dataSets.push({
                data: (this.hourlyHeatData.item(key) ?? []).filter(this.dataPointFallsInOpeningTime),
                label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.heat),
                color: ColorProvider.getTemperatureGraphColors(EnergyType.Heat, false),
                type: EnergyType.Heat
            });

            if (!this.state.openingTimeOnly) {
                dataSets.push({
                    data: (this.hourlyHeatData.item(key) ?? []).filter(d => !this.dataPointFallsInOpeningTime(d)),
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.heatoutsideopeninghours),
                    color: ColorProvider.getTemperatureGraphColors(EnergyType.Heat, true),
                    type: EnergyType.Heat
                });
            }
        }

        if (this.coldSelected) {
            dataSets.push({
                data: (this.hourlyColdData.item(key) ?? []).filter(this.dataPointFallsInOpeningTime),
                label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.cold),
                color: ColorProvider.getTemperatureGraphColors(EnergyType.Cold, false),
                type: EnergyType.Cold
            });

            if (!this.state.openingTimeOnly) {
                dataSets.push({
                    data: (this.hourlyColdData.item(key) ?? []).filter(d => !this.dataPointFallsInOpeningTime(d)),
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.coldoutsideopeninghours),
                    color: ColorProvider.getTemperatureGraphColors(EnergyType.Cold, true),
                    type: EnergyType.Cold
                });
            }
        }

        if (this.state.openingTimeOnly) {
            for (const dataSet of dataSets) {
                dataSet.data = dataSet.data.filter(this.dataPointFallsInOpeningTime);
            }
        }

        this.setState({
            measuredData: dataSets,
            loading: false
        });
    }

    private dataPointFallsInOpeningTime(datapoint: IHourlyEnergieMissieValue): boolean {
        if (!this.state.venue) {
            return true;
        }

        const openingHour = this.state.venue.openingHour;
        const closingHour = this.state.venue.closingHour === 0 ? 24 : this.state.venue.closingHour;
        const date = new Date(datapoint.localYear, datapoint.localMonth - 1, datapoint.localDay);

        const inOpeningHours = datapoint.localHour >= openingHour && datapoint.localHour < closingHour;

        // openDays is monday indexed, getDay is sunday indexed.
        const onOpenDay = this.state.venue.openDays[(date.getDay() + 6) % 7] === '1';

        return inOpeningHours && onOpenDay;
    }

    private get gasSelected(): boolean {
        return this.state.energyTypes.includes(EnergyType.Gas);
    }

    private get electricitySelected(): boolean {
        return this.state.energyTypes.includes(EnergyType.Electricity);
    }

    private get heatSelected(): boolean {
        return this.state.energyTypes.includes(EnergyType.Heat);
    }

    private get coldSelected(): boolean {
        return this.state.energyTypes.includes(EnergyType.Cold);
    }

    private async toggleGasUnitTypeSelection(): Promise<void> {
        this.toggleUnitTypeSelection(EnergyType.Gas);
    }

    private async toggleEnergyUnitTypeSelection(): Promise<void> {
        this.toggleUnitTypeSelection(EnergyType.Electricity);
    }

    private async toggleHeatUnitTypeSelection(): Promise<void> {
        this.toggleUnitTypeSelection(EnergyType.Heat);
    }

    private async toggleColdUnitTypeSelection(): Promise<void> {
        this.toggleUnitTypeSelection(EnergyType.Cold);
    }

    private async toggleUnitTypeSelection(type: EnergyType): Promise<void> {
        let newTypes = this.state.energyTypes;
        let toggledOn = false;
        if (newTypes.includes(type)) {
            newTypes = newTypes.filter(t => t !== type);
        }
        else {
            newTypes.push(type);
            toggledOn = true;
        }

        newTypes = newTypes.sort((a, b) => a - b);

        this.setState({
            energyTypes: newTypes
        }, () => this.updateDataForUnitTypeToggle(type, toggledOn));
    }

    private async updateDataForUnitTypeToggle(type: EnergyType, toggledOn: boolean): Promise<void> {
        // Retrieves data for a single type (if needed) and then updates state.
        // Seperate method from the initializeAllEnergyDataAsync method, since this means
        // we don't need to re-retrieve all datatypes on any toggle.

        this.setState({
            loading: true,
            measuredData: []
        });

        if (toggledOn) {
            if (type === EnergyType.Gas) {
                await this.initializeGasRetrievalAsync();
            }
            else if (type === EnergyType.Electricity) {
                await this.initializeElectricityRetrievalAsync();
            }
            else if (type === EnergyType.Heat) {
                await this.initializeHeatRetrievalAsync();
            }
            else if (type === EnergyType.Cold) {
                await this.initializeColdRetrievalAsync();
            }
        }

        this.updateDatasetsFromRetrievedData();
    }

    private determineInitialDataTypes(): EnergyType[] {
        if (this.state.hasElectricitySubscription) {
            return [EnergyType.Electricity];
        }

        if (this.state.hasGasSubscription) {
            return [EnergyType.Gas];
        }

        if (this.state.hasHeatSubscription) {
            return [EnergyType.Heat];
        }

        return [EnergyType.Cold];
    }

    private onDateChange(timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date, triggerDataInitalization: boolean = true): void {
        this.setState({
            startDate: startDate,
            endDate: endDate,
            measuredData: [],
            loading: true
        }, triggerDataInitalization ? this.initializeCompleteDataAsync : undefined);
    }

    private handleOpeningHoursToggle(): void {
        this.setState({
            openingTimeOnly: !this.state.openingTimeOnly
        }, this.updateDatasetsFromRetrievedData);
    }

    private getCurrentDictionaryKey(): string {
        return `${this.state.venue?.id}|${this.state.startDate.getFullYear()}`;
    }

    private getGraphHeaderTitle(): string {
        const baseTitle = LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.graphtitle);
        const title = `${baseTitle} - ${this.state.startDate.getFullYear()}`;

        return title;
    }

    private renderDatePicker(): JSX.Element {
        return (
            <>
                <DatePickerDeluxe
                    defaultDateUnit={'year'}
                    onDateChange={this.onDateChange}
                    newStartDate={this.state.startDate}
                    newEndDate={this.state.endDate}
                    compactView={true}
                    newTimeUnitType={'year'}
                    disableDayPicker={true}
                    disableWeekPicker={true}
                    disableMonthPicker={true}
                    openByDefaultOnPreviousWeek={true}
                    draggableOffset={{ x: 50, y: -220 }}
                    minimalisticCompactButton={true}
                />
                <div
                    className="checkbox-container opening-time-toggle clickable mr-5"
                    onClick={(): void => this.handleOpeningHoursToggle()}>
                    <img src={this.state.openingTimeOnly ? Selectbox_on : Selectbox_off}
                        className="ml-2 checkbox-img" />
                    <label className="form-check-label clickable ml-3" htmlFor="flexCheckChecked">
                        {LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.openinghoursonly)}
                    </label>
                </div>
            </>
        );
    }

    private getExportFileName(): string {
        return `${this.state.venue && this.state.venue.name}-${this.getGraphHeaderTitle()}`.replaceAll(' ', '_').replaceAll('.', '_');
    }

    public render(): JSX.Element {
        const key = this.getCurrentDictionaryKey();

        return (
            <EnergyManagementPage>
                <PageHeader pageName="energymanagement-reports" />
                <div id="energy-temperature-page" className="main-content" ref={this.graphHtmlReference}>
                    <div className="main-content-header">
                        <div className="row input-selection">
                            <div className="col-sm-8">
                                <h1>
                                    {this.state.venue && this.state.venue.name}
                                </h1>
                            </div>
                            <div className="col-sm-4 d-flex png-hidden">
                                <div className="ml-auto d-flex">
                                    {this.state.hasElectricitySubscription && <div>
                                        <div
                                            className="checkbox-container datatype-toggle clickable mr-4"
                                            onClick={this.toggleEnergyUnitTypeSelection}>
                                            <img src={EnergyIcon} />
                                            <img src={this.electricitySelected ? Selectbox_on : Selectbox_off}
                                                className="ml-2 checkbox-img" />
                                        </div>
                                    </div>}
                                    {this.state.hasGasSubscription && <div>
                                        <div
                                            className="checkbox-container datatype-toggle clickable mr-4"
                                            onClick={this.toggleGasUnitTypeSelection}>
                                            <img src={GasIcon} />
                                            <img src={this.gasSelected ? Selectbox_on : Selectbox_off}
                                                className="ml-2 checkbox-img" />
                                        </div>
                                    </div>}
                                    {this.state.hasHeatSubscription && <div>
                                        <div
                                            className="checkbox-container datatype-toggle clickable mr-4"
                                            onClick={this.toggleHeatUnitTypeSelection}>
                                            <img src={HeatIcon} />
                                            <img src={this.heatSelected ? Selectbox_on : Selectbox_off}
                                                className="ml-2 checkbox-img" />
                                        </div>
                                    </div>}
                                    {this.state.hasColdSubscription && <div>
                                        <div
                                            className="checkbox-container datatype-toggle clickable"
                                            onClick={this.toggleColdUnitTypeSelection}>
                                            <img src={ColdIcon} />
                                            <img src={this.coldSelected ? Selectbox_on : Selectbox_off}
                                                className="ml-2" />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <EnergyGraphHeader
                                    selectedTimeUnit={'year'}
                                    startDate={this.state.startDate}
                                    onDateChange={this.onDateChange}
                                    title={this.getGraphHeaderTitle()}
                                    extraRenderAfterTitle={this.renderDatePicker}
                                    tooltipContent={LanguageProvider.getTranslation(translations.tooltips.energy.temperaturegraph.titletooltip)}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <TemperatureScatterGraph
                                    width={20}
                                    year={this.state.startDate.getFullYear()}
                                    height={window.innerHeight > this.viewportHeightThresholdForIncreasedGraphHeight ? 7 : 6}
                                    hourlyTemperatureData={this.state.hourlyTemperatureData.item(key) ?? []}
                                    measuredData={this.state.measuredData}
                                    showLegend={true}
                                    loading={this.state.loading}
                                    exportOptions={{
                                        fileName: this.getExportFileName()
                                    }}
                                    graphHtmlReference={this.graphHtmlReference} />
                            </div>
                        </div>
                    </div>
                </div>
            </EnergyManagementPage>
        );
    }
}

export default withTranslation()(withTelemetry(EnergyTemperaturePage, "EnergyTemperaturePage"));