import moment from 'moment';
import * as React from 'react';

import EnergyContractProgress from '@/models/energy/energyContractProgress';
import IContractProgressBarProps from './interfaces/IContractProgressBarProps';
import IContractProgressBarState from './interfaces/IContractProgressBarState';
import YearProgress from './yearProgress';

export default class ContractProgressBar extends React.Component<IContractProgressBarProps, IContractProgressBarState> {

    public constructor(props: IContractProgressBarProps) {
        super(props);
        const state: IContractProgressBarState = {
            isHovering: false
        };

        this.state = state;
    }

    public render(): JSX.Element {
        const progressRender = new ProgressRender(this.props.progress);

        return (
            <span>
                <div className='contract-progress'>
                    {progressRender.renderYearProgress()}
                </div>
                <div className={`contract-end ${progressRender.contractOver ? progressRender.lastYearBelowTarget ? "below-target" : "contract-over" : ""}`} />
            </span>
        );
    }
}

class ProgressRender {

    public constructor(private progress: EnergyContractProgress) {
    }

    public get contractOver(): boolean {
        return this.progress.monthsLeft + this.progress.yearsLeft + this.progress.daysLeft <= 0;
    }

    public get lastYearBelowTarget(): boolean | undefined {
        const nrOfYears = this.progress.subscriptionDetails.yearlySubscriptionDetails.length;
        const lastYearDetails = this.progress.subscriptionDetails.yearlySubscriptionDetails[nrOfYears - 1];

        return (this.progress.savingsGoalPercentage != null)
            && (lastYearDetails.totalSavingsPercentage != null)
            && this.progress.savingsGoalPercentage > lastYearDetails.totalSavingsPercentage;
    }

    public renderYearProgress(): JSX.Element[] {
        const nrOfDays = moment(this.progress.subscriptionDetails.endDate).diff(moment(this.progress.subscriptionDetails.startDate), 'days', true);
        const dayWidth = 100 / nrOfDays;

        const yearProgress = this.progress.subscriptionDetails.yearlySubscriptionDetails.map((sd, i) => {
            const endOfYear = moment.min(moment(sd.endDate), moment(this.progress.subscriptionDetails.endDate));
            const nrOfDaysInYear = endOfYear.diff(moment(sd.startDate), 'days', true);
            const yearWidth = nrOfDaysInYear * dayWidth;

            return <YearProgress
                yearlySubscriptionDetail={sd}
                iterator={i}
                savingsGoalPercentage={this.progress.savingsGoalPercentage ? this.progress.savingsGoalPercentage : 0}
                endMoment={endOfYear}
                yearWidth={yearWidth}
                key={i}
            />;
        });

        return yearProgress;
    }
}