import PageHeaderInfo from "./pageHeaderInfo";

export default class PageHeaderManager {
    private static tabs: PageHeaderInfo[] = [];

    public static add(headers: PageHeaderInfo): void {
        this.tabs.push(headers);
    }

    public static getHeadersForPage(pageName: string): PageHeaderInfo | null {
        const result = this.tabs.find(t => t.pageName.toLowerCase() === pageName.toLowerCase());
        return result || null;
    }
}