import { ISpaceOnMap, ILabel, ISpaceStyle, IStyle, IStyler } from "@beyondeyes/shared";
import CoreSpace from "models/coreSpace";
import MultiAssetMotionValue from "models/multiAssetMotionValue";
import ColorUtils from "utils/colorUtils";
import Colors from "@/styles/colors";
import BaseStyler from "./baseStyler";

class HeatmapStyler extends BaseStyler implements IStyler {
	private allAssetData: MultiAssetMotionValue[][];
	private exceptionLowerbound: number;
	private exceptionUpperbound: number;
	private onlyShowExceptions: boolean;

	public constructor() {
		super();
		this.allAssetData = [];
	}

	public getStyle(spaceOnMap: ISpaceOnMap): IStyle | undefined {
		const space = spaceOnMap.beSpace as CoreSpace;

		if (space == null) {
			return undefined;
		}

		const placeStyle = this.getPlaceStyle(space);
		const placeLabel = placeStyle.markerDisplay ? this.getSpaceLabel(space) : undefined;

		return {
			placeStyle: placeStyle,
			placeLabel: placeLabel,
		};
	}

	public SetAllAssetData(allAssetData: MultiAssetMotionValue[][]): void {
		this.allAssetData = allAssetData;
	}

	public SetExceptionBounds(lowerBound: number, upperBound: number): void {
		this.exceptionLowerbound = lowerBound;
		this.exceptionUpperbound = upperBound;
	}

	public SetOnlyShowExceptions(onlyShowExceptions: boolean): void {
		this.onlyShowExceptions = onlyShowExceptions;
	}

	private getSpaceLabel(space: CoreSpace): ILabel {
		return {
			size: this.defaultLabelSize,
			text: space.name,
		};
	}

	private getPlaceStyle(space: CoreSpace): ISpaceStyle {
		return space.hasMotionDevices ? this.getHeatColoredStyling(space) : this.getDefaultGreyStyling();
	}

	private getHeatColoredStyling(space: CoreSpace): ISpaceStyle {
		const average = Math.round(this.calculateAverageOccupancyForSpace(space!));

		if (average < 0) {
			// Handle assets with no known data:
			return this.getDefaultGreyStyling();
		}

		const occupiedColor = ColorUtils.percentageToGradientColor(average);
		const isException = average < this.exceptionLowerbound || average > this.exceptionUpperbound;

		if (this.onlyShowExceptions && !isException) {
			return this.getDefaultGreyStyling();
		} else {
			return {
				fillColor: occupiedColor,
				fillOpacity: 0.95,
				markerDisplay: isException,
			};
		}
	}

	private calculateAverageOccupancyForSpace(space: CoreSpace): number {
		const assetData = this.allAssetData.find((ar) => ar.find((v) => v.partitionKey === space.id) !== undefined);

		if (assetData === undefined) {
			return -1;
		} else {
			return assetData.reduce((p, c) => p + c.percentage, 0) / assetData.length;
		}
	}

	private getDefaultGreyStyling(): ISpaceStyle {
		return {
			fillColor: Colors.black,
		};
	}
}

export default HeatmapStyler;
