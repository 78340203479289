import * as React from 'react';
import { withTranslation } from "react-i18next";
import moment from 'moment';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';

import IDatePickerDeluxeProps from "./interfaces/IDatePickerDeluxeProps";
import IDatePickerDeluxeState from "./interfaces/IDatePickerDeluxeState";
import { IDatePickerTimeUnit } from '@/interfaces/IDatePickerTimeUnit';
import TimeUnitSelectButton from './timeUnitSelectButton';
import YearDatePickerDeluxe from './yearDatePickerDeluxe';
import MonthDatePickerDeluxe from './monthDatePickerDeluxe';
import DayDatePickerDeluxe from './dayDatePickerDeluxe';
import LanguageProvider from '@/providers/languageProvider';

import "./datePickerDeluxe.scss";
import DateIcon from '@/images/Datepick_icon_blue.svg';

class DatePickerDeluxe extends React.Component<IDatePickerDeluxeProps, IDatePickerDeluxeState> {
    public constructor(props: IDatePickerDeluxeProps) {
        super(props);

        const state: IDatePickerDeluxeState = {
            displayPicker: false,
            selectedTimeUnit: this.props.defaultDateUnit,
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate()
        };

        this.state = state;

        this.togglePicker = this.togglePicker.bind(this);
        this.changeTimeUnit = this.changeTimeUnit.bind(this);
        this.setSelectedDate = this.setSelectedDate.bind(this);
        this.renderDateRange = this.renderDateRange.bind(this);
        this.renderDisplayBox = this.renderDisplayBox.bind(this);
    }

    public componentDidUpdate(previousProps: IDatePickerDeluxeProps, previousState: IDatePickerDeluxeState): void {
        if (this.props.newTimeUnitType !== undefined && this.props.newTimeUnitType !== previousProps.newTimeUnitType) {
            this.setState({
                selectedTimeUnit: this.props.newTimeUnitType
            });
        }

        if (this.props.newStartDate !== undefined && this.props.newEndDate !== undefined && previousProps.newStartDate !== this.props.newStartDate) {
            this.setState({
                startDate: this.props.newStartDate,
                endDate: this.props.newEndDate
            });
        }
    }

    private togglePicker(): void {
        this.setState({
            displayPicker: !this.state.displayPicker
        });
    }

    private changeTimeUnit(timeUnit: IDatePickerTimeUnit): void {
        this.setState({
            selectedTimeUnit: timeUnit
        });
    }

    private get daySelected(): boolean {
        return this.state.selectedTimeUnit === "day" && !this.props.disableDayPicker;
    }

    private get weekSelected(): boolean {
        return this.state.selectedTimeUnit === "week" && !this.props.disableWeekPicker;
    }

    private get monthSelected(): boolean {
        return this.state.selectedTimeUnit === "month" && !this.props.disableMonthPicker;
    }

    private get yearSelected(): boolean {
        return this.state.selectedTimeUnit === "year" && !this.props.disableYearPicker;
    }

    private renderSelectButtons(): JSX.Element {
        const options: IDatePickerTimeUnit[] = [];

        if (!this.props.disableDayPicker) {
            options.push("day");
        }

        if (!this.props.disableWeekPicker) {
            options.push("week");
        }

        if (!this.props.disableMonthPicker) {
            options.push("month");
        }

        if (!this.props.disableYearPicker) {
            options.push("year");
        }

        if (options.length <= 1) {
            return (<span />);
        }

        const tiles = options.map((x, i) => (
            <TimeUnitSelectButton
                key={x}
                timeUnit={x}
                displayText={LanguageProvider.getTranslation(`dates.${x}`)}
                activeTimeUnit={this.state.selectedTimeUnit}
                selected={this.state.selectedTimeUnit === x}
                onSelect={this.changeTimeUnit}
                lastElement={i === options.length - 1} />
        ));

        return (<div className="date-picker-header pb-1 text-center">{tiles}</div>);
    }

    private setSelectedDate(timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date): void {
        this.setState({
            startDate: startDate,
            endDate: endDate,
            selectedTimeUnit: timeUnit,
        });

        if (this.props.onDateChange) {
            this.props.onDateChange(timeUnit, startDate, endDate);
        }
    }

    private renderDateRange(): JSX.Element {
        const left = this.state.startDate.toLocaleDateString().replace(/-/g, "/");
        const right = this.daySelected ? "00:00 - 23:59" : this.state.endDate.toLocaleDateString().replace(/-/g, "/");

        return (
            <div className="date-range">
                {left} {this.daySelected ? '' : ' - '} {right}
            </div>
        );
    }

    private renderDisplayBox(): JSX.Element {
        if (this.props.compactView) {
            return (
                <div className={`date-picker-input clickable ${this.props.minimalisticCompactButton ? 'minimal-compact' : 'compact'}`} onClick={this.togglePicker}>
                    <div className="date-icon">
                        <img src={DateIcon} />
                    </div>
                </div>);
        }
        else {
            return (
                <div className="date-picker-input clickable full w-100" onClick={this.togglePicker}>
                    {this.renderDateRange()}
                    <div className="date-icon text-center">
                        <img src={DateIcon} />
                    </div>
                </div>
            );
        }
    }

    public render(): JSX.Element {
        return (
            <div className="date-picker-deluxe png-hidden" >
                {this.state.displayPicker && <div className="date-dismiss-toggle" onClick={this.togglePicker} />}

                {this.renderDisplayBox()}

                <Draggable positionOffset={this.props.draggableOffset} disabled={isMobile}>
                    {<div className={"date-picker-container ml-5 pl-2 pt-2 pr-2 " + (this.state.displayPicker ? '' : 'd-none')}>
                        <div className="h-100 d-flex flex-column">
                            {this.renderSelectButtons()}
                            <div className="date-picker-body mb-2">
                                <DayDatePickerDeluxe
                                    isActive={this.state.displayPicker && this.daySelected}
                                    selectionType="day"
                                    onDateChange={this.setSelectedDate}
                                    newStartDate={this.props.newStartDate} />
                                <DayDatePickerDeluxe
                                    isActive={this.state.displayPicker && this.weekSelected}
                                    selectionType="week"
                                    onDateChange={this.setSelectedDate}
                                    newStartDate={this.props.newStartDate}
                                    openOnLastWeek={this.props.openByDefaultOnPreviousWeek} />
                                <MonthDatePickerDeluxe
                                    isActive={this.state.displayPicker && this.monthSelected}
                                    onDateChange={this.setSelectedDate}
                                    newStartDate={this.props.newStartDate} />
                                <YearDatePickerDeluxe
                                    isActive={this.state.displayPicker && this.yearSelected}
                                    onDateChange={this.setSelectedDate}
                                    newStartDate={this.props.newStartDate} />
                            </div>
                        </div>
                    </div>}
                </Draggable>
            </div>
        );
    }
}

export default withTranslation()(DatePickerDeluxe);