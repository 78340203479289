import "./selectBoxUtils.scss";
import ISelectValueWithSeparator from "interfaces/ISelectValueWithSeparator";
import Colors from "@/styles/colors";

export default class SelectBoxUtils {
    public static getSelectStylesWithDefaultOption(height?: number): any {
        const stylesWithControl = SelectBoxUtils.getDefaultSelectStyles(height);
        stylesWithControl.option = (provided: any, state: any): any => {
            if (state.data.isDefault || state.data.displaySeparator) {
                return {
                    ...provided,
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingBottom: '0px',
                    marginBottom: '0px',
                };
            }

            return {
                ...provided,
            };
        };
        stylesWithControl.clearIndicator = (provided: any, state: any): any => ({
            ...provided,
            display: "none"
        });

        return stylesWithControl;
    }

    public static getDefaultOptionFormatter(): any {
        const customDefaultOptionFormatter = ({ label, isDefault, displaySeparator }: ISelectValueWithSeparator, { context }: { context: "menu" | "value" }): JSX.Element => {
            if (context === "menu") {
                if (isDefault || displaySeparator) {
                    return (
                        <>
                            <div className="default-option-label">
                                {label}
                            </div>
                            <div className="default-option-separator-top" />
                            <div className="default-option-separator-bot" />
                        </>
                    );
                }
            }

            return (
                <div>
                    {label}
                </div>);
        };

        return customDefaultOptionFormatter;
    }

    public static getDefaultSelectStyles(height?: number): any {
        const styles = SelectBoxUtils.defaultSelectStyles;
        const control = SelectBoxUtils.getControlProps(height);

        styles.control = control;

        return { ...styles, control: control };
    }

    public static getDefaultSelectStylesWithInputHeight(height: number, inputHeight: number): any {
        const styles = SelectBoxUtils.getDefaultSelectStyles(height);
        styles.input = (provided: any, state: any): any => ({
            ...provided,
            color: Colors.black,
            'maxHeight': inputHeight
        });
        return styles;
    }

    public static getDefaultSelectColoredStyles(height?: number): any {
        const styles = { ...SelectBoxUtils.defaultSelectStyles };
        const control = SelectBoxUtils.getControlProps(height);
        const multiValueLabel = SelectBoxUtils.getMultiValueLabelProps();
        styles.control = control;
        styles.multiValueLabel = multiValueLabel;

        return { ...styles, control: control };
    }

    private static appendHeightToControl(defaultPropsMethod: (provided: any) => any, height?: number): any {
        if (height) {
            return (provided: any, state: any): any => {
                const result = defaultPropsMethod(provided);
                return {
                    ...result,
                    'minHeight': height
                };
            };
        }
        else {
            return (provided: any, state: any): any => defaultPropsMethod(provided);
        }
    }

    private static getControlProps(height?: number): any {
        const defaultControlProps = (provided: any): any => ({
            ...provided,
            color: Colors.black,
            boxShadow: 'none',
            border: `1px solid ${Colors.grey}`,
            'lineHeight': '16px',
            cursor: 'pointer',
            'borderRadius': 0,
            '&:hover': {
                border: `1px solid ${Colors.grey}`
            },
        });

        return SelectBoxUtils.appendHeightToControl(defaultControlProps, height);
    }

    private static getMultiValueLabelProps(): any {
        const multiValueLabel = (provided: any, state: any): any => ({
            ...provided,
            color: state.data.textColor,
            'backgroundColor': state.data.color,
            'fontSize': 12,
            'borderRadius': 0,
            'paddingLeft': 15,
            'paddingRight': 15,
            'paddingTop': 0,
            'paddingBottom': 0
        });

        return multiValueLabel;
    }

    private static defaultSelectStyles: any = {
        clearIndicator: (provided: any, state: any): any => ({
            ...provided,
            color: 'red',
            '&:hover': {
                color: 'red',
            },
            'padding': '0px'
        }),
        container: (provided: any, state: any): any => ({
            ...provided,
        }),
        dropdownIndicator: (provided: any, state: any): any => ({
            ...provided,
            color: Colors.royal_blue,
            cursor: 'pointer',
            '&:hover': {
                color: Colors.royal_blue,
            },
            padding: "0px 8px 0px 0px"
        }),
        group: (provided: any, state: any): any => ({
            ...provided,
        }),
        groupHeading: (provided: any, state: any): any => ({
            ...provided,
        }),
        indicatorsContainer: (provided: any, state: any): any => ({
            ...provided
        }),
        indicatorSeparator: (provided: any, state: any): any => ({
            ...provided,
            'backgroundColor': Colors.white,
        }),
        input: (provided: any, state: any): any => ({
            ...provided,
            color: Colors.black
        }),
        loadingIndicator: (provided: any, state: any): any => ({
            ...provided,
        }),
        loadingMessage: (provided: any, state: any): any => ({
            ...provided,
        }),
        menu: (provided: any, state: any): any => ({
            ...provided,
            'zIndex': '100',
            'borderRadius': '0px',
        }),
        menuList: (provided: any, state: any): any => ({
            ...provided,
            'outlineStyle': 'solid',
            'outlineColor': Colors.light_grey,
        }),
        menuPortal: (provided: any, state: any): any => ({
            ...provided,
        }),
        multiValue: (provided: any, state: any): any => ({
            ...provided,
        }),
        multiValueLabel: (provided: any, state: any): any => ({
            ...provided,
            color: Colors.black,
            'backgroundColor': Colors.white,
            'fontSize': 12,
            'borderRadius': 0,
            'paddingLeft': 15,
            'paddingRight': 15,
            'paddingTop': 0,
            'paddingBottom': 0
        }),
        multiValueRemove: (provided: any, state: any): any => ({
            ...provided,
            color: 'red',
            'borderRadius': 0,
            'backgroundColor': Colors.white,
            '&:hover': {
                color: 'red',
                'backgroundColor': Colors.white
            },
        }),
        noOptionsMessage: (provided: any, state: any): any => ({
            ...provided,
            color: Colors.black,
        }),
        option: (provided: any, state: any): any => ({
            ...provided,
        }),
        placeholder: (provided: any, state: any): any => ({
            ...provided,
            color: Colors.black,
        }),
        singleValue: (provided: any, state: any): any => ({
            ...provided,
            color: Colors.black,
        }),
        valueContainer: (provided: any, state: any): any => ({
            ...provided,
        }),
    };
}