import * as React from 'react';
import { withTranslation } from "react-i18next";

import "./pageHeader.css";

import IPageHeaderProps from './interfaces/IPageHeaderProps';
import IPageHeaderState from './interfaces/IPageHeaderState';
import IPageHeaderItem from '../../interfaces/IPageHeaderItem';
import PageHeaderManager from '../../models/pageHeaderManager';
import LanguageProvider from '../../providers/languageProvider';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import VenueProvider from '../../providers/venueProvider';
import SubscriptionValidationService from '../../services/subscriptionValidationService';

import SaveButton from "../../images/save-icon-white.svg";
import translations from '../../translations/mapper';
import Item from "./item";

class PageHeader extends React.Component<IPageHeaderProps, IPageHeaderState> {
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: IPageHeaderProps) {
        super(props);

        const pageHeaderInfo = PageHeaderManager.getHeadersForPage(this.props.pageName);
        let activeTab: IPageHeaderItem;
        if (pageHeaderInfo) {
            // to determine the current active tab we need to filter/clone the array and then match on url. The item with the longest relative URL is the closest match
            activeTab = pageHeaderInfo.tabs.filter((i) => window.location.pathname.startsWith(i.relativeUrl)).sort((a, b) => b.relativeUrl.length - a.relativeUrl.length)[0];
            if (!activeTab) {
                console.error(`No tab found for path '${window.location.pathname}'. Please check pageHeader configuration in calling component.`);
            }
        }
        else {
            console.error(`No page header items found for page '${this.props.pageName}'`);
            return;
        }

        this.state = {
            // Start initial tabs with all tabs that are independent of venue subscriptions (since we know those will be shown for sure).
            tabs: pageHeaderInfo ? pageHeaderInfo.tabs.filter(t => t.onlyShowForVenuesWithSubscriptionTypes === undefined) : [],
            activeTab
        };

        this.toggleTab = this.toggleTab.bind(this);
        this.updateTabsForVenueAsync = this.updateTabsForVenueAsync.bind(this);
        this.onSave = this.onSave.bind(this);
        this.checkOnPageExit = this.checkOnPageExit.bind(this);
        this.pageHasUnsavedChanges = this.pageHasUnsavedChanges.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.updateTabsForVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        window.onbeforeunload = this.checkOnPageExit;

        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.updateTabsForVenueAsync();
    }

    public async componentWillUnmount(): Promise<void> {
        this.removeBeforeUnloadEvents();

        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.updateTabsForVenueAsync);
    }

    public async updateTabsForVenueAsync(): Promise<void> {
        const activeVenue = VenueProvider.getActiveVenue();
        const pageHeaderInfo = PageHeaderManager.getHeadersForPage(this.props.pageName);

        if (activeVenue === undefined) {
            throw new Error("Error getting venue settings: No venue found in venueprovider!");
        }

        // An active tab should always be displayed, even if a tab should be hidden due to a subscription
        // Otherwise this creates an unintuitive UI for the user
        const validTabs = pageHeaderInfo?.tabs.filter(tab =>
            tab.onlyShowForVenuesWithSubscriptionTypes === undefined ||
            tab.id === this.state.activeTab.id ||
            tab.onlyShowForVenuesWithSubscriptionTypes.find(
                type => this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, [type])));
        this.setState({
            tabs: validTabs ?? []
        });
    }

    private onSave(): void {
        if (!this.props.enableSaveButton || !this.props.onSaveSettings) {
            return;
        }

        this.props.onSaveSettings();
    }

    private renderSavingSpinner(): JSX.Element {
        return(
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        );
    }

    private renderSaveText(): JSX.Element {
        return(
            <>
                {LanguageProvider.getTranslation(translations.buttons.save)}
                <img src={SaveButton} />
            </>
        );
    }

    public render(): JSX.Element {
        return (
            <>
                <div className="page-header">
                    {this.state.tabs.length > 0 &&
                        <ul className="nav">
                            {this.state.tabs.map((item) =>
                                <Item key={item.id}
                                    data={item}
                                    onClick={this.toggleTab}
                                    isActive={this.state.activeTab != null && this.state.activeTab.id === item.id} />)}
                        </ul>
                    }
                    {this.props.displaySaveButton &&
                        <div className="ml-auto">
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className={`btn save-button btn-primary ${(this.props.enableSaveButton || this.props.isSaving) ? '' : 'disabled'}`}
                                    data-test="btn-header-save"
                                    onClick={this.onSave}>
                                    <div className="d-flex justify-content-around">
                                        {this.props.isSaving ? this.renderSavingSpinner() : this.renderSaveText()}
                                    </div>
                                </button>
                            </div>
                        </div>}
                </div>
            </>);
    }

    private toggleTab(activeTab: IPageHeaderItem): void {
        if (this.pageHasUnsavedChanges()) {
            return;
        }

        if (this.props.onTabChange) {
            this.props.onTabChange(activeTab.id);
        }

        this.setState({ activeTab });
    }

    private checkOnPageExit(): string | undefined {
        if (this.pageHasUnsavedChanges()) {
            return LanguageProvider.getTranslation(translations.alerts.navigation);
        }

        return undefined;
    }

    private pageHasUnsavedChanges(): boolean {
        return !!(this.props.displaySaveButton && this.props.enableSaveButton);
    }

    private removeBeforeUnloadEvents(): void {
        window.onbeforeunload = null;
    }
}

export default withTranslation()(PageHeader);