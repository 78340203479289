import * as React from "react";
import { withTranslation } from "react-i18next";
import { ChartData, ChartOptions } from "chart.js";

import BarGraph from "@/components/graphs/barGraph";
import IInsightOccupationBarGraphProps from "./interfaces/IInsightOccupationBarGraphProps";
import IInsightHourlyOccupationBarGraphState from "./interfaces/IInsightHourlyOccupationBarGraphState";
import DataInsightsService from "@/services/dataInsightsService";
import HourlyMotionValue from "@/models/hourlyMotionValue";
import ChartDataCreator from "@/utils/chartDataCreator";
import LanguageProvider from "@/providers/languageProvider";
import { withTelemetry } from "@/services/telemetryService";
import Colors from "@/styles/colors";

class InsightHourlyOccupationBarGraph extends React.Component<IInsightOccupationBarGraphProps, IInsightHourlyOccupationBarGraphState> {

    private dataInsightsService: DataInsightsService;

    public constructor(props: IInsightOccupationBarGraphProps) {
        super(props);

        this.dataInsightsService = new DataInsightsService();

        const state: IInsightHourlyOccupationBarGraphState = {
            chartData: [],
            earliestHour: 0,
            latestHour: 23,
            loading: true
        };

        this.state = state;
    }

    public async componentDidMount(): Promise<void> {
        if (!this.props.ids || this.props.ids.length === 0) {
            return;
        }

        await this.updateChartData();
    }

    public async componentDidUpdate(previousProps: IInsightOccupationBarGraphProps): Promise<void> {
        if (!this.props.ids || this.props.ids.length === 0) {
            return;
        }

        if (this.props.startDate === previousProps.startDate
            && this.props.endDate === previousProps.endDate
            && JSON.stringify(this.props.ids.sort()) === JSON.stringify(previousProps.ids.sort())) {
            return;
        }

        await this.updateChartData();
    }

    private async updateChartData(): Promise<void> {
        this.setState({
            loading: true,
            chartData: []
        });

        let motionData: HourlyMotionValue[] = [];

        switch (this.props.renderType) {
            case 'asset':
                motionData = await this.dataInsightsService.getHourlyAssetMotionData(this.props.ids, this.props.startDate, this.props.endDate);
                break;
            case 'floor':
                motionData = await this.dataInsightsService.getHourlyFloorMotionData(this.props.ids, this.props.startDate, this.props.endDate);
                break;
            case 'venue':
                motionData = await this.dataInsightsService.getHourlyVenueMotionData(this.props.ids, this.props.startDate, this.props.endDate);
                break;
        }

        const [chartData, earliestHour, latestHour] = ChartDataCreator.createHourlyMotionData(motionData);

        this.setState({
            chartData: chartData,
            earliestHour: earliestHour,
            latestHour: latestHour,
            loading: false
        });
    }

    public render(): JSX.Element {
        const averageLabel = LanguageProvider.getTranslation("pages.motioninsights.reports.graph.occupancy.average");
        const hourNumbers = Array.from({ length: (this.state.latestHour - this.state.earliestHour + 1) }, (v, k) => k + this.state.earliestHour);
        const hourLabels = hourNumbers.map((x) => `${x.toString()}:00`);

        const data: ChartData = {
            labels: hourLabels,
            datasets: [{
                label: averageLabel,
                data: this.state.chartData,
                backgroundColor: Colors.royal_blue,
                borderColor: Colors.royal_blue,
                yAxisID: 'bar',
                borderWidth: 1,
                categoryPercentage: 0.7,
                barPercentage: 0.5
            }]
        };

        const options: ChartOptions = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        beginAtZero: true
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    stacked: false
                }],
                yAxes: [{
                    id: 'bar',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        padding: 10,
                        stepSize: this.props.stepSize
                    },
                    stacked: false
                }]
            },
            maintainAspectRatio: true,
            legend: {
                display: false
            }
        };

        return (
            <div className="pt-3">
                <BarGraph data={data} height={this.props.height} width={this.props.width} options={options} loading={this.state.loading} />
            </div>
        );
    }
}

export default withTranslation()(withTelemetry(InsightHourlyOccupationBarGraph, "InsightHourlyOccupationBarGraph"));