import ThresholdNotification from "../models/thresholdNotification";
import LanguageProvider from "./languageProvider";
import { LanguageCode } from "./languageProvider";
import translations from "../translations/mapper";

export default class ThresholdNotificationProvider {
    public static GetTranslatedDescriptionForThresholdNotification(notification: ThresholdNotification): string {
        const activeLanguage = LanguageProvider.activeLanguage;

        const roundedMeasuredValue = Math.round(notification.measuredValue * 100) / 100;

        switch (activeLanguage) {
            case LanguageCode.EN:
                return `There was a ${this.TranslateDataType(notification.category)} threshold ${notification.measuredValue > notification.thresholdValue ? "exceedance" : "undershoot"}` +
                    ` in ${notification.venueName}, ${notification.floorName}, ${notification.spaceName}.` +
                    ` On ${notification.LocalizedCreatedOn.format("DD-MM")} at ${notification.LocalizedCreatedOn.format("HH:mm")}. Largest deviation after notification: ${roundedMeasuredValue} ${this.getMeasurementIcon(notification.category)}`;
            case LanguageCode.NL:
                return `Er was een ${this.TranslateDataType(notification.category)} ${notification.measuredValue > notification.thresholdValue ? "overschrijding" : "onderschrijding"}` +
                    ` in ${notification.venueName}, ${notification.floorName}, ${notification.spaceName}. ` +
                    `Op ${notification.LocalizedCreatedOn.format("DD-MM")} om ${notification.LocalizedCreatedOn.format("HH:mm")}. Grootste afwijking sinds melding: ${roundedMeasuredValue} ${this.getMeasurementIcon(notification.category)}`;
            default:
                return `No description translations available yet for language ${activeLanguage}`;
        }
    }

    public static TranslateDataType(dataType: string): string {
        return LanguageProvider.getTranslation(`comfort.${dataType.toLowerCase()}`);
    }

    public static GetTranslatedCategoryForThresholdNotification(notification: ThresholdNotification): string {
        const typeOfNotifications = notification.measuredValue > notification.thresholdValue
            ? LanguageProvider.getTranslation(translations.pages.notifications.thresholdexpand.exceedance)
            : LanguageProvider.getTranslation(translations.pages.notifications.thresholdexpand.undershoot);
        return `${ThresholdNotificationProvider.TranslateDataType(notification.category)} ${typeOfNotifications}`;
    }

    public static getMeasurementIcon(dataType: string): string {
        switch (dataType) {
            case "Temperature":
                return "°C";
            case "CarbonDioxide":
                return "ppm";
            case "Humidity":
                return "%";
            default:
                return "";
        }
    }
}