import * as React from 'react';
import { withTranslation } from "react-i18next";
import moment from 'moment';

import BarGraph from '@/components/graphs/barGraph';
import LineGraph from '@/components/graphs/lineGraph';

import INormalisedEnergieMissieGraphProps from "./interfaces/INormalisedEnergieMissieGraphProps";
import INormalisedEnergieMissieGraphState from './interfaces/INormalisedEnergieMissieGraphState';
import EnergyUnitType from './enums/energyUnitType';
import LanguageProvider from '@/providers/languageProvider';
import IBeSenseChartData from '@/components/graphs/interfaces/IBeSenseChartData';
import { ChartOptions } from 'chart.js';
import CenteredPageLoader from 'components/loaders/centeredPageLoader';
import EnergyType from './enums/energyType';
import translations from 'translations/mapper';
import DownloadIcon from "@/images/Download.svg";
import { CsvColumn, CsvExport } from '../models/csvExport';
import EnergyGraphPngButton from './energyGraphPngButton';
import Colors from '@/styles/colors';

class NormalisedEnergieMissieGraph extends React.Component<INormalisedEnergieMissieGraphProps, INormalisedEnergieMissieGraphState> {
    private readonly informationTypeUsage: string = 'usage';
    private readonly informationTypePrognosis: string = 'prognosis';
    private readonly informationTypeBaseline: string = 'baseline';
    private readonly informationTypeTemperature: string = 'temperature';

    private readonly labelTranslationTypeUsage: string = 'actualusage';
    private readonly labelTranslationTypePrognosis: string = 'prognosisusage';

    public constructor(props: INormalisedEnergieMissieGraphProps) {
        super(props);

        this.getLabels = this.getLabels.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.formatCsvData = this.formatCsvData.bind(this);
        this.getCsvColumnData = this.getCsvColumnData.bind(this);
        this.renderExportButtons = this.renderExportButtons.bind(this);
    }

    private getLabels(): string[] {
        const weekDays = LanguageProvider.getTranslations(["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            .map(d => `dates.shorthand.${d}`));

        switch (this.props.timeRepresentation) {
            case 'day':
                const dayLabels: string[] = [];
                for (let i = 0; i < 24; i++) {
                    dayLabels.push(`${i}:00`);
                }
                return dayLabels;
            case 'week':
                const weekLabels: string[] = [];
                for (let i = 0; i < 7; i++) {
                    const date = moment(this.props.startDate);
                    const day = (date.day() + i) % 7;
                    weekLabels.push(`${weekDays[day]} ${date.add(i, 'days').date()}`);
                }
                return weekLabels;
            case 'month':
                const monthLabels: string[] = [];
                const startMonth = this.props.startDate.getMonth();
                let currentMonth = startMonth;
                for (let i = 0; currentMonth === startMonth; i++) {
                    const momentDate = moment(this.props.startDate);
                    const dateToPrint = momentDate.add(i, 'days');
                    monthLabels.push(`${weekDays[dateToPrint.day()]} ${dateToPrint.date()}`);

                    // Check if the next iteration should happen or not
                    const nextDate = momentDate.add(1, 'days');
                    currentMonth = nextDate.month();
                }
                return monthLabels;
            case 'year':
                return LanguageProvider.getTranslations(
                    ["january", "february", "march", "april", "may", "june", "july", "august",
                        "september", "october", "november", "december"].map(d => `dates.shorthand.${d}`));
        }
    }

    private getCsvColumnData(): string[] {
        const result: string[] = [];

        // On 'day' level a different graph is used, so no need to handle that case
        switch (this.props.timeRepresentation) {
            case 'week': {
                const date = moment(this.props.startDate);
                for (let i = 0; i < 7; i++) {
                    result.push(date.format("YYYY-MM-DD"));
                    date.add(1, 'day');
                }
                break;
            }
            case 'month': {
                const date = moment(this.props.startDate);
                const currentMonth = date.month();
                while (currentMonth === date.month()) {
                    result.push(date.format("YYYY-MM-DD"));
                    date.add(1, 'day');
                }
                break;
            }
            case 'year': {
                const date = moment(this.props.startDate);
                for (let i = 0; i < 12; i++) {
                    result.push(date.format("YYYY-MM-DD"));
                    date.add(1, 'month');
                }
                break;
            }
        }

        return result;
    }

    private getPrognosisLineWidth(): number {
        switch (this.props.timeRepresentation) {
            case 'week':
                return 56;
            case 'month':
                return 16;
            case 'year':
                return 36;
            default:
                return 24;
        }
    }

    private roundTemperature(rawTemperature: number): number{
        return Math.floor(rawTemperature * 10) / 10;
    }

    private roundValue(rawValue: number): number {
        switch (this.props.timeRepresentation) {
            case 'year':
                return this.props.unitType === EnergyUnitType.percentage ? Math.round(rawValue * 10) / 10 : Math.round(rawValue);
            default:
                return Math.floor(rawValue * 100) / 100;
        }
    }

    private calculateMonthlyAverageTemperatures(): (number|null)[] {
        const monthlyWeatherData: (number|null)[]= [];
        // For year we need to show average temperature per month, rather than the temperature of each day
        for (let i = 1; i <= 12; i++) {
            const weatherDataInMonth= this.props.dailyTemperatureData.filter(d => d.localMonth === i);
            if (weatherDataInMonth.length > 0){
                const sum = weatherDataInMonth.reduce((s, day) => s + day.temperature, 0);
                const average = sum / weatherDataInMonth.length;

                monthlyWeatherData.push(this.roundTemperature(average));
            }
            else{
                monthlyWeatherData.push(null);
            }
        }

        return monthlyWeatherData;
    }

    private renderTooltip(informationType: string): string {
        let key: string;

        let type = '';
        if (this.props.energyType.toString() === EnergyType[EnergyType.Gas]) {
            type = 'gas';
        }
        else if (this.props.energyType.toString() === EnergyType[EnergyType.Heat]) {
            type = 'heat';
        }

        switch (informationType) {
            case this.informationTypeUsage:
                key = `tooltips.energy.venue-energy-consumption-content.usage-${type}`;
                break;
            default:
                key = `tooltips.energy.venue-energy-consumption-content.${informationType.toLocaleLowerCase()}-usage-${type}`;
                break;
        }
        return LanguageProvider.getTranslation(key);
    }

    private renderLabel: (_: string) => string = (label: string): string => LanguageProvider.getTranslation(`pages.energy.tabs.graph.${label}`);

    private formatCsvData(): void {
        const csvExport = new CsvExport(this.props.exportOptions?.fileName ?? "export.csv");
        const currentUnitType = EnergyUnitType[this.props.unitType];

        const dateColumn: CsvColumn = {
            header: 'Timestamp',
            data: this.getCsvColumnData()
        };

        csvExport.AddColumn(dateColumn);

        const usageColumn: CsvColumn = {
            header: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusage) + ` (${currentUnitType})`,
            data: this.props.data.map(d => d.actualUsage === null ? '' : CsvExport.stringifyWithAtLeastOneDecimal(d.actualUsage))
        };

        csvExport.AddColumn(usageColumn);

        if (this.props.data.some(x => x.savingsAreAggregated) && !this.props.onlyRenderConsumption) {
            const prognosisColumn: CsvColumn = {
                header: this.renderLabel(this.labelTranslationTypePrognosis) + ` (${currentUnitType})`,
                data: this.props.data.map(x => x.savingsAreAggregated ? CsvExport.stringifyWithAtLeastOneDecimal(this.roundValue(x.forecastedUsage)) : '')
            };

            const baselineColumn: CsvColumn = {
                header: this.renderLabel(this.informationTypeBaseline) + ` (${currentUnitType})`,
                data: this.props.data.map(x => x.savingsAreAggregated ? CsvExport.stringifyWithAtLeastOneDecimal(this.roundValue(x.normalisedBaseline)) : '')
            };

            csvExport.AddColumn(prognosisColumn, baselineColumn);
        }

        if (this.props.dailyTemperatureData && this.props.dailyTemperatureData.length > 0) {
            if (this.props.timeRepresentation === 'year'){
                const monthlyData = this.calculateMonthlyAverageTemperatures();

                const weatherColumn: CsvColumn = {
                    header: this.renderLabel(this.informationTypeTemperature),
                    data: monthlyData.map(w => CsvExport.stringifyWithAtLeastOneDecimal(w))
                }
                csvExport.AddColumn(weatherColumn)
            }
            else {
                const sortedWeatherData = this.props.dailyTemperatureData.sort((a, b) => a.localDay - b.localDay);

                const weatherColumn: CsvColumn = {
                    header: this.renderLabel(this.informationTypeTemperature),
                    data: sortedWeatherData.map(w => CsvExport.stringifyWithAtLeastOneDecimal(this.roundTemperature(w.temperature)))
                }
                csvExport.AddColumn(weatherColumn)
            }
        }

        csvExport.ExecuteDownload();
    }

    private renderExportButtons(): JSX.Element {
        return (<>
            <div className="row mr-1 png-hidden">
                <div className="col-sm-12">
                    <div className="d-flex w-100">
                        <button
                            onClick={this.formatCsvData}
                            className="ml-auto btn btn-secondary">
                            <span>CSV</span>
                            <img className="pl-1" src={DownloadIcon} />
                        </button>
                    </div>
                </div>
            </div>
            {this.props.graphHtmlReference && <EnergyGraphPngButton divRef={this.props.graphHtmlReference} heightCompensation={25} fileName={this.props.exportOptions?.fileName} />}
        </>);
    }

    public render(): JSX.Element {
        const prognosisLineWidth = this.getPrognosisLineWidth();

        const data: IBeSenseChartData = {
            labels: this.getLabels(),
            datasets: []
        };

        if (this.props.dailyTemperatureData && this.props.dailyTemperatureData.length > 0){
            let weatherData: (number|null)[]= [];

            if (this.props.timeRepresentation === 'year'){
                weatherData = this.calculateMonthlyAverageTemperatures();
            }
            else{
                weatherData = this.props.dailyTemperatureData.sort((a, b) => a.localDay - b.localDay).map(x => this.roundTemperature(x.temperature));
            }

            data.datasets?.push(
                {
                    type: "line",
                    label: this.renderLabel(this.informationTypeTemperature),
                    tooltipContent: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.temperaturedescription),
                    data: weatherData,
                    backgroundColor: Colors.persian_red,
                    borderColor: Colors.persian_red,
                    yAxisID: 'temp',
                    borderWidth: 2.5,
                    fill: false,
                    lineTension: 0.3,
                    barPercentage: 1.0,
                    categoryPercentage: 0.5,
                    renderLegendAsLine: true,
                    disableLegend: false,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointHitRadius: 25
                }
            );
        }

        let renderNoBaselineFoundColor = false;

        const barColors = this.props.data.map(d => {
            // Actual color is irrelevant if value is null, but still needs to be in the array.
            if (this.props.onlyRenderConsumption || d.actualUsage === null) {
                return Colors.royal_blue;
            }
            else if (!d.savingsAreAggregated) {
                renderNoBaselineFoundColor = renderNoBaselineFoundColor || d.actualUsage > 0;
                return Colors.royal_blue;
            }
            else {
                if (d.actualUsage > d.normalisedBaseline) {
                    return Colors.amaranth_red;
                }

                if (d.actualUsage < d.forecastedUsage) {
                    return Colors.energymanagement_green;
                }

                return Colors.sunglow_yellow;
            }
        });

        if (!this.props.onlyRenderConsumption) {
            data.datasets?.push(
                {
                    type: "line",
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusagebelowprognosis),
                    tooltipContent: this.renderTooltip(this.informationTypeUsage),
                    backgroundColor: Colors.energymanagement_green,
                    disableLegendClick: true
                },
                {
                    type: "line",
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusagebelowbaseline),
                    tooltipContent: this.renderTooltip(this.informationTypeUsage),
                    backgroundColor: Colors.sunglow_yellow,
                    disableLegendClick: true
                },
                {
                    type: "line",
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusageabovebaseline),
                    tooltipContent: this.renderTooltip(this.informationTypeUsage),
                    backgroundColor: Colors.amaranth_red,
                    disableLegendClick: true
                },
                {
                    type: "line",
                    label: this.renderLabel(this.informationTypeBaseline),
                    tooltipContent: this.renderTooltip(this.informationTypeBaseline),
                    data: this.props.data.map(x => x.savingsAreAggregated ? this.roundValue(x.normalisedBaseline) : undefined),
                    showLine: false,
                    pointStyle: "line",
                    pointBorderColor: Colors.black,
                    pointRadius: prognosisLineWidth,
                    pointBorderWidth: 2,
                    pointHoverRadius: prognosisLineWidth,
                    pointHoverBorderWidth: 1,
                    renderLegendAsLine: true
                },
                {
                    type: "line",
                    label: this.renderLabel(this.labelTranslationTypePrognosis),
                    tooltipContent: this.renderTooltip(this.informationTypePrognosis),
                    data: this.props.data.map(x => x.savingsAreAggregated ? this.roundValue(x.forecastedUsage) : undefined),
                    showLine: false,
                    pointStyle: 'line',
                    pointBorderColor: Colors.delta_grey,
                    pointRadius: prognosisLineWidth,
                    pointBorderWidth: 2,
                    pointHoverRadius: prognosisLineWidth,
                    pointHoverBorderWidth: 1,
                    renderLegendAsLine: true
                }
            );
        }

        data.datasets?.push({
            label: this.renderLabel(this.labelTranslationTypeUsage),
            tooltipContent: this.renderTooltip(this.informationTypeUsage),
            data: this.props.data.map(x => x.actualUsage === null ? undefined : this.roundValue(x.actualUsage)),
            backgroundColor: barColors,
            borderColor: this.props.graphType === "bar" ? barColors : Colors.grey,
            yAxisID: 'bar',
            categoryPercentage: 0.5,
            barPercentage: 0.9,
            borderWidth: 1,
            fill: false,
            lineTension: 0,
            pointBorderColor: barColors,
            disableLegend: true
        })

        if (renderNoBaselineFoundColor || this.props.onlyRenderConsumption) {
            data.datasets?.push(
                {
                    type: "line",
                    label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusagenobaseline),
                    tooltipContent: this.renderTooltip(this.informationTypeUsage),
                    backgroundColor: Colors.royal_blue,
                    disableLegendClick: true
                }
            );
        }

        const options: ChartOptions = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontSize: 12,
                        fontFamily: "'Poppins', sans-serif",
                        fontStyle: "100"
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: `${LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.datatype[this.props.energyType.toString().toLowerCase()])} [${EnergyUnitType[this.props.unitType]}]`
                        },
                        id: 'bar',
                        type: 'linear',
                        position: 'left',
                        gridLines: {
                            drawBorder: false
                        },
                        ticks: {
                            fontColor: Colors.black,
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 12,
                            fontStyle: "100",
                            beginAtZero: true,
                            padding: 10
                        }
                    }
                ]
            },
            maintainAspectRatio: true,
            title: {
                display: false
            },
            legend: {
                display: false
            }
        };

        options.scales?.yAxes?.push({
            scaleLabel: {
                display: true,
                labelString: this.renderLabel(this.informationTypeTemperature) + ' [°C]'
            },
            id: 'temp',
            type: 'linear',
            position: 'right',
            gridLines: {
                display: false
            },
            ticks: {
                fontColor: Colors.black,
                fontFamily: "'Poppins', sans-serif",
                fontSize: 12,
                fontStyle: "100",
                suggestedMin: -10,
                padding: 10
            },
            // Set display to false rather than remove scale from set due to bug in chartjs, where removal
            // of scale is not reflected until refresh (but updates to existing scale are).
            display: this.props.dailyTemperatureData && this.props.dailyTemperatureData.length > 0
        })


        return (
            <div>
                {this.props.loading && <div id="graph-loader">
                    <div className="w-100 h-75 position-absolute">
                        <div className="w-100 h-100 map-margin-correction d-flex justify-content-center">
                            <CenteredPageLoader loading={this.props.loading ?? false} size={12} />
                        </div>
                    </div>
                </div>}
                {this.props.graphType === "bar" && <BarGraph
                    data={data}
                    height={this.props.height}
                    width={this.props.width}
                    options={options}
                    elementsClickable={this.props.timeRepresentation !== "day"}
                    onElementClick={this.props.onElementClick}
                    showLegend={true}
                    renderExportButtons={this.renderExportButtons} />}
                {this.props.graphType === "line" && <LineGraph
                    data={data}
                    height={this.props.height}
                    width={this.props.width}
                    options={options}
                    showLegend={true}
                    renderExportButtons={this.renderExportButtons} />}
            </div>
        );
    }
}

export default withTranslation()(NormalisedEnergieMissieGraph);