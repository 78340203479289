import { Component } from "react";
import FullPageLoader from "../../components/loaders/fullPageLoader";
import Teaser from '../../components/teaser/realEstateUtilizationTeaser';
import VenueProvider from "../../providers/venueProvider";
import SubscriptionValidationService from "../../services/subscriptionValidationService";
import AppEventHub, { AppEvents } from "../../utils/appEventHub";

import IState from './interfaces/iRealEstateUtilizationPageState';

export default class RealEstateUtilizationPage extends Component<{}, IState> {

    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: {}) {
        super(props);

        const state = {
            loading: true,
            displayContent: false
        };

        this.state = state;

        this.initializeAsync = this.initializeAsync.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeAsync);
        await this.initializeAsync();
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeAsync);
    }

    private async initializeAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();
        const hasSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEEquipped"]) : false;

        this.setState({
            displayContent: hasSubscription,
            loading: false
        });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}

                {!this.state.loading && <>
                    {!this.state.displayContent && <Teaser/>}
                    <div className={`${this.state.displayContent ? "" : "d-none"}`}>
                        {this.props.children}
                    </div>
                </>}
            </>
        );
    }
}