import React from "react";
import PageUtils from "../utils/pageUtils";

class Page extends React.Component<IPageProps> {
    public render(): JSX.Element {
        PageUtils.setPageTitle(this.props.title);
        const PageComponent = this.props.component;

        return (
            <PageComponent {...this.props} />
        );
    }
}

export default Page;

interface IPageProps {
    title: string;
    component: any;
}