import DashboardService from "./dashboardService";
import VenueProvider from "../providers/venueProvider";
import ITrashcanThreshold from "../interfaces/ITrashcanThreshold";

export default class TrashcanService {
    private readonly dashboardService: DashboardService;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getAllTrashcanThresholds(skipCache: boolean = false): Promise<ITrashcanThreshold[]> {
        const venue = VenueProvider.getActiveVenue();
        if (venue === undefined) {
            throw new Error("Error getting venue settings: No venue found in venueprovider!");
        }
        return this.dashboardService.get<ITrashcanThreshold[]>(`trashcan/customer/${venue.customerId}/venue/${venue.id}/thresholds`, skipCache);
    }

    public async batchUpsertVenueSettings(trashcanThresholds: ITrashcanThreshold[]): Promise<Response> {
        const venue = VenueProvider.getActiveVenue();
        if (venue === undefined) {
            throw new Error("Error batch upserting venue settings: No venue found in venueprovider!");
        }
        return this.dashboardService.put(`trashcan/customer/${venue.customerId}/venue/${venue.id}/thresholds`, trashcanThresholds);
    }
}