import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ComponentClass, ComponentType } from 'react';

export default class TelemetryService {

    private static reactPlugin: ReactPlugin = new ReactPlugin();
    private static appInsights: ApplicationInsights;
    private static isInitialized: boolean;
    private static enabled: boolean = true;

    public static initialize(instrumentationKey?: string, browserHistory?: any): void {
        if (TelemetryService.isInitialized) {
            return;
        }

        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }

        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided');
        }

        TelemetryService.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                extensions: [TelemetryService.reactPlugin],
                extensionConfig: {
                    [TelemetryService.reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        TelemetryService.appInsights.loadAppInsights();
        TelemetryService.isInitialized = true;
        TelemetryService.enhanceTracking();
    }

    public static setTracking(enabled: boolean): void {
        TelemetryService.enabled = enabled;
    }

    private static enhanceTracking(): void {
        const telemetryInitializer = (msg: ITelemetryItem): void => {
            if (!msg?.data) {
                return;
            }

            msg.data["page"] = document.title;
            msg.data["url"] = document.URL;
            msg.data["is_customer"] = TelemetryService.enabled;
        };

        TelemetryService.AppInsights.addTelemetryInitializer(telemetryInitializer);
    }

    public static get AppInsights(): ApplicationInsights {
        return TelemetryService.appInsights;
    }

    public static get ReactPlugin(): ReactPlugin {
        return TelemetryService.reactPlugin;
    }
}

export function withTelemetry<P>(component: ComponentType<P>, componentName?: string, className?: string): ComponentClass<P, any> {
    return withAITracking(TelemetryService.ReactPlugin, component, componentName, className);
}