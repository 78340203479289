import "./sanitaryInsightsPage.scss";

import { nl } from "date-fns/esm/locale";
import moment from "moment";
import * as React from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Select, { OnChangeValue } from "react-select";

import CoreSpaceDatatypes from "../../../enums/coreSpaceDatatypes";
import CoreSpaceIncludes from "../../../enums/coreSpaceIncludes";
import IReactSelectValue from "../../../interfaces/IReactSelectValue";
import ColorProvider from "@/providers/colorProvider";
import LanguageProvider from "@/providers/languageProvider";
import VenueProvider from "@/providers/venueProvider";
import CoreSpaceService from "@/services/coreSpaceService";
import { withTelemetry } from "@/services/telemetryService";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import Dictionary from "@/utils/dictionary";
import SelectBoxUtils from "@/utils/selectBoxUtils";
import DailySanitaryOverviewGraph from "../components/dailySanitaryOverviewGraph";
import HourlySanitaryOverviewGraph from "../components/hourlySanitaryOverviewGraph";
import IFloorSelectValue from "../../../interfaces/IFloorSelectValue";
import IGraphSelectValue from "../interfaces/IGraphSelectValue";
import IInsightsOverviewProps from "../interfaces/IInsightsOverviewProps";
import ISanitaryInsightsPageState from "../interfaces/ISanitaryInsightsPageState";
import RealEstateUtilizationPage from "../realEstateUtilizationPage";
import translations from "@/translations/mapper";
import BesenseTooltip from "@/components/besenseTooltip/besenseTooltip";
import FloorSelect from "@/components/select/floorSelect";

// Todo: Currently are placeholder icons until design of real icons is finished, replace later.
import PercentageSelectedIcon from "@/images/BezoekenPercentage_selected.svg";
import PercentageIcon from "@/images/BezoekenPercentage_unselected.svg";
import VisitorsSelectedIcon from "@/images/BezoekenAbsoluut_selected.svg";
import VisitorsIcon from "@/images/BezoekenAbsoluut_unselected.svg";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import GeneralTeaser from "@/components/teaser/generalTeaser";
import links from "@/utils/links";

class SanitaryInsightsPage extends React.Component<IInsightsOverviewProps, ISanitaryInsightsPageState> {
    private coreSpaceService: CoreSpaceService;
    private subscriptionValidationService: SubscriptionValidationService;

    private sanitaryAssetsLookup: Dictionary<string>;

    public constructor(props: IInsightsOverviewProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        this.sanitaryAssetsLookup = new Dictionary<string>();

        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        const dateDiff = startDate.getDay() === 1 ? 7 : (startDate.getDay() + 6) % 7;
        startDate.setDate(startDate.getDate() - dateDiff);
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        const state: ISanitaryInsightsPageState = {
            loading: true,
            venueId: "",
            selectedRenderIds: [],
            selectedRenderType: "",
            endDate: endDate,
            startDate: startDate,
            floors: [],
            selectedFloor: null,
            selectedSanitaryAssets: [],
            sanitarySelectionOptions: [],
            renderMap: false,
            renderMapInitial: false,
            selectedDataType: "percentage",
            hasMotionSanitary: true
        };

        this.state = state;

        this.onAssetAdd = this.onAssetAdd.bind(this);
        this.onAssetRemove = this.onAssetRemove.bind(this);

        this.setActiveFloorAsync = this.setActiveFloorAsync.bind(this);
        this.setActiveSanitaryAsset = this.setActiveSanitaryAsset.bind(this);
        this.setActiveVenueAsync = this.setActiveVenueAsync.bind(this);
        this.clearFloorSelection = this.clearFloorSelection.bind(this);
        this.clearAssetSelection = this.clearAssetSelection.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.setPercentageSelected = this.setPercentageSelected.bind(this);
        this.setVisitorsSelected = this.setVisitorsSelected.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.setActiveVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        registerLocale('nl', nl);
        setDefaultLocale('nl');

        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.setActiveVenueAsync();

        this.setState({
            loading: false
        });
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.setActiveVenueAsync);
    }

    private async setActiveVenueAsync(): Promise<void> {
        this.sanitaryAssetsLookup = new Dictionary<string>();

        const activeVenue = VenueProvider.getActiveVenue();
        this.clearFloorSelection();
        this.setState({ sanitarySelectionOptions: [] });

        if (!activeVenue) {
            return;
        }

        const floors = await this.coreSpaceService.getFloorsForVenue(activeVenue.id, [CoreSpaceIncludes.Properties]);
        const hasMotionSanitarySubscription = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["MotionSanitary"]);

        this.setState({
            venueId: activeVenue.id,
            floors: floors,
            hasMotionSanitary: hasMotionSanitarySubscription
        });
    }

    private onAssetAdd(selectedValue: IGraphSelectValue): void {
        if (!selectedValue) {
            return;
        }

        this.setActiveSanitaryAsset(this.state.selectedSanitaryAssets.concat(selectedValue));
    }

    private onAssetRemove(selectedValue: IReactSelectValue): void {
        if (!selectedValue) {
            return;
        }

        this.setActiveSanitaryAsset(this.state.selectedSanitaryAssets.filter(asset => asset.value !== selectedValue.value));
    }

    private async setActiveFloorAsync(selectedItem: OnChangeValue<IFloorSelectValue, false>): Promise<void> {
        const item = selectedItem as IFloorSelectValue | null;
        this.setState({
            selectedFloor: item
        }, this.clearAssetSelection);

        if (!item) {
            return;
        }

        if (item.isDefault) {
            this.setState({
                sanitarySelectionOptions: [],
                selectedFloor: null
            });

            return;
        }

        const floorId = item.value;
        const assets = await this.coreSpaceService.getSpacesForFloor(this.state.venueId, floorId, undefined, undefined, [CoreSpaceDatatypes.Toilet]);
        assets.forEach(a => this.sanitaryAssetsLookup.add(a.id, a.id));

        const sanitarySelectionOptions = assets.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
            .map((a, i) => ({
                value: a.id,
                label: a.name,
                color: ColorProvider.getNextSemiRandomBackgroundColorForIndex(i),
                textColor: ColorProvider.getNextSemiRandomTextColorForIndex(i)
            }));

        this.setState({
            sanitarySelectionOptions: sanitarySelectionOptions
        });
    }

    private get percentageSelected(): boolean {
        return this.state.selectedDataType === "percentage";
    }

    private get visitorsSelected(): boolean {
        return this.state.selectedDataType === "visitors";
    }

    private setActiveSanitaryAsset(selectedItems: OnChangeValue<IGraphSelectValue, true>): void {
        const items = selectedItems as IGraphSelectValue[];

        this.setState({
            selectedSanitaryAssets: items,
        });
    }

    private handleStartDateChange(state: Date): void {
        this.setState({
            startDate: state
        });
    }

    private handleEndDateChange(state: Date): void {
        this.setState({
            endDate: state
        });
    }

    private clearAssetSelection(): void {
        this.setState({
            sanitarySelectionOptions: [],
            selectedSanitaryAssets: []
        });
    }

    private clearFloorSelection(): void {
        this.clearAssetSelection();
        this.setState({
            selectedFloor: null,
            selectedSanitaryAssets: [],
            sanitarySelectionOptions: []
        });
    }

    private setPercentageSelected(): void {
        this.setState({
            selectedDataType: "percentage"
        });
    }

    private setVisitorsSelected(): void {
        this.setState({
            selectedDataType: "visitors"
        });
    }

    public render(): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation(translations.buttons.dropdowns.nooptions);
        const venue = VenueProvider.getActiveVenue();
        const venueName = venue?.name ?? "";

        return (
            <RealEstateUtilizationPage>
                {!this.state.hasMotionSanitary && <GeneralTeaser buttonLink={links.external.beyondeyes.realestateutilization} />}
                {this.state.hasMotionSanitary && <>
                    <div id="sanitary-insights-page" className="main-content">
                        <div className="main-content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>{venueName}</h1>
                                </div>
                            </div>

                            <div className="row input-selection">
                                <div className="col-sm-12 d-flex">
                                    <div className="input-field-m mr-2">
                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            maxDate={this.state.endDate}
                                            placeholderText={LanguageProvider.getTranslation(translations.datepicker.startdate)}
                                            className="asset-datepicker clickable"
                                            dateFormat="dd-MM-yyyy"
                                            todayButton={LanguageProvider.getTranslation(translations.datepicker.today)}
                                        />
                                    </div>
                                    <div className="input-field-m mr-2">
                                        <DatePicker
                                            selected={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            minDate={this.state.startDate}
                                            maxDate={moment().toDate()}
                                            placeholderText={LanguageProvider.getTranslation(translations.datepicker.enddate)}
                                            className="asset-datepicker clickable"
                                            dateFormat="dd-MM-yyyy"
                                            todayButton={LanguageProvider.getTranslation(translations.datepicker.today)}
                                        />
                                    </div>
                                    <div className="input-field-l mr-2">
                                        <FloorSelect
                                            floors={this.state.floors}
                                            setActiveFloor={this.setActiveFloorAsync}
                                            isDisabled={this.state.renderMap}
                                            selectedFloor={this.state.selectedFloor}
                                        />
                                    </div>
                                    <div className="input-field-xl mr-auto">
                                        <Select
                                            value={this.state.selectedSanitaryAssets}
                                            onChange={this.setActiveSanitaryAsset}
                                            options={this.state.sanitarySelectionOptions}
                                            isMulti={true}
                                            placeholder={`${LanguageProvider.getTranslation(translations.buttons.dropdowns.asset)}`}
                                            noOptionsMessage={noOptionsMessage}
                                            styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)}
                                        />
                                    </div>
                                    <div className="selection-container mr-2">
                                        <div
                                            title={LanguageProvider.getTranslation(translations.pages.sanitaryinsights.buttons.percentage)}
                                            onClick={this.setPercentageSelected}
                                            className={"selection-item " + (this.percentageSelected ? "selected" : "")}>
                                            <img src={this.percentageSelected ? PercentageSelectedIcon : PercentageIcon} />
                                        </div>
                                        <div
                                            title={LanguageProvider.getTranslation(translations.pages.sanitaryinsights.buttons.visitors)}
                                            onClick={this.setVisitorsSelected}
                                            className={"selection-item " + (this.visitorsSelected ? "selected" : "")}>
                                            <img src={this.visitorsSelected ? VisitorsSelectedIcon : VisitorsIcon} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main-content-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2>
                                        {this.state.selectedDataType === "percentage" ? LanguageProvider.getTranslation("pages.sanitaryinsights.graph.dailytitlepercentage") : LanguageProvider.getTranslation("pages.sanitaryinsights.graph.dailytitlevisitors")}
                                        {this.state.selectedDataType === "visitors" && <BesenseTooltip content={LanguageProvider.getTranslation("pages.sanitaryinsights.graph.visitorsexplanation")} />}
                                    </h2>
                                    <DailySanitaryOverviewGraph
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        assetIds={this.state.selectedSanitaryAssets}
                                        floorId={this.state.selectedSanitaryAssets.length > 0 ? undefined : this.state.selectedFloor}
                                        venueId={this.state.selectedFloor ? undefined : { label: venueName, value: this.state.venueId }}
                                        width={20}
                                        height={8}
                                        selectedDataType={this.state.selectedDataType} />
                                </div>

                                <div className="col-sm-6">
                                    <h2>
                                        {this.state.selectedDataType === "percentage" ? LanguageProvider.getTranslation("pages.sanitaryinsights.graph.hourlytitlepercentage") : LanguageProvider.getTranslation("pages.sanitaryinsights.graph.hourlytitlevisitors")}
                                        {this.state.selectedDataType === "visitors" && <BesenseTooltip content={LanguageProvider.getTranslation(translations.pages.sanitaryinsights.graph.visitorsexplanation)} />}
                                    </h2>
                                    <HourlySanitaryOverviewGraph
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        assetIds={this.state.selectedSanitaryAssets}
                                        floorId={this.state.selectedSanitaryAssets.length > 0 ? undefined : this.state.selectedFloor}
                                        venueId={this.state.selectedFloor ? undefined : { label: venueName, value: this.state.venueId }}
                                        width={20}
                                        height={8}
                                        selectedDataType={this.state.selectedDataType} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </RealEstateUtilizationPage>
        );
    }
}

export default withTelemetry(SanitaryInsightsPage, "SanitaryInsightsPage");