import { withTranslation } from "react-i18next";
import IComfortOverviewGraphProps from "../../../pages/realestateutilization/components/interfaces/IComfortOverviewGraphProps";
import BaseComfortOverviewGraph from "../../../pages/realestateutilization/components/baseComfortOverviewGraph";
import ChartDataCreator from "@/utils/chartDataCreator";

class HourlyComfortOverviewGraph extends BaseComfortOverviewGraph {
    private latestHour: number;

    private earliestHour: number;

    public constructor(props: IComfortOverviewGraphProps) {
        super(props);
    }

    public getTestKey(): string {
        return "hourly-comfort-overview-graph";
    }

    public async updateChartData(): Promise<void> {
        const assetIds = this.props.assetIds || [];
        const ids = assetIds.map(a => a.value);
        const comfortData = await this.dataInsightsService.getHourlyAssetComfortData(ids, this.props.selectedComfortType.value, this.props.startDate, this.props.endDate);
        const [chartData, earliestHour, latestHour] = ChartDataCreator.createHourlyComfortOverview(comfortData, assetIds, this.props.thresholdHigh, this.props.thresholdLow);

        this.earliestHour = earliestHour;
        this.latestHour = latestHour;

        this.setState({
            chartData: chartData
        });
    }

    public getLabels(): string[] {
        const hourNumbers = Array.from({ length: (this.latestHour - this.earliestHour) }, (v, k) => k + this.earliestHour);
        const hourLabels = hourNumbers.map((x) => `${x.toString()}:00`);

        return hourLabels;
    }
}

export default withTranslation()(HourlyComfortOverviewGraph);