import * as React from "react";

import Switch from "react-switch";
import Colors from "@/styles/colors";

import LanguageProvider from "@/providers/languageProvider";
import SliderComponent from "@/components/slider/sliderComponent";
import IThresholdSettingLineState from "./interfaces/IThresholdSettingLineState";
import DataThresholds from "@/models/dataThresholds";
import translations from "@/translations/mapper";
import IThresholdSettingLineProps from "./interfaces/IThresholdSettingLineProps";
import EmailInput from "@/components/input/emailInput";

export default class ThresholdSettingLine extends React.Component<IThresholdSettingLineProps, IThresholdSettingLineState> {
    public constructor(props: IThresholdSettingLineProps) {
        super(props);

        const state: IThresholdSettingLineState = {
            currentMininumThreshold: this.props.thresholds.lowerThreshold,
            currentMaximumThreshold: this.props.thresholds.upperThreshold,
            mailAddresses: this.props.thresholds.mailAddresses ?? "",
            sendNotificationMail: this.props.thresholds.sendMailNotification ?? false
        };
        this.state = state;

        this.handleThresholdValuesChange = this.handleThresholdValuesChange.bind(this);
        this.updateThresholdFromProps = this.updateThresholdFromProps.bind(this);
        this.handleSendNotificationMailChange = this.handleSendNotificationMailChange.bind(this);
        this.handleAddressesChange = this.handleAddressesChange.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<IThresholdSettingLineProps>, prevState: Readonly<IThresholdSettingLineState>): void {
        if (prevProps.thresholds.lowerThreshold !== this.props.thresholds.lowerThreshold
            || prevProps.thresholds.upperThreshold !== this.props.thresholds.upperThreshold
            || prevProps.thresholds.mailAddresses !== this.props.thresholds.mailAddresses
            || prevProps.thresholds.sendMailNotification !== this.props.thresholds.sendMailNotification) {
            this.updateThresholdFromProps();
        }
    }

    private updateThresholdFromProps(): void {
        this.setState({
            currentMininumThreshold: this.props.thresholds.lowerThreshold,
            currentMaximumThreshold: this.props.thresholds.upperThreshold,
            mailAddresses: this.props.thresholds.mailAddresses ?? "",
            sendNotificationMail: this.props.thresholds.sendMailNotification ?? false
        });
    }

    private handleThresholdValuesChange(values: readonly number[]): void {
        if (values.length === 1) {
            this.setState({
                currentMaximumThreshold: values[0]
            }, () => this.callbackUpdateToThresholds());
        }
        else {
            this.setState({
                currentMininumThreshold: values[0],
                currentMaximumThreshold: values[1]
            }, () => this.callbackUpdateToThresholds());
        }
    }

    private handleAddressesChange(emails: string[]): void {
        this.setState({
            mailAddresses: emails.join(';')
        }, () => this.callbackUpdateToThresholds());
    }

    private handleSendNotificationMailChange(checked: boolean): void {
        this.setState({
            sendNotificationMail: checked
        }, () => this.callbackUpdateToThresholds());
    }

    private callbackUpdateToThresholds(): void {
        const thresholds = new DataThresholds(this.props.thresholds.dataType, this.state.mailAddresses, this.state.sendNotificationMail, this.state.currentMininumThreshold, this.state.currentMaximumThreshold);
        this.props.thresholdUpdateCallback(thresholds);
    }

    private getDomainForCurrentDatatype(): number[] {
        switch (this.props.thresholds.dataType) {
            case "temperature":
                return [0, 50];
            case "carbondioxide":
                return [0, 5000];
            case "humidity":
                return [0, 100];
            default:
                return [0, 100];
        }
    }

    private getStepsizeForCurrentDatatype(): number {
        switch (this.props.thresholds.dataType) {
            case "temperature":
                return 0.1;
            case "carbondioxide":
            case "humidity":
                return 1;
            default:
                return 1;
        }
    }

    private checkIfComfortTypeShouldBeHidden(): boolean {
        switch (this.props.thresholds.dataType) {
            case "sound":
            case "light":
                return true;
            default:
                return false;
        }
    }

    public render(): JSX.Element {
        return (
            <div className="threshold-settings-line row mt-3" hidden={this.checkIfComfortTypeShouldBeHidden()}>
                <div className="col-sm-4 mt-4" id="threshold-column">
                    <div className="mb-4">
                        <h2>{LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds[this.props.thresholds.dataType])}</h2>
                    </div>
                    <div className="mt-2">
                        <h3>{LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.setthresholds)}</h3>
                    </div>
                    <SliderComponent
                        title={LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds[this.props.thresholds.dataType])}
                        showTitle={false}
                        domain={this.getDomainForCurrentDatatype()}
                        step={this.getStepsizeForCurrentDatatype()}
                        mode={2}
                        values={this.state.currentMininumThreshold === undefined ? [this.state.currentMaximumThreshold ?? 0] : [this.state.currentMininumThreshold, this.state.currentMaximumThreshold ?? 0]}
                        ticksCount={0}
                        disabled={!this.state.sendNotificationMail}
                        onChange={this.handleThresholdValuesChange}
                    />
                </div>
                <div className="col-sm-4 mt-4" id="mail-column">
                    <div className="mail-field pr-4" id={`mail-${this.props.thresholds.dataType}`}>
                        <div className="mb-4">
                            <h3>{LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.sendnotificationsto)}</h3>
                        </div>
                        <EmailInput
                            dataTestAttribute={`${this.props.thresholds.dataType}-email`}
                            onEmailChange={this.handleAddressesChange}
                            disabled={!this.state.sendNotificationMail}
                            defaultValues={this.props.thresholds.mailAddresses?.split(';')} />
                    </div>
                </div>
                <div className="col-sm-3 ml-3 mt-5" id="send-notification-column">
                    <div className="mb-4 mt-4">
                        <h3>{LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.enableblenotifications)}</h3>
                    </div>
                    <Switch
                        onChange={this.handleSendNotificationMailChange}
                        checked={this.state.sendNotificationMail}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offColor={Colors.grey}
                        onColor={Colors.shakespeare_blue}
                        activeBoxShadow={""}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        height={40}
                        width={80}
                        handleDiameter={33}
                        className={"notification-switch"}
                    />
                </div>
            </div>
        );
    }
}