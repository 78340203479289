import Serializable from "./serializeable";
import CoreSpaceValues from "../enums/coreSpaceValues";
import ISpaceValue from "../interfaces/ISpaceValue";
import ICoreSpace from "../interfaces/ICoreSpace";

export default class CoreEntity extends Serializable {
    private propertyMapping: { [name: string]: string };
    private valueMapping: { [type: string]: ISpaceValue };

    public constructor(space: ICoreSpace) {
        super();

        this.propertyMapping = {};
        this.valueMapping = {};

        if (space.properties) {
            for (const property of space.properties) {
                this.propertyMapping[property.name] = property.value;
            }
        }

        if (space.values) {
            for (const value of space.values) {
                this.valueMapping[value.name] = value;
            }
        }
    }

    protected getCustomPropertyValue(name: string): string {
        return this.propertyMapping[name];
    }

    protected getValue(type: CoreSpaceValues): ISpaceValue {
        return this.valueMapping[CoreSpaceValues[type]];
    }
}