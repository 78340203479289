import DashboardService from "@/services/dashboardService";
import IHistoricReservation from '@/interfaces/IHistoricReservation';
import VenueProvider from "@/providers/venueProvider";
import moment from "moment";

export default class HistoricReservationService {
    private readonly dashboardService: DashboardService;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getNoShowHistoricReservationsForVenue(startDate: Date, endDate: Date, onlyNoShows: boolean = false): Promise<IHistoricReservation[]> {
        const venue = VenueProvider.getActiveVenue();
        if (venue === undefined) {
            throw new Error("Error getting venue settings: No venue found in venueprovider!");
        }

        return this.dashboardService.get<IHistoricReservation[]>(`reservations/customer/${venue.customerId}/venue/${venue.id}/from/${this.formatDate(startDate)}/to/${this.formatDate(endDate)}${onlyNoShows ? 'isNoShow=true' : ''}`);
    }

    public async getNoShowHistoricReservationsForFloor(floorId: string, startDate: Date, endDate: Date, onlyNoShows: boolean = false): Promise<IHistoricReservation[]> {
        const venue = VenueProvider.getActiveVenue();

        if (venue === undefined) {
            throw new Error("Error getting venue settings: No venue found in venueprovider!");
        }

        return this.dashboardService.get<IHistoricReservation[]>(`reservations/customer/${venue.customerId}/floor/${floorId}/from/${this.formatDate(startDate)}/to/${this.formatDate(endDate)}${onlyNoShows ? 'isNoShow=true' : ''}`);
    }

    private formatDate(date: Date): string {
        return moment(date).format("YYYY-MM-DD");
    }
}