import { Component } from "react";

import "./teaser.scss";

interface ITeaserProps {
    imagePath: string;
    title: string;
    subtitle?: string;
    paragraph1?: string;
    paragraph2title?: string;
    paragraph2?: string;
    paragraph3title?: string;
    paragraph3?: string;
    learnmoretext?: string;
    buttonLink?: string;
}

export default class Teaser extends Component<ITeaserProps> {

    public constructor(props: ITeaserProps) {
        super(props);

        this.openLink = this.openLink.bind(this);
    }

    public openLink(): void {
        window.open(this.props.buttonLink, '_blank', 'noopener');
    }

    public render(): JSX.Element {
        return (
            <div className="teaser-container" style={{ backgroundImage: `url(${this.props.imagePath})` }}>
                <div className="teaser-content">
                    <div className="teaser-title">{this.props.title}</div>
                    {this.props.subtitle && <div className="teaser-subtitle first">{this.props.subtitle}</div>}
                    {this.props.paragraph1 && <p className="teaser-text"> {this.props.paragraph1}</p>}
                    {this.props.paragraph2title && <p className="teaser-subtitle"> {this.props.paragraph2title}</p>}
                    {this.props.paragraph2 && <p className="teaser-text"> {this.props.paragraph2}</p>}
                    {this.props.paragraph3title && <p className="teaser-subtitle"> {this.props.paragraph3title}</p>}
                    {this.props.paragraph3 && <p className="teaser-text"> {this.props.paragraph3}</p>}
                    <button type="button" className="btn btn-secondary" onClick={this.openLink}>{this.props.learnmoretext}</button>
                </div>
                <div className="teaser-content-gradient"></div>
            </div>
        );
    }
}