import { Component } from "react";
import { TooltipComponent, AnimationModel } from '@syncfusion/ej2-react-popups';
import { WithTranslation, withTranslation } from 'react-i18next';

import IBaseState from "@/interfaces/IBaseState";

import infoIcon from "@/images/information-circle.svg";
import "./besenseTooltip.scss";

interface ITooltipProps extends WithTranslation {
    content: string;
}

class BeSenseTooltip extends Component<ITooltipProps, IBaseState> {
    private semiUniqueKey: string;

    public constructor(props: ITooltipProps) {
        super(props);
        const state: IBaseState = {
            loading: false
        };

        this.state = state;
        this.semiUniqueKey = `${this.props.content.substr(0, 2)}|${this.props.content.length}|${this.props.content.substr(this.props.content.length - 2)}`;
    }

    private animation: AnimationModel = {
        open: { effect: 'FadeIn', duration: 200, delay: 0 },
        close: { effect: 'FadeOut', duration: 100, delay: 0 }
    };

    public render(): JSX.Element {
        return (
            <div className="besenseTooltip-container png-hidden">
                <TooltipComponent
                    animation={this.animation}
                    className="tooltip-box" content={this.props.content}
                    width="400px"
                    offsetX={6}
                    offsetY={-9}
                    position="RightBottom">
                    <img data-tooltip={this.semiUniqueKey} src={infoIcon} />
                </TooltipComponent>
            </div>
        );
    }
}

export default withTranslation()(BeSenseTooltip);