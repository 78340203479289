enum IconType {
    temperature,
    humidity,
    co2,
    energy,
    gas,
    heat,
    pdf,
    calenderChecked,
    calenderCrossed,
    contact,
    handShake,
    contract,
    calendar,
    assetSoonAvailable,
    assetOccupied,
    assetAvailable,
    tree,
    solarpanels,
    costSavingsTotal,
    costSavingsElectricity,
    costSavingsGas,
    costSavingsHeat,
    buildingSavings,
    pigEuro,
    buildingEuro
}

export default IconType;