import * as React from "react";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";

import VenueProvider from "@/providers/venueProvider";
import LanguageProvider from "@/providers/languageProvider";
import CoreSpaceService from "@/services/coreSpaceService";
import Dictionary from "@/utils/dictionary";
import CoreSpaceIncludes from "../../../../../enums/coreSpaceIncludes";
import CoreSpaceTypes from "../../../../../enums/coreSpaceTypes";
import ICoreSpace from "../../../../../interfaces/ICoreSpace";
import translations from "@/translations/mapper";

import IWorkSpaceSettingsTypeTabProps from "./interfaces/IWorkSpaceSettingsTypeTabProps";
import IWorkSpaceSettingsTypeTabState from "./interfaces/IWorkSpaceSettingsTypeTabState";
import WorkSpaceSettingsTypeActiveTags from "./components/workSpaceSettingsTypeActiveTags";

import "./workSpaceSettingsTypeTab.scss";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import GeneralTeaser from "@/components/teaser/generalTeaser";
import links from "@/utils/links";
import RealEstateUtilizationSettingsPage from "../../realEstateUtilizationSettingsPage";

import AutodeskMap from "autodeskMap/components/autodeskMapWrapper";
import DashboardMapService from "components/dashboardMap/interfaces/DashboardMapService";
import { DashboardMapOptions } from "components/dashboardMap/interfaces/options";
import {
	IAutodeskMapService,
	IAutodeskPopupContent,
	IFloorChangeEvent,
	ISelectorEvent,
	ISpaceOnMap
} from "@beyondeyes/shared";
import WorkSpaceSettingsTypeMapOverlay from "./components/workSpaceSettingsTypeMapOverlay";
import ITableTag from "./components/interfaces/ITableTag";
import TagStyler from "@/utils/stylers/tagStyler";
import { SpaceUtils } from "utils/spaceUtils";
import WorkSpaceSettingsTypeDeletePopup from "./components/workSpaceSettingsTypeDeletePopup";
import ISpaceTagClear from "interfaces/ISpaceTagClear";
import AppEventHub, { AppEvents } from "utils/appEventHub";

class WorkSpaceSettingsTypeTab extends React.Component<IWorkSpaceSettingsTypeTabProps, IWorkSpaceSettingsTypeTabState> {
	private readonly mapService: IAutodeskMapService;
	private coreSpaceService: CoreSpaceService;
	private subscriptionValidationService: SubscriptionValidationService;

	private readonly styler: TagStyler;

	public constructor(props: any) {
		super(props);

		this.state = {
			loading: true,
			hasWorkspacesSubscription: false,
			styledMapSpaces: [],
			tableTags: [],
			tags: [],
			coreSpaces: [],
			tagsLastModified: [],
			showPopup: false,
			deletePopupVisible: false,
			deleteOptionIdentifer: "",
			deleteOptionLabel: "",
		};

		this.styler = new TagStyler();
		this.coreSpaceService = new CoreSpaceService();
		this.mapService = new DashboardMapService(new DashboardMapOptions(false, true));

		this.onFloorChangeAsync = this.onFloorChangeAsync.bind(this);
		this.initializeAsync = this.initializeAsync.bind(this);
		this.initializeWithLoadingAsync = this.initializeWithLoadingAsync.bind(this);
		this.getOptionIdentifierToSpaceMapping = this.getOptionIdentifierToSpaceMapping.bind(this);
		this.setSelectedTagOption = this.setSelectedTagOption.bind(this);
		this.getSpaceTagCounts = this.getSpaceTagCounts.bind(this);
		this.onPlaceClickAsync = this.onPlaceClickAsync.bind(this);
		this.handleRemoveOptionIdentifierClickedAsync = this.handleRemoveOptionIdentifierClickedAsync.bind(this);
		this.closeDeletePopup = this.closeDeletePopup.bind(this);
		this.handleRemoveOptionIdentifierSubmittedAsync = this.handleRemoveOptionIdentifierSubmittedAsync.bind(this);
		this.onBeforeFloorChangeAsync = this.onBeforeFloorChangeAsync.bind(this);

		AppEventHub.on(AppEvents.BuildingSelected, this.initializeWithLoadingAsync);
	}

	public async componentDidMount(): Promise<void> {
		await this.initializeWithLoadingAsync();
	}

	public componentWillUnmount(): void {
		AppEventHub.off(AppEvents.BuildingSelected, this.initializeWithLoadingAsync);
	}

	private async initializeWithLoadingAsync(): Promise<void> {
		this.setState(
			{
				loading: true,
			},
			async () => {
				await this.initializeAsync();
				this.setState({
					loading: false,
				});
			}
		);
	}

	private async initializeAsync(): Promise<void> {
		const venue = VenueProvider.getActiveVenue();
		if (!venue) {
			console.warn("No active venue found.");
			return;
		}

		this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

		const hasSubscription = this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["FindWorkspaces", "ReserveWorkspaces"]);

		this.setState({
			hasWorkspacesSubscription: hasSubscription,
		});

		if (!hasSubscription) {
			return;
		}

		const spacesCall = this.coreSpaceService.getSpacesForVenue(
			venue.id,
			[CoreSpaceTypes.Workspace],
			[CoreSpaceIncludes.Properties, CoreSpaceIncludes.Tags]
		);
		const tagsCall = this.coreSpaceService.getPossibleSpaceTags();
		const tagsLastModifiedCall = this.coreSpaceService.getSpaceTagsLastModified(venue.id);

		const [spaces, tags, lastModified] = await Promise.all([spacesCall, tagsCall, tagsLastModifiedCall]);

		this.setState(
			{
				coreSpaces: spaces,
				tags: tags,
				tagsLastModified: lastModified,
			},
			() => this.setTableTags()
		);
	}

	private async onFloorChangeAsync(eventContext: IFloorChangeEvent): Promise<void> {
		this.redraw(eventContext.renderedSpaces);
	}

	private async onBeforeFloorChangeAsync(): Promise<void> {
		this.setState({
			showPopup: false
		});
	}

	private getOptionIdentifierToSpaceMapping(): Dictionary<ICoreSpace[]> {
		const dictionary = new Dictionary<ICoreSpace[]>();

		this.state.coreSpaces
			.filter((space) => space.tags !== undefined)
			.forEach((space) => {
				space.tags!.forEach((tag) => {
					const key = tag.optionIdentifier;
					if (dictionary.containsKey(key)) {
						dictionary.item(key).push(space);
					} else {
						dictionary.add(key, [space]);
					}
				});
			});

		return dictionary;
	}

	private getSpaceTagCounts(): { selectedOption: number; otherOption: number; noOption: number } {
		const selectedOption = this.state.tableTags.find((x) => x.optionIdentifier === this.state.selectedTagOption)?.count ?? 0;
		const otherOption = this.state.tableTags
			.filter((x) => x.optionIdentifier !== this.state.selectedTagOption)
			.reduce((sum, t) => sum + (t.count ?? 0), 0);
		const noOption = this.state.coreSpaces.length - otherOption - selectedOption;

		return {
			selectedOption,
			otherOption,
			noOption,
		};
	}

	private setTableTags(setInitial: boolean = false): void {
		const tableTags: ITableTag[] = [];
		const spaceListLookup = this.getOptionIdentifierToSpaceMapping();

		this.state.tags.forEach((tag) => {
			tag.options.forEach((option) => {
				const id = option.optionIdentifier;
				const spaces = spaceListLookup.item(id);
				const count = spaces?.length ?? 0;
				const lastModified = this.state.tagsLastModified.find((x) => x.optionIdentifier === id)?.lastmodified;

				const tableTag: ITableTag = {
					name: option.option,
					count: count,
					optionIdentifier: id,
					lastModified: lastModified,
				};

				tableTags.push(tableTag);
			});
		});

		this.setState({
			tableTags: tableTags,
		});
	}

	private setSelectedTagOption(optionIdentifier: string): void {
		this.styler.setActiveTagOption(optionIdentifier);

		this.setState({
			selectedTagOption: optionIdentifier,
		});

		this.redraw(this.state.styledMapSpaces);
	}

	private async onPlaceClickAsync(eventContext: ISelectorEvent): Promise<void> {
		const mapSpaceRef = eventContext.space;

		if (!mapSpaceRef) {
			this.setState({ showPopup: false });
			return;
		}

		const space = mapSpaceRef.beSpace as ICoreSpace;

		if (!space || space.type !== CoreSpaceTypes.Workspace) {
			this.setState({ showPopup: false });
			return;
		}

		const activeId = this.state.selectedTagOption;
		const activeTag = this.state.tags.find((t) => t.options.some((o) => o.optionIdentifier === activeId));

		if (!activeTag) {
			this.setState({
				showPopup: true,
				popupContent: {
					position: { ...mapSpaceRef.position! },
					content: this.getTagPopup(space),
				},
			});
			return;
		}

		let showPopup = false;
		let content: IAutodeskPopupContent | undefined = undefined;
		const successText = LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.popup.successave);
		const errorText = LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.popup.failsave);

		if (space.tags === undefined || space.tags.length === 0) {
			const option = activeTag.options.find((t) => t.optionIdentifier === activeId);
			space.tags = [
				{
					key: activeTag.key,
					keyIdentifier: activeTag.keyIdentifier,
					option: option!.option,
					optionIdentifier: option!.optionIdentifier,
					spaceId: space.id,
				},
			];

			this.coreSpaceService
				.updateSpaceTags(space.venueId, [
					{
						keyIdentifier: activeTag.keyIdentifier,
						optionIdentifier: option!.optionIdentifier,
						spaceId: space.id,
					},
				])
				.then(() => NotificationManager.success(successText))
				.catch(() => NotificationManager.error(errorText));
		} else if (space.tags.some((t) => t.optionIdentifier === activeId)) {
			space.tags = [];

			this.coreSpaceService
				.clearSpaceTags(space.venueId, [
					{
						keyIdentifier: activeTag.keyIdentifier,
						optionIdentifier: activeId!,
						spaceId: space.id,
					},
				])
				.then(() => NotificationManager.success(successText))
				.catch(() => NotificationManager.error(errorText));
		} else {
			showPopup = true;
			content = {
				position: { ...mapSpaceRef.position! },
				content: this.getTagPopup(space),
			};
		}

		const spaces = [...this.state.styledMapSpaces.filter((s) => s.id !== space.id), mapSpaceRef];
		const coreSpaces = [...this.state.coreSpaces.filter((s) => s.id !== space.id), space];
		this.redraw(spaces);

		this.setState(
			{
				showPopup: showPopup,
				popupContent: content,
				coreSpaces: coreSpaces,
			},
			() => this.setTableTags()
		);
	}

	private getTagPopup(space: ICoreSpace): JSX.Element {
		return (
			<>
				<div className="tag-popup">
					<div className="popup-header">
						<h3>{space.name} </h3>
					</div>
					<div className="popup-body pt-3">
						{space.tags && space.tags[0] && (
							<>
								<p>{space.tags[0].option}</p>
								<p className="reminder-text">
									{LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.deleteexplanation)}
								</p>
							</>
						)}
					</div>
				</div>
			</>
		);
	}

	private async handleRemoveOptionIdentifierClickedAsync(optionIdentifier: string, optionLabel: string): Promise<void> {
		this.setState({
			deletePopupVisible: true,
			deleteOptionIdentifer: optionIdentifier,
			deleteOptionLabel: optionLabel,
		});
	}

	private redraw(spaces: ISpaceOnMap[]): void {
		const spacesWithStyle = SpaceUtils.getSpacesWithStyle(spaces, this.styler);
		this.setState({
			styledMapSpaces: spacesWithStyle,
		});
	}

	private closeDeletePopup(): void {
		this.setState({
			deletePopupVisible: false,
		});
	}

	private async handleRemoveOptionIdentifierSubmittedAsync(optionIdentifier: string): Promise<void> {
		const spaces = this.state.coreSpaces;
		const spacesToClear = spaces.filter((s) => s.tags && s.tags.some((t) => t.optionIdentifier === optionIdentifier));

		if (!spacesToClear || spacesToClear.length === 0) {
			return;
		}

		const tag = spacesToClear[0].tags!.find((t) => t.optionIdentifier === optionIdentifier);

		const ids = new Set([...spacesToClear.map((s) => s.id)]);
		const styledSpaces = this.state.styledMapSpaces.filter((s) => s.beSpace && ids.has(s.beSpace.id));

		spacesToClear.forEach((s) => (s.tags = []));
		styledSpaces.forEach((s) => ((s.beSpace as ICoreSpace).tags = []));

		const coreSpaces = spacesToClear.concat(this.state.coreSpaces.filter((s) => !ids.has(s.id)));
		const spacesToReDraw = styledSpaces.concat(this.state.styledMapSpaces.filter((s) => !s.beSpace || !ids.has(s.beSpace.id)));

		const tagClears: ISpaceTagClear[] = spacesToClear.map((s) => ({
			spaceId: s.id,
			keyIdentifier: tag!.keyIdentifier,
			optionIdentifier: optionIdentifier,
		}));

		this.coreSpaceService
			.clearSpaceTags(spacesToClear[0].venueId, tagClears)
			.then(() => NotificationManager.success(LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.successdelete)))
			.catch(() => NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.faildelete)));

		this.redraw(spacesToReDraw);

		this.setState(
			{
				coreSpaces: coreSpaces,
				deletePopupVisible: false,
			},
			() => this.setTableTags()
		);
	}

	public render(): JSX.Element {
		return (
			<RealEstateUtilizationSettingsPage>
				{!this.state.hasWorkspacesSubscription && !this.state.loading && <GeneralTeaser buttonLink={links.external.beyondeyes.realestateutilization} />}

				<div className={`main-content ${this.state.hasWorkspacesSubscription ? "d-block" : "d-none"}`} id="workspace-type-tab">
					<div className="main-content-header">
						<div className="row header-margin-bottom-paragraph">
							<div className="col-sm">
								<h1>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.title)}</h1>
								<div className="row">
									<div className="col-sm-9">
										{LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.explanation)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="main-content-body d-flex">
						<div className="w-50 pr-4">
							<WorkSpaceSettingsTypeActiveTags
								loading={this.state.loading}
								selectOptionIdentifier={this.setSelectedTagOption}
								removeOptionidentifierClicked={this.handleRemoveOptionIdentifierClickedAsync}
								tags={this.state.tableTags}
							/>
						</div>
						<div className="w-50">
							<div className="map-wrapper">
								<AutodeskMap
									onPlaceClickAsync={this.onPlaceClickAsync}
									onFloorChangedAsync={this.onFloorChangeAsync}
									spaces={this.state.styledMapSpaces}
									mapService={this.mapService}
									startWithFirstFloor={true}
									showPopup={this.state.showPopup}
									popupContent={this.state.popupContent}
									onBeforeFloorChangeAsync={this.onBeforeFloorChangeAsync}
								/>
								<WorkSpaceSettingsTypeMapOverlay
									loading={this.state.loading}
									selectedAssetCount={this.getSpaceTagCounts().selectedOption}
									otherOptionAssetCount={this.getSpaceTagCounts().otherOption}
									noOptionAssetCount={this.getSpaceTagCounts().noOption}
								/>
							</div>
						</div>
					</div>
				</div>
				<WorkSpaceSettingsTypeDeletePopup
					optionLabel={this.state.deleteOptionLabel}
					optionIdentifier={this.state.deleteOptionIdentifer}
					showPopup={this.state.deletePopupVisible}
					cancelDelete={this.closeDeletePopup}
					submitDelete={this.handleRemoveOptionIdentifierSubmittedAsync}
				/>
			</RealEstateUtilizationSettingsPage>
		);
	}
}

export default withTranslation()(WorkSpaceSettingsTypeTab);
