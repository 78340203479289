import * as React from "react";

import { IFloorChangeEvent, ISelectorEvent, IAutodeskMapService, IFloor, IVector } from "@beyondeyes/shared";
import IOccupancyMapProps from "../interfaces/IOccupancyMapProps";
import MapDetails from "../components/mapDetails";
import { withTelemetry } from "@/services/telemetryService";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import VenueProvider from "@/providers/venueProvider";
import IOccupancyMapState from "../interfaces/IOccupancyMapState";
import CoreSpace from "models/coreSpace";
import { DashboardMapOptions } from "components/dashboardMap/interfaces/options";
import OccupancyStyler from "@/utils/stylers/occupancyStyler";
import AutodeskMap from "autodeskMap/components/autodeskMapWrapper";
import DashboardMapService from "components/dashboardMap/interfaces/DashboardMapService";
import { MapPopup } from "../components/mapPopup";
import AppEventHub, { AppEvents } from "utils/appEventHub";
import { SpaceUtils } from "utils/spaceUtils";
import "./mapPage.scss";

class MapPage extends React.Component<IOccupancyMapProps, IOccupancyMapState> {
	private subscriptionValidationService: SubscriptionValidationService;
	private readonly occupancyStyler: OccupancyStyler;
	private readonly mapService: IAutodeskMapService;

	private currentFloor: IFloor | undefined = undefined;
	private refreshIntervalId: NodeJS.Timeout;

	public constructor(props: IOccupancyMapProps) {
		super(props);

		this.onPlaceClickAsync = this.onPlaceClickAsync.bind(this);
		this.onFloorChangeAsync = this.onFloorChangeAsync.bind(this);
		this.refreshSpacesAsync = this.refreshSpacesAsync.bind(this);
		this.onBeforeFloorChangeAsync = this.onBeforeFloorChangeAsync.bind(this);
		this.showPopup = this.showPopup.bind(this);
		this.onMapLoadFailure = this.onMapLoadFailure.bind(this);

		this.occupancyStyler = new OccupancyStyler();
		this.mapService = new DashboardMapService(new DashboardMapOptions());

		const state: IOccupancyMapState = {
			loading: true,
			spaces: [],
			activeTab: "map",
			showPopup: false,
		};

		this.state = state;
	}

	public async componentDidMount(): Promise<void> {
		this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

		this.refreshIntervalId = setInterval(this.refreshSpacesAsync, 60000);
		AppEventHub.on(AppEvents.MapRefreshed, this.refreshSpacesAsync);
	}

	public componentWillUnmount(): void {
		clearInterval(this.refreshIntervalId);
		AppEventHub.off(AppEvents.MapRefreshed, this.refreshSpacesAsync);
	}

	private async refreshSpacesAsync(): Promise<void> {
		if (!this.currentFloor) {
			return;
		}

		const floorSpaces = await this.mapService.getFloorSpacesAsync(this.currentFloor);
		const spacesOnMap = this.state.spaces?.map((space) => {
			const beSpace = floorSpaces.find((fs) => fs.id === space.id);
			return { ...space, beSpace: beSpace };
		});

		this.setState({
			spaces: SpaceUtils.getSpacesWithStyle(spacesOnMap, this.occupancyStyler),
			showPopup: false,
		});
	}

	private async onPlaceClickAsync(eventContext: ISelectorEvent): Promise<void> {
		const space = eventContext.space?.beSpace;

		if (!space) {
			this.setState({ showPopup: false });
			return;
		}

		const venue = VenueProvider.getActiveVenue();
		let popupShouldIncludeCleaning = false;

		if (venue) {
			const hasMotion = this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["Motion"]);
			const hasBeClean = this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEClean"]);

			popupShouldIncludeCleaning = hasMotion && hasBeClean;
		}

		const mapPopup = new MapPopup(
			space as CoreSpace,
			{
				showCleaningTime: popupShouldIncludeCleaning,
				showCleaningStatus: false,
				showComfortValues: true,
				showOccupancyTile: true
			},
			() => this.setState({ showPopup: false }));

		this.showPopup(eventContext.space!.position, mapPopup.getHtmlToRender());
	}

	private showPopup(position: IVector, content: JSX.Element): void {
		this.setState({
			popupContent: {
				content: content,
				position: { ...position },
			},
			showPopup: true,
		});
	}

	private async onBeforeFloorChangeAsync(): Promise<void> {
		this.setState({
			showPopup: false
		});
	}

	private onFloorChangeAsync(eventContext: IFloorChangeEvent): void {
		this.currentFloor = eventContext.floor;
		this.setState({
			spaces: SpaceUtils.getSpacesWithStyle(eventContext.renderedSpaces, this.occupancyStyler),
			loading: false,
		});
	}

	private get floorSpaces(): CoreSpace[] {
		return this.state.spaces?.map((spaces) => spaces.beSpace! as CoreSpace) ?? [];
	}

	private onMapLoadFailure(): void {
		this.setState({
			loading: false,
			spaces: []
		});
	}

	public render(): JSX.Element {
		return (
			<span className="map-page">
				<div id="map-page-wrapper">
					<AutodeskMap
						onFloorChangedAsync={this.onFloorChangeAsync}
						onPlaceClickAsync={this.onPlaceClickAsync}
						spaces={this.state.spaces}
						mapService={this.mapService}
						startWithFirstFloor={true}
						showPopup={this.state.showPopup}
						popupContent={this.state.popupContent}
						onBeforeFloorChangeAsync={this.onBeforeFloorChangeAsync}
						onMapLoadFailure={this.onMapLoadFailure}
					/>
				</div>
				<MapDetails loading={this.state.loading} spaces={this.floorSpaces} />
			</span>
		);
	}
}

export default withTelemetry(MapPage, "MapPage");
