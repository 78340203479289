import * as React from "react";
import { withTranslation } from 'react-i18next';

import IVenueSavingsTileCollectionProps from "./interfaces/IVenueSavingsTileCollectionProps";
import IVenueSavingsTileCollectionState from "./interfaces/IVenueSavingsTileCollectionState";
import LanguageProvider from "@/providers/languageProvider";
import Unit from "@/components/tile/enums/unit";
import Tile from "@/components/tile/tile";
import IconType from "@/components/tile/enums/iconType";

class VenueSavingsTileCollection extends React.Component<IVenueSavingsTileCollectionProps, IVenueSavingsTileCollectionState> {
    public constructor(props: any) {
        super(props);
    }

    private get hasGasSubscription(): boolean {
        return this.props.contracts.find(c => c.type === 'Gas') !== undefined;
    }

    private get hasElectricitySubscription(): boolean {
        return this.props.contracts.find(c => c.type === 'Electricity') !== undefined;
    }

    private get hasHeatSubscription(): boolean {
        return this.props.contracts.find(c => c.type === 'Heat') !== undefined;
    }

    public render(): JSX.Element {
        return (
            <>
                <h3>
                    {LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.totalsavingscontractyear")}
                </h3>
                <div className="row">
                    <Tile
                        unit={Unit.percentage}
                        icon={IconType.buildingSavings}
                        subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.totalenergysavingsuntilnow")}
                        totalNumberOfTileColumns={2}
                        value={Math.round(this.props.data.currentSavingsPercentage * 10) / 10}
                        tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.totalenergysavingsuntilnow")}
                    />
                    <Tile
                        unit={Unit.euro}
                        icon={IconType.costSavingsTotal}
                        subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.indicationcostreduction")}
                        totalNumberOfTileColumns={2}
                        value={Math.round(this.props.data.savingsInCurrency)}
                        tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.indicationcostreduction")}
                    />
                    <Tile
                        unit={Unit.ton}
                        icon={IconType.co2}
                        subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.co2reduction")}
                        totalNumberOfTileColumns={2}
                        value={Math.round(this.props.data.savingsInCo2Kg / 10) / 100}
                        tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.co2reduction")}
                    />
                </div>
                {this.hasElectricitySubscription && <>
                    <div className="content-divider" />
                    <h3>
                        {LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.electricoverviewcontractyear")}
                    </h3>
                    <div className="row">
                        <Tile
                            unit={Unit.percentage}
                            icon={IconType.energy}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.electricitysavings")}
                            totalNumberOfTileColumns={2}
                            value={this.hasElectricitySubscription ? Math.round(this.props.data.electricitySavingsPercentage * 10) / 10 : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.electricitysavings-percentage")}
                        />
                        <Tile
                            unit={Unit.kWh}
                            icon={IconType.costSavingsElectricity}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.electricitysavings")}
                            totalNumberOfTileColumns={2}
                            value={this.hasElectricitySubscription ? Math.round(this.props.data.electricitySavingsInkWh) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.electricitysavings-kwh")}
                        />
                        <Tile
                            unit={Unit.kWh}
                            icon={IconType.energy}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.electricityconsumption")}
                            totalNumberOfTileColumns={2}
                            value={this.hasElectricitySubscription && this.props.data.electricityUsageInkWh !== null ? Math.round(this.props.data.electricityUsageInkWh) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.electricityconsumption")}
                        />
                    </div>
                </>}
                {this.hasGasSubscription && <>
                    <div className="content-divider" />
                    <h3>
                        {LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.gasoverviewcontractyear")}
                    </h3>
                    <div className="row">
                        <Tile
                            unit={Unit.percentage}
                            icon={IconType.gas}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.gassavings")}
                            totalNumberOfTileColumns={2}
                            value={this.hasGasSubscription ? Math.round(this.props.data.gasSavingsPercentage * 10) / 10 : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.gassavings-percentage")}
                        />
                        <Tile
                            unit={Unit.m3}
                            icon={IconType.costSavingsGas}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.gassavings")}
                            totalNumberOfTileColumns={2}
                            value={this.hasGasSubscription ? Math.round(this.props.data.gasSavingsInM3) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.gassavings")}
                        />
                        <Tile
                            unit={Unit.m3}
                            icon={IconType.gas}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.gasconsumption")}
                            totalNumberOfTileColumns={2}
                            value={this.hasGasSubscription && this.props.data.gasUsageInM3 !== null ? Math.round(this.props.data.gasUsageInM3) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.gasconsumption")}
                        />
                    </div>
                </>}
                {this.hasHeatSubscription && <>
                    <div className="content-divider" />
                    <h3>
                        {LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.heatoverviewcontractyear")}
                    </h3>
                    <div className="row">
                        <Tile
                            unit={Unit.percentage}
                            icon={IconType.heat}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.heatsavings")}
                            totalNumberOfTileColumns={2}
                            value={this.hasHeatSubscription ? Math.round(this.props.data.heatSavingsPercentage * 10) / 10 : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.heatsavings-percentage")}
                        />
                        <Tile
                            unit={Unit.GJ}
                            icon={IconType.costSavingsHeat}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.heatsavings")}
                            totalNumberOfTileColumns={2}
                            value={this.hasHeatSubscription ? Math.round(this.props.data.heatSavingsInGj) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.heatsavings")}
                        />
                        <Tile
                            unit={Unit.GJ}
                            icon={IconType.heat}
                            subtitle={LanguageProvider.getTranslation("pages.energy.tabs.overview.venuesavings.heatconsumption")}
                            totalNumberOfTileColumns={2}
                            value={this.hasHeatSubscription && this.props.data.heatUsageInGj !== null ? Math.round(this.props.data.heatUsageInGj) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.venue-savings.heatconsumption")}
                        />
                    </div>
                </>}
            </>
        );
    }
}

export default withTranslation()(VenueSavingsTileCollection);