import IBaseState from "@/interfaces/IBaseState";
import IEnergyPortfolioLine from "@/interfaces/energy/IEnergyPortfolioLine";
import { TableColumn } from "react-data-table-component";

export interface IEnergyPortfolioPageState extends IBaseState {
    data: IEnergyPortfolioLine[];
    columns: TableColumn<IEnergyPortfolioLine>[];
    selectedType: SelectedPortfolioTableType;
    showGasSelection: boolean;
    showElectricitySelection: boolean;
    showHeatSelection: boolean;
}

export enum SelectedPortfolioTableType {
    Contract,
    Currency,
    Electricity,
    Gas,
    Heat
}