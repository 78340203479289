import * as React from "react";
import { Handles, Tracks, Ticks, Slider } from 'react-compound-slider';

import Handle from "./handle";
import Track from "./track";
import Tick from "./tick";

import "./sliderComponent.scss";

interface ISliderProps {
    title: string;
    domain: number[];
    values: number[];
    step: number;
    mode?: 1 | 2 | 3;
    ticksCount: number;
    onChange: (values: readonly number[]) => void;
    showTitle: boolean;
    sliderTrackClassName?: string;
    customHandleDisplay?: (handleValue: number) => string;
    disabled?: boolean;
}

export default class SliderComponent extends React.Component<ISliderProps> {

    public constructor(props: ISliderProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <>
                {this.props.showTitle && <span className="slider-title">{this.props.title}</span>}
                <Slider
                    className={`slider-style ${this.props.disabled ? 'disabled' : ''}`}
                    domain={this.props.domain}
                    values={this.props.values}
                    step={this.props.step}
                    mode={this.props.mode}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                >
                    <div className={`rail-style ${this.props.sliderTrackClassName}`} />
                    <Handles>
                        {({ handles, getHandleProps }): JSX.Element => (
                            <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        getHandleProps={getHandleProps}
                                        customHandleDisplay={this.props.customHandleDisplay}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks left={this.props.values.length === 1} right={false}>
                        {({ tracks, getTrackProps }): JSX.Element => (
                            <div className={`slider-tracks ${this.props.sliderTrackClassName} `}>
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                    {this.props.ticksCount !== 0 && <Ticks count={this.props.ticksCount}>
                        {({ ticks }): JSX.Element => (
                            <div className="slider-ticks">
                                {ticks.map(tick => (
                                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                                ))}
                            </div>
                        )}
                    </Ticks>}
                </Slider>
            </>
        );
    }
}