import React from "react";
import { ChartOptions } from "chart.js";
import INoShowGraphProps from "./interfaces/INoShowGraphProps";

import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";
import IBeSenseChartData from "@/components/graphs/interfaces/IBeSenseChartData";
import moment from "moment";
import IHistoricReservationsGroup from "../interfaces/IHistoricReservationsGroup";

import LineGraph from "@/components/graphs/lineGraph";
import Colors from "@/styles/colors";

export default class NoShowAveragesInterval extends React.Component<INoShowGraphProps> {

    public constructor(props: INoShowGraphProps) {
        super(props);
    }

    private getNoShowIntervalData(): IBeSenseChartData {
        const noShows = this.props.historicReservations.filter(r => r.isNoShow);

        const groupedReservations = noShows.reduce((intervalCategories, reservation) => {
            let interval = moment(reservation.reservationStart).diff(reservation.reservationCreatedOn, 'months', true);
            let intervalCategory: IHistoricReservationsGroup;
            if (interval > 3) {
                intervalCategory = intervalCategories[0];
            }
            else if (interval > 1) {
                intervalCategory = intervalCategories[1];
            }

            else {
                interval = moment(reservation.reservationStart).diff(reservation.reservationCreatedOn, 'days', true);
                if (interval > 7) {
                    intervalCategory = intervalCategories[2];
                }
                else if (interval > 1) {
                    intervalCategory = intervalCategories[3];
                }
                else {
                    intervalCategory = intervalCategories[4];
                }
            }

            intervalCategory.historicReservations.push(reservation);

            return intervalCategories;
        }, [{
            key: ">3 months",
            historicReservations: []
        },
        {
            key: ">1 month",
            historicReservations: []
        },
        {
            key: ">1 week",
            historicReservations: []
        },
        {
            key: ">1 day",
            historicReservations: []
        },
        {
            key: "<1 day",
            historicReservations: []
        }] as IHistoricReservationsGroup[]);

        const workSpaceNoShowsCount = groupedReservations.map(g => g.historicReservations.length);

        const noShowIntervalData: IBeSenseChartData = {
            labels: [
                LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.intervallabels.threemonths),
                LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.intervallabels.onemonth),
                LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.intervallabels.oneweek),
                LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.intervallabels.morethanoneday),
                LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.intervallabels.lessthanoneday)
            ],
            datasets: [
                {
                    label: "No-shows",
                    backgroundColor: 'rgba(67, 189, 207, 0.4)',
                    borderColor: Colors.shakespeare_blue,
                    borderWidth: 1,
                    pointBackgroundColor: Colors.shakespeare_blue,
                    data: workSpaceNoShowsCount
                }
            ]
        };

        return noShowIntervalData;
    }

    private getNoShowIntervalOptions(): ChartOptions {
        const noShowTimesOptions: ChartOptions = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                    },
                    gridLines: {
                        color: Colors.white,
                        zeroLineColor: Colors.alto_grey
                    }
                }],
                yAxes: [{
                    id: 'bar',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        color: Colors.white,
                        zeroLineColor: Colors.alto_grey
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true
                    },
                    stacked: false
                }]
            },
            maintainAspectRatio: true,
            legend: {
                display: false
            },
            plugins: {
                datalabels: {
                    display: true,
                    anchor: 'end',
                    color: Colors.blue_grey,
                    align: 'top',
                    font: {
                        size: 8
                    },
                    clamp: true
                }
            },
            layout: {
                padding: {
                    top: 20
                }
            },
            title: {
                display: false,
                text: "no-show-intervals"
            }
        };

        return noShowTimesOptions;
    }

    public render(): JSX.Element {
        return (
            <div className="no-show-averages-component" id="no-show-interval">
                <div className="no-show-graph">
                    <LineGraph testKey={"no-show-averages-interval"}  data={this.getNoShowIntervalData()} options={this.getNoShowIntervalOptions()} />
                </div>
                <div className="no-show-averages-title">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.noshowinterval)}</div>
                <div className="no-show-averages-subtitle">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.noshowtimebetween)}</div>
            </div>
        );
    }

}