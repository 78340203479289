import CoreSpaceDatatypes from '@/enums/coreSpaceDatatypes';
import CoreSpaceIncludes from '@/enums/coreSpaceIncludes';
import CoreSpaceTypes from '@/enums/coreSpaceTypes';
import ICoreSpace from '@/interfaces/ICoreSpace';
import ISpaceRestriction from '@/interfaces/ISpaceRestriction';
import ISpaceTagClear from '@/interfaces/ISpaceTagClear';
import ISpaceTagLastModified from '@/interfaces/ISpaceTagLastModified';
import ISpaceTagUpdate from '@/interfaces/ISpaceTagUpdate';
import ISpaceTagWithOptions from '@/interfaces/ISpaceTagWithOptions';
import CoreSpace from '@/models/coreSpace';
import DashboardService from './dashboardService';

export default class CoreSpaceService {
    private readonly dashboardService: DashboardService;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getVenues(skipCacheCheck: boolean = false): Promise<CoreSpace[]>{
        let requestUrl = `spaces/venues`;
        const includes = [CoreSpaceIncludes.Properties, CoreSpaceIncludes.DataTypes];
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, undefined);

        const spaces = this.dashboardService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return (await spaces).map(s => new CoreSpace(s));
    }

    public async getVenueById(venueId: string, includes?: CoreSpaceIncludes[], skipCacheCheck: boolean = false): Promise<CoreSpace>{
        let requestUrl = `spaces/venue/${venueId}`;
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, undefined);

        return this.dashboardService.get<ICoreSpace>(requestUrl, skipCacheCheck).then(s => new CoreSpace(s));
    }

    public async getChildSpacesByType(venueId: string, spaceId: string, types?: CoreSpaceTypes[], includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], skipCacheCheck: boolean = false, includeRootSpace: boolean = false): Promise<CoreSpace[]> {

        let requestUrl = `spaces/venue/${venueId}/spaces/${spaceId}/descendants`;
        requestUrl += this.constructSpacesQueryParameters(types, includes, dataTypes, includeRootSpace);

        const spaces = await this.dashboardService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return spaces.map(s => new CoreSpace(s));
    }

    public async getSpaceById(venueId: string, spaceId: string, includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], skipCacheCheck: boolean = false): Promise<CoreSpace> {
        let requestUrl = `spaces/venue/${venueId}/spaces/${spaceId}`;
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, dataTypes);

        return this.dashboardService.get<ICoreSpace>(requestUrl, skipCacheCheck).then(s => new CoreSpace(s));
    }

    public async getSpacesForVenue(venueId: string, types?: CoreSpaceTypes[], includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], skipCacheCheck: boolean = false): Promise<CoreSpace[]> {
        let requestUrl = `spaces/venue/${venueId}/spaces`;
        requestUrl += this.constructSpacesQueryParameters(types, includes, dataTypes);

        const spaces = await this.dashboardService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return spaces.map(s => new CoreSpace(s));
    }

    public async getFloorsForVenue(venueId: string, includes?: CoreSpaceIncludes[], skipCacheCheck: boolean = false): Promise<CoreSpace[]> {
        let requestUrl = `spaces/venue/${venueId}/floors`;
        requestUrl += this.constructSpacesQueryParameters(undefined, includes, undefined);

        const spaces = await this.dashboardService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return spaces.map(s => new CoreSpace(s));
    }

    public async getSpacesForFloor(venueId: string, floorId: string, types?: CoreSpaceTypes[], includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], skipCacheCheck: boolean = false): Promise<CoreSpace[]> {
        let requestUrl = `spaces/venue/${venueId}/floors/${floorId}/spaces`;
        requestUrl += this.constructSpacesQueryParameters(types, includes, dataTypes);

        const spaces = await this.dashboardService.get<ICoreSpace[]>(requestUrl, skipCacheCheck);
        return spaces.map(s => new CoreSpace(s));
    }

    public async updateSpaceRestrictions(venueId: string, restrictions: ISpaceRestriction[]): Promise<Response> {
        return this.dashboardService.patch(`spaces/venue/${venueId}/restrictions`, restrictions);
    }

    public async updateSpaceTags(venueId: string, tags: ISpaceTagUpdate[]): Promise<Response> {
        return this.dashboardService.patch(`spaces/venue/${venueId}/tags`, tags);
    }

    public async clearSpaceTags(venueId: string, tags: ISpaceTagClear[]): Promise<Response> {
        return this.dashboardService.patch(`spaces/venue/${venueId}/tags/clear`, tags);
    }

    public async getPossibleSpaceTags(): Promise<ISpaceTagWithOptions[]> {
        return this.dashboardService.get<ISpaceTagWithOptions[]>(`spaces/tags`);
    }

    public async getSpaceTagsLastModified(venueId: string): Promise<ISpaceTagLastModified[]> {
        return this.dashboardService.get<ISpaceTagLastModified[]>(`spaces/venue/${venueId}/tags/lastmodified`, true);
    }

    private constructSpacesQueryParameters(types?: CoreSpaceTypes[], includes?: CoreSpaceIncludes[], dataTypes?: CoreSpaceDatatypes[], includeRootSpace: boolean = false): string {
        const queryParameters: string[] = [];

        if (types) {
            types.forEach(type => {
                queryParameters.push(`types=${type}`);
            });
        }

        if (includes) {
            includes.forEach(include => {
                queryParameters.push(`includes=${include}`);
            });
        }

        if (dataTypes) {
            dataTypes.forEach(dataType => {
                queryParameters.push(`dataTypes=${dataType}`);
            });
        }

        queryParameters.push(`includeRootSpace=${(includeRootSpace) ? "True": "False"}`);

        if (queryParameters.length > 0) {
            const combinedParameters = queryParameters.join('&');
            return `?${combinedParameters}`;
        }
        else {
            return "";
        }
    }
}