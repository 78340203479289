import * as React from "react";
import { SliderItem } from "react-compound-slider";

interface ITickProps {
    tick: SliderItem;
    count: number;
}

export default class Tick extends React.Component<ITickProps> {

    public constructor(props: ITickProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div>
                <div
                    className="tick-wrapper"
                    style={{
                        left: `${this.props.tick.percent}%`,
                    }}
                />
                <div
                    className="tick-style"
                    style={{
                        marginLeft: `${-(100 / this.props.count) / 2}%`,
                        width: `${100 / this.props.count}%`,
                        left: `${this.props.tick.percent}%`,
                    }}
                >
                    {this.props.tick.value}
                </div>
            </div>
        );
    }
}