import * as React from "react";
import Switch from "react-switch";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { Modal } from "react-bootstrap";
import { PulseLoader } from "react-spinners";

import Cross_Cancel from "@/images/Cross_Cancel.svg";
import SaveButton from "@/images/save-icon-white.svg";
import "./sanitarySettingsTab.scss";
import "./cleaningSettingsModal.scss";

import VenueProvider from "@/providers/venueProvider";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import Dictionary from "@/utils/dictionary";
import CoreSpaceService from "@/services/coreSpaceService";
import ISanitarySettingsProps from "./interfaces/ISanitarySettingsProps";
import ISanitarySettingsState from "./interfaces/ISanitarySettingsState";
import SliderComponent from "@/components/slider/sliderComponent";

import IThreshold from "../../../../../interfaces/IThreshold";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import links from "@/utils/links";

import AutodeskMap from "autodeskMap/components/autodeskMapWrapper";
import { IAutodeskMapService, ISelectorEvent } from "@beyondeyes/shared";
import { ICustomFloorChangeEvent } from "@/autodeskMap/interfaces/IAutodeskMapWrapperProps";

import NotificationStyler from "@/utils/stylers/notificationStyler";
import DashboardMapService from "components/dashboardMap/interfaces/DashboardMapService";
import { DashboardMapOptions } from "components/dashboardMap/interfaces/options";
import AppEventHub, { AppEvents } from "utils/appEventHub";
import CoreSpace from "models/coreSpace";
import RealEstateUtilizationSettingsPage from "../../realEstateUtilizationSettingsPage";
import CoreSpaceIncludes from "enums/coreSpaceIncludes";
import GeneralTeaser from "components/teaser/generalTeaser";
import { SpaceUtils } from "utils/spaceUtils";
import Colors from "@/styles/colors";

class SanitarySettingsTab extends React.Component<ISanitarySettingsProps, ISanitarySettingsState> {
	private readonly notificationStyler: NotificationStyler;
	private readonly mapService: IAutodeskMapService;

	private readonly coreSpaceService: CoreSpaceService;
	private subscriptionValidationService: SubscriptionValidationService;

	private venueSanitaryThresholdLookup: Dictionary<IThreshold>;

	public constructor(props: ISanitarySettingsProps) {
		super(props);

		this.notificationStyler = new NotificationStyler();

		this.mapService = new DashboardMapService(new DashboardMapOptions(true));

		this.onFloorChangeAsync = this.onFloorChangeAsync.bind(this);
		this.onPlaceClickAsync = this.onPlaceClickAsync.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.coreSpaceService = new CoreSpaceService();

		this.handleThresholdValuesChange = this.handleThresholdValuesChange.bind(this);
		this.handleEnabledNotificationChange = this.handleEnabledNotificationChange.bind(this);
		this.handleApplyToAll = this.handleApplyToAll.bind(this);
		this.onVenueChangeAsync = this.onVenueChangeAsync.bind(this);
		this.getDefaultThreshold = this.getDefaultThreshold.bind(this);
		this.getCountOfFloorSpacesWithGivenNotificationEnabled = this.getCountOfFloorSpacesWithGivenNotificationEnabled.bind(this);
		this.initializeSubscriptions = this.initializeSubscriptions.bind(this);

		const state: ISanitarySettingsState = {
			loading: true,
			venueSanitaryThresholds: [],
			showModal: false,
			selectedSpaceName: "",
			selectedSpaceId: "",
			selectedThreshold: this.getDefaultThreshold(),
			applyToAll: false,
			hasPaperbinIncidentsSubscription: false,
			hasToiletIncidentsSubscription: false,
		};

		this.state = state;
		this.venueSanitaryThresholdLookup = new Dictionary<IThreshold>();
	}

	public async componentDidMount(): Promise<void> {
		this.setState({
			loading: true,
		});

		this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

		await this.onVenueChangeAsync();

		this.setState({
			loading: false,
		});

		AppEventHub.on(AppEvents.BuildingSelected, async () => {
			await this.onVenueChangeAsync();
		});
	}

	public componentWillUnmount(): void {
		// Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
		AppEventHub.off(AppEvents.BuildingSelected, async () => {
			await this.onVenueChangeAsync();
		});
	}

	private async onVenueChangeAsync(): Promise<void> {
		this.initializeSubscriptions();

		await this.setSanitaryThresholdsAsync();
		await this.initializeThresholdsAsync();
	}

	private initializeSubscriptions(): void {
		const venue = VenueProvider.getActiveVenue();
		let hasToiletIncidentsSubscription = false;
		let hasPaperbinIncidentsSubscription = false;

		if (venue && this.subscriptionValidationService) {
			hasToiletIncidentsSubscription = this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["ToiletIncidents"])
			hasPaperbinIncidentsSubscription = this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["PaperbinIncidents"])
		}

		this.setState({
			hasToiletIncidentsSubscription: hasToiletIncidentsSubscription,
			hasPaperbinIncidentsSubscription: hasPaperbinIncidentsSubscription
		});
	}

	private async setSanitaryThresholdsAsync(): Promise<void> {
		const thresholds = await this.props.getThresholds();

		this.setState({
			venueSanitaryThresholds: thresholds,
		});
	}

	private async onPlaceClickAsync(eventContext: ISelectorEvent): Promise<void> {
		const space = eventContext.space?.beSpace;
		if (!space || !this.notificationStyler.isSpaceNotifiable(space.id)) {
			return;
		}

		const threshold = this.venueSanitaryThresholdLookup.item(space.id) || this.getDefaultThreshold(space.id);
		const copy: IThreshold = {
			assetId: threshold.assetId,
			notificationEnabled: threshold.notificationEnabled,
			threshold: threshold.threshold,
		};

		this.setState({
			showModal: true,
			selectedSpaceName: space.name,
			selectedSpaceId: space.id,
			selectedThreshold: copy,
		});
	}

	private async onFloorChangeAsync(eventContext: ICustomFloorChangeEvent): Promise<void> {

		const relevantSpaces = this.props.tabName === "toiletstab" ? eventContext.renderedSpaces : eventContext.pointsOfInterest;

		this.setState(
			{
				spaces: SpaceUtils.getSpacesWithStyle(relevantSpaces, this.notificationStyler),
			},
			this.forceUpdate
		);
	}

	private async initializeThresholdsAsync(): Promise<void> {
		const activeVenue = VenueProvider.getActiveVenue();
		if (!activeVenue) {
			return;
		}

		this.venueSanitaryThresholdLookup.clear();

		const venueFloors = await this.coreSpaceService.getFloorsForVenue(activeVenue.id);

		const floorSpacesCalls = venueFloors.map(async (floor) => {
			const spaces = await this.coreSpaceService.getSpacesForFloor(
				activeVenue.id,
				floor.id,
				undefined,
				[CoreSpaceIncludes.DataTypes],
				[this.props.dataType]
			);
			spaces.forEach((space) => {
				const threshold = this.state.venueSanitaryThresholds.find((t) => t.assetId === space.id) || this.getDefaultThreshold(space.id);
				this.venueSanitaryThresholdLookup.add(space.id, threshold);
				this.notificationStyler.setNotificationStylingForSpace(space.id, threshold.notificationEnabled);
			});
		});

		await Promise.all(floorSpacesCalls);
	}

	private handleThresholdValuesChange(values: readonly number[]): void {
		const threshold = this.state.selectedThreshold;
		threshold.threshold = values[0];
		this.setState({
			selectedThreshold: threshold,
		});
	}

	private handleEnabledNotificationChange(checked: boolean): void {
		const threshold = this.state.selectedThreshold;
		threshold.notificationEnabled = checked;
		this.setState({
			selectedThreshold: threshold,
		});
	}

	private handleApplyToAll(event: any): void {
		this.setState({
			applyToAll: !this.state.applyToAll,
		});
	}

	private async handleSubmit(): Promise<void> {
		const activeVenue = VenueProvider.getActiveVenue();
		if (!activeVenue) {
			return;
		}

		let result: Response;
		if (this.state.applyToAll) {
			const assetIds = this.venueSanitaryThresholdLookup.getKeys();

			const updatedThresholds: IThreshold[] = assetIds.map((assetId) => ({
				assetId: assetId,
				notificationEnabled: this.state.selectedThreshold.notificationEnabled,
				threshold: this.state.selectedThreshold.threshold,
			}));

			result = await this.props.upsertThresholds(updatedThresholds);
			if (result.status === 204) {
				updatedThresholds.forEach((t) => {
					this.venueSanitaryThresholdLookup.add(t.assetId, t);
					this.notificationStyler.setNotificationStylingForSpace(t.assetId, t.notificationEnabled);
				});
			}
		} else {
			const threshold = this.state.selectedThreshold;
			result = await this.props.upsertThresholds([threshold]);
			if (result.status === 204) {
				this.venueSanitaryThresholdLookup.add(threshold.assetId, threshold);
				this.notificationStyler.setNotificationStylingForSpace(threshold.assetId, threshold.notificationEnabled);
			}
		}

		result.status === 204
			? NotificationManager.success(LanguageProvider.getTranslation(translations.pages.settings.workspaces.changessaved))
			: NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.workspaces.erroronsave));

		const threshold = this.getDefaultThreshold();
		this.setState({
			showModal: false,
			selectedThreshold: threshold,
			spaces: SpaceUtils.getSpacesWithStyle(this.state.spaces, this.notificationStyler)
		});
	}

	private handleClose(): void {
		this.setState({
			showModal: false,
		});
	}

	private getDefaultThreshold(spaceId?: string): IThreshold {
		return {
			assetId: spaceId || "",
			notificationEnabled: this.props.defaultThreshold.notificationEnabled,
			threshold: this.props.defaultThreshold.threshold,
		};
	}

	private get spacesOnMap(): CoreSpace[] {
		return this.state.spaces?.map((s) => s.beSpace! as CoreSpace) ?? [];
	}

	private getCountOfFloorSpacesWithGivenNotificationEnabled(notificationEnabled: boolean): number {
		const floorSpaces = this.spacesOnMap;
		const floorThresholds = this.venueSanitaryThresholdLookup.getValues().filter((t) => floorSpaces.find((fs) => fs.id === t.assetId));

		return floorThresholds.filter((t) => t.notificationEnabled === notificationEnabled).length;
	}

	public render(): JSX.Element {

		const hasValidSubscription = this.state.hasPaperbinIncidentsSubscription || this.state.hasToiletIncidentsSubscription;

		return (
			<RealEstateUtilizationSettingsPage>
				{!this.state.loading && !hasValidSubscription &&
					<GeneralTeaser buttonLink={links.external.beyondeyes.realestateutilization} />
				}
				<div className={`main-content ${hasValidSubscription ? "d-block" : "d-none"}`}>
					<div className="main-content-header">
						<div className="row header-margin-bottom-paragraph">
							<div className="col-sm">
								<h1>{LanguageProvider.getTranslation("pages.settings.cleaning." + this.props.tabName + ".title")}</h1>
								<div className="row">
									<div className="col-sm-9">
										{LanguageProvider.getTranslation("pages.settings.cleaning." + this.props.tabName + ".instruction")}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="main-content-body">
						<div className="row">
							<div className="col-sm">
								<div id="map-sanitary-container">
									<AutodeskMap
										onFloorChangedAsync={this.onFloorChangeAsync}
										onPlaceClickAsync={this.onPlaceClickAsync}
										spaces={this.state.spaces}
										mapService={this.mapService}
										startWithFirstFloor={true}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-0 mb-2 ml-5 mr-5 pt-3 row">
						<div id="thresholds-info">
							{this.state.loading && (
								<div id="cleaning-pulse-loader">
									<PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.state.loading} />
								</div>
							)}
							{!this.state.loading && (
								<div className="align-self-center">
									<div className="thresholds-info-container">
										<div className="thresholds-info-row first-line">
											<div className="info-circle" id="available" />
											<span className="thresholds-info-count">
												{this.getCountOfFloorSpacesWithGivenNotificationEnabled(true)}
											</span>
										</div>
										<div className="thresholds-info-row second-line">
											{" "}
											{LanguageProvider.getTranslation("pages.settings.cleaning." + this.props.tabName + ".notification")}{" "}
										</div>
									</div>
									<div className="thresholds-info-container">
										<div className="thresholds-info-row first-line">
											<div className="info-circle" id="unavailable" />
											<span className="thresholds-info-count">
												{this.getCountOfFloorSpacesWithGivenNotificationEnabled(false)}
											</span>
										</div>
										<div className="thresholds-info-row second-line">
											{" "}
											{LanguageProvider.getTranslation(
												"pages.settings.cleaning." + this.props.tabName + ".nonotification"
											)}{" "}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<Modal id="cleaning-setings-modal" centered={true} show={this.state.showModal} onHide={this.handleClose}>
						<Modal.Header>
							<Modal.Title>{this.state.selectedSpaceName}</Modal.Title>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
								<img src={Cross_Cancel} />
							</button>
						</Modal.Header>
						<Modal.Body>
							<div className="modal-body-item">
								<div className="modal-body-header" id="enable-notification-header">
									{LanguageProvider.getTranslation("pages.settings.cleaning." + this.props.tabName + ".sendnotification")}
								</div>
								<Switch
									onChange={this.handleEnabledNotificationChange}
									checked={this.state.selectedThreshold.notificationEnabled}
									checkedIcon={false}
									uncheckedIcon={false}
									offColor={Colors.grey}
									onColor={Colors.shakespeare_blue}
									activeBoxShadow={""}
									boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
									height={40}
									width={80}
									handleDiameter={33}
									className={"toilet-switch"}
								/>
							</div>
							<div className="modal-body-item">
								<div className="modal-body-header">
									{LanguageProvider.getTranslation("pages.settings.cleaning." + this.props.tabName + ".threshold")}
								</div>
								<SliderComponent
									title={""}
									showTitle={false}
									domain={this.props.sliderDomain}
									step={this.props.sliderStep}
									mode={2}
									values={[this.state.selectedThreshold.threshold]}
									ticksCount={0}
									onChange={this.handleThresholdValuesChange}
								/>
							</div>
							<div className="modal-body-item">
								<div className="form-check form-check-inline">
									<input
										className="clickable form-check-input"
										type="checkbox"
										id="apply-to-all"
										checked={this.state.applyToAll}
										onChange={this.handleApplyToAll}
									/>
									<label className="clickable form-check-label" onClick={this.handleApplyToAll}>
										<span className="form-check-text">
											{LanguageProvider.getTranslation("pages.settings.cleaning." + this.props.tabName + ".applytoall")}
										</span>
									</label>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button className="ml-auto btn btn-secondary modal-cancel-button" onClick={this.handleClose}>
								{LanguageProvider.getTranslation("pages.settings.cleaning." + this.props.tabName + ".cancel")}
							</button>
							<button type="submit" className="ml-auto btn btn-primary modal-save-button" value="Submit" onClick={this.handleSubmit}>
								{LanguageProvider.getTranslation(translations.pages.settings.cleaning.toiletstab.save)}
								<img src={SaveButton} />
							</button>
						</Modal.Footer>
					</Modal>
				</div>
			</RealEstateUtilizationSettingsPage>
		);
	}
}

export default withTranslation()(SanitarySettingsTab);
