import IApplicationConfig from "./interfaces/IApplicationConfig";

export const ApplicationConfig: IApplicationConfig = {
	endpoints: {
		dashboardApi: process.env.REACT_APP_endpoints_dashboardApi || "",
		autodeskProxy: process.env.REACT_APP_endpoints_autodeskProxy || "",
		energyApi: process.env.REACT_APP_endpoints_energyApi || "",
	},
	environment: process.env.REACT_APP_environment || "",
	ad: {
		tenant: "common",
		clientId: process.env.REACT_APP_clientId || "",
		endpoints: {
			dashboardApi: process.env.REACT_APP_appIds_dashboardApi || "",
		},
		scopes: {
			dashboardApi: process.env.REACT_APP_scopes_dashboardApi || "",
			DashboardFrontend: process.env.REACT_APP_scopes_dashboardFrontend || "",
		},
		cacheLocation: "sessionStorage",
		redirectUri: window.location.origin,
	},
	autodesk: {
		header: {
			venueId: "beyondeyes-autodesk-venueid",
		},
	},
};
