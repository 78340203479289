import IInsightsDataRequest from "../interfaces/request/IInsightsDataRequest";
import DashboardService from "./dashboardService";
import DailyMotionValue from "../models/dailyMotionValue";
import HourlyMotionValue from "../models/hourlyMotionValue";
import DailyComfortValue from "../models/dailyComfortValue";
import DailyMotionDataEntity from "../models/dailyMotionDataEntity";
import HourlyComfortValue from "../models/hourlyComfortValue";
import IToiletAssetData from '../interfaces/IToiletAssetData';
import IToiletFloorData from '../interfaces/IToiletFloorData';
import IToiletVenueData from '../interfaces/IToiletVenueData';
import MultiAssetMotionValue from '../models/multiAssetMotionValue';

export default class DataInsightsService {
    private dashboardService: DashboardService;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getDailyAssetComfortData(ids: string[], type: string, start: Date, end: Date): Promise<DailyComfortValue[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `asset/${type}/comfort/days/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<DailyComfortValue>(requestUrl, ids);
    }

    public async getHourlyAssetComfortData(ids: string[], type: string, start: Date, end: Date): Promise<HourlyComfortValue[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `asset/${type}/comfort/hours/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<HourlyComfortValue>(requestUrl, ids);
    }

    public async getDailyMultiAssetMotionData(ids: string[], start: Date, end: Date): Promise<MultiAssetMotionValue[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `multiasset/motion/daily/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<MultiAssetMotionValue>(requestUrl, ids);
    }

    public async getWeeklyMultiAssetMotionData(ids: string[], start: Date, end: Date): Promise<MultiAssetMotionValue[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `multiasset/motion/weekly/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<MultiAssetMotionValue>(requestUrl, ids);
    }

    public async getMonthlyMultiAssetMotionData(ids: string[], start: Date, end: Date): Promise<MultiAssetMotionValue[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `multiasset/motion/monthly/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<MultiAssetMotionValue>(requestUrl, ids);
    }

    public async getHourlyAssetSanitaryData(assetIds: string[], start: Date, end: Date): Promise<IToiletAssetData[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `asset/sanitary/hours/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<IToiletAssetData>(requestUrl, assetIds);
    }

    public async getDailyAssetSanitaryData(assetIds: string[], start: Date, end: Date): Promise<IToiletAssetData[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `asset/sanitary/days/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<IToiletAssetData>(requestUrl, assetIds);
    }

    public async getHourlyFloorSanitaryData(floorIds: string[], start: Date, end: Date): Promise<IToiletFloorData[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `floor/sanitary/hours/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<IToiletFloorData>(requestUrl, floorIds);
    }

    public async getDailyFloorSanitaryData(floorIds: string[], start: Date, end: Date): Promise<IToiletFloorData[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `floor/sanitary/days/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<IToiletFloorData>(requestUrl, floorIds);
    }

    public async getHourlyVenueSanitaryData(venueIds: string[], start: Date, end: Date): Promise<IToiletVenueData[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `venue/sanitary/hours/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<IToiletVenueData>(requestUrl, venueIds);
    }

    public async getDailyVenueSanitaryData(venueIds: string[], start: Date, end: Date): Promise<IToiletVenueData[][]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `venue/sanitary/days/${formattedStartDate}/${formattedEndDate}`;

        return this.getDataRequestForIds<IToiletVenueData>(requestUrl, venueIds);
    }

    public async getTopAssetData(type: 'desk' | 'room' | 'floor', start: Date, end: Date): Promise<DailyMotionDataEntity[]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);

        return this.dashboardService.getByCustomerId<DailyMotionDataEntity[]>("aggregateddata", `top/${type}/${formattedStartDate}/${formattedEndDate}`)
            .catch(e => {
                console.error(e);
                return [];
            });
    }

    public async getHourlyVenueMotionData(ids: string[], start: Date, end: Date): Promise<HourlyMotionValue[]> {
        return this.getMotionData("hours", "venue", ids, start, end);
    }

    public async getHourlyFloorMotionData(ids: string[], start: Date, end: Date): Promise<HourlyMotionValue[]> {
        return this.getMotionData("hours", "floor", ids, start, end);
    }

    public async getHourlyAssetMotionData(ids: string[], start: Date, end: Date): Promise<HourlyMotionValue[]> {
        return this.getMotionData("hours", "asset", ids, start, end);
    }

    public async getDailyVenueMotionData(ids: string[], start: Date, end: Date): Promise<DailyMotionValue[]> {
        return this.getMotionData("days", "venue", ids, start, end);
    }

    public async getDailyFloorMotionData(ids: string[], start: Date, end: Date): Promise<DailyMotionValue[]> {
        return this.getMotionData("days", "floor", ids, start, end);
    }

    public async getDailyAssetMotionData(ids: string[], start: Date, end: Date): Promise<DailyMotionValue[]> {
        return this.getMotionData("days", "asset", ids, start, end);
    }

    private async getMotionData<T extends IMotionData>(
        timeUnit: 'days' | 'hours', level: 'asset' | 'floor' | 'venue', ids: string[], start: Date, end: Date): Promise<T[]> {
        const formattedStartDate = DashboardService.formatDate(start);
        const formattedEndDate = DashboardService.formatDate(end);
        const requestUrl = `${level}/motion/${timeUnit}/${formattedStartDate}/${formattedEndDate}`;
        const body = {
            identifiers: ids
        };

        return this.dashboardService.postWithResponseByCustomerId<IInsightsDataRequest, T[]>("aggregateddata", requestUrl, body)
            .catch(e => {
                console.error(e);
                return [];
            });
    }

    private async getDataRequestForIds<T>(requestUrl: string, ids: string[]): Promise<T[][]> {
        const body = {
            identifiers: ids
        };

        return this.dashboardService.postWithResponseByCustomerId<IInsightsDataRequest, T[][]>("aggregateddata", requestUrl, body)
            .catch(e => {
                console.error(e);
                return [];
            });
    }
}

interface IMotionData extends DailyMotionValue, HourlyMotionValue { }