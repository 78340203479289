import * as React from "react";
import moment from 'moment';

import './topDataTable.scss';

import ITopDataTableProps from "./interfaces/ITopDataTableProps";
import ITopDataTableState from "./interfaces/ITopDataTableState";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import LanguageProvider from "@/providers/languageProvider";
import DataInsightsService from "@/services/dataInsightsService";
import { withTelemetry } from "@/services/telemetryService";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";

class TopDataTable extends React.Component<ITopDataTableProps, ITopDataTableState> {

    private dataInsightsService: DataInsightsService;
    private didMount: boolean;

    public constructor(props: ITopDataTableProps) {
        super(props);

        this.dataInsightsService = new DataInsightsService();
        const state: ITopDataTableState = {
            topDeskData: [],
            topFloorData: [],
            topRoomData: [],
            loading: true
        };

        this.state = state;

        this.updateTopTable = this.updateTopTable.bind(this);
        AppEventHub.on(AppEvents.BuildingSelected, this.updateTopTable);
    }

    public async componentDidMount(): Promise<void> {
        this.didMount = true;

        this.updateTopTable();
    }

    public componentWillUnmount(): void {
        this.didMount = false;

        AppEventHub.off(AppEvents.BuildingSelected, this.updateTopTable);
    }

    public async componentDidUpdate(prevProps: ITopDataTableProps): Promise<void> {
        if (prevProps.startDate === this.props.startDate && prevProps.endDate === this.props.endDate) {
            return;
        }

        this.updateTopTable();
    }

    private updateTopTable(): void {
        if (!this.didMount) {
            return;
        }

        this.setState({
            loading: true
        }, async () => {
            const calls = [
                this.dataInsightsService.getTopAssetData("desk", this.props.startDate, this.props.endDate),
                this.dataInsightsService.getTopAssetData("room", this.props.startDate, this.props.endDate),
                this.dataInsightsService.getTopAssetData("floor", this.props.startDate, this.props.endDate)
            ];

            const result = await Promise.all(calls);

            if (!this.didMount) {
                return;
            }

            this.setState({
                topDeskData: result[0],
                topRoomData: result[1],
                topFloorData: result[2],
                loading: false
            });
        });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.loading &&
                    <div className="top-data-loader d-flex align-items-center justify-content-center">
                        <CenteredPageLoader loading={this.state.loading} />
                    </div>}
                {!this.state.loading &&
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="header">
                                <h2>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.titlepopular")}</h2>
                            </div>

                            <table className="table table-borderless top-five-table">
                                <thead>
                                    <tr>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.type")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.name")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.value")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.day")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.weekday")}</h3></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.topDeskData.length > 0 &&
                                        <tr>
                                            <td>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.desk")}</td>
                                            <td>{this.state.topDeskData[0].spaceName}</td>
                                            <td>{Math.round(this.state.topDeskData[0].percentage * 100) / 100}%</td>
                                            <td>{moment(this.state.topDeskData[0].utcDay).format("DD-MM-YYYY")}</td>
                                            <td>{LanguageProvider.getTranslation(`pages.motioninsights.reports.topdata.weekday${moment(this.state.topDeskData[0].utcDay).day()}`)}</td>
                                        </tr>
                                    }
                                    {this.state.topRoomData.length > 0 &&
                                        <tr>
                                            <td>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.room")}</td>
                                            <td>{this.state.topRoomData[0].spaceName}</td>
                                            <td>{Math.round(this.state.topRoomData[0].percentage * 100) / 100}%</td>
                                            <td>{moment(this.state.topRoomData[0].utcDay).format("DD-MM-YYYY")}</td>
                                            <td>{LanguageProvider.getTranslation(`pages.motioninsights.reports.topdata.weekday${moment(this.state.topRoomData[0].utcDay).day()}`)}</td>
                                        </tr>
                                    }
                                    {this.state.topFloorData.length > 0 &&
                                        <tr>
                                            <td>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.floor")}</td>
                                            <td>{this.state.topFloorData[0].spaceName}</td>
                                            <td>{Math.round(this.state.topFloorData[0].percentage * 100) / 100}%</td>
                                            <td>{moment(this.state.topFloorData[0].utcDay).format("DD-MM-YYYY")}</td>
                                            <td>{LanguageProvider.getTranslation(`pages.motioninsights.reports.topdata.weekday${moment(this.state.topFloorData[0].utcDay).day()}`)}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-5 offset-1">
                            <div className="header">
                                <h2>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.titlenonpopular")}</h2>
                            </div>

                            <table className="table table-borderless top-five-table">
                                <thead>
                                    <tr>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.type")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.name")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.value")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.day")}</h3></th>
                                        <th scope="col"><h3>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.weekday")}</h3></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.topDeskData.length > 0 &&
                                        <tr>
                                            <td>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.desk")}</td>
                                            <td>{this.state.topDeskData[1].spaceName}</td>
                                            <td>{Math.round(this.state.topDeskData[1].percentage * 100) / 100}%</td>
                                            <td>{moment(this.state.topDeskData[1].utcDay).format("DD-MM-YYYY")}</td>
                                            <td>{LanguageProvider.getTranslation(`pages.motioninsights.reports.topdata.weekday${moment(this.state.topDeskData[1].utcDay).day()}`)}</td>
                                        </tr>
                                    }
                                    {this.state.topRoomData.length > 0 &&
                                        <tr>
                                            <td>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.room")}</td>
                                            <td>{this.state.topRoomData[1].spaceName}</td>
                                            <td>{Math.round(this.state.topRoomData[1].percentage * 100) / 100}%</td>
                                            <td>{moment(this.state.topRoomData[1].utcDay).format("DD-MM-YYYY")}</td>
                                            <td>{LanguageProvider.getTranslation(`pages.motioninsights.reports.topdata.weekday${moment(this.state.topRoomData[1].utcDay).day()}`)}</td>
                                        </tr>
                                    }
                                    {this.state.topFloorData.length > 0 &&
                                        <tr>
                                            <td>{LanguageProvider.getTranslation("pages.motioninsights.reports.topdata.floor")}</td>
                                            <td>{this.state.topFloorData[1].spaceName}</td>
                                            <td>{Math.round(this.state.topFloorData[1].percentage * 100) / 100}%</td>
                                            <td>{moment(this.state.topFloorData[1].utcDay).format("DD-MM-YYYY")}</td>
                                            <td>{LanguageProvider.getTranslation(`pages.motioninsights.reports.topdata.weekday${moment(this.state.topFloorData[1].utcDay).day()}`)}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>}
            </>
        );
    }
}

export default withTelemetry(TopDataTable, "TopDataTable");