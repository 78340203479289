import * as React from "react";
import Props from "./interfaces/iRealEstateUtilizationSettingsPageProps";
import State from "./interfaces/iRealEstateUtilizationSettingsPageState";
import { withTelemetry } from "@/services/telemetryService";

import './realEstateUtilizationSettingsPage.scss';

import PageHeader from "@/components/header/pageHeader";
import FullPageLoader from "@/components/loaders/fullPageLoader";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import VenueProvider from "@/providers/venueProvider";
import Teaser from "@/components/teaser/realEstateUtilizationTeaser";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import { translations } from "@/translations/mapper";
import LanguageProvider from "@/providers/languageProvider";
import { Prompt } from "react-router-dom";

class RealEstateUtilizationSettingsPage extends React.Component<Props, State> {
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: Props) {
        super(props);

        const state: State = {
            loading: true,
            hasRealEstateUtilization: false
        };

        this.state = state;
        this.initializeVenue = this.initializeVenue.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenue);

        this.initializeVenue();
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenue);
    }

    private initializeVenue(): void {
        const venue = VenueProvider.getActiveVenue();
        const hasRealEstateUtilization = venue ? this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEEquipped"]) : false;

        this.setState({
            loading: false,
            hasRealEstateUtilization: hasRealEstateUtilization
        });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}
                {!this.state.loading &&
                    <>
                        {!this.state.hasRealEstateUtilization && <Teaser />}
                        <div id="realestateutilization-settings" className={`${this.state.hasRealEstateUtilization ? "" : "d-none"}`}>
                            <Prompt
                                when={!!(this.props.displaySaveButton && this.props.enableSaveButton)}
                                message={LanguageProvider.getTranslation(translations.alerts.navigation)} />
                            <PageHeader
                                pageName="realestateutilization-settings"
                                isSaving={this.props.isSaving}
                                displaySaveButton={this.props.displaySaveButton}
                                enableSaveButton={this.props.enableSaveButton}
                                onSaveSettings={this.props.onSave} />
                            {this.props.children}
                        </div>
                    </>}
            </>
        );
    }
}

export default withTelemetry(RealEstateUtilizationSettingsPage, "RealEstateUtilizationSettingsPage");