import * as React from "react";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { IAutodeskMapService, ISelectorEvent, IFloorChangeEvent, ISpaceOnMap } from "@beyondeyes/shared";

import VenueProvider from "@/providers/venueProvider";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import IWorkSpaceSettingsAvailabilityTabState from "./interfaces/IWorkSpaceSettingsAvailabilityTabState";
import ISpaceRestriction from "../../../../../interfaces/ISpaceRestriction";
import DashboardService from "@/services/dashboardService";
import WorkspaceSettingsService from "@/services/workspaceSettingsService";
import IWorkSpaceSettingsAvailabilityTabProps from "./interfaces/IWorkSpaceSettingsAvailabilityTabProps";

import SpaceRestrictionsInfo from "./components/spaceRestrictionsInfo";

import Colors from "@/styles/colors";
import CoreSpaceTypes from "../../../../../enums/coreSpaceTypes";
import AutodeskMap from "autodeskMap/components/autodeskMapWrapper";
import DashboardMapService from "components/dashboardMap/interfaces/DashboardMapService";
import { DashboardMapOptions } from "components/dashboardMap/interfaces/options";
import CoreSpace from "models/coreSpace";
import RealEstateUtilizationSettingsPage from "../../realEstateUtilizationSettingsPage";
import AvailabilityStyler from "@/utils/stylers/availabilityStyler";
import { SpaceUtils } from "utils/spaceUtils";
import CenteredPageLoader from "../../../../../components/loaders/centeredPageLoader";

class WorkSpaceSettingsAvailabilityTab extends React.Component<IWorkSpaceSettingsAvailabilityTabProps, IWorkSpaceSettingsAvailabilityTabState> {
    private readonly availabilityStyler: AvailabilityStyler;
    private readonly mapService: IAutodeskMapService;

    private readonly dashboardService: DashboardService;
    private readonly workspaceSettingsService: WorkspaceSettingsService;

    private addressesInput: HTMLInputElement | null = null;
    private spacesOnMap: ISpaceOnMap[] = [];

    public constructor(props: IWorkSpaceSettingsAvailabilityTabProps) {
        super(props);

        this.availabilityStyler = new AvailabilityStyler();
        this.mapService = new DashboardMapService(new DashboardMapOptions());

        this.handleSubmit = this.handleSubmit.bind(this);

        this.dashboardService = new DashboardService();
        this.workspaceSettingsService = new WorkspaceSettingsService();

        this.onFloorChangeAsync = this.onFloorChangeAsync.bind(this);
        this.onPlaceClickAsync = this.onPlaceClickAsync.bind(this);
        this.setSubmitState = this.setSubmitState.bind(this);

        const state: IWorkSpaceSettingsAvailabilityTabState = {
            loading: true,
            hasChanges: false,
            floorSpaceRestrictions: [],
            changedSpaceRestrictions: [],
            seed: Date.now()
        };

        this.state = state;
    }

    private async onFloorChangeAsync(eventContext: IFloorChangeEvent): Promise<void> {
        this.spacesOnMap = eventContext.renderedSpaces;
        this.setState(
            {
                floor: eventContext.floor,
            },
            () => this.updateRestrictionsAsync(eventContext.floor.level)
        );
    }

    private async onPlaceClickAsync(eventContext: ISelectorEvent): Promise<void> {
        const space = eventContext.space?.beSpace as CoreSpace;

        if (!space || space.type !== CoreSpaceTypes.Workspace) {
            return;
        }

        let newSpaceRestriction: ISpaceRestriction;
        const changedRestriction = this.state.changedSpaceRestrictions.find((r) => r.spaceId === space.id);

        if (changedRestriction) {
            newSpaceRestriction = {
                restricted: !changedRestriction.restricted,
                spaceId: space.id,
                floorLevel: changedRestriction.floorLevel,
            };
        } else {
            const floor = this.state.floor!;
            newSpaceRestriction = {
                restricted: !space.isRestricted,
                spaceId: space.id,
                floorLevel: floor.level,
            };
        }

        await this.updateChangedSpaceRestrictions(space.id, newSpaceRestriction);
    }

    private async updateChangedSpaceRestrictions(spaceId: string, newSpaceRestriction: ISpaceRestriction): Promise<void> {
        const changedSpaceRestrictions = this.state.changedSpaceRestrictions;
        const changedSpaceRestriction = changedSpaceRestrictions.find((r) => r.spaceId === spaceId);

        if (changedSpaceRestriction) {
            // existing change
            const changedIndex = changedSpaceRestrictions.indexOf(changedSpaceRestriction);
            changedSpaceRestrictions[changedIndex] = newSpaceRestriction;
        } else {
            // new change
            changedSpaceRestrictions.push(newSpaceRestriction);
        }

        const floorSpaceRestrictions = this.state.floorSpaceRestrictions;
        const floorSpaceRestriction = floorSpaceRestrictions.find((r) => r.spaceId === spaceId);

        if (floorSpaceRestriction) {
            const index = floorSpaceRestrictions.indexOf(floorSpaceRestriction);
            floorSpaceRestrictions[index] = newSpaceRestriction;
        }

        this.availabilityStyler.setChangedSpaceRestrictions(changedSpaceRestrictions);

        this.setState({
            changedSpaceRestrictions: changedSpaceRestrictions,
            floorSpaceRestrictions: floorSpaceRestrictions,
            hasChanges: this.state.changedSpaceRestrictions.length > 0,
            spaces: this.getSpacesWithStyle,
        });
    }

    private async updateRestrictionsAsync(floorLevel: number): Promise<void> {
        this.setState({
            loading: true,
        });

        const workspaces = this.spacesOnMap?.filter((s) => (s.beSpace as CoreSpace).type === CoreSpaceTypes.Workspace) ?? [];
        const floorSpaceRestrictions: ISpaceRestriction[] = [];

        workspaces.forEach((space) => {
            const coreSpace = space.beSpace as CoreSpace;
            let restricted: boolean = true;
            const changedSpaceRestriction = this.state.changedSpaceRestrictions.find((r) => r.spaceId === coreSpace.id);
            if (changedSpaceRestriction) {
                restricted = changedSpaceRestriction.restricted;
            } else if (space.beSpace) {
                restricted = coreSpace.isRestricted;
            }

            floorSpaceRestrictions.push({
                floorLevel: floorLevel,
                restricted: restricted,
                spaceId: coreSpace.id,
            });
        });

        this.availabilityStyler.setChangedSpaceRestrictions(this.state.changedSpaceRestrictions);

        this.setState({
            floorSpaceRestrictions: floorSpaceRestrictions,
            loading: false,
            hasChanges: this.state.changedSpaceRestrictions.length > 0,
            spaces: this.getSpacesWithStyle,
        });
    }

    private get getSpacesWithStyle(): ISpaceOnMap[] {
        return SpaceUtils.getSpacesWithStyle(this.spacesOnMap, this.availabilityStyler);
    }

    private async handleSubmit(): Promise<void> {
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const activeVenue = VenueProvider.getActiveVenue();
        if (!activeVenue) {
            return;
        }
        const tasks: Promise<Response>[] = [];
        if (this.state.changedSpaceRestrictions.length > 0) {
            tasks.push(this.dashboardService.updateSpaceRestrictions(activeVenue.id, this.state.changedSpaceRestrictions));
        }

        const results = await Promise.all(tasks);

        const failed = results.find((r) => r.status !== 200 && r.status !== 204) !== undefined;

        if (!failed) {
            NotificationManager.success(LanguageProvider.getTranslation(translations.pages.settings.workspaces.changessaved));
        } else {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.workspaces.erroronsave));
            return;
        }

        // Getting the changes needs to be delayed as the server needs some time to process information
        setTimeout(this.setSubmitState, 5000);
    }

    public setSubmitState(): void {
        this.setState({
            loading: false,
            changedSpaceRestrictions: [],
            hasChanges: false,
            seed: Date.now()
        });
    }

    public render(): JSX.Element {
        return (
            <RealEstateUtilizationSettingsPage enableSaveButton={this.state.hasChanges && !this.state.loading} displaySaveButton={true} onSave={this.handleSubmit}>
                <div className="main-content map">
                    <div className="main-content-header">
                        <div className="row header-margin-bottom-paragraph">
                            <div className="col-sm">
                                <h1>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.availability)}</h1>
                                <div className="row">
                                    <div className="col-sm-9">
                                        {LanguageProvider.getTranslation(translations.pages.settings.workspaces.instruction)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        <div className="row mt-3">
                            <div className="col-sm">
                                <div className="map-wrapper-availability">
                                    {this.state.loading &&
                                        <div className="w-100 h-100 availability-loader position-absolute d-flex justify-content-center">
                                            <CenteredPageLoader color={Colors.midnight_black} size={12} loading={this.state.loading} />
                                        </div>}
                                    <div>
                                        <div id="map-container-availability">
                                            <AutodeskMap
                                                onFloorChangedAsync={this.onFloorChangeAsync}
                                                onPlaceClickAsync={this.onPlaceClickAsync}
                                                spaces={this.state.spaces}
                                                mapService={this.mapService}
                                                startWithFirstFloor={true}
                                                key={this.state.seed}
                                            />
                                        </div>
                                    </div>
                                    <SpaceRestrictionsInfo
                                        restrictedCount={this.state.floorSpaceRestrictions.filter((r) => r.restricted).length}
                                        unrestrictedCount={this.state.floorSpaceRestrictions.filter((r) => !r.restricted).length}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RealEstateUtilizationSettingsPage>
        );
    }
}

export default withTranslation()(WorkSpaceSettingsAvailabilityTab);
