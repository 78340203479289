import { EnergyAssetType } from "@/models/energyAssetType";

export default class EnergyNavigationStore {

    private static navigationDetails?: INavigationDetails;

    public static setNavigationDetails(navigationDetails: INavigationDetails): void {
        this.navigationDetails = navigationDetails;
    }

    public static getNavigationDetails(): INavigationDetails | undefined {
        return this.navigationDetails;
    }

    public static clearStore(): void {
        this.navigationDetails = undefined;
    }
}

interface INavigationDetails {
    name: string;
    type: EnergyAssetType;
    id: string;
    unitType: 'm3' | 'kWh' | 'GJ';
}