import moment from "moment";

export default class ClientSideCacheItem {
    private urlHashBackingField: string;
    private expiresAt: number;
    private contentsBackingField?: string;

    public constructor(urlHash?: string, contents?: string) {
        this.urlHashBackingField = urlHash || "";
        this.contentsBackingField = contents;
        this.setExpirationDate();
    }

    public get url(): string {
        return this.urlHashBackingField;
    }

    public get contents(): string | undefined {
        return this.contentsBackingField;
    }

    public get Timestamp(): Date {
        return this.Timestamp;
    }

    public get isValid(): boolean {
        const curDate = moment(new Date()).valueOf();
        const isValid = this.contentsBackingField !== null && this.expiresAt >= curDate;
        return isValid;
    }

    public parse(serializedCache: string | null): void {
        if (serializedCache !== null) {
            const curDate = moment(new Date()).valueOf();
            const deserialized: ClientSideCacheItem = JSON.parse(serializedCache);
            this.expiresAt = deserialized.expiresAt;
            if (this.expiresAt > curDate) {
                this.contentsBackingField = deserialized.contentsBackingField;
            }
        }
    }

    private setExpirationDate(): void {
        this.expiresAt = moment(new Date()).add(59, "s").valueOf();
    }
}