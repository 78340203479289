enum CoreSpaceTypes {
    Customer = "Customer",
    Venue = "Venue",
    Floor = "Floor",
    Room = "Room",
    Workspace = "Workspace",
    Installation = "Installation",
    InstallationGroup = "InstallationGroup",
    Unknown = "Unknown"
}

export default CoreSpaceTypes;