import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ISideBarItem } from '@/interfaces/ISideBarItem';
import './sideBar.scss';
import LanguageProvider from '@/providers/languageProvider';

interface ISideBarItemProps extends WithTranslation {
    sideBarItem: ISideBarItem;
}

class SideBarItem extends React.Component<ISideBarItemProps, {}> {

    public constructor(props: ISideBarItemProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <>
                {this.props.sideBarItem.show && <>
                    <div className={`sidebar-item ${this.props.sideBarItem.active ? "active" : "disabled"}`}>
                        <Link to={this.props.sideBarItem.linkTo} className={this.props.sideBarItem.className} data-test={this.props.sideBarItem.dataTestAttr}>
                            <div className="d-flex align-items-center">
                                <img src={this.props.sideBarItem.imageSrc} />
                                {LanguageProvider.getTranslation(this.props.sideBarItem.translateId)}
                            </div>
                        </Link>
                        {this.props.sideBarItem.subItems
                            && this.props.sideBarItem.active
                            && (this.props.sideBarItem.subItems.find(i => i.active))
                            && <div className="sub-items">
                                {this.props.sideBarItem.subItems.filter(i => i.active).map(item => (
                                    <Link key={item.linkTo} className={`sub-item ${item.className}`} to={item.linkTo} data-test={item.dataTestAttr}>
                                        {LanguageProvider.getTranslation(item.translateId)}
                                    </Link>
                                ))}
                            </div>}
                    </div>
                    {this.props.sideBarItem.showBottomDivider &&
                        <div className="sidebar-divider" />
                    }
                </>}
            </>
        );
    }
}

export default withTranslation()(SideBarItem);