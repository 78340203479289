import * as React from "react";
import { withRouter } from 'react-router-dom';

import "./energyTopConsumersOverview.scss";

import IEnergyTopConsumersOverviewProps from "./interfaces/IEnergyTopConsumersOverviewProps";
import { IEnergyTopConsumersOverviewState, SelectedVenueTableType } from "./interfaces/IEnergyTopConsumersOverviewState";

import HeatSelectedIcon from '@/images/Heat_selected.svg';
import HeatIcon from '@/images/Heat_unselected.svg';
import GasSelectedIcon from '@/images/Gas_selected.svg';
import GasIcon from '@/images/Gas_unselected.svg';
import EnergySelectedIcon from '@/images/Energy_selected.svg';
import EnergyIcon from '@/images/Energy_unselected.svg';
import CurrencySelectedIcon from '@/images/piggy_selected.svg';
import CurrencyIcon from '@/images/piggy_unselected.svg';
import Blue_Arrow from "@/images/Blue_Arrow_Right.svg";
import EnergyService from "@/services/energyService";
import CoreSpaceService from "@/services/coreSpaceService";
import VenueProvider from "@/providers/venueProvider";
import LanguageProvider from "@/providers/languageProvider";
import IEnergyThisYearVenueSavings from "../../../interfaces/energy/IEnergyThisYearVenueSavings";
import { TrClickHandler } from "@/components/utils/clickHandlers";
import BesenseTooltip from "@/components/besenseTooltip/besenseTooltip";
import CoreSpaceIncludes from "../../../enums/coreSpaceIncludes";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";
import links from "@/utils/links";
import AppEventHub, { AppEvents } from "utils/appEventHub";

class EnergyTopConsumersOverview extends React.Component<IEnergyTopConsumersOverviewProps, IEnergyTopConsumersOverviewState> {

    private readonly energyService: EnergyService;
    private readonly coreSpaceService: CoreSpaceService;
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: IEnergyTopConsumersOverviewProps) {
        super(props);
        this.energyService = new EnergyService();
        this.coreSpaceService = new CoreSpaceService();

        const state: IEnergyTopConsumersOverviewState = {
            loading: true,
            venueSavings: [],
            selectedTable: SelectedVenueTableType.Currency,
            showElectricitySelection: false,
            showGasSelection: false,
            showHeatSelection: false
        };

        this.state = state;
        this.renderColumns = this.renderColumns.bind(this);
        this.renderDataRow = this.renderDataRow.bind(this);
        this.navigateToVenueDetailsAsync = this.navigateToVenueDetailsAsync.bind(this);
        this.selectCurrency = this.selectCurrency.bind(this);
        this.selectElectricity = this.selectElectricity.bind(this);
        this.selectGas = this.selectGas.bind(this);
        this.selectHeat = this.selectHeat.bind(this);
        this.redirectToEnergyPage = this.redirectToEnergyPage.bind(this);
        this.updatePageForCurrentVenueAsync = this.updatePageForCurrentVenueAsync.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.updatePageForCurrentVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.updatePageForCurrentVenueAsync();
    }

    public async componentWillUnmount(): Promise<void> {
        AppEventHub.off(AppEvents.BuildingSelected, this.updatePageForCurrentVenueAsync);
    }

    private async updatePageForCurrentVenueAsync(): Promise<void> {
        const savings = await this.energyService.GetThisYearsTopVenueSavingsAsync();

        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        const customerId = venue.customerId;

        const gasSubscription = this.subscriptionValidationService.customerHasAnyApplicableSubscription(customerId, ["Gas"]);
        const electricitySubscription = this.subscriptionValidationService.customerHasAnyApplicableSubscription(customerId, ["Electricity"]);
        const heatSubscription = this.subscriptionValidationService.customerHasAnyApplicableSubscription(customerId, ["Heat"]);

        this.setState({
            loading: false,
            venueSavings: savings,
            showElectricitySelection: electricitySubscription,
            showGasSelection: gasSubscription,
            showHeatSelection: heatSubscription
        });
    }

    private async navigateToVenueDetailsAsync(venueId?: string): Promise<void> {
        if (!venueId) {
            return;
        }

        const space = await this.coreSpaceService.getVenueById(venueId, [CoreSpaceIncludes.Properties]);

        VenueProvider.saveActiveVenue(space);
        this.props.history.push(links.energymanagement.overview);
    }

    private redirectToEnergyPage(): void {
        this.props.history.push(links.energymanagement.portfolio);
    }

    private renderColumns(): JSX.Element {
        switch (this.state.selectedTable) {
            case SelectedVenueTableType.Currency:
                return <tr>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.venue")}</h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingseuro")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings.currentsavings-euro")} /></h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingspercentage")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings.currentsavings-percentage")} /></h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.goalsavings")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings.savingstarget")} /></h3></th>
                </tr>;
            case SelectedVenueTableType.Electricity:
                return <tr>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.venue")}</h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingskwh")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings-energy.currentsavings-kwh")} /></h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingspercentage")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings-energy.currentsavings-percentage")} /></h3></th>
                    <th />
                </tr>;
            case SelectedVenueTableType.Gas:
                return <tr>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.venue")}</h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingsm3")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings-gas.currentsavings-m3")} /></h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingspercentage")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings-gas.currentsavings-percentage")} /></h3></th>
                    <th />
                </tr>;
            case SelectedVenueTableType.Heat:
                return <tr>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.venue")}</h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingsgj")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings-heat.currentsavings-gj")} /></h3></th>
                    <th><h3>{LanguageProvider.getTranslation("energy.topvenuesavings.columns.currentsavingspercentage")}<BesenseTooltip content={LanguageProvider.getTranslation("tooltips.energy.top-venue-savings-heat.currentsavings-percentage")} /></h3></th>
                    <th />
                </tr>;
        }

        return <tr><th /></tr>;
    }

    private renderDataRow(data: IEnergyThisYearVenueSavings): JSX.Element {
        let cells = [<td key={0} />];
        let savingsNotZero = true;

        switch (this.state.selectedTable) {
            case SelectedVenueTableType.Currency:
                savingsNotZero = data.savingsInCurrency !== 0;
                cells = [
                    <td key={0}>{data.venue}</td>,
                    <td key={1}>€ {savingsNotZero ? `${Math.round(data.savingsInCurrency).toLocaleString()},-` : '-'}</td>,
                    <td key={2}>{savingsNotZero ? Math.round(data.currentSavingsPercentage * 10) / 10 : '-'}%</td>,
                    <td key={3}>{Math.round(data.savingsGoalPercentage * 10) / 10}%</td>
                ];
                break;
            case SelectedVenueTableType.Electricity:
                savingsNotZero = data.electricitySavingsInkWh !== 0;
                cells = [
                    <td key={0}>{data.venue}</td>,
                    <td key={1}>{savingsNotZero ? Math.round(data.electricitySavingsInkWh) : '-'} kWh</td>,
                    <td key={2}>{savingsNotZero ? Math.round(data.electricitySavingsPercentage * 10) / 10 : '-'}%</td>,
                    <td key={3} />
                ];
                break;
            case SelectedVenueTableType.Gas:
                savingsNotZero = data.gasSavingsInM3 !== 0;
                cells = [
                    <td key={0}>{data.venue}</td>,
                    <td key={1}>{savingsNotZero ? Math.round(data.gasSavingsInM3) : '-'} m<sup>3</sup></td>,
                    <td key={2}>{savingsNotZero ? Math.round(data.gasSavingsPercentage * 10) / 10 : '-'}%</td>,
                    <td key={3} />
                ];
                break;
            case SelectedVenueTableType.Heat:
                savingsNotZero = data.heatSavingsInGj !== 0;
                cells = [
                    <td key={0}>{data.venue}</td>,
                    <td key={1}>{savingsNotZero ? Math.round(data.heatSavingsInGj) : '-'} GJ</td>,
                    <td key={2}>{savingsNotZero ? Math.round(data.heatSavingsPercentage * 10) / 10 : '-'}%</td>,
                    <td key={3} />
                ];
                break;
        }

        return <TrClickHandler key={data.venueId} content={cells} onClick={this.navigateToVenueDetailsAsync} params={data.venueId} />;
    }

    private get currencySelected(): boolean {
        return this.state.selectedTable === SelectedVenueTableType.Currency;
    }

    private get electricitySelected(): boolean {
        return this.state.selectedTable === SelectedVenueTableType.Electricity;
    }

    private get gasSelected(): boolean {
        return this.state.selectedTable === SelectedVenueTableType.Gas;
    }

    private get heatSelected(): boolean {
        return this.state.selectedTable === SelectedVenueTableType.Heat;
    }

    private selectCurrency(): void {
        if (this.currencySelected) {
            return;
        }

        this.setState({
            selectedTable: SelectedVenueTableType.Currency
        });
    }

    private selectElectricity(): void {
        if (this.electricitySelected) {
            return;
        }

        this.setState({
            selectedTable: SelectedVenueTableType.Electricity
        });
    }

    private selectGas(): void {
        if (this.gasSelected) {
            return;
        }

        this.setState({
            selectedTable: SelectedVenueTableType.Gas
        });
    }

    private selectHeat(): void {
        if (this.heatSelected) {
            return;
        }

        this.setState({
            selectedTable: SelectedVenueTableType.Heat
        });
    }

    public render(): JSX.Element {
        return (
            <div className="energy-top-users-overview">
                <div className="header-container">
                    <div className="d-flex">
                        <h1>{LanguageProvider.getTranslation("energy.topvenuesavings.title")}</h1>
                    </div>
                    <div className="selection-container ml-auto">
                        <div onClick={this.selectCurrency} className={"selection-item " + (this.currencySelected ? "selected" : "")}>
                            <img src={this.currencySelected ? CurrencySelectedIcon : CurrencyIcon} />
                        </div>
                        {this.state.showElectricitySelection && <div onClick={this.selectElectricity} className={"selection-item " + (this.electricitySelected ? "selected" : "")}>
                            <img src={this.electricitySelected ? EnergySelectedIcon : EnergyIcon} />
                        </div>}
                        {this.state.showGasSelection && <div onClick={this.selectGas} className={"selection-item " + (this.gasSelected ? "selected" : "")}>
                            <img src={this.gasSelected ? GasSelectedIcon : GasIcon} />
                        </div>}
                        {this.state.showHeatSelection && <div onClick={this.selectHeat} className={"selection-item " + (this.heatSelected ? "selected" : "")}>
                            <img src={this.heatSelected ? HeatSelectedIcon : HeatIcon} />
                        </div>}
                    </div>
                    <div className="ml-4 clickable d-flex align-items-center" onClick={this.redirectToEnergyPage}>
                        <span className="mr-2 more-button">{LanguageProvider.getTranslation("pages.home.all")}</span>
                        <img src={Blue_Arrow} />
                    </div>
                </div>
                {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                {!this.state.loading && <table className="table table-hover table-borderless" id="latest-notifications">
                    <thead>
                        {this.renderColumns()}
                    </thead>
                    <tbody>
                        {this.state.venueSavings.map(s => this.renderDataRow(s))}
                    </tbody>
                </table>
                }
            </div>
        );
    }
}

export default withRouter(EnergyTopConsumersOverview);