import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import "./loginError.scss";
import AccessService from "@/services/accessService";
import { BeyondEyesLogoWhite } from "@/images";
import UserSettingsMenu from '@/components/header/userSettingsMenu';
import LanguageProvider from "@/providers/languageProvider";

interface IErrorDisplayProps extends WithTranslation {
    error?: Error;
}

class LoginError extends React.Component<IErrorDisplayProps, {}> {
    private readonly AccessService: AccessService;
    private userName: string;

    public constructor(props: IErrorDisplayProps) {
        super(props);
        LanguageProvider.initialize();

        this.AccessService = new AccessService();
        this.logoutUser = this.logoutUser.bind(this);
        this.userName = this.AccessService.getUserName();
    }

    public logoutUser(): void {
        this.AccessService.signOut();
    }

    public render(): JSX.Element {
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light pr-5" id="user-menu-navbar">
                    <img src={BeyondEyesLogoWhite} className="logo" />
                    <div className="navbar-nav ml-auto">
                        <div className="nav-item nav-link">
                            <UserSettingsMenu />
                        </div>
                    </div>
                </nav>
                <div className="screen-background">
                    <div className="loginerror container d-flex">
                        <div className="loginerror content">
                            <div className="loginerror innerbox">
                                <div className="loginerror header">
                                    <div className="d-flex justify-content-center"><h2 className="pt-2">{LanguageProvider.getTranslation("noAccess.title")}</h2></div>
                                    <div className="d-flex justify-content-center"><h3>{LanguageProvider.getTranslation("noAccess.subTitle")}</h3></div>
                                </div>
                                <p />
                                {this.userName && <p><b>{LanguageProvider.getTranslation("noAccess.currentuser") + " " + this.userName}</b></p>}
                                {this.props.error && <p>{LanguageProvider.getTranslation("noAccess.errormessage")}</p>}
                                {this.props.error && <p><b>{this.props.error.message}</b></p>}
                                <p/>
                                {<p>{LanguageProvider.getTranslation("noAccess.firstLine")}</p>}
                                {<ol>
                                    <li>{LanguageProvider.getTranslation("noAccess.firstOption")}</li>
                                    <li>{LanguageProvider.getTranslation("noAccess.secondOption")}</li>
                                </ol>}
                                <p>{LanguageProvider.getTranslation("noAccess.lastLine")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(LoginError);