import Highcharts from 'highcharts';
import Colors from '../../../../styles/colors';
import LanguageProvider from '../../../../providers/languageProvider';

const chartOptions = (title: string, percentage: number): Highcharts.Options => ({
    chart: {
        type: 'gauge',
        plotBorderWidth: 0,
        plotShadow: false,
        height: '100%'
    },
    lang: {
        contextButtonTitle: LanguageProvider.getTranslation("pages.realestateutilization.charts.general.contextbuttontitle"),
        downloadCSV: LanguageProvider.getTranslation("pages.realestateutilization.charts.general.downloadcsv"),
        downloadPNG: LanguageProvider.getTranslation("pages.realestateutilization.charts.general.downloadpng"),
        downloadXLS: LanguageProvider.getTranslation("pages.realestateutilization.charts.general.downloadxls"),
        exitFullscreen: LanguageProvider.getTranslation("pages.realestateutilization.charts.general.exitfullscreen"),
        viewFullscreen: LanguageProvider.getTranslation("pages.realestateutilization.charts.general.viewfullscreen"),
    },
    title: {
        text: title,
        align: 'left',
        y: 20,
        margin: 25,
        style: {
            fontFamily: 'Poppins',
            fontSize: '14px',
        }
    },
    pane: {
        startAngle: -135,
        endAngle: 135,
        background: [{
            outerRadius: '103%',
            innerRadius: '101%',
            backgroundColor: Colors.black,
            borderWidth: 1,
            borderColor: Colors.black
        }],
        center: ['50%', '50%'],
        size: '100%'
    },
    yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart?.backgroundColor || Colors.white,
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: 5,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickWidth: 1,
        minorTickColor: Highcharts.defaultOptions.chart?.backgroundColor || Colors.white,
        tickPositions: [0, 25, 50, 75, 100],
        labels: {
            distance: -35,
            style: {
                fontSize: '14px'
            }
        },
        lineWidth: 0,
        plotBands: [{
            from: 0,
            to: 25,
            color: Colors.blue_cruise,
            thickness: 20
        }, {
            from: 25,
            to: 75,
            color: Colors.shakespeare_blue,
            thickness: 20
        }, {
            from: 75,
            to: 100,
            color: Colors.blue_astral,
            thickness: 20
        }]
    },
    series: [{
        type: 'gauge',
        name: title,
        data: [percentage],
        tooltip: {
            valueSuffix: '%'
        },
        dataLabels: {
            format: '{y}%',
            borderWidth: 0,
            color: (
                Highcharts.defaultOptions.title?.style?.color
            ) || Colors.black,
            style: {
                fontSize: '24px'
            }
        },
        dial: {
            radius: '95%',
            backgroundColor: Colors.royal_blue,
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%'
        },
        pivot: {
            backgroundColor: Colors.royal_blue,
            radius: 6
        }
    }],
    credits: {
        enabled: false
    },
    exporting: {
        enabled: true,
        buttons: {
            contextButton: {
                menuItems: ['downloadPNG', 'downloadCSV', 'downloadXLS', 'viewFullscreen']
            }
        },
    }
});

export default chartOptions;
