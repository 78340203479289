import AutodeskMap from "autodeskMap/components/autodeskMapWrapper";
import { ISelectorEvent, IFloorChangeEvent, IAutodeskMapService, ISpaceOnMap } from "@beyondeyes/shared";
import DashboardMapService from "components/dashboardMap/interfaces/DashboardMapService";
import { DashboardMapOptions } from "components/dashboardMap/interfaces/options";
import IReactSelectValue from "interfaces/IReactSelectValue";
import * as React from "react";
import TelemetryService, { withTelemetry } from "services/telemetryService";
import InsightsMapStyler from "@/utils/stylers/insightsMapStyler";
import { AIEvents } from "utils/aiEvents";
import Dictionary from "utils/dictionary";
import { SpaceUtils } from "utils/spaceUtils";
import IInsightsMapProps from "../interfaces/IInsightsMapProps";
import IInsightsMapState from "../interfaces/IInsightsMapState";

import "./insightsMap.scss";

class InsightsMap extends React.Component<IInsightsMapProps, IInsightsMapState> {
	private reactSelectValueLookup: Dictionary<IReactSelectValue>;

	private readonly insightsMapStyler: InsightsMapStyler;
	private readonly mapService: IAutodeskMapService;
	private currentFloor: number | undefined = undefined;
	private floorChangedExternal: boolean | undefined = undefined;
	private spacesOnMap: ISpaceOnMap[] = [];

	public constructor(props: IInsightsMapProps) {
		super(props);

		this.reactSelectValueLookup = new Dictionary<IReactSelectValue>();

		this.insightsMapStyler = new InsightsMapStyler();
		this.mapService = new DashboardMapService(new DashboardMapOptions());

		const state: IInsightsMapState = {};
		this.state = state;

		this.onPlaceClickAsync = this.onPlaceClickAsync.bind(this);
		this.onFloorChangeAsync = this.onFloorChangeAsync.bind(this);
		this.setSelectedAssetsOnMap = this.setSelectedAssetsOnMap.bind(this);
		this.updateStylesOrCurrentFloor = this.updateStylesOrCurrentFloor.bind(this);
	}

	public async componentDidMount(): Promise<void> {
		this.setExternalSelectionOptions();
		this.setSelectedAssetsOnMap();

		this.currentFloor = this.props.selectedFloor?.floorLevel;
		this.setState({
			startFloor: this.currentFloor
		});
	}

	public async componentDidUpdate(prevProps: IInsightsMapProps): Promise<void> {
		let selectedSpacesUpdated = false;
		if (this.props.selectedAssets && this.props.selectedAssets.length !== prevProps.selectedAssets.length) {
			this.setSelectedAssetsOnMap();
			selectedSpacesUpdated = true;
		}

		let assetOptionsUpdated = false;
		if (this.props.assetOptions.length !== prevProps.assetOptions.length) {
			this.setExternalSelectionOptions();
			assetOptionsUpdated = true;
		}

		if (this.props.isDisplayed && !prevProps.isDisplayed) {
			this.updateStylesOrCurrentFloor();
		}

		if (assetOptionsUpdated && this.props.isDisplayed) {
			this.setState({
				spaces: this.getSpacesWithStyle,
			});
			return;
		}

		if (selectedSpacesUpdated && this.props.isDisplayed) {
			this.setState({
				selectedSpaces: this.getSpacesWithStyle
			});
		}
	}

	private updateStylesOrCurrentFloor(): void {
		if (this.currentFloor === this.props.selectedFloor?.floorLevel) {
			this.setState({
				spaces: this.getSpacesWithStyle,
			});
		} else {
			this.floorChangedExternal = this.props.selectedFloor?.floorLevel !== undefined;
			this.setState({
				currentFloor: this.props.selectedFloor?.floorLevel
			});
		}
	}

	private get getSpacesWithStyle(): ISpaceOnMap[] {
		return SpaceUtils.getSpacesWithStyle(this.spacesOnMap, this.insightsMapStyler);
	}

	private async onFloorChangeAsync(eventContext: IFloorChangeEvent): Promise<void> {
		if (!eventContext) {
			return;
		}

		this.spacesOnMap = eventContext.renderedSpaces;

		if (this.floorChangedExternal) {
			this.setState({
				spaces: this.getSpacesWithStyle,
			});
		}
		this.floorChangedExternal = false;
		this.currentFloor = eventContext.floor.level;
		this.props.onFloorChange(eventContext.floor.level);
	}

	private setExternalSelectionOptions(): void {
		this.props.assetOptions.forEach((asset) => this.reactSelectValueLookup.add(asset.label.toLowerCase(), asset));

		const spaceIds = this.props.assetOptions.map((asset) => asset.value);
		this.insightsMapStyler.setSelectableSpaces(spaceIds);
	}

	private setSelectedAssetsOnMap(): void {
		this.insightsMapStyler.clearSelectedSpaces();

		this.props.selectedAssets.forEach((asset) => {
			const spaceId = asset.value;
			this.insightsMapStyler.setSpaceAsSelected(spaceId, true);
		});
	}

	private async onPlaceClickAsync(eventContext: ISelectorEvent): Promise<void> {
		const space = eventContext.space?.beSpace;
		if (!space) {
			return;
		}

		TelemetryService.AppInsights.trackEvent({ name: AIEvents.motionInsights.map.click });

		const wasPreviouslySelected = this.insightsMapStyler.isSpaceSelected(space.id);
		this.insightsMapStyler.setSpaceAsSelected(space.id, !wasPreviouslySelected);
		const reactSelectValue = this.reactSelectValueLookup.item(space.name.toLowerCase());

		if (wasPreviouslySelected) {
			this.props.onAssetRemove(reactSelectValue);
		} else {
			this.props.onAssetAdd(reactSelectValue);
		}
	}

	public render(): JSX.Element {
		return (
			<span id="insights-map">
				<AutodeskMap
					onFloorChangedAsync={this.onFloorChangeAsync}
					onPlaceClickAsync={this.onPlaceClickAsync}
					spaces={this.state.spaces}
					mapService={this.mapService}
					startFloorLevel={this.state.startFloor}
					currentFloorLevel={this.state.currentFloor}
					selectedSpaces={this.state.selectedSpaces}
					startWithFirstFloor={this.state.startFloor == null}
					maxNumberOfFloorButtons={6}
				/>
			</span>
		);
	}
}

export default withTelemetry(InsightsMap, "InsightsMap");
