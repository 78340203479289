import IBaseGraphProps from "./interfaces/IBaseGraphProps";
import { withTranslation } from 'react-i18next';
import { Doughnut } from "react-chartjs-2";
import BaseGraph from './baseGraph';

class DoughnutGraph extends BaseGraph {

    public constructor(props: IBaseGraphProps) {
        super(props);
        this.chartCallback = this.chartCallback.bind(this);
    }

    private chartCallback(ref: Doughnut): void {
        if (!ref) {
            return;
        }

        this.chartInstance = ref.chartInstance;
        this.setState({
            graphTitle: this.props.title,
            datasets: ref.chartInstance.data.datasets
        });
    }

    public render(): JSX.Element {
        this.props.options.onHover = this.onElementHover;

        return (
            <Doughnut
                data={this.props.data}
                width={this.props.width}
                height={this.props.height}
                options={this.getChartOptions()}
                ref={this.chartCallback}
                onElementsClick={this.onElementsClickCallback} />
        );
    }
}

export default withTranslation()(DoughnutGraph);