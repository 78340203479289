import * as React from "react";

import IIndoorClimateSettingsPageProps from "./interfaces/iIndoorClimateSettingsPageProps";
import IIndoorClimateSettingsPageState from "./interfaces/iIndoorClimateSettingsPageState";
import { withTelemetry } from "@/services/telemetryService";

import PageHeader from "@/components/header/pageHeader";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import VenueProvider from "@/providers/venueProvider";
import Teaser from "@/components/teaser/indoorClimateTeaser";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import FullPageLoader from "@/components/loaders/fullPageLoader";
import { Prompt } from "react-router-dom";
import LanguageProvider from "@/providers/languageProvider";
import { translations } from "@/translations/mapper";

class IndoorClimateSettingsPage extends React.Component<IIndoorClimateSettingsPageProps, IIndoorClimateSettingsPageState> {
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: IIndoorClimateSettingsPageProps) {
        super(props);

        const state: IIndoorClimateSettingsPageState = {
            hasIndoorClimate: false,
            loading: true
        };

        this.state = state;

        this.initializeVenue = this.initializeVenue.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenue);

        this.initializeVenue();
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenue);
    }

    private initializeVenue(): void {
        const venue = VenueProvider.getActiveVenue();
        const hasIndoorClimate = venue ?
            this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEVital"]) : false;

        this.setState({
            loading: false,
            hasIndoorClimate: hasIndoorClimate
        });
    }

    public render(): JSX.Element {
        return (
            <>

                {this.state.loading && <FullPageLoader loading={this.state.loading} />}
                {!this.state.loading && <>
                    {!this.state.hasIndoorClimate && <Teaser />}
                    {this.state.hasIndoorClimate && <div id="indoorclimate-settings">
                        <Prompt
                            when={!!(this.props.displaySaveButton && this.props.enableSaveButton)}
                            message={LanguageProvider.getTranslation(translations.alerts.navigation)} />
                        <PageHeader
                            pageName="indoorclimate-settings"
                            displaySaveButton={this.props.displaySaveButton}
                            enableSaveButton={this.props.enableSaveButton}
                            onSaveSettings={this.props.onSave} />
                        {this.props.children}
                    </div>}
                </>}
            </>
        );
    }
}

export default withTelemetry(IndoorClimateSettingsPage, "IndoorClimateSettingsPage");