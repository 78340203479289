import { Component } from "react";
import 'moment/locale/nl';
import { OnChangeValue } from "react-select";

import { GreenCheckmark, GreyCheckmark } from "@/images";
import "./notificationsPage.scss";

import IBaseNotificationsState from "./interfaces/IBaseNotificationsState";
import IBaseNotificationsProps from "./interfaces/IBaseNotificationsProps";
import { FilterMenu } from "@/components/filterMenu/filterMenu";
import ISelectDropdownField from "@/components/filterMenu/interfaces/ISelectDropdownField";
import ISelectDropdownValue from "@/components/filterMenu/interfaces/ISelectDropdownValue";
import LanguageProvider from "@/providers/languageProvider";
import AppEventHub, { AppEvents } from "@//utils/appEventHub";
import translations from "@/translations/mapper";
import TimeZoneUtils from "@/utils/timeZoneUtils";
import BaseNotification from "@/models/abstractions/baseNotification";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import DataTable, { TableColumn, ConditionalStyles } from "react-data-table-component";
import PageUtils from "../../../utils/pageUtils";

export default abstract class BaseNotificationsComponent<T extends BaseNotification, TExtraState = undefined> extends Component<IBaseNotificationsProps, IBaseNotificationsState<T, TExtraState>> {
    private filterNameNotificationSpace: string = 'NotificationBy';
    private filterNameStatus: string = 'Status';
    private filterNameCategories: string = 'Categories';
    private clearCurrentOption: string = 'ClearCurrentOption';

    protected subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: any, extraState?: TExtraState) {
        super(props);

        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);

        const dateDiff = startDate.getDate() - 1;
        startDate.setDate(startDate.getDate() - dateDiff);

        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        const state: IBaseNotificationsState<T, TExtraState> = {
            notifications: [],
            loading: true,
            columns: this.getColumns(),
            startDate: startDate,
            endDate: undefined,
            dropdowns: [],
            filteredNotifications: [],
            selectedFilters: [],
            hasSubscription: false,
            extraState: extraState
        };

        this.state = state;

        this.getColumns = this.getColumns.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.setSelectFilterOptions = this.setSelectFilterOptions.bind(this);
        this.changeStatusFilter = this.changeStatusFilter.bind(this);
        this.changeCategoriesFilter = this.changeCategoriesFilter.bind(this);
        this.changeNotificationSpaceFilter = this.changeNotificationSpaceFilter.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.changeVenue = this.changeVenue.bind(this);
        this.translateTypeOfElement = this.translateTypeOfElement.bind(this);
        this.setHasSubscription = this.setHasSubscription.bind(this);

        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumns);
        AppEventHub.on(AppEvents.LanguageChanged, this.setSelectFilterOptions);
        AppEventHub.on(AppEvents.BuildingSelected, this.changeVenue);
        AppEventHub.on(AppEvents.BuildingSelected, this.setHasSubscription);
    }

    public componentWillUnmountBase(): void {
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumns);
        AppEventHub.off(AppEvents.LanguageChanged, this.setSelectFilterOptions);
        AppEventHub.off(AppEvents.BuildingSelected, this.changeVenue);
        AppEventHub.off(AppEvents.BuildingSelected, this.setHasSubscription);
    }

    public async componentDidMountBase(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

        this.setHasSubscription();
        await this.getNotificationsAsync();
    }

    protected abstract renderAboveNotifications(): JSX.Element;

    protected abstract renderExpandedNotification(event: any): JSX.Element;

    protected abstract translateTypeOfElement(element: T): string;

    protected abstract getNotificationsAsync(): Promise<void>;

    protected abstract getTeaser(): JSX.Element;

    protected abstract translateFilterCategory(filterCategory: string): string;

    protected abstract getTitle(): string;

    protected abstract setHasSubscription(): void;

    protected abstract getExtraFilterDropdowns(): ISelectDropdownField[];

    protected abstract getConditionalRowStyles(): ConditionalStyles<T>[];

    protected additionalFilterForNotifications(notifications: T[]): T[] {
        // Can be overridden in extending components if they want to apply extra custom filtering.
        return notifications;
    }

    private handleStartDateChange(state: Date): void {
        this.setState(
            {
                startDate: state
            }, () => this.getNotificationsAsync());
    }

    private handleEndDateChange(state: Date): void {
        this.setState(
            {
                endDate: state
            }, () => this.getNotificationsAsync());
    }

    private changeVenue(): void {
        this.setState({
            selectedFilters: [],
            dropdowns: []
        }, this.getNotificationsAsync);
    }

    protected setSelectFilterOptions(): void {
        const distinct = (value, index, self): boolean => {
            return self.indexOf(value) === index;
        };

        const notificationList = this.state.notifications;

        const uniqueCategories = notificationList.map((notification) => notification.category).filter(distinct);
        const categoryDropdownOptions = uniqueCategories.map((option) =>
        ({
            value: option,
            label: this.translateFilterCategory(option),
            dropdownType: this.filterNameCategories,
        })
        );

        const uniqueSpaceNames = notificationList.map((notification) => notification.spaceName).filter(distinct);
        const spaceDropdownOptions = uniqueSpaceNames.map((option) =>
        ({
            value: option,
            label: option,
            dropdownType: this.filterNameNotificationSpace,
        })
        );

        const statusDropdownOptions = [
            ({
                value: "resolved",
                label: LanguageProvider.getTranslation(translations.pages.notifications.resolved),
                dropdownType: this.filterNameStatus,
            }),
            ({
                value: "notResolved",
                label: LanguageProvider.getTranslation(translations.pages.notifications.notresolved),
                dropdownType: this.filterNameStatus,
            })
        ];

        const baseMenus: ISelectDropdownField[] = [{
            dropdownName: LanguageProvider.getTranslation(translations.pages.notifications.category),
            dropdownList: categoryDropdownOptions,
            key: '1',
            onSelect: this.changeCategoriesFilter
        },
        {
            dropdownName: LanguageProvider.getTranslation(translations.pages.notifications.space),
            dropdownList: spaceDropdownOptions,
            key: '2',
            onSelect: this.changeNotificationSpaceFilter
        }];

        const extraDropdowns = this.getExtraFilterDropdowns();

        const dropdownMenus = baseMenus.concat(extraDropdowns);

        const statusDropdown = {
            dropdownName: LanguageProvider.getTranslation(translations.pages.notifications.status),
            dropdownList: statusDropdownOptions,
            key: (extraDropdowns.length + 3).toString(),
            onSelect: this.changeStatusFilter
        };
        dropdownMenus.push(statusDropdown);

        // Update the translation of the selected resolved filter.
        const selectedResolvedFilter = this.state.selectedFilters.find(f => f.dropdownType === this.filterNameStatus);

        if (selectedResolvedFilter !== undefined) {
            selectedResolvedFilter.label = selectedResolvedFilter.value === "resolved" ? LanguageProvider.getTranslation(translations.pages.notifications.resolved) : LanguageProvider.getTranslation(translations.pages.notifications.notresolved);
        }

        this.setState({
            dropdowns: dropdownMenus
        });
    }

    private changeStatusFilter(optionSelected: OnChangeValue<ISelectDropdownValue, false>): void {
        this.changeValueForFilter(optionSelected, this.filterNameStatus);
    }

    private changeCategoriesFilter(optionSelected: OnChangeValue<ISelectDropdownValue, false>): void {
        this.changeValueForFilter(optionSelected, this.filterNameCategories);
    }

    private changeNotificationSpaceFilter(optionSelected: OnChangeValue<ISelectDropdownValue, false>): void {
        this.changeValueForFilter(optionSelected, this.filterNameNotificationSpace);
    }

    private changeValueForFilter(optionSelected: OnChangeValue<ISelectDropdownValue, false>, dropdownType: string): void {
        if (!optionSelected) {
            optionSelected = {
                dropdownType: dropdownType,
                label: '',
                value: this.clearCurrentOption,
            };
        }

        this.updateFilters(optionSelected);
    }

    protected updateFilters(newFilterOptionSelected?: OnChangeValue<ISelectDropdownValue, false>): void {
        let newActiveFilters = this.state.selectedFilters;

        if (newFilterOptionSelected) {
            const item = newFilterOptionSelected as ISelectDropdownValue;
            const dropdownType = item.dropdownType;
            newActiveFilters = this.state.selectedFilters.filter(filter => filter.dropdownType !== dropdownType);

            if (item.value === this.clearCurrentOption) {
                newActiveFilters.filter((option) => option.dropdownType === item.dropdownType);
            } else if (item) {
                newActiveFilters.push(item);
            }
        }

        let filteredNotifications: T[] = this.state.notifications;

        newActiveFilters.forEach(filter => {
            switch (filter.dropdownType) {
                case this.filterNameNotificationSpace:
                    filteredNotifications = filteredNotifications.filter((notification) => notification.spaceName.toLowerCase() === filter.value.toLowerCase());
                    break;
                case this.filterNameCategories:
                    filteredNotifications = filteredNotifications.filter((notification) => notification.category === filter.value);
                    break;
                case this.filterNameStatus:
                    filteredNotifications = filteredNotifications.filter((notification) => filter.value === "resolved"
                        ? notification.resolvedOn !== null && notification !== undefined
                        : notification.resolvedOn === null || notification.resolvedOn === undefined);
                    break;
                default:
                    break;
            }
        });

        filteredNotifications = this.additionalFilterForNotifications(filteredNotifications);

        this.setState({
            selectedFilters: newActiveFilters,
            filteredNotifications: filteredNotifications
        });
    }

    private updateColumns(): void {
        const columns = this.getColumns();

        this.setState({ columns: columns });
    }

    private getColumns(): TableColumn<T>[] {
        return (
            [
                {
                    name: LanguageProvider.getTranslation(translations.pages.notifications.category),
                    id: 'category',
                    selector: (element: T): string => this.translateTypeOfElement(element),
                    width: '270px'
                },
                {
                    name: LanguageProvider.getTranslation(translations.pages.notifications.createdon),
                    id: 'createdOn',
                    width: '220px',
                    selector: (element): string => element.createdOn ? TimeZoneUtils.ConvertUtcDateToWestEurope(element.createdOn).format("DD-MM-YYYY HH:mm") : ""
                },
                {
                    name: LanguageProvider.getTranslation(translations.pages.notifications.resolvedon),
                    id: 'resolvedOn',
                    width: '220px',
                    selector: (element: any): string => element.resolvedOn ? TimeZoneUtils.ConvertUtcDateToWestEurope(element.resolvedOn).format("DD-MM-YYYY HH:mm") : ""
                }, {
                    name: LanguageProvider.getTranslation(translations.pages.notifications.venue),
                    id: 'venueName',
                    selector: (row): string => row.venueName,
                }, {
                    name: LanguageProvider.getTranslation(translations.pages.notifications.space),
                    selector: (row): string => row.spaceName,
                    id: "spaceName",
                }, {
                    name: LanguageProvider.getTranslation(translations.pages.notifications.status),
                    id: 'resolved',
                    selector: (row): string  => <img src={row.resolvedOn !== undefined && row.resolvedOn !== null ? GreenCheckmark : GreyCheckmark} /> as unknown as any,
                    width: '100px',
                }
            ]
        );
    }

    public render(): JSX.Element {
        return (<>
            {!this.state.hasSubscription &&
                !this.state.loading &&
                this.getTeaser()}

            {this.state.hasSubscription &&
                <div id="notifications-page" className="main-content">
                    <div className="main-content-header">
                        <div className="row">
                            <div className="col-sm">
                                <h1>{this.getTitle()}</h1>
                            </div>
                        </div>
                        {this.renderAboveNotifications()}
                        <div className="row input-selection">
                            <div className="col-sm-12 d-flex">
                                <FilterMenu startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    handleEndDateChange={this.handleEndDateChange}
                                    handleStartDateChange={this.handleStartDateChange}
                                    dropdownField={this.state.dropdowns}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                        {!this.state.loading && (
                            (this.state.filteredNotifications.length > 0 &&
                                <DataTable
                                    className="besense-grey-border-table"
                                    progressPending={this.state.loading}
                                    columns={this.state.columns}
                                    data={this.state.filteredNotifications}
                                    pagination={true}
                                    paginationPerPage={10}
                                    defaultSortFieldId={"createdOn"}
                                    conditionalRowStyles={this.getConditionalRowStyles()}
                                    expandableRows
                                    expandableRowsComponent={(row: any): JSX.Element => { return this.renderExpandedNotification(row.data); }}
                                    paginationComponentOptions={PageUtils.getDefaultPaginationOptions()}
                                />
                            )
                            ||
                            <div className="row justify-content-center">
                                {LanguageProvider.getTranslation(translations.pages.notifications.norawdatafound)}
                            </div>)
                        }
                    </div>
                </div>}
        </>
        );
    }
}