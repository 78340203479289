import moment from 'moment';
import IBaseToiletData from '../interfaces/IBaseToiletData';

import ILineGraphData from '../interfaces/ILineGraphData';
import DailyComfortValue from '../models/dailyComfortValue';
import DailyMotionValue from '../models/dailyMotionValue';
import HourlyComfortValue from '../models/hourlyComfortValue';
import HourlyMotionValue from '../models/hourlyMotionValue';
import IGraphSelectValue from '../pages/realestateutilization/interfaces/IGraphSelectValue';
import ColorProvider from '../providers/colorProvider';
import VenueProvider from '../providers/venueProvider';
import Colors from '@/styles/colors';
import TimeZoneUtils from './timeZoneUtils';

export default class ChartDataCreator {

    public static createDailyMotionAverageOverview(motionData: DailyMotionValue[]): number[][] {
        const dataCurrentWeek: number[] = [0, 0, 0, 0, 0, 0, 0];
        const dataPreviousWeeks: number[][] = [[], [], [], [], [], [], []];

        // moment.js counts sunday as the start of the week, thus as 0, therefore we need to subtract 1 to get to monday from the current day.
        // (this data is used for a graph that starts on monday!)
        const lastMonday = moment().subtract(moment().day() - 1, "days").hours(0).minutes(0).seconds(0).milliseconds(0);
        motionData.forEach((m) => {
            const date = moment(`${m.localDay}-${m.localMonth}-${m.localYear}`, "DD-MM-YYYY");
            const weekDay = date.day() === 0 ? 6 : date.day() - 1;
            const roundedPercentage = Math.round(m.percentage * 100) / 100;

            if (date >= lastMonday) {
                dataCurrentWeek[weekDay] = roundedPercentage;
            }
            else {
                dataPreviousWeeks[weekDay].push(roundedPercentage);
            }
        });

        const dataPreviousWeeksAverage = dataPreviousWeeks.map((d) => {
            const previousWeekDayArray = d;
            let sum = 0;
            previousWeekDayArray.forEach((w) => {
                sum += w;
            });
            const average = sum / previousWeekDayArray.length;
            const roundedAverage = Math.round(average * 100) / 100;
            return roundedAverage;
        });

        return [dataPreviousWeeksAverage, dataCurrentWeek];
    }

    public static createDailyMotionMinMaxOverview(motionData: DailyMotionValue[]): number[][] {
        const data: number[][] = [[], [], []];
        const dataAverage: number[] = [0, 0, 0, 0, 0, 0, 0];
        const dataCount: number[] = [0, 0, 0, 0, 0, 0, 0];

        motionData.forEach((m) => {
            const date = moment(`${m.localDay}-${m.localMonth}-${m.localYear}`, "DD-MM-YYYY");
            // Moment .day uses sunday-starting weeks. Our graph starts on sunday here!
            const weekDay = date.day();

            dataAverage[weekDay] += m.percentage;
            dataCount[weekDay] += 1;
        });

        for (let i = 0; i < 7; i++) {
            if (dataCount[i] !== 0) {
                dataAverage[i] = dataAverage[i] / dataCount[i];
                // Round the resulting average to 2 decimals, for nicer display.
                dataAverage[i] = Math.round(dataAverage[i] * 100) / 100;
            }
        }
        data[0] = dataAverage;
        return data;
    }

    public static createDailyComfortOverview(comfortData: DailyComfortValue[][], assets: IGraphSelectValue[], thresholdHigh?: number, thresholdLow?: number): ILineGraphData[] {
        const graphsData: ILineGraphData[] = [];

        comfortData.forEach((comfortAssetData, i) => {
            const data: number[] = [];
            const countData: number[] = [];
            let label = "";
            comfortAssetData.forEach((m) => {
                const date = moment(`${m.localDay}-${m.localMonth}-${m.localYear}`, "DD-MM-YYYY");
                // Weekday uses sunday-starting weeks. Our graph starts on sunday here!
                const weekDay = date.day();
                const average = m.average;
                if (data[weekDay] === undefined) {
                    data[weekDay] = average;
                    countData[weekDay] = 1;
                }
                else {
                    data[weekDay] = data[weekDay] + average;
                    countData[weekDay] = countData[weekDay] + 1;
                }
                label = m.spaceName;
            });
            for (let index = 0; index < 7; index++) {
                if (data[index] !== undefined) {
                    data[index] = data[index] / countData[index];
                    // Round the resulting average to 2 decimals, for nicer display.
                    data[index] = Math.round(data[index] * 100) / 100;
                }
            }
            graphsData[i] = {
                data: data,
                label: label,
                color: assets[i].color,
                textColor: assets[i].textColor
            };
        });

        if (thresholdLow !== undefined) {
            const colorLowIndex = graphsData.length;
            graphsData.push({
                isLowerBound: true,
                isUpperBound: false,
                data: [thresholdLow, thresholdLow, thresholdLow, thresholdLow, thresholdLow, thresholdLow, thresholdLow],
                label: "",
                color: ColorProvider.getNextSemiRandomBackgroundColorForIndex(colorLowIndex),
                textColor: ColorProvider.getNextSemiRandomTextColorForIndex(colorLowIndex)
            });
        }
        if (thresholdHigh !== undefined) {
            const colorHighIndex = graphsData.length;
            graphsData.push({
                isLowerBound: false,
                isUpperBound: true,
                data: [thresholdHigh, thresholdHigh, thresholdHigh, thresholdHigh, thresholdHigh, thresholdHigh, thresholdHigh],
                label: "",
                color: ColorProvider.getNextSemiRandomBackgroundColorForIndex(colorHighIndex),
                textColor: ColorProvider.getNextSemiRandomTextColorForIndex(colorHighIndex)
            });
        }
        return graphsData;
    }

    public static createHourlyComfortOverview(comfortData: HourlyComfortValue[][], assets: IGraphSelectValue[], thresholdHigh?: number, thresholdLow?: number): [ILineGraphData[], number, number] {
        const graphsData: ILineGraphData[] = [];

        const venue = VenueProvider.getActiveVenue();
        const earliestHour = venue?.openingHour ?? 0;
        const latestHour = Math.min(venue?.closingHour ?? 24, 24);

        comfortData.forEach((comfortAssetData, i) => {
            const data: number[] = [];
            const countData: number[] = [];
            let label = "";
            comfortAssetData.forEach((m) => {
                if(m.localHour < earliestHour || m.localHour >= latestHour){
                    return;
                }

                const arrayIndex = m.localHour - earliestHour;
                if (data[arrayIndex] === undefined) {
                    data[arrayIndex] = m.average;
                    countData[arrayIndex] = 1;
                }
                else {
                    data[arrayIndex] = data[arrayIndex] + m.average;
                    countData[arrayIndex] = countData[arrayIndex] + 1;
                }

                label = m.spaceName;
            });

            for (let index = 0; index < latestHour - earliestHour; index++) {
                if (data[index] !== undefined) {
                    data[index] = data[index] / countData[index];
                    // Round the resulting averages to display nicer.
                    data[index] = Math.round(data[index] * 10) / 10;
                }
            }
            graphsData[i] = {
                data: data,
                label: label,
                color: assets[i].color,
                textColor: assets[i].textColor
            };
        });

        const hourlyBound = latestHour - earliestHour;
        if (thresholdLow !== undefined) {
            const data = this.generateThresholdData(thresholdLow, hourlyBound, graphsData.length, true, false);
            graphsData.push(data);
        }
        if (thresholdHigh !== undefined) {
            const data = this.generateThresholdData(thresholdHigh, hourlyBound, graphsData.length, false, true);
            graphsData.push(data);
        }

        return [graphsData, earliestHour, latestHour];
    }

    private static generateThresholdData(thresholdValue: number, hourlyBound: number, colorIndex: number, isLowerBound: boolean, isUpperBound: boolean): ILineGraphData {
        const lineLength: number[] = [];

        for (let index = 0; index < hourlyBound; index++) {
            lineLength.push(thresholdValue);
        }

        return {
            isLowerBound: isLowerBound,
            isUpperBound: isUpperBound,
            data: lineLength,
            label: "",
            color: ColorProvider.getNextSemiRandomBackgroundColorForIndex(colorIndex),
            textColor: ColorProvider.getNextSemiRandomTextColorForIndex(colorIndex)
        };
    }

    public static createHourlySanitaryOverview(sanitaryData: IBaseToiletData[][], assets: IGraphSelectValue[], selectedDataType: 'percentage' | 'visitors'): [ILineGraphData[], number, number] {
        const graphsData: ILineGraphData[] = [];

        const venue = VenueProvider.getActiveVenue();
        const earliestHour = venue?.openingHour ?? 0;
        const latestHour = Math.min(venue?.closingHour ?? 24, 24);

        sanitaryData.forEach((sanitaryAssetData, i) => {
            const dataTotal: number[] = [];
            const dataCount: number[] = [];
            let label = "";
            sanitaryAssetData.forEach((m) => {
                if (m.localHour < earliestHour || m.localHour >= latestHour) {
                    return;
                }

                const arrayIndex = m.localHour - earliestHour;
                const value = selectedDataType === 'visitors' ?
                    m.usageCount ?? m.averageUsageCount :
                    m.duration ?? m.averageDuration;

                if (dataTotal[arrayIndex] === undefined) {
                    dataTotal[arrayIndex] = value;
                    dataCount[arrayIndex] = 1;
                }
                else {
                    dataTotal[arrayIndex] = dataTotal[arrayIndex] + value;
                    dataCount[arrayIndex] = dataCount[arrayIndex] + 1;
                }

                label = m.spaceName;
            });

            for (let index = 0; index < latestHour - earliestHour; index++) {
                if (dataTotal[index] !== undefined) {
                    dataTotal[index] = dataTotal[index] / dataCount[index];

                    if (selectedDataType === 'percentage') {
                        // We want to show the average percentage of time the toilet is in use, so far the data contains
                        // the average duration (in seconds) a toilet is occupied per hour, so divide by 36 (= 3600/100).
                        dataTotal[index] = dataTotal[index] / 36;
                    }

                    // Round the resulting averages to display nicer.
                    dataTotal[index] = Math.round(dataTotal[index]);
                }
            }


            graphsData[i] = {
                data: dataTotal,
                label: label,
                color: assets[i].color,
                textColor: assets[i].textColor
            };
        });

        if (graphsData.length > 1) {
            // Add an average dotted line between the different dataSets (use the assumption that all datasets are same length, should be true):
            const averages: number[] = [];
            for (let index = 0; index < graphsData[0].data.length; index++) {
                const dataSetsWithDataForIndex = graphsData.filter(d => d.data[index] !== undefined);
                if (dataSetsWithDataForIndex.length === 0) {
                    continue;
                }

                const totalOverDatasets = dataSetsWithDataForIndex.map(d => d.data[index]).reduce((a, b) => a + b, 0);
                averages[index] = totalOverDatasets / dataSetsWithDataForIndex.length;

                // Round the resulting average, for nicer display.
                averages[index] = Math.round(averages[index]);
            }

            const averageDataSet = {
                data: averages,
                label: "",
                color: Colors.grey,
                textColor: Colors.grey,
                isAverage: true
            };

            graphsData.push(averageDataSet);
        }

        return [graphsData, earliestHour, latestHour];
    }

    public static createDailySanitaryOverview(sanitaryData: IBaseToiletData[][], assets: IGraphSelectValue[], selectedDataType: 'percentage' | 'visitors'): [ILineGraphData[], boolean[]] {
        // Note that daily overview is currently based on hourly records, not daily records!
        // This is because the current wish is to only use data from openinghours for calculation, but aggregation for toilet doesn't take openinghours into consideration.
        const graphsData: ILineGraphData[] = [];

        const venue = VenueProvider.getActiveVenue();
        const earliestHour = venue?.openingHour ?? 0;
        const latestHour = Math.min(venue?.closingHour ?? 24, 24);
        const openDaysProperty = venue?.openDays ?? "1111111";

        // Create a sunday starting boolean array of which days the venue is open.
        const openDays: boolean[] = [];
        for (let index = 0; index < 7; index++) {
            openDays[index] = openDaysProperty[(index + 6) % 7] === '1';
        }

        sanitaryData.forEach((toiletAssetData, i) => {
            const dataTotal: number[] = [];
            const dataCount: number[] = [];
            let label = "";
            toiletAssetData.forEach((m) => {
                if (m.localHour < earliestHour || m.localHour >= latestHour) {
                    return;
                }

                const localTimestamp = TimeZoneUtils.ConvertUtcDateToWestEurope(m.utcHour);

                const date = moment(`${localTimestamp.date()}-${localTimestamp.month() + 1}-${localTimestamp.year()}`, "DD-MM-YYYY");
                // Weekday uses sunday-starting weeks. Our graph starts on sunday here!
                const weekDay = date.day();

                // Only use data from days that the venue is open.
                if (!openDays[weekDay]) {
                    return;
                }

                const value = selectedDataType === 'visitors' ?
                    m.usageCount ?? m.averageUsageCount :
                    m.duration ?? m.averageDuration;

                if (dataTotal[weekDay] === undefined) {
                    dataTotal[weekDay] = value;
                    dataCount[weekDay] = 1;
                }
                else {
                    dataTotal[weekDay] = dataTotal[weekDay] + value;
                    dataCount[weekDay] = dataCount[weekDay] + 1;
                }
                label = m.spaceName;
            });
            for (let index = 0; index < 7; index++) {
                if (dataTotal[index] !== undefined) {
                    const hoursCountedPerDay = latestHour - earliestHour;
                    dataTotal[index] = hoursCountedPerDay * dataTotal[index] / dataCount[index];

                    if (selectedDataType === 'percentage') {
                        // We want to show the average percentage of time the toilet is in use, so far the data contains
                        // the average duration (in seconds) a toilet is occupied per day, so divide by openinghours * 3600 and multiply by 100 (to make percentage).
                        dataTotal[index] = 100 * dataTotal[index] / (hoursCountedPerDay * 3600);
                    }

                    // Round the resulting average, for nicer display.
                    dataTotal[index] = Math.round(dataTotal[index]);
                }
            }

            // Confusing loop instead of filter, since filter clears out empty spaces in the array (even when condition evaluates to true), causing
            // days to shift to the left (for instance if monday is outside the date range, but should be displayed in the graph). Looping like this
            // ensures the empty space will exist in the new array.
            const dataOnOpenedDays: number[] = [];
            let newIndex = 0;
            for (let index = 0; index < 7; index++) {
                if (openDays[index]) {
                    dataOnOpenedDays[newIndex] = dataTotal[index];
                    newIndex++;
                }
            }

            graphsData[i] = {
                data: dataOnOpenedDays,
                label: label,
                color: assets[i].color,
                textColor: assets[i].textColor
            } as ILineGraphData;
        });

        if (graphsData.length > 1) {
            // Add an average dotted line between the different dataSets (use the assumption that all datasets are same length, should be true):
            const averages: number[] = [];
            for (let index = 0; index < graphsData[0].data.length; index++) {
                const dataSetsWithDataForIndex = graphsData.filter(d => d.data[index] !== undefined);
                if (dataSetsWithDataForIndex.length === 0) {
                    continue;
                }

                const totalOverDatasets = dataSetsWithDataForIndex.map(d => d.data[index]).reduce((a, b) => a + b, 0);
                averages[index] = totalOverDatasets / dataSetsWithDataForIndex.length;

                // Round the resulting average, for nicer display.
                averages[index] = Math.round(averages[index]);
            }

            const averageDataSet = {
                data: averages,
                label: "",
                color: Colors.grey,
                textColor: Colors.grey,
                isAverage: true
            };

            graphsData.push(averageDataSet);
        }

        return [graphsData, openDays];
    }

    public static createHourlyMotionData(hourlyMotionData: HourlyMotionValue[]): [number[], number, number] {
        const data: number[] = [];
        const countData: number[] = [];

        const venue = VenueProvider.getActiveVenue();
        const earliestHour = venue?.openingHour ?? 0;
        const latestHour = Math.min(venue?.closingHour ?? 23, 23);

        // Return an array with averages over all hours for which data exists.
        hourlyMotionData.forEach((m) => {
            const arrayIndex = m.localHour - earliestHour;
            if (data[arrayIndex] === undefined) {
                data[arrayIndex] = m.percentage;
                countData[arrayIndex] = 1;
            }
            else {
                data[arrayIndex] = data[arrayIndex] + m.percentage;
                countData[arrayIndex] = countData[arrayIndex] + 1;
            }
        });

        for (let index = 0; index < latestHour - earliestHour + 1; index++) {
            if (data[index] !== undefined) {
                data[index] = data[index] / countData[index];
                // Round the resulting averages to display nicer.
                data[index] = Math.round(data[index] * 100) / 100;
            }
        }

        return [data, earliestHour, latestHour];
    }
}