import * as React from "react";
import { withTranslation } from "react-i18next";
import { Modal } from 'react-bootstrap';

import Cross_Cancel from "@/images/Cross_Cancel.svg";
import Delete_Large from "@/images/Delete_Large.svg";
import Delete_Small from "@/images/Tag_Delete_White.svg";
import translations from "@/translations/mapper";
import LanguageProvider from "@/providers/languageProvider";
import IWorkSpaceSettingsTypeDeletePopupProps from "./interfaces/IWorkSpaceSettingsTypeDeletePopupProps";

import "./workSpaceSettingsTypeDeletePopup.scss";

class WorkSpaceSettingsTypeDeletePopup extends React.Component<IWorkSpaceSettingsTypeDeletePopupProps> {
    public constructor(props: any) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
    }

    private handleDelete(): void {
        this.props.submitDelete(this.props.optionIdentifier);
    }

    public render(): JSX.Element {
        return (
            <>
                <Modal id="workspace-type-delete-popup" centered={true} show={this.props.showPopup} onHide={this.props.cancelDelete} dialogClassName="workspace-type-delete-popup">
                    <Modal.Header>
                        <h3 className="modal-title" id="workspaceModelLabel">
                            {LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.deletepopup.delete)}
                        </h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.cancelDelete}>
                            <img src={Cross_Cancel} />
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="delete-popup-body">
                            <img src={Delete_Large} />
                            <div className="delete-popup-question">
                                {LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.deletepopup.questionfirstpart)
                                    + this.props.optionLabel
                                    + LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.deletepopup.questionsecondpart)}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="popup-cancel-button btn-secondary" id="popup-cancel-button" onClick={this.props.cancelDelete}>
                            {LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.popup.cancel)}
                        </button>
                        <button type="submit" className="popup-save-button btn-primary" id="popup-cancel-button" value="Submit" onClick={this.handleDelete}>
                            {LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.deletepopup.delete)}
                            <img src={Delete_Small}/>
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}

export default withTranslation()(WorkSpaceSettingsTypeDeletePopup);