import IBaseState from "@/interfaces/IBaseState";
import IEnergyThisYearVenueSavings from "@/interfaces/energy/IEnergyThisYearVenueSavings";

export interface IEnergyTopConsumersOverviewState extends IBaseState {
    venueSavings: IEnergyThisYearVenueSavings[];
    selectedTable: SelectedVenueTableType;
    showGasSelection: boolean;
    showElectricitySelection: boolean;
    showHeatSelection: boolean;
}

export enum SelectedVenueTableType {
    Currency,
    Electricity,
    Gas,
    Heat
}