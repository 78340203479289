import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { EndpointContextBuilder } from "@beyondeyes/shared";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { NotificationContainer } from "react-notifications";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import "./main.scss";

import App from "./pages/app";
import { registerServiceWorker } from "@/utils/registerServiceWorker";
import AccessService from "./services/accessService";
import AppEventHub from "./utils/appEventHub";
import LanguageProvider from "./providers/languageProvider";
import TelemetryProvider from "./providers/telemetryProvider";

import { ApplicationConfig } from "config";
import LoginError from "./pages/home/loginError";

AppEventHub.initialize();
LanguageProvider.validateTranslations();
EndpointContextBuilder.initiate(ApplicationConfig);
const accessService = new AccessService();

LanguageProvider.initialize();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

accessService.signIn(
	() => {
		root.render(
			<Router>
				<TelemetryProvider>
					<I18nextProvider i18n={i18next}>
						<NotificationContainer />
						<App location={window.location} />
					</I18nextProvider>
				</TelemetryProvider>
			</Router>);
		registerServiceWorker();
	},
	() => {
		root.render(
			<I18nextProvider i18n={i18next}>
				<LoginError />
			</I18nextProvider>);
	},
	(error: Error) => {
		root.render(
			<I18nextProvider i18n={i18next}>
				<LoginError error={error} />
			</I18nextProvider>);
	}
);
