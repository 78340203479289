import VenueContextService from "./venueContextService";
import IVenueSetting from "../interfaces/IVenueSetting";
import VenueProvider from "providers/venueProvider";
import DashboardService from "./dashboardService";
import IWorkSpaceReservationSettings from "interfaces/IWorkSpaceReservationSettings";
import WorkspaceAutoCancellationSettings from "interfaces/workspaceAutoCancellationSettings";

export default class WorkspaceSettingsService {
    private static readonly prefix: string = "workspacereservationsetting_";
    private static readonly daysInAdvance: string = "daysinadvance";
    private static readonly releaseAfterMinutes: string = "releaseafterminutes";
    private static readonly ignoreReleaseStart: string = "ignorerelease_start";
    private static readonly ignoreReleaseEnd: string = "ignorerelease_end";

    private readonly venueContextService: VenueContextService
    private readonly dashboardService: DashboardService

    public constructor() {
        this.venueContextService = new VenueContextService();
        this.dashboardService = new DashboardService();
    }

    public async getWorkSpaceReservationSettings(skipCacheCheck?: boolean): Promise<IWorkSpaceReservationSettings> {
        const allSettings = await this.venueContextService.getVenueContext(skipCacheCheck);
        const reservationSettings = allSettings.filter(s => s.settingIdentifier.startsWith(WorkspaceSettingsService.prefix));

        const settings: IWorkSpaceReservationSettings = {
            daysInAdvance: 0,
            releaseAfterMinutes: 0,
            ignoreReleaseStart: "",
            ignoreReleaseEnd: ""
        };

        reservationSettings.forEach(setting => {
            if (setting.settingIdentifier.endsWith(WorkspaceSettingsService.daysInAdvance)) {
                settings.daysInAdvance = this.parseSettingsValue(setting.value);
            }

            if (setting.settingIdentifier.endsWith(WorkspaceSettingsService.releaseAfterMinutes)) {
                settings.releaseAfterMinutes = this.parseSettingsValue(setting.value);
            }

            if (setting.settingIdentifier.endsWith(WorkspaceSettingsService.ignoreReleaseStart)) {
                settings.ignoreReleaseStart = setting.value;
            }

            if (setting.settingIdentifier.endsWith(WorkspaceSettingsService.ignoreReleaseEnd)) {
                settings.ignoreReleaseEnd = setting.value;
            }
        });

        return settings;
    }

    public async upsertWorkspaceAutoCancelSettings(settings: WorkspaceAutoCancellationSettings): Promise<Response> {
        const venue = VenueProvider.getActiveVenue();
        return await this.dashboardService.upsertWorkspacesAutomaticCancellationSettings(venue!.id, settings);
    }

    public async upsertWorkSpaceReservationSettings(reservationSettings: IWorkSpaceReservationSettings): Promise<Response> {
        const settings: IVenueSetting[] = [];

        if (reservationSettings.daysInAdvance !== undefined) {
            const inAdvanceSetting = {
                value: reservationSettings.daysInAdvance.toString(),
                settingIdentifier: WorkspaceSettingsService.prefix + WorkspaceSettingsService.daysInAdvance
            };
            settings.push(inAdvanceSetting);
        }

        if (reservationSettings.releaseAfterMinutes !== undefined) {
            const releaseSetting = {
                value: reservationSettings.releaseAfterMinutes.toString(),
                settingIdentifier: WorkspaceSettingsService.prefix + WorkspaceSettingsService.releaseAfterMinutes
            };
            settings.push(releaseSetting);
        }

        if (reservationSettings.ignoreReleaseStart !== undefined) {
            const ignoreStartSetting = {
                value: reservationSettings.ignoreReleaseStart.toString(),
                settingIdentifier: WorkspaceSettingsService.prefix + WorkspaceSettingsService.ignoreReleaseStart
            };
            settings.push(ignoreStartSetting);
        }

        if (reservationSettings.ignoreReleaseEnd !== undefined) {
            const ignoreEndSetting = {
                value: reservationSettings.ignoreReleaseEnd.toString(),
                settingIdentifier: WorkspaceSettingsService.prefix + WorkspaceSettingsService.ignoreReleaseEnd
            };
            settings.push(ignoreEndSetting);
        }
        return await this.venueContextService.batchUpsertVenueSettings(settings);
    }

    private parseSettingsValue(value: string): number | undefined {
        const parsedValue = Number(value);
        return isNaN(parsedValue) ? undefined : parsedValue;
    }
}