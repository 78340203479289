import * as React from 'react';

import State from "./interfaces/iIndoorClimatePageState";

import { withTelemetry } from '../../services/telemetryService';
import { NotificationManager } from 'react-notifications';
import LanguageProvider from '../../providers/languageProvider';
import VenueProvider from '../../providers/venueProvider';
import CoreSpaceService from '../../services/coreSpaceService';
import Teaser from '../../components/teaser/indoorClimateTeaser';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import SubscriptionValidationService from '../../services/subscriptionValidationService';
import FullPageLoader from '../../components/loaders/fullPageLoader';
import ComfortInsightsPage from './comfortInsightsPage';

class IndoorClimatePage extends React.Component<{}, State> {
    private subscriptionValidationService: SubscriptionValidationService;
    private readonly coreSpaceService: CoreSpaceService;

    public constructor(props: {}) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();

        this.state = {
            loading: true,
            hasIndoorClimateSubscription: false,
        };

        this.initializeActiveVenueAsync = this.initializeActiveVenueAsync.bind(this);
        AppEventHub.on(AppEvents.BuildingSelected, this.initializeActiveVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        let activeVenue = VenueProvider.getActiveVenue();

        // Ensure a venue is selected (since this component mounts before app.tsx does).
        if (!activeVenue) {
            const venues = await this.coreSpaceService.getVenues();

            if (!venues.length) {
                NotificationManager.error(LanguageProvider.getTranslation("global-error-messages.account-configuration-incorrect"));
            } else {
                activeVenue = venues[0];
                VenueProvider.saveActiveVenue(activeVenue);
            }
        }
        if (activeVenue) {
            await this.initializeActiveVenueAsync();
        }
    }

    public async componentWillUnmount(): Promise<void> {
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeActiveVenueAsync);
    }

    private async initializeActiveVenueAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();
        const hasSubscription = venue ? this.subscriptionValidationService.venueHasAnyApplicableService(venue, ["BEVital"]) : false;

        this.setState({
            loading: false,
            hasIndoorClimateSubscription: hasSubscription,
        });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.loading && <FullPageLoader loading={this.state.loading} />}

                {!this.state.loading && <div className="indoorclimate-page">
                    <div className={`${this.state.hasIndoorClimateSubscription ? "" : "d-none"}`}>
                        <ComfortInsightsPage />
                    </div>
                    {!this.state.hasIndoorClimateSubscription && <Teaser/>}
                </div>}
            </>
        );
    }
}

export default withTelemetry(IndoorClimatePage, "IndoorClimatePage");