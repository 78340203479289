import Resizer from "react-image-file-resizer";

export const ImageToMaximizedBase64Async = (image: File): Promise<string> => {
    const promise = new Promise<string>((resolve, reject) => {
      try {
        Resizer.imageFileResizer(image, 1024, 1024, "JPEG", 100, 0, (resizedImage) => {
          return resolve(resizedImage as string);
        }, "base64");
      }
      catch (error) {
        return reject(error);
      }
    });

    return promise;
};