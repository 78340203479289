import { Component } from "react";

import './currentDataOverview.scss';

import ICurrentOccupancyOverviewProps from "./interfaces/ICurrentOccupancyOverviewProps";
import ICurrentOccupancyOverviewState from "./interfaces/ICurrentOccupancyOverviewState";

import Tile from '@/components/tile/tile';
import CoreSpaceService from "@/services/coreSpaceService";
import Dictionary from "@/utils/dictionary";
import CoreSpaceValues from "@/enums/coreSpaceValues";
import IconType from "@/components/tile/enums/iconType";
import Unit from "@/components/tile/enums/unit";
import LanguageProvider from "@/providers/languageProvider";
import CoreSpace from "@/models/coreSpace";
import CoreSpaceTypes from "@/enums/coreSpaceTypes";
import CoreSpaceIncludes from "@/enums/coreSpaceIncludes";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";
import SubscriptionValidationService from "@/services/subscriptionValidationService";

class CurrentOccupancyOverview extends Component<ICurrentOccupancyOverviewProps, ICurrentOccupancyOverviewState> {
    private readonly coreSpaceService: CoreSpaceService;
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: ICurrentOccupancyOverviewProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();

        const state: ICurrentOccupancyOverviewState = {
            loading: true,
            venues: new Map<string, CoreSpace>(),
            spaces: new Dictionary<CoreSpace[]>(),
        };

        this.state = state;
        this.initializeStatisticsAsync = this.initializeStatisticsAsync.bind(this);
        this.renderOccupancyRows = this.renderOccupancyRows.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeStatisticsAsync();
    }

    public async initializeStatisticsAsync(): Promise<void> {
        const allVenues = await this.coreSpaceService.getVenues();
        let venues = allVenues.filter(venue => this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["Motion"]));

        // use map to keep the sorting for the remaining items
        const venueDictionary = new Map<string, CoreSpace>();
        venues = venues.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        }).slice(0, 4); // only show 4 buildings

        venues.forEach(venue => venueDictionary.set(venue.id, venue));

        const tempDictionary = new Dictionary<CoreSpace[]>();
        await Promise.all(venues.map(async venue => {
            const result = await this.coreSpaceService.getSpacesForVenue(venue.id, [CoreSpaceTypes.Room, CoreSpaceTypes.Workspace], [CoreSpaceIncludes.Values]);
            tempDictionary.add(venue.id, result);
        }));

        this.setState({
            venues: venueDictionary,
            spaces: tempDictionary,
            loading: false
        });
    }

    public renderOccupancyRows(): JSX.Element[] {
        const result: JSX.Element[] = [];

        this.state.spaces.getKeys().map(id => this.state.venues.get(id))
        .filter((venue): venue is CoreSpace => !!venue)
            .forEach(venue => {
                    const venueId = venue.id;
                    const spaces = this.state.spaces.item(venueId);

                    let occupied = 0;
                    let coolingDown = 0;
                    let free = 0;
                    if (spaces.length === 0) {
                        const div = <div key={venueId} />;
                        result.push(div);
                    }
                    else {
                        for (const space of spaces) {
                            if (space.hasValue(CoreSpaceValues.OccupancyStatus)) {
                                if (space.isOccupied) {
                                    occupied++;
                                }
                                else if (space.isCoolingDown) {
                                    coolingDown++;
                                }
                                else {
                                    free++;
                                }
                            }
                        }
                    }

                    const tile = <div key={"overview-tile_" + venue.id}>
                        <h3>{venue.name}</h3>
                        <div className="row mb-4">
                            <Tile icon={IconType.assetAvailable} value={free} unit={Unit.none} subtitle={LanguageProvider.getTranslation("pages.home.occupancy.assetsavailable")} totalNumberOfTileColumns={3} />
                            <Tile icon={IconType.assetSoonAvailable} value={coolingDown} unit={Unit.none} subtitle={LanguageProvider.getTranslation("pages.home.occupancy.assetssoonavailable")} totalNumberOfTileColumns={3} />
                            <Tile icon={IconType.assetOccupied} value={occupied} unit={Unit.none} subtitle={LanguageProvider.getTranslation("pages.home.occupancy.assetsoccupied")} totalNumberOfTileColumns={3} />
                        </div>
                    </div>;
                    result.push(tile);
            });

        return result;
    }

    public render(): JSX.Element {
        const noDataMessage = LanguageProvider.getTranslation("pages.home.comfort.norecentdata");

        return (
            <>
                {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                {!this.state.loading && this.state.spaces.count === 0 && <div>{noDataMessage}</div>}
                {!this.state.loading && this.renderOccupancyRows()}
            </>
        );
    }
}
export default CurrentOccupancyOverview;