import React from "react";
import { ChartOptions, ChartData } from "chart.js";
import INoShowGraphProps from "./interfaces/INoShowGraphProps";

import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import DoughnutGraph from '@/components/graphs/doughnutGraph';
import { Context } from "chartjs-plugin-datalabels";
import { Color } from "chartjs-plugin-datalabels/types/options";
import Colors from "@/styles/colors";

export default class NoShowAveragesTotal extends React.Component<INoShowGraphProps> {

    public constructor(props: INoShowGraphProps) {
        super(props);
    }

    private getNoShowTotalOptions(displayTooltip: boolean): ChartOptions {
        const doughNutOptions: ChartOptions = {
            maintainAspectRatio: true,
            title: {
                display: false,
                text: "no-show-total"
            },
            legend: {
                display: displayTooltip,
                align: 'start',
                labels: {
                    boxWidth: 12
                }
            },
            rotation: Math.PI,
            circumference: Math.PI,
            cutoutPercentage: 63,
            plugins: {
                datalabels: {
                    display: true,
                    color: (context: Context): Color => {
                        return context.dataIndex !== 1 ?
                            Colors.black :
                            Colors.blue_grey;
                    },
                    align: 'center',
                    formatter: (value: any, context: Context): any => {
                        if (this.props.historicReservations.length === 0) {
                            return 0;
                        }
                        else {
                            return value;
                        }

                    }
                }
            },
            tooltips: {
                enabled: displayTooltip
            }
        };

        return doughNutOptions;
    }

    private getNoShowTotalData(): ChartData {

        if (this.props.historicReservations.length === 0) {
            const doughnutData: ChartData = {
                labels: [],
                datasets: [{
                    data: [1],
                    backgroundColor: [Colors.alto_grey],
                    borderWidth: 1,
                    borderColor: [Colors.alto_grey]
                }]
            };
            return doughnutData;
        }

        const noshowLabel = LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.noshows);
        const showsLabel = LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.shows);

        const noShows = this.props.historicReservations.filter(r => r.isNoShow).length;
        const shows = this.props.historicReservations.filter(r => !r.isNoShow).length;

        const doughnutData: ChartData = {
            labels: [noshowLabel, showsLabel],
            datasets: [{
                data: [noShows, shows],
                backgroundColor: [Colors.shakespeare_blue, Colors.alto_grey],
                borderWidth: 1,
                borderColor: [Colors.shakespeare_blue, Colors.alto_grey]
            }]
        };
        return doughnutData;
    }

    public render(): JSX.Element {
        const noShows = this.props.historicReservations.filter(r => r.isNoShow).length;
        const shows = this.props.historicReservations.filter(r => !r.isNoShow).length;
        const displayTooltip = this.props.historicReservations && this.props.historicReservations.length > 0;
        const options = this.getNoShowTotalOptions(displayTooltip);

        return (
            <div className="no-show-averages-component" id="no-show-total">
                <div className="no-show-graph" id="no-show-doughnut">
                    <DoughnutGraph testKey={"no-show-averages-total"} data={this.getNoShowTotalData()} options={options} showLegend={true} />
                </div>
                <div id="no-show-doughnut-subtitle">
                    <div id="total-percentage">
                        {noShows + shows !== 0 ? Math.round(noShows / (noShows + shows) * 100) : '0'}
                        <span>%</span>
                    </div>
                    <div id="total-relative">
                        {noShows} / {noShows + shows}
                    </div>
                </div>
                <div className="no-show-averages-title">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.total)}</div>
                <div className="no-show-averages-subtitle">{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.percentageofreservations)}</div>
            </div>
        );
    }

}