import React, { useState, useEffect } from 'react';

import LanguageProvider from '../../../../providers/languageProvider';

import './hourFilter.scss';

interface HourFilterProps {
    id?: string;
    onChange: (hourFrom: number, hourTo: number) => void;
    minTime: number;
    maxTime: number;
    initialHourFrom: number;
    initialHourTo: number;
}

const HourFilter: React.FC<HourFilterProps> = (props) => {
    const [hourFrom, setHourFrom] = useState<number>(props.initialHourFrom);
    const [hourTo, setHourTo] = useState<number>(props.initialHourTo);

    useEffect(() => {
        // Update state if initial values change
        setHourFrom(props.initialHourFrom);
        setHourTo(props.initialHourTo);
    }, [props.initialHourFrom, props.initialHourTo]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { name, value } = event.target;
        const numericValue = parseInt(value, 10);

        if (name === 'hourFrom') {
            if (numericValue < hourTo) {
                setHourFrom(numericValue);
                props.onChange(numericValue, hourTo);
            }
        } else if (name === 'hourTo') {
            if (numericValue > hourFrom) {
                setHourTo(numericValue);
                props.onChange(hourFrom, numericValue);
            }
        }
    }

    return (
        <div className={'hour-filter-wrapper'}>
            <label htmlFor="hourFrom">{LanguageProvider.getTranslation("pages.realestateutilization.filter.fromtime")}:</label>
            <input
                type="number"
                id="hourFrom"
                name="hourFrom"
                value={hourFrom}
                min={props.minTime}
                max={props.maxTime}
                onChange={handleChange}
            />
            <label htmlFor="hourTo">{LanguageProvider.getTranslation("pages.realestateutilization.filter.totime")}</label>
            <input
                type="number"
                id="hourTo"
                name="hourTo"
                value={hourTo}
                min={props.minTime}
                max={props.maxTime}
                onChange={handleChange}
            />
            <label>{LanguageProvider.getTranslation("pages.realestateutilization.filter.hour")}</label>
        </div>
    );
}

export default HourFilter;
