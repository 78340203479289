import * as React from "react";


import "./pollutionMapLegend.scss";

import IPollutionMapLegendProps from "./interfaces/IPollutionMapLegendProps";
import ColorUtils from "@/utils/colorUtils";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

export default class PollutionMapLegend extends React.Component<IPollutionMapLegendProps> {
    public constructor(props: IPollutionMapLegendProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div id="pollution-legend-container">

                <div>
                    {LanguageProvider.getTranslation(translations.pages.pollutionmap.legendtitle)}
                </div>

                <div className="pollution-legend-wrapper d-flex">
                    {
                        Array(this.props.upperbound + 1).fill(null).map((v, i) => {
                            const percentageColor = ColorUtils.numberToGradientColor(i, this.props.upperbound, true);
                            const gradientRenderer =
                                <div className="gradient-part"
                                    style={{ backgroundColor: percentageColor }}
                                    key={i} />;

                            const minInHours = 60;
                            const division = this.props.upperbound / 6;

                            if (i === this.props.upperbound - 5) {
                                return (
                                    <div key={percentageColor}>
                                        <div className="percentage">{this.props.upperbound / minInHours}</div>
                                        {gradientRenderer}
                                    </div>);
                            }
                            else if (i % division === 0 && i !== this.props.upperbound) {
                                return (
                                    <div key={percentageColor}>
                                        <div className="percentage">{i / minInHours}</div>
                                        {gradientRenderer}
                                    </div>);
                            }
                            else {
                                return gradientRenderer;
                            }
                        })
                    }
                </div>
            </div>
        );
    }
}