import DashboardService from "./dashboardService";
import CleaningNotification from "../models/cleaningNotification";
import ThresholdNotification from "../models/thresholdNotification";
import WorkspaceNotification from "../models/workspaceNotification";
import IThresholdNotification from "../interfaces/IThresholdNotification";
import IWorkspaceNotification from "../interfaces/IWorkspaceNotification";

export default class NotificationService {
    private dashboardService: DashboardService;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getThresholdNotificationsorCurrentVenueAsync(fromDate?: Date, toDate?: Date): Promise<ThresholdNotification[]> {
        const relativeRequest = "threshold";
        const rawModels = await this.getNotificationsWithFromAndToDateParameters<IThresholdNotification>(relativeRequest, fromDate, toDate);
        return rawModels.map(n => new ThresholdNotification(n.createdOn, n.spaceName, n.floorName, n.venueName, n.category, n.measuredValue, n.thresholdValue, n.createdBy, n.resolvedOn));
    }

    public async getWorkspaceNotificationsForCurrentVenueAsync(fromDate?: Date, toDate?: Date): Promise<WorkspaceNotification[]> {
        const relativeRequest = "workspace";
        const rawModels = await this.getNotificationsWithFromAndToDateParameters<IWorkspaceNotification>(relativeRequest, fromDate, toDate);
        return rawModels.map(n => new WorkspaceNotification(n.createdOn, n.spaceName, n.floorName, n.venueName, n.category, n.createdBy, n.resolvedOn));
    }

    // Get the most recent cleaning notifications, count equals number of notifications
    public async getRecentCleaningNotificationsAsync(count: number): Promise<CleaningNotification[]> {
        return [];
    }

    // Query for notifications by customerId and daterange
    public async getCleaningNotificationsAsync(fromDate: Date, toDate: Date): Promise<CleaningNotification[]> {
        return [];
    }

    private async getNotificationsWithFromAndToDateParameters<T>(relativeRequest: string, fromDate?: Date, toDate?: Date): Promise<T[]> {
        if (fromDate) {
            const formattedFromDate = DashboardService.formatDate(fromDate);
            relativeRequest += `?fromDate=${formattedFromDate}`;
            if (toDate) {
                const formattedToDate = DashboardService.formatDate(toDate);
                relativeRequest += `&toDate=${formattedToDate}`;
            }
        } else if (toDate) {
            const formattedToDate = DashboardService.formatDate(toDate);
            relativeRequest += `?toDate=${formattedToDate}`;
        }

        return await this.dashboardService.getByCustomerAndVenueId<T[]>("notifications", relativeRequest).catch(e => {
            console.error(JSON.stringify(e));
            const emptyList: T[] = [];
            return emptyList;
        });
    }
}
