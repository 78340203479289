import { PaginationOptions } from "react-data-table-component";
import LanguageProvider from "@/providers/languageProvider";
export default class PageUtils {
    public static setPageTitle(title: string): void {
        document.title = `${title} | Beyond Eyes`;
    }
    public static getDefaultPaginationOptions(): PaginationOptions {
        return { rangeSeparatorText: LanguageProvider.getTranslation("pagination.of"), rowsPerPageText: LanguageProvider.getTranslation("pagination.itemsperpage") };
    }
}
