import * as React from 'react';
import moment from 'moment';

import LanguageProvider, { LanguageCode } from '@/providers/languageProvider';
import AccessService from '@/services/accessService';
import Translations from '@/translations/mapper';

import './userSettingsMenu.scss';
import NLIcon from "@/images/lang_flag_nl.svg";
import ENIcon from "@/images/lang_flag_en.svg";
import ProfileIcon from "@/images/Avatar_wit.svg";
import { WithTranslation, withTranslation } from 'react-i18next';

class UserSettingsMenu extends React.Component<WithTranslation> {

    private accessService: AccessService;

    public constructor(props: WithTranslation) {
        super(props);
        this.accessService = new AccessService();
        this.logoutUser = this.logoutUser.bind(this);
        this.toggleLanguage = this.toggleLanguage.bind(this);
    }

    private logoutUser(): void {
        this.accessService.signOut();
    }

    private toggleLanguage(lang: LanguageCode): void {
        const str = LanguageCode[lang];
        LanguageProvider.activeLanguage = lang;
        this.props.i18n.changeLanguage(str.toLowerCase());
        moment.locale(str.toLocaleLowerCase());
    };

    private getActiveLanguageText(): string {
        switch (LanguageProvider.activeLanguage) {
            case LanguageCode.EN:
                return LanguageProvider.getTranslation(Translations.usersettingsmenu.languages.english);
            case LanguageCode.NL:
                return LanguageProvider.getTranslation(Translations.usersettingsmenu.languages.dutch);
        }
    }

    public render(): JSX.Element {

        return (
            <div className="dropdown" id="user-settings-menu">

                <div className="clickable d-flex" data-toggle="dropdown" id="user-settings-button" aria-haspopup="true" aria-expanded="false">
                    <div className="mr-4">
                        <div className="default-text">{LanguageProvider.getTranslation(Translations.usersettingsmenu.welcome)}</div>
                        <div className="font-weight-bold">{this.accessService.getName()}</div>
                    </div>
                    <div className="d-flex align-items-end">
                        <img src={ProfileIcon} id="profile-icon" />
                    </div>
                </div>

                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="user-settings-button">
                    <div className="dropdown-item d-flex justify-content-between">
                        <div className="user-info">
                            <h3 className="p-0 m-0 font-weight-bolder">{LanguageProvider.getTranslation(Translations.usersettingsmenu.account)}</h3>
                            <div className="font-weight-normal">{this.accessService.getName()}</div>
                            <div className="font-weight-normal">{this.accessService.getUserName()}</div>
                        </div>
                        <div
                            onClick={this.logoutUser}
                            className="clickable d-flex btn-logout align-items-center justify-content-center">
                            {LanguageProvider.getTranslation(Translations.usersettingsmenu.logout)}
                        </div>
                    </div>

                    <div className="dropdown-item-separator mt-2 mb-2 ml-4 mr-4"></div>

                    <div className="dropdown-item d-flex justify-content-between">
                        <div>
                            <h3 className="p-0 m-0 font-weight-bolder">{LanguageProvider.getTranslation(Translations.usersettingsmenu.language)}</h3>
                            <div className="font-weight-normal">{this.getActiveLanguageText()}</div>
                        </div>
                        <div className="d-flex align-items-center language-items">
                            <div
                                data-test="language-toggle-en"
                                className={`clickable ${LanguageProvider.activeLanguage === LanguageCode.EN ? 'selected' : ''}`}
                                onClick={(): void => this.toggleLanguage(LanguageCode.EN)}>
                                <img src={ENIcon} />
                                <span className="ml-2">{LanguageCode[LanguageCode.EN]}</span>
                            </div>
                            <div
                                data-test="language-toggle-nl"
                                className={`ml-5 clickable ${LanguageProvider.activeLanguage === LanguageCode.NL ? 'selected' : ''}`}
                                onClick={(): void => this.toggleLanguage(LanguageCode.NL)}>
                                <img src={NLIcon} />
                                <span className="ml-2">{LanguageCode[LanguageCode.NL]}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(UserSettingsMenu);