import IReactSelectValue from "../../../interfaces/IReactSelectValue";

export type ReservedTimeData = {
    [key: string]: number[];
};

export default class TempReservationMockData {

    public static generateReservedTimeData(): ReservedTimeData {
        const data: ReservedTimeData = {
            maandag: [3, 17, 29, 30, 31, 30, 28, 20, 1, 3, 5, 40, 32, 5, 8, 25, 13, 14, 15, 16, 17, 18, 19, 20],
            dinsdag: [3, 22, 37, 39, 40, 39, 38, 29, 2, 4, 6, 7, 8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            woensdag: [5, 31, 44, 46, 46, 46, 46, 35, 2, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
            donderdag: [4, 29, 44, 47, 47, 47, 46, 34, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
            vrijdag: [2, 15, 24, 25, 25, 25, 25, 17, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        };

        return data;
    }

    public static generateDayOptions(): IReactSelectValue[] {
        const dayOptions: IReactSelectValue[] = [];
        const daysOfWeek = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag', 'zondag'];

        daysOfWeek.forEach((day, index) => {
            dayOptions.push({
                label: day,
                value: `day-${index + 1}`
            });
        });

        return dayOptions;
    }

    public static generateHolidayOptions(): IReactSelectValue[] {
        const holidayOptions: IReactSelectValue[] = [];
        const daysOfWeek = ['Goede Vrijdag', 'Hemelvaart', 'Koningsdag', 'Nieuwjaarsdag'];

        daysOfWeek.forEach((day, index) => {
            holidayOptions.push({
                label: day,
                value: `holiday-${index + 1}`
            });
        });

        return holidayOptions;
    }

    public static generateVenueOptions(): IReactSelectValue[] {
        const venueOptions: IReactSelectValue[] = [];
        const officeBuildings = [
            'De Rotterdam', 'Maastoren', 'WTC Amsterdam', 'The Edge', 'Zuidas',
            'Hoog Catharijne', 'De Cope', 'De Oliphant', 'Rembrandt Tower',
            'Millennium Tower', 'Atrium', 'Infinity', 'Symphony', 'Amstel Tower',
            'Mondriaan Tower', 'ITO Tower', 'UN Studio', 'Riviera Tower',
            'Alpha Tower', 'Beta Building'
        ];

        officeBuildings.forEach((building, index) => {
            venueOptions.push({
                label: building,
                value: `venue-${index + 1}`
            });
        });

        return venueOptions;
    }

    public static generateFloorOptions(): IReactSelectValue[] {
        const floorOptions: IReactSelectValue[] = Array.from({ length: 5 }, (_, i) => ({
            label: `Verdieping ${i}`,
            value: `floor-${i}`
        }));

        return floorOptions;
    }

    public static generatePlaceOptions(): IReactSelectValue[] {
        const placeOptions: IReactSelectValue[] = [];
        const placePrefix = 'H RO65 2DZ1.';

        for (let i = 1; i <= 100; i++) {
            const placeNumber = i.toString().padStart(2, '0'); // Ensures two-digit format
            placeOptions.push({
                label: `${placePrefix}${placeNumber} Werkplek`,
                value: `place-${i}`
            });
        }

        return placeOptions;
    }

    public static generatePlaceTypeOptions(): IReactSelectValue[] {
        const placeTypes: IReactSelectValue[] = [];
        const categories = [
            'Woningdichtheid', 'Vastgoedbel', 'Wijk', 'Uitwisselbaarheid',
            'Staat van het Kanal', 'Heilmanas Nederland Infra Concentratiegebied',
            'Beschikbaar Natuurgebieden', 'Algemeen'
        ];

        categories.forEach((category, index) => {
            placeTypes.push({
                label: category,
                value: `type-${index + 1}`
            });
        });

        return placeTypes;
    }

    public static generateWeekOptions(): IReactSelectValue[] {
        const weekOptions: IReactSelectValue[] = [];
        const startDate = new Date('2024-01-01');
        let weekNumber = 1;

        while (startDate.getFullYear() === 2024) {
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6);

            const startDateString = startDate.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' });
            const endDateString = endDate.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' });

            weekOptions.push({
                label: `wk ${weekNumber} '24 (${startDateString} - ${endDateString})`,
                value: `week-${weekNumber}`
            });

            startDate.setDate(startDate.getDate() + 7);
            weekNumber++;
        }

        return weekOptions;
    }
}