import { ISpaceOnMap, IMarker, ISpaceStyle, IStyle, IStyler } from "@beyondeyes/shared";
import CoreSpaceTypes from "enums/coreSpaceTypes";
import ISpaceRestriction from "interfaces/ISpaceRestriction";
import CoreSpace from "models/coreSpace";
import { UnavailableWorkPlaceImage } from "@/images";
import Colors from "@/styles/colors";
import BaseStyler from "./baseStyler";

class AvailabilityStyler extends BaseStyler implements IStyler  {
	private changedSpaceRestrictions: ISpaceRestriction[];

	public constructor() {
		super()
		this.changedSpaceRestrictions = [];
	}

	public getStyle(spaceOnMap: ISpaceOnMap): IStyle | undefined {
		const space = spaceOnMap.beSpace as CoreSpace;

		if (space == null) {
			return undefined;
		}

		const isWorkspace = space.type === CoreSpaceTypes.Workspace;
		const isRestricted = isWorkspace ? this.spaceIsRestricted(space) : false;
		const imageSize = 24;

		if (!isWorkspace) {
			return undefined;
		}

		const placeStyle = isRestricted ? this.getRestrictedWorkspaceStyle() : this.getNotRestrictedWorkspaceStyle();
		const placeMarker = isRestricted ? this.createRestrictedMarker(imageSize) : undefined;

		return {
			placeStyle: placeStyle,
			placeMarker: placeMarker,
			placeLabel: { text: space.name, size: this.defaultLabelSize }
		};
	}

	public setChangedSpaceRestrictions(changedSpaceRestrictions: ISpaceRestriction[]): void {
		this.changedSpaceRestrictions = changedSpaceRestrictions;
	}

	private spaceIsRestricted(space: CoreSpace): boolean {
		const currentSpaceRestriction = this.changedSpaceRestrictions.find((r) => r.spaceId === space.id);
		if (currentSpaceRestriction) {
			return currentSpaceRestriction.restricted;
		} else {
			return space.isRestricted;
		}
	}

	private getRestrictedWorkspaceStyle(): ISpaceStyle {
		return {
			fillColor: Colors.black,
			fillOpacity: 0.95,
		};
	}

	private getNotRestrictedWorkspaceStyle(): ISpaceStyle {
		return {
			fillColor: Colors.signal_green,
			fillOpacity: 0.95,
		};
	}

	private createRestrictedMarker(imageSize: number): IMarker {
		return {
			image: UnavailableWorkPlaceImage,
			imageSize: imageSize,
		};
	}
}

export default AvailabilityStyler;
