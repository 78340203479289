import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";

import IAbnormalValuesOverviewProps from "./interfaces/IAbnormalValuesOverviewProps";
import AbnormalSpaceValue from "./models/abnormalSpaceValue";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import LanguageProvider from "@/providers/languageProvider";

import './abnormalValuesOverview.scss';

const AbnormalValuesOverview: React.FC<IAbnormalValuesOverviewProps> = (props) => {

    const [columns, setColumns] = useState<any>();

    useEffect(() => {

        updateTable();
        AppEventHub.on(AppEvents.LanguageChanged, updateTable);

        return (): void => { AppEventHub.off(AppEvents.LanguageChanged, updateTable); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function updateTable(): void {
        setColumns(getColumns());
    }

    function getColumns(): TableColumn<AbnormalSpaceValue>[] {
        const columns = [
            {
                id: 'spaceName',
                name: LanguageProvider.getTranslation("pages.heatmap.table.asset_name"),
                selector: (row: AbnormalSpaceValue): string => row.spaceName
            },
            {
                id: 'occupancyPercentage',
                name: LanguageProvider.getTranslation("pages.heatmap.table.occupancy"),
                selector: (row: AbnormalSpaceValue): string => `${Math.round(row.occupancyPercentage)}%`
            }];
        return columns;
    }

    const paginationComponentOptions = {
        rowsPerPageText: LanguageProvider.getTranslation("tables.rowsperpage")
    };

    return (
        <div id="abnormal-values-overview-component">
            <h2>{LanguageProvider.getTranslation("pages.heatmap.abnormal_values_header")}</h2>
            <div className="row">
                <div className="col-sm-12">
                    <DataTable
                        className="abnormal-values-table besense-grey-border-table"
                        progressPending={props.loading}
                        columns={columns}
                        data={props.abnormalities}
                        noDataComponent={LanguageProvider.getTranslation("tables.nodata")}
                        paginationComponentOptions={paginationComponentOptions}
                        progressComponent={LanguageProvider.getTranslation("tables.loadingtext")}
                        pagination={props.abnormalities.length >= 10}
                        paginationPerPage={5}
                    />
                </div>
            </div>
        </div>
    );
}

export default AbnormalValuesOverview