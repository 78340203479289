import VenueContextService from "./venueContextService";
import INoShowSettings from "../interfaces/INoShowSettings";
import IRoomNoShowSettings from "interfaces/IRoomNoShowSettings";
import DashboardService from "./dashboardService";
import VenueProvider from "../providers/venueProvider";

export default class NoShowSettingService {
    private readonly venueContextService: VenueContextService;
    private readonly dashboardService: DashboardService;

    public constructor() {
        this.venueContextService = new VenueContextService();
        this.dashboardService = new DashboardService();
    }

    public async getNoShowSettings(skipCacheCheck?: boolean): Promise<INoShowSettings> {
        const noShowSetting = await this.venueContextService.getVenueSetting("noshowsetting_noshowafter", skipCacheCheck);
        const settings: INoShowSettings = {
            noShowAfter: Number(noShowSetting.value)
        };

        return settings;
    }

    public async upsertNoShowSettings(noShowSettings: INoShowSettings): Promise<Response> {
        const venue = VenueProvider.getActiveVenue();
        const noShowSettingsForRooms: IRoomNoShowSettings = {
            ReleaseRoomNoShowTimeInMinutes: noShowSettings.noShowAfter!
        };

        return await this.dashboardService.upsertRoomNoShowSettings(venue!.customerId, venue!.id, noShowSettingsForRooms);
    }
}