import * as React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import IEnergyContractTileCollectionState from "./interfaces/IEnergyContractTileCollectionState";
import IEnergyContractTileCollectionProps from "./interfaces/IEnergyContractTileCollectionProps";
import LanguageProvider from "@/providers/languageProvider";
import IconType from "@/components/tile/enums/iconType";
import Unit from "@/components/tile/enums/unit";
import Tile from "@/components/tile/tile";
import links from "@/utils/links";

import "./contractTileCollection.scss";

import ArrowRight from "@/images/Blue_Arrow_Right.svg";


class EnergyContractTileCollection extends React.Component<IEnergyContractTileCollectionProps, IEnergyContractTileCollectionState> {
    public constructor(props: IEnergyContractTileCollectionProps) {
        super(props);

        this.redirectToDocumentsPage = this.redirectToDocumentsPage.bind(this);
        this.getPromisedSavingsSubtitle = this.getPromisedSavingsSubtitle.bind(this);
    }

    private redirectToDocumentsPage(): void {
        this.props.history.push(links.documents);
    }

    private getPromisedSavingsSubtitle(): string {
        if(this.hasElectricitySubscription && this.hasGasSubscription){
            return LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.deal");
        }
        else if(this.hasElectricitySubscription){
            return LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.electricitydeal");
        }
        else if(this.hasGasSubscription){
            return LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.gasdeal");
        }
        else if(this.hasHeatOrColdSubscription){
            return LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.deal");
        }

        return '';
    }

    private get hasGasSubscription(): boolean {
        return this.props.contracts.find(c => c.type === 'Gas') !== undefined;
    }

    private get hasElectricitySubscription(): boolean {
        return this.props.contracts.find(c => c.type === 'Electricity') !== undefined;
    }

    private get hasHeatOrColdSubscription(): boolean {
        return this.props.contracts.find(c => c.type === 'Heat' || c.type === 'Cold') !== undefined;
    }

    public render(): JSX.Element {
        // Note: all energy contracts on the same venue should share start/end dates and contact details, so we can choose a contract at random for these
        // details:
        const contract = this.props.contracts[0];

        // Note: all contracts on the same venue with savings filled out should share the same promised total savings.
        const contractWithSavings = this.props.contracts.find(c => c.promisedSavings);

        const nrMonthsLeft = contract.monthsDuration;
        const nrYearsLeft = contract.yearsDuration;
        const monthsLeft = nrMonthsLeft > 0;
        const yearsLeft = nrYearsLeft > 0;
        const contractStartDate = moment(contract.startDate).format('DD-MM-YYYY');
        const contractEndDate = moment(contract.endDate).format('DD-MM-YYYY');

        return (
            <span className="contract-tile-collection">
                <div className="row mt-4">
                    <Tile
                        subtitle={`${LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.start")} ${contractStartDate}`}
                        totalNumberOfTileColumns={3}
                        value={LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.start")}
                        icon={IconType.calenderChecked}
                        unit={Unit.none}
                    />
                    <Tile
                        subtitle={`${LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.end")} ${contractEndDate}`}
                        totalNumberOfTileColumns={3}
                        value={LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.end")}
                        icon={IconType.calenderCrossed}
                        unit={Unit.none}
                    />
                    <Tile
                        subtitle={`${yearsLeft ?
                            `${nrYearsLeft} ${LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.year")}` :
                            ''}${yearsLeft && monthsLeft ? ` ${LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.and")}` : ''
                            }${monthsLeft ?
                                ` ${nrMonthsLeft} ${LanguageProvider.getTranslation(`pages.energy.tabs.overview.contractinformation.subtitles.month${nrMonthsLeft > 1 ? 's' : ''}`)}` :
                                ''
                            } ${LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.duration")}`}
                        totalNumberOfTileColumns={3}
                        value={LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.duration")}
                        icon={IconType.calendar}
                        unit={Unit.none}
                    />
                </div>

                <div className="row mt-4">
                    <Tile
                        icon={IconType.contact}
                        subtitle={contract.contactName || ""}
                        subtitle2={contract.contactPhoneNumber || ""}
                        subtitle3={contract.contactEmail || ""}
                        value={LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.contact")}
                        totalNumberOfTileColumns={3}
                        unit={Unit.none}
                    />
                    <Tile
                        icon={IconType.handShake}
                        subtitle={`${contractWithSavings?.promisedSavings || 0}% ${this.getPromisedSavingsSubtitle()}`}
                        value={LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.deal")}
                        totalNumberOfTileColumns={3}
                        unit={Unit.none}
                    />
                    <Tile
                        icon={IconType.contract}
                        subtitle={
                            <span>
                                {LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.subtitles.contract")}
                                <img className="document-redirect-img" onClick={this.redirectToDocumentsPage} src={ArrowRight} />
                            </span>
                        }
                        value={LanguageProvider.getTranslation("pages.energy.tabs.overview.contractinformation.contract")}
                        unit={Unit.none}
                        totalNumberOfTileColumns={3}
                    />
                </div>
            </span>
        );
    }
}

export default withRouter(withTranslation()(EnergyContractTileCollection));