import React from "react";

import INoShowAveragesProps from "./interfaces/INoShowAveragesProps";

import './noShowAverages.scss';

import NoShowAveragesTotal from './components/noShowAveragesTotal';
import NoShowAveragesTopFive from './components/noShowAveragesTopFive';
import NoShowAveragesHours from './components/noShowAveragesHours';
import NoShowAveragesInterval from './components/noShowAveragesInterval';
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

export default class NoShowAverages extends React.Component<INoShowAveragesProps> {

    public constructor(props: any) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div id="no-show-averages">
                <h2>
                    {LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.averages.title)}
                </h2>
                <div>
                    <NoShowAveragesTotal historicReservations={this.props.historicReservations} />
                    <NoShowAveragesTopFive historicReservations={this.props.historicReservations} />
                    <NoShowAveragesHours historicReservations={this.props.historicReservations} totalOpenDays={this.props.totalOpenDays} />
                    <NoShowAveragesInterval historicReservations={this.props.historicReservations} />
                </div>
            </div>
        );
    }
}