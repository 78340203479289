import * as React from "react";
import moment from 'moment';
import 'moment/locale/nl';
import { NotificationManager } from 'react-notifications';

import './comfortThresholdsSettingsPage.scss';

import LanguageProvider from "@/providers/languageProvider";
import IComfortThresholdsSettingsPageState from "./interfaces/IComfortThresholdsSettingsPageState";
import IComfortThresholdsSettingsPageProps from "./interfaces/IComfortThresholdsSettingsPageProps";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import ThresholdSettingLine from "./components/thresholdSettingLine";
import translations from "@/translations/mapper";
import DataThresholds from "@/models/dataThresholds";
import DataThresholdsService from "@/services/dataThresholdsService";
import { withTelemetry } from "@/services/telemetryService";
import DayTimeNotificationSettingsService from '@/services/dayTimeNotificationSettingsService';
import SubscriptionValidationService from "@/services/subscriptionValidationService";
import IndoorClimateSettingsPage from "../indoorClimateSettingsPage";

import FullPageLoader from "@/components/loaders/fullPageLoader";
import VenueProvider from "@/providers/venueProvider";

class ComfortThresholdsSettingsPage extends React.Component<IComfortThresholdsSettingsPageProps, IComfortThresholdsSettingsPageState> {
    private readonly dataThresholdsService: DataThresholdsService;
    private subscriptionValidationService: SubscriptionValidationService;
    private readonly dayTimeNotificationSettingsService: DayTimeNotificationSettingsService;

    private readonly allDaysOfWeek: string = "7";
    private readonly weekdays: string[] = ["1", "2", "3", "4", "5", "6", "0"];

    public constructor(props: IComfortThresholdsSettingsPageProps) {
        super(props);

        const state: IComfortThresholdsSettingsPageState = {
            venueThresholds: [],
            loading: true,
            hasSubscriptions: false,
            selectedWeekdaySettings: this.weekdays,
            selectedStartTimeSetting: "0:00",
            selectedEndTimeSetting: "23:59",
            enableSaveButton: false,
            validStartTime: true,
            validEndTime: true
        };

        this.state = state;

        this.dataThresholdsService = new DataThresholdsService();
        this.dayTimeNotificationSettingsService = new DayTimeNotificationSettingsService();

        this.initializeVenueAsync = this.initializeVenueAsync.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateThresholdsInState = this.updateThresholdsInState.bind(this);
        this.setSubscriptionsAsync = this.setSubscriptionsAsync.bind(this);
        this.validateTimeInput = this.validateTimeInput.bind(this);
        this.onNotificationEndTimeChange = this.onNotificationEndTimeChange.bind(this);
        this.onNotificationStartTimeChange = this.onNotificationStartTimeChange.bind(this);
        this.validateStartTimeIsEarlierThanEndTime = this.validateStartTimeIsEarlierThanEndTime.bind(this);
        this.toggleWeekday = this.toggleWeekday.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.on(AppEvents.BuildingSelected, this.setSubscriptionsAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeVenueAsync();
        await this.setSubscriptionsAsync();
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.off(AppEvents.BuildingSelected, this.setSubscriptionsAsync);
    }

    private async setSubscriptionsAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();

        const hasReserveRoomsSubscription = venue ? await this.subscriptionValidationService.venueHasAnyApplicableSubscription(venue, ["ReserveRooms"]): false;
        const hasOccupancySubscription = venue ? await this.subscriptionValidationService.customerHasAnyApplicableService(venue.customerId, ["BEEquipped"]): false;

        this.setState({ hasSubscriptions: hasOccupancySubscription && hasReserveRoomsSubscription });
    }

    private async initializeVenueAsync(): Promise<void> {
        const venueThresholds = await this.dataThresholdsService.getAllDataThresholds(true);
        const notificationSettings = await this.dayTimeNotificationSettingsService.getDayTimeNotificationSettingsAsync();

        this.setState({
            venueThresholds: venueThresholds.sort((a, b) => a.dataType > b.dataType ? 1 : -1),
            loading: false,
            selectedWeekdaySettings: notificationSettings.weekdays.includes(this.allDaysOfWeek) ? this.weekdays : [...notificationSettings.weekdays],
            selectedStartTimeSetting: notificationSettings.starttime,
            selectedEndTimeSetting: notificationSettings.endtime,
            enableSaveButton: false
        });
    }

    private onNotificationStartTimeChange(event: any): void {
        const startTime = event.target.value;
        const validTimeInput = this.validateTimeInput(startTime);

        this.setState({
            selectedStartTimeSetting: startTime,
            validStartTime: validTimeInput,
            enableSaveButton: true
        });
    }

    private onNotificationEndTimeChange(event: any): void {
        const endTime = event.target.value;
        const validTimeInput = this.validateTimeInput(endTime);

        this.setState({
            selectedEndTimeSetting: endTime,
            validEndTime: validTimeInput,
            enableSaveButton: true
        });
    }

    private validateTimeInput(time: string): boolean {
        const regEx = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/;
        return regEx.test(time);
    }

    private validateStartTimeIsEarlierThanEndTime(startTime: string, endTime: string): boolean {
        // startTime and endTime will never be undefined
        const startTimeHoursMinutes = startTime.split(':');
        const endTimeHoursMinutes = endTime.split(':');

        if (startTimeHoursMinutes.length !== 2 || endTimeHoursMinutes.length !== 2) {
            return false;
        }

        const start = moment({ hour: +startTimeHoursMinutes[0], minute: +startTimeHoursMinutes[1] });
        const end = moment({ hour: +endTimeHoursMinutes[0], minute: +endTimeHoursMinutes[1] });

        return start < end;
    }

    private async handleSubmit(): Promise<void> {
        if (this.state.loading) {
            return;
        }

        if (!this.state.validStartTime || !this.state.validEndTime) {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.invalidtime));
            return;
        }

        if (!this.validateStartTimeIsEarlierThanEndTime(this.state.selectedStartTimeSetting, this.state.selectedEndTimeSetting)) {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.invalidstartendtimecombination));
            return;
        }

        const result = await this.dataThresholdsService.upsertDataThresholds(this.state.venueThresholds);

        const weekdays = this.state.selectedWeekdaySettings.length === 7 ? [this.allDaysOfWeek] : this.state.selectedWeekdaySettings;
        const dayTimeNotificationResult = await this.dayTimeNotificationSettingsService.upsertDayTimeNotificationSettings(
            this.state.selectedStartTimeSetting,
            this.state.selectedEndTimeSetting,
            weekdays);

        if (result.status === 204 && dayTimeNotificationResult.status) {
            NotificationManager.success(LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.saved));

            this.setState({
                enableSaveButton: false
            });
        }
        else {
            NotificationManager.error(LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.erroronsave));
        }
    }

    private updateThresholdsInState(thresholdsUpdate: DataThresholds): void {
        const relevantThreshold = this.state.venueThresholds.find(t => t.dataType === thresholdsUpdate.dataType);
        if (relevantThreshold === undefined) {
            return;
        }
        relevantThreshold.updateThresholdsValues(thresholdsUpdate);

        this.setState({
            enableSaveButton: true
        });
    }

    private toggleWeekday(weekDay: string): void {
        let selectedWeekdays = this.state.selectedWeekdaySettings;

        if (selectedWeekdays.includes(weekDay)) {
            selectedWeekdays = selectedWeekdays.filter(w => w !== weekDay);
        }
        else {
            selectedWeekdays.push(weekDay);
        }

        this.setState({
            selectedWeekdaySettings: selectedWeekdays,
            enableSaveButton: true
        });
    }

    public render(): JSX.Element {
        return (
            <IndoorClimateSettingsPage
                displaySaveButton={true}
                enableSaveButton={this.state.enableSaveButton}
                onSave={this.handleSubmit}>
                {this.state.loading && <FullPageLoader loading={true} viewHeightPxReduction={200} />}
                {!this.state.loading &&
                    <div className="main-content" id="thresholds-settings">
                        <div className="main-content-header">
                            <div className="row header-margin-bottom-paragraph">
                                <div className="col-sm">
                                    <h1>{LanguageProvider.getTranslation(translations.pages.settings.workspaces.comfortsettings)}</h1>
                                    <div className="row">
                                        <div className="col-sm-9">
                                            {LanguageProvider.getTranslation(translations.pages.settings.workspaces.comfortsettingsdescription)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-content-body">
                            <div className="row">
                                <div className="col-sm">
                                    <h2>{LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.receivenotifications)}</h2>
                                </div>
                            </div>
                            <div className="row mt-3 mb-5">
                                <div className="col-sm">
                                    <div id="select-days-column" className="mb-4">
                                        <h3>{LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.selectdays)}</h3>
                                    </div>
                                    <div className="d-flex ml-1">
                                        {
                                            this.weekdays.map(w => {
                                                return (
                                                    <div
                                                        className="checkbox-container clickable mr-5"
                                                        onClick={(): void => this.toggleWeekday(w)}
                                                        key={w}>
                                                        <input
                                                            className="clickable"
                                                            type="checkbox"
                                                            data-test={`checkbox-${w}`}
                                                            readOnly={true}
                                                            checked={this.state.selectedWeekdaySettings.includes(w)} />
                                                        <label className="form-check-label clickable ml-3" htmlFor="flexCheckChecked">
                                                            {LanguageProvider.getTranslation(`pages.settings.workspaces.generaltab.weekdays.weekday${w}`)}
                                                        </label>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4 notification-time">
                                    <div id="select-timeframe-column" className="mb-4">
                                        <h3>{LanguageProvider.getTranslation(translations.pages.settings.comfortthresholds.selecttimeframe)}</h3>
                                    </div>
                                    <input
                                        className={`form-control ${this.state.validStartTime ? '' : 'is-invalid'}`}
                                        required={true}
                                        value={this.state.selectedStartTimeSetting}
                                        onChange={this.onNotificationStartTimeChange}
                                    />
                                    <input
                                        className={`form-control ${this.state.validEndTime ? '' : 'is-invalid'}`}
                                        required={true}
                                        value={this.state.selectedEndTimeSetting}
                                        onChange={this.onNotificationEndTimeChange}
                                    />
                                    {(!this.state.validStartTime || !this.state.validEndTime) &&
                                        <p className="text-danger">
                                            {LanguageProvider.getTranslation(translations.pages.settings.workspaces.generaltab.invalidtime)}
                                        </p>}
                                </div>
                            </div>
                            <div className="content-divider" />
                            {this.state.venueThresholds.map((t, i) =>
                                <ThresholdSettingLine
                                    thresholds={t}
                                    thresholdUpdateCallback={this.updateThresholdsInState}
                                    key={`settingLine-${i}`} />)}
                        </div>
                    </div>}
            </IndoorClimateSettingsPage>
        );
    }
}

export default withTelemetry(ComfortThresholdsSettingsPage, "ComfortThresholdsSettingsPage");