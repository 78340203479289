import * as React from "react";
import { withTranslation } from 'react-i18next';

import IEnergyManagementOverviewProps from "./interfaces/IEnergyManagementOverviewProps";
import IEnergyManagementOverviewState from "./interfaces/IEnergyManagementOverviewState";
import EnergyService from "@/services/energyService";
import LanguageProvider from "@/providers/languageProvider";
import Unit from "@/components/tile/enums/unit";
import Tile from "@/components/tile/tile";
import IconType from "@/components/tile/enums/iconType";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";
import AppEventHub, { AppEvents } from "utils/appEventHub";
import SubscriptionValidationService from "services/subscriptionValidationService";
import VenueProvider from "providers/venueProvider";

class EnergyManagementOverview extends React.Component<IEnergyManagementOverviewProps, IEnergyManagementOverviewState> {

    private readonly energyService: EnergyService;
    private subscriptionValidationService: SubscriptionValidationService;

    public constructor(props: IEnergyManagementOverviewProps) {
        super(props);

        const state: IEnergyManagementOverviewState = {
            loading: true,
            hasElectricitySubscription: false,
            hasGasSubscription: false,
            hasHeatSubscription: false
        };

        this.loadCurrentVenueAsync = this.loadCurrentVenueAsync.bind(this);

        this.state = state;
        this.energyService = new EnergyService();

        AppEventHub.on(AppEvents.BuildingSelected, this.loadCurrentVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.loadCurrentVenueAsync();
    }

    public async componentWillUnmount(): Promise<void> {
        AppEventHub.off(AppEvents.BuildingSelected, this.loadCurrentVenueAsync);
    }

    private async loadCurrentVenueAsync(): Promise<void> {
        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        const customerId = venue.customerId;

        const managementOverview = await this.energyService.GetManagementOverviewAsync();
        const gasSubscription = this.subscriptionValidationService.customerHasAnyApplicableSubscription(customerId, ["Gas"]);
        const electricitySubscription = this.subscriptionValidationService.customerHasAnyApplicableSubscription(customerId, ["Electricity"]);
        const heatSubscription = this.subscriptionValidationService.customerHasAnyApplicableSubscription(customerId, ["Heat"]);

        this.setState({
            loading: false,
            energyManagementOverview: managementOverview,
            hasElectricitySubscription: electricitySubscription,
            hasGasSubscription: gasSubscription,
            hasHeatSubscription: heatSubscription
        });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                {!this.state.loading && <div id="energymanagementoverview">
                    <div className="row">
                        <div className="col-md-4">
                            <h3>{LanguageProvider.getTranslation("energy.managementoverview.tiles.total.title")}</h3>
                        </div>
                        <div className="col-md-4">
                            <h3>{LanguageProvider.getTranslation("energy.managementoverview.tiles.thisyear.title")}</h3>
                        </div>
                        <div className="col-md-4">
                            <h3>{LanguageProvider.getTranslation("energy.managementoverview.tiles.savings.title")}</h3>
                        </div>
                    </div>
                    <div className="row pb-4">
                        <Tile
                            unit={Unit.euro}
                            icon={IconType.pigEuro}
                            subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.total.costs")}
                            totalNumberOfTileColumns={3}
                            value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.totalSavingsInCurrency) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.total.costs")}
                        />
                        <Tile
                            unit={Unit.euro}
                            icon={IconType.buildingEuro}
                            subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.thisyear.costs")}
                            totalNumberOfTileColumns={3}
                            value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.thisYearSavingsInCurrency) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.this-year.costs")}
                        />
                        <Tile
                            unit={Unit.percentage}
                            icon={IconType.buildingSavings}
                            subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.savings.percentage")}
                            totalNumberOfTileColumns={3}
                            value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.thisYearSavingsPercentage) : '-'}
                            tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.savings.totalpercentage")}
                        />
                    </div>
                    {this.state.hasElectricitySubscription && <>
                        <div className="row solar-panel-row">
                            <Tile
                                unit={Unit.kWh}
                                icon={IconType.costSavingsElectricity}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.total.electricity")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.totalElectricitySavingsInkWh) : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.total.electricity")}
                            />
                            <Tile
                                unit={Unit.kWh}
                                icon={IconType.energy}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.thisyear.electricity")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.thisYearElectricitySavingsInkWh) : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.this-year.electricity")}
                            />
                            <Tile
                                unit={Unit.none}
                                icon={IconType.solarpanels}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.savings.solarpanels")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? this.state.energyManagementOverview.virtualSolarPanels : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.savings.solarpanels")}
                            />
                        </div>
                    </>}
                    {this.state.hasGasSubscription && <>
                        <div className="row pb-4">
                            <Tile
                                unit={Unit.m3}
                                icon={IconType.costSavingsGas}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.total.gas")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.totalGasSavingsInM3) : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.total.gas")}
                            />
                            <Tile
                                unit={Unit.m3}
                                icon={IconType.gas}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.thisyear.gas")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.thisYearGasSavingsInM3) : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.this-year.gas")}
                            />
                            <Tile
                                unit={Unit.ton}
                                icon={IconType.co2}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.savings.co2")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? Math.round((this.state.energyManagementOverview.co2SavedInKg / 10)) / 100 : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.savings.co2")}
                            />
                        </div>
                    </>}
                    {this.state.hasHeatSubscription && <>
                        <div className="row pb-4">
                            <Tile
                                unit={Unit.GJ}
                                icon={IconType.costSavingsHeat}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.total.heat")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.totalHeatSavingsGj) : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.total.heat")}
                            />
                            <Tile
                                unit={Unit.GJ}
                                icon={IconType.heat}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.thisyear.heat")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? Math.round(this.state.energyManagementOverview.thisYearHeatSavingsGj) : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.this-year.heat")}
                            />
                            <Tile
                                unit={Unit.none}
                                icon={IconType.tree}
                                subtitle={LanguageProvider.getTranslation("energy.managementoverview.tiles.savings.trees")}
                                totalNumberOfTileColumns={3}
                                value={this.state.energyManagementOverview ? this.state.energyManagementOverview.virtualTrees : '-'}
                                tooltip={LanguageProvider.getTranslation("tooltips.energy.managementoverview.savings.trees")}
                            />
                        </div>
                    </>}
                </div>}
            </>
        );
    }
}

export default withTranslation()(EnergyManagementOverview);