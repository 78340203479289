import moment from "moment";

import IRoomPhoto from '@/interfaces/IRoomPhoto';
import BaseService from "./baseService";
import { ApplicationConfig } from "@/config";
import AuthService from "@/services/authService";
import VenueProvider from "@/providers/venueProvider";
import UserQuestion from "@/models/userQuestion";
import { DocumentBlobType } from "@/enums/documentBlobType";
import DocumentMetaData from "@/models/documentMetaData";
import IComfortData from "@/interfaces/IComfortData";
import ISpaceRestriction from "@/interfaces/ISpaceRestriction";
import IRoomSettingsDto from "@/interfaces/IRoomSettingsDto";
import IRoomPhotoForCreationDto from "@/interfaces/IRoomPhotoForCreationDto";
import IAutomaticCancellationForRoomsSetting from "@/interfaces/IAutomaticCancellationForRoomsSetting";
import WorkspaceAutoCancellationSettings from '@/interfaces/workspaceAutoCancellationSettings';
import IRoomNoShowSettings from '@/interfaces/IRoomNoShowSettings';
import IAdministratorMessage from "@/interfaces/IAdministratorMessage";

export default class DashboardService extends BaseService {
    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new AuthService());
    }

    public static formatDate(date: Date): string {
        return moment(date).format("YYYY-MM-DD");
    }

    public async getByCustomerId<T>(controller: string, requestUrl: string = "", skipCache: boolean = false): Promise<T> {
        const request = this.appendCustomerIdToRequest(controller, requestUrl);
        return this.get<T>(request, skipCache);
    }

    public async getByVenueId<T>(controller: string, requestUrl: string = "", skipCache: boolean = false): Promise<T> {
        const request = this.appendVenueIdToRequest(controller, requestUrl);
        return this.get<T>(request, skipCache);
    }

    public async getByCustomerAndVenueId<T>(controller: string, requestUrl: string = "", skipCache: boolean = false): Promise<T> {
        const request = this.appendCustomerAndVenueIdToRequest(controller, requestUrl);
        return this.get<T>(request, skipCache);
    }

    public async postWithResponseByCustomerId<TContent, TResponse>(controller: string, requestUrl: string, content: TContent): Promise<TResponse> {
        const request = this.appendCustomerIdToRequest(controller, requestUrl);
        return this.postWithResponse<TResponse>(request, content);
    }

    public async putByCustomerId<T>(controller: string, requestUrl: string, content: T): Promise<Response> {
        const request = this.appendCustomerIdToRequest(controller, requestUrl);
        return this.put(request, content);
    }

    public async updateSpaceRestrictions(venueId: string, restrictions: ISpaceRestriction[]): Promise<Response> {
        return this.patch(`spaces/venue/${venueId}/restrictions`, restrictions);
    }

    public async updateSpacesRoomSettings(venueId: string, rooms: IRoomSettingsDto[]): Promise<Response> {
        return this.patch(`spaces/venue/${venueId}/rooms`, rooms);
    }

    public async upsertRoomsAutomaticCancellationSetting(venueId: string, automaticCancellationForRoomsUpdate: IAutomaticCancellationForRoomsSetting): Promise<Response> {
        return this.patch(`spaces/venue/${venueId}/rooms/automaticcancellation`, automaticCancellationForRoomsUpdate);
    }

    public async upsertWorkspacesAutomaticCancellationSettings(venueId: string, automaticCancellationForWorkspacesUpdate: WorkspaceAutoCancellationSettings): Promise<Response> {
        return this.patch(`spaces/venue/${venueId}/workspaces/automaticcancellation`, automaticCancellationForWorkspacesUpdate);
    }

    public async upsertRoomNoShowSettings(customerId: string, venueId: string, noShowSettingsForRoom: IRoomNoShowSettings): Promise<Response> {
        return this.patch(`noshows/customer/${customerId}/venue/${venueId}/rooms/noshowsettings`, noShowSettingsForRoom);
    }

    public async ensureAccess(): Promise<boolean> {
        return this.get<boolean>("access");
    }

    public async getAccessForRoomSpaces(): Promise<boolean> {
        return this.get<boolean>("spaces/venue/rooms/access").catch(e => false);
    }

    public async sendUserQuestion(question: UserQuestion): Promise<void> {
        await this.post("Question", question);
    }

    public async getVenueDocuments(documentType?: DocumentBlobType, skipCache: boolean = false): Promise<DocumentMetaData[]> {
        let request = "?includeCustomerDocuments=true";
        if (documentType !== undefined) {
            request += `&dataType=${documentType}`;
        }
        return this.getByVenueId<DocumentMetaData[]>("documents", request, skipCache);
    }

    public async getRecentComfortData(numberOfAssets: number): Promise<IComfortData> {
        const request = `rawdata/comfort/recent/${numberOfAssets}`;
        return await this.get<IComfortData>(request);
    }

    public async getDocumentContent(blobId: string): Promise<string> {
        const request = `documents/${blobId}?saveDownloadedOn=true`;
        return this.get<string>(request);
    }

    public async getShouldUserBeTracked(): Promise<boolean> {
        const request = "UserContext/trackuser";

        // By default we'd like to track users.
        return this.get<boolean>(request).catch(e => true);
    }

    public async getCustomerPermissions(): Promise<string[]> {
        return this.get<string[]>("customerpermissions").catch(e => {
            console.log('No customerpermissions have been found for current user');
            return [];
        });
    }

    public async getRoomPhotos(): Promise<IRoomPhoto[]>{
        const venue = VenueProvider.getActiveVenue();
        const request = `reservationsimages/venue/${venue?.venueId}?assetType=Room`;
        return await this.get<any[]>(request,true);
    }

    public async upsertRoomPhoto(photo: IRoomPhotoForCreationDto): Promise<Response> {
        const { venueId, spaceId, base64Image } = photo;
        const request = `reservationsimages/venue/${venueId}/spaces/${spaceId}/image`;
        return await this.post(request, base64Image);
    }

    public async deleteRoomPhoto(spaceId: String): Promise<Response> {
        const venue = VenueProvider.getActiveVenue();
        const request = `reservationsimages/venue/${venue?.id}/spaces/${spaceId}/image`;
        return await this.delete(request);
    }

    public async getAdminMessage(): Promise<IAdministratorMessage | undefined> {
        const adminMessages = await this.get<IAdministratorMessage[]>('AdministratorMessage', true);
        const firstMessage = adminMessages.length > 0 ? adminMessages[0] : undefined;

        return await firstMessage;
    }

    private appendCustomerIdToRequest(controller: string, requestUrl: string): string {
        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        let request = `${controller}/customer/${venue.customerId}`;
        if (requestUrl) {
            request = `${request}/${requestUrl}`;
        }

        return request;
    }

    private appendVenueIdToRequest(controller: string, requestUrl: string): string {
        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        let request = `${controller}/venue/${venue.id}`;
        if (requestUrl) {
            request = `${request}/${requestUrl}`;
        }

        return request;
    }

    private appendCustomerAndVenueIdToRequest(controller: string, requestUrl: string): string {
        const venue = VenueProvider.getActiveVenue();

        if (!venue) {
            throw new Error("No venue was selected.");
        }

        let request = `${controller}/customer/${venue.customerId}/venue/${venue.id}`;
        if (requestUrl) {
            request = `${request}/${requestUrl}`;
        }

        return request;
    }
}