import * as React from 'react';
import { withTranslation } from "react-i18next";
import moment, { Moment } from 'moment';

import IConsumptionEnergyGraphProps from './interfaces/IEnergyGraphProps';
import IEnergyGraphState from './interfaces/IEnergyGraphState';
import BarGraph from '@/components/graphs/barGraph';
import LineGraph from '@/components/graphs/lineGraph';
import DownloadIcon from "@/images/Download.svg";

import EnergyUnitType from './enums/energyUnitType';
import LanguageProvider from '@/providers/languageProvider';
import { ChartOptions } from 'chart.js';
import CenteredPageLoader from 'components/loaders/centeredPageLoader';
import { CsvColumn, CsvExport } from '../models/csvExport';
import translations from 'translations/mapper';
import EnergyGraphPngButton from './energyGraphPngButton';
import Colors from '@/styles/colors';

class EnergyGraph extends React.Component<IConsumptionEnergyGraphProps, IEnergyGraphState> {
    private readonly informationTypeTemperature: string = 'temperature';

    public constructor(props: IConsumptionEnergyGraphProps) {
        super(props);

        const state: IEnergyGraphState = {
            csvData: []
        };

        this.state = state;

        this.getLabels = this.getLabels.bind(this);
        this.renderLabel = this.renderLabel.bind(this);
        this.formatCsvData = this.formatCsvData.bind(this);
        this.getCsvColumnData = this.getCsvColumnData.bind(this);
        this.renderExportButtons = this.renderExportButtons.bind(this);
    }

    private getLabels(): string[] {
        const weekDays = LanguageProvider.getTranslations(["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            .map(d => `dates.shorthand.${d}`));

        switch (this.props.timeRepresentation) {
            case 'day':
                const dayLabels: string[] = [];
                const isQuarterlyData = this.props.data.length > 0 ? this.props.data[0].data.length >= 92 : false;
                for (let i = 0; i < 24; i++) {
                    if (isQuarterlyData) {
                        dayLabels.push(`${i}:00`);
                        for (let j = 1; j < 4; j++) {
                            const quarterValue = j * 15;
                            dayLabels.push(`${i}:${quarterValue === 0 ? "00" : quarterValue}`);
                        }
                    }
                    else {
                        dayLabels.push(`${i}:00`);
                    }
                }
                return dayLabels;
            case 'week':
                const weekLabels: string[] = [];
                for (let i = 0; i < 7; i++) {
                    const date = moment(this.props.startDate);
                    const day = (date.day() + i) % 7;
                    weekLabels.push(`${weekDays[day]} ${date.add(i, 'days').date()}`);
                }
                return weekLabels;
            case 'month':
                const monthLabels: string[] = [];
                const startMonth = this.props.startDate.getMonth();
                let currentMonth = startMonth;
                for (let i = 0; currentMonth === startMonth; i++) {
                    const momentDate = moment(this.props.startDate);
                    const dateToPrint = momentDate.add(i, 'days');
                    monthLabels.push(`${weekDays[dateToPrint.day()]} ${dateToPrint.date()}`);

                    // Check if the next iteration should happen or not
                    const nextDate = momentDate.add(1, 'days');
                    currentMonth = nextDate.month();
                }
                return monthLabels;
            case 'year':
                return LanguageProvider.getTranslations(
                    ["january", "february", "march", "april", "may", "june", "july", "august",
                        "september", "october", "november", "december"].map(d => `dates.shorthand.${d}`));
        }
    }

    private getCsvColumnData(): string[] {
        const result: string[] = [];

        switch (this.props.timeRepresentation) {
            case 'day': {
                const date = moment(this.props.startDate);
                const endDate = moment(this.props.endDate);
                const isQuarterlyData = this.props.data.length > 0 ? this.props.data[0].data.length >= 92 : false;

                const formatHour = (hour: number): string => hour <= 9 ? `0${hour}` : `${hour}`;
                const formatDate = (date: Moment, endDate: Moment): string => {
                    if (this.props.exportOptions?.enableYearOnlyDailyTimestamp) {
                        return date.format("YYYY");
                    }

                    if (this.props.exportOptions?.enableWeekDailyTimestamp) {
                        return `${date.format("YYYY-MM-DD")} - ${endDate.format("YYYY-MM-DD")}`;
                    }
                    return date.format("YYYY-MM-DD");
                };

                for (let i = 0; i < 24; i++) {
                    if (isQuarterlyData) {
                        result.push(`${formatDate(date, endDate)} ${formatHour(i)}:00`);
                        for (let j = 1; j < 4; j++) {
                            const quarterValue = j * 15;
                            result.push(`${formatDate(date, endDate)} ${formatHour(i)}:${quarterValue}`);
                        }
                    }
                    else {
                        result.push(`${formatDate(date, endDate)} ${formatHour(i)}:00`);
                    }
                }
                break;
            }
            case 'week': {
                const date = moment(this.props.startDate);
                for (let i = 0; i < 7; i++) {
                    result.push(date.format("YYYY-MM-DD"));
                    date.add(1, 'day');
                }
                break;
            }
            case 'month': {
                const date = moment(this.props.startDate);
                const currentMonth = date.month();
                while (currentMonth === date.month()) {
                    result.push(date.format("YYYY-MM-DD"));
                    date.add(1, 'day');
                }
                break;
            }
            case 'year': {
                const date = moment(this.props.startDate);
                for (let i = 0; i < 12; i++) {
                    result.push(date.format("YYYY-MM-DD"));
                    date.add(1, 'month');
                }
                break;
            }
        }

        return result;
    }

    private roundTemperature(rawTemperature: number): number{
        return Math.floor(rawTemperature * 10) / 10;
    }

    private roundValue(rawValue: number): number {
        switch (this.props.timeRepresentation) {
            case 'year':
                return this.props.unitType === EnergyUnitType.percentage ? Math.round(rawValue * 10) / 10 : Math.round(rawValue);
            default:
                return Math.round(rawValue * 100) / 100;
        }
    }

    private renderLabel(): string {
        switch (this.props.unitType) {
            case EnergyUnitType.percentage:
                return "%";
            case EnergyUnitType.euro:
                return "\u20ac";
            case EnergyUnitType.co2:
                return "Kg";
            default:
                if (this.props.energyType){
                    return `${LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.datatype[this.props.energyType.toString().toLowerCase()])} [${EnergyUnitType[this.props.unitType]}]`
                }

                return EnergyUnitType[this.props.unitType];
        }
    }

    private formatCsvData(): void {
        const csvExport = new CsvExport(this.props.exportOptions?.fileName ?? "export.csv");
        const currentUnitType = EnergyUnitType[this.props.unitType];

        const inputData = this.props.data;

        const dateColumn: CsvColumn = {
            header: 'Timestamp',
            data: this.getCsvColumnData()
        };

        csvExport.AddColumn(dateColumn);

        const dataColumns: CsvColumn[] = inputData.map(data => ({
            header: data.label + ` (${currentUnitType})`,
            data: data.data.map(x => x === null ? "" : CsvExport.stringifyWithAtLeastOneDecimal(this.roundValue(x)))
        }));

        csvExport.AddColumn(...dataColumns);

        if (this.props.hourlyTemperatureData && this.props.hourlyTemperatureData.length > 0) {
            let weatherData: number[] = [];

            weatherData = this.props.hourlyTemperatureData.sort((a, b) => a.localHour - b.localHour).map(x => this.roundTemperature(x.temperature));

            // For electricity we have data points at quarterly level instead of hourly level, so we need to account for that
            // with the weather data.
            if (this.props.containsQuarterlyData){
                const quarterlyDataPerHour = weatherData.map(w => [w, w, w, w])
                weatherData = quarterlyDataPerHour.flat()
            }


            const weatherColumn: CsvColumn = {
                header: LanguageProvider.getTranslation(`pages.energy.tabs.graph.${this.informationTypeTemperature}`),
                data: weatherData.map(w => CsvExport.stringifyWithAtLeastOneDecimal(this.roundTemperature(w)))
            }
            csvExport.AddColumn(weatherColumn)
        }

        csvExport.ExecuteDownload();
    }

    private renderExportButtons(): JSX.Element {
        return (<>
            <div className="row png-hidden mr-1">
                <div className="col-sm-12">
                    <div className="d-flex w-100">
                        <button
                            onClick={this.formatCsvData}
                            className="ml-auto btn btn-secondary">
                            <span>CSV</span>
                            <img className="pl-1" src={DownloadIcon} />
                        </button>
                    </div>
                </div>
            </div>
            {this.props.graphHtmlReference && <EnergyGraphPngButton divRef={this.props.graphHtmlReference} heightCompensation={25} fileName={this.props.exportOptions?.fileName} />}
        </>);
    }

    public render(): JSX.Element {
        const data: any = {
            labels: this.getLabels(),
            datasets: []
        };

        if (this.props.hourlyTemperatureData && this.props.hourlyTemperatureData.length > 0 && this.props.timeRepresentation === 'day'){
            let weatherData: number[] = [];

            weatherData = this.props.hourlyTemperatureData.sort((a, b) => a.localHour - b.localHour).map(x => this.roundTemperature(x.temperature));

            // For electricity we have data points at quarterly level instead of hourly level, so we need to account for that
            // with the weather data.
            if (this.props.containsQuarterlyData){
                const quarterlyDataPerHour = weatherData.map(w => [w, w, w, w])
                weatherData = quarterlyDataPerHour.flat()
            }

            data.datasets?.push(
                {
                    type: "line",
                    label: LanguageProvider.getTranslation(`pages.energy.tabs.graph.${this.informationTypeTemperature}`),
                    tooltipContent: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.temperaturedescription),
                    data: weatherData,
                    backgroundColor: Colors.persian_red,
                    borderColor: Colors.persian_red,
                    yAxisID: 'temp',
                    borderWidth: 2.5,
                    fill: false,
                    lineTension: this.props.containsQuarterlyData ? 0.1 : 0.3,
                    barPercentage: 1.0,
                    categoryPercentage: 0.5,
                    disableLegend: false,
                    renderLegendAsLine: true,
                    pointRadius: 0,
                    pointHoverRadius: 3,
                    pointHitRadius: 25
                }
            );
        }

        data.datasets.push(...this.props.data.map(d => (
            {
                label: d.label,
                data: d.data.map(x => x === null ? undefined : this.roundValue(x)),
                backgroundColor: d.color,
                borderColor: d.color,
                yAxisID: 'bar',
                borderWidth: 1,
                fill: false,
                categoryPercentage: 0.7,
                barPercentage: 0.5,
                tooltipContent: d.tooltipContent,
                greyedOut: d.noData,
                renderLegendAsCircle: this.props.graphType === 'line',
                lineTension: 0,
            })));

        const options: ChartOptions = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontSize: 12,
                        fontFamily: "'Poppins', sans-serif",
                        fontStyle: "100",
                        maxTicksLimit: 31,
                        maxRotation: 0
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: this.renderLabel()
                    },
                    id: 'bar',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        drawBorder: false
                    },
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        beginAtZero: true,
                        padding: 10
                    }
                }]
            },
            maintainAspectRatio: true,
            title: {
                display: false
            },
            legend: {
                display: false
            }
        };

        options.scales?.yAxes?.push({
            scaleLabel: {
                display: true,
                labelString: LanguageProvider.getTranslation(`pages.energy.tabs.graph.${this.informationTypeTemperature}`) + ' [°C]'
            },
            id: 'temp',
            type: 'linear',
            position: 'right',
            gridLines: {
                display: false
            },
            ticks: {
                fontColor: Colors.black,
                fontFamily: "'Poppins', sans-serif",
                fontSize: 12,
                fontStyle: "100",
                suggestedMin: -10,
                padding: 10
            },
            // Set display to false rather than remove scale from set due to bug in chartjs, where removal
            // of scale is not reflected until refresh (but updates to existing scale are).
            display: this.props.hourlyTemperatureData && this.props.hourlyTemperatureData.length > 0
        })

        return (
            <div>
                {this.props.loading && <div id="graph-loader">
                    <div className="w-100 h-75 position-absolute">
                        <div className="w-100 h-100 map-margin-correction d-flex justify-content-center">
                            <CenteredPageLoader loading={this.props.loading ?? false} size={12} />
                        </div>
                    </div>
                </div>}

                {this.props.graphType === "bar" && <BarGraph
                    data={data}
                    height={this.props.height}
                    width={this.props.width}
                    options={options}
                    elementsClickable={this.props.isBarGraphElementClickable}
                    onElementClick={this.props.onElementClick}
                    showLegend={this.props.showLegend}
                    renderExportButtons={this.renderExportButtons} />}
                {this.props.graphType === "line" && <LineGraph
                    data={data}
                    height={this.props.height}
                    width={this.props.width}
                    options={options}
                    showLegend={this.props.showLegend}
                    renderExportButtons={this.renderExportButtons} />}
            </div>
        );
    }
}

export default withTranslation()(EnergyGraph);