import DashboardService from "./dashboardService";
import UserContext from "../models/userContext";
import IGraphThreshold from "../interfaces/IGraphThreshold";
import { ApplicationConfig } from './../config';
import IUserContext from "../interfaces/IUserContext";

export default class UserContextService {
    private dashboardService: DashboardService;
    private readonly sessionStorageUserContext: string = `bs.userContext.${ApplicationConfig.environment}`;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getUserContextAsync(): Promise<UserContext> {
        const userContextReponse = await this.dashboardService.getByCustomerId<IUserContext>("usercontext", undefined, true);
        return new UserContext(userContextReponse);
    }

    public async updateUserThresholds(thresholds: IGraphThreshold[]): Promise<Response> {
        sessionStorage.removeItem(this.sessionStorageUserContext);
        return await this.dashboardService.putByCustomerId("usercontext", `thresholds`, thresholds);
    }
}