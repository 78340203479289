import { withTranslation } from "react-i18next";

import ISanitaryOverviewGraphProps from "./interfaces/ISanitaryOverviewGraphProps";

import BaseSanitaryOverviewGraph from "./baseSanitaryOverviewGraph";
import ChartDataCreator from "@/utils/chartDataCreator";
import LanguageProvider from "@/providers/languageProvider";
import Colors from "@/styles/colors";
class DailySanitaryOverviewGraph extends BaseSanitaryOverviewGraph {
    private openDays: boolean[] = [true, true, true, true, true, true, true];

    public constructor(props: ISanitaryOverviewGraphProps) {
        super(props);
    }

    public async updateChartData(): Promise<void> {
        if (this.props.venueId) {
            await this.setupVenueChartDataAsync();
        }
        else if (this.props.floorId) {
            await this.setupFloorChartDataAsync();
        }
        else {
            await this.setupAssetChartDataAsync();
        }
    }

    private async setupAssetChartDataAsync(): Promise<void> {
        const assetIds = this.props.assetIds || [];
        const ids = assetIds.map(a => a.value);

        const sanitaryData = await this.dataInsightsService.getHourlyAssetSanitaryData(ids, this.props.startDate, this.props.endDate);
        const [chartData, openDays] = ChartDataCreator.createDailySanitaryOverview(sanitaryData, assetIds, this.props.selectedDataType);
        this.openDays = openDays;

        if(assetIds.length === this.props.assetIds.length){
            this.setState({
                chartData: chartData
            });
        }
    }

    private async setupFloorChartDataAsync(): Promise<void> {
        if (!this.props.floorId) {
            return;
        }

        const floorId = this.props.floorId.value;

        const sanitaryData = await this.dataInsightsService.getHourlyFloorSanitaryData([floorId], this.props.startDate, this.props.endDate);

        // Avoids timing related null ref when floor became undefined while waiting for the request.
        if (!this.props.floorId) {
            return;
        }
        const [chartData, openDays] = ChartDataCreator.createDailySanitaryOverview(sanitaryData,
            [{
                label: this.props.floorId.label,
                value: this.props.floorId.value,
                color: Colors.royal_blue,
                textColor: Colors.royal_blue
            }],
            this.props.selectedDataType);
        this.openDays = openDays;

        // Avoids issue with long running requests overriding data if the user selects multiple options in quick succession.
        if (this.props.floorId.value === floorId) {
            this.setState({
                chartData: chartData
            });
        }
    }

    private async setupVenueChartDataAsync(): Promise<void> {
        if (!this.props.venueId) {
            return;
        }

        const venueId = this.props.venueId.value;

        const sanitaryData = await this.dataInsightsService.getHourlyVenueSanitaryData([venueId], this.props.startDate, this.props.endDate);

        // Avoids timing related null ref when venue became null while waiting for the request.
        if (!this.props.venueId) {
            return;
        }
        const [chartData, openDays] = ChartDataCreator.createDailySanitaryOverview(sanitaryData,
            [{
                label: this.props.venueId.label,
                value: this.props.venueId.value,
                color: Colors.royal_blue,
                textColor: Colors.royal_blue
            }],
            this.props.selectedDataType);
        this.openDays = openDays;

        // Avoids issue with long running requests overriding data if the user selects multiple options in quick succession.
        if (this.props.venueId.value === venueId) {
            this.setState({
                chartData: chartData
            });
        }
    }

    public getLabels(): string[] {
        const weekDayTranslations = LanguageProvider.getTranslations(["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            .map(d => `dates.shorthand.${d}`));
        return weekDayTranslations.filter((translation, i) => this.openDays[i]);
    }
}

export default withTranslation()(DailySanitaryOverviewGraph);