import * as React from "react";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import moment from 'moment';
import IYearProgressProps from "./interfaces/IYearProgressProps";
import IYearProgressState from "./interfaces/IYearProgressState";
import LanguageProvider from "@/providers/languageProvider";

export default class YearProgress extends React.Component<IYearProgressProps, IYearProgressState> {

    public constructor(props: IYearProgressProps) {
        super(props);
    }

    public render(): JSX.Element {
        const startMoment = moment(this.props.yearlySubscriptionDetail.startDate);
        const endMoment = this.props.endMoment;
        const today = moment();
        const yearAgo = moment().subtract(1, "year");
        const futureContract = startMoment.isAfter(today);

        let onTarget = true;
        let targetClass = 'on-target';
        if (futureContract) {
            targetClass = 'unknown-target';
        }
        else {
            if (this.props.yearlySubscriptionDetail.totalSavingsPercentage !== undefined && this.props.savingsGoalPercentage && this.props.savingsGoalPercentage > this.props.yearlySubscriptionDetail.totalSavingsPercentage) {
                targetClass = 'below-target';
                onTarget = false;
            }
        }

        let percentage = 100;
        let greyPercentage = 0;

        const startInLastYear = startMoment.isBetween(yearAgo, today);
        if (startInLastYear) {
            percentage = ((today.toDate().getTime() - startMoment.toDate().getTime()) / (endMoment.toDate().getTime() - startMoment.toDate().getTime())) * 100;
            percentage = percentage > 100 ? 100 : percentage;
            greyPercentage = 100 - percentage;
        }
        return (
            <Popup
                key={this.props.iterator}
                trigger={
                    <div
                        className={`contract-progress-year`}
                        style={{ width: `${this.props.yearWidth}%` }}
                    >
                        <div className={`year-start ${targetClass}`} />
                        <div
                            className={`year-bar ${targetClass}`}
                            style={{
                                width: `calc(${percentage}% - ${5 * percentage / 100}px)`
                            }} />
                        {startInLastYear ? <div className={`grey-bar`} style={{ width: `calc(${greyPercentage}% - ${5 * greyPercentage / 100}px)` }} /> : ""}
                    </div>
                }
                position="top center"
                on="hover"
            >
                <div title="Top Center" >
                    {`${LanguageProvider.getTranslation("energy.progressbar.year")} ${this.props.iterator + 1}`} <br />
                    {!futureContract &&
                        <span>
                            {this.props.yearlySubscriptionDetail.totalSavingsPercentage === null
                                ? LanguageProvider.getTranslation("energy.progressbar.savingsunknown")
                                : `${(this.props.yearlySubscriptionDetail.totalSavingsPercentage !== undefined && this.props.yearlySubscriptionDetail.totalSavingsPercentage !== null)
                                    ? Math.round(this.props.yearlySubscriptionDetail.totalSavingsPercentage * 10) / 10
                                    : '-'
                                }% ${LanguageProvider.getTranslation("energy.progressbar.saved")}`} <br />
                            {onTarget ? LanguageProvider.getTranslation("energy.progressbar.ontarget") : LanguageProvider.getTranslation("energy.progressbar.belowtarget")}
                        </span>
                    }
                    {futureContract &&
                        <span>
                            {LanguageProvider.getTranslation("energy.progressbar.stillunknown")}
                        </span>}
                </div>
            </Popup>

        );
    }
}