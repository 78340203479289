import { EnergyAssetType } from "./energyAssetType";

export default class EnergyAssetTree {
    public constructor(name: string, assetId: string, nodeType: EnergyAssetType, usage?: number, percentage?: number) {
        this.name = name;
        this.assetId = assetId;
        this.usage = usage === undefined ? 0 : usage;
        this.percentage = percentage === undefined ? 0 : percentage;
        this.children = [];
        this.nodeType = nodeType;
    }

    public name: string;
    public usage: number;
    public percentage: number;
    public assetId: string;
    public children: EnergyAssetTree[];
    public nodeType: EnergyAssetType;
}