import * as React from "react";
import { withTranslation } from "react-i18next";

import IToiletSettingsTabState from './interfaces/IToiletSettingsTabState';
import SanitaryService from "@/services/sanitaryService";
import IToiletSettingsTabProps from "./interfaces/IToiletSettingsTabProps";
import CoreSpaceDatatypes from "../../../../../enums/coreSpaceDatatypes";
import IThreshold from "../../../../../interfaces/IThreshold";
import SanitarySettingsTab from "./sanitarySettingsTab";

class ToiletSettingsTab extends React.Component<IToiletSettingsTabProps, IToiletSettingsTabState> {
    private readonly sanitaryService: SanitaryService;

    public constructor(props: IToiletSettingsTabProps) {
        super(props);

        this.sanitaryService = new SanitaryService();
        this.getDefaultThreshold = this.getDefaultThreshold.bind(this);
        this.getAllSanitaryThresholds = this.getAllSanitaryThresholds.bind(this);
        this.upsertThresholds = this.upsertThresholds.bind(this);
    }

    private getDefaultThreshold(): IThreshold {
        return {
            assetId: "",
            notificationEnabled: false,
            threshold: 60
        };
    }

    private async getAllSanitaryThresholds(): Promise<IThreshold[]> {
        const sanitaryThresholds = await this.sanitaryService.getAllSanitaryThresholds(true);
        return sanitaryThresholds.map(threshold => ({
            assetId: threshold.assetId,
            notificationEnabled: threshold.notificationEnabled,
            threshold: threshold.thresholdInMinutes,
        }));
    }

    private async upsertThresholds(thresholds: IThreshold[]): Promise<Response> {
        const sanitaryThresholds = thresholds.map(t => ({
            assetId: t.assetId,
            notificationEnabled: t.notificationEnabled,
            thresholdInMinutes: t.threshold,
        }));
        return await this.sanitaryService.batchUpsertVenueSettings(sanitaryThresholds);
    }

    public render(): JSX.Element {
        return (
            <SanitarySettingsTab
                tabName="toiletstab"
                defaultThreshold={this.getDefaultThreshold()}
                dataType={CoreSpaceDatatypes.Toilet}
                getThresholds={this.getAllSanitaryThresholds}
                upsertThresholds={this.upsertThresholds}
                sliderDomain={[0, 360]}
                sliderStep={30}
            />
        );
    }
}

export default withTranslation()(ToiletSettingsTab);