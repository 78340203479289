import EnergyType from "pages/energymanagement/components/enums/energyType";
import Colors from "@/styles/colors";

export default class ColorProvider {

    private static cardinal_red: string = "#bbB1542";
    private static seance_red: string = "#702283";
    private static sushi_green: string = "#73ae3e";
    private static raja_orange: string = "#FABC74";
    private static abbey_grey: string = "#505457";
    private static disco_purple: string = "#84106c";
    private static hippy_green: string = "#4a8048";
    private static buddha_gold: string = "#bf8f05";
    private static orchid_pink: string = "#e098d1";
    private static apricot_orange: string = "#f1916f";
    private static orange: string = "#ffa500";
    private static cornflower_blue: string = "#5480f0";
    private static watermelon_red: string = "#ff6e79"
    private static fruitsalad_green: string = "#5ea35c";
    private static cerise_pink: string = "#e31cbb";

    private static backgroundBaseColors: string[] = [Colors.shakespeare_blue, Colors.royal_blue, Colors.midnight_black, Colors.blue_grey, Colors.alto_grey, Colors.amaranth_red, ColorProvider.cardinal_red, ColorProvider.seance_red, ColorProvider.sushi_green, ColorProvider.raja_orange];
    private static textBaseColors: string[] = [Colors.white, Colors.white, Colors.white, Colors.white, ColorProvider.abbey_grey, Colors.white, Colors.white, Colors.white, ColorProvider.abbey_grey, ColorProvider.abbey_grey];
    private static weekDayColors: string[] = [Colors.amaranth_red, Colors.royal_blue, Colors.energymanagement_green, ColorProvider.disco_purple, Colors.midnight_black, Colors.sunglow_yellow, Colors.shakespeare_blue];
    private static holidayColors: string[] = [Colors.sunglow_yellow, ColorProvider.hippy_green, Colors.royal_blue, Colors.shakespeare_blue, ColorProvider.buddha_gold, Colors.energymanagement_green, Colors.midnight_black, ColorProvider.disco_purple, ColorProvider.orchid_pink, Colors.amaranth_red, ColorProvider.apricot_orange, ColorProvider.orange];

    public static getNextSemiRandomBackgroundColorForIndex(index: any): string {
        let color: string;
        // Start by using the default colors:
        if (index < this.backgroundBaseColors.length) {
            color = this.backgroundBaseColors[index];
        } else {
            // Otherwise generate a color using an algorithm:
            const red = (index % 7) * Math.floor(155 / 6);
            const blue = ((index) % 5) * Math.floor(235 / 4);
            const green = (index % 3) * Math.floor(235 / 2);
            color = `rgba(${red}, ${green}, ${blue}, 255)`;
        }

        index++;
        return color;
    }

    public static getNextSemiRandomTextColorForIndex(index: any): string {
        let color: string;
        // Start by using the default colors:
        if (index < this.textBaseColors.length) {
            color = this.textBaseColors[index];
        } else {
            color = Colors.white;
        }

        index++;
        return color;
    }

    public static getNextHolidayColorForIndex(index: number): string {
        return this.holidayColors[index];
    }

    public static getColorForWeekday(dayIndex: number): string {
        return this.weekDayColors[dayIndex];
    }

    public static getTemperatureGraphColors(type: EnergyType, outsideOpeningHours: boolean): string {
        if (!outsideOpeningHours) {
            switch (type) {
                case EnergyType.Electricity:
                    return Colors.royal_blue;
                case EnergyType.Gas:
                    return Colors.amaranth_red;
                case EnergyType.Heat:
                    return this.hippy_green;
                case EnergyType.Cold:
                    return this.disco_purple;
            }
        }
        else{
            switch (type) {
                case EnergyType.Electricity:
                    return this.cornflower_blue;
                case EnergyType.Gas:
                    return this.watermelon_red;
                case EnergyType.Heat:
                    return this.fruitsalad_green;
                case EnergyType.Cold:
                    return this.cerise_pink;
            }
        }
    }
}