import * as React from 'react';
import { withTranslation } from "react-i18next";
import { withTelemetry } from '@/services/telemetryService';
import { nl } from 'date-fns/esm/locale';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import './noShowInsightsPage.scss';

import IInsightsOverviewProps from "../interfaces/IInsightsOverviewProps";
import INoShowInsightsPageState from '../interfaces/INoShowInsightsPageState';
import LanguageProvider from '@/providers/languageProvider';
import VenueProvider from '@/providers/venueProvider';
import { OnChangeValue } from 'react-select';
import SubscriptionValidationService from '@/services/subscriptionValidationService';

import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import IFloorSelectValue from '../../../interfaces/IFloorSelectValue';
import CoreSpaceService from '@/services/coreSpaceService';
import NoShowOverviewTable from '../components/noShowOverviewTable';
import IHistoricReservation from '../../../interfaces/IHistoricReservation';

import NoShowAverages from "../components/noShow/noShowAverages";
import HistoricReservationService from './historicReservationService';
import NoShowSettingsService from '@/services/noShowSettingsService';
import translations from '@/translations/mapper';
import CoreSpaceIncludes from '../../../enums/coreSpaceIncludes';
import RealEstateUtilizationPage from '../realEstateUtilizationPage';
import FullPageLoader from '@/components/loaders/fullPageLoader';
import GeneralTeaser from '@/components/teaser/generalTeaser';
import { Link } from 'react-router-dom';
import links from '@/utils/links';
import FloorSelect from "@/components/select/floorSelect";


class NoShowInsightsPage extends React.Component<IInsightsOverviewProps, INoShowInsightsPageState> {
    private coreSpaceService: CoreSpaceService;
    private subscriptionValidationService: SubscriptionValidationService;
    private readonly historicReservationService: HistoricReservationService;
    private readonly noShowSettingsService: NoShowSettingsService;

    public constructor(props: IInsightsOverviewProps) {
        super(props);

        this.coreSpaceService = new CoreSpaceService();
        this.historicReservationService = new HistoricReservationService();
        this.noShowSettingsService = new NoShowSettingsService();

        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setMonth(startDate.getMonth() - 1);
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        const state: INoShowInsightsPageState = {
            loading: true,
            venueId: "",
            selectedRenderIds: [],
            selectedRenderType: "",
            endDate: endDate,
            startDate: startDate,
            selectedFloor: null,
            hasReserveRoomSubscription: false,
            renderMap: false,
            renderMapInitial: false,
            historicReservations: [],
            noShowAfterMinutes: 0,
            floors: []
        };

        this.state = state;

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.setActiveVenueAsync = this.setActiveVenueAsync.bind(this);
        this.setActiveFloor = this.setActiveFloor.bind(this);
        this.getHistoricReservationsAsync = this.getHistoricReservationsAsync.bind(this);
        this.clearFloorSelection = this.clearFloorSelection.bind(this);
        this.renderNoShowDescription = this.renderNoShowDescription.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.setActiveVenueAsync);
    }

    public async componentDidMount(): Promise<void> {
        registerLocale('nl', nl);
        setDefaultLocale('nl');

        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();

        await this.setActiveVenueAsync();

        this.setState({
            loading: false,
        }, () => this.getHistoricReservationsAsync());
    }

    public componentWillUnmount(): void {
        AppEventHub.off(AppEvents.BuildingSelected, this.setActiveVenueAsync);
    }

    private onStartDateChange(state: Date): void {

        if (!(state instanceof Date)) {
            return;
        }

        this.setState({
            startDate: state
        }, () => this.getHistoricReservationsAsync());
    }

    private onEndDateChange(state: Date): void {

        if (!(state instanceof Date)) {
            return;
        }

        this.setState({
            endDate: state
        }, () => this.getHistoricReservationsAsync());
    }

    private async setActiveVenueAsync(): Promise<void> {
        const activeVenue = VenueProvider.getActiveVenue();
        this.clearFloorSelection();

        if (!activeVenue) {
            return;
        }

        const floorsCall = this.coreSpaceService.getFloorsForVenue(activeVenue.id, [CoreSpaceIncludes.Properties]);
        const noShowSettingsTask = this.noShowSettingsService.getNoShowSettings(true);

        const [noShowSettings, floors] = await Promise.all([noShowSettingsTask, floorsCall]);

        const hasReserveRoomSubscription = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["NoShowRooms"]);

        this.setState({
            venueId: activeVenue.id,
            selectedRenderIds: [activeVenue.id],
            hasReserveRoomSubscription: hasReserveRoomSubscription,
            noShowAfterMinutes: isNaN(noShowSettings.noShowAfter) ? 0 : noShowSettings.noShowAfter,
            floors: floors
        }, () => this.getHistoricReservationsAsync());
    }

    private setActiveFloor(selectedItem: OnChangeValue<IFloorSelectValue, false>): void {
        const item = selectedItem as IFloorSelectValue | null;

        if (!item || item.isDefault) {
            this.setState({
                selectedRenderIds: [this.state.venueId],
                selectedRenderType: "venue",
                selectedFloor: item
            }, () => this.getHistoricReservationsAsync());
        } else {
            this.setState({
                selectedRenderIds: [item.value],
                selectedFloor: item,
                selectedRenderType: "floor",
            }, () => this.getHistoricReservationsAsync());
        }
    }

    private clearFloorSelection(): void {
        this.setState({
            selectedRenderIds: [this.state.venueId],
            selectedRenderType: "venue",
            selectedFloor: null,
        }, () => this.getHistoricReservationsAsync());
    }

    private async getHistoricReservationsAsync(): Promise<void> {
        const id = this.state.selectedRenderIds[0];

        let historicReservations: IHistoricReservation[];
        if (this.state.selectedRenderType === 'floor') {
            historicReservations = await this.historicReservationService.getNoShowHistoricReservationsForFloor(id, this.state.startDate, this.state.endDate);
        } else {
            historicReservations = await this.historicReservationService.getNoShowHistoricReservationsForVenue(this.state.startDate, this.state.endDate);
        }
        this.setState({ historicReservations: historicReservations });
    }

    private getOpenDays(): number {
        const currentVenue = VenueProvider.getActiveVenue();

        if (!currentVenue) {
            return 0;
        }
        const venueOpenDays = currentVenue.openDays.split('');

        if (venueOpenDays.length !== 7) {
            return 0;
        }

        let openDays = 0;
        const currentDate = new Date(this.state.startDate);

        for (currentDate; currentDate <= this.state.endDate; currentDate.setDate(currentDate.getDate() + 1)) {
            const weekDay = currentDate.getDay();
            if (venueOpenDays[(weekDay + 6) % 7] === "1") {
                openDays++;
            }
        }

        return openDays;
    }

    private renderNoShowDescription(): JSX.Element {
        return (
            <>
                {`${LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.noshowdescriptionpartone)} 
                ${this.state.noShowAfterMinutes} 
                ${LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.noshowdescriptionparttwo)}`}
                <Link to={links.settings.realestateutilization.roomreservations}>{LanguageProvider.getTranslation(translations.pages.noshowinsights.overview.noshowdescriptionlink)}</Link>
            </>
        );
    }

    public render(): JSX.Element {
        const venue = VenueProvider.getActiveVenue();

        let venueName = "";
        if (venue) {
            venueName = venue.name;
        }

        return (
            <RealEstateUtilizationPage>
                {this.state.loading && this.state.hasReserveRoomSubscription && <FullPageLoader loading={this.state.loading} />}
                {!this.state.loading && <>
                    {!this.state.hasReserveRoomSubscription && <GeneralTeaser buttonLink={links.external.beyondeyes.realestateutilization} />}
                    {this.state.hasReserveRoomSubscription &&
                        <div id="noshow-insights" className="main-content">
                            <div className="main-content-header">
                                <div className="row">
                                    <div className="col-sm">
                                        <h1>{LanguageProvider.getTranslation(translations.navigation.noshowinsights)} - {venueName}</h1>
                                        {this.renderNoShowDescription()}
                                    </div>
                                </div>
                                <div className="row input-selection">
                                    <div className="col-sm-12 d-flex">
                                        <div className="input-field-m mr-2">
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.onStartDateChange}
                                                maxDate={this.state.endDate}
                                                placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
                                                className="asset-datepicker clickable"
                                                dateFormat="dd-MM-yyyy"
                                                todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                            />
                                        </div>
                                        <div className="input-field-m mr-2">
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={this.onEndDateChange}
                                                minDate={this.state.startDate}
                                                maxDate={moment().toDate()}
                                                placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
                                                className="asset-datepicker clickable"
                                                dateFormat="dd-MM-yyyy"
                                                todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                            />
                                        </div>
                                        <div className="input-field-l mr-2">
                                            <FloorSelect
                                                floors={this.state.floors}
                                                setActiveFloor={this.setActiveFloor}
                                                isDisabled={this.state.renderMap}
                                                selectedFloor={this.state.selectedFloor}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-content-body">
                                <div className="row">
                                    <div className="col-sm">
                                        <NoShowAverages historicReservations={this.state.historicReservations} totalOpenDays={this.getOpenDays()} />
                                    </div>
                                </div>
                                <div className="content-divider" />
                                <NoShowOverviewTable data={this.state.historicReservations} />
                            </div>
                        </div>}
                </>}
            </RealEstateUtilizationPage>
        );
    }
}

export default withTranslation()(withTelemetry(NoShowInsightsPage, "NoShowInsightsPage"));