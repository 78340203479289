import * as React from 'react';
import moment from 'moment';

import IUnitDatePickerDeluxeProps from './interfaces/IUnitDatePickerDeluxeProps';
import IYearDatePickerDeluxeState from './interfaces/IYearDatePickerDeluxeState';

class YearDatePickerDeluxe extends React.Component<IUnitDatePickerDeluxeProps, IYearDatePickerDeluxeState> {
    private currentYear: number = moment(new Date()).year();

    public constructor(props: IUnitDatePickerDeluxeProps) {
        super(props);

        const state: IYearDatePickerDeluxeState = {
            selectedYear: this.currentYear
        };
        this.state = state;

        this.renderRow = this.renderRow.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.setSelectedYear = this.setSelectedYear.bind(this);
    }

    public componentWillReceiveProps(nextProps: IUnitDatePickerDeluxeProps): void {
        if (nextProps.isActive !== this.props.isActive) {
            if (nextProps.isActive) {
                this.setSelectedYear(this.state.selectedYear);
            }
        }

        if(nextProps.newStartDate !== undefined && nextProps.newStartDate !== this.props.newStartDate && nextProps.isActive){
            this.setState({
                selectedYear: nextProps.newStartDate.getFullYear()
            });
        }
    }

    private setSelectedYear(year: number): void {
        this.setState({
            selectedYear: year
        });

        this.props.onDateChange('year', moment(`1-1-${year}`, "DD-MM-YYYY").toDate(), moment(`31-12-${year}`, "DD-MM-YYYY").toDate());
    }

    private renderRow(from: number, until: number): JSX.Element {
        const cols: JSX.Element[] = [];

        for (let i = from; i <= until; i++) {
            const item = <YearDatePickerTile
                key={i}
                year={i}
                selected={i === this.state.selectedYear}
                onYearSelect={this.setSelectedYear} />;
            cols.push(item);
        }

        return (
            <tr key={from}>{cols}</tr>
        );
    }

    private renderRows(): JSX.Element[] {
        const rows: JSX.Element[] = [];

        for (let i = 0; i < 4; i++) {
            rows.push(this.renderRow(this.currentYear - 11 + (i * 3), this.currentYear - 9 + (i * 3)));
        }

        return rows;
    }

    public render(): JSX.Element {
        return (<table className={"unit-calendar table-border w-100 " + (this.props.isActive ? "" : "d-none")}>
            <tbody>{this.renderRows()}</tbody>
        </table>);
    }
}

export default YearDatePickerDeluxe;

class YearDatePickerTile extends React.Component<IYearDatePickerTileProps> {
    private currentYear: number = moment(new Date()).year();

    public constructor(props: IYearDatePickerTileProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    private get isDisabled(): boolean {
        return this.props.year > this.currentYear;
    }

    private onClick(): void {
        if (this.isDisabled) {
            return;
        }

        this.props.onYearSelect(this.props.year);
    }

    public render(): JSX.Element {
        return (
            <td
                key={this.props.year}
                onClick={this.onClick}
                className={"clickable text-center "
                    + (this.isDisabled ? "disabled " : "")
                    + (this.props.year === this.currentYear ? "current " : "")
                    + (this.props.selected ? "selected" : "")}>
                {this.props.year}
            </td>
        );
    }
}

interface IYearDatePickerTileProps {
    year: number;
    onYearSelect: (year: number) => void;
    selected: boolean;
}