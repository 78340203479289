import { ApplicationConfig } from '@/config';
export default class Comparer {
    public static compareProperties(obj1: any, obj2: any, collection1Name: string, collection2Name: string): boolean {
        const getKeys = (obj: any): string[] => {
            const keys: string[] = [];

            const rec = (o: any, name?: string): void => {
                Object.keys(o).forEach(key => {
                    const prop = `${name ? name : ""}${key}`;
                    keys.push(prop);

                    if (typeof o[key] === 'object') {
                        rec(o[key], `${prop}.`);
                    }
                });
            };

            rec(obj);
            return keys;
        };

        const keys1 = getKeys(obj1);
        const keys2 = getKeys(obj2);
        const valid = this.areAllKeysEqual(keys1, keys2, collection1Name, collection2Name);

        return valid;
    }

    private static areAllKeysEqual(keysSet1: string[], keysSet2: string[], collection1Name: string, collection2Name: string): boolean {
        if (keysSet1.length !== keysSet2.length) {
            console.log(`Differences in translations: Number of translations do not match. set ` + collection1Name + `: ${keysSet1.length}, set ` + collection2Name + `: ${keysSet2.length}`);
        }

        const shouldLog = ApplicationConfig.environment !== 'prod';

        const set1 = keysSet2.every(key => {
            const found = keysSet1.find(k => k === key);
            if (!found && shouldLog) {
                console.log('key not found in set: ' + collection1Name + ':' + key);
            }
            return found;
        });

        const set2 = keysSet1.every(key => {
            const found = keysSet2.find(k => k === key);
            if (!found && shouldLog) {
                console.log('keys not found in set:' + collection2Name + ':' + key);
            }
            return found;
        });

        return set1 && set2;
    }
}