import moment from 'moment';
import * as React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { withTranslation } from "react-i18next";
import { nl } from 'date-fns/esm/locale';

import IDocumentsPageProps from './interfaces/IDocumentsPageProps';
import IDocumentsPageState from './interfaces/IDocumentsPageState';

import Download from "../../images/Download.svg";

import './documentsPage.scss';
import DashboardService from '../../services/dashboardService';
import AppEventHub, { AppEvents } from '../../utils/appEventHub';
import LanguageProvider from '../../providers/languageProvider';
import BeyondEyesServicesNamesMapper from '../../providers/BeyondEyesServicesNamesMapper';
import DocumentUtils from '../../utils/documentUtils';
import Sorter from '../../utils/sorter';
import { withTelemetry } from '../../services/telemetryService';
import DocumentMetaData from '../../models/documentMetaData';
import Translations from '../../translations/mapper';
import CenteredPageLoader from '../../components/loaders/centeredPageLoader';
import DataTable, { TableColumn } from 'react-data-table-component';
import PageUtils from '@/utils/pageUtils';

class DocumentsPage extends React.Component<IDocumentsPageProps, IDocumentsPageState>{
    private dashboardService: DashboardService;

    public constructor(props: IDocumentsPageProps) {
        super(props);
        this.dashboardService = new DashboardService();

        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setFullYear(startDate.getFullYear() - 1);

        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        this.state = {
            loading: false,
            defaultPagesize: 0,
            startDate: startDate,
            columns: this.getColumns(),
            endDate: endDate,
            documents: [],
            cachedDocuments: [],
        };

        this.loadDocumentsAsync = this.loadDocumentsAsync.bind(this);
        this.updateColumnNames = this.updateColumnNames.bind(this);
        AppEventHub.on(AppEvents.BuildingSelected, this.loadDocumentsAsync);
        AppEventHub.on(AppEvents.LanguageChanged, this.updateColumnNames);

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        registerLocale('nl', nl);
        setDefaultLocale('nl');
        await this.loadDocumentsAsync();
    }

    public async componentWillUnmount(): Promise<void> {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.loadDocumentsAsync);
        AppEventHub.off(AppEvents.LanguageChanged, this.updateColumnNames);
    }

    private getColumns(): TableColumn<DocumentMetaData>[] {
        const columns = [
            {
                id: 'title',
                name: LanguageProvider.getTranslation(Translations.pages.documents.table.title),
                selector: (row: DocumentMetaData): string => row.name,
                sortable: true
            },
            {
                id: 'reportDate',
                name: LanguageProvider.getTranslation(Translations.pages.documents.table.datetime),
                selector: (row: DocumentMetaData): string => moment(row.createdDate).format("YYYY-MM-DD/ HH:mm"),
                sortable: true
            },
            {
                id: 'type',
                name: LanguageProvider.getTranslation(Translations.pages.documents.table.service),
                selector: (row: DocumentMetaData): string => BeyondEyesServicesNamesMapper.MapService(row.type),
                sortable: true
            },
            {
                id: 'author',
                name: LanguageProvider.getTranslation(Translations.pages.documents.table.author),
                selector: (row: DocumentMetaData): string => row.author,
                sortable: true
            },
            {
                id: 'download',
                selector: (row: DocumentMetaData): JSX.Element => {
                    return <button className={"btn btn-block btn-third"}
                        onClick={this.getDocument(row.blobId, row.name)}>
                        <img src={Download} />
                    </button>
                },
                sortable: false,
                width: 50
            }];

        return columns as unknown as TableColumn<DocumentMetaData>[];
    }

    private updateColumnNames(): void {
        const columns = this.getColumns();
        this.setState({
            columns: columns
        });
    }

    private getDocument(blobid: string, filename: string): (event: any) => void {
        return (event: any): void => {
            this.downloadDocument(blobid, filename);
        };
    }

    private async downloadDocument(blobId: string, filename: string): Promise<void> {
        const content = await this.dashboardService.getDocumentContent(blobId);
        DocumentUtils.openDocument(content, filename);
    }

    private async loadDocumentsAsync(reloadCachedDocuments: boolean = true, generateStartDate: boolean = true): Promise<void> {
        this.setState({
            documents: [],
            loading: true
        });

        let documents = this.state.cachedDocuments;

        if (reloadCachedDocuments) {
            documents = await this.dashboardService.getVenueDocuments(undefined, generateStartDate);
            this.setState({
                cachedDocuments: documents
            });
        }

        if (generateStartDate) {
            if (documents != null && documents.length > 0) {
                const dates = documents.map(d => moment(d.createdDate));
                const startDate = Sorter.sort(dates)[0];

                this.setState({
                    startDate: startDate.toDate()
                });
            }
        }

        if (documents != null) {
            documents = this.filterDocuments(documents);
            this.setState({
                documents: documents,
                defaultPagesize: Math.min(10, documents.length)
            });
        }

        this.setState({
            loading: false
        });
    }

    private filterDocuments(reports: DocumentMetaData[]): DocumentMetaData[] {
        return reports.filter((a) =>
            moment(a.createdDate) >= moment(this.state.startDate)
            && moment(a.createdDate) <= moment(this.state.endDate));
    }

    private handleStartDateChange(state: Date): void {
        this.setState({
            startDate: state
        }, () => this.loadDocumentsAsync(false, false));
    }

    private handleEndDateChange(state: Date): void {
        this.setState({
            endDate: state
        }, () => this.loadDocumentsAsync(false, false));
    }

    private get documentsFound(): boolean {
        return this.state.documents != null && this.state.documents.length !== 0;
    }

    public render(): JSX.Element {
        return (
            <div className="main-content" id="venue-documents">
                <div className="main-content-header">
                    <div className="row">
                        <div className="col-sm">
                            <h1>{LanguageProvider.getTranslation(Translations.navigation.documents)}</h1>
                        </div>
                    </div>
                    <div className="row input-selection">
                        <div className="col-sm d-flex">
                            <div className="input-field-m mr-2">
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    maxDate={this.state.endDate}
                                    placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
                                    className="datepicker clickable"
                                    dateFormat="dd-MM-yyyy"
                                    todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                />
                            </div>
                            <div className="input-field-m mr-2">
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    minDate={this.state.startDate}
                                    maxDate={moment().toDate()}
                                    placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
                                    className="datepicker clickable"
                                    dateFormat="dd-MM-yyyy"
                                    todayButton={LanguageProvider.getTranslation("datepicker.today")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-content-body">
                    {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                    {!this.state.loading && <>
                        {!this.documentsFound &&
                            <h3>{LanguageProvider.getTranslation("pages.documents.notfound")}</h3>}

                        {this.documentsFound &&
                            <>
                                <div className="row">
                                    <div className="col-sm">
                                    <DataTable
                                        className="venue-documents-table besense-grey-border-table"
                                        progressPending={this.state.loading}
                                        columns={this.state.columns}
                                        data={this.state.documents!}
                                        pagination={true}
                                        paginationPerPage={this.state.defaultPagesize}
                                        defaultSortFieldId={"reportDate"}
                                        defaultSortAsc={false}
                                        paginationComponentOptions={PageUtils.getDefaultPaginationOptions()}
                                    />
                                    </div>
                                </div>
                            </>}
                    </>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(withTelemetry(DocumentsPage, "DocumentsPage"));