import VenueContextService from "./venueContextService";
import DataThresholds from "../models/dataThresholds";
import IVenueSetting from "../interfaces/IVenueSetting";

export default class DataThresholdsService {
    private static readonly dataThresholdPrefix: string = "datathreshold_";
    private static readonly minThresholdPostfix: string = "_min";
    private static readonly maxThresholdPostfix: string = "_max";
    private static readonly addressesThresholdPostfix: string = "_addresses";
    private static readonly sendNotificationThresholdPostfix: string = "_sendnotification";
    private static readonly validThresholdTypes: string[] = ["temperature", "humidity", "light", "sound", "carbondioxide"];
    private readonly venueContextService: VenueContextService;

    public constructor() {
        this.venueContextService = new VenueContextService();
    }

    public async getDataThresholdsForType(dataType: string, skipCacheCheck?: boolean): Promise<DataThresholds> {
        const thresholdMin = DataThresholdsService.dataThresholdPrefix + dataType.toLowerCase() + DataThresholdsService.minThresholdPostfix;
        const thresholdMax = DataThresholdsService.dataThresholdPrefix + dataType.toLowerCase() + DataThresholdsService.maxThresholdPostfix;
        const thresholdAddresses = DataThresholdsService.dataThresholdPrefix + dataType.toLowerCase() + DataThresholdsService.addressesThresholdPostfix;
        const thresholdSendNotification = DataThresholdsService.dataThresholdPrefix + dataType.toLowerCase() + DataThresholdsService.sendNotificationThresholdPostfix;

        const minRequest = this.venueContextService.getVenueSetting(thresholdMin, skipCacheCheck);
        const maxRequest = this.venueContextService.getVenueSetting(thresholdMax, skipCacheCheck);
        const addressesRequest = this.venueContextService.getVenueSetting(thresholdAddresses, skipCacheCheck);
        const sendNotificationRequest = this.venueContextService.getVenueSetting(thresholdSendNotification, skipCacheCheck);

        const responses = await Promise.all([minRequest, maxRequest, addressesRequest, sendNotificationRequest]);

        const lowerThreshold = this.parseThresholdValue(responses[0].value);
        const upperThreshold = this.parseThresholdValue(responses[1].value);
        const addresses = responses[2].value;
        const sendNotification = this.parseSendNotificationValue(responses[3].value);

        return new DataThresholds(dataType, addresses, sendNotification, lowerThreshold, upperThreshold);
    }

    public async getAllDataThresholds(skipCacheCheck?: boolean): Promise<DataThresholds[]> {
        const allSettings = await this.venueContextService.getVenueContext(skipCacheCheck);
        const thresholdSettings = allSettings.filter(s =>
            DataThresholdsService.validThresholdTypes.find(c =>
                s.settingIdentifier.startsWith(`${DataThresholdsService.dataThresholdPrefix}${c}`)) !== undefined);

        const dataThresholds: DataThresholds[] = [];

        thresholdSettings.forEach(setting => {
            const settingDataType = setting.settingIdentifier.split('_')[1];
            let threshold = dataThresholds.find(t => t.dataType === settingDataType);
            if (threshold === undefined) {
                threshold = new DataThresholds(settingDataType, "", false);
                dataThresholds.push(threshold);
            }

            if (setting.settingIdentifier.endsWith(DataThresholdsService.minThresholdPostfix)) {
                threshold.lowerThreshold = this.parseThresholdValue(setting.value);
            }

            if (setting.settingIdentifier.endsWith(DataThresholdsService.maxThresholdPostfix)) {
                threshold.upperThreshold = this.parseThresholdValue(setting.value);
            }

            if (setting.settingIdentifier.endsWith(DataThresholdsService.addressesThresholdPostfix)) {
                threshold.mailAddresses = setting.value;
            }

            if (setting.settingIdentifier.endsWith(DataThresholdsService.sendNotificationThresholdPostfix)) {
                threshold.sendMailNotification = this.parseSendNotificationValue(setting.value);
            }
        });

        return dataThresholds;
    }

    public async upsertDataThresholds(thresholds: DataThresholds[]): Promise<Response> {
        const settings: IVenueSetting[] = [];
        for (const element of thresholds) {
            if (element.lowerThreshold !== undefined) {
                const minSetting = {
                    value: element.lowerThreshold.toString(),
                    settingIdentifier: DataThresholdsService.dataThresholdPrefix + element.dataType.toLowerCase() + DataThresholdsService.minThresholdPostfix
                };
                settings.push(minSetting);
            }

            if (element.upperThreshold !== undefined) {
                const maxSetting = {
                    value: element.upperThreshold.toString(),
                    settingIdentifier: DataThresholdsService.dataThresholdPrefix + element.dataType.toLowerCase() + DataThresholdsService.maxThresholdPostfix
                };
                settings.push(maxSetting);
            }

            const addressesSetting = {
                value: element.mailAddresses,
                settingIdentifier: DataThresholdsService.dataThresholdPrefix + element.dataType.toLowerCase() + DataThresholdsService.addressesThresholdPostfix
            };
            settings.push(addressesSetting);

            const sendNotificationSetting = {
                value: element.sendMailNotification ? "true" : "false",
                settingIdentifier: DataThresholdsService.dataThresholdPrefix + element.dataType.toLowerCase() + DataThresholdsService.sendNotificationThresholdPostfix
            };
            settings.push(sendNotificationSetting);
        }

        return await this.venueContextService.batchUpsertVenueSettings(settings);
    }

    private parseThresholdValue(value: string): number | undefined {
        const parsedValue = Number(value);
        return isNaN(parsedValue) ? undefined : parsedValue;
    }

    private parseSendNotificationValue(value: string): boolean {
        return value.toLowerCase() === "true";
    }
}