import DashboardService from "./dashboardService";
import VenueProvider from "../providers/venueProvider";
import IVenueSetting from "../interfaces/IVenueSetting";

export default class VenueContextService {
    private readonly dashboardService: DashboardService;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getVenueContext(skipCache: boolean = false): Promise<IVenueSetting[]> {
        const venue = VenueProvider.getActiveVenue();
        if(venue === undefined){
            throw new Error("Error getting venue settings: No venue found in venueprovider!");
        }
        return this.dashboardService.get<IVenueSetting[]>(`venuecontext/customer/${venue.customerId}/venue/${venue.id}`, skipCache);
    }

    public async getVenueSetting(settingIdentifier: string, skipCache: boolean = false): Promise<IVenueSetting> {
        const venue = VenueProvider.getActiveVenue();
        if(venue === undefined){
            throw new Error("Error getting specific venue setting: No venue found in venueprovider!");
        }
        return this.dashboardService.get<IVenueSetting>(`venuecontext/customer/${venue.customerId}/venue/${venue.id}/settings/${settingIdentifier}`, skipCache);
    }

    public async hasWritePermission(skipCache: boolean = false): Promise<boolean> {
        return this.dashboardService.get<boolean>('venuecontext/haswritepermission', skipCache);
    }

    public async upsertVenueSetting(venueSetting: IVenueSetting): Promise<Response> {
        const venue = VenueProvider.getActiveVenue();
        if(venue === undefined){
            throw new Error("Error upserting venue settings: No venue found in venueprovider!");
        }
        return this.dashboardService.put(`venuecontext/customer/${venue.customerId}/venue/${venue.id}`, venueSetting);
    }

    public async batchUpsertVenueSettings(venueSettings: IVenueSetting[]): Promise<Response> {
        const venue = VenueProvider.getActiveVenue();
        if(venue === undefined){
            throw new Error("Error batch upserting venue settings: No venue found in venueprovider!");
        }
        return this.dashboardService.put(`venuecontext/customer/${venue.customerId}/venue/${venue.id}/batch`, venueSettings);
    }
}