import { Component } from "react";
import IExpandedWorkspaceNotificationProps from "./IExpandedWorkspaceNotificationProps";
import WorkspaceNotificationProvider from "@/providers/workspaceNotificationProvider";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";

import './expandedNotification.scss';

export default class ExpandedWorkspaceNotification extends Component<IExpandedWorkspaceNotificationProps>{
    public constructor(props: IExpandedWorkspaceNotificationProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div className="expanded-notification">
                <div className="expanded-header mb-3">
                    {WorkspaceNotificationProvider.getTranslatedNotificationCategoryForWorkspaceNotification(this.props.notification)}
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.createdby)}
                                    </b>
                                </div>
                                {this.props.notification.createdBy.toString()}
                            </div>
                            <div className="col-sm-4">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.venuename)}
                                    </b>
                                </div>
                                {this.props.notification.venueName}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-5">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.createdon)}
                                    </b>
                                </div>
                                {this.props.notification.LocalizedCreatedOn.format("D-M-YYYY HH:mm")}
                            </div>
                            <div className="col-sm-4">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.spacename)}
                                    </b>
                                </div>
                                {this.props.notification.spaceName}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-5">
                                <div className="mb-2 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.resolvedon)}
                                    </b>
                                </div>
                                {this.props.notification.LocalizedResolvedOn !== undefined && this.props.notification.LocalizedResolvedOn !== null ? this.props.notification.LocalizedResolvedOn.format("D-M-YYYY HH:mm") : "--"}
                            </div>
                            <div className="col-sm-4">
                                <div className="mb-1 expand-title">
                                    <b>
                                        {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.status)}
                                    </b>
                                </div>
                                <div className="status-value">
                                    {this.props.notification.LocalizedResolvedOn !== undefined && this.props.notification.LocalizedResolvedOn !== null ? LanguageProvider.getTranslation(translations.pages.notifications.resolved) : LanguageProvider.getTranslation(translations.pages.notifications.notresolved)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 ml-3">
                        <div className="mb-2 expand-title">
                            <b>
                                {LanguageProvider.getTranslation(translations.pages.notifications.sharedexpand.description)}
                            </b>
                        </div>
                        {WorkspaceNotificationProvider.getTranslatedDescriptionForWorkspaceNotification(this.props.notification)}
                    </div>
                </div>
            </div>
        );
    }
}