import * as React from "react";
import IWorkSpaceSettingsTypeMapOverlayProps from "./interfaces/IWorkSpaceSettingsTypeMapOverlayProps";
import { PulseLoader } from 'react-spinners';
import LanguageProvider from "@/providers/languageProvider";
import translations from '@/translations/mapper';
import { withTranslation } from "react-i18next";
import Colors from "@/styles/colors";

class WorkSpaceSettingsTypeMapOverlay extends React.Component<IWorkSpaceSettingsTypeMapOverlayProps> {
    public constructor(props: IWorkSpaceSettingsTypeMapOverlayProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div className="map-overlay-wrapper">
                {this.props.loading && <div id="map-pulse-loader">
                    <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.props.loading} />
                </div>}
                {!this.props.loading && <div className="align-self-center">
                    <div className="asset-container">
                        <div className="option-container">
                            <div className="asset-row first-line">
                                <div className="selected-circle" />
                                <span className="asset-count">{this.props.selectedAssetCount}</span>
                            </div>
                            <div className="asset-row second-line"> {LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.selected)} </div>
                        </div>
                        {this.props.otherOptionAssetCount !== undefined && <div className="option-container">
                            <div className="asset-row first-line">
                                <div className="other-option-circle" />
                                <span className="asset-count">{this.props.otherOptionAssetCount}</span>
                            </div>
                            <div className="asset-row second-line"> {LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.otheroption)} </div>
                        </div>}
                        {this.props.noOptionAssetCount !== undefined && <div className="option-container">
                            <div className="asset-row first-line">
                                <div className="no-option-circle" />
                                <span className="asset-count">{this.props.noOptionAssetCount}</span>
                            </div>
                            <div className="asset-row second-line"> {LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.nooption)} </div>
                        </div>}
                    </div>
                </div>}
            </div>
        );
    }
}

export default withTranslation()(WorkSpaceSettingsTypeMapOverlay);