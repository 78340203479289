import { Component } from "react";
import Teaser from "./teaser";
import RealestateUtilizationTeaserImage from "../../images/bg_vastgoedgebruik.jpg";

import LanguageProvider from '../../providers/languageProvider';
import translations from '../../translations/mapper';
import links from "../../utils/links";

export default class RealEstateUtilization extends Component<{}> {
    public render(): JSX.Element {
        return (
            <Teaser
                imagePath={RealestateUtilizationTeaserImage}
                title={LanguageProvider.getTranslation(translations.pages.realestateutilization.title)}
                subtitle={LanguageProvider.getTranslation(translations.pages.realestateutilization.teaser.subtitle)}
                paragraph1={LanguageProvider.getTranslation(translations.pages.realestateutilization.teaser.paragraph1)}
                paragraph2={LanguageProvider.getTranslation(translations.pages.realestateutilization.teaser.paragraph2)}
                learnmoretext={LanguageProvider.getTranslation(translations.pages.realestateutilization.teaser.learnmore)}
                buttonLink={links.external.beyondeyes.realestateutilization}>
            </Teaser>
        );
    }
}