import * as React from "react";
import { GetHandleProps, SliderItem } from "react-compound-slider";

interface IHandleProps {
    handle: SliderItem;
    getHandleProps: GetHandleProps;
    customHandleDisplay?: (handleValue: number) => string;
}

export default class Handle extends React.Component<IHandleProps> {

    public constructor(props: IHandleProps) {
        super(props);
    }

    public render(): JSX.Element {
        const handleValue = this.props.customHandleDisplay ? this.props.customHandleDisplay(this.props.handle.value) : Math.round(this.props.handle.value * 10000) / 10000;
        return (
            <div
                className="handle-style"
                style={{
                    left: `${this.props.handle.percent}%`
                }}
                {...this.props.getHandleProps(this.props.handle.id)}
            >
                <div>
                    {handleValue}
                </div>
            </div>
        );
    }
}