import Translations from "../translations/mapper";
import LanguageProvider from './languageProvider';

export default class BeyondEyesServicesNamesMapper {
    public static MapService(serviceName: string): string {
        const beyondEyesServicesNames = [
            { id: 'All', name: LanguageProvider.getTranslation(Translations.pages.documents.services.general) },
            { id: 'BE Clean', name: LanguageProvider.getTranslation(Translations.pages.documents.services.cleaning) },
            { id: 'BE Energized', name: LanguageProvider.getTranslation(Translations.pages.documents.services.energymanagement) },
            { id: 'BE Equipped', name: LanguageProvider.getTranslation(Translations.pages.documents.services.realestateutilization) },
            { id: 'BE Vital', name: LanguageProvider.getTranslation(Translations.pages.documents.services.indoorclimate) }
        ];

        if (beyondEyesServicesNames.some(s => s.id === serviceName)) {
            return beyondEyesServicesNames.find(s => s.id === serviceName)!.name;
        }

        return serviceName;
    }
}