import { SubscriptionType } from "@/enums/subscriptionType";
import Dictionary from "@/utils/dictionary";
import CoreSpace from "@/models/coreSpace";
import ISubscription from "@/interfaces/energy/ISubscription";
import { ServiceType } from "@/enums/serviceType";
import DashboardService from "./dashboardService";

export default class SubscriptionValidationService {

    private static EmptyGuid: string = "00000000-0000-0000-0000-000000000000";
    private static SingletonInstance: SubscriptionValidationService;
    private static initializationPromise?: Promise<void>;
    private subscriptionTypesByVenueOrCustomer: Dictionary<SubscriptionType[]>;
    private subscriptionServicesByVenueOrCustomer: Dictionary<ServiceType[]>;
    private subscriptionsByService: Dictionary<ISubscription[]>;
    private subscriptionsByType: Dictionary<ISubscription[]>;
    private dashboardService: DashboardService;

    private constructor() {
        this.subscriptionTypesByVenueOrCustomer = new Dictionary<SubscriptionType[]>();
        this.subscriptionServicesByVenueOrCustomer = new Dictionary<ServiceType[]>();
        this.subscriptionsByService = new Dictionary<ISubscription[]>();
        this.subscriptionsByType = new Dictionary<ISubscription[]>();
        this.dashboardService = new DashboardService();
    }

    public static async GetInstanceAsync(): Promise<SubscriptionValidationService> {
        const initialized = !!SubscriptionValidationService.initializationPromise;

        if (!initialized) {
            SubscriptionValidationService.SingletonInstance = new SubscriptionValidationService();
            SubscriptionValidationService.initializationPromise = SubscriptionValidationService.SingletonInstance.InitializeAsync();
        }

        await SubscriptionValidationService.initializationPromise;
        return SubscriptionValidationService.SingletonInstance;
    }

    public userHasAnyService(): boolean {
        return this.subscriptionsByService.count > 0;
    }

    public userHasAnyApplicableService(allowedServices: ServiceType[]): boolean {
        return !!allowedServices.find(service => {
            const subs = this.subscriptionsByService.item(service);
            return subs && subs.length > 0;
        });
    }

    public userHasAnyApplicableSubscription(allowedTypes: SubscriptionType[]): boolean{
        return !!allowedTypes.find(type => {
            const subs = this.subscriptionsByType.item(type);
            return subs && subs.length > 0;
        });
    }

    public customerHasAnyApplicableService(customerId: string, allowedServices: ServiceType[]): boolean {
        return !!allowedServices.find(service => this.subscriptionsByService.item(service)?.find(s => s.customerId === customerId));
    }

    public customerHasAnyApplicableSubscription(customerId: string, allowedTypes: SubscriptionType[]): boolean {
        return !!allowedTypes.find(type => {
            const subs = this.subscriptionsByType.item(type)?.filter(s => s.customerId === customerId);
            return subs && subs.length > 0;
        });
    }

    public venueHasAnyApplicableService(venue: CoreSpace, allowedServices: ServiceType[]): boolean {
        return !!allowedServices.find(service => this.venueHasApplicableServiceInternal(venue, service));
    }

    private venueHasApplicableServiceInternal(venue: CoreSpace, type: ServiceType): boolean {
        return !!this.subscriptionServicesByVenueOrCustomer.item(venue.id)?.find(s => s === type)
            || !!this.subscriptionServicesByVenueOrCustomer.item(venue.customerId)?.find(s => s === type);
    }

    public venueHasAnyApplicableSubscription(venue: CoreSpace, allowedTypes: SubscriptionType[]): boolean {
        return !!allowedTypes.find(type => this.venueHasApplicableSubscriptionInternal(venue, type));
    }

    private venueHasApplicableSubscriptionInternal(venue: CoreSpace, type: SubscriptionType): boolean {
        return !!this.subscriptionTypesByVenueOrCustomer.item(venue.id)?.find(s => s === type)
            || !!this.subscriptionTypesByVenueOrCustomer.item(venue.customerId)?.find(s => s === type);
    }

    private async InitializeAsync(): Promise<void> {
        const subscriptions = await this.GetAllSubscriptions();

        subscriptions.forEach(s => {
            const type = s.type;
            if(this.subscriptionsByType.containsKey(type)){
                this.subscriptionsByType.item(type).push(s);
            }
            else {
                this.subscriptionsByType.add(type, [s]);
            }

            const service = s.service;
            if(this.subscriptionsByService.containsKey(service)){
                this.subscriptionsByService.item(service).push(s);
            }
            else {
                this.subscriptionsByService.add(service, [s]);
            }

            const venueOrCustomerKey = s.venueId && s.venueId !== SubscriptionValidationService.EmptyGuid ? s.venueId : s.customerId;

            if (this.subscriptionTypesByVenueOrCustomer.containsKey(venueOrCustomerKey)) {
                this.subscriptionTypesByVenueOrCustomer.item(venueOrCustomerKey).push(s.type);
            }
            else {
                this.subscriptionTypesByVenueOrCustomer.add(venueOrCustomerKey, [s.type]);
            }

            if (this.subscriptionServicesByVenueOrCustomer.containsKey(venueOrCustomerKey)) {
                this.subscriptionServicesByVenueOrCustomer.item(venueOrCustomerKey).push(service);
            }
            else {
                this.subscriptionServicesByVenueOrCustomer.add(venueOrCustomerKey, [service]);
            }
        });
    }

    private async GetAllSubscriptions(): Promise<ISubscription[]> {
        return await this.dashboardService.get<ISubscription[]>(`subscriptions`);
    }
}

