import IReactSelectValue from "../../../interfaces/IReactSelectValue";

export default class ReservationInsightsFilter {
    departments?: IReactSelectValue[];
    endDate?: Date;
    floors?: IReactSelectValue[];
    fromTime?: number;
    holidays?: IReactSelectValue[];
    places?: IReactSelectValue[];
    startDate?: Date;
    toTime?: number;
    typeOfPlaces?: IReactSelectValue[];
    venues: IReactSelectValue[];
    weekdays?: IReactSelectValue[];
    weeks?: IReactSelectValue[];

    constructor(
        departments?: IReactSelectValue[],
        endDate?: Date,
        floors?: IReactSelectValue[],
        fromTime?: number,
        holidays?: IReactSelectValue[],
        places?: IReactSelectValue[],
        startDate?: Date,
        toTime?: number,
        typeOfPlaces?: IReactSelectValue[],
        venues?: IReactSelectValue[],
        weekdays?: IReactSelectValue[],
        weeks?: IReactSelectValue[]
    ) {
        this.departments = departments || [];
        this.endDate = endDate;
        this.floors = floors || [];
        this.fromTime = fromTime;
        this.holidays = holidays;
        this.places = places || [];
        this.startDate = startDate;
        this.toTime = toTime || 24;
        this.typeOfPlaces = typeOfPlaces || [];
        this.venues = venues || [];
        this.weekdays = weekdays;
        this.weeks = weeks;
    }
}
