import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';

import LineGraph from '@/components/graphs/lineGraph';
import DataInsightsService from '@/services/dataInsightsService';
import IBaseSanitaryOverviewGraphProps from './interfaces/IBaseSanitaryOverviewGraphProps';
import IBaseSanitaryOverviewGraphState from './interfaces/IBaseSanitaryOverviewGraphState';
import translations from '@/translations/mapper';
import LanguageProvider from '@/providers/languageProvider';
import Colors from '@/styles/colors';

export default abstract class BaseSanitaryOverviewGraph extends React.Component<IBaseSanitaryOverviewGraphProps, IBaseSanitaryOverviewGraphState> {
    protected dataInsightsService: DataInsightsService;

    public constructor(props: IBaseSanitaryOverviewGraphProps) {
        super(props);
        this.dataInsightsService = new DataInsightsService();

        const state: IBaseSanitaryOverviewGraphState = {
            chartData: [],
            loading: true
        };

        this.state = state;
    }

    public async componentDidMount(): Promise<void> {
        await this.updateChartData();

        this.setState({
            loading: false
        });
    }

    public async componentDidUpdate(previousProps: IBaseSanitaryOverviewGraphProps, previousState: IBaseSanitaryOverviewGraphState): Promise<void> {
        if (previousProps.startDate === this.props.startDate
            && previousProps.endDate === this.props.endDate
            && previousProps.assetIds.length === this.props.assetIds.length
            && previousProps.floorId === this.props.floorId
            && previousProps.venueId === this.props.venueId
            && previousProps.selectedDataType === this.props.selectedDataType) {
            return;
        }

        this.setState({
            loading: true
        });

        this.clearGraphData();

        await this.updateChartData();

        this.setState({
            loading: false
        });
    }

    public abstract updateChartData(): Promise<void>;

    public abstract getLabels(): string[];

    private clearGraphData(): void {
        this.setState({
            chartData: []
        });
    }

    public render(): JSX.Element {
        const labels = this.getLabels();
        const averageLabel = LanguageProvider.getTranslation(translations.pages.sanitaryinsights.graph.averagelabel);

        const dataSets: Chart.ChartDataSets[] = this.state.chartData.filter((assetData) => assetData.data.length > 0).map((assetData, i) => {
            const color = assetData.color;
            return {
                label: assetData.isAverage ? averageLabel : assetData.label,
                data: assetData.data,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: color,
                yAxisID: 'line',
                borderWidth: 1.5,
                pointStyle: "circle",
                pointBackgroundColor: color,
                pointBorderColor: color,
                pointRadius: assetData.isAverage ? 2 : 4,
                borderDash: assetData.isAverage ? [5, 5] : undefined
            } as Chart.ChartDataSets;
        });
        const data: ChartData = {
            labels: labels,
            datasets: dataSets
        };

        let minValue = Number.MAX_SAFE_INTEGER;
        let maxValue = 0;

        for (const dataSet of this.state.chartData) {
            if (dataSet.data) {
                for (const dataPoint of dataSet.data) {
                    if (dataPoint !== undefined) {
                        minValue = Math.min(minValue, dataPoint);
                        maxValue = Math.max(maxValue, dataPoint);
                    }
                }
            }
        }

        if (minValue === Number.MAX_SAFE_INTEGER) {
            minValue = 1;
        }

        if (this.state.chartData.length === 0) {
            minValue = 1;
            maxValue = -1;
        }

        const options: ChartOptions = {
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        beginAtZero: true
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }],
                yAxes: [{
                    id: 'line',
                    type: 'linear',
                    position: 'left',
                    gridLines: {
                        drawBorder: false
                    },
                    ticks: {
                        suggestedMin: Math.max(minValue - 1, 0),
                        suggestedMax: this.props.selectedDataType === 'percentage' ? Math.min(100, maxValue + 1) : maxValue + 1,
                        precision: 0,
                        fontColor: Colors.black,
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: 12,
                        fontStyle: "100",
                        padding: 10
                    }
                }]
            },
            maintainAspectRatio: true,
            legend: {
                display: false
            }
        };

        return (
            <div className="pt-3">
                <LineGraph data={data} height={this.props.height} width={this.props.width} options={options} showLegend={true} legendFilter={(dataset): boolean => dataset.label === averageLabel} loading={this.state.loading} />
            </div>
        );
    }
}