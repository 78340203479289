import { Component } from "react";

import Teaser from "@/components/teaser/teaser";
import LanguageProvider from '@/providers/languageProvider';
import translations from '@/translations/mapper';
import GeneralTeaserImage from "@/images/General_Teaser.jpg";

interface IGeneralTeaserProps {
    buttonLink: string;
}

export default class GeneralTeaser extends Component<IGeneralTeaserProps> {
    public constructor(props: IGeneralTeaserProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <Teaser
                imagePath={GeneralTeaserImage}
                title={LanguageProvider.getTranslation(translations.generalteaser.title)}
                paragraph1={LanguageProvider.getTranslation(translations.generalteaser.maintext)}
                learnmoretext={LanguageProvider.getTranslation(translations.generalteaser.learnmore)}
                buttonLink={this.props.buttonLink}>
            </Teaser>
        );
    }
}