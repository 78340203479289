import * as React from 'react';
import { Component } from 'react';

import './thresholdSettingsModal.scss';

import IThresholdSettingsModalProps from './interfaces/IThresholdSettingsModalProps';
import IThresholdSettingsModalState from './interfaces/IThresholdSettingsModalState';

import Cross_Cancel from '@/images/Cross_Cancel.svg';
import SaveButton from "@/images/save-icon-white.svg";

import UserContext from '@/models/userContext';
import UserContextProvider from '@/providers/userContextProvider';
import UserContextService from '@/services/userContextService';
import { ThresholdSubtype } from './enums/thresholdSubtype';
import IGraphThreshold from '../../../interfaces/IGraphThreshold';
import { ThresholdType } from './enums/thresholdType';
import LanguageProvider from '@/providers/languageProvider';
import SliderComponent from '@/components/slider/sliderComponent';

class ThresholdSettingsModal extends Component<IThresholdSettingsModalProps, IThresholdSettingsModalState> {
    private currentUserContextMaxThreshold: number | undefined;
    private currentUserContextMinThreshold: number | undefined;

    private userContext: UserContext;
    private readonly userContextProvider: UserContextProvider = new UserContextProvider();
    private readonly userContextService: UserContextService = new UserContextService();

    private readonly subtypeTemperature: string = ThresholdSubtype[ThresholdSubtype.temperature];
    private readonly subtypeCarbondioxide: string = ThresholdSubtype[ThresholdSubtype.carbondioxide];
    private readonly subtypeHumidity: string = ThresholdSubtype[ThresholdSubtype.humidity];
    private readonly subtypeOccupancy: string = ThresholdSubtype[ThresholdSubtype.occupancy];

    private readonly typeComfort: string = "comfort";

    public constructor(props: IThresholdSettingsModalProps) {
        super(props);
        const state: IThresholdSettingsModalState = {
            newMaxThreshold: {
                isMinThreshold: false,
                subtype: this.props.thresholdSubtype,
                type: this.props.thresholdType,
                value: 0,
            },
            newMinThreshold: {
                isMinThreshold: true,
                subtype: this.props.thresholdSubtype,
                type: this.props.thresholdType,
                value: 0,
            },
            sliderDomainRange: [0, 35],
        };

        this.state = state;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleThresholdSliderChange = this.handleThresholdSliderChange.bind(this);
        this.getDefaultValues = this.getDefaultValues.bind(this);
        this.getDomainForSlider = this.getDomainForSlider.bind(this);
        this.getUserContextThresholds = this.getUserContextThresholds.bind(this);
        this.resetToDefaultValues = this.resetToDefaultValues.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        await this.getUserContextThresholds();
        await this.getDomainForSlider();
        await this.getDefaultValues();
    }

    public async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
        if (prevProps.thresholdSubtype !== this.props.thresholdSubtype) {
            await this.getUserContextThresholds();
        }
    }

    private async getUserContextThresholds(): Promise<void> {
        const userContext = await UserContextProvider.getUserContextAsync();
        const currentThresholds = await userContext.getGraphThresholdsBySubtype(this.props.thresholdSubtype);

        const newMaxThreshold: IGraphThreshold = {
            isMinThreshold: false,
            subtype: this.props.thresholdSubtype,
            type: this.props.thresholdType,
            value: currentThresholds.max !== undefined ? currentThresholds.max : undefined
        };
        const newMinThreshold: IGraphThreshold = {
            isMinThreshold: true,
            subtype: this.props.thresholdSubtype,
            type: this.props.thresholdType,
            value: currentThresholds.min !== undefined ? currentThresholds.min : undefined
        };

        const domainRange = this.getDomainForSlider();
        this.currentUserContextMaxThreshold = currentThresholds.max !== undefined ? currentThresholds.max : undefined;
        this.currentUserContextMinThreshold = currentThresholds.min !== undefined ? currentThresholds.min : undefined;

        this.setState({
            newMaxThreshold,
            newMinThreshold,
            sliderDomainRange: domainRange
        });
    }

    private getDomainForSlider(): number[] {
        let sliderStartAt: number = 0;
        let sliderEndAt: number = 100;

        if (this.props.thresholdType === this.typeComfort) {
            switch (this.props.thresholdSubtype) {
                case this.subtypeTemperature:
                    sliderStartAt = 0;
                    sliderEndAt = 50;
                    break;
                case this.subtypeCarbondioxide:
                    sliderStartAt = 0;
                    sliderEndAt = 5000;
                    break;
                case this.subtypeHumidity:
                    sliderStartAt = 0;
                    sliderEndAt = 100;
                    break;
                case this.subtypeOccupancy:
                    sliderStartAt = 0;
                    sliderEndAt = 100;
                    break;
                default:
                    sliderStartAt = 0;
                    sliderEndAt = 100;
                    break;
            }
        } else if (this.props.thresholdType === ThresholdType[ThresholdType.heatmap]) {
            switch (this.props.thresholdSubtype) {
                case this.subtypeOccupancy:
                    sliderStartAt = 0;
                    sliderEndAt = 100;
                    break;
                default:
                    sliderStartAt = 0;
                    sliderEndAt = 100;
                    break;
            }
        }

        return [sliderStartAt, sliderEndAt];
    }

    private getDefaultValues(): void {
        const newMaxThreshold: IGraphThreshold = {
            isMinThreshold: false,
            subtype: this.props.thresholdSubtype,
            type: this.props.thresholdType,
            value: this.currentUserContextMaxThreshold
        };
        const newMinThreshold: IGraphThreshold = {
            isMinThreshold: true,
            subtype: this.props.thresholdSubtype,
            type: this.props.thresholdType,
            value: this.currentUserContextMinThreshold
        };

        this.setState({
            newMaxThreshold,
            newMinThreshold
        });
    }

    private async handleSubmit(): Promise<void> {
        this.props.updateCallback(this.props.thresholdSubtype, this.state.newMinThreshold.value, this.state.newMaxThreshold.value);
        await this.userContextService.updateUserThresholds([this.state.newMaxThreshold, this.state.newMinThreshold]);
    }

    private handleThresholdSliderChange(values: readonly number[]): void {
        if (values.length > 1) {
            const minHandle = values[0];
            const maxHandle = values[1];

            const newMaxThreshold = this.state.newMaxThreshold;
            newMaxThreshold.value = maxHandle;
            const newMinThreshold = this.state.newMinThreshold;
            newMinThreshold.value = minHandle;

            this.setState({
                newMaxThreshold,
                newMinThreshold
            });
        } else {
            const maxHandle = values[0];

            const newMaxThreshold = this.state.newMaxThreshold;
            newMaxThreshold.value = maxHandle;

            this.setState({ newMaxThreshold });
        }
    }

    private async resetToDefaultValues(): Promise<void> {
        let newMin: number = 1;
        let newMax: number = 25;

        if (this.props.thresholdType === ThresholdType[ThresholdType.heatmap]) {
            switch (this.props.thresholdSubtype) {
                case ThresholdSubtype[ThresholdSubtype.occupancy]:
                    newMin = 3;
                    newMax = 50;
                    break;
                default:
                    newMin = 3;
                    newMax = 50;
                    break;
            }
        } else if (this.props.thresholdType === ThresholdType[ThresholdType.comfort]) {
            switch (this.props.thresholdSubtype) {
                case ThresholdSubtype[ThresholdSubtype.carbondioxide]:
                    newMax = 1100;
                    break;
                case ThresholdSubtype[ThresholdSubtype.humidity]:
                    newMax = 60;
                    newMin = 40;
                    break;
                case ThresholdSubtype[ThresholdSubtype.temperature]:
                    newMax = 23;
                    newMin = 20;
                    break;
                default:
                    break;
            }
        }

        const newMaxThreshold: IGraphThreshold = {
            isMinThreshold: false,
            subtype: this.props.thresholdSubtype,
            type: this.props.thresholdType,
            value: newMax
        };
        const newMinThreshold: IGraphThreshold = {
            isMinThreshold: true,
            subtype: this.props.thresholdSubtype,
            type: this.props.thresholdType,
            value: newMin
        };

        if (this.props.thresholdSubtype !== ThresholdSubtype[ThresholdSubtype.carbondioxide]) {
            this.setState({
                newMaxThreshold,
                newMinThreshold
            });
        } else {
            this.setState({
                newMaxThreshold
            });
        }
    }

    public render(): JSX.Element {
        const sliderValues: number[] = this.state.newMinThreshold.value !== undefined ?
            [this.state.newMinThreshold.value, this.state.newMaxThreshold.value ? this.state.newMaxThreshold.value : 1] :
            [this.state.newMaxThreshold.value ? this.state.newMaxThreshold.value : 1];
        return (
            <div className="threshold-settings-modal">
                <div className="modal fade" id="threshold-settings-modal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header threshold-modal-header">
                                <div className="modal-title">{LanguageProvider.getTranslation("threshold-settings-modal.modal-title")}</div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.getUserContextThresholds}>
                                    <img src={Cross_Cancel} />
                                </button>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <span>
                                    <div className="modal-body">
                                        <div className="threshold-sub-header row">
                                            <div className="col">{LanguageProvider.getTranslation("threshold-settings-modal.set-threshold")}</div>
                                            <div className="threshold-reset-values col" onClick={this.resetToDefaultValues}>{LanguageProvider.getTranslation("threshold-settings-modal.reset-values")}</div>
                                        </div >
                                        <SliderComponent
                                            title={''}
                                            domain={this.state.sliderDomainRange}
                                            step={this.props.thresholdSubtype === this.subtypeTemperature ? 0.1 : 1}
                                            values={sliderValues}
                                            onChange={this.handleThresholdSliderChange}
                                            mode={2}
                                            ticksCount={0}
                                            showTitle={false}
                                            sliderTrackClassName={this.props.thresholdType === ThresholdType[ThresholdType.heatmap] ? 'heatmap-gradient' : undefined}
                                        />
                                    </div>
                                </span>
                                <span>
                                    <div className="modal-footer d-flex justify-content-center">
                                        <button type="button" className="threshold-cancel-button btn-secondary" id="threshold-cancel-button" data-dismiss="modal" onClick={this.getUserContextThresholds}>
                                            {LanguageProvider.getTranslation("threshold-settings-modal.cancel")}
                                        </button>
                                        <button type="button" className="threshold-save-button btn-primary" id="threshold-save-button" data-dismiss="modal" onClick={this.handleSubmit}>
                                            {LanguageProvider.getTranslation("threshold-settings-modal.save")}
                                            <img src={SaveButton} />
                                        </button>
                                    </div>
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ThresholdSettingsModal;