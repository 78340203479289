import { ISpaceOnMap, ISpaceStyle, IStyle, IStyler } from "@beyondeyes/shared";
import Dictionary from "utils/dictionary";
import Colors from "@/styles/colors";
import BaseStyler from "./baseStyler";

class NotificationStyler extends BaseStyler implements IStyler {
	private readonly isSpaceNotifiableLookup: Dictionary<boolean>;
	private readonly isNotificationForSpaceEnabledLookup: Dictionary<boolean>;

	public constructor() {
		super();
		this.isSpaceNotifiableLookup = new Dictionary<boolean>();
		this.isNotificationForSpaceEnabledLookup = new Dictionary<boolean>();
	}

	public getStyle(spaceOnMap: ISpaceOnMap): IStyle | undefined {
		const space = spaceOnMap.beSpace;

		if (space == null) {
			return undefined;
		}

		if (!this.isSpaceNotifiableLookup.containsKey(space.id)) {
			return {};
		}

		const placeStyle = this.getSpaceStyle(spaceOnMap);

		return {
			placeStyle: placeStyle,
			placeLabel: { text: space.name, size: this.defaultLabelSize }
		};
	}

	public setNotificationStylingForSpace(spaceId: string, enabled: boolean): void {
		this.isSpaceNotifiableLookup.add(spaceId, true);
		this.isNotificationForSpaceEnabledLookup.add(spaceId, enabled);
	}

	public clearNotificationStylingForSpaces(): void {
		this.isSpaceNotifiableLookup.clear();
		this.isNotificationForSpaceEnabledLookup.clear();
	}

	public isSpaceNotifiable(spaceId: string): boolean {
		return this.isSpaceNotifiableLookup.containsKey(spaceId);
	}

	private getSpaceStyle(spaceOnMap: ISpaceOnMap): ISpaceStyle {

		const color = this.isNotificationForSpaceEnabledLookup.item(spaceOnMap.beSpace!.id) ? Colors.signal_green : Colors.persian_red;
		return {
			fillColor: color,
			fillOpacity: 0.95,
		};
	}
}

export default NotificationStyler;
