
import i18next from "i18next";

import moment from 'moment';
import "moment/locale/nl";

import { ApplicationConfig } from "../config";

import nlTranslations from "./../translations/nl.json";
import enTranslations from "./../translations/en.json";

import translationsMapper from "../translations/mapper";
import AppEventHub, { AppEvents } from "../utils/appEventHub";
import Comparer from "../utils/comparer";
import TranslationMapperGenerator from "../utils/translationMapperGenerator";

export enum LanguageCode {
    EN,
    NL
}

export default class LanguageProvider {
    private static activeLanguageFile: any;
    private static availableLanguages: any[] = [
        { name: "English", code: LanguageCode[LanguageCode.EN], translations: enTranslations },
        { name: "Nederlands", code: LanguageCode[LanguageCode.NL], translations: nlTranslations }
    ];

    private static localStorageLanguageItem: string = `bs.${ApplicationConfig.environment}.language.preference`;

    private static activeLanguageCodeBackingField: LanguageCode = 0;

    public static get activeLanguage(): LanguageCode {
        const languagePreference = localStorage.getItem(this.localStorageLanguageItem);
        if (languagePreference) {
            this.activeLanguageCodeBackingField = LanguageCode[languagePreference];
        } else if (window.navigator.language === "nl-NL") {
            localStorage.setItem(this.localStorageLanguageItem, LanguageCode[1]);
            this.activeLanguageCodeBackingField = 1;
        } else {
            localStorage.setItem(this.localStorageLanguageItem, LanguageCode[this.activeLanguageCodeBackingField]);
        }
        moment.locale(this.activeLanguageCodeBackingField === 1 ? "nl" : "en");
        return this.activeLanguageCodeBackingField;
    }

    public static set activeLanguage(value: LanguageCode) {
        localStorage.setItem(this.localStorageLanguageItem, LanguageCode[value]);
        LanguageProvider.activeLanguageCodeBackingField = value;
        LanguageProvider.activeLanguageFile = null;
        AppEventHub.emit(AppEvents.LanguageChanged, value);
    }

    public static initialize(): void {
        const languagePreference = localStorage.getItem(this.localStorageLanguageItem);

        LanguageProvider.activeLanguageCodeBackingField = languagePreference ? LanguageCode[languagePreference] : LanguageCode.NL;

        i18next.init({
            interpolation: { escapeValue: false },  // React already does escaping
            lng: 'en',                              // language to use
            defaultNS: 'common',
            react: { useSuspense: false },
            resources: {
                en: {
                    common: enTranslations               // 'common' is our custom namespace
                },
                nl: {
                    common: nlTranslations
                },
            },

        });

        // for (const language of LanguageProvider.availableLanguages) {
        //    properties.addTranslationForLanguage(language.translations, language.code);
        // }

        moment.locale(this.activeLanguageCodeBackingField === 1 ? "nl" : "en");
    }

    public static createMapper(): void {
        const isTestOrDevEnvironment = (ApplicationConfig.environment === "test" || ApplicationConfig.environment === "dev");
        if (!isTestOrDevEnvironment) {
            return;
        }
        console.warn("Mapper is invalid, a new mapper will be generated because you're in dev/ test mode");

        const mapper = TranslationMapperGenerator.GenerateMapper(nlTranslations);

        const localStorageItemName = `bs.${ApplicationConfig.environment}.translationMapper`;
        localStorage.setItem(localStorageItemName, JSON.stringify(mapper));

        console.log(`Because you are running test/ dev, a new translationMapper has been generated for you in localStorage: ${localStorageItemName}`);
    }

    public static getTranslation(label: string): string {
        label = label.toLocaleLowerCase();
        let languageDetails;
        if (!LanguageProvider.activeLanguageFile) {
            languageDetails = this.availableLanguages.find(i => i.code === LanguageCode[this.activeLanguageCodeBackingField]);
        }

        if (!LanguageProvider.activeLanguageFile) {
            if (!languageDetails) {
                return `Unable to find translation file for code ${LanguageCode[this.activeLanguageCodeBackingField]}`;
            }

            LanguageProvider.activeLanguageFile = languageDetails.translations;
        }

        const translation = label
            .split(".")
            .reduce((p, c) => p && p[c] !== null ? p[c] : `Translation (${LanguageCode[this.activeLanguageCodeBackingField]}) not found for '${label}'`, this.activeLanguageFile);

        return translation;
    }

    public static getTranslations(labels: string[]): string[] {
        const translations = labels.map((l) => {
            return LanguageProvider.getTranslation(l);
        });
        return translations;
    }

    public static validateTranslations(): void {
        const languages = LanguageProvider.availableLanguages.concat([
            { name: "Mapper", code: LanguageCode[LanguageCode.NL], translations: translationsMapper }
        ]);

        const valid = Comparer.compareProperties(languages[0], languages[1], 'EN', 'NL');
        const validMapper = Comparer.compareProperties(languages[1], languages[2], 'NL', 'MAPPER');

        if (!valid) {
            console.warn("Differences were found between language files.");
        } else if (!validMapper) {
            this.createMapper();
        }
    }
}