import * as React from "react";
import { PulseLoader } from "react-spinners";

import IMapDetailsProps from "./interfaces/IMapDetailsProps";
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import translations from "translations/mapper";
import LanguageProvider from "providers/languageProvider";
import Refresh from "@/images/Refresh.svg";
import Colors from "@/styles/colors";
import "./mapDetails.scss";
import CoreSpaceTypes from "@/enums/coreSpaceTypes";

export default class MapDetails extends React.Component<IMapDetailsProps> {

    public render(): JSX.Element {
        const motionSpaces = this.props.spaces?.filter((space) => space?.hasMotionDevices ?? false) ?? [];

        const Rooms = motionSpaces?.filter((space) => space.type === CoreSpaceTypes.Room);
        const occupiedRooms = Rooms?.filter((room) => room.isOccupied).length ?? 0;
        const coolingDownRooms = Rooms?.filter((room) => room.isCoolingDown).length ?? 0;
        const freeRooms = Rooms.length - occupiedRooms - coolingDownRooms;

        const Workspaces = motionSpaces?.filter((space) => space.type === CoreSpaceTypes.Workspace);
        const occupiedWorkspaces = Workspaces?.filter((workspace) => workspace.isOccupied).length ?? 0;
        const coolingDownWorkspaces = Workspaces?.filter((workspace) => workspace.isCoolingDown).length ?? 0;
        const freeWorkspaces = Workspaces.length - occupiedWorkspaces - coolingDownWorkspaces;

        return (<div className="map-details d-flex">
            <div className="map-details-legend d-flex align-items-center justify-content-around">
                {this.props.loading && (
                    <PulseLoader color={Colors.midnight_black} size={8} margin="5px" loading={this.props.loading} />
                )}
                {!this.props.loading && (
                    <>
                        <div className="count-container d-flex align-items-center">
                            <div className="count-tile unoccupied"></div>
                            <div className="ml-3">
                                <div>{LanguageProvider.getTranslation(translations.mapdetails.unoccupied)}</div>
                                {this.getSubtitle(freeRooms, freeWorkspaces)}
                            </div>
                        </div>

                        <div className="count-container d-flex align-items-center">
                            <div className="count-tile coolingdown"></div>
                            <div className="ml-3">
                                <div>{LanguageProvider.getTranslation(translations.mapdetails.coolingdown)}</div>
                                {this.getSubtitle(coolingDownRooms, coolingDownWorkspaces)}
                            </div>
                        </div>

                        <div className="count-container d-flex align-items-center">
                            <div className="count-tile occupied"></div>
                            <div className="ml-3">
                                <div>{LanguageProvider.getTranslation(translations.mapdetails.occupied)}</div>
                                {this.getSubtitle(occupiedRooms, occupiedWorkspaces)}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="map-details-refresh" onClick={(): void => AppEventHub.emit(AppEvents.MapRefreshed)}>
                <img src={Refresh} id="refresh-button" />
            </div>
        </div>
        );
    }

    private getSubtitle(rooms: number, workspaces: number): JSX.Element {
        return (
            <div className="subtitle">
                <span className="count-number">{rooms}</span>
                {LanguageProvider.getTranslation(rooms === 1 ? translations.mapdetails.room : translations.mapdetails.rooms)}
                <span className="count-number ml-2">{workspaces}</span>
                {LanguageProvider.getTranslation(workspaces === 1 ? translations.mapdetails.workspace : translations.mapdetails.workspaces)}
            </div>
        )
    }
}