import moment from 'moment';

export default class WeekDayUtils {
    public static getFirstDayOfWeek(day: moment.Moment): moment.Moment {
        moment.locale('en');
        const date = moment(day);

        // Substract the day of the week to get the first day of the week.
        date.subtract(date.day(), 'day');

        return date;
    }

    public static getFirstDayOfWeekByWeek(year: number, week: number): moment.Moment {
        moment.locale('en');
        const endOfWeek = moment(`${year}-${week}-6`, 'YYYY-w-e');
        return WeekDayUtils.getFirstDayOfWeek(endOfWeek);
    }

    public static getLastDayOfWeek(day: moment.Moment): moment.Moment {
        const firstDayOfWeek = WeekDayUtils.getFirstDayOfWeek(day);
        return firstDayOfWeek.add('6', 'days');
    }

    public static getWeekNumber(day: moment.Moment): number {
        moment.locale('en');
        return day.week();
    }
}