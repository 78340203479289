export default class DataTresholds {
    public constructor(public dataType: string, public mailAddresses: string, public sendMailNotification: boolean, public lowerThreshold?: number, public upperThreshold?: number) {
    }

    public updateThresholdsValues(newThreshold: DataTresholds): void {
        this.lowerThreshold = newThreshold.lowerThreshold;
        this.upperThreshold = newThreshold.upperThreshold;
        this.mailAddresses = newThreshold.mailAddresses;
        this.sendMailNotification = newThreshold.sendMailNotification;
    }
}