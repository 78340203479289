import BaseNotification from "./abstractions/baseNotification";

export default class thresholdNotification extends BaseNotification{
    public constructor(
        public createdOn: Date,
        public spaceName: string,
        public floorName: string,
        public venueName: string,
        public category: string,
        public measuredValue: number,
        public thresholdValue: number,
        public createdBy: string,
        public resolvedOn?: Date) {
            super(createdOn, spaceName, floorName, venueName, category, createdBy, resolvedOn);
    }
}