import * as moment from 'moment-timezone';
import TimeZoneUtils from "../../utils/timeZoneUtils";

export default class BaseNotification {
    public constructor(
        public createdOn: Date,
        public spaceName: string,
        public floorName: string,
        public venueName: string,
        public category: string,
        public createdBy: string,
        public resolvedOn?: Date) {
    }

    public get LocalizedCreatedOn(): moment.Moment {
        return TimeZoneUtils.ConvertUtcDateToWestEurope(this.createdOn);
    }

    public get LocalizedResolvedOn(): moment.Moment | undefined {
        if (!this.resolvedOn) {
            return this.resolvedOn;
        }

        return TimeZoneUtils.ConvertUtcDateToWestEurope(this.resolvedOn);
    }
}