import * as React from "react";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import DataTable, { TableColumn } from 'react-data-table-component';

import IWorkSpaceSettingsTypeActiveTagsProps from "./interfaces/IWorkSpaceSettingsTypeActiveTagsProps";
import ITableTag from "./interfaces/ITableTag";
import LanguageProvider from "@/providers/languageProvider";
import translations from "@/translations/mapper";
import AppEventHub, { AppEvents } from "@/utils/appEventHub";
import TimeZoneUtils from "@/utils/timeZoneUtils";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";

import { Tag_Delete, Checkbox_Selected, Checkbox_Not_Selected } from "@/images";
import "./workSpaceSettingsTypeActiveTags.scss";
import PageUtils from "@/utils/pageUtils";

const WorkSpaceSettingsTypeActiveTags: React.FC<IWorkSpaceSettingsTypeActiveTagsProps> = (props) => {

    const [tags, setTags] = useState<ITableTag[]>(props.tags);
    const [columns, setColumns] = useState<any>(getColumns());
    const [selectedOptionIdentifier, setSelectedOptionIdentifier] = useState<string>("");

    useEffect(() => {
        AppEventHub.on(AppEvents.LanguageChanged, updateColumns);

        return (): void => AppEventHub.off(AppEvents.LanguageChanged, updateColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTags(props.tags);
        updateColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tags]);

    useEffect(() => {
        updateColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptionIdentifier]);

    function updateColumns(): void {
        const columns = getColumns();

        setColumns(columns);
    }

    function setActiveRow(row: ITableTag): void {
        if (selectedOptionIdentifier !== row.optionIdentifier) {
            setSelectedOptionIdentifier(row.optionIdentifier);

            props.selectOptionIdentifier(row.optionIdentifier);
        }
    }

    function deleteRow(row: ITableTag): void {
        props.removeOptionidentifierClicked(row.optionIdentifier, row.name);
    }
    function getColumns(): TableColumn<ITableTag>[] {
        const columns =
            [
                {
                    id: 'select',
                    selector: (row: ITableTag): JSX.Element => (
                        <>
                            <img src={row.optionIdentifier === selectedOptionIdentifier ? Checkbox_Selected : Checkbox_Not_Selected}
                                className={`select-option-identifier`}
                                data-row-option-identifier={row.optionIdentifier}
                                onClick={(): void => setActiveRow(row)} />
                        </>),
                    width: '60px',
                },
                {
                    id: 'name',
                    name: LanguageProvider.getTranslation('pages.settings.workspaces.typetab.tagname'),
                    selector: (row): string => row.name,
                    sortable: true,
                    width: '250px',
                },
                {
                    id: 'count',
                    name: LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.tagscount),
                    selector: (row): number => row.count!,
                    sortable: true,
                    width: '200px',
                },
                {
                    id: 'lastmodified',
                    name: LanguageProvider.getTranslation(translations.pages.settings.workspaces.typetab.lastmodified),
                    selector: (row): string => row.lastModified ? TimeZoneUtils.ConvertUtcDateToWestEurope(row.lastModified).format("DD-MM-YYYY HH:mm") : "",
                    sortField: 'lastmodified',
                    sortable: true,
                    width: '200px',
                },
                {
                    id: 'buttons',
                    name: '',
                    selector: (row: ITableTag): JSX.Element => (
                        <div className="active-tags-buttons">
                            <label className="delete-button row-button" onClick={(): void => deleteRow(row)}>
                                <img src={Tag_Delete} data-row-option-identifier={row.optionIdentifier} data-row-option-label={row.name} />
                            </label>
                        </div>
                    ),
                    width: '50px',
                    right: true
                }
            ];

        return columns as TableColumn<ITableTag>[];
    }

    return (
        <div className="workspace-active-tags">
            {<CenteredPageLoader loading={props.loading} />}
            {!props.loading &&
                <DataTable
                className="workspaces-table besense-grey-border-table"
                progressPending={props.loading}
                columns={columns}
                data={tags}
                pagination={true}
                paginationPerPage={20}
                defaultSortFieldId={"lastmodified"}
                defaultSortAsc={false}
                onRowClicked={(row: ITableTag): void => { setActiveRow(row); }}
                paginationComponentOptions={PageUtils.getDefaultPaginationOptions()}
                />}
        </div>
    );
}

export default withTranslation()(WorkSpaceSettingsTypeActiveTags);