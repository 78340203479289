import * as React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import './holidayConsumptionPage.scss';
import './energyReportStyling.scss';

import IHolidayConsumptionPageProps from "./interfaces/IHolidayConsumptionPageProps";
import IHolidayConsumptionPageState from "./interfaces/IHolidayConsumptionPageState";
import { withTelemetry } from '@/services/telemetryService';
import PageHeader from "@/components/header/pageHeader";
import EnergyManagementPage from '../../energyManagementPage';
import DatePickerDeluxe from '../../components/datePicker/datePickerDeluxe';

import EnergySelectedIcon from '@/images/Energy_selected.svg';
import EnergyIcon from '@/images/Energy_unselected.svg';
import GasSelectedIcon from '@/images/Gas_selected.svg';
import GasIcon from '@/images/Gas_unselected.svg';
import ColdSelectedIcon from '@/images/Cold_selected.svg';
import ColdIcon from '@/images/Cold_unselected.svg';
import HeatSelectedIcon from '@/images/Heat_selected.svg';
import HeatIcon from '@/images/Heat_unselected.svg';
import { IDatePickerTimeUnit } from '../../../../interfaces/IDatePickerTimeUnit';
import EnergyType from '../../components/enums/energyType';
import ColorProvider from '@/providers/colorProvider';
import VenueProvider from '@/providers/venueProvider';
import EnergyUnitType from '../../components/enums/energyUnitType';
import EnergyGraph from '../../components/energyGraph';
import EnergyGraphHeader from '../../components/energyGraphHeader';
import IEnergyGraphData from '../interfaces/IEnergyGraphData';
import EnergyService from "@/services/energyService";
import LanguageProvider from '@/providers/languageProvider';
import translations from '@/translations/mapper';
import SubscriptionValidationService from '@/services/subscriptionValidationService';
import { IEnergyUnitType } from '../../components/interfaces/IEnergyUnitType';
import IHolidayHourlyEnergy from 'interfaces/energy/IHolidayHourlyEnergy';
import AppEventHub, { AppEvents } from 'utils/appEventHub';

class HolidayConsumptionPage extends React.Component<IHolidayConsumptionPageProps, IHolidayConsumptionPageState>{
    private readonly graphHtmlReference: React.RefObject<HTMLDivElement>;
    private readonly energyService: EnergyService;
    private subscriptionValidationService: SubscriptionValidationService;

    private viewportHeightThresholdForIncreasedGraphHeight: number = 1000;

    public constructor(props: IHolidayConsumptionPageProps) {
        super(props);

        this.graphHtmlReference = React.createRef();
        const activeVenue = VenueProvider.getActiveVenue();
        const state: IHolidayConsumptionPageState = {
            loading: true,
            venue: activeVenue,
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate(),
            year: moment().startOf('year').toDate().getFullYear(),
            selectedUnitType: "kWh",
            newUnitType: "kWh",
            energyType: EnergyType.Electricity,
            projectedEnergyGraphData: [],
            hasElectricitySubscription: false,
            hasGasSubscription: false,
            hasColdSubscription: false,
            hasHeatSubscription: false
        };

        this.state = state;

        this.energyService = new EnergyService();

        this.initializeVenueAsync = this.initializeVenueAsync.bind(this);
        this.setGasUnitTypeSelection = this.setGasUnitTypeSelection.bind(this);
        this.setEnergyUnitTypeSelection = this.setEnergyUnitTypeSelection.bind(this);
        this.setHeatUnitTypeSelection = this.setHeatUnitTypeSelection.bind(this);
        this.setColdUnitTypeSelection = this.setColdUnitTypeSelection.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.initializeEnergyDataAsync = this.initializeEnergyDataAsync.bind(this);
        this.renderDatePicker = this.renderDatePicker.bind(this);
        this.getCsvFileName = this.getCsvFileName.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.on(AppEvents.LanguageChanged, this.initializeEnergyDataAsync);
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeVenueAsync();
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenueAsync);
        AppEventHub.off(AppEvents.LanguageChanged, this.initializeEnergyDataAsync);
    }

    private setSubscriptionsForVenue(): void {
        const activeVenue = VenueProvider.getActiveVenue();
        if (activeVenue) {
            const hasElectricity = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Electricity"]);
            const hasGas = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Gas"]);
            const hasHeat = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Heat"]);
            const hasCold = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Cold"]);

            this.setState({
                hasElectricitySubscription: hasElectricity,
                hasGasSubscription: hasGas,
                hasHeatSubscription: hasHeat,
                hasColdSubscription: hasCold
            }, () => this.initializeDataTypes());
        }
    }

    private get gasSelected(): boolean {
        return this.state.energyType === EnergyType.Gas;
    }

    private get energySelected(): boolean {
        return this.state.energyType === EnergyType.Electricity;
    }

    private get heatSelected(): boolean {
        return this.state.energyType === EnergyType.Heat;
    }

    private get coldSelected(): boolean {
        return this.state.energyType === EnergyType.Cold;
    }

    private async initializeVenueAsync(): Promise<void> {
        this.setState({ loading: true });
        const activeVenue = VenueProvider.getActiveVenue();

        this.setSubscriptionsForVenue();
        this.setState({
            venue: activeVenue
        }, async () => { await this.initializeEnergyDataAsync(); });
    }

    private initializeDataTypes(): void {
        const initialDataType = this.determineInitialDataType();
        const initialUnitType = this.determineInitialUnitType();

        this.setState({
            energyType: initialDataType,
            selectedUnitType: initialUnitType
        });
    }

    private determineInitialDataType(): EnergyType {
        if (this.state.hasElectricitySubscription) {
            return EnergyType.Electricity;
        }

        if (this.state.hasGasSubscription) {
            return EnergyType.Gas;
        }

        if (this.state.hasHeatSubscription) {
            return EnergyType.Heat;
        }

        return EnergyType.Cold;
    }

    private determineInitialUnitType(): IEnergyUnitType {
        if (this.state.hasElectricitySubscription) {
            return 'kWh';
        }

        if (this.state.hasGasSubscription) {
            return 'm3';
        }

        return 'GJ';
    }

    private onDateChange(timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date, triggerDataInitalization: boolean = true): void {
        this.setState({
            startDate: startDate,
            endDate: endDate,
            year: startDate.getFullYear(),
            projectedEnergyGraphData: [],
            newStartDate: startDate,
            newEndDate: endDate,
            newTimeUnitType: timeUnit
        }, async () => { if (triggerDataInitalization) { await this.initializeEnergyDataAsync(); } });
    }

    private async initializeEnergyDataAsync(): Promise<void> {
        this.setState({loading: true});
        const energyGraphData: IEnergyGraphData[] = [];

        if (this.energySelected) {
            const electricityData = await this.energyService.GetElectricUsageHolidaysAsync(this.state.year);

            electricityData.forEach((d, index) => {
                const sortedData = d.quarterlyValues.sort((a, b) => (a.localHour * 60 + a.localMinutes) - (b.localHour * 60 + b.localMinutes));

                const noData = sortedData.every(d => d.actualUsage === null);

                const holidayName = this.getHolidayNameByBackendName(d.holidayName);

                const graphData: IEnergyGraphData = {
                    label: holidayName + (noData ? " - " + LanguageProvider.getTranslation(translations.energy.general.nodataavailable) : ""),
                    color: ColorProvider.getNextHolidayColorForIndex(index),
                    data: sortedData.map(i => i.actualUsage),
                    noData: noData
                };

                energyGraphData.push(graphData);
            });
        }
        else if (this.gasSelected || this.heatSelected || this.coldSelected) {
            let hourlyData: IHolidayHourlyEnergy[] = [];

            if (this.gasSelected) {
                hourlyData = await this.energyService.GetGasUsageHolidaysAsync(this.state.year);
            }
            else if (this.heatSelected) {
                hourlyData = await this.energyService.GetHeatUsageHolidaysAsync(this.state.year);
            }
            else if (this.coldSelected) {
                hourlyData = await this.energyService.GetColdUsageHolidaysAsync(this.state.year);
            }

            hourlyData.forEach((d, index) => {
                const sortedData = d.hourValues.sort((a, b) => (a.localHour - b.localHour));
                const noData = sortedData.every(d => d.actualUsage === null);

                const holidayName = this.getHolidayNameByBackendName(d.holidayName);

                const graphData = {
                    label: holidayName + (noData ? " - " + LanguageProvider.getTranslation(translations.energy.general.nodataavailable) : ""),
                    color: ColorProvider.getNextHolidayColorForIndex(index),
                    data: sortedData.map(i => i.actualUsage),
                    noData: noData
                };
                energyGraphData.push(graphData);
            });
        }

        this.setState({
            projectedEnergyGraphData: energyGraphData,
            selectedUnitType: this.state.newUnitType,
            loading: false
        });
    }

    private getHolidayNameByBackendName(name: string): string {
        const holidayTranslationString = `dates.holidays.${name.toLowerCase().replace(/\s?\.?'?/g, '')}`;
        const holidayName = LanguageProvider.getTranslation(holidayTranslationString);
        return holidayName;
    }

    private async setGasUnitTypeSelection(): Promise<void> {
        if (this.gasSelected) {
            return;
        }

        this.setState({
            newUnitType: "m3",
            energyType: EnergyType.Gas,
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setEnergyUnitTypeSelection(): Promise<void> {
        if (this.energySelected) {
            return;
        }

        this.setState({
            newUnitType: "kWh",
            energyType: EnergyType.Electricity
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setHeatUnitTypeSelection(): Promise<void> {
        if (this.heatSelected) {
            return;
        }

        this.setState({
            newUnitType: "GJ",
            energyType: EnergyType.Heat
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setColdUnitTypeSelection(): Promise<void> {
        if (this.coldSelected) {
            return;
        }

        this.setState({
            newUnitType: "GJ",
            energyType: EnergyType.Cold
        }, async () => await this.initializeEnergyDataAsync());
    }

    public renderDatePicker(): JSX.Element {
        return (
            <DatePickerDeluxe
                defaultDateUnit={'year'}
                onDateChange={this.onDateChange}
                newStartDate={this.state.newStartDate}
                newEndDate={this.state.newEndDate}
                newTimeUnitType={this.state.newTimeUnitType}
                disableDayPicker={true}
                disableWeekPicker={true}
                disableMonthPicker={true}
                compactView={true}
                draggableOffset={{ x: 50, y: -220 }}
                minimalisticCompactButton={true}
            />
        );
    }

    private getCsvFileName(): string {
        return `${this.state.venue && this.state.venue.name}-${LanguageProvider.getTranslation(translations.dates.holidays.title)} ${this.state.startDate.getFullYear()}`.replaceAll(' ', '_');
    }

    public render(): JSX.Element {
        return (
            <EnergyManagementPage>
                {<PageHeader pageName="energymanagement-reports" />}
                <div id="venue-holiday-consumption" className="main-content" ref={this.graphHtmlReference}>
                    <div className="main-content-header">
                        <div className="row input-selection">
                            <div className="col-sm-10">
                                <h1>
                                    {this.state.venue && this.state.venue.name}
                                </h1>
                            </div>
                            <div className="col-sm-2 d-flex selection-icons">
                                <div className="ml-auto selection-container">
                                    {this.state.hasElectricitySubscription && <div onClick={this.setEnergyUnitTypeSelection} className={"selection-item " + (this.energySelected ? "selected" : "")}>
                                        <img src={this.energySelected ? EnergySelectedIcon : EnergyIcon} />
                                    </div>}
                                    {this.state.hasGasSubscription && <div onClick={this.setGasUnitTypeSelection} className={"selection-item " + (this.gasSelected ? "selected" : "")}>
                                        <img src={this.gasSelected ? GasSelectedIcon : GasIcon} />
                                    </div>}
                                    {this.state.hasHeatSubscription && <div onClick={this.setHeatUnitTypeSelection} className={"selection-item " + (this.heatSelected ? "selected" : "")}>
                                        <img src={this.heatSelected ? HeatSelectedIcon : HeatIcon} />
                                    </div>}
                                    {this.state.hasColdSubscription && <div onClick={this.setColdUnitTypeSelection} className={"selection-item " + (this.coldSelected ? "selected" : "")}>
                                        <img src={this.coldSelected ? ColdSelectedIcon : ColdIcon} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <EnergyGraphHeader
                                    selectedTimeUnit={'year'}
                                    startDate={this.state.startDate}
                                    onDateChange={this.onDateChange}
                                    title={`${LanguageProvider.getTranslation(translations.dates.holidays.title)} ${this.state.startDate.getFullYear()}`}
                                    extraRenderAfterTitle={this.renderDatePicker}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <EnergyGraph
                                    width={20}
                                    height={window.innerHeight > this.viewportHeightThresholdForIncreasedGraphHeight ? 7 : 6}
                                    timeRepresentation={'day'}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    unitType={EnergyUnitType[this.state.selectedUnitType]}
                                    graphType={'line'}
                                    data={this.state.projectedEnergyGraphData}
                                    showLegend={true}
                                    loading={this.state.loading}
                                    exportOptions={{
                                        fileName: this.getCsvFileName(),
                                        enableYearOnlyDailyTimestamp: true
                                    }}
                                    graphHtmlReference={this.graphHtmlReference}
                                />
                                <span className="pl-5 png-hidden">
                                    {LanguageProvider.getTranslation(translations.energy.general.legendexplanation)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </EnergyManagementPage>
        );
    }
}

export default withTranslation()(withTelemetry(HolidayConsumptionPage, "HolidayConsumptionPage"));