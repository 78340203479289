import DashboardService from "./dashboardService";
import VenueProvider from "../providers/venueProvider";
import ISanitaryThreshold from "../interfaces/ISanitaryThreshold";

export default class SanitaryService {
    private readonly dashboardService: DashboardService;

    public constructor() {
        this.dashboardService = new DashboardService();
    }

    public async getAllSanitaryThresholds(skipCache: boolean = false): Promise<ISanitaryThreshold[]> {
        const venue = VenueProvider.getActiveVenue();
        if(venue === undefined){
            throw new Error("Error getting venue settings: No venue found in venueprovider!");
        }
        return this.dashboardService.get<ISanitaryThreshold[]>(`sanitary/customer/${venue.customerId}/venue/${venue.id}/thresholds`, skipCache);
    }

    public async batchUpsertVenueSettings(sanitaryThresholds: ISanitaryThreshold[]): Promise<Response> {
        const venue = VenueProvider.getActiveVenue();
        if(venue === undefined){
            throw new Error("Error batch upserting venue settings: No venue found in venueprovider!");
        }
        return this.dashboardService.put(`sanitary/customer/${venue.customerId}/venue/${venue.id}/thresholds`, sanitaryThresholds);
    }
}