import * as React from "react";
import { Component } from "react";
import ICurrentComfortValuesProps from "./interfaces/ICurrentComfortOverviewProps";
import ICurrentComfortValuesState from "./interfaces/ICurrentComfortOverviewState";
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

import './currentDataOverview.scss';

import Tile from '@/components/tile/tile';
import DashboardService from "@/services/dashboardService";
import LanguageProvider from "@/providers/languageProvider";
import IconType from "@/components/tile/enums/iconType";
import Unit from "@/components/tile/enums/unit";
import CenteredPageLoader from "@/components/loaders/centeredPageLoader";

class CurrentComfortOverview extends Component<ICurrentComfortValuesProps, ICurrentComfortValuesState> {
    private readonly dashboardService: DashboardService;
    public constructor(props: ICurrentComfortValuesProps) {
        super(props);

        this.dashboardService = new DashboardService();

        const state: ICurrentComfortValuesState = {
            comfortData: [],
            loading: true,
        };

        this.state = state;
        this.getComfortValuesAsync = this.getComfortValuesAsync.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        await this.getComfortValuesAsync();
    }

    public async componentDidUpdate(previousProps: ICurrentComfortValuesProps, previousState: ICurrentComfortValuesState): Promise<void> {
        if (previousProps.itemsToLoad === this.props.itemsToLoad) {
            return;
        }

        await this.getComfortValuesAsync();
    }

    private async getComfortValuesAsync(): Promise<void> {
        this.setState({
            loading: true,
        });

        const currentlyLoaded = this.state.comfortData.length;

        let comfortData;
        try {
            comfortData = await this.dashboardService.getRecentComfortData(this.props.itemsToLoad);
        } catch (error) {
            console.log('Encountered error while fetching Comfort values: ', error);
        }

        if (comfortData.length > 0 && comfortData.length === currentlyLoaded) {
            NotificationManager.error(LanguageProvider.getTranslation("pages.home.comfort.nomoreresultstoshow"));
            this.setState({
                loading: false,
            });
        }
        else if (comfortData.length > 0) {
            this.setState({
                comfortData,
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    public render(): JSX.Element {
        const noDataMessage = LanguageProvider.getTranslation("pages.home.comfort.norecentdata");

        return (
            <>
                {this.state.loading && <CenteredPageLoader loading={this.state.loading} />}
                {!this.state.loading && this.state.comfortData.length === 0 && <div>{noDataMessage}</div>}
                {
                    this.state.comfortData.length > 0 && this.state.comfortData.map(
                        comfort =>
                            <div key={comfort.assetName}>
                                <h3>
                                    {`${comfort.venueName} - ${comfort.assetName}`}
                                </h3>
                                <div className="current-overview-padding comfort-timestamp">
                                    {moment(comfort.mostRecentUpdate).format('DD-MM-YY HH:mm')}
                                </div>
                                <div className="row mb-4">
                                    <Tile icon={IconType.temperature} value={comfort.temperature} unit={Unit.celsius} subtitle={LanguageProvider.getTranslation("pages.home.comfort.temperature")} totalNumberOfTileColumns={3} />
                                    <Tile icon={IconType.humidity} value={comfort.humidity} unit={Unit.percentage} subtitle={LanguageProvider.getTranslation("pages.home.comfort.humidity")} totalNumberOfTileColumns={3} />
                                    <Tile icon={IconType.co2} value={comfort.carbonDioxide} unit={Unit.ppm} subtitle="CO2" totalNumberOfTileColumns={3} />
                                </div>
                            </div>
                    )
                }
            </>
        );
    }

}
export default CurrentComfortOverview;