import { Component } from "react";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/nl';
import Select from 'react-select';

import ISelectDropdownField from "./interfaces/ISelectDropdownField";
import LanguageProvider from "@/providers/languageProvider";
import SelectBoxUtils from "@/utils/selectBoxUtils";

import './filterMenu.scss';

interface IFilterMenuProps {
    handleStartDateChange?: ((date: Date) => Promise<void>) | ((date: Date) => void);
    handleEndDateChange?: ((date: Date) => Promise<void>) | ((date: Date) => void);
    startDate?: Date;
    endDate?: Date;
    dropdownField?: ISelectDropdownField[];
}

export class FilterMenu extends Component<IFilterMenuProps> {
    public constructor(props: IFilterMenuProps) {
        super(props);
        this.renderSelectBox = this.renderSelectBox.bind(this);
        this.renderSelectBoxes = this.renderSelectBoxes.bind(this);
    }

    private renderSelectBox(selectItem: ISelectDropdownField): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation("filtermenu.nooptions");

        if (!this.props.dropdownField) {
            return <div />;
        }

        const styles = SelectBoxUtils.getDefaultSelectStyles(40);

        if (selectItem.cannotBeCleared) {
            styles.clearIndicator = (provided: any, state: any): any => ({
                ...provided,
                display: "none"
            });
        }

        return (
            <div
                className="col-sm-2 pl-0 pr-2 select-box"
                key={selectItem.dropdownName}
                data-test={`filter-menu-${selectItem.key}`}>
                <Select
                    defaultValue={selectItem.defaultValue}
                    onChange={selectItem.onSelect}
                    options={selectItem.dropdownList}
                    noOptionsMessage={noOptionsMessage}
                    placeholder={selectItem.dropdownName}
                    key={selectItem.key}
                    styles={styles}
                    isClearable={true}
                /></div>
        );
    }

    private renderSelectBoxes(): JSX.Element[] {
        const result: JSX.Element[] = [];
        if (!this.props.dropdownField) {
            return result;
        }

        for (let i = 0; i < this.props.dropdownField.length; i++) {
            if (!this.props.dropdownField[i].keepOptionsOrder) {
                this.props.dropdownField[i].dropdownList.sort((a, b) => {
                    if (a.label.toLowerCase() < b.label.toLowerCase()) { return -1; }
                    if (a.label.toLowerCase() > b.label.toLowerCase()) { return 1; }
                    return 0;
                });
            }
            result.push(this.renderSelectBox(this.props.dropdownField[i]));
        }
        return result;
    }

    public render(): JSX.Element {
        return (
            <div className="col-sm-12 row">

                {this.props.handleStartDateChange &&
                    <div className="mr-2">
                        <DatePicker
                            id="start-date"
                            selected={this.props.startDate}
                            onChange={this.props.handleStartDateChange}
                            maxDate={this.props.endDate ? this.props.endDate : moment().toDate()}
                            placeholderText={LanguageProvider.getTranslation("datepicker.startdate")}
                            className="filter-datepicker clickable"
                            todayButton={LanguageProvider.getTranslation("datepicker.today")}
                            data-test="filter-menu-date-start"
                        />
                    </div>}

                {this.props.handleEndDateChange &&
                    <div className="mr-2">
                        <DatePicker
                            id="end-date"
                            selected={this.props.endDate}
                            onChange={this.props.handleEndDateChange}
                            minDate={this.props.startDate}
                            maxDate={moment().toDate()}
                            placeholderText={LanguageProvider.getTranslation("datepicker.enddate")}
                            className="filter-datepicker clickable"
                            todayButton={LanguageProvider.getTranslation("datepicker.today")}
                            data-test="filter-menu-date-end"
                        />
                    </div>}
                {this.renderSelectBoxes()}
            </div>
        );
    }
}