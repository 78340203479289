import * as React from "react";

import "./heatmapLegend.scss";
import IHeatmapLegendProps from "./interfaces/IHeatmapLegendProps";
import LanguageProvider from "@/providers/languageProvider";
import ColorUtils from "@/utils/colorUtils";

export default class HeatmapLegend extends React.Component<IHeatmapLegendProps> {
    public constructor(props: IHeatmapLegendProps) {
        super(props);
    }
    public render(): JSX.Element {
        return (
            <div id="heat-legend-container">
                <div className="d-flex justify-content-between">
                    {LanguageProvider.getTranslation("pages.heatmap.occupation_percentage")}
                    <button type="button" id="toggle-heatmap-slider-button" className="threshold-settings-button" data-toggle="modal" data-target="#threshold-settings-modal" />
                </div>

                <div className="heat-legend-wrapper d-flex flex-row">
                    {Array(101).fill(null).map((v, i) => {
                        const percentageColor = ColorUtils.percentageToGradientColor(i, true);
                        const gradientRenderer =
                            <div className={"gradient-part " + (i === this.props.thresholdLowerbound || i === this.props.thresholdUpperbound ? 'threshold' : '')}
                                style={{ backgroundColor: percentageColor }}
                                key={i} />;

                        if (i === 95) {
                            return <div key={percentageColor}>
                                <div className="percentage percentage100">100</div>
                                {gradientRenderer}
                            </div>;
                        }
                        else if (i % 20 === 0 && i !== 100) {
                            return <div key={percentageColor}>
                                <div className="percentage">{i}</div>
                                {gradientRenderer}
                            </div>;
                        }
                        else {
                            return gradientRenderer;
                        }
                    })}
                </div>
            </div>
        );
    }
}