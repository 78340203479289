export default class TranslationMapperGenerator {
    public static GenerateMapper(translationFile: any): string[] {

        const getKeys = (translationFileObject: any): string[] => {
            const disectObject = (objectToDisect: any, path?: string): void => {
                Object.keys(objectToDisect).forEach(key => {
                    const prop = `${path ? path : ""}${key}`;

                    if (typeof objectToDisect[key] === 'object') {
                        disectObject(objectToDisect[key],  `${prop}.`);
                    }
                    else {
                        objectToDisect[key] = prop;
                    }
                });
            };

            disectObject(translationFileObject);
            return translationFileObject;
        };

        const keys = getKeys(translationFile);

        return keys;
    }
}