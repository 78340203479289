import * as React from "react";

import { toPng } from 'html-to-image';
import * as download from 'downloadjs';
import IEnergyGraphPngButtonProps from "./interfaces/IEnergyGraphPngButtonProps";

import { withTranslation } from "react-i18next";
import DownloadIcon from "@/images/Download.svg";

class EnergyGraphPngButton extends React.Component<IEnergyGraphPngButtonProps> {
    public constructor(props: IEnergyGraphPngButtonProps) {
        super(props);

        this.downloadGraphAsPng = this.downloadGraphAsPng.bind(this);
    }

    private async downloadGraphAsPng(): Promise<void> {
        if (!this.props.divRef.current) {
            return;
        }

        const clientHeight = this.props.divRef.current.clientHeight + (this.props.heightCompensation ?? 0);
        const clientWidth = this.props.divRef.current.clientWidth;

        const targetWidth = 1920;
        const targetHeigth = (targetWidth / clientWidth) * clientHeight;

        const dataUrl = await toPng(this.props.divRef.current,
            {
                filter: this.pngElementFilter,
                // For some reasone html-to-image doesn't correctly determine source height on some pages, causing part of source div to get left out of snapshot.
                // Heightcompensation props allows for forcing higher than determined height to be rendered onto snapshot.
                height: this.props.heightCompensation ? clientHeight + this.props.heightCompensation : undefined,
                canvasWidth: targetWidth,
                canvasHeight: targetHeigth
            });
        const fileName = this.props.fileName ?? 'graph.png';
        download(dataUrl, fileName.endsWith('.png') ? fileName : fileName + '.png');
    }

    private pngElementFilter(node: HTMLElement): boolean {
        return !node.classList?.contains("png-hidden");
    }

    public render(): JSX.Element {
        return (
            <div className="png-button png-hidden">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.downloadGraphAsPng}>
                    <span>PNG</span>
                    <img className="pl-1" src={DownloadIcon} />
                </button>
            </div >
        );
    }
}

export default withTranslation()(EnergyGraphPngButton);