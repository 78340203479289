import { ChangeEvent, Component, KeyboardEvent } from "react";
import IEmailInputProps from "./interfaces/IEmailInputProps";
import IEmailInputState from "./interfaces/IEmailInputState";

import './emailInput.scss';
import LanguageProvider from "../../providers/languageProvider";
import translations from "../../translations/mapper";

class EmailInput extends Component<IEmailInputProps, IEmailInputState> {

    public constructor(props: IEmailInputProps) {
        super(props);

        const addresses = this.props.defaultValues?.filter(e => this.validateMailAddress(e)) ?? [];
        const state: IEmailInputState = {
            value: "",
            items: addresses
        };

        this.state = state;
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.validateInput = this.validateInput.bind(this);
        this.emailIsEntered = this.emailIsEntered.bind(this);

        this.onEmailChange = this.onEmailChange.bind(this);
        this.tryAddCurrentValue = this.tryAddCurrentValue.bind(this);
    }

    private validateMailAddress(mailAddress: string): boolean {
        return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/gi.test(mailAddress);
    }

    public componentDidUpdate(prevProps: IEmailInputProps): void {
        if (!this.props.defaultValues) {
            return;
        }

        let foundDifference = false;

        if (!prevProps.defaultValues) {
            foundDifference = true;
        }
        else {
            for (let i = 0; i < this.props.defaultValues.length; i++) {
                if (prevProps.defaultValues[i] !== this.props.defaultValues[i]) {
                    foundDifference = true;
                    break;
                }
            }
        }

        if (!foundDifference) {
            return;
        }

        const addresses = this.props.defaultValues.filter(e => this.validateMailAddress(e));
        this.setState({
            items: addresses
        });
    }

    private onKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
        if (!["Enter", "Tab", ","].includes(event.key)) {
            return;
        }

        event.preventDefault();
        this.tryAddCurrentValue();
    }

    private tryAddCurrentValue(): void {
        const value = this.state.value.trim();

        if (value && this.validateInput(value)) {
            this.setState({
                items: [...this.state.items, this.state.value],
                value: ""
            }, this.onEmailChange);
        }
    }

    private onChange(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({
            value: event.target.value,
            error: undefined
        });
    }

    private onDelete(email: string): void {
        if (this.props.disabled) {
            return;
        }

        this.setState({
            items: this.state.items.filter(i => i !== email)
        }, this.onEmailChange);
    }

    private validateInput(email: string): boolean {
        let error: string | undefined = undefined;
        if (!this.validateMailAddress(email)) {
            error = `'${email}' ${LanguageProvider.getTranslation(translations.emailinput.invalidemail)}`;
        }
        else if (this.emailIsEntered(email)) {
            error = `'${email}' ${LanguageProvider.getTranslation(translations.emailinput.alreadyadded)}`;
        }

        if (error) {
            this.setState({
                error: error
            });

            return false;
        }

        return true;
    }

    private emailIsEntered(email: string): boolean {
        return this.state.items.includes(email);
    }

    private onEmailChange(): void {
        if (this.props.onEmailChange) {
            this.props.onEmailChange(this.state.items);
        }
    }

    public render(): JSX.Element {
        return (
            <div className="email-input">

                <div className="d-flex align-items-center">
                    <input
                        data-test={this.props.dataTestAttribute}
                        type="email"
                        className={`form-control ${this.state.error ? 'is-invalid' : ''}`}
                        value={this.state.value}
                        onKeyDown={this.onKeyDown}
                        onChange={this.onChange}
                        disabled={this.props.disabled}
                    />
                    <div
                        className={`btn-add ml-2 ${this.props.disabled ? 'disabled' : ''}`}
                        onClick={this.tryAddCurrentValue}>
                        +
                    </div>
                </div>

                {this.state.error && <p className="text-danger mt-1">
                    {this.state.error}
                </p>}

                {
                    this.state.items.length > 0 && <div className="mt-2">
                        {
                            this.state.items.map((item, index) => (
                                <div
                                    data-test={item}
                                    key={index}
                                    className={`email-chip ${this.props.disabled ? 'disabled' : ''}`}>
                                    {item}
                                    <span
                                        className={`ml-2 ${this.props.disabled ? '' : 'clickable'}`}
                                        onClick={(): void => this.onDelete(item)}>
                                        &times;
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        );
    }
}

export default EmailInput;