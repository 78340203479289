import { Component } from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import Select from 'react-select';

import LanguageProvider from '@/providers/languageProvider';
import SelectBoxUtils from '@/utils/selectBoxUtils';
import translations from '@/translations/mapper';
import IFloorSelectValue from '@/interfaces/IFloorSelectValue';
import CoreSpace from "@/models/coreSpace";

interface IFloorSelectProps extends WithTranslation {
    floors: CoreSpace[],
    setActiveFloor?: ((selectedItem: IFloorSelectValue | null) => Promise<void>) | ((selectedItem: IFloorSelectValue | null) => void),
    isDisabled?: boolean,
    selectedFloor: IFloorSelectValue | null,
    dataTest?: string
}

class FloorSelect extends Component<IFloorSelectProps> {
    public constructor(props: IFloorSelectProps) {
        super(props);
    }

    public render(): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation(translations.buttons.dropdowns.nooptions);
        const wholeVenueLabel = LanguageProvider.getTranslation(translations.select.wholevenue);
        const floorSelectionOptions: IFloorSelectValue[] = [];
        floorSelectionOptions.push({
            value: "",
            label: wholeVenueLabel,
            isDefault: true
        });

        this.props.floors.sort((a, b) => parseInt(a.floorLevel) - parseInt(b.floorLevel)).map(a => ({
            value: a.id,
            label: a.name,
            floorLevel: parseInt(a.floorLevel, 10)
        })).forEach(option => floorSelectionOptions.push(option));

        return (
            <div className="floor-select" data-test={this.props.dataTest}>
                <Select
                    value={this.props.selectedFloor ?? floorSelectionOptions[0]}
                    onChange={this.props.setActiveFloor}
                    options={floorSelectionOptions}
                    isDisabled={this.props.isDisabled}
                    isClearable={true}
                    placeholder={`${LanguageProvider.getTranslation(translations.select.wholevenue)}`}
                    noOptionsMessage={noOptionsMessage}
                    styles={SelectBoxUtils.getSelectStylesWithDefaultOption(40)}
                    formatOptionLabel={SelectBoxUtils.getDefaultOptionFormatter()}
                />
            </div>
        );
    }
}

export default withTranslation()(FloorSelect);