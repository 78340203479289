import { ApplicationConfig } from './../config';
import UserContextService from '../services/userContextService';
import UserContext from '../models/userContext';
import IUserContext from '../interfaces/IUserContext';

export default class UserContextProvider {
    private static readonly sessionStorageUserContext: string = `bs.userContext.${ApplicationConfig.environment}`;
    private static readonly userContextService: UserContextService = new UserContextService();

    public static async getUserContextAsync(): Promise<UserContext> {
        const sessionStorageJson = window.sessionStorage.getItem(UserContextProvider.sessionStorageUserContext);

        if (sessionStorageJson) {
            const storedUserContext: IUserContext = JSON.parse(sessionStorageJson);
            return new UserContext(storedUserContext);
        } else {
            const userContext = await UserContextProvider.userContextService.getUserContextAsync();

            const exportable = userContext.export();
            window.sessionStorage.setItem(UserContextProvider.sessionStorageUserContext, JSON.stringify(exportable));

            return userContext;
        }
    }
}