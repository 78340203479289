import 'moment/locale/nl';
import './energyReportStyling.scss';

import moment from 'moment';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import Select, { OnChangeValue } from 'react-select';

import PageHeader from "@/components/header/pageHeader";
import EnergyService from '@/services/energyService';
import CoreSpaceTypes from '@/enums/coreSpaceTypes';
import BarSelectedIcon from '@/images/Bar_selected.svg';
import BarIcon from '@/images/Bar_unselected.svg';
import EnergySelectedIcon from '@/images/Energy_selected.svg';
import EnergyIcon from '@/images/Energy_unselected.svg';
import GasSelectedIcon from '@/images/Gas_selected.svg';
import GasIcon from '@/images/Gas_unselected.svg';
import ColdSelectedIcon from '@/images/Cold_selected.svg';
import ColdIcon from '@/images/Cold_unselected.svg';
import HeatSelectedIcon from '@/images/Heat_selected.svg';
import HeatIcon from '@/images/Heat_unselected.svg';
import LineSelectedIcon from '@/images/Line_selected.svg';
import LineIcon from '@/images/Line_unselected.svg';
import ICompensatedEnergieMissieValue from '@/interfaces/energy/ICompensatedEnergieMissieValue';
import { IDatePickerTimeUnit } from '@/interfaces/IDatePickerTimeUnit';
import IReactSelectValue from '@/interfaces/IReactSelectValue';
import ColorProvider from '@/providers/colorProvider';
import LanguageProvider from '@/providers/languageProvider';
import VenueProvider from '@/providers/venueProvider';
import CoreSpaceService from '@/services/coreSpaceService';
import { withTelemetry } from '@/services/telemetryService';
import AppEventHub, { AppEvents } from '@/utils/appEventHub';
import SelectBoxUtils from '@/utils/selectBoxUtils';
import IGraphSelectValue from '../../../realestateutilization/interfaces/IGraphSelectValue';
import DatePickerDeluxe from '../../components/datePicker/datePickerDeluxe';
import EnergieMissieGraph from '../../components/energieMissieGraph';
import EnergyGraph from '../../components/energyGraph';
import EnergyGraphHeader from '../../components/energyGraphHeader';
import EnergyUnitType from '../../components/enums/energyUnitType';
import EnergyNavigationStore from '../../utils/energyNavigationStore';
import IEnergyBaselinePageProps from './interfaces/IEnergyBaselinePageProps';
import IEnergyBaselinePageState from './interfaces/IEnergyBaselinePageState';
import EnergyType from '../../components/enums/energyType';
import { IEnergyUnitType } from '../../components/interfaces/IEnergyUnitType';
import EnergyManagementPage from '../../energyManagementPage';
import SubscriptionValidationService from '@/services/subscriptionValidationService';
import INormalisedEnergieMissieValue from '@/interfaces/energy/INormalisedEnergieMissieValue';
import NormalisedEnergieMissieGraph from "../../components/normalisedEnergieMissieGraph";
import translations from 'translations/mapper';
import IHourlyEnergieMissieValue from 'interfaces/energy/IHourlyEnergieMissieValue';
import IQuarterlyEnergieMissieValue from 'interfaces/energy/IQuarterlyEnergieMissieValue';
import IGroupOrMeterSelectValue from '../interfaces/IGroupOrMeterSelectValue';
import Colors from '@/styles/colors';

class EnergyBaselinePage extends React.Component<IEnergyBaselinePageProps, IEnergyBaselinePageState>{
    private readonly graphHtmlReference: React.RefObject<HTMLDivElement>;
    private viewportHeightThresholdForIncreasedGraphHeight: number = 965;

    private readonly energyService: EnergyService;
    private readonly coreSpaceService: CoreSpaceService;
    private subscriptionValidationService: SubscriptionValidationService;

    private disableRendering: boolean;

    public constructor(props: IEnergyBaselinePageProps) {
        super(props);

        this.graphHtmlReference = React.createRef();

        const activeVenue = VenueProvider.getActiveVenue();
        const state: IEnergyBaselinePageState = {
            loading: true,
            venue: activeVenue,
            selectedAssets: [],
            assetSelectionOptions: [],
            selectedTimeUnit: 'year',
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate(),
            selectedUnitType: "kWh",
            newUnitType: "kWh",
            energyType: EnergyType.Electricity,
            selectedGraphType: "bar",
            compensatedGraphData: [],
            normalisedGraphData: [],
            selectedGroupType: this.defaultGroupOrMeterSelectionOption,
            electricGroupOrMeterOptions: [this.defaultGroupOrMeterSelectionOption],
            gasGroupOrMeterOptions: [this.defaultGroupOrMeterSelectionOption],
            hourlyEnergyMissieData: [],
            quarterlyEnergyMissieData: [],
            projectedEnergyGraphData: [],
            hourlyTemperatureData: [],
            dailyTemperatureData: [],
            hasElectricitySubscription: false,
            hasGasSubscription: false,
            hasColdSubscription: false,
            hasHeatSubscription: false,
            hasMultipleGasMeters: false
        };

        this.state = state;

        this.coreSpaceService = new CoreSpaceService();
        this.energyService = new EnergyService();

        this.setSelectedAsset = this.setSelectedAsset.bind(this);
        this.setGasUnitTypeSelection = this.setGasUnitTypeSelection.bind(this);
        this.setEnergyUnitTypeSelection = this.setEnergyUnitTypeSelection.bind(this);
        this.setHeatUnitTypeSelection = this.setHeatUnitTypeSelection.bind(this);
        this.setColdUnitTypeSelection = this.setColdUnitTypeSelection.bind(this);
        this.setBarGraphSelection = this.setBarGraphSelection.bind(this);
        this.setLineGraphSelection = this.setLineGraphSelection.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onGraphElementClickAsync = this.onGraphElementClickAsync.bind(this);
        this.setGroupSelectionWrapper = this.setGroupSelectionWrapper.bind(this);
        this.onTimeUnitClickAsync = this.onTimeUnitClickAsync.bind(this);

        this.initializeEnergyDataAsync = this.initializeEnergyDataAsync.bind(this);
        this.initializeVenueAsync = this.initializeVenueAsync.bind(this);
        this.initializeElectricGroupsAndMetersAsync = this.initializeElectricGroupsAndMetersAsync.bind(this);
        this.initializeElectricAssetSelectionAsync = this.initializeElectricAssetSelectionAsync.bind(this);

        this.setGroupOrMeterSelectionAsync = this.setGroupOrMeterSelectionAsync.bind(this);
        this.renderDatePickerAndSelectors = this.renderDatePickerAndSelectors.bind(this);

        this.getCompensatedEnergieMissieDataByVenueAsync = this.getCompensatedEnergieMissieDataByVenueAsync.bind(this);
        this.setElectricDataByAssetAsync = this.setElectricDataByAssetAsync.bind(this);
        this.renderGraphHeaderTitle = this.renderGraphHeaderTitle.bind(this);
        this.handlePageNavigation = this.handlePageNavigation.bind(this);
        this.getExportFileName = this.getExportFileName.bind(this);

        AppEventHub.on(AppEvents.BuildingSelected, this.initializeVenueAsync);
    }

    private get defaultGroupOrMeterSelectionOption(): IGroupOrMeterSelectValue {
        return {
            value: "",
            label: `${LanguageProvider.getTranslation("pages.energy.tabs.consumption.allassets")}`,
            isDefault: true,
            isGroup: false,
            isMeter: false
        };
    }

    public async componentDidMount(): Promise<void> {
        this.subscriptionValidationService = await SubscriptionValidationService.GetInstanceAsync();
        await this.initializeVenueAsync();
    }

    public shouldComponentUpdate(): boolean {
        return !this.disableRendering;
    }

    public componentWillUnmount(): void {
        // Remove our subscription(s) to the eventhub, so it won't complain about reaching the limit in event emitters.
        AppEventHub.off(AppEvents.BuildingSelected, this.initializeVenueAsync);
    }

    private get gasSelected(): boolean {
        return this.state.energyType === EnergyType.Gas;
    }

    private get electricitySelected(): boolean {
        return this.state.energyType === EnergyType.Electricity;
    }

    private get heatSelected(): boolean {
        return this.state.energyType === EnergyType.Heat;
    }

    private get coldSelected(): boolean {
        return this.state.energyType === EnergyType.Cold;
    }

    private get barGraphSelected(): boolean {
        return this.state.selectedGraphType === "bar";
    }

    private get lineGraphSelected(): boolean {
        return this.state.selectedGraphType === "line";
    }

    private get isIndividualMainMeterSelected(): boolean {
        return !!this.state.selectedGroupType?.isMeter;
    }

    private get isMeterOrGroupSelectionEnabled(): boolean {
        return this.electricitySelected || (this.gasSelected && this.state.hasMultipleGasMeters);
    }

    private get individualEnergyRenderingByGroupEnabled(): boolean {
        const individualAssetsAreSelected = this.state.selectedAssets.length > 0;
        const groupIsSelected = !!this.state.selectedGroupType?.isGroup;

        return individualAssetsAreSelected || groupIsSelected;
    }

    private setSubscriptionsForVenue(): void {
        const activeVenue = VenueProvider.getActiveVenue();
        if (activeVenue) {
            const hasElectricity = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Electricity"]);
            const hasGas = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Gas"]);
            const hasHeat = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Heat"]);
            const hasCold = this.subscriptionValidationService.venueHasAnyApplicableSubscription(activeVenue, ["Cold"]);

            this.setState({
                hasElectricitySubscription: hasElectricity,
                hasGasSubscription: hasGas,
                hasHeatSubscription: hasHeat,
                hasColdSubscription: hasCold
            },
                () => this.initializeDataTypes());
        }
    }

    private async initializeVenueAsync(): Promise<void> {
        this.setState({ loading: true });
        const activeVenue = VenueProvider.getActiveVenue();

        this.setSubscriptionsForVenue();
        this.setState({
            selectedAssets: [],
            assetSelectionOptions: [],
            compensatedGraphData: [],
            dailyTemperatureData: [],
            hourlyTemperatureData: [],
            venue: activeVenue
        }, async () => {
            if (!activeVenue) {
                return;
            }

            await Promise.all([
                this.initializeGasMetersAsync(),
                this.initializeElectricGroupsAndMetersAsync(),
                this.initializeElectricAssetSelectionAsync(activeVenue.id)]
            );

            const energyDataInitializeTriggered = await this.handlePageNavigation();

            if (!energyDataInitializeTriggered) {
                await this.initializeEnergyDataAsync();
            }
        });
    }

    private initializeDataTypes(): void {
        const initialDataType = this.determineInitialDataType();
        const initialUnitType = this.determineInitialUnitType();

        this.setState({
            energyType: initialDataType,
            selectedUnitType: initialUnitType,
            newUnitType: initialUnitType,
        });
    }

    private determineInitialDataType(): EnergyType {
        if (this.state.hasElectricitySubscription) {
            return EnergyType.Electricity;
        }

        if (this.state.hasGasSubscription) {
            return EnergyType.Gas;
        }

        if (this.state.hasHeatSubscription) {
            return EnergyType.Heat;
        }

        return EnergyType.Cold;
    }

    private determineInitialUnitType(): IEnergyUnitType {
        if (this.state.hasElectricitySubscription) {
            return 'kWh';
        }

        if (this.state.hasGasSubscription) {
            return 'm3';
        }

        return 'GJ';
    }

    private async handlePageNavigation(): Promise<boolean> {
        const navigationDetails = EnergyNavigationStore.getNavigationDetails();

        if (!navigationDetails) {
            return false;
        }

        this.disableRendering = true;

        if (navigationDetails.type === "group") {
            await this.setGroupOrMeterSelectionAsync({
                label: navigationDetails.name,
                value: navigationDetails.id
            },
                false);
        }
        else if (navigationDetails.type === "installation") {
            const selectedAsset = this.state.assetSelectionOptions.find((a) => a.value === navigationDetails.id);

            if (selectedAsset) {
                this.setState({
                    selectedAssets: [selectedAsset]
                });
            }
        }

        this.onDateChange("month",
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
            false);

        this.disableRendering = false;

        // Manually set state here and guarantee the initialize is called at this point (after the whole navigation settings have been processed).
        if (navigationDetails.unitType === "kWh") {
            this.setState({
                newUnitType: "kWh",
                energyType: EnergyType.Electricity
            }, async () => await this.initializeEnergyDataAsync());
        }
        else if (navigationDetails.unitType === "m3") {
            this.setState({
                newUnitType: "m3",
                energyType: EnergyType.Gas,
                selectedAssets: []
            }, async () => await this.initializeEnergyDataAsync());
        }
        else {
            this.setState({
                newUnitType: "GJ",
                energyType: EnergyType.Heat,
                selectedAssets: []
            }, async () => await this.initializeEnergyDataAsync());
        }

        EnergyNavigationStore.clearStore();
        return true;
    }

    private async initializeElectricAssetSelectionAsync(installationGroupId?: string): Promise<void> {
        if (!installationGroupId || !this.state.venue) {
            return;
        }

        const installations = this.state.venue.id !== installationGroupId
            ? await this.coreSpaceService.getChildSpacesByType(this.state.venue.id, installationGroupId, [CoreSpaceTypes.Installation])
            : await this.coreSpaceService.getSpacesForVenue(installationGroupId, [CoreSpaceTypes.Installation]);

        const options: IGraphSelectValue[] = installations.map((inst, i) => ({
            value: inst.id,
            label: inst.name,
            color: ColorProvider.getNextSemiRandomBackgroundColorForIndex(i),
            textColor: ColorProvider.getNextSemiRandomTextColorForIndex(i)
        }));

        this.sortSelectionOptionsAlphabetically(options);

        this.setState({
            assetSelectionOptions: options,
            selectedAssets: this.state.selectedAssets.filter(sa => options.find(o => o.value === sa.value) !== undefined)
        });
    }

    private sortSelectionOptionsAlphabetically(options: IReactSelectValue[]): void {
        options.sort((firstOption, secondOption) => {
            const firstLabel = firstOption.label.toUpperCase();
            const secondLabel = secondOption.label.toUpperCase();
            return firstLabel < secondLabel ? -1 : 1;
        });
    }

    private async initializeGasMetersAsync(): Promise<void> {
        const meters = await this.energyService.GetGasMainMetersAsync();

        let meterSelectionOptions: IGroupOrMeterSelectValue[] = [];

        if (meters.length > 1) {

            meterSelectionOptions = meters.map((m, i) => ({
                value: m.id,
                label: m.id,
                isDefault: false,
                isGroup: false,
                isMeter: true
            }));

            this.sortSelectionOptionsAlphabetically(meterSelectionOptions);
            const allOptions = [this.defaultGroupOrMeterSelectionOption].concat(meterSelectionOptions);

            this.setState({
                hasMultipleGasMeters: true,
                gasGroupOrMeterOptions: allOptions
            });
        }
    }

    private async initializeElectricGroupsAndMetersAsync(): Promise<void> {
        if (!this.state.venue) {
            return;
        }

        const [groups, meters] = await Promise.all([
            this.coreSpaceService.getSpacesForVenue(this.state.venue.id, [CoreSpaceTypes.InstallationGroup]),
            this.energyService.GetElectricMainMetersAsync()
        ]);

        let meterSelectionOptions: IGroupOrMeterSelectValue[] = [];

        if (meters.length > 1) {
            meterSelectionOptions = meters.map((m, i) => ({
                value: m.id,
                label: m.id,
                isDefault: false,
                isGroup: false,
                isMeter: true,
                displaySeparator: i === meters.length - 1,
            }));

            this.sortSelectionOptionsAlphabetically(meterSelectionOptions);
        }

        const groupSelectionOptions: IGroupOrMeterSelectValue[] = groups.map(g => ({
            value: g.id,
            label: g.name,
            isDefault: false,
            isGroup: true,
            isMeter: false
        }));

        this.sortSelectionOptionsAlphabetically(groupSelectionOptions);

        const allOptions = [this.defaultGroupOrMeterSelectionOption].concat(meterSelectionOptions).concat(groupSelectionOptions);

        this.setState({
            electricGroupOrMeterOptions: allOptions
        });
    }

    private setGroupSelectionWrapper(selectedItem: OnChangeValue<IGroupOrMeterSelectValue, false>): void {
        this.setGroupOrMeterSelectionAsync(selectedItem);
    }

    private async setGroupOrMeterSelectionAsync(selectedItem: OnChangeValue<IGroupOrMeterSelectValue, false>, triggerDataInitalization: boolean = true): Promise<void> {
        const item = selectedItem as IGroupOrMeterSelectValue | null;

        if (this.electricitySelected) {
            if (!item || item.isDefault || item.isMeter) {
                // Initialize for the entire venue, even when a specific meter is selected
                // there is no hierarchy between meters and individual devices on assets
                if (this.state.venue) {
                    await this.initializeElectricAssetSelectionAsync(this.state.venue.id);
                }
            }
            else if (item.isGroup) {
                await this.initializeElectricAssetSelectionAsync(item.value);
            }
        }

        this.setState({
            selectedGroupType: item
        }, async () => {
            if (triggerDataInitalization) {
                await this.initializeEnergyDataAsync();
            }
        });
    }

    private setSelectedAsset(selectedItems: OnChangeValue<IGraphSelectValue, true>): void {
        if (selectedItems === null || selectedItems === undefined) {
            return;
        }

        const items = selectedItems as IGraphSelectValue[];

        this.setState({
            selectedAssets: items
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setGasUnitTypeSelection(): Promise<void> {
        if (this.gasSelected) {
            return;
        }

        this.setState({
            newUnitType: "m3",
            energyType: EnergyType.Gas,
            selectedAssets: [],
            selectedGroupType: this.defaultGroupOrMeterSelectionOption,
            compensatedGraphData: [],
            projectedEnergyGraphData: []
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setEnergyUnitTypeSelection(): Promise<void> {
        if (this.electricitySelected) {
            return;
        }

        this.setState({
            newUnitType: "kWh",
            energyType: EnergyType.Electricity,
            selectedAssets: [],
            selectedGroupType: this.defaultGroupOrMeterSelectionOption,
            compensatedGraphData: [],
            projectedEnergyGraphData: []
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setHeatUnitTypeSelection(): Promise<void> {
        if (this.heatSelected) {
            return;
        }

        this.setState({
            newUnitType: "GJ",
            energyType: EnergyType.Heat,
            selectedAssets: [],
            selectedGroupType: this.defaultGroupOrMeterSelectionOption,
            compensatedGraphData: [],
            projectedEnergyGraphData: []
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setColdUnitTypeSelection(): Promise<void> {
        if (this.coldSelected) {
            return;
        }

        this.setState({
            newUnitType: "GJ",
            energyType: EnergyType.Cold,
            selectedAssets: [],
            selectedGroupType: this.defaultGroupOrMeterSelectionOption,
            compensatedGraphData: [],
            projectedEnergyGraphData: []
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setBarGraphSelection(): Promise<void> {
        if (this.barGraphSelected) {
            return;
        }

        this.setState({
            selectedGraphType: "bar"
        }, async () => await this.initializeEnergyDataAsync());
    }

    private async setLineGraphSelection(): Promise<void> {
        if (this.lineGraphSelected) {
            return;
        }

        this.setState({
            selectedGraphType: "line"
        }, async () => await this.initializeEnergyDataAsync());
    }

    private onDateChange(timeUnit: IDatePickerTimeUnit, startDate: Date, endDate: Date, triggerDataInitalization: boolean = true): void {
        this.setState({
            selectedTimeUnit: timeUnit,
            startDate: startDate,
            endDate: endDate,
            compensatedGraphData: [],
            projectedEnergyGraphData: [],
            dailyTemperatureData: [],
            hourlyTemperatureData: [],
            newStartDate: startDate,
            newEndDate: endDate,
            newTimeUnitType: timeUnit
        }, async () => { if (triggerDataInitalization) { await this.initializeEnergyDataAsync(); } });
    }

    private renderGraphHeaderTitle(): string {
        switch (this.state.energyType) {
            case EnergyType.Electricity:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.energytitle);
            case EnergyType.Gas:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.gastitle);
            case EnergyType.Heat:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.heattitle);
            case EnergyType.Cold:
                return LanguageProvider.getTranslation(translations.pages.energy.tabs.consumption.coldtitle);
            default:
                return '';
        }
    }

    private async initializeEnergyDataAsync(): Promise<void> {
        this.setState({ loading: true });
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;

        if (this.individualEnergyRenderingByGroupEnabled) {
            await this.setElectricDataByAssetAsync(startDate, endDate);
        }
        else {
            // We get data in a different model for specific days, which is why it is extracted for now.
            if (this.state.selectedTimeUnit !== "day") {
                const dailyTemperatureDataPromise = this.energyService.GetDailyTemperatureDataAsync(startDate, endDate);

                if (this.gasSelected || this.heatSelected) {
                    const gasData = await this.getNormalisedEnergieMissieDataByVenueAsync(startDate, endDate);

                    this.setState({
                        normalisedGraphData: gasData,
                        dailyTemperatureData: await dailyTemperatureDataPromise
                    });
                }
                else {
                    const data = await this.getCompensatedEnergieMissieDataByVenueAsync(startDate, endDate);

                    this.setState({
                        compensatedGraphData: data,
                        dailyTemperatureData: await dailyTemperatureDataPromise
                    });
                }
            }
            else {
                const hourlyTemperatureDataPromise = this.energyService.GetHourlyTemperatureDataAsync(startDate, endDate);

                if (this.electricitySelected) {
                    const possibleMeterId: string | undefined = this.isIndividualMainMeterSelected ? this.state.selectedGroupType!.value : undefined;

                    let quarterlyElectricData = (await this.energyService.GetQuarterlyElectricVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                        .sort((a, b) => (a.localHour * 60 + a.localMinutes) - (b.localHour * 60 + b.localMinutes));
                    quarterlyElectricData = this.filterOutDuplicateQuarterlyDataAroundDst(quarterlyElectricData);

                    this.setState({
                        projectedEnergyGraphData: [{
                            label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusage),
                            tooltipContent: LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-electricity']),
                            color: Colors.royal_blue,
                            data: quarterlyElectricData.map(i => i.actualUsage)
                        }],
                        hourlyTemperatureData: await hourlyTemperatureDataPromise
                    });
                }
                else if (this.gasSelected) {
                    const possibleMeterId: string | undefined = this.isIndividualMainMeterSelected ? this.state.selectedGroupType!.value : undefined;

                    let hourlyGasData = (await this.energyService.GetHourlyGasVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                        .sort((a, b) => a.localHour - b.localHour);
                    hourlyGasData = this.filterOutDuplicateHourAroundDst(hourlyGasData);

                    this.setState({
                        projectedEnergyGraphData: [{
                            label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusage),
                            tooltipContent: LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-gas']),
                            color: Colors.royal_blue,
                            data: hourlyGasData.map(i => i.actualUsage)
                        }],
                        hourlyTemperatureData: await hourlyTemperatureDataPromise
                    });
                }
                else if (this.heatSelected) {
                    let hourlyHeatData = (await this.energyService.GetHourlyHeatVenueUsageDataAsync(startDate, endDate))
                        .sort((a, b) => a.localHour - b.localHour);
                    hourlyHeatData = this.filterOutDuplicateHourAroundDst(hourlyHeatData);

                    this.setState({
                        projectedEnergyGraphData: [{
                            label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusage),
                            tooltipContent: LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-heat']),
                            color: Colors.royal_blue,
                            data: hourlyHeatData.map(i => i.actualUsage)
                        }],
                        hourlyTemperatureData: await hourlyTemperatureDataPromise
                    });
                }
                else if (this.coldSelected) {
                    let hourlyColdData = (await this.energyService.GetHourlyColdVenueUsageDataAsync(startDate, endDate))
                        .sort((a, b) => a.localHour - b.localHour);
                    hourlyColdData = this.filterOutDuplicateHourAroundDst(hourlyColdData);

                    this.setState({
                        projectedEnergyGraphData: [{
                            label: LanguageProvider.getTranslation(translations.pages.energy.tabs.graph.actualusage),
                            tooltipContent: LanguageProvider.getTranslation(translations.tooltips.energy['venue-energy-consumption-content']['usage-cold']),
                            color: Colors.royal_blue,
                            data: hourlyColdData.map(i => i.actualUsage)
                        }],
                        hourlyTemperatureData: await hourlyTemperatureDataPromise
                    });
                }
            }
        }

        this.setState({
            selectedUnitType: this.state.newUnitType,
            loading: false
        });
    }

    private filterOutDuplicateHourAroundDst(completeData: IHourlyEnergieMissieValue[]): IHourlyEnergieMissieValue[] {
        // This function handles the case where the day where we swap from DST to no DST has a double (local) hour (2-3).
        // Since our graph only displays hour 2 once, we only want one of these hours in our dataset. Energiemissie also only returns 1 of those hours with data!
        if (completeData.length === 24) {
            return completeData;
        }

        const results: IHourlyEnergieMissieValue[] = [];

        for (let hour = 0; hour < 24; hour++) {
            const elementsAtHour = completeData.filter(d => d.localHour === hour);

            // Try to use the first element with an actual usage value, if none, just use the first
            // element if present (can not be present if the hour is just completely missing).
            const presentElement = elementsAtHour.find(e => e.actualUsage !== null)
            if (presentElement) {
                results.push(presentElement);
            }
            else if (elementsAtHour.length > 0) {
                results.push(elementsAtHour[0])
            }
        }

        return results;
    }

    private filterOutDuplicateQuarterlyDataAroundDst(completeData: IQuarterlyEnergieMissieValue[]): IQuarterlyEnergieMissieValue[] {
        // Similar to above method (filterOutDuplicateHourAroundDst).
        if (completeData.length === 96) {
            return completeData;
        }

        const results: IQuarterlyEnergieMissieValue[] = [];

        for (let quarter = 0; quarter < 96; quarter++) {
            const elementsAtQuarter = completeData.filter(d => (d.localHour * 60 + d.localMinutes) / 15 === quarter);

            // Try to use the first element with an actual usage value, if none, just use the first
            // element if present (can not be present if the hour is just completely missing).
            const presentElement = elementsAtQuarter.find(e => e.actualUsage !== null)
            if (presentElement) {
                results.push(presentElement);
            }
            else if (elementsAtQuarter.length > 0) {
                results.push(elementsAtQuarter[0])
            }
        }

        return results;
    }

    private async getNormalisedEnergieMissieDataByVenueAsync(startDate: Date, endDate: Date): Promise<INormalisedEnergieMissieValue[]> {
        if (!this.state.venue) {
            return [];
        }

        const timeUnit = this.state.selectedTimeUnit;

        if (this.heatSelected) {
            if (timeUnit === "week") {
                return (await this.energyService.GetDailyHeatVenueUsageDataAsync(startDate, endDate))
                    .sort((a, b) => moment([a.localYear, a.localMonth - 1, a.localDay]).diff(moment([b.localYear, b.localMonth - 1, b.localDay])));
            }
            else if (timeUnit === "month") {
                return (await this.energyService.GetDailyHeatVenueUsageDataAsync(startDate, endDate))
                    .sort((a, b) => a.localDay - b.localDay);
            }
            else if (timeUnit === "year") {
                return (await this.energyService.GetMonthlyHeatVenueUsageDataAsync(startDate, endDate))
                    .sort((a, b) => a.localMonth - b.localMonth);
            }
        }
        else if (this.gasSelected) {
            const possibleMeterId: string | undefined = this.isIndividualMainMeterSelected ? this.state.selectedGroupType!.value : undefined;

            if (timeUnit === "week") {
                return (await this.energyService.GetDailyGasVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                    .sort((a, b) => moment([a.localYear, a.localMonth - 1, a.localDay]).diff(moment([b.localYear, b.localMonth - 1, b.localDay])));
            }
            else if (timeUnit === "month") {
                return (await this.energyService.GetDailyGasVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                    .sort((a, b) => a.localDay - b.localDay);
            }
            else if (timeUnit === "year") {
                return (await this.energyService.GetMonthlyGasVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                    .sort((a, b) => a.localMonth - b.localMonth);
            }
        }
        else if (this.electricitySelected || this.coldSelected) {
            console.error("This method should not be called while electric/cold is selected, since the data for electric/cold comes as another model in another method!");
        }

        return [];
    }

    private async getCompensatedEnergieMissieDataByVenueAsync(startDate: Date, endDate: Date): Promise<ICompensatedEnergieMissieValue[]> {
        if (!this.state.venue) {
            return [];
        }

        const timeUnit = this.state.selectedTimeUnit;

        if (this.electricitySelected) {
            const possibleMeterId: string | undefined = this.isIndividualMainMeterSelected ? this.state.selectedGroupType!.value : undefined;

            if (timeUnit === "week") {
                return (await this.energyService.GetDailyElectricVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                    .sort((a, b) => moment([a.localYear, a.localMonth - 1, a.localDay]).diff(moment([b.localYear, b.localMonth - 1, b.localDay])));
            }
            else if (timeUnit === "month") {
                return (await this.energyService.GetDailyElectricVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                    .sort((a, b) => a.localDay - b.localDay);
            }
            else if (timeUnit === "year") {
                return (await this.energyService.GetMonthlyElectricVenueUsageDataAsync(startDate, endDate, possibleMeterId))
                    .sort((a, b) => a.localMonth - b.localMonth);
            }
        }
        else if (this.coldSelected) {
            if (timeUnit === "week") {
                return (await this.energyService.GetDailyColdVenueUsageDataAsync(startDate, endDate))
                    .sort((a, b) => moment([a.localYear, a.localMonth - 1, a.localDay]).diff(moment([b.localYear, b.localMonth - 1, b.localDay])));
            }
            else if (timeUnit === "month") {
                return (await this.energyService.GetDailyColdVenueUsageDataAsync(startDate, endDate))
                    .sort((a, b) => a.localDay - b.localDay);
            }
            else if (timeUnit === "year") {
                return (await this.energyService.GetMonthlyColdVenueUsageDataAsync(startDate, endDate))
                    .sort((a, b) => a.localMonth - b.localMonth);
            }
        }
        else if (this.gasSelected || this.heatSelected) {
            console.error("This method should not be called while gas/heat is selected, since the data for gas/heat comes as another model in another method!");
        }

        return [];
    }

    private async setElectricDataByAssetAsync(startDate: Date, endDate: Date): Promise<void> {
        if (!this.individualEnergyRenderingByGroupEnabled) {
            return;
        }

        const completeGroup = this.state.selectedAssets.length === 0;
        const assetIds: string[] = completeGroup
            ? this.state.assetSelectionOptions.map(i => i.value)
            : this.state.selectedAssets.map(i => i.value);

        const timeUnit = this.state.selectedTimeUnit;
        let data: (number | null)[][] = [];

        if (timeUnit === "day") {
            data = (await this.energyService.GetQuarterlyAssetEnergeyManagementDataAsync(startDate, endDate, assetIds))
                .map(arr =>
                    arr.sort((a, b) => a.localHour - b.localHour).map(i => i.consumed)
                );
        }
        else if (timeUnit === "week") {
            data = (await this.energyService.GetDailyAssetEnergyManagementDataAsync(startDate, endDate, assetIds))
                .map(arr =>
                    arr.sort((a, b) => moment([a.localYear, a.localMonth - 1, a.localDay]).diff(moment([b.localYear, b.localMonth - 1, b.localDay]))).map(i => i.consumed)
                );
        }
        else if (timeUnit === "month") {
            data = (await this.energyService.GetDailyAssetEnergyManagementDataAsync(startDate, endDate, assetIds))
                .map(arr =>
                    arr.sort((a, b) => a.localDay - b.localDay)
                        .map(i => i.consumed)
                );

        }
        else if (timeUnit === "year") {
            data = (await this.energyService.GetMonthlyAssetEnergyManagementDataAsync(startDate, endDate, assetIds))
                .map(arr =>
                    arr.sort((a, b) => a.localMonth - b.localMonth).map(i => i.consumed)
                );
        }

        if (completeGroup) {
            // This method loops over the first asset (if it exists) and then sums the values from each asset for the same point in time,
            // transforming null to 0 where it exists.
            const summedData = data.length === 0
                ? []
                : data[0].map((v, i) => data.map(d => d[i]).reduce((pv, cv) => (pv ?? 0) + (cv ?? 0), 0));

            this.setState({
                projectedEnergyGraphData: [{
                    data: summedData,
                    label: this.state.selectedGroupType?.isGroup || this.state.selectedGroupType?.isMeter ? this.state.selectedGroupType.label : "",
                    color: Colors.royal_blue
                }]
            });
        }
        else {
            this.setState({
                projectedEnergyGraphData: this.state.selectedAssets.map((opt, index) => ({
                    data: data[index],
                    label: opt.label,
                    color: opt.color
                }))
            });
        }
    }

    private async onGraphElementClickAsync(index: number): Promise<void> {
        const timeUnit = this.state.selectedTimeUnit;

        if (timeUnit === "day") {
            return;
        }
        else if (timeUnit === "week") {
            const startOfWeek = this.state.startDate;
            const newDate = moment(startOfWeek).add(index, "day");
            this.onDateChange('day', newDate.toDate(), newDate.toDate());
        }
        else if (timeUnit === "month") {
            const startOfMonth = this.state.startDate;
            const newDate = moment(startOfMonth).add(index, "day");
            this.onDateChange('day', newDate.toDate(), newDate.toDate());
        }
        else if (timeUnit === "year") {
            const startOfYear = this.state.startDate;
            const newDate = moment(startOfYear).add(index, "month");
            this.onDateChange('month', newDate.toDate(), newDate.add(1, "month").add(-1, "day").toDate());
        }
    }

    private onTimeUnitClickAsync(timeUnit: IDatePickerTimeUnit): void {

        if (timeUnit === "day") {
            this.onDateChange('day', this.state.startDate, this.state.startDate);
        }
        else if (timeUnit === "week") {
            const newDate = moment(this.state.startDate).startOf('week');
            const newEndDate = moment(this.state.startDate).endOf('week');
            this.onDateChange('week', newDate.toDate(), newEndDate.toDate());
        }
        else if (timeUnit === "month") {
            const newDate = moment(this.state.startDate).startOf('month');
            const newEndDate = moment(this.state.startDate).endOf('month');
            this.onDateChange('month', newDate.toDate(), newEndDate.toDate());
        }
        else if (timeUnit === "year") {
            const newDate = moment(this.state.startDate).startOf('year');
            const newEndDate = moment(this.state.startDate).endOf('year');
            this.onDateChange('year', newDate.toDate(), newEndDate.toDate());
        }
    }

    private renderDatePickerAndSelectors(): JSX.Element {
        const noOptionsMessage = (): string => LanguageProvider.getTranslation("buttons.dropdowns.nooptions");
        return (
            <>
                <div className="row title-input-selection png-hidden">
                    <div className="col-sm-4 pr-2">
                        <Select
                            isClearable={true}
                            isDisabled={!this.isMeterOrGroupSelectionEnabled}
                            value={this.state.selectedGroupType}
                            onChange={this.setGroupSelectionWrapper}
                            options={this.electricitySelected ?
                                this.state.electricGroupOrMeterOptions : this.state.hasMultipleGasMeters ?
                                    this.state.gasGroupOrMeterOptions : []}
                            placeholder={`${LanguageProvider.getTranslation("pages.energy.tabs.consumption.allassets")}`}
                            noOptionsMessage={noOptionsMessage}
                            styles={SelectBoxUtils.getSelectStylesWithDefaultOption(40)}
                            formatOptionLabel={SelectBoxUtils.getDefaultOptionFormatter()}
                        />
                    </div>
                    <div className="col-sm-8 pr-2 pl-0">
                        <Select
                            isClearable={true}
                            isDisabled={!this.electricitySelected}
                            value={this.state.selectedAssets}
                            onChange={this.setSelectedAsset}
                            options={this.electricitySelected ? this.state.assetSelectionOptions : []}
                            placeholder={`Assets`}
                            isMulti={true}
                            noOptionsMessage={noOptionsMessage}
                            styles={SelectBoxUtils.getDefaultSelectColoredStyles(40)}
                        />
                    </div>
                </div>

                <div className="ml-3 d-flex png-hidden">
                    <div
                        className={`datetoggle-btn ${(this.state.selectedTimeUnit === 'day') ? 'active' : ''}`}
                        onClick={(): void => this.onTimeUnitClickAsync('day')}>
                        {LanguageProvider.getTranslation(translations.dates.day)}
                    </div>
                    <div
                        className={`datetoggle-btn ${(this.state.selectedTimeUnit === 'week') ? 'active' : ''}`}
                        onClick={(): void => this.onTimeUnitClickAsync('week')}>
                        {LanguageProvider.getTranslation(translations.dates.week)}
                    </div>
                    <div
                        className={`datetoggle-btn ${(this.state.selectedTimeUnit === 'month') ? 'active' : ''}`}
                        onClick={(): void => this.onTimeUnitClickAsync('month')}>
                        {LanguageProvider.getTranslation(translations.dates.month)}
                    </div>
                    <div
                        className={`datetoggle-btn ${(this.state.selectedTimeUnit === 'year') ? 'active' : ''}`}
                        onClick={(): void => this.onTimeUnitClickAsync('year')}>
                        {LanguageProvider.getTranslation(translations.dates.year)}
                    </div>
                </div>

                <DatePickerDeluxe
                    defaultDateUnit={'year'}
                    onDateChange={this.onDateChange}
                    newStartDate={this.state.startDate}
                    newEndDate={this.state.endDate}
                    compactView={true}
                    newTimeUnitType={this.state.newTimeUnitType}
                    minimalisticCompactButton={true}
                />
            </>

        );
    }

    private getExportFileName(): string {
        let dataType = '';
        switch (this.state.energyType) {
            case EnergyType.Electricity:
                dataType = LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.electricity);
                break;
            case EnergyType.Gas:
                dataType = LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.gas);
                break;
            case EnergyType.Heat:
                dataType = LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.heat);
                break;
            case EnergyType.Cold:
                dataType = LanguageProvider.getTranslation(translations.pages.energy.tabs.temperature.cold);
                break;
        }

        const monthTranslations = LanguageProvider.getTranslations(
            ["january", "february", "march", "april", "may", "june", "july", "august",
                "september", "october", "november", "december"].map(d => `dates.shorthand.${d}`));

        let timeDescription = '';
        switch (this.state.selectedTimeUnit) {
            case 'day':
                timeDescription = `${this.state.startDate.getFullYear()}${monthTranslations[this.state.startDate.getMonth()]}${this.state.startDate.getDate()}`;
                break;
            case 'week':
                timeDescription = `${this.state.startDate.getFullYear()}wk${moment(this.state.startDate).week()}`;
                break;
            case 'month':
                timeDescription = `${this.state.startDate.getFullYear()}${monthTranslations[this.state.startDate.getMonth()]}`;
                break;
            case 'year':
                timeDescription = `${this.state.startDate.getFullYear()}`;
                break;
        }

        return `${this.state.venue && this.state.venue.name}_${dataType}_${timeDescription}`;
    }

    public render(): JSX.Element {
        const renderBasicEnergyGraph = this.state.selectedTimeUnit === "day" || this.individualEnergyRenderingByGroupEnabled;

        return (
            <EnergyManagementPage>
                <PageHeader pageName="energymanagement-reports" />
                <div id="venue-energy-consumption" className="main-content" ref={this.graphHtmlReference}>
                    <div className="main-content-header">
                        <div className="row input-selection">
                            <div className="col-sm-8">
                                <h1>
                                    {this.state.venue && this.state.venue.name}
                                </h1>
                            </div>
                            <div className="col-sm-4 d-flex png-hidden">
                                <div className="selection-container mr-2 ml-auto">
                                    <div onClick={this.setBarGraphSelection} className={"selection-item " + (this.barGraphSelected ? "selected" : "")}>
                                        <img src={this.barGraphSelected ? BarSelectedIcon : BarIcon} />
                                    </div>
                                    <div onClick={this.setLineGraphSelection} className={"selection-item " + (this.lineGraphSelected ? "selected" : "")}>
                                        <img src={this.lineGraphSelected ? LineSelectedIcon : LineIcon} />
                                    </div>
                                </div>
                                <div className="selection-container">
                                    {this.state.hasElectricitySubscription && <div onClick={this.setEnergyUnitTypeSelection} className={"selection-item " + (this.electricitySelected ? "selected" : "")}>
                                        <img src={this.electricitySelected ? EnergySelectedIcon : EnergyIcon} />
                                    </div>}
                                    {this.state.hasGasSubscription && <div onClick={this.setGasUnitTypeSelection} className={"selection-item " + (this.gasSelected ? "selected" : "")}>
                                        <img src={this.gasSelected ? GasSelectedIcon : GasIcon} />
                                    </div>}
                                    {this.state.hasHeatSubscription && <div onClick={this.setHeatUnitTypeSelection} className={"selection-item " + (this.heatSelected ? "selected" : "")}>
                                        <img src={this.heatSelected ? HeatSelectedIcon : HeatIcon} />
                                    </div>}
                                    {this.state.hasColdSubscription && <div onClick={this.setColdUnitTypeSelection} className={"selection-item " + (this.coldSelected ? "selected" : "")}>
                                        <img src={this.coldSelected ? ColdSelectedIcon : ColdIcon} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <EnergyGraphHeader
                                    selectedTimeUnit={this.state.selectedTimeUnit}
                                    startDate={this.state.startDate}
                                    onDateChange={this.onDateChange}
                                    title={this.renderGraphHeaderTitle()}
                                    extraRenderAfterTitle={this.renderDatePickerAndSelectors}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                {renderBasicEnergyGraph &&
                                    <EnergyGraph
                                        width={20}
                                        height={window.innerHeight > this.viewportHeightThresholdForIncreasedGraphHeight ? 7 : 6}
                                        timeRepresentation={this.state.selectedTimeUnit}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        unitType={EnergyUnitType[this.state.selectedUnitType]}
                                        graphType={this.state.selectedGraphType}
                                        data={this.state.projectedEnergyGraphData}
                                        onElementClick={this.onGraphElementClickAsync}
                                        isBarGraphElementClickable={this.state.selectedTimeUnit !== 'day'}
                                        loading={this.state.loading}
                                        showLegend={true}
                                        exportOptions={{
                                            fileName: this.getExportFileName()
                                        }}
                                        graphHtmlReference={this.graphHtmlReference}
                                        hourlyTemperatureData={!this.individualEnergyRenderingByGroupEnabled ? this.state.hourlyTemperatureData : []}
                                        containsQuarterlyData={this.state.energyType === EnergyType.Electricity}
                                        energyType={EnergyType[this.state.energyType.toString()]}
                                    />
                                }
                                {!renderBasicEnergyGraph &&
                                    <>
                                        {(this.electricitySelected || this.coldSelected) &&
                                            <EnergieMissieGraph
                                                width={20}
                                                height={window.innerHeight > this.viewportHeightThresholdForIncreasedGraphHeight ? 7 : 6}
                                                timeRepresentation={this.state.selectedTimeUnit}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                unitType={EnergyUnitType[this.state.selectedUnitType]}
                                                energyType={EnergyType[this.state.energyType.toString()]}
                                                graphType={this.state.selectedGraphType}
                                                data={this.state.compensatedGraphData}
                                                onElementClick={this.onGraphElementClickAsync}
                                                loading={this.state.loading}
                                                exportOptions={{
                                                    fileName: this.getExportFileName()
                                                }}
                                                graphHtmlReference={this.graphHtmlReference}
                                                onlyRenderConsumption={this.isIndividualMainMeterSelected}
                                                dailyTemperatureData={this.state.dailyTemperatureData}
                                            />}
                                        {(this.heatSelected || this.gasSelected) &&
                                            <NormalisedEnergieMissieGraph
                                                width={20}
                                                height={window.innerHeight > this.viewportHeightThresholdForIncreasedGraphHeight ? 7 : 6}
                                                timeRepresentation={this.state.selectedTimeUnit}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                unitType={EnergyUnitType[this.state.selectedUnitType]}
                                                energyType={EnergyType[this.state.energyType.toString()]}
                                                graphType={this.state.selectedGraphType}
                                                data={this.state.normalisedGraphData}
                                                onElementClick={this.onGraphElementClickAsync}
                                                loading={this.state.loading}
                                                exportOptions={{
                                                    fileName: this.getExportFileName()
                                                }}
                                                graphHtmlReference={this.graphHtmlReference}
                                                onlyRenderConsumption={this.isIndividualMainMeterSelected}
                                                dailyTemperatureData={this.state.dailyTemperatureData}
                                            />}
                                    </>}
                            </div>
                        </div>
                    </div>
                </div>
            </EnergyManagementPage>
        );
    }
}

export default withTranslation()(withTelemetry(EnergyBaselinePage, "EnergyBaselinePage"));