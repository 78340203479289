export default class Sorter {
    public static sortByProperty<T>(items: T[], property: string, descending?: boolean): T[] {
        const result = items.slice();
        result.sort((a, b) =>
            a[property] ?
                b[property] ?
                    a[property] < b[property] ?
                        (descending ? 1 : -1)
                        : a[property] > b[property] ?
                            (descending ? -1 : 1)
                            : 0
                    : -1
                : 0);
        return result;
    }

    public static sort<T>(items: T[], descending?: boolean): T[] {
        const result = items.slice();
        result.sort((a, b) => a < b ? (descending ? 1 : -1) : a > b ? (descending ? -1 : 1) : 0);
        return result;
    }
}