import { ApplicationConfig } from "config";
import CoreSpaceIncludes from "enums/coreSpaceIncludes";
import CoreSpaceTypes from "enums/coreSpaceTypes";
import { IFloorOptions } from "./IFloorOptions";
import { ISpaceOptions } from "./ISpaceOptions";

export class DashboardMapOptions {
    public floorOptions: IFloorOptions;
    public spaceOptions: ISpaceOptions;
    public proxyEndpoint: string;

    public constructor(renderAllSpaceTypes: boolean = false, includeTagsForSpaces: boolean = false) {
        this.floorOptions = {
            skipCacheCheck: true,
            includes: [CoreSpaceIncludes.Properties]
        };

        this.spaceOptions = {
            types: renderAllSpaceTypes ? undefined : [CoreSpaceTypes.Workspace, CoreSpaceTypes.Room],
            skipCacheCheck: true,
            includes: [CoreSpaceIncludes.Properties, CoreSpaceIncludes.Values, CoreSpaceIncludes.DataTypes]
        };

        if (includeTagsForSpaces) {
            this.spaceOptions.includes?.push(CoreSpaceIncludes.Tags);
        }

        this.proxyEndpoint = ApplicationConfig.endpoints.autodeskProxy;
    }
}
